import { useEffect, useRef } from "react";

export function useHandleOutsideClick(open, setOpen)
{
    const node = useRef();

    function handleClickOutside(e)
    {
        // The first check is to ensure that when a fitler box is shown it doesn't close when clicked on
        // The second check is to make sure the helper section does not close whn the chat button is clicked on
        // @ts-ignore
        if (node.current.contains(e.target) || e.toElement.innerText === "Live Help Chat")
        {
            // inside click
            return;
        }
        // outside click
        setOpen(false);
    }

    useEffect(() =>
    {
        if (open)
        {
            document.addEventListener("mousedown", handleClickOutside);
        } else
        {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () =>
        {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open, setOpen]);

    useEffect(() =>
    {
        return () =>
        {
            window.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    return node;
}
