import React from "react"
import { TabbedCardFormPage } from "../../components/Form/TabbedCardFormPage"
import { useMobileFormPage } from "./useMobileFormPage"
import { permissions } from "../../Constants/permissions"

export default function MobileFormPage({ prop }: any)
{
    const { id } = prop.match.params

    const {
        loading,
        tabs,
        state,
        updateForm,
        updateErrors,
        submitData,
        // changes,
        // resetChanges,
    } = useMobileFormPage(id)

    return (
        <React.Fragment>
            <TabbedCardFormPage
                configIdentifier={state.name}
                state={state}
                updateForm={updateForm}
                updateErrors={updateErrors}
                // changes={changes}
                // resetChanges={resetChanges}
                tabs={tabs}
                loading={loading}
                defineSubmitData={submitData}
                displayName={"Mobile"}
                permissions={[permissions.mobile, permissions.admin, permissions.VHAdmin]}
            />
        </React.Fragment>
    )
}
