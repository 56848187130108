import React, { useState, useEffect, useContext } from "react"
import { SimpleRecordModal } from "../SimpleRecordModal/SimpleRecordModal"
import Table from "../Table/Table"
import Pagination from '../Table/Pagination'
import { hasPermission } from "../../Constants/permissions"
import { SessionContext } from "../../Contexts/SessionContext"

export default function FormTable({
    columns,
    value,
    onChange,
    addModal,
    deleteModal,
    defaultValue,
    customSort,
    error,
    setError,
    noPagination = true,
    name,
    permissions
}: IFormTable)
{
    const [loading, setLoading] = useState(false)
    const [addModalOpen, setAddModalOpen] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [records, setRecords] = useState()
    const { state: sessionState } = useContext(SessionContext)
    const [selectedRecords, setSelectedRecords] = useState([])
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(5)

    function updateRow(e)
    {
        const { row, value: fieldValue, name, type } = e.target

        let val = value
        val[row + offset][name] = fieldValue
        onChange({
            target: {
                name,
                value: val,
                type,
            },
        })
    }

    useEffect(() =>
    {
        setRecords(handleSort())
    }, [value, offset, limit])

    function handleSort()
    {
        let records: any = []
        // Handle custom sorting if provided
        if (customSort)
        {
            records = value.sort(
                (a, b) =>
                    customSort.order.indexOf(
                        //@ts-ignore
                        String(a[customSort.uniqueIdentifier])
                    ) -
                    customSort.order.indexOf(
                        //@ts-ignore
                        String(b[customSort.uniqueIdentifier])
                    )
            )
        } else if (!value)
        {
            records = defaultValue
        }
        else
        {
            records = value
        }
        // Handle paginantion if provided
        if (noPagination)
        {
            return records
        } else
        {
            return records.slice(offset, limit + offset)
        }

    }

    function getError(row, name)
    {
        if (error)
        {
            const fieldError = error.filter(error => error.row === row && error.name === name)

            if (fieldError && fieldError.length)
            {
                return fieldError[0].value
            }
        }
    }

    return (
        <div
            style={{
                flexDirection: "column",
                display: "flex",
                flex: 1,
            }}
        >
            <Table
                small
                searchable={false}
                noPagination={true}
                title=""
                columns={columns}
                updateRow={updateRow}
                updateErrors={(e) => setError({ target: { ...e.target, type: "formTable", name, value: e.target } })}
                getError={getError}
                loading={loading}
                records={records}
                selectedRecords={selectedRecords}
                setSelectedRecords={setSelectedRecords}
                onAdd={
                    addModal && hasPermission(permissions, sessionState.session.permissions, "update") && {
                        action: () =>
                        {
                            setAddModalOpen(true)
                        },
                    }
                }
                onDelete={
                    deleteModal && hasPermission(permissions, sessionState.session.permissions, "update") && {
                        action: () =>
                        {
                            setDeleteModalOpen(true)
                        },
                    }
                }
            />
            {!noPagination && (
                <Pagination
                    loading={loading}
                    records={records}
                    offset={offset}
                    setOffset={setOffset}
                    limit={limit}
                    setLimit={setLimit}
                    totalRecords={value.length}
                />
            )}
            {addModal && (
                <SimpleRecordModal
                    open={addModalOpen}
                    setOpen={setAddModalOpen}
                    dataType={addModal.dataType}
                    fieldFrom={addModal.fieldFrom}
                    settingsStages={addModal.settingsStages}
                    availabilityCheck={addModal.availabilityCheck}
                    defineSubmitData={addModal.defineSubmitData}
                    onSubmit={addModal.onSubmit}
                    allowBulkAdd={addModal.allowBulkAdd}
                    customTitle={addModal.customTitle}
                    customConfirm={addModal.customConfirm}
                    action={"create"}
                />
            )}
            {deleteModal && (
                <SimpleRecordModal
                    selectedRecords={selectedRecords}
                    open={deleteModalOpen}
                    setOpen={setDeleteModalOpen}
                    dataType={deleteModal.dataType}
                    fieldFrom={deleteModal.fieldFrom}
                    settingsStages={deleteModal.settingsStages}
                    availabilityCheck={deleteModal.availabilityCheck}
                    defineSubmitData={deleteModal.defineSubmitData}
                    onSubmit={deleteModal.onSubmit}
                    allowBulkAdd={deleteModal.allowBulkAdd}
                    customTitle={deleteModal.customTitle}
                    customConfirm={deleteModal.customConfirm}
                    action={"delete"}
                />
            )}
        </div>
    )
}
