import { useContext, useEffect, useState } from "react";
import { useRequest } from "../../Hooks/useRequest";
import { SessionContext } from "../../Contexts/SessionContext"
import { useFormReducer } from "../../Hooks/useFormReducer";
import { regex } from "../../Constants/regex";
import { regexError } from "../../Constants/regexError";
import { hasPermission, permissions } from "../../Constants/permissions";
import useAPIRequests from "../../Hooks/useAPIRequests";

export function useDialThroughFormPage(id: number)
{
    const [record, setRecord] = useState({})
    const [loading, setLoading] = useState(true)
    const { getRequest, postRequest } = useRequest()
    const { getCallerIdPromise } = useAPIRequests()
    const { state: sessionState } = useContext(SessionContext)

    async function pullData()
    {
        setLoading(true)
        let data = {}

        const dialThroughResponse = await getRequest(`/dialthrough/${id}`)

        if (dialThroughResponse)
        {
            data = {
                ...dialThroughResponse,
                cli: dialThroughResponse.cli
                    ? dialThroughResponse.cli
                    : "passThrough",
            }
        }

        setRecord(data)
        setLoading(false)
    }

    useEffect(() =>
    {
        pullData()
        // eslint-disable-next-line
    }, [id])

    const {
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
    } = useFormReducer({ record })

    function submitData()
    {
        return [
            {
                body:
                {
                    ...state,
                    cli: state.cli === "passThrough" ? null : state.cli
                },
                path: "/dialthrough/" + id,
            },
        ]
    }

    function deleteData()
    {
        return [{
            path: '/dialthrough',
            type: "delete",
            indexPagePath: "/dial-through",
            displayName: "Dial Through"
        }]
    }

    function checkIfCLIInUse(
        fieldFrom
    ): Promise<{ success: boolean; message: string }>
    {
        return new Promise(async (resolve) =>
        {
            if (regex.telephoneNumber.test(fieldFrom))
            {
                let response = await getRequest(
                    `/dialthrough/` + id + `/clis/-1/-1`
                )

                if (response)
                {
                    if (response.records)
                    {
                        const filteredRecords = response.records.filter(
                            (record) => record.number === fieldFrom
                        )

                        if (filteredRecords && filteredRecords.length > 0)
                        {
                            resolve({
                                success: false,
                                message:
                                    "This number is already a part of this dial through",
                            })
                        } else
                        {
                            resolve({ success: true, message: "" })
                        }
                    } else
                    {
                        resolve({
                            success: false,
                            message:
                                "Failed to retrieve data, please try again",
                        })
                    }
                } else
                {
                    resolve({
                        success: false,
                        message: "Failed to retrieve data, please try again",
                    })
                }
            } else
            {
                resolve({
                    success: false,
                    message: "Please enter a telephone number",
                })
            }
        })
    }

    const tabs: ITab[] = [
        {
            title: "General",
            icon: "toolbox",
            disabled: false,
            sections: [
                {
                    title: "Overview",
                    fields: [
                        {
                            label: "Name",
                            type: "text",
                            name: "name",
                            required: true,
                            maxLength: 50,
                        },
                        {
                            label: "Caller ID",
                            type: "select",
                            loadOptions: getCallerIdPromise,
                            name: "cli",
                            helptext: "The Caller ID displayed to the called party"
                        },
                        {
                            label: "PIN",
                            type: "password",
                            name: "pin",
                            regex: regex.pin,
                            regexError: regexError.pin,
                            helptext: "Access PIN to authenticate the Dial Through."
                        },
                        {
                            label: "PIN Enabled",
                            type: "switch",
                            name: "pinEnabled",
                            helptext: "Disable the PIN feature and only allow Caller ID Authentication."
                        },
                    ],
                },
                {
                    title: "Call Restrictions",
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "Call restrictions are very important and should be considered carefully before enabling. Fraud detection is enabled by default, however do not provide any guarantees. Please make sure that only required destinations are enabled and due diligence has been applied.",
                        },
                        {
                            type: "switch",
                            label: "Allow UK Geographic",
                            name: "ukGeo",
                            helptext: "(01, 02 & 03)",
                            halfWidth: true,
                        },
                        {
                            type: "switch",
                            label: "Allow International Calls",
                            name: "inter",
                            disabled: !hasPermission([permissions.highRisk, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                            halfWidth: true,
                        },
                        {
                            type: "switch",
                            label: "Allow UK Mobile",
                            name: "ukMob",
                            helptext: "(071 to 079)",
                            halfWidth: true,
                        },
                        {
                            type: "switch",
                            label: "Allow Other UK Calls",
                            name: "ukOther",
                            halfWidth: true,
                            helptext: "05/08 numbers"
                        },

                        {
                            label: "Concurrent Calls",
                            type: "select",
                            options: [
                                { label: "1", value: 1 },
                                { label: "2", value: 2 },
                                { label: "3", value: 3 },
                                { label: "4", value: 4 },
                                { label: "5", value: 5 },
                                { label: "10", value: 10 },
                                { label: "15", value: 15 },
                                { label: "20", value: 20 },
                                { label: "30", value: 30 },
                                { label: "40", value: 40 },
                                { label: "50", value: 50 },
                            ],
                            name: "channels",
                            helptext: "The number of concurrent calls in the Dial Through service."
                        },
                    ],
                },
                {
                    title: "Caller ID Authentication",
                    fullWidth: true,
                    fields: [
                        {
                            type: "table",
                            customPaginationName: "dialThroughCLIs",
                            name: "options",
                            dataType: "Caller ID Authentication",
                            pullData: pullData,
                            columns: [
                                {
                                    title: "Number",
                                    name: "number",
                                },
                            ],
                            urlString: `/dialthrough/${id}/clis`,
                            restType: "GET",
                            permissions: [permissions.dialThrough, permissions.admin, permissions.wholesaleAdmin, permissions.wholesaleCustomers, permissions.VHAdmin, permissions.VHCustomers],
                            addModal: {
                                displayName: "Caller ID Authentication",
                                fieldFrom: {
                                    name: "number",
                                    label: "Number",
                                    type: "text",
                                    regex: regex.telephoneNumber,
                                    regexError: regexError.telephoneNumber
                                },
                                allowBulkAdd: false,
                                availabilityCheck: (fieldFrom, fieldTo) =>
                                {
                                    return new Promise(async (resolve) =>
                                    {
                                        const responseMessage = await checkIfCLIInUse(
                                            fieldFrom
                                        )

                                        if (
                                            responseMessage &&
                                            responseMessage.success
                                        )
                                        {
                                            resolve({ success: true })
                                        } else
                                        {
                                            resolve({
                                                success: false,
                                                error: responseMessage.message,
                                            })
                                        }
                                    })
                                },
                                submitData: (data) =>
                                {
                                    return [{
                                        postData: {
                                            dtid: id,
                                        },
                                        path: "/dialthrough/cli",
                                        identifier: "number",
                                        type: "create",
                                        displayName: data.fromField,
                                    }]
                                },
                            },
                            deleteModal: {
                                displayName: "Caller ID Authentication",
                                uniqueIdentifier: "number",
                                submitData: () =>
                                {
                                    return [{
                                        path: "/dialthrough/cli",
                                        displayName: "number",
                                        type: "delete",
                                    }]
                                },
                            },
                        },
                    ],
                },
            ],
        },
    ]

    return {
        loading,
        tabs,
        state,
        updateState,
        updateForm,
        updateErrors,
        submitData,
        deleteData,
        // changes,
        // resetChanges,
    }
}
