import { FormControl, FormControlLabel, Radio, RadioGroup as RadioGroupComp } from "@material-ui/core";
import React from "react";
import Tooltip from '../Tooltip';

export function RadioGroup({
    disabled,
    radioOptions,
    onChange,
    name, 

}: IRadioGroup)
{
    return (
        <FormControl
            component="fieldset"
            disabled={disabled}
        >
            <RadioGroupComp row
                name={name}
                onChange={onChange}
                defaultValue={"0"}
            >
                {radioOptions.map(option =>

                    <Tooltip
                        text={option.tooltip}
                    >
                        <FormControlLabel
                            value={option.value}
                            control={<Radio size="small" />}
                            label={option.label}
                            disabled={option.disabled}
                        />
                    </Tooltip>
            )}
            </RadioGroupComp>
        </FormControl>
    )
}