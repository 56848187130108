import React, { forwardRef, useEffect, useContext } from "react";
import { useEditableTable } from "./useEditableTable";
import Table from "../Table/Table";
import { RecordModal } from "../RecordModal/RecordModal";
import { FileUploadModal } from "../FileUploadModal/FileUploadModal";
import { SessionContext } from "../../Contexts/SessionContext";
import { hasPermission } from "../../Constants/permissions";

export const EditableTable = forwardRef(({
    columns,
    searchable,
    filters,
    deleteModal,
    urlString,
    addModal,
    pullData,
    editModal,
    restType,
    body,
    noPagination,
    onSubmit,
    uploadFileModal,
    permissions,
    customPaginationName
}: IEditableTable, ref) =>
{
    const { state: sessionState } = useContext(SessionContext)
    const {
        state,
        dispatch,
        actions,
        updateRow,
        updateErrors,
        getError
    } = useEditableTable(restType, urlString, body, noPagination, ref, onSubmit, customPaginationName)

    return (
        <div
            style={{
                flexDirection: "column",
                display: "flex",
                flex: 1
            }}
        >
            <Table
                small
                customPaginationName={customPaginationName}
                searchable={searchable}
                noPagination={noPagination}
                title=""
                columns={columns}
                filters={filters}
                state={state}
                updateRow={updateRow}
                updateErrors={updateErrors}
                getError={getError}
                sortBy={state.sortBy}
                setSortBy={sortBy => dispatch({ type: actions.sortByChanged, payload: sortBy })}
                onSearch={(search) => dispatch({ type: actions.onSearchChanged, payload: search })}
                loading={state.loading}
                records={state.records}
                uploadFileModalButton={uploadFileModal && uploadFileModal.buttonProps}
                offset={state.offset}
                setOffset={offset => dispatch({ type: actions.offsetChanged, payload: offset })}
                limit={state.limit}
                setLimit={limit => dispatch({ type: actions.limitChanged, payload: limit })}
                totalRecords={state.totalRecords}
                selectedRecords={state.selectedRecords}
                setSelectedRecords={selectedRecords => dispatch({
                    type: actions.selectedRecordChanged,
                    payload: selectedRecords
                })}
                onAdd={addModal && hasPermission(permissions, sessionState.session.permissions, "update") && {
                    action: () => dispatch({ type: actions.newModalOpenStatusChanged, payload: true })
                }}
                onBulkEdit={editModal && hasPermission(permissions, sessionState.session.permissions, "update") && {
                    action: records =>
                    {
                        dispatch({ type: actions.selectedRecordChanged, payload: records })
                        dispatch({ type: actions.editModalOpenStatusChanged, payload: true })
                    }
                }}
                onDelete={deleteModal && hasPermission(permissions, sessionState.session.permissions, "update") && {
                    action: records =>
                    {
                        dispatch({ type: actions.selectedRecordChanged, payload: records })
                        dispatch({ type: actions.deleteModalOpenStatusChanged, payload: true })
                    }
                }}
                onFileUpload={
                    uploadFileModal && hasPermission(permissions, sessionState.session.permissions, "update") && {
                        action: () =>
                            dispatch({
                                type: actions.fileUploadModalOpenStatusChanged,
                                payload: true,
                            }),
                    }
                }
            />
            {state.newModalOpenStatus && (
                <RecordModal
                    open={state.newModalOpenStatus}
                    setOpen={() => dispatch({ type: actions.newModalOpenStatusChanged, payload: false })}
                    displayName={addModal.displayName}
                    fieldFrom={addModal.fieldFrom}
                    settingsStages={addModal.settingsStages}
                    availabilityCheck={addModal.availabilityCheck}
                    defineSubmitData={addModal.submitData}
                    allowBulkAdd={addModal.allowBulkAdd}
                    customTitle={addModal.customTitle}
                    pullData={pullData}
                    action={"create"}
                />
            )}
            {state.editModalOpenStatus && (
                <RecordModal
                    open={state.editModalOpenStatus}
                    setOpen={() => dispatch({ type: actions.editModalOpenStatusChanged, payload: false })}
                    displayName={editModal.displayName}
                    settingsStages={editModal.settingsStages}
                    availabilityCheck={editModal.availabilityCheck}
                    defineSubmitData={editModal.submitData}
                    selectedRecords={state.selectedRecords}
                    customTitle={addModal.customTitle}
                    pullData={pullData}
                    action={"edit"}
                    uniqueIdentifier={editModal.uniqueIdentifier}
                />
            )}
            {state.deleteModalOpenStatus && (
                <RecordModal
                    selectedRecords={state.selectedRecords}
                    open={state.deleteModalOpenStatus}
                    setOpen={() => dispatch({ type: actions.deleteModalOpenStatusChanged, payload: false })}
                    displayName={deleteModal.displayName}
                    defineSubmitData={deleteModal.submitData}
                    customTitle={deleteModal.customTitle}
                    pullData={pullData}
                    action={"delete"}
                    uniqueIdentifier={deleteModal.uniqueIdentifier}
                />
            )}
            {state.fileUploadModalOpenStatus && (
                <FileUploadModal
                    open={state.fileUploadModalOpenStatus}
                    setOpen={() =>
                        dispatch({
                            type: actions.fileUploadModalOpenStatusChanged,
                            payload: false,
                        })
                    }
                    customTitle={uploadFileModal.customTitle}
                    sectionTitle={uploadFileModal.sectionTitle}
                    fields={uploadFileModal.fields}
                    defineSubmitData={uploadFileModal.defineSubmitData}
                    displayResponse={uploadFileModal.displayResponse}
                    pullData={pullData}
                />
            )}
        </div>
    )
})

