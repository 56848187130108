import React, { useState } from "react"
import { TabbedCardFormPage } from "../../components/Form/TabbedCardFormPage"
import { useCustomPromptsFormPage } from "./useCustomPromptsFormPage"
import { RecordModal } from "../../components/RecordModal/RecordModal"
import { permissions } from "../../Constants/permissions"

export default function CustomPromptsFormPage({ prop }: any)
{
    const { id } = prop.match.params
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    const {
        loading,
        tabs,
        state,
        updateForm,
        updateErrors,
        submitData,
        deleteData,
        // changes,
        // resetChanges,
    } = useCustomPromptsFormPage(id)

    return (
        <React.Fragment>
            <TabbedCardFormPage
                state={state}
                updateForm={updateForm}
                updateErrors={updateErrors}
                // changes={changes}
                // resetChanges={resetChanges}
                tabs={tabs}
                loading={loading}
                onDelete={() => setDeleteModalOpen(true)}
                defineSubmitData={submitData}
                displayName={"Custom Prompt"}
                permissions={[permissions.prompts, permissions.admin, permissions.VHAdmin]}
            />
            <RecordModal
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
                uniqueIdentifier={"name"}
                action={"delete"}
                selectedRecords={[state.customPrompt]}
                displayName={"Custom Prompt"}
                defineSubmitData={deleteData}
            />
        </React.Fragment>
    )
}
