import { useEffect, useState } from "react"
import { useRequest } from "../../Hooks/useRequest"
import { useFormReducer } from "../../Hooks/useFormReducer"

export function useVoicemailFormPage(id: number, mailbox: string)
{
    const [record, setRecord] = useState({})
    const [loading, setLoading] = useState(true)
    const { getRequestBase64Conversion } = useRequest()

    useEffect(() =>
    {
        async function pullData()
        {
            setLoading(true)
            let data = {}

            const voicemailResponse = await getRequestBase64Conversion(`/voicemail/${mailbox}/messages/${id}`, `audio/wav`)
            if (voicemailResponse)
            {
                data = {
                    ...data,
                    voicemail: voicemailResponse
                }
            }

            setRecord(data)
            setLoading(false)
        }

        pullData()
        // eslint-disable-next-line
    }, [id])

    const {
        state,
        updateState,
        updateForm,
        updateErrors,
    } = useFormReducer({ record })

    function onDelete()
    {
        return [{
            path: `/voicemail/messages`,
            displayName: "Voicemail",
            type: "delete",
            indexPagePath: "/voicemail"
        }]
    }

    const tabs: ITab[] = [
        {
            title: "General",
            icon: "toolbox",
            disabled: false,
            sections: [
                {
                    title: "Overview",
                    fields: [
                        {
                            label: "Playback",
                            type: "audio",
                            name: "voicemail",
                        },
                        {
                            label: "Download",
                            type: "download",
                            name: "voicemail",
                            fileName: "voicemail",
                        },
                    ],
                },
            ],
        },
    ]

    return {
        loading,
        tabs,
        state,
        updateState,
        updateForm,
        updateErrors,
        onDelete,
        // changes,
        // resetChanges,
    }
}
