import { useContext, useEffect, useState } from "react"
import { SessionContext } from "../../Contexts/SessionContext"
import useAPIRequests from "../../Hooks/useAPIRequests"
import { useFormReducer } from "../../Hooks/useFormReducer"
import { useRequest } from "../../Hooks/useRequest"

export function useInternationalRestrictionsFormPage()
{
    const {
        getInternationalCountryPromise
    } = useAPIRequests()

    const { state: sessionState } = useContext(SessionContext)

    const [restrictions, setRestrictions] = useState([])
    const [loading, setLoading] = useState(true)
    const [record, setRecord] = useState({})
    const [addressModalOpen, setAddressModalOpen] = useState(false)
    const { getRequest, postRequestBase64Conversion, postRequest } = useRequest()
    const [linkedNumberModalRecords, setLinkedNumberModalRecords] = useState([])
    const [linkedNumberModalLoading, setLinkedNumberModalLoading] = useState(false)
    const [linkedNumberId, setLinkedNumberId] = useState(0)

    const [hasDocs, setHasDocs] = useState(false)

    useEffect(() =>
    {
        async function getZip()
        {
            setLoading(true)
            let data = {}

            const response = await postRequestBase64Conversion({}, `/numbering/international/address/proof`, `application/zip`)
            if (response && !response.message)
            {
                data = {
                    ...data,
                    success: true,
                    zippedDocuments: response
                }
            }
            setRecord(data)
            setLoading(false)
        }
        getZip()
    }, [])

    const {
        state,
        updateForm
    } = useFormReducer({ record })

    function defineData(data)
    {
        const postData = new FormData()
        postData.append(data.type + "-" + data.country, data.file)

        return {
            body: postData,
            path: `/numbering/international/address/${data.address}/proof/upload`
        }
    }

    useEffect(() =>
    {
        async function checkDocs()
        {
            setHasDocs(false)
            const response = await postRequest({}, `/numbering/international/address/proof/details/-1/-1`)
            if (response && response.records && response.records.length > 0)
            {
                setHasDocs(true)
            }
        }
        checkDocs()
    }, [])

    function getAddresses(): Promise<ISelectOption[]>
    {
        return new Promise(async (resolve) =>
        {
            let addressesSelect = [{}]
            const response = await postRequest({}, `/numbering/international/address/-1/-1`)
            if (response && response.records)
            {
                addressesSelect = response.records.map(record => ({
                    label: record.addressLine1 + " " + record.postcode,
                    value: record.id,
                }))
            }
            resolve(addressesSelect)
        })
    }

    function buildDisplay(response)
    {
        let failures = []
        let successes = []
        let multiResponse = false
        let message = ""
        let failureTitle = ""
        let successTitle = ""

        if (response && response.success)
        {
            message = "Documents added successfully"
        }
        else if (response && response.message)
        {
            message = response.message
        }
        else
        {
            message = "Something went wrong, please try again"
        }

        const feedback = {
            multiResponse: multiResponse,
            message: message,
            failures: failures,
            failureTitle: failureTitle,
            successTitle: successTitle,
            successes: successes,
        }

        return feedback
    }

    const fileUploadModal: IUploadFileModalSettings = {
        buttonProps: {
            variant: "success",
            iconLeft: "file",
            color: "white",
            iconVariation: "regular",
            children: "Upload Document",
        },
        multiResponse: false,
        customTitle:
            "Please upload a proof of address",
        sectionTitle: "Proof of Address",
        fields: [
            {
                type: "paragraph",
                value: "Proof needs to be link to an address."
            },
            {
                type: "select",
                label: "Address",
                name: "address",
                loadOptions: () => getAddresses(),
                required: true
            },
            {
                type: "select",
                label: "Country",
                name: "country",
                loadOptions: () => getInternationalCountryPromise(),
                required: true
            },
            {
                type: "select",
                label: "Type",
                name: "type",
                options: [
                    { label: "Driving Licence", value: "DrivingLicence" },
                    { label: "Enterprise Registration Certificate", value: "EnterpriseCertificate" },
                    { label: "Passport", value: "Passport" },
                    { label: "Goverment ID", value: "GovernmentID" },
                    { label: "Utility Bill", value: "UtilityBill" },
                ],
                required: true
            },
            {
                type: "fileUpload",
                name: "file",
                label: "Document",
                accept: ["application/pdf", "image/jpeg", "image/jpg", "image/png"],
                maxSizeBytes: 20 * 1000 * 1000, //20MB
                required: true
            },
        ],
        defineSubmitData: (data) =>
        {
            return new Promise(async (resolve) =>
            {
                const createData = await defineData(data)
                resolve(createData)
            })
        },
        displayResponse: (response) =>
        {
            return new Promise(async (resolve) =>
            {
                const createData = await buildDisplay(
                    response
                )
                resolve(createData)
            })
        },
    }

    useEffect(() =>
    {
        async function getRestrictions()
        {
            if (state.country)
            {
                let restrictionsObj = await getRequest(`/numbering/international/address/${state.country}/restrictions`)
                if (restrictionsObj && restrictionsObj.restrictions)
                {
                    let tempRestriction = [];
                    restrictionsObj.restrictions.forEach(restriction =>
                    {
                        tempRestriction.push({
                            type: "paragraph",
                            value: restriction
                        })
                    });
                    if (tempRestriction.length === 0)
                    {
                        tempRestriction.push({
                            type: "paragraph",
                            value: "No known restrictions on number for this country."
                        })
                    }
                    setRestrictions(tempRestriction)
                }
            }
        }
        getRestrictions()
    }, [state.country])

    const linkedNumberModalColumns: IColumn[] = [
        {
            name: "telephoneNumber",
            bold: true,
            title: "Number",
            link: { root: "telephone-numbers" },
            sortBy: true
        },
        {
            name: "nickname",
            hide: true,
            title: "Nickname",
            sortBy: true
        },
        {
            name: "destination",
            title: "Destination",
            sortBy: true
        },
        {
            name: "callRecord",
            title: "Call Recording",
            conditional: [
                { value: 0, text: "Not Enabled", icon: "microphone-slash" },
                { value: 1, text: "Enabled - Pausable", icon: "pause" },
                { value: 2, text: "Enabled - Not Pausable", icon: "microphone" },
            ]
        }
    ]


    function openModal(id)
    {
        setLinkedNumberModalLoading(true)
        setLinkedNumberId(id)
        setAddressModalOpen(true)
        new Promise(async () =>
        {
            let response = await getRequest(`/numbering/international/address/links/${id}/-1/-1`)
            let recordsArray = []
            let totalRecords = 0
            if (response && response.records)
            {
                totalRecords = response.totalRecords
                for (let record of response.records)
                {
                    let telephoneNumbersResponse = await getRequest(`/numbering/allocated/${record}`)
                    if (telephoneNumbersResponse)
                        recordsArray.push(telephoneNumbersResponse)
                }
            }
            setLinkedNumberModalRecords(recordsArray)
            setLinkedNumberModalLoading(false)
        })
    }

    function getNumbersForLinking() 
    {
        return new Promise<ISelectOption[]>(async (resolve) =>
        {
            let linkNumbers = [{}]
            const response = await postRequest({}, `/numbering/international/-1/-1`)
            if (response && response.records)
            {
                linkNumbers = response.records.map(record => ({
                    label: `${record.telephoneNumber} (${record.nickname !== null ? record.nickname : "No Name"})`,
                    value: record.id,
                }))
            }
            resolve(linkNumbers)
        })
    }

    const addLinkedNumberModal: IModalSettings = {
        settingsStages: [{
            title: "Link a number to this address",
            fields: [
                {
                    type: "select",
                    name: "number",
                    label: "Number",
                    loadOptions: getNumbersForLinking
                },
            ],
        },],
        submitData: (data) =>
        {
            return [
                {
                    postData: { "allocatedNumberId": data.number },
                    path: `/numbering/international/address/links/${linkedNumberId}`
                },
            ]
        },
    }

    const linkedNumberModal = {
        columns: linkedNumberModalColumns,
        records: linkedNumberModalRecords,
        loading: linkedNumberModalLoading,
    }
    const tabs: ITab[] = [
        {
            title: "Documentation",
            icon: "file-alt",
            disabled: false,
            sections: [
                {
                    fields: [
                        {
                            type: "paragraph",
                            value: "To see the numbers linked to an international address, and to add more, please click the title of the record in the table below."
                        }
                    ]
                },
                {
                    fields: [
                        {
                            type: "download",
                            label: "Download All Documents",
                            value: state.zippedDocuments,
                            fileName: "InternationalAddressDocuments",
                            disabled: !hasDocs
                        }
                    ]
                },
                {
                    title: "Addresses",
                    fullWidth: true,
                    fields: [
                        {
                            type: "table",
                            columns: [
                                {
                                    name: "title",
                                    title: "Title",
                                    bold: true,
                                    onClick: (id) => openModal(id)
                                },
                                {
                                    name: "firstname",
                                    title: "First Name",
                                },
                                {
                                    name: "lastname",
                                    title: "Last Name"
                                },
                                {
                                    name: "addressLine1",
                                    title: "Address Line 1"
                                },
                                {
                                    name: "addressLine2",
                                    title: "Address Line 2"
                                },
                                {
                                    name: "county",
                                    title: "County/Area"
                                },
                                {
                                    name: "country",
                                    title: "Country"
                                },
                                {
                                    name: "postcode",
                                    title: "Postal code"
                                },
                            ],
                            restType: "POST",
                            body: {},
                            urlString: `/numbering/international/address`,
                            addModal: {
                                displayName: "Address",
                                allowBulkAdd: false,
                                settingsStages: [
                                    {
                                        title: "Address",
                                        fields: [
                                            {
                                                type: "text",
                                                label: "Title",
                                                name: "title",
                                                required: true,
                                            },
                                            {
                                                type: "text",
                                                label: "First Name",
                                                name: "firstname",
                                                required: true
                                            },
                                            {
                                                type: "text",
                                                label: "Last Name",
                                                name: "lastname",
                                                required: true
                                            },
                                            {
                                                type: "text",
                                                label: "Address Line 1",
                                                name: "addressLine1",
                                                required: true
                                            },
                                            {
                                                type: "text",
                                                label: "Address Line 2",
                                                name: "addressLine2",
                                            },
                                            {
                                                type: "text",
                                                label: "County/Area",
                                                name: "county",
                                                required: true
                                            },
                                            {
                                                type: "text",
                                                label: "Country",
                                                name: "country",
                                                required: true
                                            },
                                            {
                                                type: "text",
                                                label: "Postal/Zip Code",
                                                name: "postcode",
                                                required: true
                                            },
                                        ],
                                    },
                                ],
                                submitData: (data) =>
                                {
                                    return [{
                                        postData: data,
                                        path: "/numbering/international/address",
                                        type: "create",
                                    }]
                                },
                            }
                        }
                    ]
                },
                {
                    title: "Documents",
                    fullWidth: true,
                    fields: [
                        {
                            type: "table",
                            uploadFileModal: fileUploadModal,
                            columns: [
                                {
                                    name: "filename",
                                    title: "Filename",
                                },
                                {
                                    name: "size",
                                    title: "Size",
                                    type: "bytes"
                                },
                            ],
                            restType: "POST",
                            body: {},
                            urlString: `/numbering/international/address/proof/details`,
                        },
                    ],
                },

            ]
        },
        {
            title: "International Restrictions",
            icon: "phone-slash",
            disabled: false,
            sections: [
                {
                    title: "Country",
                    fullWidth: true,
                    fields: [
                        {
                            type: "select",
                            label: "Country",
                            name: "country",
                            value: state.country,
                            onChange: updateForm,
                            loadOptions: () => getInternationalCountryPromise(),
                        },
                    ],
                },
                {
                    title: "Restrictions",
                    fullWidth: true,
                    fields: [
                        ...restrictions
                    ]
                },
            ],
        }]

    return {
        tabs,
        state,
        updateForm,
        loading,
        addressModalOpen,
        setAddressModalOpen,
        linkedNumberModal,
        addLinkedNumberModal,
    }
}