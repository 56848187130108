import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage";
import React, { useContext, useState } from "react";
import { SessionContext } from "../../Contexts/SessionContext";
import { hasPermission, permissions } from "../../Constants/permissions";
import useAPIRequests from "../../Hooks/useAPIRequests";
import DirectDebitFormModal from "./DirectDebitFormModal";
import TopUpFormModal from "./TopUpFormModal";

export default function AccountTransactionsIndexPage()
{
    const { state: session } = useContext(SessionContext)
    const { getMonths } = useAPIRequests()
    const [topUpModalOpen, setTopUpModalOpen] = useState(false)

    const columns: IColumn[] = [
        {
            name: "date",
            title: "Date",
            type: "date",
            sortBy: true,
        },
        {
            name: "time",
            title: "Time",
        },
        {
            name: "description",
            title: "Description"
        },
        {
            name: "debit",
            title: "Debit",
            type: "money",
            conditional: [
                { value: 0, text: "-" }
            ],
            sortBy: true,
        },
        {
            name: "credit",
            title: "Credit",
            type: "money",
            conditional: [
                { value: 0, text: "-" }
            ],
            sortBy: true,
        },
        {
            name: "balance",
            type: "money",
            title: "Balance"
        }
    ]

    const customActions: IButton[] = []

    {
        customActions.push({
            variant: "warning",
            iconLeft: "money-bill-alt",
            color: "white",
            iconVariation: "regular",
            children: "Top Up",
            onClick: () => setTopUpModalOpen(true)
        })
    }

    const filters: IFilterDropdown = {
        filters: [{
            label: "Month",
            name: "month",
            type: "select",
            loadOptions: () => getMonths(12)
        }]
    }

    return (
        <React.Fragment>
            <GenericIndexPage
                title={"Account Transactions"}
                urlString={"/account/transaction"}
                restType={"POST"}
                columns={columns}
                filters={filters}
                searchable={true}
                customActions={customActions}
            />
            {topUpModalOpen && <TopUpFormModal open={topUpModalOpen} setOpen={setTopUpModalOpen} />}
        </React.Fragment>
    )
}