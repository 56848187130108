import React, {useContext} from "react";
import {ToastContext} from "../../Contexts/ToastContext";
import {ToastBody} from "./ToastBody";

export function ToastContainer() {
    const {toasts, remove} = useContext(ToastContext);

    return (
        <div
            style={{position: "fixed", bottom: 0, right: 16, zIndex: 99999}}
        >
            {toasts.map(toast => (
                <ToastBody
                    key={toast.id}
                    text={toast.text}
                    type={toast.type}
                    onDismiss={() =>
                        remove(toast.id)
                    }
                    timeout={toast.timeout}
                />
            ))}
        </div>
    )
}