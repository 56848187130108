import { useEffect, useState } from "react"
import { useRequest } from "../../Hooks/useRequest"
import { useFormReducer } from "../../Hooks/useFormReducer"

export function useCustomPromptsFormPage(id: number)
{
    const [record, setRecord] = useState({})
    const [loading, setLoading] = useState(true)
    const { getRequest } = useRequest()

    const columns: IColumn[] = [
        {
            name: "table",
            title: "Type",
        },
        {
            name: "name",
            title: "Name",
        },
    ]

    useEffect(() =>
    {
        async function pullData()
        {
            setLoading(true)
            let data = {}

            const customPromptResponse = await getRequest(`/prompt/${id}`)

            if (customPromptResponse)
            {
                data = {
                    ...data,
                    customPrompt: customPromptResponse,
                }
            }

            setRecord(data)
            setLoading(false)
        }

        pullData()
        // eslint-disable-next-line
    }, [id])

    const {
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
    } = useFormReducer({ record })

    function submitData()
    {
        return [
            {
                body: {
                    name: state.customPrompt.name
                },
                path: "/prompt/" + id,
                restType: "PUT"
            },
        ]
    }

    function deleteData()
    {
        return [{
            path: "/prompt",
            type: "delete",
            indexPagePath: "/custom-prompts",
            displayName: "Custom Prompt"
        }]
    }

    const tabs: ITab[] = [
        {
            title: "General",
            icon: "toolbox",
            disabled: false,
            sections: [
                {
                    title: "Overview",
                    fullWidth: true,
                    fields: [
                        {
                            halfWidth: true,
                            label: "Name",
                            type: "text",
                            name: "customPrompt.name",
                            maxLength: 100,
                        },
                    ],
                },
                {
                    title: "Usage",
                    fullWidth: true,
                    fields: [
                        {
                            type: "table", 
                            customPaginationName: "promptUsages",
                            columns: columns,
                            restType: "GET",
                            urlString: `/prompt/${id}/usage`,
                        },
                    ],
                },
            ],
        },
    ]

    return {
        loading,
        tabs,
        state,
        updateState,
        updateForm,
        updateErrors,
        submitData,
        deleteData,
        // changes,
        // resetChanges,
    }
}
