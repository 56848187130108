import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage";
import React from "react";
import { permissions } from "../../Constants/permissions";

export default function PickUpGroupsIndexPage()
{

    const columns: IColumn[] = [
        {
            title: "Group",
            name: "extension",
            link: { root: "/pickup-groups" },
            bold: true,
            sortBy: true,
        },
        {
            title: "Name",
            name: "desc",
            sortBy: true,
        },
    ]

    const modalSettings: ISettingsStage[] = [
        {
            title: "Settings",
            fields: [
                {
                    type: "text",
                    name: "name",
                    label: "Name",
                    maxLength: 100,
                },
            ]
        }
    ]

    return (
        <GenericIndexPage
            title={"Pick Up Groups"}
            urlString={"/pickupgroups"}
            columns={columns}
            restType={"POST"}
            searchable={true}
            permissions={[permissions.pickupGroups, permissions.admin, permissions.VHAdmin]}
            addModal={{
                displayName: "Pickup Group",
                settingsStages: modalSettings,
                submitData: (data) =>
                {
                    return [{
                        postData: { "name": data.name },
                        path: "/pickupgroups",
                        type: "create",
                    }]
                }
            }}
            deleteModal={{
                displayName: "Pickup Group",
                submitData: () =>
                {
                    return [{
                        path: `/pickupgroups`,
                        type: "delete"
                    }]
                },
                uniqueIdentifier: "grouppickupId"
            }}
            helpSections={[
                {
                    title: "Pickup Groups",
                    text: "Allows for call interception for a group of phones. Adding a seat to pickup group will have its ringing calls intercepted."
                }
            ]}
        />
    )
}