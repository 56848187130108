import React from "react"
import PageLayout from "../../components/PageLayout/PageLayout"
import ErrorPageText from "../../components/ErrorPageText/ErrorPageText";

export default function PageNotFoundPage() {
    return (
        <PageLayout noBreadcrumbs>
            <ErrorPageText
                error={"404"}
                text={"This page could not be found"}

                link={
                    {
                        text: "Take me back to Dashboard",
                        url: "/"
                    }
                }
            />
        </ PageLayout>
    )
}
