import React, { useContext, useState, useEffect } from "react";
import StagedForm from "../../components/Form/StagedForm";
import Modal from "../../components/Modal/Modal";

export default function TopUpFormModal({ open, setOpen })
{
    const stripe_status = new URLSearchParams(window.location.search).get(
        "redirect_status"
    );

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            maxWidth={800}
        >
            <StagedForm
                onComplete={() => setOpen(false)}
                modal
                stages={
                    [
                        {
                            title: "Feedback",
                            fields: [
                                {
                                    type: "paragraph",
                                    value: stripe_status == "succeeded" ?
                                        `Your card payment is being processed.` :
                                        "Your card payment was unsuccessful, please try again. If the issue persists," +
                                        " please contact support."
                                }
                            ]
                        }
                    ].filter(Boolean)
                }
            />
        </Modal>
    )
}