import { Slider as SliderComp, withStyles, createMuiTheme } from "@material-ui/core"
import React, { useEffect, useState, useContext } from "react"
import { ThemeContext } from "../../Contexts/ThemeContext"
import { ThemeProvider } from "@material-ui/styles";


export default function Slider({
    value,
    onChange,
    onChangeCommitted,
    max,
    min = 0,
    step,
    name,
    showDroplet = false,
    style,
}: ISlider)
{
    const { accentColour } = useContext(ThemeContext)
    function valuetext(value: number)
    {
        return `${value}`
    }

    const materialTheme = createMuiTheme({
        overrides: {
            MuiSlider: {
                root: {
                    color: accentColour,
                    height: 8,
                },
                thumb: {
                    height: 24,
                    width: 24,
                    backgroundColor: "#fff",
                    border: "2px solid currentColor",
                    marginTop: -8,
                    marginLeft: -12,
                    "&:focus,&:hover,&$active": {
                        boxShadow: "inherit",
                    },
                },
                valueLabel: {
                    left: "calc(-50% + 4px)",
                    fontSize: "0.6em",
                },
                track: {
                    height: 8,
                    borderRadius: 4,
                },
                rail: {
                    height: 8,
                    borderRadius: 4,
                },
            }
        }
    });

    const [val, setVal] = useState(1)

    useEffect(() =>
    {
        setVal(value)
    }, [value])

    function handleChange(event: any, value: number | number[])
    {
        if (onChange)
        {
            onChange({ target: { name, value, type: "slider" } })
        }
        setVal(value as number)
    }

    function handleChangeCommit(event: any, value: number | number[])
    {
        setVal(value as number)
        if (onChangeCommitted)
        {
            onChangeCommitted({ target: { name, value, type: "slider" } })
        }
    }

    return (
        <ThemeProvider theme={materialTheme}>

            <SliderComp
                style={style}
                value={val}
                getAriaValueText={showDroplet && valuetext}
                onChange={handleChange}
                onChangeCommitted={handleChangeCommit}
                aria-labelledby="discrete-slider"
                valueLabelDisplay={showDroplet ? "auto" : "off"}
                step={step}
                min={min}
                max={max}
            />
        </ThemeProvider>
    )
}
