import React from "react"
import { TabbedCardFormPage } from "../../components/Form/TabbedCardFormPage";
import { useCallMonitoringFormPage } from "./useCallMonitoringFormPage";
import { permissions } from "../../Constants/permissions";

export default function CallMonitoringFormPage({ prop }: any)
{
    const { id } = prop.match.params

    const {
        loading,
        tabs,
        state,
        updateForm,
        updateErrors,
        submitData,
        // changes,
        // resetChanges,
    } = useCallMonitoringFormPage(id)

    return (
        <React.Fragment>
            <TabbedCardFormPage
                configIdentifier={id}
                state={state}
                updateForm={updateForm}
                updateErrors={updateErrors}
                tabs={tabs}
                // changes={changes}
                // resetChanges={resetChanges}
                loading={loading}
                displayName={"Call Monitoring"}
                defineSubmitData={submitData}
                permissions={[permissions.spy, permissions.admin, permissions.VHAdmin]}
            />
        </React.Fragment>
    )
}
