import React, { useState } from "react"
import { TabbedCardFormPage } from "../../components/Form/TabbedCardFormPage"
import { useTariffFormPage } from "./useTariffFormPage"

export default function TariffFormPage()
{
    const {
        loading,
        tabs,
        state,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
    } = useTariffFormPage()

    return (
        <React.Fragment>
            <TabbedCardFormPage
                configIdentifier={state.name}
                state={state}
                updateForm={updateForm}
                updateErrors={updateErrors}
                // changes={changes}
                // resetChanges={resetChanges}
                tabs={tabs}
                loading={loading}
            />
        </React.Fragment>
    )
}
