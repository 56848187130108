import { useEffect, useState, useContext } from "react"
import { useRequest } from "../../Hooks/useRequest"
import { useFormReducer } from "../../Hooks/useFormReducer"
import { hasPermission, permissions } from "../../Constants/permissions"
import { SessionContext } from "../../Contexts/SessionContext"
import { regex } from "../../Constants/regex"
import { regexError } from "../../Constants/regexError"
import useAPIRequests from "../../Hooks/useAPIRequests"
import { ToastContext } from "../../Contexts/ToastContext"
import { sipconvergenceReformatter } from "../../Common/urlHelpers"

export function useSIPTrunksFormPage(id: number)
{
    const [record, setRecord] = useState({})
    const [loading, setLoading] = useState(true)
    const { getRequest, postRequest } = useRequest()
    const { add } = useContext(ToastContext)

    const {
        getDestinationPromise,
        getMSTeamsPromise,
        getUnrestrictedCountriesPromise
    } = useAPIRequests()

    const {
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
    } = useFormReducer({ record })

    const { state: sessionState } = useContext(SessionContext)

    useEffect(() =>
    {
        if (state.host === "" || state.host === null || state.host === "dynamic")
        {
            updateForm({ target: { name: "host", value: "dynamic" } })
            updateForm({
                target: {
                    type: "switch",
                    name: "trunkIPAuth",
                    value: false
                }
            })
        }
    }, [state.host])

    function getCallerIDs(): Promise<ISelectOption[]>
    {
        return new Promise(async (resolve) =>
        {
            let response = await postRequest({}, `/numbering/allocated/callerids`)
            if (response && response.records)
            {
                const callerIds = response.records.map((callerId) => ({
                    label: callerId.name,
                    value: callerId.number,
                }))

                resolve(callerIds)
            }
        })
    }

    function enableTeams()
    {
        if (state && state.teams && state.teams.email && regex.email.test(state.teams.email))
        {
            return new Promise(async (resolve) =>
            {
                let response = await postRequest(
                    {
                        email: state.teams.email,
                        accountNo: state.accountNo
                    },
                    `/teams/admin/invitation`
                )
                if (response && response.success)
                {
                    add({
                        text: "Admin invitation sent",
                        type: "success",
                    })
                }
                else
                {
                    add({
                        text: "Unable to enable teams, please contact support",
                        type: "error",
                    })
                }
                resolve(true)
            })
        }
        else
        {
            add({
                text: "Please enter a valid email to enable teams",
                type: "error",
            })
        }
    }

    function createTeamsAccount()
    {
        return new Promise(async (resolve) =>
        {
            let response = await postRequest(
                {

                },
                `/teams/${state.accountNo}/create`
            )
            if (response && response.success)
            {
                add({
                    text: "Teams account created",
                    type: "success",
                })
            }
            else
            {
                add({
                    text: "Unable to create teams account, please contact support",
                    type: "error",
                })
            }
            id = id
            resolve(true)
        })
    }

    function getWeekendAndWeekday(seat)
    {
        let weekday
        let weekend

        if (seat.callRestrictions.weekday.allow)
        {
            let startTime = seat.callRestrictions.weekday.times[0]

            let endTime = seat.callRestrictions.weekday.times[1]

            if (startTime !== "00:00" || endTime !== "23:59")
            {
                if (startTime[0] === "0")
                {
                    startTime = startTime.substring(1)
                }
                if (endTime[0] === "0")
                {
                    endTime = endTime.substring(1)
                }
                weekday = `${startTime}-${endTime}`
            } else
            {
                weekday = `on`
            }
        } else
        {
            weekday = "off"
        }

        if (seat.callRestrictions.weekend.allow)
        {
            let startTime = seat.callRestrictions.weekend.times[0]

            let endTime = seat.callRestrictions.weekend.times[1]

            if (startTime !== "00:00" || endTime !== "23:59")
            {
                if (endTime[0] === "0")
                {
                    startTime = startTime.substring(1)
                }
                if (endTime[0] === "0")
                {
                    endTime = endTime.substring(1)
                }

                weekend = `${startTime}-${endTime}`
            } else
            {
                weekend = `on`
            }
        } else
        {
            weekend = "off"
        }

        return { weekday, weekend }
    }

    function submitData()
    {
        const { weekend, weekday } = getWeekendAndWeekday(state)

        let cpCLI = state.options.cpCLI

        if (state.anyPresentation && state.anyPresentation === true)
        {
            cpCLI = "yes"
        }
        else if (state.options.cpCLI === "yes")
        {
            cpCLI = "no"
        }

        return [
            {
                body: {
                    ...state,
                    trunkFailOver: state.trunkFailOver === "none" ? null : state.trunkFailOver,
                    options: {
                        ...state.options,
                        cpCLI: cpCLI,
                        weekdayTimes: weekday,
                        weekendTimes: weekend,
                    },
                },
                path: "/trunk/" + id,
            },
        ]
    }

    function deleteData()
    {
        return [{
            path: "/trunk",
            type: "delete",
            indexPagePath: "/sip",
            displayName: "SIP Trunk"
        }]
    }

    async function getAccessLists(): Promise<ISelectOption[]>
    {
        return new Promise(async (resolve) =>
        {
            let response = await postRequest({}, `/accesscontrol/-1/-1`)
            if (response && response.records)
            {
                const failoverDestinations = response.records.map(
                    (destination) => ({
                        label: destination.name,
                        value: String(destination.id),
                    })
                )

                resolve(failoverDestinations)
            }
        })
    }

    async function getEMSAddresses(): Promise<ISelectOption[]>
    {
        const emsResponse = await postRequest({}, `/emsaddress`)
        if (emsResponse && emsResponse.records)
        {
            return new Promise((resolve) =>
            {
                const formattedEMSAddresses = emsResponse.records.map(
                    (ems) => ({
                        label: `${ems.premises}, ${ems.thoroughfare ? `${ems.thoroughfare}, ` : ""
                            }${ems.locality}, ${ems.postcode}, (${formatNum(ems.telephoneNo)})`,
                        value: ems.telephoneNo,
                    })
                )
                resolve(formattedEMSAddresses)
            })
        }
    }

    function formatNum(num: String)
    {
        if (num.startsWith("44"))
        {
            num = num.replace("44", "0")
        }
        return num
    }

    useEffect(() =>
    {
        function extractSetVarTimes(value)
        {
            const timeRegex = /([0-9]{1,2}:[0-9]{1,2})-([0-9]{1,2}:[0-9]{1,2})/g

            const matches = timeRegex.exec(value)

            if (matches)
            {
                let startTime = matches[1]
                if (startTime.length === 4)
                {
                    startTime = "0" + startTime
                }
                let endTime = matches[2]
                if (endTime.length === 4)
                {
                    endTime = "0" + endTime
                }
                return [startTime, endTime]
            } else
            {
                return ["00:00", "23:59"]
            }
        }

        async function pullData()
        {
            setLoading(true)
            let data: any = {}

            let response = await getRequest(`/trunk/${id}`)

            if (response)
            {
                response.host = response.host ? response.host : "dynamic"

                if (response.host === "dynamic")
                {
                    response.status = response.status ? "Registered (" + response.server + ")" : "Unregistered"
                }
                else
                {
                    response.status = "Direct IP"
                }

                if (response.options.cpCLI === "yes")
                {
                    response.anyPresentation = true;
                }
                else
                {
                    response.anyPresentation = false;
                }

                data = {
                    ...response,
                    trunkName: response.name,
                    cliNumber: response.cliNumber ? response.cliNumber : "set by agent",
                    textHost: response.host ? response.host : "",
                    trunkFailOver: response.trunkFailOver ? response.trunkFailOver : "none",
                    transport: response.transport ? response.transport : "UDP",
                    callRestrictions: {
                        weekday: {
                            allow: response.options.weekdayTimes !== "off",
                            times: extractSetVarTimes(
                                response.options.weekdayTimes
                            ),
                        },
                        weekend: {
                            allow: response.options.weekendTimes !== "off",
                            times: extractSetVarTimes(
                                response.options.weekendTimes
                            ),
                        },
                    },
                    enableTeams: response.enableTeams,
                    options: {
                        ...response.options,
                        acl: response.options && response.options.acl ? response.options.acl : "0",
                        ctps: response.options && response.options.ctps ? response.options.ctps : "off",
                        tps: response.options && response.options.tps ? response.options.tps : "off"
                    }
                }
            }

            const teamsResponse = await getRequest(`/teams/exists/${id}`)
            if (teamsResponse)
            {
                data = {
                    ...data,
                    teams: {
                        teamsAccountExists: teamsResponse.teamsAccountExists
                    }
                }
            }
            setRecord(data)
            setLoading(false)
        }

        pullData()
        // eslint-disable-next-line
    }, [id])

    function regeneratePassword()
    {
        return new Promise(async (resolve) =>
        {
            let response = await getRequest(`/seat/${id}/password/reset`)
            if (response)
            {
                if (response.success)
                {
                    updateForm({
                        target: {
                            name: "secret",
                            value: response.message,
                        },
                    })
                } else
                {
                    add({
                        text: "Something went wrong, please try again.",
                        type: "error",
                    })
                }
            }
            resolve(true)
        })
    }

    const tabs: ITab[] = [
        {
            title: "General",
            icon: "toolbox",
            disabled: false,
            sections: [
                {
                    title: "Information",
                    spanTwoRows: true,
                    fields: [
                        {
                            type: "info",
                            label: "SIP Trunk",
                            name: "trunkName",
                        },
                        {
                            type: "info",
                            label: "Status",
                            name: "status",
                            conditional: [
                                { value: false, text: "Unregistered" },
                                { value: true, text: "Registered" },
                            ],
                            helptext:
                                "PBX's that use SIP Registration to identify their location will show as 'Registered' when they have successfully requested and authenticated via SIP /r/n " +
                                "If 'Direct IP' addressing is used the status will show as 'Direct IP'",
                        },
                        {
                            type: "info",
                            label: "Agent",
                            nullValue: "N/A",
                            name: "agent",
                        },
                        {
                            type: "info",
                            label: "IP Address",
                            name: "ip",
                            nullValue: "N/A",
                            helptext:
                                "IP Address - If the PBX registers, the IP address of the system is shown here. It is recommended that this IP address is explicitly set in the IP address tab",
                        },
                        {
                            type: "info",
                            label: "Network",
                            name: "network",
                        },
                        {
                            type: "info",
                            label: "Username",
                            name: "name",
                        },
                        {
                            type: "info",
                            label: "Password",
                            name: "secret",
                        },
                        {
                            type: "info",
                            label: "Host",
                            conditional: [
                                {
                                    value: "dynamic",
                                    text: sipconvergenceReformatter("st.voicehost.co.uk"),
                                },
                            ],
                            name: "textHost",
                        },
                        {
                            type: "text",
                            label: "Nickname",
                            name: "nickname",
                            helptext:
                                "The nickname allows you to tag the seat so you can identify who is using it or where it is located",
                            maxLength: 15
                        },
                        {
                            type: "button",
                            placeholder: "Regenerate Password",
                            onClick: regeneratePassword,
                        },
                    ],
                },
                {
                    title: "Concurrent Calls/Channels",
                    fields: [
                        {
                            type: "number",
                            name: "options.channelLimit",
                            min: 1,
                            max: hasPermission([permissions.retail], sessionState.session.permissions, "read")
                                && !hasPermission([permissions.VHAdmin], sessionState.session.permissions, "read")
                                ? 2 : 200,
                            label: "Number",
                            helptext: "Number of channels",
                        },
                    ],
                },
                {
                    title: "Caller Identification",
                    fields: [
                        {
                            type: "select",
                            name: "cliNumber",
                            label: "Number",
                            conditionallyDisabled: {
                                checks: [{
                                    field: "withholdNumber",
                                    value: true,
                                }]
                            },
                            loadOptions: getCallerIDs,
                            options: [{ label: "Set By Agent", value: "set by agent" }],
                            helptext:
                                "The 'Caller ID Number' is shown as your identity when making outgoing calls. \r\n" +
                                "This will allow the person receiving your call to see your telephone number on their phone's display \r\n" +
                                "This is dependant on network type and services on the end users line",
                        },
                        {
                            type: "switch",
                            label: "Withhold Caller ID",
                            name: "withholdNumber",
                        },
                        hasPermission([permissions.VHAdmin], sessionState.session.permissions, "read") && {
                            type: "switch",
                            name: "anyPresentation",
                            label: "Allow Any Number Presentation"
                        }
                    ],
                },
                {
                    title: "Inbound Failover",
                    fullWidth: true,
                    fields: [
                        {
                            type: "select",
                            label: "Destination",
                            name: "trunkFailOver",
                            options: [{ value: "none", label: "None" }],
                            loadOptions: () => getDestinationPromise({
                                highPriorityQueue: true,
                                extensionMap: false,
                                excludeObjectRegex: "^SIPTRUNK\\/" + state.name + "|SIPTRUNKMAP\\/" + state.name + "\\/[0-9]*$"
                            }),
                            helptext:
                                "If the SIP Trunk is offline or cannot be contacted, send the call to the set destination. \r\n" +
                                "If no destination is set a temporary unavailable signal is sent back to the caller. \r\n" +
                                "We recommend setting a registration period of 60 seconds to make sure the fail over happens as soon as the PBX goes offline",
                        },
                    ],
                },
                {
                    title: "Emergency Services",
                    fullWidth: true,
                    fields: [
                        {
                            type: "select",
                            label: "Location",
                            name: "emergencyServicesCid",
                            heightChange: 200,
                            loadOptions: getEMSAddresses,
                            helptext:
                                "The emergency services location option allows you to set the physical location of a phone for use when calling 999 or 112. This helps the emergency services locate you if you are unable to speak" +
                                " or otherwise unable to confirm your location. It is recommended that you update every phone with it's physical location as this could make the difference between life and death. \r\n" +
                                "If your physical address is not shown in the list, you must update your location by going to the 'Telephones Numbers' section and select the telephone number associated with the phone",
                        },
                    ],
                },
            ],
        },
        {
            title: "IP Address",
            icon: "map-marker-alt",
            disabled: false,
            sections: [
                {
                    title: "Direct IP",
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "Direct IP addressing allows a PBX to receive calls directly without the use of SIP registrations. To direct calls to a PBX, enter it's public IP address below:",
                        },
                        {
                            type: "text",
                            label: "IP Address",
                            name: "host",
                            conditional: [{ value: "dynamic", text: "" }],
                            helptext:
                                "Direct IP addressing allows a PBX to receive calls directly without the use of SIP registrations. \r\n" +
                                "Please note if your PBX tries to register and an IP address has been set, registrations will fail. \r\n" +
                                "The recommended setting would be to allow the PBX to register and therefore the Direct IP address to be left blank",
                            regex: regex.publicIpAddress,
                            regexError: regexError.publicIpAddress
                        },
                        hasPermission([permissions.VHAdmin], sessionState.session.permissions, "read") && {
                            type: "paragraph",
                            value:
                                "IP authentication allows outbound calls to be authenticated using the above direct IP address. The PBX is not required to supply a username and password per call.",
                        },
                        hasPermission([permissions.VHAdmin], sessionState.session.permissions, "read") && {
                            conditionallyDisabled: {
                                checks: [{
                                    field: "host",
                                    value: "dynamic"
                                }]
                            },
                            type: "switch",
                            label: "IP Authentication",
                            name: "trunkIPAuth",
                        },
                    ],
                },
                {
                    title: "Restrict to IP Address",
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "To increase security on a trunk please enter the IP addresses of your PBX below. (Maximum of 3). Any calls sent to us from alternate IP addresses will be denied.",
                        },
                        {
                            type: "multiText",
                            label: "IP Address",
                            name: "permit",
                            helptext:
                                "The 'restrict to IP address' feature allows calls to only originate from a single IP address. \r\n" +
                                "This increases security as any calls originating from any other IP address will be rejected. \r\n" +
                                "It is highly recommended that the IP address of the PBX is entered here.",
                            regex: regex.publicIpAddress,
                            regexError: regexError.publicIpAddress,
                            maxLength: 45,
                        },
                    ],
                },
            ],
        },
        {
            title: "Call Restrictions",
            icon: "minus-circle",
            disabled: !hasPermission([permissions.SIPTrunksCallRestrictions, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
            sections: [
                {
                    title: "Legal Notice",
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "These restrictions are provided to help reduce the risk of fraud, however do not guarantee to stop any fraudulent attacks originating from equipment connected to the service." +
                                " It remains your responsibility to secure any equipment connected to the network." +
                                " By lifting these restrictions you acknowledge the risk and therefore take responsibility for any miss use and charges that may be a consequences of doing so.",
                        },
                    ],
                },
                {
                    title: "Outbound Call Restrictions",
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "The following call restrictions do not affect emergency calls via the SIP Trunk.",
                        },
                        {
                            type: "switch",
                            label: "Allow UK Geographic",
                            name: "options.allowUKGeo",
                            helptext: "(01, 02 & 03)",
                            halfWidth: true,
                        },
                        {
                            type: "switch",
                            label: "Allow UK Mobile",
                            name: "options.allowUKMobile",
                            helptext: "(071 to 079)",
                            halfWidth: true,
                        },
                        {
                            type: "switch",
                            label: "Allow UK Directory",
                            name: "options.allowUK118",
                            helptext: "(118)",
                            halfWidth: true,
                        },
                        {
                            type: "switch",
                            label: "Allow Other UK Calls",
                            name: "options.allowUKOther",
                            helptext: "(08XX)",
                            halfWidth: true,
                        },
                        {
                            disabled: !hasPermission([permissions.highRisk, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                            type: "switch",
                            label: "Allow International Calls",
                            name: "options.allowInternational",
                            halfWidth: true,
                        },
                        {
                            conditionallyShow: {
                                checks: [{
                                    field: "options.allowInternational",
                                    value: true,
                                }]
                            },
                            disabled: !hasPermission([permissions.highRisk, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                            type: "switch",
                            label: "Allow High Risk Zone",
                            name: "options.allowInternationalHighRisk",
                            helptext:
                                "High Risk Zones are destinations that are considered to have high or extreme risks of telecommunications fraud based on current data",
                            halfWidth: true,
                        },
                        {
                            type: "switch",
                            label: "Allow UK Premium Calls (09)",
                            name: "options.allowUKPremium09",
                            halfWidth: true,
                        },
                        {
                            type: "switch",
                            label: "Allow UK Premium Calls (070)",
                            name: "options.allowUKPremium070",
                            halfWidth: true,
                        },
                        {
                            type: "select",
                            label: "Outbound Blacklist",
                            name: "options.acl",
                            options: [{ value: "0", label: "No Blacklist" }],
                            loadOptions: getAccessLists
                        },
                    ],
                },
                {
                    title: "Outbound Time Restrictions",
                    fields: [
                        {
                            type: "switch",
                            label: "Allow Weekday Calls",
                            name: "callRestrictions.weekday.allow",
                        },
                        {
                            conditionallyShow: {
                                checks: [{
                                    field: "callRestrictions.weekday.allow",
                                    value: true,
                                }]
                            },
                            type: "timeSlider",
                            label: "Between",
                            name: "callRestrictions.weekday.times",
                        },
                        {
                            type: "switch",
                            label: "Allow Weekend Calls",
                            name: "callRestrictions.weekend.allow",
                        },
                        {
                            conditionallyShow: {
                                checks: [{
                                    field: "callRestrictions.weekend.allow",
                                    value: true,
                                }]
                            },
                            type: "timeSlider",
                            label: "Between",
                            name: "callRestrictions.weekend.times",
                        },
                    ],
                },
                {
                    title: "Outbound International PIN",
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "Require a PIN to be entered when dialling an international destination. \"Allow International Calls\" must be selected to use this feature.",
                            fullWidth: true,
                        },
                        {
                            type: "text",
                            conditionallyDisabled: { checks: [{ field: "options.allowInternational", value: false }] },
                            label: "PIN",
                            name: "options.internationalPIN",
                            regex: regex.internationalPin,
                            regexError: regexError.internationalPin
                        },
                        {
                            type: "paragraph",
                            value:
                                "Restrict the IP location of the calls being made by this seat, to only allow outbound calls to progress from the countries selected below.",
                            fullWidth: true,
                        },
                        {
                            type: "select",
                            name: "options.ipAcl",
                            loadOptions: getUnrestrictedCountriesPromise,
                            multi: true,
                            label: "IP Location Restrictions",
                        }
                    ],
                },
            ],
        },
        {
            title: "Call Recording",
            icon: "microphone-alt",
            disabled: false,
            sections: [
                {
                    title: "Legal Notice",
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "If you monitor and record telephone calls you must comply with the Regulation of Investigatory Powers Act 2000 (RIPA), " +
                                "the Telecommunications (Lawful Business Practice)(Interception of Communications) Regulations 2000 (SI 2000/2699) (the LBP Regulations) and the Data Protection Act 1998.",
                        },
                    ],
                },
                {
                    title: "Outbound Call Recording",
                    fields: [
                        {
                            type: "select",
                            label: "Enabled",
                            name: "options.callRecording",
                            options: [
                                { value: "off", label: "None" },
                                { value: "pausable", label: "Enabled - Pausable" },
                                {
                                    value: "permanent",
                                    label: "Enabled - Non-Pausable",
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            title: "TPS/CTPS",
            icon: "user-shield",
            disabled: false,
            sections: [
                {
                    title: "Telephone Preference Service",
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "The Telephone Preference Service (TPS) is a list of consumers (including Sole Traders and Partnerships, except in Scotland) who have registered their wish not to receive unsolicited direct marketing calls. \r\n" +
                                "It is a legal requirement that companies do not make such calls to numbers registered on the TPS. " +
                                "Companies who are reported to the TPS for breach of the above regulation are included in a monthly report sent to the Information Commissioners Office (ICO) the body responsible for enforcement. \r\n" +
                                "The TPS service checks the TPS register each time a telephone number is dialled. " +
                                "If the telephone number appears on the register the caller is notified and depending on the selected option below, can at their discretion, choose to continue their call.",
                        },
                        {
                            type: "select",
                            label: "TPS Enabled",
                            name: "options.tps",
                            options: [
                                { value: "off", label: "Disabled" },
                                {
                                    value: "block",
                                    label: "Enabled (Block Calls)",
                                },
                                {
                                    value: "option",
                                    label: "Enabled (Override Option)",
                                },
                                {
                                    value: "signalsonly",
                                    label: "Enabled (SIP Signal Only)",
                                },
                            ],
                        },
                    ],
                },
                {
                    title: "Corporate Telephone Preference Service",
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "The Corporate Telephone Preference Service (CTPS) is a list of organisations " +
                                "(limited companies, public limited companies and Scottish partnerships) who have registered their wish not to receive unsolicited direct marketing calls. \r\n" +
                                "It is a legal requirement that companies do not make such calls to numbers registered on the CTPS. " +
                                "The original legislation was introduced in May 1999. It has subsequently been updated and now the relevant legislation is the Privacy and Electronic (EC Directive) Regulations 2004 (amended). " +
                                "Companies who are reported to the CTPS for breach of the above regulation are included in a monthly report sent to the Information Commissioners Office (ICO) the body responsible for enforcement.",
                        },
                        {
                            type: "select",
                            label: "Corporate TPS Enabled",
                            name: "options.ctps",
                            options: [
                                { value: "off", label: "Disabled" },
                                {
                                    value: "block",
                                    label: "Enabled (Block Calls)",
                                },
                                {
                                    value: "option",
                                    label: "Enabled (Override Option)",
                                },
                                {
                                    value: "signalsonly",
                                    label: "Enabled (SIP Signal Only)",
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            title: "Teams",
            icon: "users",
            sections: [
                {
                    conditionallyHide: { checks: [{ field: "teams.teamsAccountExists", value: false }] },
                    fields: [
                        {
                            type: "switch",
                            label: "Enable Teams",
                            name: "enableTeams"
                        },
                    ]
                },
                {
                    conditionallyHide: { checks: [{ field: "teams.teamsAccountExists", value: true }] },
                    fields: [
                        {
                            type: "paragraph",
                            value: "To enable Teams on this account, please enter the Office 365 admin account email and get them to register and follow the instructions below. You will not be able to enable Teams again until that happens."
                        },
                        {
                            type: "paragraph",
                            value: "Once the admin accepts the invitation they will need to navigate to the call2teams portal. Now click into your portal on the right hand side, "
                                + "then go into Services section. After that click on the Teams icon and lastly click the 'Enable Sync' button. "
                        },
                        {
                            type: "paragraph",
                            value: "Users will now be able to enable Teams for their seat or SIP trunk. Every time a user enables Teams you will have to sync their Office 365 account via the Teams portal. "
                                + "To do this log into the portal, go into the same services section but this time click on the trunk icon. Now find the appropriate trunk, "
                                + "finally select the user you want using the trunk."
                        },
                        {
                            label: "Admin Email",
                            type: "text",
                            name: "teams.email",
                            regex: regex.email,
                            regexError: regexError.email,
                            maxLength: 50
                        },
                        {
                            type: "button",
                            placeholder: "Send Admin Invite",
                            onClick: enableTeams
                        }
                    ]
                },
                {
                    conditionallyHide: { checks: [{ field: "teams.teamsAccountExists", value: true }] },
                    fields: [
                        {
                            type: "paragraph",
                            value: "By pressing this button you override the need to provide an admin email address for teams."
                                + " Please ensure the person who manages your office 365 account has access before pressing this."
                        },
                        {
                            type: "button",
                            placeholder: "Create Teams Account",
                            onClick: createTeamsAccount
                        }
                    ]
                }
            ]
        },
        {
            title: "Advanced",
            icon: "check",
            disabled: false,
            sections: [
                {
                    title: "Inbound Number Mapping",
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "The standard mapping format for inbound calls to a PBX is in E.164 format, for example '+44X'. " +
                                "However some PBX's can not accept the standard E.164 formatting for inbound calls. " +
                                "Therefore the following option allows the number to be sent to the PBX without the plus ('+') prefixed.",
                        },
                        {
                            type: "select",
                            name: "trunkNoPlus",
                            label: "Number Mapping Format",
                            options: [
                                { label: "E.164 - To Header - Plus  (e.g. +441XXXXXXXXX)", value: 0 },
                                { label: "E.164 - To Header - No Plus (e.g. 441XXXXXXXXX)", value: 1 },
                                { label: "E.164 - From/To Headers (e.g. +441XXXXXXXXX)", value: 2 },
                            ],
                            defaultValue: -1
                        },
                    ],
                },
                {
                    title: "Trunk Signaling",
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "By default the SIP Trunk plays prompts for busy, unavailable and incorrectly dialled calls. " +
                                "If you require the PBX to handle these call states and only receive the relevant signals via SIP, enable the following option.",
                        },
                        {
                            type: "switch",
                            label: "Only signal",
                            name: "options.sipSignalOnly",
                        },
                    ],
                },
                {
                    title: "Trunk Network Transport",
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "The default transport for SIP Trunks is UDP, some PBXs such as Microsoft Lync require TCP trunks." +
                                " To enable TCP connections, select the transport option you require from below:",
                        },
                        {
                            type: "paragraph",
                            value:
                                "The use of TLS is a chargeable service, please check your services tariff " +
                                "for charges.",
                        },
                        {
                            type: "select",
                            label: "Transport",
                            name: "transport",
                            options: [
                                { value: "udp", label: "UDP" },
                                { value: "tcp", label: "TCP" },
                                { value: "tls", label: "TLS" },
                            ],
                        },
                        {
                            type: "paragraph",
                            conditionallyShow: {
                                checks: [{
                                    field: "transport",
                                    value: "tls",
                                }]
                            },
                            value: "In order to use TLS, change the host the PBX uses to: " + sipconvergenceReformatter("secure.st.voicehost.co.uk")
                        }
                    ],
                },
            ],
        },
    ]

    return {
        loading,
        tabs,
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        submitData,
        deleteData,
        // resetChanges,
    }
}
