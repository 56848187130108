import React, { useContext, useEffect, useState } from "react"
import { Pagination as PaginationComp } from "react-bootstrap"
import Cookies from 'universal-cookie'
import { ThemeContext } from "../../Contexts/ThemeContext"
import Select from "../FormInputs/Select"
import "./table.scss"

interface IProps
{
    loading?: boolean
    offset: number
    setOffset: (val: number) => void
    limit: number
    setLimit: (val: number) => void
    totalRecords: number
    records: { [key: string]: any }[]
    hideTotalRecords?: boolean
    customPaginationName?: string
}

export default function Pagination({
    loading,
    offset,
    setOffset,
    limit,
    setLimit,
    totalRecords,
    records,
    hideTotalRecords,
    customPaginationName
}: IProps)
{
    const { primaryColour } = useContext(ThemeContext)
    const [totalPages, setTotalPages] = useState<number>(1)
    const [displayAll, setDisplayAll] = useState(true)
    const [allowEitherSide, setAllowEitherSide] = useState(2)
    const [paginationButtons, setPaginationButtons] = useState([])

    function handlePageChange(direction)
    {
        if (direction === "down" && offset > 0)
        {
            setOffset(offset - limit)
        }
        if (direction === "up" && offset + limit < totalRecords)
        {
            setOffset(offset + limit)
        }
    }

    useEffect(() =>
    {
        let tempTotalPages = totalRecords / limit
        if (tempTotalPages % 1 !== 0 && totalRecords !== limit)
        {
            tempTotalPages += 1
        }
        setTotalPages(Math.trunc(tempTotalPages))
        if (tempTotalPages > 5)
        {
            setDisplayAll(false)
        }
    }, [totalRecords, limit])

    function updateSession(val)
    {
        let name
        if (customPaginationName)
        {
            name = `pagination/${customPaginationName}`
        }
        else
        {
            name = `pagination${window.location.pathname}`
        }
        localStorage.setItem(name, val);
    }

    useEffect(() =>
    {
        const newCurrentPage = offset / limit + 1

        if (totalPages)
        {
            const paginationButtons = Array.from(Array(totalPages).keys()).map(
                (number) =>
                {
                    let display = true
                    const actualNumber = number + 1
                    let content: any = actualNumber
                    if (!displayAll)
                    {
                        if (actualNumber !== newCurrentPage)
                        {
                            // before current page
                            if (
                                newCurrentPage - actualNumber >
                                allowEitherSide
                            )
                            {
                                if (
                                    newCurrentPage - actualNumber ===
                                    allowEitherSide + 1 &&
                                    actualNumber !== 1
                                )
                                {
                                    content = "..."
                                } else
                                {
                                    if (actualNumber !== 1)
                                    {
                                        display = false
                                    }
                                }
                            }

                            // past current page
                            if (
                                actualNumber - newCurrentPage >
                                allowEitherSide
                            )
                            {
                                if (
                                    actualNumber - newCurrentPage ===
                                    allowEitherSide + 1 &&
                                    actualNumber !== totalPages
                                )
                                {
                                    content = "..."
                                } else
                                {
                                    if (actualNumber !== totalPages)
                                    {
                                        display = false
                                    }
                                }
                            }
                        }
                    }

                    if (display)
                    {
                        return {
                            actualNumber,
                            active: actualNumber === newCurrentPage,
                            onClick: () => setOffset(number * limit),
                            content: content,
                        }
                    } else
                    {
                        return false
                    }
                }
            )
            setPaginationButtons(paginationButtons.filter(Boolean))
        }
    }, [offset, totalPages])

    return (
        <div className="pagination-container">
            {!loading && records && records.length > 0 && (
                <React.Fragment>
                    <div
                        className={"paginationQuantityBox"}
                        style={{
                            display: "flex",
                            flex: 2,
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <div
                            className={"paginationRecordNoBox"}
                            style={{
                                flex: 1,
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            Show
                            <div>
                                <Select
                                    value={Number(limit)}
                                    onChange={(e) =>
                                    {
                                        updateSession(e.target.value)
                                        setLimit(e.target.value)
                                        setOffset(0)
                                    }}
                                    options={[
                                        { label: "5", value: 5 },
                                        { label: "10", value: 10 },
                                        { label: "15", value: 15 },
                                        { label: "20", value: 20 },
                                        { label: "25", value: 25 },
                                        { label: "30", value: 30 },
                                        { label: "35", value: 35 },
                                        { label: "40", value: 40 },
                                        { label: "50", value: 50 },
                                    ]}
                                    style={{
                                        width: 75,
                                        marginLeft: 8,
                                        marginRight: 8,
                                    }}
                                />
                            </div>
                            Records
                        </div>

                        <p
                            style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            Showing {Number(offset) + 1} to {Number(offset) + Number(records.length)} {!hideTotalRecords ? `of ${totalRecords} entries` : ""}
                        </p>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-end",
                        }}
                    >
                        <PaginationComp style={{ margin: 0 }}>
                            <PaginationComp.Prev
                                disabled={offset - limit < 0}
                                onClick={() => handlePageChange("down")}
                            />

                            {!hideTotalRecords && paginationButtons.map((paginationButton) => (
                                <PaginationComp.Item
                                    style={{ backgroundColor: primaryColour, borderColor: primaryColour, color: "green" }}
                                    key={paginationButton.actualNumber}
                                    active={paginationButton.active}
                                    onClick={paginationButton.onClick}
                                >
                                    {paginationButton.content}
                                </PaginationComp.Item>
                            ))}

                            <PaginationComp.Next
                                disabled={offset + limit >= totalRecords}
                                onClick={() => handlePageChange("up")}
                            />
                        </PaginationComp>
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}
