import React, { useContext, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { ToastContext } from "../../Contexts/ToastContext";
import { useRequest } from "../../Hooks/useRequest";
import Button from "../Button";

export function CSVDownload({
    url,
    restType,
    postData,
    filename,
    displayName,
    buttonStyle,
    disabled,
}: ICSVDownload)
{
    const { add } = useContext(ToastContext)

    const csvLink = useRef<CSVLink>(null)

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<String | Array<String> | Array<Object>>("")
    const { getRequestAsString, postRequestAsString } = useRequest()

    async function fetchData()
    {
        setLoading(true)
        let response: String | Array<String> | Array<Object>
        if (restType === "get")
        {
            response = await getRequestAsString(url)
        }
        else if (restType === "post")
        {
            response = await postRequestAsString(postData, url)
        }
        if (response)
        {
            setData(response)
            csvLink.current.link.click()
        }
        else
        {
            add({
                type: "error",
                text: "There was an error while attempt to get your file. Please try again or contact support."
            })
        }
        setLoading(false)
    }

    return (
        <>
            <Button
                variant={"primary"}
                iconLeft={"save"}
                color={"white"}
                iconVariation={"regular"}
                size="sm"
                style={buttonStyle}
                disabled={disabled || loading}
                onClick={fetchData}
                loading={loading}
            >
                {displayName}
            </Button>
            <CSVLink
                filename={`${filename}.csv`}
                data={data}
                ref={csvLink}
                className="hidden"
                target="_blank"
            >
            </CSVLink>
        </>
    )
}