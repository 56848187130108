import moment from "moment";
import React, { useEffect, useImperativeHandle, useReducer, useRef, useState } from "react";
import { useRequest } from "../../Hooks/useRequest";
import Cookies from 'universal-cookie'
import { useHistory, useLocation } from "react-router-dom";

/**
 *
 * @param title Title of the page
 * @param urlString getRequest uses urlString as "getRequest(`${urlString}/${state.offset}/${state.limit}`)"
 * @param columns [{}] of columns
 */
export function useGenericIndexPage(restType, urlString, body, filters, ref, conditionallySetValue: IConditionallySetValue, hideTotalRecords)
{
    const [first, setFirst] = useState(true)
    const { replace } = useHistory();
    const { search, pathname } = useLocation();
    //used for query params in URL
    function useQuery()
    {
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery()

    const actions = {
        titleChange: `TITLE_CHANGED`,
        recordsChanged: "RECORD_CHANGED",
        offsetChanged: `OFFSET_CHANGED`,
        limitChanged: `LIMIT_CHANGED`,
        totalRecordsChanged: `TOTAL_RECORDS_CHANGED`,
        newModalOpenStatusChanged: `NEW_OPEN_MODAL_STATUS_CHANGED`,
        editModalOpenStatusChanged: `EDIT_OPEN_MODAL_STATUS_CHANGED`,
        deleteModalOpenStatusChanged: `DELETE_OPEN_MODAL_STATUS_CHANGED`,
        customModalOpenStatusChanged: `CUSTOM_MODAL_OPEN_STATUS_CHANGED`,
        fileUploadModalOpenStatusChanged: `FILE_UPLOAD_MODAL_OPEN_STATUS_CHANGED`,
        sortByChanged: `SORT_BY_CHANGED`,
        columnsChanged: `COLUMNS_CHANGED`,
        filtersChanged: `FILTERS_CHANGED`,
        toastChanged: `TOAST_CHANGED`,
        loadingChanged: `LOADING_CHANGED`,
        selectedRecordChanged: `SELECTED_RECORDS_CHANGED`,
        restTypeChanged: `REST_TYPE_CHANGED`,
        failedRecordsChanged: `FAILED_RECORDS_CHANGED`,
        deletedRecordsChanged: `DELETED_RECORDS_CHANGED`,
        onSearchChanged: `ON_SEARCH_CHANGED`,
        filterAppliedChanged: `FILTER_APPLIED_CHANGED`,
        resetFilters: `RESET_FILTERS`,
    }

    function updateURLParams(params: { name: string, value: string }[])
    {
        for (let param of params)
        {
            if (param.name && param.value)
            {
                query.set(param.name, param.value)
            }
            else if (param.name)
            {
                query.delete(param.name)
            }
        }
        replace({ pathname, search: query.toString() })
    }

    const indexReducer = (state, action) =>
    {
        const { payload, type } = action
        switch (type)
        {
            case actions.titleChange:
                return { ...state, title: payload }
            case actions.recordsChanged:
                return { ...state, records: payload }
            case actions.offsetChanged:
                updateURLParams([{ name: "offset", value: payload }])
                return { ...state, offset: payload }
            case actions.limitChanged:
                updateURLParams([{ name: "limit", value: payload }])
                return { ...state, limit: payload }
            case actions.totalRecordsChanged:
                return { ...state, totalRecords: payload }
            case actions.newModalOpenStatusChanged:
                return { ...state, newModalOpenStatus: payload }
            case actions.editModalOpenStatusChanged:
                return { ...state, editModalOpenStatus: payload }
            case actions.deleteModalOpenStatusChanged:
                return { ...state, deleteModalOpenStatus: payload }
            case actions.fileUploadModalOpenStatusChanged:
                return { ...state, fileUploadModalOpenStatus: payload }
            case actions.sortByChanged:
                updateURLParams([{ name: "sortBy", value: payload?.column }, { name: "sortByDir", value: payload?.direction }])
                return { ...state, sortBy: payload }
            case actions.columnsChanged:
                return { ...state, columns: payload }

            case actions.filtersChanged:
                const { name, value } = payload

                return {
                    ...state,
                    filters: {
                        ...state.filters,
                        [name]: value,
                    },
                }

            case actions.toastChanged:
                return { ...state, toast: payload }
            case actions.loadingChanged:
                return { ...state, loading: payload }
            case actions.selectedRecordChanged:
                return { ...state, selectedRecords: payload }
            case actions.restTypeChanged:
                return { ...state, restType: payload }
            case actions.failedRecordsChanged:
                return { ...state, failedRecords: payload }
            case actions.deletedRecordsChanged:
                return { ...state, deletedRecords: payload }
            case actions.onSearchChanged:
                updateURLParams([{ name: "search", value: payload }])
                return { ...state, onSearch: payload, offset: 0 }
            case actions.filterAppliedChanged:
                Object.keys(payload).map((key) =>
                {
                    const filterObjs = filters?.filters?.filter((filter) => filter.name === key)
                    if (filterObjs && filterObjs[0]?.onChange)
                    {
                        filterObjs[0].onChange(payload[key])
                    }
                })
                updateURLParams([{ name: "filters", value: JSON.stringify(payload) }])

                return { ...state, filterApplied: payload }

            case actions.resetFilters:
                updateURLParams([{ name: "filters", value: undefined }])
                return { ...state, filterApplied: {}, filters: {} }
            case actions.customModalOpenStatusChanged:
                return { ...state, customModalOpenStatus: payload }
            default:
                return state
        }
    }

    function getInitialLimit()
    {
        if (localStorage.getItem(`pagination${window.location.pathname}`))
        {
            return Number(localStorage.getItem(`pagination${window.location.pathname}`))
        }
        else
        {
            return 15
        }
    }


    const initialState = {
        title: "",
        records: [],
        offset: 0,
        limit: getInitialLimit(),
        totalRecords: 0,
        newModalOpenStatusChanged: false,
        editModalOpenStatusChanged: false,
        deleteModalOpenStatusChanged: false,
        customModalOpenStatusChanged: false,
        fileUploadModalOpenStatusChanged: false,
        columns: [],
        filters: {},
        selectedRecords: [],
        restType: restType ? restType : "GET",
    }

    const { postRequest, getRequest } = useRequest()
    const [state, dispatch] = useReducer(indexReducer, initialState)

    function setFilterDefault()
    {
        if (!filters)
        {
            filters = {}
        }

        let updatedFilters = {}

        if (filters && filters.filters)
        {
            filters.filters.forEach((filter) =>
            {
                let value: any = ""
                if (filter.defaultValue)
                {
                    value = filter.defaultValue
                }
                else
                {
                    if (filter.type === "text")
                    {
                        value = ""
                    }
                    if (filter.type === "number")
                    {
                        value = 0
                    }
                    if (filter.type === "switch")
                    {
                        value = false
                    }
                    if (filter.type === "select")
                    {
                        value = filter.defaultValue
                    }
                    if (filter.type === "time")
                    {
                        value = "00:00"
                    }
                    if (filter.type === "checkbox")
                    {
                        value = false
                    }
                    if (filter.type === "date")
                    {
                        value = moment().format("DD/MM/YYYY")
                    }
                }
                dispatch({
                    type: actions.filtersChanged,
                    payload: {
                        name: filter.name,
                        value,
                    },
                })
            })
        }

        if (filters)
        {
            filters?.filters?.forEach((filter) =>
            {
                let urlFilters = JSON.parse(query.get('filters'))
                if (filter?.name && filter?.value)
                {
                    if ((!(urlFilters && Object.keys(urlFilters).includes(filter.name))) && filter.value && filter.value !== state.filters[filter.name])
                    {
                        dispatch({
                            type: actions.filtersChanged,
                            payload: { name: filter.name, value: filter.value },
                        })
                        updatedFilters = { ...updatedFilters, [filter.name]: filter.value }
                    }
                    else if (urlFilters && Object.keys(urlFilters).includes(filter.name))
                    {
                        dispatch({
                            type: actions.filtersChanged,
                            payload: { name: filter.name, value: urlFilters[filter.name] },
                        })
                        updatedFilters = { ...updatedFilters, [filter.name]: urlFilters[filter.name] }
                    }
                }
                else if (filter?.name && urlFilters && Object.keys(urlFilters).includes(filter.name))
                {
                    dispatch({
                        type: actions.filtersChanged,
                        payload: { name: filter.name, value: urlFilters[filter.name] },
                    })
                    updatedFilters = { ...updatedFilters, [filter.name]: urlFilters[filter.name] }
                }
            })
        }
        else if (query.get('filters'))
        {
            updatedFilters = JSON.parse(query.get('filters'))
        }
        if (updatedFilters && Object.keys(updatedFilters).length)
        {
            dispatch({
                type: actions.filterAppliedChanged,
                payload: updatedFilters
            })
        }

        return updatedFilters
    }

    useImperativeHandle(ref, () => ({
        refPullData()
        {
            pullData()
        },
        refGetSelectedRecords()
        {
            return state?.selectedRecords
        }
    }))

    function getPostBody(updatedFilters?)
    {
        if (!body)
        {
            body = {}
        }

        if (query.get("sortBy") && query.get("sortByDir"))
        {
            dispatch({
                type: actions.sortByChanged,
                payload: { column: query.get("sortBy"), direction: query.get("sortByDir") }
            })
            body = {
                sortBy: query.get("sortBy"),
                direction: query.get("sortByDir"),
            }
        }

        if (query.get('search'))
        {
            dispatch({
                type: actions.onSearchChanged,
                payload: query.get('search')
            })
            body = { ...body, search: query.get('search') }
        }

        if (updatedFilters)
        {
            body = { ...body, ...updatedFilters }
        }
        else if (query.get('filters'))
        {
            let urlFilters = JSON.parse(query.get('filters'))
            for (let filter of Object.keys(urlFilters))
            {
                setFilterState({ name: filter, value: urlFilters[filter] })
            }
            dispatch({
                type: actions.filterAppliedChanged,
                payload: urlFilters
            })
            body = { ...body, ...urlFilters }
        }
        return body
    }

    async function pullData()
    {
        dispatch({ type: actions.loadingChanged, payload: true })
        let response
        let updatedFilters;
        let offset = 0
        let limit = 0
        if (first)
        {
            updatedFilters = setFilterDefault()
            limit = state.limit
            offset = state.offset
            setFirst(false)
        }

        if (query.get('limit'))
        {
            limit = Number(query.get('limit'))
        }
        dispatch({
            type: actions.limitChanged,
            payload: limit
        })

        if (query.get('offset'))
        {
            offset = Number(query.get('offset'))
        }

        dispatch({
            type: actions.offsetChanged,
            payload: offset
        })

        if (state.restType === "GET")
        {
            response = await getRequest(
                `${urlString}/${offset}/${limit}`
            )
        }
        if (state.restType === "POST")
        {
            let body = getPostBody(updatedFilters)
            response = await postRequest(
                body,
                `${urlString}/${offset}/${limit}`
            )
        }
        if (response && response.records)
        {
            if (conditionallySetValue) // Used to set values based on other values in the response
            {
                response.records.map((record) =>
                {
                    if (conditionallySetValue.match)
                    {
                        if (record[conditionallySetValue.checkedName] && record[conditionallySetValue.checkedName] === conditionallySetValue.checkedValue)
                        {
                            if (record[conditionallySetValue.setName])
                            {
                                record[conditionallySetValue.setName] = conditionallySetValue.setValue
                            }
                        }
                    }
                    else
                    {
                        if (record[conditionallySetValue.checkedName] && record[conditionallySetValue.checkedName] !== conditionallySetValue.checkedValue)
                        {
                            if (record[conditionallySetValue.setName])
                            {
                                record[conditionallySetValue.setName] = conditionallySetValue.setValue
                            }
                        }
                    }

                })
            }

            dispatch({
                type: actions.recordsChanged,
                payload:
                    response.records &&
                    response.records.map((record) => ({
                        ...record,
                    })),
            })
            if (response.totalRecords)
            {
                dispatch({
                    type: actions.totalRecordsChanged,
                    payload: response.totalRecords,
                })
            }
            else if (hideTotalRecords)
            {
                if (response.lastPage && response.lastPage)
                {
                    dispatch({
                        type: actions.totalRecordsChanged,
                        payload: offset + limit,
                    })
                }
                else
                {
                    dispatch({
                        type: actions.totalRecordsChanged,
                        payload: offset + limit + limit,
                    })
                }
            }
        } else
        {
            dispatch({
                type: actions.toastChanged,
                payload: {
                    text: "The request failed.",
                    type: "error",
                },
            })
        }

        dispatch({ type: actions.loadingChanged, payload: false })
    }

    useEffect(() =>
    {
        pullData()
    }, [])

    function onSearch(search: string)
    {
        dispatch({ type: actions.onSearchChanged, payload: search })
        pullData()
    }

    function setFilterState(data: { name: string; value: any })
    {
        dispatch({ type: actions.filtersChanged, payload: data })
    }

    function applyFilters()
    {
        Object.keys(state.filters).forEach(filter =>
        {
            if (state.filters[filter] && state.filters[filter].length < 1)
            {
                state.filters[filter] = null;
            }

        })
        dispatch({ type: actions.filterAppliedChanged, payload: state.filters })
        pullData()
    }

    function resetFilters()
    {
        dispatch({ type: actions.resetFilters })
        pullData()
    }

    return {
        state,
        dispatch,
        actions,
        setFilterState,
        applyFilters,
        resetFilters,
        pullData,
        onSearch,
    }
}
