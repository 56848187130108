export function loginDotUrl(redirect: boolean)
{
    const baseUrl = window.location.hostname.replace("cp3.", "").replace("cp.", "")
    const redirectUrl = `/index.jsp?origin=${window.location.protocol}//${window.location.hostname}/`

    let url = `${window.location.protocol}//login.${baseUrl}`

    if (redirect)
    {
        url = url + redirectUrl
    }

    return url
}

export function sipconvergenceReformatter(url: string)
{

    if (process.env.NODE_ENV === "development" || window.location.hostname.includes("dev."))
    {
        url = url.replace("voicehost", "dev.voicehost")
    }

    if (window.location.hostname.includes("sipconvergence"))
    {
        url = url.replace("voicehost", "sipconvergence")
    }

    return url
}