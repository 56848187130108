import { useContext, useEffect, useState } from "react"
import { SessionContext } from "../../Contexts/SessionContext"
import useAPIRequests from "../../Hooks/useAPIRequests"
import { useFormReducer } from "../../Hooks/useFormReducer"
import { useRequest } from "../../Hooks/useRequest"

export function useAddTelephoneNumberModal()
{
    const { state: session } = useContext(SessionContext)

    const [numberStageOptionLoading, setNumberStageOptionLoading] = useState(false)
    const [loadingNumbers, setLoadingNumbers] = useState(false)
    const { getRequest, postRequest, postMultiPartRequest } = useRequest()


    const [loading, setLoading] = useState(false)
    const [maximumRange, setMaximumRange] = useState(0)
    const [maximumNumbers, setMaximumNumbers] = useState(0)

    const [tariffs, setTariffs] = useState([])
    const [countryStage, setCountryStage] = useState([])
    const [usaStateStage, setUsaStateStage] = useState([])
    const [regionStage, setRegionStage] = useState([])
    const [vanityFilterStage, setVanityFilterStage] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [feedbackStageFields, setFeedbackStageFields] = useState([])

    const [isInternationalNumberRegulated, setIsInternationalNumberRegulated] = useState(false)
    const [isInternationalNumberProofRequired, setIsInternationalNumberProofRequired] = useState(false)
    const [internationalRegulation, setInternationalRegulation] = useState("")

    const {
        getInternationalCountryPromise,
        getLocationPromise,
        getInternationalLocationPromise,
        getUSAStatesPromise,
        getUSANumbersPromise,
        getNumbersPromise,
        getRangePromise
    } = useAPIRequests()

    const { state, updateForm, updateErrors } = useFormReducer({
        record: {
            type: "GEONUM",
            vanityType: "GEONUM",
            errors: [],
            country: "",
            usaState: "",
            region: "",
            range: 1,
            amount: 1,
            numbers: [],
            vanityLevels: [],
            addressSelect: "new"
        },
    })

    function getAddresses(): Promise<ISelectOption[]>
    {
        return new Promise(async (resolve) =>
        {
            let addressesSelect = [{}]
            const response = await postRequest({}, `/numbering/international/address/-1/-1`)
            if (response && response.records)
            {
                addressesSelect = response.records.map(record => ({
                    label: record.addressLine1 + " " + record.postcode,
                    value: record.id,
                }))
            }
            resolve(addressesSelect)
        })
    }

    function getRegulationFields(): IFormField[]
    {
        let regFields: IFormField[] = [{
            type: "info",
            label: "Regulation Warning",
            value: internationalRegulation
        }]
        if (isInternationalNumberRegulated)
        {

            regFields = [...regFields, {
                type: "select",
                label: "Choose Address",
                name: "addressSelect",
                value: state.addressSelect,
                onChange: updateForm,
                setError: updateErrors,
                error: findFieldError("addressSelect"),
                options: [{ label: "New Address", value: "new" }],
                loadOptions: () => getAddresses(),
            }]
            if (state.addressSelect === "new")
            {
                regFields = [...regFields,
                {
                    type: "text",
                    label: "Title",
                    name: "addressTitle",
                    value: state.addressTitle,
                    onChange: updateForm,
                    setError: updateErrors,
                    error: findFieldError("addressTitle"),
                    required: true,
                    maxLength: 20,
                    regex: /^[A-Za-z']*$/,
                    regexError: "Must be letters and apostrophes(') only."
                },
                {
                    type: "text",
                    label: "First Name",
                    name: "addressFirstname",
                    value: state.addressFirstname,
                    onChange: updateForm,
                    setError: updateErrors,
                    error: findFieldError("addressFirstname"),
                    required: true,
                    maxLength: 50,
                    regex: /^[A-Za-z']*$/,
                    regexError: "Must be letters and apostrophes(') only."
                },
                {
                    type: "text",
                    label: "Last Name",
                    name: "addressLastname",
                    value: state.addressLastname,
                    onChange: updateForm,
                    setError: updateErrors,
                    error: findFieldError("addressLastname"),
                    required: true,
                    maxLength: 50,
                    regex: /^[A-Za-z']*$/,
                    regexError: "Must be letters and apostrophes(') only."
                },
                {
                    type: "text",
                    label: "Address Line 1",
                    name: "addressLine1",
                    value: state.addressLine1,
                    onChange: updateForm,
                    setError: updateErrors,
                    error: findFieldError("addressLine1"),
                    required: true,
                    maxLength: 150,
                    regex: /^[\dA-Za-z ']*$/,
                    regexError: "Must be letters, numbers, spaces and apostrophes(') only."
                },
                {
                    type: "text",
                    label: "Address Line 2",
                    value: state.addressLine2,
                    onChange: updateForm,
                    setError: updateErrors,
                    error: findFieldError("addressLine2"),
                    name: "addressLine2",
                    maxLength: 150,
                    regex: /^[\dA-Za-z ']*$/,
                    regexError: "Must be letters, numbers, spaces and apostrophes(') only."
                },
                {
                    type: "text",
                    label: "County/Area",
                    name: "addressCounty",
                    value: state.addressCounty,
                    onChange: updateForm,
                    setError: updateErrors,
                    error: findFieldError("addressCounty"),
                    required: true,
                    maxLength: 50,
                    regex: /^[A-Za-z ']*$/,
                    regexError: "Must be letters, spaces and apostrophes(') only."
                },
                {
                    type: "text",
                    label: "Country",
                    name: "addressCountry",
                    value: state.addressCountry,
                    onChange: updateForm,
                    setError: updateErrors,
                    error: findFieldError("addressCountry"),
                    required: true,
                    maxLength: 50,
                    regex: /^[A-Za-z ']*$/,
                    regexError: "Must be letters, spaces and apostrophes(') only."
                },
                {
                    type: "text",
                    label: "Postal/Zip Code",
                    name: "addressPostcode",
                    value: state.addressPostcode,
                    onChange: updateForm,
                    setError: updateErrors,
                    error: findFieldError("addressPostcode"),
                    required: true,
                    maxLength: 20,
                    regex: /^[\dA-Za-z ']*$/,
                    regexError: "Must be letters, numbers, spaces and apostrophes(') only."
                }]
                if (isInternationalNumberProofRequired)
                {
                    regFields = [...regFields,
                    {
                        type: "select",
                        label: "Type",
                        name: "proofType",
                        value: state.proofType,
                        options: [
                            { label: "Driving Licence", value: "DrivingLicence" },
                            { label: "Enterprise Registration Certificate", value: "EnterpriseCertificate" },
                            { label: "Passport", value: "Passport" },
                            { label: "Goverment ID", value: "GovernmentID" },
                            { label: "Utility Bill", value: "UtilityBill" },
                        ],
                        onChange: updateForm,
                        setError: updateErrors,
                        error: findFieldError("proofType"),
                        required: true
                    },
                    {
                        type: "fileUpload",
                        name: "addressProof",
                        label: "Documentation",
                        onChange: updateForm,
                        setError: updateErrors,
                        error: findFieldError("addressProof"),
                        required: true
                    }]
                }
            }
        }
        return regFields
    }

    useEffect(() =>
    {
        let count = 0
        if (state.type !== "INT" && state.type !== "VAN")
        {
            if (state.numbers)
            {
                for (let number of state.numbers)
                {
                    if (number.includes("-"))
                    {
                        let nums = number.split(" - ")
                        count = count + amountInRange(nums[0], nums[1])
                    }
                    else
                    {
                        count++
                    }
                }
            }
            setTotalCount(count)
        }
        else
        {
            setTotalCount(state.amount)
        }
    }, [state.numbers, state.amount])

    // GET MAXIMUM RANGES FOR NUMBERS
    useEffect(() =>
    {
        async function getMaximumRange()
        {
            setNumberStageOptionLoading(true)
            let postData
            let numberRange
            let rangeId
            let promise

            if (state.type !== "GEONUM")
            {
                postData = getCodeFromType(state.type)

                if (postData)
                {
                    numberRange = await postRequest(postData, `/numbering/available/ranges`)
                    if (numberRange && numberRange.records && numberRange.records.length === 1)
                    {
                        rangeId = numberRange.records[0].id
                    }
                }
            }
            else
            {
                rangeId = state.region
            }
            if (rangeId)
            {
                postData = { numberRangeId: rangeId, minimumRangeSize: 2 }
                let ranges = await postRequest(postData, `/numbering/available/ranges/-1/-1`)
                if (ranges && ranges.records)
                {
                    let highest = -1
                    for (const range of ranges.records)
                    {
                        if (range.amount > highest)
                        {
                            highest = range.amount
                        }
                    }
                    setMaximumRange(highest <= 100 ? highest : 100) // maximum is 100
                }
            }
            setNumberStageOptionLoading(false)
        }
        getMaximumRange()
    }, [state.type, state.region])

    function getRegulationStage(): IStage[]
    {
        if (isInternationalNumberRegulated)
        {
            return ([{
                title: "Regulations",
                onComplete: {
                    function: () =>
                    {
                        return new Promise(async (resolve) =>
                        {
                            let success = true
                            let requiredFields = [
                                "addressTitle",
                                "addressFirstname",
                                "addressLastname",
                                "addressLine1",
                                "addressCounty",
                                "addressCountry",
                                "addressPostcode"
                            ]
                            let requiredProofFields = [
                                "proofType",
                                "addressProof"
                            ]
                            if (isInternationalNumberRegulated && state.addressSelect === "new")
                            {
                                requiredFields.map(field =>
                                {
                                    if (!state[field] && state[field] !== 0)
                                    {
                                        updateErrors({ target: { name: field, value: "This is a required field" } })
                                        success = false
                                    }
                                })
                            }
                            if (isInternationalNumberProofRequired && state.addressSelect === "new")
                            {
                                requiredProofFields.map(field =>
                                {
                                    if (!state[field] && state[field] !== 0)
                                    {
                                        updateErrors({ target: { name: field, value: "This is a required field" } })
                                        success = false
                                    }
                                })
                            }

                            resolve({ success })
                        })
                    }
                },
                fields: getRegulationFields(),
            }])
        }
        else
        {
            return []
        }
    }

    // GET INTERNATIONAL DETAILS FOR NUMBER STAGE
    useEffect(() =>
    {
        async function getInternationalNumberRegulationsAndCount()
        {
            setNumberStageOptionLoading(true)
            let postData = { ids: [state.region] }
            let countryObj = await postRequest(postData, `/numbering/international/country/${state.country}/-1/1`)
            if (countryObj && countryObj.records)
            {
                const areas = countryObj.records[0]
                if (areas)
                {
                    setMaximumNumbers(areas.stock <= 100 ? areas.stock : 100)
                    setIsInternationalNumberRegulated(false)
                    let requirementString = "No restrictions are applied for the area."
                    if (areas.regulationRequirement)
                    {
                        setIsInternationalNumberRegulated(true)
                        if (areas.regulationRequirement.addressType && areas.regulationRequirement.addressType === "WORLDWIDE")
                        {
                            requirementString = "A worldwide address must be supplied for this number to be activated."
                        }

                        if (areas.regulationRequirement.addressType && areas.regulationRequirement.addressType === "NATIONAL")
                        {
                            requirementString = "A national address, for the country the number is situated in, must be supplied for this number to be activated."
                        }

                        if (areas.regulationRequirement.addressType && areas.regulationRequirement.addressType === "LOCAL")
                        {
                            requirementString = "A local address, for the region the number is situated in, must be supplied for this number to be activated."
                        }

                        if (areas.regulationRequirement.proofRequired)
                        {
                            setIsInternationalNumberProofRequired(true)
                            requirementString += " Proof of this address must be supplied."
                        }
                        requirementString += " For more information, please contact support."
                    }
                    setInternationalRegulation(requirementString)
                }
            }
            setNumberStageOptionLoading(false)
        }
        if (state.type === "INT")
        {
            getInternationalNumberRegulationsAndCount()
        }
    }, [state.region])

    // GET TARIFF PRICES
    useEffect(() =>
    {
        async function getTariffPrices()
        {
            let response = await getRequest(`/billing/tariff/-1/-1`)
            if (response && response.records)
            {
                let tariffs = response.records.map(tariff => ({
                    item: tariff.item,
                    monthly: tariff.monthly,
                    setup: tariff.setup,
                }))

                setTariffs(tariffs)
            }
        }
        getTariffPrices()
    }, [])

    // SET COUNTRY STAGE
    useEffect(() => 
    {
        setUsaStateStage([])
        if (state.type === "INT" || state.type === "GEONUM" || (state.type === "VAN" && state.vanityType === "GEONUM"))
        {
            setCountryStage([{
                title: "Country",
                error: findFieldError("country"),
                onComplete: {
                    function: () =>
                    {
                        return new Promise(async (resolve) =>
                        {
                            if (!state.country)
                            {
                                updateErrors({
                                    target: {
                                        name: "country",
                                        value: "Please select a country"
                                    },
                                })
                                resolve({ success: false })
                            }
                            else
                            {
                                resolve({ success: true })
                            }
                        })
                    }
                },
                fields: [
                    {
                        type: "select",
                        label: "Country",
                        onChange: updateForm,
                        setError: updateErrors,
                        error: findFieldError("country"),
                        name: "country",
                        value: state.country,
                        loadOptions: () => getCountryOptions(),
                    },
                ]
            }])
        }
        else
        {
            setRegionStage([])
            setCountryStage([])
        }
    }, [state.type, state.errors, state.vanityType])

    // SET REGION STAGE (NON USA)
    useEffect(() =>
    {
        if (state.type === "INT" || state.type === "GEONUM" || (state.type === "VAN" && state.vanityType === "GEONUM"))
        {
            if (state.country === "USA")
            {
                setRegionStage([])
                setUsaStateStage([{
                    title: "State",
                    error: findFieldError("usaState"),
                    onComplete: {
                        function: () =>
                        {
                            return new Promise(async (resolve) =>
                            {
                                if (!state.usaState)
                                {
                                    updateErrors({
                                        target: {
                                            name: "usaState",
                                            value: "Please select a State"
                                        },
                                    })
                                    resolve({ success: false })
                                }
                                else
                                {
                                    resolve({ success: true })
                                }
                            })
                        }
                    },
                    fields: [
                        {
                            type: "paragraph",
                            value: renderPricing()
                        },
                        {
                            type: "select",
                            label: "State",
                            onChange: updateForm,
                            setError: updateErrors,
                            error: findFieldError("usaState"),
                            name: "usaState",
                            value: state.usaState,
                            loadOptions: () => getUSAStatesPromise(),

                        },
                    ]
                }])
            }
            else
            {
                setUsaStateStage([])
                setRegionStage([{
                    title: "Region",
                    error: findFieldError("region"),
                    onComplete: {
                        function: () =>
                        {
                            return new Promise(async (resolve) =>
                            {
                                if (!state.region)
                                {
                                    updateErrors({
                                        target: {
                                            name: "region",
                                            value: "Please select a Region"
                                        },
                                    })
                                    resolve({ success: false })
                                }
                                else
                                {
                                    resolve({ success: true })
                                }
                            })
                        }
                    },
                    fields: [
                        {
                            type: "paragraph",
                            value: renderPricing()
                        },
                        {
                            type: "select",
                            label: "Region",
                            onChange: updateForm,
                            setError: updateErrors,
                            error: findFieldError("region"),
                            name: "region",
                            value: state.region,
                            loadOptions: () => getRegionOptions(),

                        },
                    ]
                }])
            }

        }
        else
        {
            setRegionStage([])
            setUsaStateStage([])
        }
    }, [state.country, state.errors, state.vanityType])

    // SET REGION STAGE (USA)
    useEffect(() =>
    {
        if (state.usaState)
        {
            setRegionStage([{
                title: "Region",
                fields: [
                    {
                        type: "select",
                        label: "Region",
                        onChange: updateForm,
                        setError: updateErrors,
                        error: findFieldError("region"),
                        name: "region",
                        value: state.region,
                        loadOptions: () => getUSANumbersPromise(state.usaState),
                    },
                ]
            }])
        }
    }, [state.usaState, state.errors])

    // CLEAR NUMBERS STAGE VALUES ON CHANGES
    useEffect(() =>
    {
        if (state.type === "INT")
        {
            updateForm({
                target: {
                    name: "amount",
                    value: 1
                }
            })
        }
        else
        {
            if (state.range > 1)
            {
                updateForm({
                    target: {
                        name: "range",
                        value: 1
                    }
                })
            }

            if (state.numbers)
            {
                updateForm({
                    target: {
                        name: "numbers",
                        value: []
                    }
                })
            }
        }

    }, [state.region, state.type, state.usaState])

    useEffect(() => 
    {
        if (state.successes && state.successes.length > 0)
        {
            setFeedbackStageFields([{
                type: "feedbackAccordion",
                title: getAccordionTitle(true, totalCount),
                items:
                    state.successes &&
                    state.successes.map(
                        (success) =>
                            `${success.id} - ${success.message}`
                    ),
                variant: "green"
            }])
        }
        if (state.failures && state.failures.length > 0)
        {
            setFeedbackStageFields([...feedbackStageFields, {
                type: "feedbackAccordion",
                title: getAccordionTitle(false, totalCount),
                items:
                    state.failures &&
                    state.failures.map(
                        (failure) =>
                            `${failure.id} - ${failure.message}`
                    ),
                variant: "red"
            }])
        }
    }, [state.successes, state.failures])

    ////    // ============================================================ //
    ///    // useEffects that reset options when parent values are changed //
    //    // ============================================================ //

    // I wanted to do a cool parallelogram shape but it autoformatted it away :(
    // Don't worry man, I got you!

    useEffect(() =>
    {
        updateForm({
            target: {
                name: "country",
                value: "",
            }
        })
    }, [state.type])

    useEffect(() =>
    {
        updateForm({
            target: {
                name: "region",
                value: "",
            }
        })
        updateForm({
            target: {
                name: "usaState",
                value: "",
            }
        })
    }, [state.country, state.type])

    useEffect(() =>
    {
        if (state.range > 1)
        {
            updateForm({
                target: {
                    name: "range",
                    value: 1,
                }
            })
        }
        if (state.numbers)
        {
            updateForm({
                target: {
                    name: "numbers",
                    value: [],
                }
            })
        }
        if (state.amount > 1)
        {
            updateForm({
                target: {
                    name: "amount",
                    value: 1,
                }
            })
        }
    }, [state.region, state.usaState, state.country, state.type])

    //// // ===== //
    /// //  End  //
    // // ===== //

    function formatCurrency(price)
    {
        return (Math.round(price * 100) / 100).toFixed(2);
    }

    function findFieldError(name)
    {
        if (state.errors && state.errors.length > 0)
        {
            const errors = state.errors.filter((error) => error.name === name)
            if (errors && errors.length > 0)
            {
                return errors[0].value
            }
        }
        return null
    }

    function renderChargeAdvice()
    {
        let chargeAdvice = ""
        let tariffItem = state.type

        if (tariffItem === "INT") // Same for retail and wholesale.
        {
            chargeAdvice = "International Numbers are charged based on the country and are subject to a 3 month contract. Please note that with international numbers the numbers you will receive are not guaranteed to be sequential, nor is a particular number given. If you need sequential or specific international numbers please contact support."
        }
        else
        {
            let isVan = false;
            if (tariffItem === "0333NGN") // These items are the same price in the DB so need to be set here
            {
                tariffItem = "03NGN"
            }
            else if (tariffItem === "0808FREE")
            {
                tariffItem = "0800FREE"
            }
            else if (tariffItem === "VAN")
            {
                tariffItem = state.vanityType

                if (tariffItem === "0333NGN") // These items are the same price in the DB so need to be set here
                {
                    tariffItem = "03NGN"
                }
                else if (tariffItem === "0808FREE")
                {
                    tariffItem = "0800FREE"
                }
                isVan = true;
                chargeAdvice = "Vanity Number charges are dependant on the level of the vanity number. Each vanity number purchased has a 24 month contract. "
            }

            if (isVan || !session.account.wholesale)
            {
                let tariff = tariffs.find(({ item }) => item === tariffItem)
                if (tariff)
                {
                    let retailCharge = `at £${formatCurrency(tariff.monthly)} (Ex-VAT) `
                    switch (tariffItem)
                    {
                        case "GEONUM":
                            chargeAdvice += `UK Geographic ${isVan ? "vanity " : ""}numbers are charged ${!session.account.wholesale ? retailCharge : ""}per month, per number.`;
                            break;
                        case "0333NGN":
                            chargeAdvice = `UK Wide ${isVan ? "vanity " : ""}numbers are charged ${!session.account.wholesale ? retailCharge : ""}per month, per number.`
                            break;
                        case "03NGN":
                            chargeAdvice += `UK Not-for-Profit 0300 ${isVan ? "vanity " : ""}numbers are charged ${!session.account.wholesale ? retailCharge : ""}per month, per number. ` +
                                "Please Note: 0300 numbers are for public sector bodies and not-for-profit bodies such as registered charities. To obtain a 0300 number you must meet the required Ofcom requirements."
                            break
                        case "0843NGN":
                            chargeAdvice += `UK Non-Geographic 0843 557 (SC006) ${isVan ? "vanity " : ""}numbers are charged ${!session.account.wholesale ? retailCharge : ""}per month, per number. Calls to 0843 557 (SC006) numbers are charged at 5ppm + network access charge.`
                            break
                        case "0845NGN":
                            chargeAdvice += `UK Non-Geographic 0845 561 (SC003) ${isVan ? "vanity " : ""}numbers are charged ${!session.account.wholesale ? retailCharge : ""}per month, per number. Calls to 0845 561 (SC003) numbers are charged at 2ppm + network access charge.`
                            break
                        case "0800FREE":
                            chargeAdvice += `UK 0800 Free Phone ${isVan ? "vanity " : ""}numbers are charged ${!session.account.wholesale ? retailCharge : ""}per month, per number. ` +
                                `Free phone numbers are also charged per minute for inbound calls as per your inbound tariff.`
                            break;
                        case "0808FREE":
                            chargeAdvice += `UK 0808 Free Phone ${isVan ? "vanity " : ""}numbers are charged ${!session.account.wholesale ? retailCharge : ""}per month, per number. ` +
                                "Free phone numbers are also charged per minute for inbound calls as per your inbound tariff."
                            break;
                    }
                }
                else
                {
                    chargeAdvice = "Error returning pricing"
                }
            }
        }
        return chargeAdvice
    }

    function renderPricing()
    {
        if (!session.account.wholesale)
        {
            let tariffItem = state.type
            if (tariffItem === "0333NGN") // These items are the same price in the DB so need to be set here
            {
                tariffItem = "03NGN"
            }
            else if (tariffItem === "0808FREE")
            {
                tariffItem = "0800FREE"
            }
            else if (tariffItem === "INT")
            {
                tariffItem = "INT" + state.country
            }
            let tariff = tariffs.find(({ item }) => item === tariffItem)
            if (tariff)
            {
                return `Pricing for these number is £${formatCurrency(tariff.monthly)} (Ex-VAT) per month with a setup fee of £${formatCurrency(tariff.setup)} (Ex-VAT). These fees are per number.`
            }
        }
        else
        {
            return ``
        }
    }

    function getTotalCost(amount)
    {
        if (state.type === "VAN")
        {
            let Numberslevels = state.vanityLevels.filter(vanityNumber => state.numbers.includes(vanityNumber.number))
            let chargeAmount = getVanitySetup(Numberslevels)

            let tariffItem = state.vanityType

            if (tariffItem === "0333NGN") // These items are the same price in the DB so need to be swapped here
            {
                tariffItem = "03NGN"
            }
            else if (tariffItem === "0808FREE")
            {
                tariffItem = "0800FREE"
            }

            let tariff = tariffs.find(({ item }) => item === tariffItem)
            let value
            if (tariff)
            {
                value = `Setup Costs: £${formatCurrency(chargeAmount)} (Ex-VAT). Monthly costs: £${formatCurrency(tariff.monthly * state.numbers.length)} (Ex-VAT).`
            }
            else
            {
                value = "Error returning cost"
            }
            return value
        }
        else
        {
            let tariffItem = state.type
            if (tariffItem === "0333NGN") // These items are the same price in the DB so need to be swapped here
            {
                tariffItem = "03NGN"
            }
            else if (tariffItem === "0808FREE")
            {
                tariffItem = "0800FREE"
            }
            else if (tariffItem === "INT")
            {
                tariffItem = "INT" + state.country
            }

            let tariff = tariffs.find(({ item }) => item === tariffItem)
            let value
            if (tariff)
            {
                value = `Setup Costs: £${formatCurrency(tariff.setup * amount)} (Ex-VAT). Monthly costs: £${formatCurrency(tariff.monthly * amount)} (Ex-VAT).`
            }
            else
            {
                value = "Error returning cost"
            }
            return value
        }
    }

    function getVanitySetup(numbers)
    {
        let setUpAmount = 0
        numbers.forEach(number =>
        {
            if (number.level === 4)
            {
                setUpAmount += 50
            }
            else if (number.level === 3)
            {
                setUpAmount += 70
            }
            else if (number.level === 2)
            {
                setUpAmount += 100
            }
            else if (number.level === 1)
            {
                setUpAmount += 150
            }
        });

        return setUpAmount
    }

    function getCountryOptions()
    {
        if (state.type === "GEONUM" || (state.type === "VAN" && state.vanityType === "GEONUM"))
        {
            return [
                { value: "ENG", label: "England" },
                { value: "NIR", label: "Northern Ireland" },
                { value: "SCO", label: "Scotland" },
                { value: "WAL", label: "Wales" }
            ]
        }
        else if (state.type === "INT")
        {
            return getInternationalCountryPromise()
        }
    }

    function getRegionOptions()
    {
        if (state.type === "GEONUM" || (state.type === "VAN" && state.vanityType === "GEONUM"))
        {
            return getLocationPromise(state.country)
        }
        else if (state.type === "INT")
        {
            return getInternationalLocationPromise(state.country)
        }
    }

    // COUNTS ALL NUMBERS BETWEEN TWO PHONE NUMBERS
    function amountInRange(start: string, end: string)
    {
        let count = 1
        while (start !== end) 
        {
            start = start.split("").reverse().join("")

            let carry = false
            let newString = ""
            for (let i = 0; i < start.length; i++)
            {
                let a = start.charAt(i)

                if (i == 0 || carry)
                {
                    if (a === '9')
                    {
                        a = '0'
                        carry = true
                    }
                    else if (a >= '0' && a <= '8')
                    {
                        let num = Number(a)
                        num++
                        a = String(num)
                        carry = false
                    }
                }
                newString = newString + a
            }
            start = newString.split("").reverse().join("")
            count++
        }
        return count
    }

    function getCodeFromType(type)
    {
        if (type === "0333NGN")
        {
            return { countryCode: "44", areaCode: "333", subCode: null }
        }
        else if (type === "03NGN")
        {
            return { countryCode: "44", areaCode: "300", subCode: null }
        }
        else if (type === "0843NGN")
        {
            return { countryCode: "44", areaCode: "843", subCode: "557" }
        }
        else if (type === "0845NGN")
        {
            return { countryCode: "44", areaCode: "845", subCode: "561" }
        }
        else if (type === "0800FREE")
        {
            return { countryCode: "44", areaCode: "800", subCode: null }
        }
        else if (type === "0808FREE")
        {
            return { countryCode: "44", areaCode: "808", subCode: null }
        }
    }

    async function getNumbers(range)
    {
        setLoadingNumbers(true)
        let postData
        let numberRange
        let rangeId
        let promise

        if (state.type !== "GEONUM")
        {
            postData = getCodeFromType(state.type)
            if (postData)
            {
                numberRange = await postRequest(postData, `/numbering/available/ranges`)
                if (numberRange && numberRange.records && numberRange.records.length === 1)
                {
                    rangeId = numberRange.records[0].id
                }
            }
        }
        else
        {
            rangeId = state.region
        }
        if (rangeId)
        {
            if (range === 1)
            {
                promise = getNumbersPromise(rangeId)
            }
            else
            {
                promise = getRangePromise(rangeId, range)
            }
        }
        setLoadingNumbers(false)
        return promise
    }

    async function getVanityNumbers(): Promise<ISelectOption[]>
    {
        return new Promise(async (resolve) =>
        {
            setLoadingNumbers(true)

            let id = 0

            if (state.vanityType === "GEONUM")
            {
                id = state.region
            }
            else if (state.vanityType === "0333NGN")
            {
                id = 676
            }
            else if (state.vanityType === "03NGN")
            {
                id = 700
            }
            else if (state.vanityType === "0800FREE")
            {
                id = 359
            }
            else if (state.vanityType === "0808FREE")
            {
                id = 699
            }

            const postData = {
                numberRangeId: id,
                platinum: true,
                gold: true,
                silver: true,
                bronze: true,
                filter: null
            }

            const numbers = await postRequest(postData, `/numbering/available/vanity`)

            if (numbers && numbers.records)
            {
                const vanityNumbers = numbers.records.map((number) => ({
                    label: number.number + " (" + getVanityLevel(number.class) + ")",
                    value: number.number
                }))

                const vanityLevels = numbers.records.map((number) => ({
                    number: number.number,
                    level: number.class
                }))

                updateForm({
                    target: {
                        value: vanityLevels,
                        name: "vanityLevels"
                    }
                })

                resolve(vanityNumbers)
            }
        })
    }

    function getVanityLevel(level)
    {
        if (level === 1)
        {
            return "Platinum"
        }
        else if (level === 2)
        {
            return "Gold"
        }
        else if (level === 3)
        {
            return "Silver"
        }
        else if (level === 4)
        {
            return "Bronze"
        }
    }

    function getNumberStage(): IStage[]
    {

        if (state.type === "INT")
        {
            return [{

                title: "Numbers",
                fields: [
                    {
                        type: "number",
                        label: "Amount",
                        onChange: updateForm,
                        min: 1,
                        max: maximumNumbers,
                        name: "amount",
                        value: state.amount,
                        loading: numberStageOptionLoading,
                    },
                    {
                        type: "info",
                        label: "Maximum numbers availiable",
                        value: `${maximumNumbers}`,
                        loading: numberStageOptionLoading,
                    },
                ]
            }]
        }
        else if (state.type === "VAN")
        {
            return [{
                title: "Numbers",
                error: findFieldError("numbers"),
                onComplete: {
                    function: () =>
                    {
                        return new Promise(async (resolve) =>
                        {
                            if (state.numbers.length < 1)
                            {
                                updateErrors({
                                    target: {
                                        name: "numbers",
                                        value: "Please select at least 1 number"
                                    },
                                })
                                resolve({ success: false })
                            }
                            else
                            {
                                resolve({ success: true })
                            }
                        })
                    }
                },
                fields: [
                    {
                        type: "select",
                        label: "Numbers",
                        onChange: (e) =>
                        {
                            updateForm(e)
                            updateErrors({ target: { name: "numbers", value: "" } })
                        },
                        setError: updateErrors,
                        error: findFieldError("numbers"),
                        name: "numbers",
                        value: state.numbers,
                        loading: loadingNumbers,
                        required: true,
                        loadOptions: getVanityNumbers,
                        multi: true,
                    }
                ]
            }]
        }
        else
        {
            return [{
                title: "Numbers",
                error: findFieldError("numbers"),
                onComplete: {
                    function: () =>
                    {
                        return new Promise(async (resolve) =>
                        {
                            if (state.numbers.length < 1)
                            {
                                updateErrors({
                                    target: {
                                        name: "numbers",
                                        value: "Please select at least 1 number"
                                    },
                                })
                                resolve({ success: false })
                            }
                            else
                            {
                                resolve({ success: true })
                            }
                        })
                    }
                },
                fields: [
                    {
                        type: "slider",
                        label: "Range",
                        onChangeCommitted: updateForm,
                        setError: updateErrors,
                        error: findFieldError("range"),
                        name: "range",
                        value: state.range,
                        min: 1,
                        max: maximumRange,
                        showDroplet: true,
                        style: { "padding-top": 50 },
                    },
                    {
                        type: "select",
                        label: "Numbers",
                        onChange: (e) =>
                        {
                            updateForm(e)
                            updateErrors({ target: { name: "numbers", value: "" } })
                        },
                        setError: updateErrors,
                        error: findFieldError("numbers"),
                        name: "numbers",
                        value: state.numbers,
                        loadOptions: () => getNumbers(state.range),
                        multi: true,
                        loading: loadingNumbers,
                        updateOn: state.range,
                        required: true
                    },
                ]
            }]
        }
    }

    function onSubmit(): Promise<{ successes?: postFeedback[]; failures?: postFeedback[] }>
    {
        return new Promise(async (resolve) =>
        {
            setLoading(true)
            const successes = []
            const failures = []
            let postData
            let response

            if (state.type === "INT")
            {

                postData = {
                    "customerReference": "Ordered through CP",
                    "areaId": state.region,
                    "quantity": state.amount
                }
                response = await postRequest(postData, "/numbering/international/order")


                if (response)
                {
                    let successfulInternationalIDs = []
                    if (response.records)
                    {
                        for (let record of response.records)
                        {
                            successfulInternationalIDs.push(record.id)
                            successes.push({
                                id: record.number,
                                message: "Purchased successfully",
                            })
                        }
                    } else
                    {
                        if (response.validation)
                        {
                            failures.push({
                                id: "Validation Error",
                                message: "Please go back to rectify the validation errors.",
                            })
                            for (let key in response.validation)
                            {
                                if (response.validation.hasOwnProperty(key))
                                {
                                    updateErrors({
                                        target: {
                                            name: key,
                                            value: response.validation[key]
                                        }
                                    })
                                }
                            }
                        } else if (response.message)
                        {
                            failures.push({
                                id: "Telephone Number",
                                message: response.message,
                            })
                        }
                        else if (response.error)
                        {
                            failures.push({
                                id: "Telephone Number",
                                message: response.error,
                            })
                        }
                    }
                    if (isInternationalNumberRegulated && state.addressSelect === "new")
                    {
                        postData = {
                            "title": state.addressTitle,
                            "firstname": state.addressFirstname,
                            "lastname": state.addressLastname,
                            "addressLine1": state.addressLine1,
                            "addressLine2": state.addressLine2,
                            "county": state.addressCounty,
                            "country": state.addressCountry,
                            "postcode": state.addressPostcode,
                            "allocatedNumberIds": successfulInternationalIDs
                        }
                        response = await postRequest(postData, `/numbering/international/address`)
                        if (response && response.success && response.success && response.message)
                        {
                            if (isInternationalNumberProofRequired)
                            {
                                let formData = new FormData()
                                formData.append(state.proofType + "-" + state.country, state.addressProof)
                                response = await postMultiPartRequest(formData, `/numbering/international/address/${response.message}/proof/upload`)
                                if (!(response && response.success && response.success))
                                {
                                    failures.push({
                                        id: "Proof Error",
                                        message: "Unable to save the proof of address provided. Please add this via the International Restriction page.",
                                    })
                                }
                            }
                        }
                        else
                        {
                            failures.push({
                                id: "Address Error",
                                message: "Unable to save the address provided. Please add this via the International Restriction page.",
                            })
                        }
                    }

                } else
                {
                    failures.push({
                        id: "Telephone Number",
                        message: "Something went wrong please try again",
                    })
                }
            }
            else
            {
                let telephoneNumberRanges = []
                let telephoneNumbers = []
                for (let num of state.numbers)
                {
                    if (num.includes("-"))
                    {
                        let nums = num.split("-")
                        telephoneNumberRanges.push({ "start": nums[0], "end": nums[1] })
                    }
                    else
                    {
                        telephoneNumbers.push(num)
                    }
                    postData = { telephoneNumberRanges, telephoneNumbers }
                }
                response = await postRequest(postData, "/numbering/available/allocate")

                if (response)
                {
                    if (response.success)
                    {
                        successes.push({
                            id: "Telephone Number",
                            message: "Purchased successfully",
                        })
                    } else
                    {
                        if (response.validation)
                        {
                            failures.push({
                                id: "Validation Error",
                                message: "Please go back to rectify the validation errors.",
                            })
                            for (let key in response.validation)
                            {
                                if (response.validation.hasOwnProperty(key))
                                {
                                    updateErrors({
                                        target: {
                                            name: key,
                                            value: response.validation[key]
                                        }
                                    })
                                }
                            }
                        } else
                        {
                            failures.push({
                                id: "Telephone Number",
                                message: response.message,
                            })
                        }
                    }
                } else
                {
                    failures.push({
                        id: "Telephone Number",
                        message: "Something went wrong please try again",
                    })
                }
            }
            const feedback = {
                successes: successes,
                failures: failures,
            }
            setLoading(false)
            resolve(feedback)
        })
    }

    function getConfirmationStage(): IStage[]
    {
        return [
            {
                title: "Confirm",
                buttonText: "Purchase",
                onComplete: {
                    function: () =>
                    {
                        return new Promise(async (resolve) =>
                        {
                            if (isInternationalNumberRegulated && !state.acceptRegulation)
                            {
                                updateErrors({
                                    target: {
                                        name: "acceptRegulation",
                                        value: "Please accept that you are aware of the regulation requirements before continuing."
                                    }
                                })
                                resolve({ success: false })
                            }
                            else
                            {
                                let ret
                                let feedback
                                feedback = await onSubmit()

                                updateForm({
                                    target: {
                                        name: "successes",
                                        value: feedback.successes,
                                    },
                                })
                                updateForm({
                                    target: {
                                        name: "failures",
                                        value: feedback.failures,
                                    },
                                })
                                resolve({ success: true })
                            }
                        })
                    },
                },
                fields: [
                    {
                        type: "paragraph",
                        value: "Are you sure you wish to make this purchase, all purchases are final once processed.",
                    },
                    !session.account.wholesale && {
                        type: "info",
                        value: totalCount,
                        label: `Total Numbers`,
                    },
                    !session.account.wholesale && {
                        type: "info",
                        value: getTotalCost(totalCount),
                        label: "Total Cost",
                    },
                    isInternationalNumberRegulated && {
                        type: "checkbox",
                        label: "I am aware that regulation apply for the numbers I am purchasing.",
                        name: "acceptRegulation",
                        value: state.acceptRegulation,
                        onChange: updateForm,
                        setError: updateErrors,
                        error: findFieldError("acceptRegulation"),
                    },
                    {
                        type: "paragraph",
                        value: "Click 'Purchase' to complete.",
                    },
                ],
            }
        ]
    }

    function getAccordionTitle(succeeded, amount)
    {
        let title = ""
        if (succeeded)
        {
            title += `${amount} Telephone Number${amount > 1 ? "s" : ""
                } successfully purchased`
        }
        else
        {
            if (state.failures && state.failures.length > 0)
            {
                title += `${state.failures.length} error${state.failures.length > 1 ? "s" : ""
                    } occurred`
            }
        }

        return title
    }

    return {
        getConfirmationStage,
        getNumberStage,
        renderChargeAdvice,
        findFieldError,
        loading,
        state,
        updateErrors,
        updateForm,
        countryStage,
        usaStateStage,
        regionStage,
        getRegulationStage,
        feedbackStageFields,
        vanityFilterStage
    }
}