import { useRequest } from "./useRequest";

export default function useAPIRequests()
{

    const { getRequest, postRequest } = useRequest()
    function capitaliser(unformatted)
    {
        const regexConst = new RegExp(' ');
        unformatted = unformatted.toLowerCase()
        let groups = unformatted.split(regexConst)
        let formatted = ""
        if (groups)
        {
            for (let group of groups)
            {
                formatted += group.charAt(0).toUpperCase() + group.slice(1) + " "
            }
        } else
        {
            return unformatted.charAt(0).toUpperCase() + unformatted.slice(1)
        }
        return formatted
    }

    function getDestinationPromise(filter?: IDestinationsFilter): Promise<ISelectOption[]>
    {
        filter = filter ? filter : {}
        const options = {
            body: filter,
            url: "destinations",
            label: "name",
            value: "destination"
        }
        return getPromise(options)
    }

    function getCallerIdPromise(): Promise<ISelectOption[]>
    {
        return new Promise(async resolve =>
        {
            let callerIds = []

            const cidResponse = await postRequest({}, `/numbering/allocated/callerids`)
            if (cidResponse && cidResponse.records)
            {
                callerIds = cidResponse.records.map(cid => ({
                    label: cid.name,
                    value: cid.number
                }))

            }

            resolve(callerIds)
        })
    }

    function getMailboxes()
    {
        return new Promise(async resolve =>
        {
            let response = await postRequest({}, "/voicemail/-1/-1")
            if (response && response.records)
            {
                const promiseDetails = response.records.map(details => ({
                    label: details["mailboxName"],
                    value: details["mailbox"],
                }))

                resolve(promiseDetails)
            }
        })
    }

    function getPromise(options: IPromise): Promise<ISelectOption[]>
    {
        return new Promise(async resolve =>
        {
            let response
            if (options.restType && options.restType === "GET")
            {
                response = await getRequest("/" + options.url + "/-1/-1")
            }
            else
            {
                response = await postRequest(options.body, "/" + options.url + "/-1/-1")
            }
            if (response && response.records)
            {
                const promiseDetails = response.records.map(details => ({
                    label: details[options.label],
                    value: details[options.value],
                }))
                if (promiseDetails.length > 0)
                {
                    resolve(promiseDetails)
                }
                else
                {
                    resolve([{ label: "No items found.", value: "" }])
                }
            }
        })
    }

    function getMobiles(): Promise<ISelectOption[]>
    {
        return new Promise(async resolve =>
        {
            let response = await postRequest({}, "/mobile/-1/-1")
            if (response && response.records)
            {
                const promiseDetails = response.records.map(details => ({
                    label: details["description"] + " (" + details["number"] + ")",
                    value: details["id"],
                }))

                resolve(promiseDetails)
            }
        })
    }

    function getMonths(months: number): Promise<ISelectOption>
    {
        return new Promise(async resolve =>
        {
            let response = await postRequest({ "maxMonths": months }, "/account/activemonths")
            if (response && response.records)
            {
                const promiseDetails = response.records.map(details => ({
                    label: details["label"],
                    value: details["value"],
                }))

                resolve(promiseDetails)
            }
        })
    }

    function getCDRMonths(): Promise<ISelectOption>
    {
        return new Promise(async resolve =>
        {
            let response = await getRequest("/callrecords/months")
            if (response && response.records)
            {
                const promiseDetails = response.records.map(details => ({
                    label: details["label"],
                    value: details["value"],
                }))

                resolve(promiseDetails)
            }
        })
    }

    function getMusicOnHoldPromise(): Promise<ISelectOption[]>
    {
        const options = {
            body: {},
            url: "musiconhold",
            label: "name",
            value: "mohclass"
        }

        return getPromise(options)
    }

    function getPromptPromise(): Promise<ISelectOption[]>
    {
        const options = {
            body: {},
            url: "prompt",
            label: "name",
            value: "id"
        }

        return getPromise(options)
    }

    function getInternationalCountryPromise(): Promise<ISelectOption[]>
    {
        return new Promise(async resolve =>
        {
            let countryPromise = await getRequest(`/numbering/international/countries/-1/-1`)
            if (countryPromise && countryPromise.records)
            {
                const formatted = countryPromise.records.map(responsePrompt =>
                ({
                    value: responsePrompt.countryCodeA3,
                    label: capitaliser(responsePrompt.countryName) + "(+" + responsePrompt.phoneCode + ")",
                }))
                resolve(formatted)
            }
        })
    }

    function getLocationPromise(country): Promise<ISelectOption[]>
    {
        return new Promise(async resolve =>
        {
            let postData = { filterField: "name", ascending: true }
            let rangePromise = await postRequest(postData, `/numbering/available/ranges/${country}`)
            if (rangePromise && rangePromise.records)
            {
                const formatted = rangePromise.records.map(responsePrompt =>
                ({
                    value: responsePrompt.id,
                    label: responsePrompt.name,
                }))
                resolve(formatted)
            }
        })
    }

    function getInternationalLocationPromise(country: String): Promise<ISelectOption[]>
    {
        return new Promise(async resolve =>
        {
            let postData = { ids: [] }
            let countryPromise = await postRequest(postData, `/numbering/international/country/${country}/-1/-1`)

            if (countryPromise && countryPromise.records)
            {
                const formatted = countryPromise.records.map(responsePrompt =>
                ({
                    value: responsePrompt.id,
                    label: responsePrompt.cityName ? capitaliser(responsePrompt.cityName) + " (Area Code: " + responsePrompt.areaCode + ") " : responsePrompt.areaCode
                }))
                resolve(formatted)
            }
        })
    }

    function getUSAStatesPromise(): Promise<ISelectOption[]>
    {
        return new Promise(async resolve =>
        {

            let statePromise = await getRequest(`/numbering/international/country/USA/states/-1/-1`)
            if (statePromise && statePromise.records)
            {
                const formatted = statePromise.records.map(responsePrompt =>
                ({
                    value: responsePrompt.id,
                    label: responsePrompt.name
                }))
                resolve(formatted)
            }
        })
    }

    function getUSANumbersPromise(stateid: String): Promise<ISelectOption[]>
    {
        return new Promise(async resolve =>
        {
            let countryPromise = await getRequest(`/numbering/international/country/USA/states/${stateid}/-1/-1`)

            if (countryPromise && countryPromise.records)
            {
                const formatted = countryPromise.records.map(responsePrompt =>
                ({
                    value: responsePrompt.id,
                    label: responsePrompt.cityName ? capitaliser(responsePrompt.cityName) + " (Area Code: " + responsePrompt.areaCode + ") " : responsePrompt.areaCode
                }))
                resolve(formatted)
            }
        })
    }

    function getNumbersPromise(location: String): Promise<ISelectOption[]>
    {
        return new Promise(async resolve =>
        {
            let postData = { rangeId: location }
            let formatted
            let country = await postRequest(postData, `/numbering/available/search`)
            if (country && country.records)
            {
                formatted = country.records.map(responsePrompt =>
                ({
                    value: responsePrompt.number,
                    label: responsePrompt.number,
                }))
            }
            else
            {
                formatted = [{ value: "", label: "No Numbers Found" }]
            }
            resolve(formatted)
        })
    }

    function getRangePromise(location: String, range: number): Promise<ISelectOption[]>
    {
        return new Promise(async resolve =>
        {
            let postData = { numberRangeId: location, minimumRangeSize: `${range}` }
            let ranges = await postRequest(postData, `/numbering/available/ranges/-1/-1`)
            let formatted
            if (ranges && ranges.records)
            {
                let starts = []
                for (let rangeObj of ranges.records)
                {
                    let numAmount = (rangeObj.amount - range + 1)
                    let num = rangeObj.start

                    for (let i = 0; i < numAmount; i++)
                    {
                        let start = Number(num) + Number(i)
                        let last = Number(start) + Number(range) - 1

                        let startString = start.toString()
                        let lastString = last.toString()
                        if (startString.startsWith("44"))
                        {
                            startString = startString.replace("44", "0")
                        }
                        if (lastString.startsWith("44"))
                        {
                            lastString = lastString.replace("44", "0")
                        }
                        starts.push({ start: start, last: last, startLabel: startString, lastLabel: lastString })
                    }
                }
                formatted = starts.map(responsePrompt =>
                ({
                    value: responsePrompt.start + " - " + responsePrompt.last,
                    label: responsePrompt.startLabel + " - " + responsePrompt.lastLabel + " (Inclusive)",
                }))
            }
            else
            {
                formatted = [{ value: "", label: "No Numbers Found" }]
            }
            resolve(formatted)
        })
    }

    function getCountriesPromise(key?: string): Promise<ISelectOption[]>
    {
        return new Promise(async resolve =>
        {
            let response = await getRequest(`/account/countries`)

            if (response && response.country_list)
            {
                const formatted = response.country_list.map(responsePrompt =>
                ({
                    value: key ? responsePrompt[key] : responsePrompt.country_id,
                    label: responsePrompt.country_name,
                }))
                resolve(formatted)
            }
        })
    }

    function getUnrestrictedCountriesPromise(key?: string): Promise<ISelectOption[]>
    {
        return new Promise(async resolve =>
        {
            let response = await getRequest(`/seat/locations`)

            if (response && response.records)
            {
                const formatted = response.records.map(responsePrompt =>
                ({
                    value: key ? responsePrompt[key] : responsePrompt.country_code,
                    label: responsePrompt.country_name,
                }))
                resolve(formatted)
            }
        })
    }

    function getMSTeamsPromise(seatName): Promise<ISelectOption[]>
    {
        return new Promise(async resolve =>
        {
            let response = await getRequest(`/numbering/allocated/msteams/` + seatName)

            if (response && response.records)
            {
                const formatted = response.records.map(number =>
                ({
                    value: number.voipNumber,
                    label: number.displayNumber,
                }))
                resolve(formatted)
            }
        })
    }

    function getUsersPromise(): Promise<ISelectOption[]>
    {
        return new Promise(async resolve =>
        {
            let response = await getRequest(`/users/0/199`)

            if (response && Array.isArray(response))
            {
                const formatted = response.filter((user) => user.enabled).map(user =>
                ({
                    value: user.id,
                    label: user.name + " " + user.surname,
                }))
                resolve(formatted)
            }
        })
    }

    function getAllOutboundCLILists(): Promise<ISelectOption[]>
    {
        return new Promise(async resolve =>
        {
            let response = await getRequest('/outboundclilist/-1/-1')

            if (response && response.records)
            {
                const formatted = response.records.map(list =>
                ({
                    value: list.id,
                    label: list.name,
                }))
                resolve(formatted)
            }
        })
    }

    return {
        getPromise,
        getDestinationPromise,
        getMusicOnHoldPromise,
        getPromptPromise,
        getMonths,
        getCDRMonths,
        getMailboxes,
        getMobiles,
        getCallerIdPromise,
        getLocationPromise,
        getInternationalCountryPromise,
        getInternationalLocationPromise,
        getUSAStatesPromise,
        getUSANumbersPromise,
        getNumbersPromise,
        getRangePromise,
        getCountriesPromise,
        getMSTeamsPromise,
        getUnrestrictedCountriesPromise,
        getUsersPromise,
        getAllOutboundCLILists
    }
}