import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as SolidIcons from "@fortawesome/free-solid-svg-icons";
import * as RegularIcons from "@fortawesome/free-regular-svg-icons";
import * as BrandIcons from "@fortawesome/free-brands-svg-icons";
import { FlipProp, SizeProp } from "@fortawesome/fontawesome-svg-core";

interface IProps
{
    icon: string
    color?: string
    size?: SizeProp
    flip?: FlipProp
    inverse?: boolean
    transform?: string
    pulse?: boolean
    spin?: boolean
    onClick?: () => void
    style?: { [key: string]: string | number }
    variation?: "solid" | "regular"
}

export default function Icon({
    icon,
    color,
    size,
    flip,
    inverse,
    transform,
    pulse,
    spin,
    onClick,
    style,
    variation,
}: IProps)
{
    function iconString(name)
    {
        var newVal = name.toString().replace(/-([a-z])/g, function (g)
        {
            return g[1].toUpperCase();
        });

        newVal = "fa" + newVal.charAt(0).toUpperCase() + newVal.slice(1);
        return newVal;
    }

    function fetchIcon()
    {
        if (variation === "solid")
        {
            if (SolidIcons[iconString(icon)])
            {
                return SolidIcons[iconString(icon)];
            }
        } else if (variation === "regular")
        {
            if (RegularIcons[iconString(icon)])
            {
                return RegularIcons[iconString(icon)];
            }
        }

        if (SolidIcons[iconString(icon)])
        {
            return SolidIcons[iconString(icon)];
        } else if (RegularIcons[iconString(icon)])
        {
            return RegularIcons[iconString(icon)];
        } else if (BrandIcons[iconString(icon)])
        {
            return BrandIcons[iconString(icon)];
        } else
        {
            return null;
        }
    }

    function flipIcon()
    {
        if (flip)
        {
            return flip;
        } else if (icon === "phone" || icon === "phone-slash")
        {
            return "horizontal";
        }
    }

    return (
        <FontAwesomeIcon
            icon={fetchIcon()}
            color={color}
            size={size}
            inverse={inverse}
            flip={flipIcon()}
            transform={transform}
            pulse={pulse}
            spin={spin}
            style={{ ...style, cursor: onClick && "pointer" }}
            onClick={onClick}
        />
    );
}
