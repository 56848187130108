import React, { useEffect, useState, useContext } from "react";
import { MuiPickersUtilsProvider, TimePicker, } from '@material-ui/pickers';
import moment from "moment";
import Icon from "../Icons/Icon";
import Modal from "../Modal/Modal";
import TextInput from "./TextInput";
import TimeField from 'react-simple-timefield';
import { FormButtons } from "../Form/FormButtons";
import DateFnsUtils from '@date-io/moment';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { ThemeContext } from "../../Contexts/ThemeContext";
import { FormControl } from "react-bootstrap";

export default function TimeInput({
    name,
    value,
    onChange,
    small,
    setError,
    reversed
}: ITimeInput)
{
    const { primaryColour, primaryColourDarker } = useContext(ThemeContext)
    const [selectedDate, handleDateChange] = useState(moment("00:00", "HH:mm"))
    const [pickerOpen, setPickerOpen] = useState(false)

    useEffect(() =>
    {
        if (value)
        {
            handleDateChange(moment(value, 'HH:mm'))
        }
        setError && setError({ target: { name, value: "" } })
    }, [value])

    const materialTheme = createMuiTheme({
        overrides: {
            //@ts-ignore
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: primaryColour,
                    thumb: {
                        backgroundColor: primaryColour,
                    }
                }
            },
            MuiPickersClock: {
                pin: {
                    backgroundColor: primaryColour,
                }
            },
            MuiPickersClockPointer: {
                pointer: {
                    backgroundColor: primaryColour,
                },
                thumb: {
                    backgroundColor: primaryColour,
                    border: `14px solid ${primaryColour}`
                }
            },
        },
    });

    function handleInputChange(val)
    {
        handleDateChange(val)
        if (onChange)
        {
            onChange({
                target: {
                    name,
                    type: "time",
                    value: moment(val).format("HH:mm")
                }
            })
        }
    }

    function handleAccept()
    {
        if (onChange)
        {
            onChange({
                target: {
                    name,
                    type: "time",
                    value: moment(selectedDate).format("HH:mm")
                }
            })
        }
        setPickerOpen(false)
    }

    function handleCancel()
    {
        setPickerOpen(false)
    }

    return (

        <div
            style={{
                display: "flex",
                alignItems: "center"
            }}
            className={`timePicker ${small ? "small" : ""}`}
        >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className={`timeInputContainer ${reversed ? "timeInputContainerReversed" : ""}`}>
                    <div
                        className={"clockIcon"}
                        onClick={() => setPickerOpen(!pickerOpen)}
                    >
                        <Icon
                            transform={"grow-5"}
                            icon={"calendar-day"}
                            variation={"regular"}
                            color={primaryColourDarker}
                        />
                    </div>

                    <TimeField
                        value={value}
                        onChange={e => handleInputChange(moment(e.target.value, "HH:mm"))}
                        input={<FormControl
                            size={small ? "sm" : null}
                            style={{ width: small && "80%" }}
                            type={"text"}
                            name={name}
                            onChange={onChange}
                            value={value !== undefined ? String(value) : ""}
                        />}
                    />

                </div>

                <Modal
                    maxWidth={325}
                    open={pickerOpen}
                    setOpen={setPickerOpen}
                    noPadding
                >
                    <ThemeProvider theme={materialTheme}>
                        <TimePicker
                            ampm={false}
                            variant={"static"}
                            orientation={"portrait"}
                            value={selectedDate}
                            onChange={handleDateChange}
                            autoOk={false}
                        />
                    </ThemeProvider>
                    <div
                        style={{ padding: 8 }}
                    >
                        <FormButtons
                            submitText={"Set Time"}
                            onSubmit={handleAccept}
                            onCancel={handleCancel}
                        />
                    </div>
                </Modal>

            </MuiPickersUtilsProvider>
        </div>
    )
}