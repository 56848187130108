import React from "react"
import { TabbedCardFormPage } from "../../components/Form/TabbedCardFormPage"
import { useSMSFormPage } from "./useSMSFormPage"
import { RecordModal } from "../../components/RecordModal/RecordModal"
import { permissions } from "../../Constants/permissions"

export default function SMSFormPage({ prop }: any)
{
    const { id } = prop.match.params

    const {
        loading,
        tabs,
        state,
        updateForm,
        updateErrors,
        addModal,
        customButtons,
        deleteModal,
        // changes,
        // resetChanges,
    } = useSMSFormPage(id)

    return (
        <React.Fragment>
            <TabbedCardFormPage
                configIdentifier={state.name}
                state={state}
                updateForm={updateForm}
                updateErrors={updateErrors}
                // changes={changes}
                // resetChanges={resetChanges}
                tabs={tabs}
                loading={loading}
                customButtons={customButtons}
                onDelete={() => deleteModal.setOpen(true)}
                displayName={"SMS"}
                permissions={[permissions.mobile, permissions.admin, permissions.VHAdmin]}
            />
            <RecordModal
                displayName={"SMS"}
                defineSubmitData={addModal.onSubmit}
                settingsStages={addModal.settingsStages}
                action={"create"}
                open={addModal.open}
                setOpen={addModal.setOpen}
            />
            <RecordModal
                displayName={"SMS"}
                defineSubmitData={deleteModal.onSubmit}
                action={"delete"}
                uniqueIdentifier={"smsOriginator"}
                selectedRecords={[state]}
                open={deleteModal.open}
                setOpen={deleteModal.setOpen}
            />
        </React.Fragment>
    )
}
