import { useEffect, useState } from "react";
import { useRequest } from "../../Hooks/useRequest";
import { useFormReducer } from "../../Hooks/useFormReducer";
import useAPIRequests from "../../Hooks/useAPIRequests";
import { regex } from "../../Constants/regex";
import { regexError } from "../../Constants/regexError";

export function useConferencingFormPage(id: number)
{
    const [record, setRecord] = useState({})
    const [loading, setLoading] = useState(true)
    const { getRequest } = useRequest()
    const { getMusicOnHoldPromise } = useAPIRequests()

    useEffect(() =>
    {
        async function pullData()
        {
            setLoading(true)
            let data = {}

            const conferencingResponse = await getRequest(`/conferencing/${id}`)

            if (conferencingResponse)
            {
                data = {
                    ...data,
                    conferencing: conferencingResponse,
                }
            }

            setRecord(data)
            setLoading(false)
        }

        pullData()
        // eslint-disable-next-line
    }, [id])

    const {
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
    } = useFormReducer({ record })

    function deleteData()
    {
        return [{
            path: "/conferencing",
            type: "delete",
            indexPagePath: "/conferencing",
            displayName: "Conference Room"
        }]
    }

    function submitData()
    {
        return [
            {
                body: {
                    nickname: state.conferencing.nickname,
                    moh: state.conferencing.moh,
                    announceUsers: state.conferencing.announceUsers,
                    listenOnly: state.conferencing.listenOnly,
                    waitForMarked: state.conferencing.waitForMarked,
                    mohClass: state.conferencing.mohClass,
                    pin: state.conferencing.pin,
                    adminPin: state.conferencing.adminPin
                },
                path: `/conferencing/${id}`,
                formPrefix: "conferencing",

            }
        ]
    }

    const tabs: ITab[] = [
        {
            title: "General",
            icon: "toolbox",
            disabled: false,
            sections: [
                {
                    title: "Overview",
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "The conferencing service can be accessed using On-net SIP phones or via the Off-net" +
                                " telephone number below. The conference room can host up to 100 participants at any one time.",
                        },
                        {
                            type: "paragraph",
                            value:
                                "To use the conferencing service please dial one of the access numbers below and" +
                                " follow the spoken prompts. You will be asked for the 'Conference Number' and one of" +
                                " the 'PIN' numbers specified below.",
                        },
                    ],
                },
                {
                    title: "Conference Details",
                    fields: [
                        {
                            label: "Conference Number",
                            type: "info",
                            name: "conferencing.confNumber",
                            helptext: "Room number to enter the conference"
                        },
                        {
                            label: "Nickname",
                            type: "text",
                            name: "conferencing.nickname",
                            maxLength: 50,
                        }
                    ]
                },
                {
                    title: "Features",
                    fields: [
                        {
                            label: "Listen Only",
                            type: "switch",
                            name: "conferencing.listenOnly",
                            halfWidth: true,
                            helptext: "Allow the standard attendees to only listen to the conference."
                        },
                        {
                            label: "Wait for Admin",
                            type: "switch",
                            name: "conferencing.waitForMarked",
                            halfWidth: true,
                            helptext: "Start the conference only when the Admin user joins."
                        },
                        {
                            label: "Announce Attendee",
                            type: "switch",
                            name: "conferencing.announceUsers",
                            halfWidth: true,
                            helptext: "Announce the attendees as they join and leave the conference."
                        },
                        {
                            label: "Music on Hold",
                            type: "switch",
                            name: "conferencing.moh",
                            halfWidth: true,
                            helptext: "Play music on hold while only one attendee is joined."
                        },
                        {
                            label: "Music on Hold File",
                            type: "select",
                            loadOptions: getMusicOnHoldPromise,
                            name: "conferencing.mohClass",
                            helptext: "This option allows you to select the music, when the conference only has one attendee."
                        },
                    ],
                },
                {
                    title: "Security",
                    fields: [
                        {
                            label: "PIN",
                            type: "text",
                            name: "conferencing.pin",
                            regex: regex.pin,
                            regexError: regexError.pin,
                            maxLength: 20,
                            helptext: "PIN for standard attendees."
                        },
                        {
                            label: "Admin PIN",
                            type: "text",
                            name: "conferencing.adminPin",
                            regex: regex.pin,
                            regexError: regexError.pin,
                            maxLength: 20,
                            helptext: "PIN for the administrator which allows the conference to be locked or unlocked, and eject the last attendee that dialled in."
                        },
                    ],
                },
                {
                    title: "Access Numbers",
                    fields: [
                        {
                            label: "On-net",
                            type: "info",
                            value: "155",
                        },
                        {
                            label: "Off-net",
                            type: "info",
                            value:
                                "0843 5575575 (Calls charged at 5ppm plus your providers access charge)",
                        },
                    ],
                },
            ],
        },
    ]

    return {
        loading,
        tabs,
        state,
        updateState,
        updateForm,
        updateErrors,
        submitData,
        deleteData,
        // changes,
        // resetChanges,
    }
}
