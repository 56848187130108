import moment from "moment"
import React, { useEffect, useState } from "react"
import useAPIRequests from "../../Hooks/useAPIRequests"
import { useFormReducer } from "../../Hooks/useFormReducer"
import { useRequest } from "../../Hooks/useRequest"

export function useTimeProfileCalendarFormPages(id: number, timeProfileId: number)
{
    const [loading, setLoading] = useState(true)
    const { getRequest } = useRequest()
    const {
        state,
        updateState,
        updateForm,
        updateErrors
    } = useFormReducer({ record: {} })
    const { getDestinationPromise } = useAPIRequests()

    useEffect(() =>
    {
        async function pullData()
        {
            setLoading(true)
            let response = await getRequest(`/timeprofilecalendar/${id}`)

            if (response)
            {
                let timeProfileResponse = await getRequest(`/timeprofile/${timeProfileId}`)

                if (timeProfileResponse)
                {
                    updateState({
                        ...response,
                        start: moment(response.start, "DD/MM/YYYY HH:mm").format("YYYY-MM-DDTHH:mm"),
                        end: moment(response.end, "DD/MM/YYYY HH:mm").format("YYYY-MM-DDTHH:mm"),
                        timeProfile: timeProfileResponse
                    })
                }
                else
                {
                    updateState({
                        ...response,
                        start: moment(response.start, "DD/MM/YYYY HH:mm").format("YYYY-MM-DDTHH:mm"),
                        end: moment(response.end, "DD/MM/YYYY HH:mm").format("YYYY-MM-DDTHH:mm")
                    })
                }
            }
            setLoading(false)
        }

        pullData()
    }, [id])

    const tabs: ITab[] = [
        {
            title: "General",
            icon: "toolbox",
            sections: [
                {
                    fields: [
                        {
                            type: "text",
                            label: "Description",
                            name: "description",
                            maxLength: 100
                        },
                        {
                            type: "select",
                            label: "Destination",
                            name: "destination",
                            options: [{ label: "None", value: "none" }],
                            loadOptions: () =>
                                getDestinationPromise({
                                    extensionMap: false,
                                    highPriorityQueue: false,
                                    excludeObjectRegex: "^TIMEPROFILE\\/" + state.timeProfile.name.replace("*", "\\*") + "$"
                                }),
                        },
                        {
                            type: "dateTime",
                            label: "Start",
                            name: "start",
                        },
                        {
                            type: "dateTime",
                            label: "End",
                            name: "end",
                        },
                    ]
                }
            ]
        }
    ]

    function saveTimeProfileCalendar()
    {
        return [
            {
                body: {
                    start: moment(state.start, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                    end: moment(state.end, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                    destination: state.destination,
                    description: state.description,
                },
                path: "/timeprofilecalendar/" + id,
            },
        ]
    }

    function deleteTimeProfileCalendar()
    {
        return [{
            path: "/timeprofilecalendar",
            displayName: "Time Profile Calendar",
            type: "delete",
            indexPagePath: "/time-profiles/" + timeProfileId
        }]
    }

    return {
        loading,
        tabs,
        state,
        updateForm,
        updateErrors,
        saveTimeProfileCalendar,
        deleteTimeProfileCalendar
    }
}