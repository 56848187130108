import React from "react";
import Card from "../Card/Card";
import "./modal.scss";
import Icon from "../Icons/Icon";

interface IProps
{
    open?: boolean
    setOpen: (val: boolean) => void
    children: React.ReactNode
    maxWidth?: number
    minHeight?: number
    noPadding?: boolean
    onClose?: () => void
    scrollable?: boolean
}

export default function Modal({
    open,
    setOpen,
    children,
    maxWidth,
    minHeight,
    noPadding,
    onClose,
    scrollable
}: IProps)
{
    return open ? (
        <div className="modal-overlay">
            <div
                className="modal-container"
                style={{
                    maxWidth: `${maxWidth ? maxWidth : 1200}px `,
                    overflowY: scrollable ? "auto" : "visible",
                    overflowX: scrollable ? "auto" : "visible"
                }}
            >
                <Card
                    noPadding={noPadding}
                    className={"modal-card"}
                    style={{
                        minHeight: minHeight && minHeight,
                    }}
                >
                    <div
                        className={"close-modal-button"}
                        onClick={onClose ? () => onClose() : () => setOpen(false)}
                    >
                        <Icon icon={"times"} />
                    </div>
                    {children}
                </Card>
            </div>
        </div>
    ) : null;
}
