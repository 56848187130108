import { NavLink } from "react-router-dom";
import React from "react";
import "./successPageText.scss"


interface IProps
{
    success: string,
    text: string,
    link?: {
        url: string,
        text: string
    }
}

export default function SuccessPageText({ success, text, link }: IProps)
{
    return (
        <div
            className={"successTextContainer"}
            style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                paddingTop: 80
            }}
        >
            <span className="successText">{success}</span>
            <span className="successSecondaryText">{text}</span>
            {link && (
                <span className="successLink">
                    <NavLink to={link.url}>{link.text}</NavLink>
                </span>
            )}
        </div>
    )
}