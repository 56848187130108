import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage";
import React, { useContext, useEffect, useState } from "react";
import useAPIRequests from "../../Hooks/useAPIRequests";
import { useRequest } from "../../Hooks/useRequest";
import { SessionContext } from "../../Contexts/SessionContext";
import { hasPermission, permissions } from "../../Constants/permissions";
import Modal from "../../components/Modal/Modal";
import { FormButtons } from "../../components/Form/FormButtons";
import { ToastContext } from "../../Contexts/ToastContext";
import { useHistory } from "react-router";

export default function PhoneDirectoryIndexPage()
{
    const { getDestinationPromise } = useAPIRequests()
    const { postRequest, getRequest } = useRequest()
    const { state: sessionState } = useContext(SessionContext)
    const [open, setOpen] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const history = useHistory()
    const { add } = useContext(ToastContext)

    const columns: IColumn[] = [
        {
            name: "text",
            title: "Directory",
            link: { root: "/phone-directory" },
            bold: true,
        },
    ]

    function checkExists(fieldFrom): Promise<{ success: boolean, message: string }>
    {
        return new Promise(async resolve =>
        {

            if (!fieldFrom)
            {
                resolve({ success: false, message: "Please select an seat" })
            }

            let response = await postRequest({}, `/ldap/overview/-1/-1`)

            if (response)
            {
                if (response.records)
                {
                    const filteredRecords = response.records.filter(record => record.id === fieldFrom.split('*')[1])

                    if (filteredRecords && filteredRecords.length > 0)
                    {
                        resolve({ success: false, message: "This directory already exists" })
                    } else
                    {
                        resolve({ success: true, message: "" })
                    }
                } else
                {
                    resolve({ success: false, message: "Failed to retrieve data, please try again" })
                }
            } else
            {
                resolve({ success: false, message: "Failed to retrieve data, please try again" })
            }
        })
    }

    useEffect(() =>
    {
        async function checkInitialised()
        {
            let response = await postRequest({}, `/ldap/overview/-1/-1`)
            if (response && response.totalRecords == 0)
            {
                setOpen(true)
            }
        }
        checkInitialised()
    }, [])

    function goToDashboard()
    {
        history.push("/")
    }

    async function initialiseLDAP()
    {
        return await getRequest(`/ldap/${sessionState.session.account_no}/initialise`)
    }

    const customModalButton: IButton =
    {
        variant: "danger",
        iconLeft: "user-slash",
        color: "white",
        iconVariation: "regular",
        children: "Delete Children",
        requireSelected: true
    }

    return (
        <React.Fragment>
            <GenericIndexPage
                title={"Hosted Phone Directory"}
                urlString={"/ldap/overview"}
                restType={"POST"}
                searchable={true}
                columns={columns}
                customModalButton={hasPermission([permissions.ldap, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "delete") && customModalButton}
                permissions={[permissions.ldap, permissions.admin, permissions.VHAdmin]}
                addModal={{
                    displayName: "Phone Directory",
                    allowBulkAdd: false,
                    fieldFrom: {
                        field: "Extension",
                        label: "Seat",
                        type: "select",
                        loadOptions: () => getDestinationPromise({
                            seats: true,
                            queues: false,
                            voicemail: false,
                            groups: false,
                            ivrs: false,
                            timeProfiles: false,
                            externalDest: false,
                            sipTrunks: false,
                            dialThrough: false,
                            queueTrunkMaps: false,
                            extensionMap: false,
                            highPriorityQueue: false,
                            sipTrunkMap: false,
                        }),
                    },
                    availabilityCheck: (fieldFrom) =>
                    {
                        return new Promise(async resolve =>
                        {

                            const responseMessage = await checkExists(fieldFrom)

                            if (responseMessage && responseMessage.success)
                            {
                                resolve({ success: true })
                            } else
                            {
                                resolve({ success: false, error: responseMessage.message })
                            }
                        })
                    },
                    submitData: (data) =>
                    {
                        return [{
                            postData: {},
                            path: `/ldap/directory/${data.fieldFrom && data.fieldFrom.replace("SIP/", "").replace("*", "/")}`,
                            type: "create",
                            displayName: data.fieldFrom
                        }]
                    }
                }}
                deleteModal={{
                    displayName: "LDAP Directory",
                    uniqueIdentifier: "text",
                    submitData: (data) =>
                    {
                        return [{
                            path: `/ldap/${sessionState.session.account_no}`,
                            identifier: `${data.directory}`,
                            displayName: "LDAP Directory",
                            type: "delete",
                        }]
                    }
                }}
                customModal={{
                    displayName: "LDAP Directory Children",
                    uniqueIdentifier: "text",
                    submitData: (data) =>
                    {
                        return [{
                            path: `/ldap/${sessionState.session.account_no}`,
                            identifier: `${data.directory}`,
                            displayName: "LDAP Directory",
                            postData: {},
                            type: "edit",
                            pathSuffix: `/children`
                        }]
                    }
                }}
                helpSections={[
                    {
                        title: "Phone Directory",
                        text: "Manage the Hosted Phone Directory for your account."
                    }
                ]}
            />
            <Modal setOpen={setOpen} open={open} maxWidth={600} onClose={goToDashboard}>
                <h4 style={{ paddingBottom: 12 }}>
                    Initialise LDAP on this account?
                </h4>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <p>Your account does not currently have a phone directory assigned to it. <br></br> Would you like to initialise this now? </p>
                    <FormButtons
                        onCancel={() => goToDashboard()}
                        loading={submitting}
                        submitText={"Initialise"}
                        onSubmit={async () =>
                        {
                            setSubmitting(true)
                            const response = await initialiseLDAP()
                            if (response && response.success)
                            {
                                add({
                                    type: "success",
                                    text: "Phone Directory Initialised successfully"
                                })
                                setSubmitting(false)
                                setOpen(false)
                                history.go(0)
                            } else
                            {
                                add({
                                    type: "error",
                                    text: "There was an error initialising the Phone Directory on this account."
                                })
                            }
                            setSubmitting(false)
                        }}
                    />
                </div>
            </Modal>
        </React.Fragment>
    )
}