import React from "react"
import { Image } from "./Image"

export function Images({
    images
}: IImages)
{

    return (
        <React.Fragment>
            {images.map(image =>
                <Image
                    {...image}
                />
            )}
        </React.Fragment>
    )
}