import { createMuiTheme, Step, StepLabel, Stepper as StepperComponent, ThemeProvider } from "@material-ui/core"
import React, { useContext } from "react"
import { ThemeContext } from "../../Contexts/ThemeContext"

interface IStepper
{
    orientation: "vertical" | "horizontal"
    activeStep?: number
    steps: { label: string, error?: boolean }[]
}

export default function Stepper({ orientation, activeStep, steps }: IStepper)
{
    const { primaryColour, primaryColourDarker } = useContext(ThemeContext)

    const materialTheme = createMuiTheme({
        overrides: {
            MuiStepIcon: {
                root: {
                    color: `${primaryColour} !important`,
                },
                active: {
                    color: `${primaryColourDarker} !important`,
                },
            },

        }
    });
    return (
        <ThemeProvider theme={materialTheme}>
            <StepperComponent
                orientation={orientation}
                activeStep={activeStep}
                nonLinear
            >
                {steps.map((step) => (
                    <Step key={step.label}>
                        <StepLabel
                            error={step.error}
                        >
                            {step.label}
                        </StepLabel>
                    </Step>
                ))}
            </StepperComponent>
        </ThemeProvider>
    )
}