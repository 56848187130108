import { Breadcrumb } from "react-bootstrap";
import React, { useEffect, useState, useContext } from "react";
import "./breadcrumbs.scss"
import { breadcrumbSections } from "../../Constants/breadcumbSections";
import { ThemeContext } from "../../Contexts/ThemeContext";

interface IProps
{
    configIdentifier?: string
    specialIndices?: { href: string, text: string }[]
    subIndex?: boolean
}

export function Breadcrumbs({
    configIdentifier,
    specialIndices,
    subIndex
}: IProps)
{
    const [pageType, setPageType] = useState("dashboard")
    const [section, setSection] = useState(null)
    const { primaryColour } = useContext(ThemeContext)


    const [sectionObject, setSectionObject] = useState<{ baseUrl?: string, index?: string, subIndex?: string, config?: string }>({})

    useEffect(() =>
    {
        const url = window.location.pathname

        let splitUrl = url.split("/")

        if (splitUrl.length > 1)
        {
            setSection(splitUrl[1])
            const tempSectionObject = breadcrumbSections.filter(section => section.baseUrl === splitUrl[1])
            if (tempSectionObject)
            {
                setSectionObject(tempSectionObject[0])
            }

            if (splitUrl.length === 2)
            {
                if (splitUrl[1] && splitUrl[1] !== "")
                {
                    setPageType("index")
                } else
                {
                    setPageType("dashboard")
                }
            }
            else if (splitUrl.length > 2)
            {
                setPageType("config")
            }
        }
    }, [])

    return (
        <Breadcrumb>
            <Breadcrumb.Item
                href="/"
                active={pageType === "dashboard"}
                style={{
                    color: pageType !== "dashboard" && primaryColour
                }}
            >
                Dashboard
            </Breadcrumb.Item>
            {pageType !== "dashboard" && (
                <Breadcrumb.Item
                    active={pageType === "index"}
                    href={`/${section}`}
                    style={{
                        color: pageType !== "index" && primaryColour
                    }}
                >
                    {sectionObject && sectionObject.index}
                </Breadcrumb.Item>
            )}
            {pageType === "config" && specialIndices && specialIndices.map((specialIndex) =>
                <Breadcrumb.Item
                    href={`/${specialIndex.href}`}
                    style={{
                        color: primaryColour ? primaryColour : undefined
                    }}
                >
                    {specialIndex.text}
                </Breadcrumb.Item>
            )}
            {pageType === "config" && configIdentifier && subIndex && (
                <Breadcrumb.Item
                    active>
                    {sectionObject?.subIndex?.replace(":identifier", configIdentifier ? configIdentifier : "")}
                </Breadcrumb.Item>
            )}
            {pageType === "config" && configIdentifier && !subIndex && (
                <Breadcrumb.Item
                    active>
                    {sectionObject?.config?.replace(":identifier", configIdentifier ? configIdentifier : "")}
                </Breadcrumb.Item>
            )}
        </Breadcrumb>
    )
}
