import React, { useEffect, useState } from "react"
import Spinner from "../Spinner/Spinner";
import "./youtubeVideo.scss"
import { useRequest } from "../../Hooks/useRequest"

export default function YouTubeVideo({
}: IYouTubeVideo)
{
    const [videoIdList, setVideoIdList] = useState([])
    const [loading, setLoading] = useState(true)
    const { getRequest } = useRequest()

    useEffect(() =>
    {
        async function fetchYouTubeIDs()
        {
            setLoading(true)
            const youTubeResponse = await getRequest(`/youtube/videos`)
            if (youTubeResponse && youTubeResponse['videoIDs'])
            {
                setVideoIdList(youTubeResponse['videoIDs']);
            }
            setLoading(false)
        }

        fetchYouTubeIDs()
    }, [])

    return (
        <>
            <div className="videoParent">
                {videoIdList ? videoIdList.map((id) =>
                    <div className="videoChild">
                        <iframe id="video" allow="fullscreen" width="500" height="300"
                            src={`https://www.youtube.com/embed/${id}`}>
                        </iframe>
                    </div>
                ) : <Spinner />}
            </div>
        </>
    )
} 