import React, { useContext, useState } from "react"
import { SessionContext } from "../../Contexts/SessionContext"
import useAPIRequests from "../../Hooks/useAPIRequests"
import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage"
import { hasPermission, permissions } from "../../Constants/permissions"

export default function CallRecordsIndexPage()
{
    const { state: session } = useContext(SessionContext)
    const { getCDRMonths } = useAPIRequests()
    const [date, setDate] = useState()

    const filters: IFilterDropdown = {
        filters: [
            {
                name: "date",
                type: "select",
                label: "Month",
                loadOptions: () => getCDRMonths(),
                value: date,
                onChange: setDate,
            },
        ],
    }

    function getFilename()
    {
        let formatDate: String = date
        if (!formatDate)
        {
            let newDate = new Date()
            let month = newDate.getMonth() + 1
            let year = newDate.getFullYear()
            if (month.toString().length == 1)
            {
                formatDate = `${year}0${month}`
            }
            else
            {
                formatDate = `${year}${month}`
            }
        }
        {
            let formatYear = formatDate.substring(0, 4)
            let formatMonth = formatDate.substring(4, 6)
            switch (formatMonth)
            {
                case "01":
                    formatMonth = "January"
                    break
                case "02":
                    formatMonth = "February"
                    break
                case "03":
                    formatMonth = "March"
                    break
                case "04":
                    formatMonth = "April"
                    break
                case "05":
                    formatMonth = "May"
                    break
                case "06":
                    formatMonth = "June"
                    break
                case "07":
                    formatMonth = "July"
                    break
                case "08":
                    formatMonth = "August"
                    break
                case "09":
                    formatMonth = "September"
                    break
                case "10":
                    formatMonth = "October"
                    break
                case "11":
                    formatMonth = "November"
                    break
                case "12":
                    formatMonth = "December"
                    break
                default:
                    formatMonth = "Unknown"
                    break
            }
            return `CDRs for ${formatMonth} ${formatYear}`
        }
    }

    const columns: IColumn[] = [
        {
            title: "Date",
            name: "date",
            type: "date"
        },
        {
            title: "Time",
            name: "time",
        },
        {
            title: "Source",
            name: "source",
        },
        {
            title: "Destination",
            name: "destination",
        },
        {
            title: "TPS/CTPS",
            name: "tpsStatusText",
            conditional: [{ value: "TPS Not Checked", text: " " }]
        },
        {
            title: "Rejected",
            name: "rejectedCallReason",
        },
        {
            title: "Restricted",
            name: "restrictedCallReason",
        },
        {
            title: "Inbound #",
            name: "inboundNumber",
        },
        {
            title: "Duration",
            name: "mins",
        },
        hasPermission([permissions.retail], session.session.permissions, "read") && {
            title: "Cost",
            name: "cost",
            type: "money",
        },
    ]

    return (
        <GenericIndexPage
            title={"Call Records"}
            urlString={"/callrecords"}
            columns={columns}
            restType={"POST"}
            body={{}}
            filters={filters}
            hideTotalRecords={true}
            csvProperties={{
                restType: "post",
                url: "/callrecords/csv",
                postData: { "date": date },
                csvName: getFilename(),
                displayName: "Download"
            }}
        />
    )
}
