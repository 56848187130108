import queryString from 'query-string';
import { useContext, useEffect, useState } from "react";
import { capitaliseFirstLetter } from '../../Common/textHelpers';
import { hasPermission, permissions } from '../../Constants/permissions';
import { SessionContext } from '../../Contexts/SessionContext';
import { useFormReducer } from "../../Hooks/useFormReducer";
import { useRequest } from "../../Hooks/useRequest";

export function usePortingEmailFormPage(prop)
{
    const [record, setRecord] = useState({})
    const [loading, setLoading] = useState(true)
    const { state: session } = useContext(SessionContext)

    const {
        state,
        updateState,
        updateForm,
        updateErrors,
    } = useFormReducer({ record })

    const { postRequest } = useRequest()

    useEffect(() =>
    {
        async function pullData()
        {
            setLoading(true)
            const values = queryString.parse(prop.location.search)

            if (values != null && values.key != null && values.response != null)
            {
                let response = await postRequest({ "key": values.key, "accept": values.response === "accept" }, "/numbering/porting/transfer")
                response = { ...response, "response": values.response, "key": values.key }
                setRecord(response)
            }
            setLoading(false)
        }
        pullData()
    }, [])



    function getWholesalePorting(): ISection[]
    {
        if (state.wholesale)
        {
            return [
                {
                    title: "Wholesale Porting",
                    fields: [
                        {
                            type: "paragraph",
                            value: "The Wholesale Customer / Reseller acknowledges and accepts that Number Portability is provided "
                                + "for the benefit of the End User. Wholesale Ports (where the Wholesale Customer / Reseller is remaining "
                                + "in the supply chain to the End User) are expressly prohibited."
                        },
                        {
                            type: "paragraph",
                            value: "I declare that this is not wholesale porting and accept any additional fees if found to be otherwise.",
                        },
                        {
                            type: "checkbox",
                            onChange: updateForm,
                            label: "I agree to the above.",
                            name: "resellerExport",
                            defaultValue: false,
                            required: true
                        }
                    ]
                }
            ]
        }
        else
        {
            return []
        }
    }

    function getConfirmedResponseFields(): IFormField[]
    {
        if (state.wholesale)
        {
            return [
                {
                    type: "paragraph",
                    value: "A response has been received. If you need to make amendments, please contact the porting desk."
                },
            ]
        }
        else
        {
            return [
                {
                    type: "paragraph",
                    value: "A response has been received. If you need to make amendments, please contact the porting desk."
                },
                {
                    type: "paragraph",
                    value: state.ofcomnumberexport_aot_response === "Accept" ? "Please note, an invoice has been created in your account. Please pay this now." : ""
                }
            ]
        }
    }

    function getSections(): ISection[]
    {
        if (state
            && state.ofcomnumberexport_aot_date_response === null
            && state.ofcomnumberexport_date_rejected === null
            && state.ofcomnumberexport_date_cancelled === null
            && state.ofcomnumberexport_status !== "AOT Not Required")
        {
            return [
                {
                    title: "Transfer Details",
                    fields: [
                        {
                            type: "info",
                            label: "Telephone",
                            name: "ofcomnumberexport_number",
                        },
                        {
                            type: "info",
                            label: "Port Date",
                            name: "ofcomnumberexport_date_porting"
                        },
                        {
                            type: "info",
                            label: "Account",
                            name: "account_no"
                        },
                        {
                            type: "info",
                            label: "Status",
                            name: "ofcomnumberexport_status"
                        },
                    ]
                },
                ...getWholesalePorting(),
                {
                    title: "Admininstration Fees",
                    fields: [
                        {
                            type: "paragraph",
                            fullWidth: !state.wholesale,
                            value: "Administration fees apply to all number exports, please refer to your tariff for charges. By accepting the AOT you acknowledge the administration fee and agree to settle the amount when presented."
                        }
                    ]

                },
                {
                    title: "Response",
                    fields: [
                        {
                            type: "select",
                            name: "response",
                            label: "Response",
                            options: [
                                { label: "Accept", value: "accept" },
                                { label: "Reject", value: "reject" }
                            ],
                            required: true
                        },
                        {
                            type: "text",
                            label: "Reason",
                            name: "reason",
                            required: true,
                            minLength: 10,
                        }
                    ]
                },
                {
                    title: "Your Details",
                    fields: [
                        {
                            type: "text",
                            label: "Name",
                            name: "name",
                            required: true
                        },
                        {
                            type: "text",
                            label: "Company Name",
                            name: "companyName",
                            required: true
                        }
                    ]
                }
            ]
        }
        else if (state
            && state.ofcomnumberexport_status === "AOT Not Required")
        {
            return [{
                title: "Details",
                fullWidth: true,
                fields: [
                    {
                        type: "paragraph",
                        value: "AOT not required for this number. If you believe this is an error, please contact the porting desk."
                    },
                ]
            }]
        }
        else if (state
            && state.ofcomnumberexport_date_rejected
            && state.ofcomnumberexport_date_rejected !== null)
        {
            return [{
                title: "Details",
                fullWidth: true,
                fields: [
                    {
                        type: "paragraph",
                        value: `AOT was rejected ${state.ofcomnumberexport_date_rejected}. If you believe this is an error, please contact the porting desk.`
                    },
                ]
            }]
        }
        else if (state
            && state.ofcomnumberexport_date_cancelled
            && state.ofcomnumberexport_date_cancelled !== null)
        {
            return [{
                title: "Details",
                fullWidth: true,
                fields: [
                    {
                        type: "paragraph",
                        value: `AOT was cancelled ${state.ofcomnumberexport_date_cancelled}. If you believe this is an error, please contact the porting desk.`
                    },
                ]
            }]
        }
        else
        {
            return [{
                title: "Details",
                fullWidth: true,
                fields: getConfirmedResponseFields()
            }]
        }
    }

    function submitData()
    {
        if (state
            && state.ofcomnumberexport_aot_date_response === null
            && state.ofcomnumberexport_date_rejected === null
            && state.ofcomnumberexport_date_cancelled === null
            && state.ofcomnumberexport_status !== "AOT Not Required")
        {
            return [
                {
                    body: {
                        "resellerExport": state.resellerExport,
                        "companyName": state.companyName,
                        "response": capitaliseFirstLetter(state.response),
                        "reason": state.reason,
                        "name": state.name,
                        "key": state.key,
                    },
                    restType: "POST",
                    path: "/numbering/porting/transfer/response",
                    refreshOnSuccess: true,
                },
            ]
        }
        else
        {
            return null
        }
    }

    const tabs: ITab[] = [
        {
            title: "Advice Of Transfer (AOT)",
            icon: "toolbox",
            disabled: false,
            sections: getSections()
        }
    ]


    return {
        loading,
        tabs,
        state,
        updateState,
        updateForm,
        updateErrors,
        submitData
    }
}