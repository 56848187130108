import React from "react";
import "./helperBar.scss"
import Button from "../Button";

interface IProps
{
    sections: IHelpSection[]
    open?: boolean
}

export default function HelperBar({ open, sections }: IProps)
{

    return (
        <div
            className={`helperBarContainer ${open ? "helperBarContainerOpen" : ""}`}
        >
            <div className={"helperBarContent"}>
                <div className={"helperBarTitle"}>
                    Help<b>Dot</b>
                </div>
                {sections.map(section => (
                    <div
                        className={"helperBarItem"}
                    >
                        <div>
                            <span className={"helperBarItemTitle"}>{section.title}</span>
                        </div>
                        <span className={"helperBarItemText"}>
                            {section.text}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    )
}