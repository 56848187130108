import { useContext, useEffect, useState } from "react"
import { SessionContext } from "../../Contexts/SessionContext"
import { ToastContext } from "../../Contexts/ToastContext"
import useAPIRequests from "../../Hooks/useAPIRequests"
import { useFormReducer } from "../../Hooks/useFormReducer"
import { useRequest } from "../../Hooks/useRequest"

export function useSoftphoneCLIsFormPage(id: number)
{

    const [record, setRecord] = useState({})
    const [loading, setLoading] = useState(true)
    const { getRequest } = useRequest()
    const { add } = useContext(ToastContext)
    const {
        getCallerIdPromise
    } = useAPIRequests()

    const {
        state,
        updateState,
        updateForm,
        updateErrors
    } = useFormReducer({ record })

    const { state: sessionState } = useContext(SessionContext)

    async function pullData()
    {
        setLoading(true)

        const response = await getRequest(`/outboundclilist/${id}`)
        if (response?.success !== false)
        {
            setRecord(response)
        }
        else
        {
            add({
                type: "error",
                text: "An error occured loading this record."
            })
        }
        setLoading(false)
    }

    useEffect(() =>
    {
        pullData()
    }, [id])

    const tabs: ITab[] = [
        {
            title: "General",
            icon: "toolbox",
            disabled: false,
            sections: [
                {
                    title: "Overview",
                    fullWidth: true,
                    fields: [
                        {
                            type: "text",
                            label: "Name",
                            name: "name",
                        },
                        {
                            type: "text",
                            label: "Description",
                            name: "description"
                        },
                        {
                            type: "select",
                            label: "Available Numbers",
                            name: "numbers",
                            multi: true,
                            loadOptions: getCallerIdPromise,
                        }
                    ]
                }]
        }
    ]

    function submitData()
    {
        return [{
            body: {
                name: state.name,
                description: state.description,
                numbers: state.numbers
            },
            path: `/outboundclilist/${id}`,
            restType: "POST"
        }]
    }

    function deleteData()
    {
        return [{
            path: `/outboundclilist`,
            indexPagePath: "/outbound-clis",
            restType: "DELETE"
        }]
    }


    return {
        loading,
        tabs,
        state,
        updateForm,
        updateErrors,
        submitData,
        deleteData,
        pullData
    }
}