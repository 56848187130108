import React, { useState } from "react"
import { TabbedCardFormPage } from "../../components/Form/TabbedCardFormPage"
import { useCallRecordingFormPage } from "./useCallRecordingFormPage"

export default function CallRecordingFormPage({ prop }: any)
{
    const { id } = prop.match.params
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    const {
        loading,
        tabs,
        state,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
    } = useCallRecordingFormPage(id)

    return (
        <React.Fragment>
            <TabbedCardFormPage
                state={state}
                updateForm={updateForm}
                updateErrors={updateErrors}
                tabs={tabs}
                // changes={changes}
                // resetChanges={resetChanges}
                loading={loading}
                displayName={"Call Recording"}
            />
        </React.Fragment>
    )
}
