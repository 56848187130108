import React, { useEffect } from "react";
import { useRequest } from "../../Hooks/useRequest";
import queryString from 'query-string';
import { useHistory } from "react-router-dom";
import { sipconvergenceReformatter } from "../../Common/urlHelpers";

export default function ZohoCallbackPage({ prop }: any)
{
    const { postRequest } = useRequest()

    const history = useHistory()

    useEffect(() =>
    {
        async function generateToken()
        {
            const values = queryString.parse(prop.location.search)

            if (values != null && values.code != null && values.location != null)
            {
                await postRequest({ "code": values.code, "location": values.location }, "/zoho/token/creation")
            }
            history.push("/integrations")
        }
        if (prop && prop !== null)
        {
            generateToken()
        }
    }, [prop])

    return (
        <div>
            <meta http-equiv="Refresh" content={sipconvergenceReformatter("https://cp3.voicehost.co.uk/integrations")} />
        </div>
    )
}