import React, { useEffect, useState } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import './formInputs.scss'
import Button from "../Button";
import Icon from "../Icons/Icon";

export default function SearchInput({ placeholder, style, onSearch, presetValue }: { placeholder?: string, style?: { [key: string]: number | string }, onSearch: (value) => void, presetValue?: string })
{

    const [value, setValue] = useState<any>()

    function handleSearch()
    {
        onSearch(value)
    }

    function handleKeyPress(e)
    {
        if (e.key === 'Enter')
        {
            handleSearch()
        }
    }

    useEffect(() =>
    {
        setValue(presetValue)
    }, [presetValue])

    return (
        <div className="flex-row">
            <InputGroup className="inputGroupNoMargin mb-3">
                <FormControl
                    placeholder={placeholder}
                    style={{ ...style, maxWidth: 200 }}
                    type={"search"}
                    value={value}
                    onKeyPress={handleKeyPress}
                    onChange={e => setValue(e.target.value)}
                />
                <Button
                    variant={"outline-primary"}
                    onClick={handleSearch}
                    style={{
                        borderColor: "#ced4da",
                        borderLeft: "none",
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        boxShadow: "none"
                    }}
                >
                    <Icon icon={"search"} />
                </Button>
            </InputGroup>
        </div>
    )
}
