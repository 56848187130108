import React, { useContext, useEffect } from "react";
import StagedForm from "../../components/Form/StagedForm";
import Modal from "../../components/Modal/Modal";
import { SessionContext } from "../../Contexts/SessionContext";
import { useFormReducer } from "../../Hooks/useFormReducer";
import { useRequest } from "../../Hooks/useRequest";
import useAPIRequests from "../../Hooks/useAPIRequests";
import { regex } from "../../Constants/regex";
import { regexError } from "../../Constants/regexError";
import { useNetbanx } from "../../Hooks/useNetbanx";

interface IInvoicePaymentModal
{
    open?: boolean
    setOpen: (val: boolean) => void
    invoice?: any

}

export default function InvoicePaymentModal({ open, setOpen, invoice }: IInvoicePaymentModal)
{
    const { state, updateForm } = useFormReducer({
        record: {
            paymentType: "bankTransfer",
            amount: invoice.total,
            vat: invoice.vat,
            autoBill: false,
            success: false
        }
    })
    const { postRequest } = useRequest()
    const { state: session } = useContext(SessionContext)

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            maxWidth={800}
        >
            <StagedForm
                onComplete={() => setOpen(false)}
                modal
                stages={
                    [
                        {
                            title: "Details",
                            fields: [
                                {
                                    type: "paragraph",
                                    value: `Please confirm that you would like to pay invoice number <span style="font-weight: 500">${invoice.id}</span> by clicking <span style="font-weight: 500">'Next'</span>.`
                                },
                                {
                                    type: "info",
                                    value: `£${state.amount.toFixed(2)} (including £${(state.vat).toFixed(2)} VAT)`,
                                    label: "Amount"
                                },
                                {
                                    type: "paragraph",
                                    value: `If you would like to make payments automatically using your credit card in the future, please select the option 
                                    below. When an invoice is raised on your account an automated debit will be taken from your card three days after the 
                                    invoice date.`
                                },
                                {
                                    type: "checkbox",
                                    label: "Automatic Payments",
                                    name: "autoBill",
                                    onChange: updateForm,
                                    value: state.autoBill
                                },
                            ],
                        },
                        {
                            title: "Payment",
                            fields: [
                                {
                                    type: "creditCard",
                                    paymentType: "invoice",
                                    invoiceNumber: invoice.id,
                                    cardAutoBill: state.autoBill,
                                    cardAmount: state.amount
                                }
                            ],
                        },
                    ].filter(Boolean)
                }
            />
        </Modal>
    )
}