import { useHistory } from "react-router-dom";
import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage";
import React, { useContext } from "react";
import { SessionContext } from "../../Contexts/SessionContext";
import { hasPermission, permissions } from "../../Constants/permissions";

export default function CustomPromptsIndexPage()
{
    const { state: sessionState } = useContext(SessionContext)
    const history = useHistory()

    const columns: IColumn[] = [
        {
            title: "Name",
            onClick: id =>
            {
                history.push(`/custom-prompts/${id}`)
            },
            bold: true,
            name: "name",
            sortBy: true,
        },
        {
            title: "Used In",
            name: "count",
            sortBy: true,
        }
    ]

    const deleteModalSettings: IModalSettings = {
        displayName: "Custom Prompt",
        uniqueIdentifier: "name",
        submitData: data =>
        {
            return [{
                path: "/prompt",
                type: "delete"
            }]
        }
    }

    function defineData(data)
    {
        const postData = new FormData()
        postData.append("name", data.name)
        postData.append("message", data.musicFile)

        return {
            body: postData,
            path: "/prompt"
        }
    }
    function buildDisplay(response)
    {
        let failures = []
        let successes = []
        let multiResponse = false
        let message = ""
        let failureTitle = ""
        let successTitle = ""

        if (response && response.success)
        {
            message = "Custom Prompt added successfully"
        }
        else if (response && response.message)
        {
            message = response.message
        }
        else
        {
            message = "Something went wrong, please try again"
        }

        const feedback = {
            multiResponse: multiResponse,
            message: message,
            failures: failures,
            failureTitle: failureTitle,
            successTitle: successTitle,
            successes: successes,
        }

        return feedback
    }

    const fileUploadModal: IUploadFileModalSettings = {
        buttonProps: {
            variant: "success",
            iconLeft: "file-audio",
            color: "white",
            iconVariation: "regular",
            children: "Upload",
            leftSide: true
        },
        multiResponse: false,
        customTitle:
            "Please upload a custom prompt",
        sectionTitle: "Custom Prompt",
        fields: [
            {
                type: "paragraph",
                value: "Custom Prompt must be a .mp3 or .wav file, and must not exceed 20MB"
            },
            {
                type: "text",
                name: "name",
                label: "Filename",
                required: true
            },
            {
                type: "fileUpload",
                name: "musicFile",
                label: "Custom Prompt",
                fileType: "mp3/wav",
                accept: ["audio/mpeg", "audio/wav"],
                maxSizeBytes: 20 * 1000 * 1000, //20MB
                required: true
            }
        ],
        defineSubmitData: (data) =>
        {
            return new Promise(async (resolve) =>
            {
                const createData = await defineData(data)
                resolve(createData)
            })
        },
        displayResponse: (response) =>
        {
            return new Promise(async (resolve) =>
            {
                const createData = await buildDisplay(
                    response
                )
                resolve(createData)
            })
        },
    }


    return (
        <GenericIndexPage
            title={"Custom Prompt"}
            urlString={"/prompt/overview"}
            columns={columns}
            searchable={true}
            restType={"POST"}
            permissions={[permissions.prompts, permissions.admin, permissions.VHAdmin]}
            deleteModal={deleteModalSettings}
            uploadFileModal={hasPermission([permissions.prompts, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "create") && fileUploadModal}
            helpSections={[
                {
                    title: "Custom Prompts",
                    text: "These are sound files which can be assigned to play within Call Queues, IVR Menus and Time Profiles."
                }
            ]}
        />

    )
}