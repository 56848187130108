import React, { useContext } from "react"
import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage"
import { useHistory } from "react-router-dom"
import useAPIRequests from "../../Hooks/useAPIRequests"
import { useRequest } from "../../Hooks/useRequest"
import { SessionContext } from "../../Contexts/SessionContext"
import { permissions } from "../../Constants/permissions"

export default function TimeProfilesIndexPage()
{
    const history = useHistory()
    const { getDestinationPromise, getPromptPromise } = useAPIRequests()
    const { postRequest, getRequest } = useRequest()
    const { state: sessionState } = useContext(SessionContext)

    async function getLatestTimeProfile()
    {
        const response = await postRequest({}, "/timeprofile/next")

        if (response)
        {
            return Number(response)
        }
    }

    const columns: IColumn[] = [
        {
            name: "extension",
            title: "Profile",
            onClick: (id) =>
            {
                history.push(`/time-profiles/${id}`)
            },
            bold: true,
            sortBy: true,
            sortByName: "name",
        },
        {
            name: "description",
            title: "Name",
            sortBy: true,
        },
    ]

    const modalSettings: ISettingsStage[] = [
        {
            title: "Settings",
            fields: [
                {
                    type: "text",
                    label: "Name",
                    name: "description",
                    required: true,
                    maxLength: 100
                },
                {
                    type: "select",
                    label: "UK Bank Holidays",
                    name: `bankHoliday`,
                    options: [
                        { value: 0, label: "Disabled" },
                        { value: 1, label: "England & Wales" },
                        { value: 2, label: "Scotland" },
                        { value: 3, label: "Northern Ireland" },
                    ],
                    defaultValue: 0
                },
                {
                    type: "select",
                    label: "Active Message",
                    name: `inPromptId`,
                    loadOptions: () => getPromptPromise(),
                    options: [{ label: "None", value: 0 }],
                    defaultValue: 0
                },
                {
                    type: "select",
                    label: "Active Destination",
                    name: `inDestination`,
                    loadOptions: () =>
                        getDestinationPromise({
                            extensionMap: false,
                            highPriorityQueue: true,
                        }),
                    options: [{ label: "None", value: "none" }],
                    defaultValue: "none"
                },
                {
                    type: "select",
                    label: "Inactive Message",
                    name: `outPromptId`,
                    loadOptions: () => getPromptPromise(),
                    options: [{ label: "None", value: 0 }],
                    defaultValue: 0
                },
                {
                    type: "select",
                    label: "Inactive Destination",
                    name: `outDestination`,
                    loadOptions: () =>
                        getDestinationPromise({
                            extensionMap: false,
                            highPriorityQueue: true,
                        }),
                    options: [{ label: "None", value: "none" }],
                    defaultValue: "none"
                },
            ],
        },
        {
            title: "Days",
            fields: [
                {
                    type: "formTable",
                    name: "days",
                    defaultValue: [
                        {
                            day: "Monday",
                            enabled: true,
                            time: ["09:00", "17:30"],
                        },
                        {
                            day: "Tuesday",
                            enabled: true,
                            time: ["09:00", "17:30"],
                        },
                        {
                            day: "Wednesday",
                            enabled: true,
                            time: ["09:00", "17:30"],
                        },
                        {
                            day: "Thursday",
                            enabled: true,
                            time: ["09:00", "17:30"],
                        },
                        {
                            day: "Friday",
                            enabled: true,
                            time: ["09:00", "17:30"],
                        },
                        {
                            day: "Saturday",
                            enabled: false,
                            time: ["09:00", "17:30"],
                        },
                        {
                            day: "Sunday",
                            enabled: false,
                            time: ["09:00", "17:30"],
                        },
                    ],
                    columns: [
                        {
                            name: "day",
                            title: "Day",
                        },
                        {
                            name: "enabled",
                            title: "Active",
                            formField: {
                                type: "switch",
                                name: "enabled",
                            },
                        },
                        {
                            name: "time",
                            title: "Time",
                            formField: {
                                type: "timeSlider",
                                name: "time",
                            },
                        },
                    ],
                },
            ],
        },
    ]

    const editModalSettings: ISettingsStage[] = [
        {
            title: "Settings",
            fields: [
                {
                    type: "text",
                    label: "Name",
                    name: "description",
                    required: true,
                    maxLength: 100
                },
                {
                    type: "select",
                    label: "UK Bank Holidays",
                    name: `bankHoliday`,
                    options: [
                        { value: 0, label: "Disabled" },
                        { value: 1, label: "England & Wales" },
                        { value: 2, label: "Scotland" },
                        { value: 3, label: "Northern Ireland" },
                    ]
                },
                {
                    type: "select",
                    label: "Active Message",
                    name: `inPromptId`,
                    loadOptions: () => getPromptPromise(),
                    options: [{ label: "None", value: 0 }],
                },
                {
                    type: "select",
                    label: "Active Destination",
                    name: `inDestination`,
                    loadOptions: () =>
                        getDestinationPromise({
                            extensionMap: false,
                            highPriorityQueue: false,
                        }),
                    options: [{ label: "None", value: "none" }],
                },
                {
                    type: "select",
                    label: "Inactive Message",
                    name: `outPromptId`,
                    loadOptions: () => getPromptPromise(),
                    options: [{ label: "None", value: 0 }],
                },
                {
                    type: "select",
                    label: "Inactive Destination",
                    name: `outDestination`,
                    loadOptions: () =>
                        getDestinationPromise({
                            extensionMap: false,
                            highPriorityQueue: true,
                        }),
                    options: [{ label: "None", value: "none" }],
                },
            ],
        },
        {
            title: "Days",
            fields: [
                {
                    type: "formTable",
                    name: "days",
                    defaultValue: [
                        {
                            day: "Monday",
                            enabled: true,
                            time: ["09:00", "17:30"],
                        },
                        {
                            day: "Tuesday",
                            enabled: true,
                            time: ["09:00", "17:30"],
                        },
                        {
                            day: "Wednesday",
                            enabled: true,
                            time: ["09:00", "17:30"],
                        },
                        {
                            day: "Thursday",
                            enabled: true,
                            time: ["09:00", "17:30"],
                        },
                        {
                            day: "Friday",
                            enabled: true,
                            time: ["09:00", "17:30"],
                        },
                        {
                            day: "Saturday",
                            enabled: false,
                            time: ["09:00", "17:30"],
                        },
                        {
                            day: "Sunday",
                            enabled: false,
                            time: ["09:00", "17:30"],
                        },
                    ],
                    columns: [
                        {
                            name: "day",
                            title: "Day",
                        },
                        {
                            name: "enabled",
                            title: "Active",
                            formField: {
                                type: "switch",
                                name: "enabled",
                            },
                        },
                        {
                            name: "time",
                            title: "Time",
                            formField: {
                                type: "timeSlider",
                                name: "time",
                            },
                        },
                    ],
                },
            ],
        },
    ]

    function checkTimeProfileTaken(
        fieldFrom,
        fieldTo
    ): Promise<{ success: boolean; message: string }>
    {
        return new Promise(async (resolve) =>
        {
            let response = await postRequest({}, `/timeprofile/-1/-1`)

            if (response)
            {
                if (response.records)
                {
                    const filteredRecords = response.records.filter(
                        (record) =>
                            Number(record.extension) === Number(fieldFrom) ||
                            (Number(fieldFrom) <= Number(record.extension) &&
                                Number(record.extension) <= Number(fieldTo))
                    )

                    if (filteredRecords && filteredRecords.length > 0)
                    {
                        if (!fieldTo)
                        {
                            resolve({
                                success: false,
                                message:
                                    "Time profile number is already in use. Please select a different number",
                            })
                        } else
                        {
                            resolve({
                                success: false,
                                message:
                                    "1 or more of the time profiles already has the number in this selected range. Please check the range and try again",
                            })
                        }
                    } else
                    {
                        resolve({ success: true, message: "" })
                    }
                } else
                {
                    resolve({
                        success: false,
                        message: "Failed to retrieve data, please try again",
                    })
                }
            } else
            {
                resolve({
                    success: false,
                    message: "Failed to retrieve data, please try again",
                })
            }
        })
    }

    function getDayData(day, days)
    {
        const selectedDay = days.filter(
            (filteredDay) => filteredDay.day === day
        )[0]
        return {
            enabled: selectedDay.enabled,
            start: selectedDay.time[0],
            end: selectedDay.time[1],
        }
    }

    return (
        <GenericIndexPage
            title={"Time Profiles"}
            urlString={"/timeprofile"}
            columns={columns}
            searchable={true}
            restType={"POST"}
            permissions={[permissions.timeprofile, permissions.admin, permissions.VHAdmin]}
            addModal={{
                displayName: "Time Profile",
                fieldFrom: {
                    field: "Time Profile",
                    label: "Time Profile",
                    type: "number",
                    min: 200,
                    max: 998,
                    loadDefaultValue: getLatestTimeProfile,
                },
                settingsStages: modalSettings,
                availabilityCheck: (fieldFrom, fieldTo) =>
                {
                    return new Promise(async (resolve) =>
                    {
                        if (
                            Number(fieldFrom) < 200 ||
                            Number(fieldFrom) > 998 ||
                            (fieldTo &&
                                (Number(fieldTo) < 200 || fieldTo > 998))
                        )
                        {
                            resolve({
                                success: false,
                                error: "Please input a valid number",
                            })
                        }

                        if (fieldTo && fieldTo - fieldFrom > 10)
                        {
                            resolve({
                                success: false,
                                error:
                                    "You cannot add more than 10 time profiles at one time, please contact your account manager to add more",
                            })
                        }

                        if (fieldTo && fieldTo <= fieldFrom)
                        {
                            resolve({
                                success: false,
                                error:
                                    "Please ensure this number is less than the To field",
                            })
                        }

                        const responseMessage = await checkTimeProfileTaken(
                            fieldFrom,
                            fieldTo
                        )

                        if (responseMessage && responseMessage.success)
                        {
                            resolve({ success: true })
                        } else
                        {
                            resolve({
                                success: false,
                                error: responseMessage.message,
                            })
                        }
                    })
                },
                submitData: (data) =>
                {
                    return [{
                        postData: {
                            description: data.description,
                            bankHoliday: data.bankHoliday,
                            inPromptId: data.inPromptId,
                            inDestination: data.inDestination === "none" ? null : data.inDestination,
                            outPromptId: data.outPromptId,
                            outDestination: data.outDestination === "none" ? null : data.outDestination,
                            monday: getDayData("Monday", data.days),
                            tuesday: getDayData("Tuesday", data.days),
                            wednesday: getDayData("Wednesday", data.days),
                            thursday: getDayData("Thursday", data.days),
                            friday: getDayData("Friday", data.days),
                            saturday: getDayData("Saturday", data.days),
                            sunday: getDayData("Sunday", data.days),
                            accountNo: sessionState.session.account_no,
                        },
                        path: "/timeprofile",
                        identifier: "name",
                        type: "create",
                        displayName: data.fromField,
                        bulkIncrement: "description"
                    }]
                },
            }}
            editModal={{
                displayName: "Time Profile",
                settingsStages: editModalSettings,
                uniqueIdentifier: "extension",
                loadRecords: [{
                    url: "/timeprofile",
                }],
                submitData: (data) =>
                {
                    return [{
                        postData: {
                            description: data.description,
                            name: data.name,
                            bankHoliday: data.bankHoliday,
                            inPromptId: data.inPromptId,
                            inDestination: data.inDestination !== "none" ? data.inDestination : null,
                            outPromptId: data.outPromptId,
                            outDestination: data.outDestination !== "none" ? data.outDestination : null,
                            monday: getDayData("Monday", data.days),
                            tuesday: getDayData("Tuesday", data.days),
                            wednesday: getDayData("Wednesday", data.days),
                            thursday: getDayData("Thursday", data.days),
                            friday: getDayData("Friday", data.days),
                            saturday: getDayData("Saturday", data.days),
                            sunday: getDayData("Sunday", data.days),
                            accountNo: sessionState.session.account_no,
                        },
                        path: "/timeprofile",
                        type: "edit",
                        displayName: "name",
                    }]
                },
            }}
            deleteModal={{
                displayName: "Time Profile",
                uniqueIdentifier: "extension",
                submitData: () =>
                {
                    return [{
                        path: "/timeprofile",
                        displayName: "name",
                        type: "delete",
                    }]
                },
            }}
            helpSections={[
                {
                    title: "Time Profiles",
                    text: "Configure call routing based on time of day."
                }
            ]}
        />
    )
}
