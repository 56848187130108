import React, { useContext, useState, useEffect } from "react";
import StagedForm from "../../components/Form/StagedForm";
import Modal from "../../components/Modal/Modal";
import { hasPermission, permissions } from "../../Constants/permissions";
import { SessionContext } from "../../Contexts/SessionContext";
import { useFormReducer } from "../../Hooks/useFormReducer";
import { useRequest } from "../../Hooks/useRequest";
import variables from "../../Constants/variables.scss";

export default function TopUpFormModal({ open, setOpen })
{
    const { state, updateForm } = useFormReducer({

        record: {
            paymentType: "bankTransfer",
            threshold: 2,
            amount: 10,
            topUpAutomatically: false,
            success: false,
            card: {
                cvc: "",
                expiry: "",
                focused: "",
                name: "",
                number: "",
            }
        }
    })
    const { state: sessionState } = useContext(SessionContext)
    const { getRequest, putRequest } = useRequest()
    const [paypalProgessBlocked, setPaypalProgessBlocked] = useState(true)
    const { state: session } = useContext(SessionContext)

    useEffect(() =>
    {
        pullAccountData()
        checkAutoTopup()
    }, [])

    async function pullAccountData()
    {
        // @ts-ignore
        let accountResponse = await getRequest(
            `/account/` + session.session.account_no
        )

        if (accountResponse)
        {
            const object = {
                firstName: accountResponse.firstName,
                lastName: accountResponse.surname,
                addressLine1: accountResponse.address_line1,
                addressLine2: accountResponse.address_line2,
                city: accountResponse.address_city,
                region: accountResponse.address_county,
                country: accountResponse.address_country,
                postCode: accountResponse.address_postcode,
                telephone: accountResponse.telephone,
                email: accountResponse.email,
            }

            Object.keys(object).map(key =>
            {
                updateForm({
                    target: {
                        name: key,
                        value: object[key]
                    }
                })
            })
        }
    }

    async function checkAutoTopup()
    {
        // @ts-ignore
        let autoTopupResponse = await getRequest(
            `/account/` + session.session.account_no + `/hasautotopup`
        )

        if (autoTopupResponse)
        {
            if (autoTopupResponse.autoTopupEnabled)
            {
                updateForm({
                    target: {
                        name: "autoTopupEnabled",
                        value: true
                    }
                })
            }
            if (autoTopupResponse.cpv3AutoTopupEnabled)
            {
                updateForm({
                    target: {
                        name: "cpv3AutoTopupEnabled",
                        value: true
                    }
                })

                updateForm({
                    target: {
                        name: "autoTopup",
                        value: true
                    }
                })

                updateForm({
                    target: {
                        name: "paymentType",
                        value: "autoTopupConfig"
                    }
                })

                if (autoTopupResponse.autoTopUpAmount)
                {
                    updateForm({
                        target: {
                            name: "amount",
                            value: autoTopupResponse.autoTopUpAmount
                        }
                    })
                    updateForm({
                        target: {
                            name: "amountFormatted",
                            value: `£${autoTopupResponse.autoTopUpAmount.toFixed(2)}`
                        }
                    })
                }

                if (autoTopupResponse.autoTopUpThreshold)
                {
                    updateForm({
                        target: {
                            name: "threshold",
                            value: autoTopupResponse.autoTopUpThreshold
                        }
                    })
                }
            }
        }
    }

    function savePaypalOrder(data)
    {
        return new Promise(async resolve =>
        {
            let success = true

            updateForm({
                target: {
                    name: "success",
                    value: true
                }
            })
            setPaypalProgessBlocked(false)
            resolve({ success })
        })
    }

    function handleAutoTopupConfig()
    {
        return new Promise(async resolve =>
        {
            const postData = {
                enabled: state.autoTopup,
                threshold: state.threshold
            }
            const response = await putRequest(postData, `/account/${sessionState.session.account_no}/topup`)
            if (response && response.success)
            {
                updateForm({
                    target: {
                        name: "success", value: true
                    }
                })
                resolve({ success: true })
            }
            else
            {
                updateForm({
                    target: {
                        name: "success", value: false
                    }
                })
                resolve({ success: false })
            }

        })
    }

    const paymentFieldTypes = {
        bankTransfer: [
            {
                type: "paragraph",
                value: "To top up using bank transfer, please transfer money using the following details:"
            },
            {
                type: "info",
                label: "Account Name",
                value: "VoiceHost Ltd"
            },
            {
                type: "info",
                label: "Sort Code",
                value: "30-96-17"
            },
            {
                type: "info",
                label: "Reference",
                value: `AC${session.session.account_no}`
            },
            {
                type: "info",
                label: "Account Number",
                value: "51 98 70 60"
            },
        ],
        creditDebitCard: [
            {
                type: "paragraph",
                value: "Please choose how much you would like to top up:"
            },
            {
                type: "select",
                label: "Amount",
                name: "amount",
                options: [
                    { label: "£20 inc VAT", value: 20 },
                    { label: "£30 inc VAT", value: 30 },
                    { label: "£40 inc VAT", value: 40 },
                    { label: "£50 inc VAT", value: 50 },
                    { label: "£100 inc VAT", value: 100 },
                    { label: "£150 inc VAT", value: 150 },
                ],
                value: state.amount,
                onChange: updateForm
            },
            {
                type: "paragraph",
                value: `If you would like to make top-ups automatically using your credit/debit
                        card in the future, please select the option below. When your account balance 
                        falls below your selected threshold, an automated top-up will be attempted for the same value as this
                        payment.`
            },
            {
                type: "paragraph",
                value: `Please Note: This feature is not available on some cards due to security
                        measures imposed by the card issuer. Therefore when a transaction is attempted
                        it may not be authorised by the card issuer and fail the automatic top-up process.`
            },
            {
                type: "checkbox",
                label: "Top-up automatically",
                name: "autoTopup",
                onChange: updateForm,
                value: state.autoTopup
            },
            (state.autoTopup && {
                type: "select",
                label: "Threshold",
                name: "threshold",
                default: 2,
                options: [
                    { label: "£2.00", value: 2 },
                    { label: "£5.00", value: 5 },
                    { label: "£10.00", value: 10 },
                    { label: "£15.00", value: 15 },
                    { label: "£20.00", value: 20 },
                ],
                value: state.threshold,
                onChange: updateForm
            }),
            (state.autoTopupEnabled || state.cpv3AutoTopupEnabled) && {
                type: "paragraph",
                colour: variables.colorGreen,
                value: `You already have auto top-up enabled, enabling this will overwrite any saved details.`
            },
        ].filter(Boolean),
        paypal: [
            {
                type: "paragraph",
                value: "Please choose how much you would like to top up:"
            },
            {
                type: "select",
                label: "Amount",
                name: "amount",
                options: [
                    { label: "£20 inc VAT", value: 20 },
                    { label: "£30 inc VAT", value: 30 },
                    { label: "£40 inc VAT", value: 40 },
                    { label: "£50 inc VAT", value: 50 },
                    { label: "£100 inc VAT", value: 100 },
                    { label: "£150 inc VAT", value: 150 },
                ],
                value: state.amount,
                onChange: updateForm
            }
        ],
        autoTopupConfig: [
            {
                type: "paragraph",
                value: "If you would like to change the amount to top up, you will have to disable this auto topup configuration, and create a new one."
            },
            {
                type: "info",
                label: "Amount",
                name: "amount",
                disabled: true,
                value: state.amountFormatted,
                onChange: updateForm
            },
            {
                type: "paragraph",
                value: "Please select the top up threshold. If your balance goes below this value, your account will automatically top up:"
            },
            {
                type: "select",
                label: "Threshold",
                name: "threshold",
                options: [
                    { label: "£2.00", value: 2 },
                    { label: "£5.00", value: 5 },
                    { label: "£10.00", value: 10 },
                    { label: "£15.00", value: 15 },
                    { label: "£20.00", value: 20 },
                ],
                value: state.threshold,
                onChange: updateForm
            },
            {
                type: "checkbox",
                label: "Auto Topup Enabled",
                name: "autoTopup",
                value: state.autoTopup,
                onChange: updateForm
            }
        ]
    }

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            maxWidth={800}
        >
            <StagedForm
                onComplete={() => setOpen(false)}
                modal
                stages={
                    [
                        !state.cpv3AutoTopupEnabled && {
                            title: "Payment Type",
                            fields: [
                                {
                                    type: "paragraph",
                                    value: "To add credit please select your preferred payment method from below. " +
                                        "Credit/Debit card payments are instantly added to your account on successful" +
                                        " verification. BACS/Bank Transfer credits are added to your account as soon" +
                                        " as cleared funds arrive in our account."
                                },
                                {
                                    type: "paragraph",
                                    value: "Please note, we use advanced fraud detection techniques, so if your " +
                                        "payment fails we may require further identification. This is normally" +
                                        " easily produced by genuine buyers, however reduces fraudulent payments " +
                                        "dramatically."
                                },
                                {
                                    type: "select",
                                    label: "Payment Type",
                                    name: "paymentType",
                                    options: [
                                        { label: "Bank Transfer", value: "bankTransfer" },
                                        { label: "Credit/Debit Card", value: "creditDebitCard" },
                                        hasPermission(
                                            [permissions.paypal],
                                            session.session.permissions,
                                            "create"
                                        ) && { label: "Paypal", value: "paypal" }
                                    ],
                                    value: state.paymentType,
                                    onChange: updateForm
                                }
                            ]
                        },
                        {
                            title: "Details",
                            fields: paymentFieldTypes[state.paymentType],
                        },
                        state.paymentType && state.paymentType === "paypal" && {
                            title: "Payment",
                            progressBlocked: paypalProgessBlocked,
                            fields: [
                                {
                                    fullWidth: true,
                                    type: "paypalButton",
                                    productDescription: `TOPUP-${sessionState.session.account_no}`,
                                    productPriceGBP: state.amount,
                                    successText: "Payment successful, click 'Next' to continue",
                                    onApproveTransaction: (data) => { return savePaypalOrder(data) }
                                }
                            ],
                        },
                        state.paymentType && state.paymentType === "creditDebitCard" && {
                            title: "Payment",
                            fields: [
                                {
                                    type: "creditCard",
                                    paymentType: "topup",
                                    cardAutoTopup: state.autoTopup,
                                    cardAmount: state.amount,
                                    cardThreshold: state.threshold
                                }
                            ],
                            hideNextButton: true
                        },
                        state.paymentType && state.paymentType === "autoTopupConfig" && {
                            title: "Confirmation",
                            buttonText: "Confirm",
                            onComplete: {
                                function: () =>
                                {
                                    return new Promise(async resolve =>
                                    {
                                        resolve(handleAutoTopupConfig())
                                    })
                                }
                            },
                            fields: [
                                !state.autoTopup && {

                                    type: "paragraph",
                                    value: "Please confirm you wish to disable auto top-up."
                                },
                                state.autoTopup && {
                                    type: "paragraph",
                                    value: "Please confirm that you would like to make the following changes to your auto top-up configuration."
                                },
                                state.autoTopup && state.threshold && {
                                    type: "info",
                                    label: "Threshold",
                                    value: `£${state.threshold.toFixed(2)}`
                                },
                                {
                                    type: "paragraph",
                                    value: `To continue, please select 'Confirm' below.`
                                },
                            ].filter(Boolean)
                        },
                        state.paymentType && state.paymentType !== "bankTransfer" && state.paymentType !== "creditDebitCard" && state.paymentType !== "autoTopupConfig" && {
                            title: "Feedback",
                            fields: [
                                {
                                    type: "paragraph",
                                    value: state.success ?
                                        `Your ${state.autoTopup ? "auto top-up setup" : "top-up"} was successful.` :
                                        "Your top-up was unsuccessful, please try again. If the issue persists," +
                                        " please contact support."
                                }
                            ]
                        },
                        state.paymentType && state.paymentType === "autoTopupConfig" && {
                            title: "Feedback",
                            fields: [
                                {
                                    type: "paragraph",
                                    value: state.success ?
                                        `Your auto top-up setup was successfully ${!state.autoTopup ? "disabled" : "updated"}.` :
                                        "Your auto top-up setup update was unsuccessful, please try again. If the issue persists," +
                                        " please contact support."
                                }
                            ]
                        }
                    ].filter(Boolean)
                }
            />
        </Modal>
    )
}