import variables from "../../Constants/variables.scss";
import React from "react";
import renderHTML from "react-render-html";

export function Paragraph({ value, bold, colour, centred, fontSize }: IParagraph)
{

    return (
        <p style={{
            color: colour ? colour : variables.textColor,
            fontWeight: bold && 500,
            margin: centred && `auto`,
            width: centred && `50%`,
            padding: centred && `10px`,
            textAlign: centred ? `center` : `left`,
            fontSize: fontSize ? fontSize : `1.0em`
        }}
        >
            {value && renderHTML(value)}
        </p >
    )
}