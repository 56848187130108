import React, { useEffect, useContext } from "react"
import StagedForm from "../../components/Form/StagedForm"
import Modal from "../../components/Modal/Modal"
import { ToastContext } from "../../Contexts/ToastContext";
import { useFormReducer } from "../../Hooks/useFormReducer"
import { useRequest } from "../../Hooks/useRequest"
import { regex } from "../../Constants/regex";
import { regexError } from "../../Constants/regexError";
import { SessionContext } from "../../Contexts/SessionContext"
import { hasPermission, permissions } from "../../Constants/permissions"
import { useHistory } from "react-router-dom"


export default function DirectDebitFormModal({ open, setOpen })
{
    const { getRequest, putRequest, postRequest } = useRequest()
    const { add } = useContext(ToastContext);
    const { state: session } = useContext(SessionContext)
    const history = useHistory()

    const { state, updateForm, updateErrors, updateState } = useFormReducer({
        record: {
            accountInName: false,
            onlySignatureRequired: false,

            firstName: "",
            lastName: "",
            company: "",
            dateOfBirth: "",
            telephoneNumber: "",
            postcode: "",

            line1: "",
            line2: "",
            city: "",
            county: "",
            country: "",

            bankName: "",
            accountNumber: "",
            sortCode: "",

            initialCheckFailed: false,
            errors: [],
            understand: false
        }
    })

    async function pullData(firstLoad)
    {
        let data = {}

        const ddRecord = await getRequest(`/billing/directdebit`)

        if (ddRecord)
        {
            data = {
                debitRecord: ddRecord
            }
        }

        if (firstLoad)
        {
            updateState(data)
        }
    }


    useEffect(() =>
    {
        pullData(true)
    }, [])

    async function lookupBank(event)
    {
        if (event.target.value && event.target.value.match(/[0-9]{6}/))
        {
            return new Promise(async resolve =>
            {
                const sortcode = event.target.value;
                let postData = { accountNumber: state.accountNumber, sortCode: sortcode }
                const response = await postRequest(postData, "/billing/directdebit/bankname")

                if (response && response.success)
                {
                    updateForm({
                        target: {
                            type: "text",
                            name: "bankName",
                            value: response.name,
                        },
                    })
                    updateForm({
                        target: {
                            type: "text",
                            name: "sortCode",
                            value: sortcode,
                        },
                    })
                }
                else
                {
                    add({
                        text: "Account number and sort code do not match any GB bank. Please confirm the details are correct",
                        type: "error"
                    })
                    updateForm({
                        target: {
                            type: "text",
                            name: "bankName",
                            value: "",
                        },
                    })
                }
                resolve([])
            })
        }
    }

    async function lookupAddress()
    {
        const postcode = state.postcode
        if (postcode && postcode.match(regex.postcode))
        {
            const response = await getRequest("/address/" + postcode + "/addresses")

            if (response && response.success)
            {
                return response.records.map(record => ({
                    label: record.line_1 + ", " + record.line_2 + ", " + record.post_town + ", " + record.postal_county + ", " + record.country_iso,
                    value: record
                }))
            }
        }
        return []
    }

    function submitForm(ddExists: boolean): Promise<{ success: boolean, message: string }>
    {
        return new Promise(async resolve =>
        {
            let address = state.address;
            const postData = {
                firstName: state.firstName,
                surname: state.lastName,
                company: state.company,
                dateOfBirth: state.dateOfBirth,
                telephone: state.telephoneNumber,
                line1: address.line_1,
                line2: address.line_2,
                city: address.post_town,
                county: address.postal_county,
                countryIso: address.country_iso,
                postCode: state.postcode,
                bankName: state.bankName,
                accountNumber: state.accountNumber,
                sortCode: state.sortCode,
                accountHolder: true,
                onlySignatory: true
            }

            let response

            if (ddExists)
            {
                response = await putRequest(postData, "/billing/directdebit")
            }
            else
            {
                response = await postRequest(postData, "/billing/directdebit")
            }

            if (response)
            {
                if (response.success)
                {
                    resolve({ success: true, message: response.message })
                }
                else
                {
                    if (response.validation)
                    {
                        Object.entries(response.validation).map(([key, value], index) =>
                            add({
                                text: value,
                                type: "error"
                            })
                        )

                        resolve({ success: false, message: response.validation[0] })
                    }
                    else
                    {
                        add({
                            text: response.message,
                            type: "error"
                        })

                        resolve({ success: false, message: response.message })
                    }
                }
            }
            else
            {
                resolve({ success: false, message: "Something went wrong, please try again" })
            }
        })
    }

    function findFieldError(name)
    {
        if (state.errors && state.errors.length > 0)
        {
            const errors = state.errors.filter((error) => error.name === name)
            if (errors && errors.length > 0)
            {
                return errors[0].value
            }
        }
        return null
    }

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            maxWidth={800}
        >
            <StagedForm
                onComplete={() => 
                {
                    setOpen(false)
                }}
                stages={
                    [
                        (state?.debitRecord?.status === 'Approved' || state?.debitRecord?.status === 'Pending') && {
                            title: "Details",
                            fields: [
                                {
                                    type: "child",
                                    noLabel: true,
                                    value: <div className="d-flex">
                                        <span className="float-left">
                                            Your current Direct Debit instruction details are below. If you wish to replace
                                            your instruction then please carry on and complete this form. Please be aware that
                                            any change will take 3 working days to complete.
                                        </span>
                                        <span className="float-right">
                                            <img src={require('../../Assets/directdebit.png')} alt="Direct Debit PNG" width="113" height="38" />
                                        </span>
                                    </div>
                                },
                                {
                                    type: "paragraph",
                                    value: "Your current Direct Debit instruction details are below. " +
                                        "If you wish to replace your instruction then please carry on and complete this form. " +
                                        "Please be aware that any change will take 3 working days to complete."
                                },
                                {
                                    type: "info",
                                    name: "accountHolder",
                                    label: "Account Holder",
                                    bold: true,
                                    readOnly: true,
                                    value: `${state.debitRecord.holder_firstname} ${state.debitRecord.holder_lastname}`,
                                },
                                {
                                    type: "info",
                                    name: "bank_name",
                                    label: "Bank Name",
                                    bold: true,
                                    readOnly: true,
                                    value: state.debitRecord.bank_name,
                                },
                                {
                                    type: "info",
                                    name: "account_ending",
                                    label: "Account Ending",
                                    bold: true,
                                    readOnly: true,
                                    value: state.debitRecord.account_ending,
                                },
                                {
                                    type: "info",
                                    name: "status",
                                    label: "Status",
                                    bold: true,
                                    readOnly: true,
                                    value: state.debitRecord.status,
                                },
                                {
                                    type: "info",
                                    name: "setup",
                                    label: "Create Date",
                                    bold: true,
                                    readOnly: true,
                                    value: state.debitRecord.setup,
                                },
                                {
                                    type: "info",
                                    name: "setup",
                                    label: "Customer Name",
                                    bold: true,
                                    readOnly: true,
                                    value: `${state.debitRecord.customer_firstname} ${state.debitRecord.customer_lastname}`,
                                },
                                {
                                    type: "info",
                                    name: "setup",
                                    label: "Company Name",
                                    bold: true,
                                    readOnly: true,
                                    value: state.debitRecord.company_name ? state.debitRecord.company_name : "N/A",
                                },
                                {
                                    type: "info",
                                    name: "setup",
                                    label: "Address",
                                    bold: true,
                                    readOnly: true,
                                    value: state.debitRecord.address,
                                },
                                {
                                    type: "info",
                                    name: "setup",
                                    label: "Billing Email/s",
                                    bold: true,
                                    readOnly: true,
                                    value: state.debitRecord.email,
                                }
                            ]
                        },
                        {
                            title: "Payment Type",
                            fields: [
                                {
                                    type: "child",
                                    noLabel: true,
                                    value: <div className="d-flex">
                                        <span className="float-left">
                                            A Direct Debit is an instruction from you to your bank or building society.
                                            It authorises VoiceHost Ltd to collect payments directly from your bank account
                                            automatically, usually 3 days before the invoice due date.
                                        </span>
                                        <span className="float-right">
                                            <img src={require('../../Assets/directdebit.png')}
                                                alt="Direct Debit PNG" width="113" height="38" />
                                        </span>
                                    </div>
                                },
                                {
                                    type: "checkbox",
                                    name: "accountInName",
                                    label: "Is the bank account in your name?",
                                    value: state.accountInName,
                                    onChange: updateForm,
                                    halfWidth: true,
                                    required: true
                                },
                                {
                                    type: "checkbox",
                                    name: "onlySignatureRequired",
                                    label: "Are you the only signatory required to authorise this Direct Debit?",
                                    value: state.onlySignatureRequired,
                                    onChange: updateForm,
                                    halfWidth: true,
                                    required: true
                                }
                            ],
                            onComplete: {
                                function: () =>
                                {
                                    return new Promise(async resolve =>
                                    {

                                        if (state.accountInName && state.onlySignatureRequired)
                                        {

                                            updateForm({
                                                target: {
                                                    name: "initialCheckFailed",
                                                    value: false
                                                }
                                            })
                                        } else
                                        {
                                            updateForm({
                                                target: {
                                                    name: "initialCheckFailed",
                                                    value: true
                                                }
                                            })
                                        }
                                        resolve({ success: true })
                                    })
                                }
                            },
                        },
                        !state.initialCheckFailed && {
                            title: "Your Details",
                            fields: [
                                {
                                    type: "child",
                                    noLabel: true,
                                    value: <div style={{ width: "100%" }}>
                                        <span style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <img src={require('../../Assets/directdebit.png')}
                                                alt="Direct Debit PNG" width="113" height="38" />
                                        </span>
                                    </div>
                                },
                                {
                                    type: "text",
                                    name: "firstName",
                                    label: "First Name",
                                    value: state.firstName,
                                    onChange: updateForm,
                                    setError: updateErrors,
                                    error: findFieldError("firstName"),
                                    required: true
                                },
                                {
                                    type: "text",
                                    name: "lastName",
                                    label: "Last Name",
                                    value: state.lastName,
                                    onChange: updateForm,
                                    setError: updateErrors,
                                    error: findFieldError("lastName"),
                                    required: true
                                },
                                {
                                    type: "text",
                                    name: "company",
                                    label: "Company Name",
                                    value: state.company,
                                    onChange: updateForm,
                                    setError: updateErrors,
                                    error: findFieldError("company"),
                                    required: true
                                },
                                {
                                    type: "date",
                                    name: "dateOfBirth",
                                    label: "Date Of Birth",
                                    value: state.dateOfBirth,
                                    onChange: updateForm,
                                    setError: updateErrors,
                                    error: findFieldError("dateOfBirth"),
                                    required: true
                                },
                                {
                                    type: "text",
                                    name: "telephoneNumber",
                                    label: "Telephone",
                                    value: state.telephoneNumber,
                                    onChange: updateForm,
                                    regex: regex.telephoneNumber,
                                    regexError: regexError.telephoneNumber,
                                    setError: updateErrors,
                                    error: findFieldError("telephoneNumber"),
                                    required: true
                                },
                                {
                                    type: "text",
                                    name: "postcode",
                                    label: "Postcode",
                                    value: state.postcode,
                                    onChange: updateForm,
                                    regex: regex.postcode,
                                    regexError: regexError.postcode,
                                    setError: updateErrors,
                                    error: findFieldError("postcode"),
                                    required: true
                                },
                                {
                                    type: "select",
                                    name: "address",
                                    label: "Address",
                                    value: state.address,
                                    required: true,
                                    loadOptions: () => lookupAddress(),
                                    updateOn: state.postcode,
                                    onChange: updateForm,
                                    noResultText: "Entering a postcode will autofill the address drop down.",
                                    helptext: "Entering a postcode will autofill the address drop down."
                                }
                            ],
                            onComplete: {
                                function: () =>
                                    new Promise(async (resolve) =>
                                    {
                                        let success = false

                                        if (state.errors.length === 0)
                                        {
                                            success = true
                                        }

                                        if (state.firstName === '')
                                        {
                                            updateErrors({ target: { name: "firstName", value: "This is a required field" } })
                                            success = false
                                        }
                                        if (state.lastName === '')
                                        {
                                            updateErrors({ target: { name: "lastName", value: "This is a required field" } })
                                            success = false
                                        }
                                        if (state.company === '')
                                        {
                                            updateErrors({ target: { name: "company", value: "This is a required field" } })
                                            success = false
                                        }
                                        if (state.telephoneNumber === '')
                                        {
                                            updateErrors({ target: { name: "telephoneNumber", value: "This is a required field" } })
                                            success = false
                                        }
                                        if (state.postcode === '')
                                        {
                                            updateErrors({ target: { name: "postcode", value: "This is a required field" } })
                                            success = false
                                        }

                                        resolve({ success })
                                    })
                            },
                        },
                        !state.initialCheckFailed && {
                            title: "Payment Details",
                            fields: [
                                {
                                    type: "child",
                                    noLabel: true,
                                    value: <div style={{ width: "100%" }}>
                                        <span style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <img src={require('../../Assets/directdebit.png')}
                                                alt="Direct Debit PNG" width="113" height="38" />
                                        </span>
                                    </div>
                                },
                                {
                                    type: "text",
                                    name: "accountNumber",
                                    label: "Account No",
                                    value: state.accountNumber,
                                    maxLength: 8,
                                    regex: regex.bankAccountNumber,
                                    regexError: regexError.bankAccountNumber,
                                    onChange: updateForm,
                                    setError: updateErrors,
                                    error: findFieldError("accountNumber"),
                                    required: true
                                },
                                {
                                    type: "text",
                                    name: "sortCode",
                                    label: "Sort Code",
                                    value: state.sortCode,
                                    regex: regex.sortCode,
                                    regexError: regexError.sortCode,
                                    setError: updateErrors,
                                    error: findFieldError("sortCode"),
                                    onChange: lookupBank,
                                    maxLength: 6,
                                    required: true
                                },
                                {
                                    type: "info",
                                    name: "bankName",
                                    label: "Bank Name",
                                    readOnly: true,
                                    value: state.bankName,
                                    required: true
                                }
                            ],
                            onComplete: {
                                function: () =>
                                    new Promise(async (resolve) =>
                                    {
                                        let success = false

                                        if (state.errors.length === 0)
                                        {
                                            success = true
                                        }

                                        if (state.accountNumber === '')
                                        {
                                            updateErrors({ target: { name: "accountNumber", value: "This is a required field" } })
                                            success = false
                                        }
                                        if (state.sortCode === '')
                                        {
                                            updateErrors({ target: { name: "sortCode", value: "This is a required field" } })
                                            success = false
                                        }
                                        if (state.bankName === '')
                                        {
                                            updateErrors({ target: { name: "bankName", value: "This is a required field" } })
                                            success = false
                                        }

                                        resolve({ success })
                                    })
                            },
                        },
                        !state.initialCheckFailed && {
                            title: "Confirm",
                            buttonText: "Continue",
                            fields: [
                                {
                                    type: "child",
                                    noLabel: true,
                                    value: <div className="d-flex">
                                        <span className="float-left">
                                            I understand that this instruction may remain with VoiceHost Ltd and
                                            details will be passed electronically to my bank/building society.
                                        </span>
                                        <span className="float-right">
                                            <img src={require('../../Assets/directdebit.png')}
                                                alt="Direct Debit PNG" width="113" height="38" />
                                        </span>
                                    </div>
                                },
                                hasPermission(
                                    [permissions.prepay],
                                    session.session.permissions,
                                    "read"
                                ) && {
                                    name: "understand",
                                    type: "paragraph",
                                    value: "I understand that setting up this Direct Debit will change my account type " +
                                        "from Prepay, to Postpay. This means I will be invoiced monthly for all calls " +
                                        "and services.",
                                },
                                {
                                    type: "paragraph",
                                    value: "Payments by GoCardless. Read the <a href=\"https://gocardless.com/privacy\">privacy notice.</a>",
                                },
                                {
                                    type: "checkbox",
                                    name: "agree",
                                    label: "Confirm",
                                    value: state.agree,
                                    onChange: updateForm,
                                    required: true
                                }
                            ],
                            onComplete: {
                                function: () =>
                                {
                                    return new Promise(async resolve =>
                                    {
                                        const retData = await submitForm(state?.debitRecord?.status === 'Approved' || state?.debitRecord?.status === 'Pending')

                                        updateForm({
                                            target: {
                                                name: "success",
                                                value: retData.success
                                            }
                                        })

                                        updateForm({
                                            target: {
                                                name: "message",
                                                value: retData.message
                                            }
                                        })

                                        //only reload if converting from prepay to postpay
                                        if (retData.success &&
                                            hasPermission(
                                                [permissions.prepay],
                                                session.session.permissions,
                                                "read"
                                            ))
                                        {
                                            setTimeout(() =>
                                            {
                                                history.push(`/invoice`)
                                                window.location.reload()
                                            }, 3000)
                                        }

                                        resolve({ success: retData.success })
                                    })
                                }
                            },
                        },
                        !state.initialCheckFailed && {
                            title: "Feedback",
                            fields: [
                                {
                                    type: "paragraph",
                                    value: state.success ?
                                        "Your direct debit was successfully set up." :
                                        "Your direct debit setup failed, please try again. If the issue " +
                                        "persists, please contact support."
                                }
                            ]
                        },
                        state.initialCheckFailed && {
                            title: "Feedback",
                            fields: [
                                {
                                    type: "paragraph",
                                    value: "This online application can only be used if the bank account is in your" +
                                        " name and you are the only signatory required to authorise this Direct Debit."
                                },
                                {
                                    type: "paragraph",
                                    value: "To setup a Direct Debit for accounts with dual signatures please download" +
                                        " the manual Direct Debit form:"
                                }
                            ]
                        }
                    ].filter(Boolean)
                }
            />
        </Modal>
    )
}