import { useEffect, useRef, useState } from "react"
import { useRequest } from "../../Hooks/useRequest"
import { useFormReducer } from "../../Hooks/useFormReducer"
import useAPIRequests from "../../Hooks/useAPIRequests"
import { permissions } from "../../Constants/permissions"

export function useIVRsFormPage(id: number)
{
    const [loading, setLoading] = useState(true)
    const { getRequest } = useRequest()
    const [unavailableNumbers, setUnavailableNumbers] = useState([])
    const { getDestinationPromise, getPromptPromise } = useAPIRequests()

    const ref = useRef()
    //@ts-ignore
    ref.current = unavailableNumbers

    const {
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
    } = useFormReducer({ record: {} })

    useEffect(() =>
    {
        setUnavailableNumbers(
            state.options &&
            state.options.map((option) => String(option.number))
        )
    }, [state])

    function loadUnavailableNumbers(): number[] | string[]
    {
        // @ts-ignore
        if (ref && ref.current && ref.current.length > 0)
        {
            //@ts-ignore
            return ref.current.map((option) => String(option))
        } else
        {
            return []
        }
    }

    function defineAddOptionData(data)
    {
        return {
            identifier: "number",
            displayName: data.fromField,
            data: {
                destination: data.destination,
            },
        }
    }

    async function addNewOption(data)
    {
        updateState({
            type: "UPDATE_TABLE",
            payload: {
                type: "create",
                name: "options",
                value: data,
            },
        })
    }

    async function deleteOption(data)
    {
        updateState({
            type: "UPDATE_TABLE",
            payload: {
                type: "delete",
                name: "options",
                value: data,
                uniqueIdentifier: "number",
            },
        })
    }

    function submitData()
    {
        let options = {}
        state.options.forEach((option) =>
        {
            let optionKey = option.number
            if (option.number === "*")
            {
                optionKey = "Star"
            } else if (option.number === "#")
            {
                optionKey = "Hash"
            }

            options[`option${optionKey}`] = option.destination
        })
        return [
            {
                body: {
                    description: state.description,
                    greetingId: state.greetingId,
                    invalidPrompt: state.invalidPrompt,
                    ...options,
                },
                path: "/ivr/" + id,
            },
        ]
    }

    function deleteData()
    {
        return [{
            path: "/ivr",
            type: "delete",
            indexPagePath: "/ivrs",
            displayName: "IVR"
        }]
    }

    useEffect(() =>
    {
        async function pullData()
        {
            setLoading(true)
            let response = await getRequest(`/ivr/${id}`)
            if (response)
            {
                updateState({
                    ...response,
                    options: Object.keys(response)
                        .map((key) =>
                        {
                            if (key.startsWith("option"))
                            {
                                if (response[key])
                                {
                                    let optionNumber = key.replace("option", "")
                                    if (optionNumber === "Star")
                                    {
                                        optionNumber = "*"
                                    } else if (optionNumber === "Hash")
                                    {
                                        optionNumber = "#"
                                    }
                                    return {
                                        number: optionNumber,
                                        destination: response[key],
                                    }
                                }
                            }
                        })
                        .filter(Boolean),
                })
            }
            setLoading(false)
        }

        pullData()
        // eslint-disable-next-line
    }, [id])

    const tabs: ITab[] = [
        {
            title: "General",
            icon: "toolbox",
            disabled: false,
            sections: [
                {
                    title: "Configuration",
                    fields: [
                        {
                            type: "text",
                            label: "Name",
                            name: "description",
                            required: true,
                            maxLength: 50,
                            helptext: "Allows identification through the control panel."
                        },
                        {
                            type: "select",
                            label: "Introduction Prompt",
                            name: "greetingId",
                            loadOptions: getPromptPromise,
                            options: [{ value: -1, label: "None" }],
                            helptext: "Custom Prompt that plays when caller enters the IVR"
                        },
                        {
                            type: "select",
                            label: "Invalid Option Prompt",
                            name: "invalidPrompt",
                            loadOptions: getPromptPromise,
                            options: [{ value: -1, label: "None" }],
                            helptext: "Custom Prompt that plays if caller selects an invalid option."
                        },
                    ],
                },
                {
                    fullWidth: true,
                    fields: [
                        {
                            type: "formTable",
                            name: "options",
                            dataType: "IVR Setting",
                            customSort: {
                                uniqueIdentifier: "number",
                                order: [
                                    "1",
                                    "2",
                                    "3",
                                    "4",
                                    "5",
                                    "6",
                                    "7",
                                    "8",
                                    "9",
                                    "0",
                                    "*",
                                    "#",
                                    "Timeout",
                                ],
                            },
                            columns: [
                                {
                                    title: "Number",
                                    name: "number",
                                },
                                {
                                    title: "Destination",
                                    name: "destination",
                                    formField: {
                                        type: "select",
                                        loadOptions: () =>
                                            getDestinationPromise({
                                                excludeObjectRegex: "^IVR\\/" + state.accountNo + "\\*" + state.name + "$" 
                                            }),
                                        name: "destination",
                                        options: [{ label: "Conferencing", value: "CONFERENCING" }],
                                        helptext: "Where the caller is routed to on pressing that option.",
                                    },
                                },
                            ],
                            permissions: [permissions.IVR, permissions.admin, permissions.VHAdmin],
                            deleteModal: {
                                //@ts-ignore
                                dataType: "IVR Option",
                                uniqueIdentifier: "number",
                                //@ts-ignore
                                onSubmit: (data) => deleteOption(data),
                            },
                            addModal: {
                                displayName: "IVR Option",
                                customTitle: "Numbers",
                                fieldFrom: {
                                    title: "Number",
                                    name: "number",
                                    type: "dialPad",
                                    loadUnavailableNumbers,
                                },
                                allowBulkAdd: false,
                                settingsStages: [
                                    {
                                        title: "Settings",
                                        fields: [
                                            {
                                                type: "select",
                                                name: "destination",
                                                loadOptions: () =>
                                                    getDestinationPromise({
                                                        excludeObjectRegex: "^IVR\\/" + state.accountNo + "\\*" + state.name + "$"
                                                    }),
                                                    options: [{ label: "Conferencing", value: "CONFERENCING" }],
                                                label: "Destination",
                                            },
                                        ],
                                    },
                                ],
                                //@ts-ignore
                                onSubmit: (data) => addNewOption(data),
                                //@ts-ignore
                                defineSubmitData: (data) =>
                                {
                                    return new Promise(async (resolve) =>
                                    {
                                        const createData = await defineAddOptionData(
                                            data
                                        )
                                        resolve(createData)
                                    })
                                },
                            },
                        },
                    ],
                },
            ],
        },
    ]

    return {
        loading,
        tabs,
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        submitData,
        deleteData,
        // resetChanges,
    }
}
