import React, { useEffect, useState } from "react";
import { useRequest } from "../../Hooks/useRequest";
import Spinner from "../../components/Spinner/Spinner";

export default function StoreRedirectPage()
{
    const { getRequest } = useRequest()
    const [loading, setLoading] = useState(false)
    const [url, setUrl] = useState("")

    useEffect(() =>
    {
        async function getToken()
        {
            setLoading(true)
            let response = await getRequest("/store/token")

            if (response && response.token)
            {
                setUrl(`0; url=https://store.voicehost.co.uk/index.php?route=account/login&token=${response.token}`)
            }
            else
            {
                setUrl(`0; url=https://store.voicehost.co.uk`)
            }

            setLoading(false)

        }
        getToken()
    }, [])

    return (
        url ? (
            <div>
                <meta http-equiv="Refresh" content={url} />
            </div>
        ) : (
                <div
                    style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        marginTop: "-5px",
                        marginLeft: "-5px",
                    }}
                >
                    <Spinner />
                </div>
            )
    )
}
