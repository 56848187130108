import { useEffect, useState } from "react"
import { useRequest } from "../../Hooks/useRequest"
import { useFormReducer } from "../../Hooks/useFormReducer"



export function useCallRecordingFormPage(id: number)
{
    const [record, setRecord] = useState({})
    const [loading, setLoading] = useState(true)
    const { getRequestBase64Conversion, getRequest } = useRequest()
    const [fileName, setFileName] = useState("")

    useEffect(() =>
    {
        async function pullData()
        {
            setLoading(true)
            let data = {}

            const callRecordingAudio = await getRequestBase64Conversion(`/callrecording/${id}/download`, "audio/mpeg")
            if (callRecordingAudio)
            {
                data = {
                    ...data,
                    callRecording: callRecordingAudio
                }
            }

            const callRecordingResponse = await getRequest(`/callrecording/${id}`)
            if (callRecordingResponse)
            {
                data = {
                    ...data,
                    ...callRecordingResponse,
                    inbound: callRecordingResponse.inbound && callRecordingResponse.inbound.startsWith("44") ? callRecordingResponse.inbound.replace(44, 0) : callRecordingResponse.inbound,
                    origin: callRecordingResponse.origin && callRecordingResponse.origin.startsWith("44") ? callRecordingResponse.origin.replace(44, 0) : callRecordingResponse.origin,
                    displayFileSize: callRecordingResponse.filesize && callRecordingResponse.filesize.toFixed(2) + " Mb"
                }
                setFileName(callRecordingResponse.fileName)
            }

            setRecord(data)
            setLoading(false)
        }

        pullData()
        // eslint-disable-next-line
    }, [id])

    const {
        state,
        updateState,
        updateForm,
        updateErrors,
        // resetChanges,
        // changes,
    } = useFormReducer({ record })

    const tabs: ITab[] = [
        {
            title: "General",
            icon: "toolbox",
            disabled: false,
            sections: [
                {
                    title: "Details",
                    fields: [
                        {
                            label: "Inbound Number",
                            type: "info",
                            name: "inbound",
                        },
                        {
                            label: "Origin",
                            type: "info",
                            name: "origin",
                        },
                        {
                            label: "Destination",
                            type: "info",
                            name: "destination",
                        },
                        {
                            label: "Duration",
                            type: "info",
                            name: "mins",
                        },
                        {
                            label: "Date",
                            type: "info",
                            name: "date",
                        },
                        {
                            label: "Time",
                            type: "info",
                            name: "time",
                        }
                    ],
                },
                {
                    title: "Overview",
                    fields: [
                        {
                            label: "Playback",
                            type: "audio",
                            name: "callRecording",
                        },
                        {
                            label: "Download",
                            type: "download",
                            name: "callRecording",
                            fileName: fileName,
                        },
                        {
                            label: "File Size",
                            type: "info",
                            name: "displayFileSize",
                        },
                    ],
                },
            ],
        },
    ]

    return {
        loading,
        tabs,
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
    }
}
