import React, { useEffect, useState } from 'react'
import { Table as TableComponent } from 'react-bootstrap'
import Icon from '../Icons/Icon'
import Pagination from './Pagination'
import './table.scss'
import { Hidden } from 'react-grid-system'
import SearchInput from '../FormInputs/SearchInput'
import Button from '../Button'
import { TableFilters } from './TableFilters'
import Spinner from "../Spinner/Spinner";
import { FormField } from "../Form/FormField";
import { NavLink } from "react-router-dom";
import variablesScss from "../../Constants/variables.scss";
import Tooltip from '../Tooltip'
import { regex } from '../../Constants/regex'
import moment from "moment";
import { useHandleOutsideClick } from '../../Hooks/useHandleOutsideClick'
import { CSVDownload } from '../CSVDownload/CSVDownload'

interface IProps
{
    title?: string
    columns: IColumn[]
    uniqueIdentifier?: string
    filters?: IFilterDropdown
    csvProperties?: ICSVProperties
    customActions?: IButton[]
    customModalButton?: IButton
    uploadFileModalButton?: IButton
    filterState?: any
    setFilterState?: ISetFilter
    onSearch?: (val: any) => void
    searchDefault?: string
    sortBy?: ISortBy
    setSortBy?: (ISortBy) => void
    offset?: number
    setOffset?: (val: number) => void
    limit?: number
    setLimit?: (val: number) => void
    totalRecords?: number
    records: { [key: string]: any }[]
    loading: boolean
    onAdd?: { action: () => void }
    onBulkEdit?: { action: (ids: number[]) => void }
    onDelete?: { action: (ids: number[]) => void }
    onCustomModal?: { action: (ids: number[]) => void }
    onFileUpload?: { action: () => void }
    bulkActions?: any
    selectedRecords?: any[]
    setSelectedRecords?: (records: any[]) => void
    state?: any
    updateRow?: any
    updateErrors?: any
    getError?: any
    small?: boolean
    searchable?: boolean
    noPagination?: boolean
    applyFilters?: () => void
    resetFilters?: () => void
    conditionalReadOnly?: IConditionalReadOnly
    hideTotalRecords?: boolean
    customPaginationName?: string
    csvLoading?: boolean
    disableAdd?: boolean
}

export default function Table({
    title,
    columns,
    csvProperties,
    customActions,
    customModalButton,
    uploadFileModalButton,
    uniqueIdentifier = "id",
    filters,
    setFilterState,
    filterState,
    onSearch,
    searchDefault,
    sortBy,
    setSortBy,
    offset,
    setOffset,
    limit,
    setLimit,
    totalRecords,
    records,
    loading,
    onAdd,
    onDelete,
    onBulkEdit,
    onCustomModal,
    onFileUpload,
    bulkActions,
    selectedRecords,
    setSelectedRecords,
    state,
    updateRow,
    updateErrors,
    getError,
    small,
    searchable,
    noPagination,
    applyFilters,
    resetFilters,
    conditionalReadOnly,
    hideTotalRecords,
    customPaginationName,
    disableAdd
}: IProps)
{
    const [filtersBoxOpen, setFiltersBoxOpen] = useState(false)
    const [CSVDropdownOpen, setCSVDropdownOpen] = useState(false)

    const node = useHandleOutsideClick(CSVDropdownOpen, setCSVDropdownOpen)

    function handleConditional(column, value, type)
    {
        if (column && column.formField)
        {
            if (value !== null)
            {
                return value
            }
            else
            {
                return ""
            }
        }

        if (column && column.conditional)
        {
            let val = column.conditional.filter(
                (con) =>
                    con.value === value || (con.value === "ANY-VALUE" && value)
            )
            if (val != null && val.length > 0 && val[0])
            {
                if (val[0].color && type === "color")
                {
                    return val[0].color
                }
                else if (val[0].text && type === "text")
                {
                    return val[0].text
                }
                else if (val[0].icon && type === "icon")
                {
                    return val[0].icon
                }
                else if (val[0].iconColor && type === "iconColor")
                {
                    return val[0].iconColor
                }
                else if (val[0].onClick && type === "onClick")
                {
                    return val[0].onClick
                }
                else
                {
                    return ""
                }
            }
            else
            {
                return null
            }
        } else
        {
            return null
        }
    }

    function handleIconConditional(conditional, value, type)
    {
        let val = conditional.value == value || (conditional.value === "ANY-VALUE" && value)

        if (val)
        {
            if (conditional.icon && type === "icon")
            {
                return conditional.icon
            }
            else if (conditional.iconColor && type === "iconColor")
            {
                return conditional.iconColor
            }
        }
        else
        {
            return null;
        }
    }

    useEffect(() =>
    {
        setSelectedRecords([])
    }, [records])

    function isCheckboxDisabled(record)
    {
        if (conditionalReadOnly)
        {
            if (
                record[conditionalReadOnly.field] === conditionalReadOnly.value
            )
            {
                return true
            } else
            {
                return false
            }
        } else
        {
            return false
        }
    }

    function renderSortArrows(column: IColumn)
    {
        const sortByVar = column.sortByName ? column.sortByName : column.name
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 4,
                    marginRight: 4,
                }}>
                <Icon
                    icon="caret-up"
                    style={{ height: 10 }}
                    color={
                        sortBy &&
                            sortBy.column === sortByVar &&
                            sortBy.direction === "asc"
                            ? variablesScss.textColor
                            : variablesScss.colorVeryLightGrey
                    }
                />
                <Icon
                    icon="caret-down"
                    style={{
                        height: 10,
                        marginTop: -2,
                    }}
                    color={
                        sortBy &&
                            sortBy.column === sortByVar &&
                            sortBy.direction === "desc"
                            ? variablesScss.textColor
                            : variablesScss.colorVeryLightGrey
                    }
                />
            </div>
        )
    }

    function handleChangeSortBy(column: IColumn)
    {
        const sortByVar = column.sortByName ? column.sortByName : column.name
        if (column.sortBy)
        {
            if (sortBy && sortBy.column === sortByVar)
            {
                if (sortBy.direction === "asc")
                {
                    setSortBy({
                        column: sortByVar,
                        direction: "desc",
                    })
                } else if (sortBy.direction === "desc")
                {
                    setSortBy(null)
                }
            } else
            {
                setSortBy({
                    column: sortByVar,
                    direction: "asc",
                })
            }
        }
    }

    function displayValue(column, value)
    {
        if (!column.hideValue)
        {
            let result = handleConditional(column, value, "text")
            if (result !== null && result !== "" && result !== value)
            {
                return result
            }
            if (column.type === "money")
            {
                let val
                val =
                    "£" +
                    Math.abs(value).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                    })
                if (value < 0)
                {
                    val = "-" + val
                }
                if (val.length > 0)
                {
                    return val
                } else
                {
                    return ""
                }
            } else if (column.type === "date")
            {
                let d, month, day, year
                if (regex.vmDateFormat.test(value))
                {
                    let formattedDate = moment(value, "DD MM YYYY HH:mm:SS").format("YYYY-MM-DD")
                    d = new Date(formattedDate)
                    month = "" + (d.getMonth() + 1)
                    day = "" + d.getDate()
                    year = d.getFullYear()
                }
                else
                {
                    d = new Date(value)
                    month = "" + (d.getMonth() + 1)
                    day = "" + d.getDate()
                    year = d.getFullYear()
                }
                if (month.length < 2) month = "0" + month
                if (day.length < 2) day = "0" + day

                return day + "/" + month + "/" + year
            } else if (column.type === "bytes")
            {
                let kilobyte = 1024
                let megabyte = kilobyte * 1024
                let gigabyte = megabyte * 1024
                if (value / gigabyte > 1)
                {
                    return (value / gigabyte).toFixed(2) + "GB"
                }
                else if (value / megabyte > 1)
                {
                    return (value / megabyte).toFixed(2) + "MB"
                }
                else if (value / kilobyte > 1)
                {
                    return (value / kilobyte).toFixed(2) + "KB"
                }
                else
                {
                    return value + "B"
                }
            } else if (column.type === "datetime")
            {
                let d, month, day, year, hour, min, sec
                if (regex.vmDateFormat.test(value))
                {
                    let formattedDate = moment(value, "DD MM YYYY HH:mm:SS").format("YYYY-MM-DDTHH:mm:SS")
                    d = new Date(formattedDate)
                    month = "" + (d.getMonth() + 1)
                    day = "" + d.getDate()
                    year = d.getFullYear()
                    hour = d.getHours()
                    min = d.getMinutes()
                    sec = d.getSeconds()
                }
                else
                {
                    d = new Date(value)
                    month = "" + (d.getMonth() + 1)
                    day = "" + d.getDate()
                    year = d.getFullYear()
                    hour = d.getHours()
                    min = d.getMinutes()
                    sec = d.getSeconds()
                }
                if (month.length < 2) month = "0" + month
                if (day.length < 2) day = "0" + day

                return `${day}/${month}/${year} ${hour}:${min}:${sec}`
            }
            else
            {
                return value
            }
        }
    }

    function renderTableCellContent(column: IColumn, state, record, i)
    {
        function content()
        {
            const onClick = handleConditional(
                column,
                record[column.name],
                "onClick"
            )

            return (
                <div
                    className={"tableCell"}
                    onClick={() => onClick && onClick(record[uniqueIdentifier])}
                    style={{ cursor: onClick && "pointer", fontWeight: onClick && 500 }}
                >
                    {column.conditional && column.conditional.map(
                        (conditional) =>
                        {
                            if (handleIconConditional(
                                conditional,
                                record[conditional.name ? conditional.name : column.name],
                                "icon"
                            ))
                            {
                                return (
                                    <div className={"tableCellIcon"}>
                                        <Icon
                                            style={{ marginRight: 8 }}
                                            size="xs"
                                            color={handleIconConditional(
                                                conditional,
                                                record[conditional.name ? conditional.name : column.name],
                                                "iconColor"
                                            )}
                                            icon={handleIconConditional(
                                                conditional,
                                                record[conditional.name ? conditional.name : column.name],
                                                "icon"
                                            )}
                                        />
                                    </div>
                                )
                            }
                        }
                    )}
                    {displayValue(column, record[column.name])}
                </div>
            )
        }

        if (column.formField)
        {
            let val
            if (
                state &&
                state.records &&
                state.records[i] &&
                state.records[i][column.name]
            )
            {
                val = state.records[i][column.name]
            } else if (record)
            {
                val = record[column.name]
            }
            if (column.conditional)
            {
                val = handleConditional(column, val, "text")
            }

            let error
            if (getError)
            {
                error = getError(i, column.name)
            }

            let conditionalProps = {}
            if (column.formField.conditionalProps)
            {
                const { field, value, props } = column.formField.conditionalProps

                if (records[i][field] === value)
                {
                    conditionalProps = props
                }
            }
            let disabled = false
            if (column.formField.conditionallyDisabled)
            {
                const trueConditions = column.formField.conditionallyDisabled.checks.filter(disabled => records[i][disabled.field] === disabled.value)
                disabled = trueConditions.length > 0
                if (disabled && val)
                {
                    val = ""
                    updateRow({
                        target: {
                            name: column.name,
                            value: val,
                            row: i,
                        },
                    })
                }
            }
            return (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row"
                    }}
                >
                    <FormField
                        {...column.formField}
                        {...conditionalProps}
                        disabled={disabled}
                        onChange={(e) =>
                        {
                            if (column.emptyFields)
                            {
                                column.emptyFields.forEach(columnName =>
                                {
                                    updateRow({
                                        target: {
                                            name: columnName,
                                            value: "",
                                            row: i,
                                        },
                                    })
                                });
                            }

                            if (column.conditionalFill)
                            {
                                if (column.conditionalFill.conditionalForFill)
                                {
                                    let autoFill = true
                                    column.conditionalFill.conditionalForFill.forEach(column =>
                                    {
                                        if (record[column.field] !== column.value)
                                        {
                                            autoFill = false
                                        }
                                    })

                                    if (autoFill)
                                    {
                                        let autoFillValue
                                        let dataSet = column.conditionalFill.dataSet

                                        dataSet.forEach(data =>
                                        {
                                            if (data.value === e.target.value)
                                            {
                                                autoFillValue = data.label
                                            }
                                        })

                                        if (autoFillValue)
                                        {
                                            updateRow({
                                                target: {
                                                    name: column.conditionalFill.columnToUpdate,
                                                    value: autoFillValue,
                                                    row: i,
                                                },
                                            })
                                        }
                                    }
                                }
                            }
                            updateRow({
                                target: {
                                    name: e.target.name,
                                    value: e.target.value,
                                    type: e.target.type,
                                    row: i,
                                },
                            })
                        }}
                        value={val}
                        setError={
                            updateErrors &&
                            ((e) =>
                                updateErrors({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.value,
                                        type: e.target.type,
                                        row: i,
                                    },
                                }))
                        }
                        noLabel
                        style={{ paddingRight: error && 8 }}
                    />
                    {error && (
                        <Tooltip
                            text={error}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingLeft: 8
                                }}
                            >

                                <Icon
                                    color={variablesScss.colorRed}
                                    icon={"exclamation-circle"}
                                />

                            </div>
                        </Tooltip>
                    )}
                </div>
            )
        } else if (column.link)
        {
            return (
                <span
                    onClick={() =>
                        column.onClick &&
                        column.onClick(record[uniqueIdentifier])
                    }
                    style={{
                        cursor:
                            column.onClick || column.link
                                ? "pointer"
                                : "default",
                    }}>
                    <NavLink
                        onClick={(e) =>
                        {
                            if (!column.link)
                            {
                                e.preventDefault()
                            }
                        }}
                        to={
                            column.link
                                ? `${column.link.root}/${record[uniqueIdentifier]}`
                                : ""
                        }
                        target={column.link && column.link.newTab && "_blank"}
                        style={{
                            textDecoration: "none",
                            color: variablesScss.textColor,
                            cursor:
                                column.link && column.link.root
                                    ? "pointer"
                                    : "default",
                        }}>
                        {content()}
                    </NavLink>
                </span>
            )
        } else
        {
            return (
                <span
                    onClick={() => column.onClick && column.onClick(record.id)}
                    style={{
                        cursor:
                            column.onClick || column.link
                                ? "pointer"
                                : "default",
                    }}>
                    {content()}
                </span>
            )
        }
    }

    return (
        <div
            className={!small ? "card-component" : ""}
            style={{ width: "100%" }}>
            <div className="table-card">
                <div className="tableTitle">{title && <h3>{title}</h3>}</div>
                <div
                    className="tableActions"
                    style={{ alignItems: "center", flex: 1 }}>
                    <div
                        className={"actionButtons"}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            flex: 1,
                        }}>
                        {customModalButton && customModalButton.leftSide && (
                            <Button
                                {...customModalButton}
                                disabled={customModalButton.requireSelected && !selectedRecords?.length}
                                onClick={customModalButton.onClick ? customModalButton.onClick : () =>
                                    onCustomModal.action(selectedRecords)
                                }
                                style={{ marginRight: 4 }}>
                            </Button>
                        )}
                        {uploadFileModalButton && uploadFileModalButton.leftSide && (
                            <Button
                                {...uploadFileModalButton}
                                disabled={uploadFileModalButton.requireSelected && !selectedRecords?.length}
                                onClick={uploadFileModalButton.onClick ? uploadFileModalButton.onClick : () =>
                                    onFileUpload.action()
                                }
                                style={{ marginRight: 4 }} />
                        )}
                        {onAdd && (
                            <Button
                                size="sm"
                                variant="success"
                                disabled={disableAdd}
                                style={{
                                    width: 32,
                                    height: 38,
                                    marginRight: 4,
                                }}
                                onClick={() => onAdd.action()}>
                                <Icon icon="plus" />
                            </Button>
                        )}
                        {onBulkEdit && (
                            <Button
                                size="sm"
                                variant="warning"
                                disabled={!selectedRecords?.length}
                                style={{
                                    width: 32,
                                    height: 38,
                                    marginRight: 4,
                                }}
                                onClick={() =>
                                    onBulkEdit.action(selectedRecords)
                                }>
                                <Icon icon="pencil-alt" color={"white"} />
                            </Button>
                        )}
                        {onDelete && (
                            <Button
                                size="sm"
                                variant="danger"
                                disabled={!selectedRecords?.length}
                                style={{
                                    width: 32,
                                    height: 38,
                                    marginRight: 4,
                                }}
                                onClick={() =>
                                    onDelete.action(selectedRecords)
                                }>
                                <Icon icon="trash-alt" />
                            </Button>
                        )}
                        {customModalButton && !customModalButton.leftSide && (
                            <Button
                                {...customModalButton}
                                disabled={customModalButton.requireSelected && !selectedRecords?.length}
                                onClick={customModalButton.onClick ? customModalButton.onClick : () =>
                                    onCustomModal.action(selectedRecords)
                                }
                                style={{ marginRight: 4 }}></Button>
                        )}
                        {uploadFileModalButton && !uploadFileModalButton.leftSide && (
                            <Button
                                {...uploadFileModalButton}
                                disabled={uploadFileModalButton.requireSelected && !selectedRecords?.length}
                                onClick={uploadFileModalButton.onClick ? uploadFileModalButton.onClick : () =>
                                    onFileUpload.action()
                                }
                                style={{ marginRight: 4 }} />
                        )}
                        {filters && (
                            <TableFilters
                                filters={filters}
                                filterState={filterState}
                                setFilterState={setFilterState}
                                open={filtersBoxOpen}
                                setOpen={setFiltersBoxOpen}
                                loading={loading}
                                applyFilters={applyFilters}
                                resetFilters={resetFilters}
                            />
                        )}
                        {customActions &&
                            customActions.map((customAction) => (
                                <Button
                                    {...customAction}
                                    size="sm"
                                    style={{
                                        height: 38,
                                        marginRight:
                                            searchable && onSearch && 4,
                                        whiteSpace: "nowrap",
                                    }}
                                />
                            ))}
                        {csvProperties &&
                            <CSVDownload
                                url={csvProperties.url}
                                displayName={csvProperties.displayName}
                                restType={csvProperties.restType}
                                postData={csvProperties.postData}
                                filename={csvProperties.csvName}
                                buttonStyle={{
                                    height: 38,
                                    marginRight:
                                        (searchable && onSearch) && 4,
                                    whiteSpace: "nowrap",
                                }}
                                disabled={loading || records.length === 0}
                            />
                        }

                    </div>
                    {searchable && onSearch && (
                        <SearchInput
                            placeholder="Search..."
                            style={{
                                padding: 8,
                            }}
                            presetValue={searchDefault}
                            onSearch={onSearch}
                        />
                    )}
                </div>
            </div>
            <div className={`table-container ${small ? "smallTable" : ""}`}>
                <TableComponent responsive striped>
                    <thead>
                        <tr>
                            {(bulkActions || onDelete || onBulkEdit || customModalButton?.requireSelected) && (
                                <th>
                                    <input
                                        type="checkbox"
                                        onChange={(e) =>
                                        {
                                            if (e.target.checked)
                                            {
                                                setSelectedRecords(
                                                    records.filter((record) =>
                                                        conditionalReadOnly
                                                            ? record.accountNo !==
                                                            conditionalReadOnly.value
                                                            : record
                                                    )
                                                )
                                            } else
                                            {
                                                setSelectedRecords([])
                                            }
                                        }}
                                        checked={
                                            records &&
                                            records.length > 0 &&
                                            records
                                                .filter((record) =>
                                                    conditionalReadOnly
                                                        ? record.accountNo !==
                                                        conditionalReadOnly.value
                                                        : record
                                                )
                                                .sort()
                                                .join(",") ===
                                            selectedRecords.sort().join(",")
                                        }
                                    />
                                </th>
                            )}
                            {columns.map((column) => (
                                <Hidden
                                    sm={column.hide}
                                    xs={column.hide}
                                    key={column.name}>
                                    <th
                                        style={{
                                            cursor: column.sortBy && "pointer",
                                            maxWidth: column.maxWidth,
                                        }}
                                        onClick={() =>
                                            handleChangeSortBy(column)
                                        }>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                            }}>
                                            {column.title}
                                            {column.sortBy &&
                                                renderSortArrows(column)}
                                        </div>
                                    </th>
                                </Hidden>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {!loading ? (
                            records && records.length > 0 ? (
                                records.map((record, i) => (
                                    <tr
                                        key={record?.id ? record.id : i}
                                        style={{
                                            borderLeft:
                                                state &&
                                                state.errors &&
                                                state.errors.filter(
                                                    (error) => error.row === i
                                                ).length &&
                                                `1px solid ${variablesScss.colorRed}`,
                                        }}>
                                        {(bulkActions ||
                                            onDelete ||
                                            onBulkEdit ||
                                            customModalButton?.requireSelected) && (
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        onChange={(e) =>
                                                        {
                                                            if (e.target.checked)
                                                            {
                                                                setSelectedRecords([
                                                                    ...selectedRecords,
                                                                    record,
                                                                ])
                                                            } else
                                                            {
                                                                setSelectedRecords(
                                                                    selectedRecords.filter(
                                                                        (
                                                                            checkedRecord
                                                                        ) =>
                                                                            checkedRecord[uniqueIdentifier] !==
                                                                            record[uniqueIdentifier]
                                                                    )
                                                                )
                                                            }
                                                        }}
                                                        checked={selectedRecords.includes(
                                                            record
                                                        )}
                                                        disabled={isCheckboxDisabled(
                                                            record
                                                        )}
                                                    />
                                                </td>
                                            )}

                                        {columns.map((column) => (
                                            <Hidden
                                                sm={column.hide}
                                                xs={column.hide}
                                                key={column.name}>
                                                <td
                                                    style={{
                                                        fontWeight:
                                                            column.bold && 500,
                                                        maxWidth:
                                                            column.maxWidth,
                                                        color: handleConditional(
                                                            column,
                                                            record[column.name],
                                                            "color"
                                                        ),
                                                    }}>
                                                    {renderTableCellContent(
                                                        column,
                                                        state,
                                                        record,
                                                        i
                                                    )}
                                                </td>
                                            </Hidden>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={columns.length + 1}>
                                        There are no records to show
                                    </td>
                                </tr>
                            )
                        ) : (
                            <tr>
                                <td
                                    colSpan={
                                        onDelete
                                            ? columns.length + 1
                                            : columns.length
                                    }>
                                    <Spinner />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </TableComponent>
            </div>
            {
                !noPagination && (
                    <Pagination
                        loading={loading}
                        records={records}
                        offset={offset}
                        setOffset={setOffset}
                        limit={limit}
                        setLimit={setLimit}
                        totalRecords={totalRecords}
                        hideTotalRecords={hideTotalRecords}
                        customPaginationName={customPaginationName}
                    />
                )
            }
        </div >
    )
}
