import React, { useEffect, useState, useContext } from "react"
import { useFormReducer } from "../../Hooks/useFormReducer"
import { useRequest } from "../../Hooks/useRequest"
import StagedForm from "../Form/StagedForm"
import Modal from "../Modal/Modal"
import { useHistory } from "react-router-dom"
import { ToastContext } from "../../Contexts/ToastContext"
import { regex } from "../../Constants/regex"

interface IProps
{
    open?: boolean
    setOpen?: (val?: boolean) => void
    displayName: string
    fieldFrom?: IFieldFrom
    settingsStages?: ISettingsStage[]
    availabilityCheck?: any
    defineSubmitData: (data) => IModalSubmitData[]
    allowBulkAdd?: boolean
    confirm?: boolean
    selectedRecords?: any[]
    action: string
    customTitle?: string
    uniqueIdentifier?: string
    pullData?: () => void
    deleteCheck?: any
    refreshData?: () => void
    loadRecords?: {
        dataDefinition?: (data: {
            [key: string]: any
        }) => { [key: string]: any }
        url: string
        urlSuffix?: string
    }[]
    deleteFailureMessage?: any
    apiUniqueIdentifier?: string
    onSuccess?: (response) => void
}

export function RecordModal({
    open,
    setOpen,
    displayName,
    fieldFrom,
    settingsStages = [],
    availabilityCheck,
    defineSubmitData,
    allowBulkAdd = true,
    confirm,
    selectedRecords,
    action,
    customTitle,
    uniqueIdentifier,
    pullData,
    refreshData,
    deleteCheck,
    loadRecords,
    deleteFailureMessage,
    apiUniqueIdentifier,
    onSuccess
}: IProps)
{
    const { add } = useContext(ToastContext)
    const { state, updateForm, updateErrors } = useFormReducer({
        record: {
            bulkAdd: false,
            totalNumber: 1,
            totalCost: 0,
            fieldFrom: "",
            fieldTo: null,
            fieldFromLoading: false,
            errors: [],
        },
    })
    const [loading, setLoading] = useState(false)

    const history = useHistory()

    const { postRequest, putRequest, deleteRequest, getRequest } = useRequest()

    useEffect(() =>
    {
        async function loadFieldFromDefaultValue()
        {
            const val = await fieldFrom.loadDefaultValue()
            updateForm({
                target: {
                    name: "fieldFrom",
                    value: val,
                },
            })
            updateForm({
                target: {
                    name: "fieldFromLoading",
                    value: false,
                },
            })
        }

        if (fieldFrom && fieldFrom.loadDefaultValue)
        {
            updateForm({
                target: {
                    name: "fieldFromLoading",
                    value: true,
                },
            })
            loadFieldFromDefaultValue()
        }
    }, [fieldFrom])

    useEffect(() =>
    {
        updateForm({
            target: {
                name: "selectedRecords",
                value: selectedRecords,
            },
        })
    }, [selectedRecords])

    useEffect(() =>
    {
        async function initialiseSettingsStages()
        {
            setLoading(true)
            if (
                loadRecords &&
                settingsStages &&
                selectedRecords &&
                selectedRecords.length === 1
            )
            {
                loadRecords.forEach(async (loadRecord) => 
                {
                    let url = `${loadRecord.url}/${selectedRecords[0].id}`
                    if (loadRecord.urlSuffix)
                    {
                        url = url + `${loadRecord.urlSuffix}`
                    }
                    const record = await getRequest(url)

                    if (loadRecord.dataDefinition)
                    {
                        const formattedData = loadRecord.dataDefinition(record)
                        Object.keys(formattedData).forEach((key) =>
                        {
                            if (
                                settingsStages.filter((settingsStage) =>
                                {
                                    return (
                                        settingsStage.fields.filter(
                                            (field) => field.name === key
                                        ).length > 0
                                    )
                                }).length > 0
                            )
                            {
                                updateForm({
                                    target: {
                                        name: key,
                                        value: formattedData[key],
                                    },
                                })
                            }
                        })
                    } else
                    {
                        settingsStages.forEach((settingsStage) =>
                        {
                            settingsStage.fields.forEach((field) =>
                            {
                                if (Object.keys(record).includes(field.name))
                                {
                                    updateForm({
                                        target: {
                                            type: field.type,
                                            name: field.name,
                                            value: record[field.name] ? record[field.name] : "",
                                        },
                                    })
                                }
                            })
                        })
                    }
                })
            } else if (settingsStages)
            {
                settingsStages.forEach((settingsStage) =>
                {
                    settingsStage.fields.forEach((field) =>
                    {
                        let value: any = ""
                        if (field.type === "text")
                        {
                            value = ""
                        }
                        if (field.type === "switch")
                        {
                            value = null
                        }
                        if (field.type === "time")
                        {
                            if (field.name === "end")
                            {
                                value = "23:59"
                            }
                            else
                            {
                                value = "00:00"
                            }
                        }
                        if (field.type === "select")
                        {
                            value = ""
                        }
                        if (field.type === "multiText")
                        {
                            value = []
                        }
                        if (field.defaultValue !== null && field.defaultValue !== undefined)
                        {
                            value = field.defaultValue
                        }
                        updateForm({
                            target: {
                                type: field.type,
                                name: field.name,
                                value,
                            },
                        })
                    })
                })
            }
            setLoading(false)
        }

        initialiseSettingsStages()
    }, [])

    useEffect(() =>
    {
        if (state.bulkAdd)
        {
            updateForm({
                target: {
                    type: "number",
                    name: "fieldTo",
                    value: Number(+state.fieldFrom + 1),
                },
            })
        } else
        {
            updateForm({
                target: {
                    type: "number",
                    name: "fieldTo",
                },
            })
        }
    }, [state.bulkAdd])

    useEffect(() =>
    {
        if (state.fieldTo)
        {
            updateForm({
                target: {
                    type: "number",
                    name: "totalNumber",
                    value: state.fieldTo - state.fieldFrom + 1,
                },
            })
        } else if (Array.isArray(state.fieldFrom))
        {
            updateForm({
                target: {
                    type: "number",
                    name: "totalNumber",
                    value: state.fieldFrom.length,
                },
            })
        }
        else
        {
            updateForm({
                target: {
                    type: "number",
                    name: "totalNumber",
                    value: 1,
                },
            })
        }
        updateForm({
            target: {
                type: "number",
                name: "totalCost",
                value: state.totalNumber * 6,
            },
        })
    }, [state.fieldFrom, state.fieldTo, state.totalNumber])

    useEffect(() =>
    {
        updateForm({
            target: {
                type: "number",
                name: "totalCost",
                value: state.totalNumber * 6,
            },
        })
    }, [state.totalNumber])

    function getConfirmationText()
    {
        let confirmationText = `Are you sure you want to ${action}`

        if (state.selectedRecords)
        {
            if (regex.pluralCheck.test(displayName))
            {
                confirmationText += ` ${state.selectedRecords.length} ${state.selectedRecords.length > 1 ? displayName.slice(0, -1) + "ies" : displayName}`
            }
            else
            {
                confirmationText += ` ${state.selectedRecords.length} ${displayName}${state.selectedRecords.length > 1 ? "s" : ""}`
            }
        }
        else
        {
            if (regex.pluralCheck.test(displayName))
            {
                confirmationText += ` ${state.totalNumber} ${state.totalNumber > 1 ? displayName.slice(0, -1) + "ies" : displayName}`
            }
            else
            {
                confirmationText += ` ${state.totalNumber} ${displayName}${state.totalNumber > 1 ? "s" : ""}`
            }
        }

        return confirmationText + "?"
    }

    function getAccordionTitle(succeeded)
    {
        let title = ""
        if (succeeded)
        {
            if (state.successes && state.successes.length > 0)
            {
                if (regex.pluralCheck.test(displayName))
                {
                    title += `${state.successes.length} ${state.successes.length > 1 ? displayName.slice(0, -1) + "ies" : displayName}`
                }
                else
                {
                    title += `${state.successes.length} ${displayName}${state.successes.length > 1 ? "s" : ""}`
                }
            }
            else
            {
                if (regex.pluralCheck.test(displayName))
                {
                    title += `${state.totalNumber} ${state.totalNumber > 1 ? displayName.slice(0, -1) + "ies" : displayName}`
                }
                else
                {
                    title += `${state.totalNumber} ${displayName}${state.totalNumber > 1 ? "s" : ""}`
                }
            }

            title += ` successfully`

            if (action === "edit")
            {
                title += ` edited`
            } else if (action === "delete")
            {
                title += ` deleted`
            } else
            {
                title += ` created`
            }
        }
        else
        {

            if (state.failures && state.failures.length > 0)
            {
                title += `${state.failures.length} error${state.failures.length > 1 ? "s" : ""
                    } occurred`
            }
        }

        return title
    }

    function createObjects(
        path,
        postData,
        identifier,
        displayName,
        bulkIncrement
    ): Promise<{ successes?: postFeedback[]; failures?: postFeedback[] }>
    {
        return new Promise(async (resolve) =>
        {
            const successes = []
            const failures = []

            let switchNames = []

            settingsStages.forEach(settingStage =>
            {
                const stageSwitches = settingStage.fields.filter(field => field.type === "switch")
                stageSwitches.forEach(stageSwitch =>
                {
                    switchNames = switchNames.concat(stageSwitch.name)
                })
            })

            Object.entries(postData).map(([key, value]) =>
            {
                if (typeof value === "object" && value !== null && !Array.isArray(value)) 
                {
                    Object.entries(value).map(([secondKey, secondValue]) =>
                    {
                        if (switchNames.includes(secondKey))
                        {
                            postData[key][secondKey] = postData[key][secondKey] ? postData[key][secondKey] : false
                        }
                    })
                }
                else
                {
                    if (switchNames.includes(key))
                    {
                        postData[key] = postData[key] ? postData[key] : false
                    }
                }
            })

            if (state.bulkAdd)
            {
                let count = 1
                for (let i = state.fieldFrom; i <= state.fieldTo; i++)
                {
                    postData = { ...postData, [identifier]: Number(i) }

                    if (bulkIncrement)
                    {
                        postData = { ...postData, [bulkIncrement]: state[bulkIncrement] + " " + count++ }
                    }

                    let response = await postRequest(
                        postData,
                        path
                    )
                    if (response)
                    {
                        if (response.success)
                        {
                            successes.push({
                                id: i,
                                message: "created successfully",
                            })
                            onSuccess && onSuccess(response)
                        } else
                        {
                            if (response.validation)
                            {
                                failures.push({
                                    id: i,
                                    message: "Please go back to rectify the validation errors.",
                                })

                                for (let key in response.validation)
                                {
                                    if (
                                        response.validation.hasOwnProperty(key)
                                    )
                                    {
                                        updateErrors({
                                            target: {
                                                name: key,
                                                value: response.validation[key]
                                            }
                                        })
                                    }
                                }
                            } else
                            {
                                failures.push({
                                    id: i,
                                    message: response.message,
                                })
                            }
                        }
                    } else
                    {
                        failures.push({
                            id: i,
                            message: "Something went wrong please try again",
                        })
                    }
                }
            } else if (Array.isArray(state.fieldFrom))
            {
                for (let i = 0; i < state.fieldFrom.length; i++)
                {
                    const fieldFrom = state.fieldFrom[i]
                    let response = await postRequest(
                        { ...postData, [identifier]: fieldFrom },
                        path
                    )

                    if (response)
                    {
                        if (response.success)
                        {
                            successes.push({
                                id: fieldFrom,
                                message: "created successfully",
                            })
                            onSuccess && onSuccess(response)
                        } else
                        {
                            if (response.validation)
                            {
                                failures.push({
                                    id: fieldFrom ? fieldFrom : "Validation Error",
                                    message: "Please go back to rectify the validation errors.",
                                })
                                for (let key in response.validation)
                                {
                                    if (response.validation.hasOwnProperty(key))
                                    {
                                        updateErrors({
                                            target: {
                                                name: key,
                                                value: response.validation[key]
                                            }
                                        })
                                    }
                                }
                            }
                            else
                            {
                                failures.push({
                                    id: fieldFrom,
                                    message: response.message,
                                })
                            }
                        }
                    } else
                    {
                        failures.push({
                            id: displayName,
                            message: "Something went wrong please try again",
                        })
                    }
                }
            }
            else
            {
                if (identifier && state?.fieldFrom)
                {
                    postData = { ...postData, [identifier]: state.fieldFrom }
                }
                let response = await postRequest(postData, path)
                console.log(response)
                if (response)
                {
                    if (response.success)
                    {
                        successes.push({
                            id: displayName,
                            message: "created successfully",
                        })
                        onSuccess && onSuccess(response)
                    } else
                    {
                        if (response.validation)
                        {
                            failures.push({
                                id: state.fieldFrom ? state.fieldFrom : "Validation Error",
                                message: "Please go back to rectify the validation errors.",
                            })

                            for (let key in response.validation)
                            {
                                if (response.validation.hasOwnProperty(key))
                                {
                                    updateErrors({
                                        target: {
                                            name: key,
                                            value: response.validation[key]
                                        }
                                    })
                                }
                            }
                        } else
                        {
                            failures.push({
                                id: displayName,
                                message: response.message,
                            })
                        }
                    }
                } else
                {
                    failures.push({
                        id: displayName,
                        message: "Something went wrong please try again",
                    })
                }
            }

            const feedback = {
                successes: successes,
                failures: failures,
            }

            resolve(feedback)
        })
    }

    function editObjects(
        path,
        postData,
        displayName,
        pathSuffix,
        allowNull,
        bulkIncrement
    ): Promise<{ successes?: postFeedback[]; failures?: postFeedback[] }>
    {
        return new Promise(async (resolve) =>
        {
            const successes = []
            const failures = []

            for (let i = 0; i < state.selectedRecords.length; i++)
            {
                let record = state.selectedRecords[i]
                let count = i + 1;

                Object.entries(postData).map(([key, value]) =>
                {
                    if (value !== null && value !== undefined && value !== "")
                    {
                        if (typeof value === "object" && !(value instanceof Array))
                        {
                            Object.entries(value).map(([key1, value1]) =>
                            {
                                if (value1 !== null && value1 !== undefined && value1 !== "")
                                {
                                    record[key][key1] = value1
                                }
                            })
                        }
                        else
                        {
                            record[key] = postData[key]
                        }
                    }
                    else if (allowNull && allowNull.includes(key) && value === null)
                    {
                        record[key] = postData[key]
                    }
                })

                if (bulkIncrement && postData[bulkIncrement])
                {
                    record[bulkIncrement] = state[bulkIncrement] + " " + count++
                }

                const id = !uniqueIdentifier
                    ? record.id
                    : record[uniqueIdentifier]



                let response = await putRequest(
                    record,
                    path + `/` + state.selectedRecords[i].id + pathSuffix
                )

                if (response)
                {
                    if (response.success)
                    {
                        successes.push({
                            id,
                            message: "edited successfully",
                        })
                        onSuccess && onSuccess(response)
                    } else
                    {
                        if (response.validation)
                        {
                            failures.push({
                                id,
                                message: "Please go back to rectify the validation errors.",
                            })

                            for (let key in response.validation)
                            {
                                if (response.validation.hasOwnProperty(key))
                                {
                                    updateErrors({
                                        target: {
                                            name: key,
                                            value: response.validation[key]
                                        }
                                    })
                                }
                            }
                        } else
                        {
                            failures.push({
                                id,
                                message: response.message,
                            })
                        }
                    }
                } else
                {
                    failures.push({
                        id,
                        message: "Something went wrong please try again",
                    })
                }
            }

            const feedback = {
                successes: successes,
                failures: failures,
            }

            resolve(feedback)
        })
    }
    function deleteObjects(
        path,
        displayName,
        pathSuffix,
        indexPagePath,
        deleteFailureMessage
    ): Promise<{ successes?: postFeedback[]; failures?: postFeedback[] }>
    {
        return new Promise(async (resolve) =>
        {
            const successes = []
            const failures = []

            for (let i = 0; i < state.selectedRecords.length; i++)
            {
                const record = state.selectedRecords[i]

                const id = !uniqueIdentifier
                    ? record.id
                    : record[uniqueIdentifier]

                let response = await deleteRequest(
                    null,
                    path +
                    `/${!apiUniqueIdentifier ? record.id : record[apiUniqueIdentifier]}` +
                    pathSuffix
                )

                if (response)
                {
                    if (response.success)
                    {
                        if (indexPagePath)
                        {
                            add({
                                text: `Successfully deleted ${displayName}`,
                                type: "success",
                            })
                            history.push(indexPagePath)
                        } else
                        {
                            successes.push({
                                id,
                                message: "Deleted Successfully",
                            })
                            onSuccess && onSuccess(response)
                        }
                    } else
                    {
                        if (response.validation)
                        {
                            for (var key in response.validation)
                            {
                                if (response.validation.hasOwnProperty(key))
                                {
                                    failures.push({
                                        id: id,
                                        message: key + " " + response.validation[key],
                                    })
                                }
                            }
                        } else
                        {
                            if (deleteFailureMessage)
                            {
                                let failureMessage = await deleteFailureMessage(response)
                                if (typeof failureMessage === 'string')
                                {
                                    failures.push({
                                        id: id,
                                        message: failureMessage
                                    })
                                }
                                else
                                {
                                    for (let failString of failureMessage)
                                    {
                                        failures.push({
                                            id: id,
                                            message: failString
                                        })
                                    }
                                }
                            }
                            else
                            {

                                failures.push({
                                    id: id,
                                    message: response.message,
                                })
                            }
                        }
                    }
                }
            }

            const feedback = {
                successes: successes,
                failures: failures,
            }

            resolve(feedback)
        })
    }

    function findFieldError(name)
    {
        if (state.errors && state.errors.length > 0)
        {
            const errors = state.errors.filter((error) => error.name === name)
            if (errors && errors.length > 0)
            {
                return errors[0].value
            }
        }
        return null
    }

    return (
        <Modal open={open} setOpen={setOpen} maxWidth={700}>
            {!customTitle ? (
                <h4>
                    {action.charAt(0).toUpperCase() + action.slice(1)}{" "}
                    {displayName.charAt(0).toUpperCase() + displayName.slice(1)}
                </h4>
            ) : (
                <h4>{customTitle}</h4>
            )}
            <StagedForm
                modal
                state={state}
                loading={loading}
                onComplete={() =>
                {
                    pullData && pullData()
                    setOpen(false)
                }}
                stages={[
                    fieldFrom && {
                        title: `${fieldFrom.label}s`,
                        error: findFieldError("fieldFrom") || findFieldError("fieldTo"),
                        onComplete: {
                            buttonText: "Create",
                            function: () =>
                            {
                                return new Promise(async (resolve) =>
                                {
                                    let ret = null
                                    if (fieldFrom.required && !state.fieldFrom && state.fieldFrom !== 0)
                                    {
                                        updateErrors({ target: { name: "fieldFrom", value: "This is a required field" } })
                                        ret = { success: false }
                                    }
                                    else if (availabilityCheck)
                                    {
                                        ret = await availabilityCheck(
                                            state.fieldFrom,
                                            state.fieldTo
                                        )
                                        if (ret && ret.error)
                                        {
                                            updateErrors({
                                                target: {
                                                    name: "fieldFrom",
                                                    value: ret.error,
                                                },
                                            })
                                        } else
                                        {
                                            updateErrors({
                                                target: {
                                                    name: "fieldFrom",
                                                    value: null,
                                                },
                                            })
                                        }
                                    }
                                    else
                                    {
                                        ret = { success: true }
                                    }
                                    resolve(ret)
                                })
                            },
                        },
                        fields: [
                            {
                                ...fieldFrom,
                                value: state.fieldFrom,
                                onChange: updateForm,
                                loading: state.fieldFromLoading,
                                setError: updateErrors,
                                error: findFieldError("fieldFrom"),
                                name: "fieldFrom",
                                required: action === "create" && fieldFrom.required
                            },
                            state.bulkAdd && {
                                ...fieldFrom,
                                name: "fieldTo",
                                value: state.fieldTo,
                                min: Number(+state.fieldFrom + 1),
                                label: "To",
                                onChange: updateForm,
                                setError: e =>
                                {
                                    if (e.target.value === "")
                                    {
                                        updateErrors({
                                            target:
                                                { name: "fieldFrom", value: "" }
                                        })
                                        updateErrors(e)
                                    }
                                    else
                                    {
                                        updateErrors(e)
                                    }

                                },
                                error: findFieldError("fieldTo"),
                            },
                            allowBulkAdd && {
                                type: "switch",
                                name: "bulkAdd",
                                value: state.bulkAdd,
                                label: "Bulk Add",
                                onChange: (e) =>
                                {
                                    updateForm(e)
                                    updateErrors({ target: { name: "fieldFrom", value: "" } })
                                }
                            },
                            {
                                type: "paragraph",
                                value: "Click 'Next' to continue",
                            },
                        ].filter(Boolean),
                    },
                    ...settingsStages.filter(stage => !state.bulkAdd || !stage.hideOnBulkAdd).map((stage) => ({
                        ...stage,
                        error: !!stage.fields.filter(field => findFieldError(field.name)).filter(Boolean).length,
                        onComplete: {
                            buttonText: "Next",
                            function: () =>
                                new Promise(async (resolve) =>
                                {
                                    let success = true
                                    if (action === "create")
                                    {
                                        stage.fields.map(field =>
                                        {
                                            if (field.required && !state[field.name] && state[field.name] !== 0)
                                            {
                                                updateErrors({ target: { name: field.name, value: "This is a required field" } })
                                                success = false
                                            }
                                        })
                                    }
                                    resolve({ success })
                                })
                        },
                        fields: [
                            selectedRecords &&
                            selectedRecords.length > 1 && {
                                type: "paragraph",
                                value:
                                    "All fields left blank will be ignored, and will not affect the selected records.",
                            },
                            ...stage.fields.map((field) =>
                            {
                                return ({
                                    ...field,
                                    setError: updateErrors,
                                    error: findFieldError(field.name),
                                    onChange: updateForm,
                                    nullable:
                                        selectedRecords &&
                                        selectedRecords.length > 1,
                                    value: field.value
                                        ? field.value
                                        : state[field.name],
                                    required: (action === "create" || selectedRecords.length < 2) && field.required
                                })

                            }).filter(Boolean),
                        ],
                    })),
                    confirm && {
                        title: "Confirm",
                        onComplete: {
                            buttonText: "Create",
                            function: () =>
                            {
                                return new Promise(async (resolve) =>
                                {
                                    const ret = await defineSubmitData(state)
                                    resolve(ret)
                                })
                            },
                        },
                        fields: [
                            {
                                type: "paragraph",
                                value: getConfirmationText(),
                            },
                            {
                                type: "info",
                                name: "totalNumber",
                                value: state.totalNumber,
                                label: `Total ${displayName}`,
                            },
                            {
                                type: "info",
                                name: "totalCost",
                                value: `£${state.totalCost} per month`,
                                label: "Total Cost",
                            },
                            {
                                type: "paragraph",
                                value: "Click 'Next' to continue",
                            },
                        ],
                    },
                    !confirm && {
                        title: "Confirm",
                        onComplete: {
                            buttonText: "Create",
                            function: () =>
                            {
                                return new Promise(async (resolve) =>
                                {
                                    setLoading(true)
                                    const returnDatas = await defineSubmitData(
                                        state
                                    )

                                    returnDatas.forEach(async (retData) =>
                                    {
                                        let feedback
                                        if (action === "create")
                                        {
                                            feedback = await createObjects(
                                                retData.path,
                                                retData.postData,
                                                retData.identifier,
                                                displayName,
                                                retData.bulkIncrement
                                            )
                                        }
                                        if (action === "edit")
                                        {
                                            feedback = await editObjects(
                                                retData.path,
                                                retData.postData,
                                                displayName,
                                                retData.pathSuffix
                                                    ? retData.pathSuffix
                                                    : "",
                                                retData.allowNull,
                                                retData.bulkIncrement
                                            )
                                        }
                                        if (action === "delete")
                                        {
                                            feedback = await deleteObjects(
                                                retData.path,
                                                displayName,
                                                retData.pathSuffix
                                                    ? retData.pathSuffix
                                                    : "",
                                                retData.indexPagePath,
                                                deleteFailureMessage
                                            )
                                        }

                                        updateForm({
                                            target: {
                                                name: "successes",
                                                value: feedback.successes,
                                            },
                                        })
                                        updateForm({
                                            target: {
                                                name: "failures",
                                                value: feedback.failures,
                                            },
                                        })
                                        setLoading(false)
                                    })
                                    resolve({ success: true })
                                })
                            },
                        },
                        fields: [
                            {
                                type: "paragraph",
                                value: getConfirmationText(),
                            },
                        ],
                    },
                    !state.bulkAdd &&
                    !state.selectedRecords &&
                    !(Array.isArray(state.fieldFrom) && state.fieldFrom.length > 1) && {
                        title: "Feedback",
                        fields: [
                            state.failures &&
                            state.failures.length > 0 && {
                                type: "feedbackAccordion",
                                title: getAccordionTitle(false),
                                items:
                                    state.failures &&
                                    state.failures.map(
                                        (failure) =>
                                            `${failure.id} - ${failure.message}`
                                    ),
                                variant: "red",
                            },
                            (!state.failures ||
                                state.failures.length === 0) &&
                            !state.deleteFailure && {
                                type: "paragraph",
                                value: `The ${displayName} was ${action === "edit"
                                    ? `${action}ed`
                                    : `${action}d`
                                    } successfully`,
                            },
                        ],
                    },
                    state.deleteFailure &&
                    state.deleteFailureMessage && {
                        title: "Feedback",
                        fields: [
                            {
                                type: "paragraph",
                                value: state.deleteFailureMessage,
                            },
                        ],
                    },
                    (state.bulkAdd || state.selectedRecords || (Array.isArray(state.fieldFrom) && state.fieldFrom.length > 1)) &&
                    !state.deleteFailure && {
                        title: "Feedback",
                        fields: [
                            state.successes &&
                            state.successes.length > 0 && {
                                type: "feedbackAccordion",
                                title: getAccordionTitle(true),
                                items:
                                    state.successes &&
                                    state.successes.map(
                                        (success) =>
                                            `${success.id} - ${success.message}`
                                    ),
                            },
                            state.failures &&
                            state.failures.length > 0 && {
                                type: "feedbackAccordion",
                                title: getAccordionTitle(false),
                                items:
                                    state.failures &&
                                    state.failures.map(
                                        (failure) =>
                                            `${failure.id} - ${failure.message}`
                                    ),
                                variant: "red",
                            },
                        ],
                    },
                ].filter(Boolean)}
            />
        </Modal>
    )
}
