import { createStyles, makeStyles, useMediaQuery } from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import { hasPermission, permissions } from "../../Constants/permissions"
import { routes } from "../../Constants/routes"
import { SessionContext } from "../../Contexts/SessionContext"
import { ThemeContext } from "../../Contexts/ThemeContext"
import { useHandleOutsideClick } from "../../Hooks/useHandleOutsideClick"
import useSidebarItems from "../../Hooks/useSidebarItems"
import { MenuItem } from "../MenuItem/MenuItem"
import variables from "./../../Constants/variables.scss"
import "./sidebar.scss"
import TopUpFormModal from "./../../Pages/AccountTransactionsPage/TopUpFormModal";

interface IProps
{
    sidebarExpanded?: boolean
    setSidebarExpanded?: (val: boolean) => void
}

export default function Sidebar({ sidebarExpanded, setSidebarExpanded }: IProps)
{
    const [lockExpanded, setLockExpanded] = useState(true)
    const [sectionOpen, setSectionOpen] = useState<number>(null)
    const [section, setSection] = useState(null)
    const [initialCheckRun, setInitialCheckRun] = useState(false)
    const [doTransition, setDoTransition] = useState(false)
    const sidebarItems = useSidebarItems()
    const { state } = useContext(SessionContext);
    const [topUpModalOpen, setTopUpModalOpen] = useState(false)

    const { accentColour } = useContext(ThemeContext)

    const useStyles = makeStyles((theme) =>
        createStyles({
            sidebarItem: {
                "&:hover": {
                    "& svg": {
                        color: accentColour
                    }
                }
            },
        })
    );

    const classes = useStyles();

    useEffect(() =>
    {
        const timer = setTimeout(() =>
        {
            setDoTransition(true)
        }, 100)
        return () => clearTimeout(timer);
    }, [])

    const isMobile = useMediaQuery("(max-width:600px)")

    const node = useHandleOutsideClick(sidebarExpanded, setSidebarExpanded)

    useEffect(() =>
    {
        if (isMobile)
        {
            setSectionOpen(null)
            setInitialCheckRun(false)
            setLockExpanded(false)
        } else
        {
            setLockExpanded(true)
        }
    }, [isMobile])

    useEffect(() =>
    {
        if (!initialCheckRun && !isMobile)
        {
            const sectionRegex = /\/([A-Za-z-]*)/
            const result = sectionRegex.exec(window.location.pathname)

            if (result.length > 0)
            {
                setSection(result[0])

                // Expand menu item with active link
                sidebarItems.forEach((menuItem, index) =>
                {
                    if (
                        menuItem.subItems &&
                        menuItem.subItems.filter(subItem => subItem.url === section)
                            .length > 0
                    )
                    {
                        setSectionOpen(index)
                        setInitialCheckRun(true)
                    }
                })
            }
        }
    }, [isMobile, initialCheckRun, section])

    function showLink(url)
    {
        if (!url)
        {
            return true
        }
        const filteredRoutes = routes.filter(route => route.path === url)

        if (filteredRoutes && filteredRoutes.length)
        {
            if (state.session && state.session.permissions)
            {
                if (hasPermission(filteredRoutes[0].permissions, state.session.permissions, "read"))
                {
                    if (filteredRoutes[0].isRetail)
                    {
                        if (hasPermission([permissions.retail], state.session.permissions, "read"))
                        {
                            return true
                        }
                        else
                        {
                            return false
                        }
                    }
                    else if (filteredRoutes[0].isWholesale)
                    {
                        if (hasPermission([permissions.wholesale], state.session.permissions, "read"))
                        {
                            return true
                        }
                        else
                        {
                            return false
                        }
                    }
                    else if (filteredRoutes[0].isPostpay)
                    {
                        if (hasPermission([permissions.postpay], state.session.permissions, "read"))
                        {
                            return true
                        }
                        else
                        {
                            return false
                        }
                    }
                    else if (filteredRoutes[0].isPrepay)
                    {
                        if (hasPermission([permissions.prepay], state.session.permissions, "read"))
                        {
                            return true
                        }
                        else
                        {
                            return false
                        }
                    }
                    else
                    {
                        return true
                    }
                }
                else
                {
                    return false
                }
            } else
            {
                return false
            }
        } else
        {
            return true
        }
    }

    function visibleSubItems(item)
    {
        return item.subItems.filter(subItem => showLink(subItem.url)).filter(subItem => !subItem.hide)
    }

    return (
        <React.Fragment>
            <div
                ref={node}
                style={{ cursor: "default" }}
                className={`sidebar ${!sidebarExpanded ? "sidebarHidden" : ""} `}
                onMouseLeave={() =>
                {
                    !lockExpanded && setSectionOpen(null)
                }}
            >
                {sidebarItems.map((item, index) => (
                    showLink(item.url) && ((item.subItems && visibleSubItems(item).length > 0) || !item.subItems) && (
                        <div key={item.text} className={`${item.bottom && "sidebarItemBottom"} ${item.borderTop && "sidebarItemBorderTop"}`}>
                            <div
                                key={item.text}
                                style={{
                                    cursor: "default",
                                    borderLeft: item.url === section && `4px solid ${accentColour}`,
                                    paddingBottom: item.button && 20
                                }}
                                className={`flex-row sidebar-item ${item.url === section && "sidebar-item-active"} ${item.nonClickable ? "nonClickable" : ""}  ${classes.sidebarItem}`}
                                onClick={e =>
                                {
                                    if (!item.button)
                                    {
                                        e.preventDefault()
                                        if (!item.nonClickable)
                                        {
                                            if (sectionOpen !== index)
                                            {
                                                setSectionOpen(index)
                                            } else
                                            {
                                                setSectionOpen(0)
                                            }
                                        }
                                    }

                                    if (item.text === "Top Up")
                                    {
                                       setTopUpModalOpen(true)
                                    }
                                }}
                            >
                                <MenuItem
                                    icon={item.icon}
                                    children={item.text}
                                    to={!item.subItems && item.url}
                                    button={item.button && item.button}
                                    isExternalLink={item.isExternal}
                                    iconBoxSize={24}
                                    iconRight={item.subItems && "chevron-left"}
                                    iconColor={item.error ? "#DE4600" : item.url ===
                                        section ? accentColour : item.subItems && "white"}
                                    iconRightStyle={{
                                        transform: sectionOpen === index ? "rotate(-90deg)" : "none",
                                        transition: doTransition && "transform 0.3s ease-out",
                                    }}
                                    iconRightSize="sm"
                                    textClassName="sidebar-text"
                                    iconVariation={item.iconVariant}
                                    onClick={item.onClick}
                                />
                            </div>
                            {item.subItems && (
                                <div
                                    style={{
                                        backgroundColor: variables.sidebarSubItemColor,
                                        height: sectionOpen === index ? visibleSubItems(item).length * 40 : 0,
                                        transition: doTransition && "height 0.3s  ease-out",
                                        overflow: "hidden",
                                    }}
                                >
                                    {sectionOpen === index && (
                                        visibleSubItems(item).map(subItem => (
                                            <div
                                                key={subItem.text}
                                                className={`flex-row sidebar-item ${subItem.url ===
                                                    section && "sidebar-item-active"} ${classes.sidebarItem}`}
                                                style={{ borderLeft: subItem.url === section && `4px solid ${accentColour}` }}
                                            >
                                                <MenuItem
                                                    icon={subItem.icon}
                                                    children={subItem.text}
                                                    to={subItem.url}
                                                    button={subItem.button}
                                                    isExternalLink={subItem.isExternal}
                                                    iconColor={subItem.error ? "#DE4600" : subItem.url ===
                                                        section ? accentColour : "white"}
                                                    iconBoxSize={24}
                                                    iconVariation="regular"
                                                />
                                            </div>
                                        )))}
                                </div>

                            )}
                        </div>
                    )
                ))}
            </div>
            <div
                className={`sidebar-buffer ${lockExpanded ? "sidebar-buffer-expanded" : ""
                    }`}
            />
            {topUpModalOpen && <TopUpFormModal open={topUpModalOpen} setOpen={setTopUpModalOpen} />}
        </React.Fragment>
    )
}
