import React, { useContext, useEffect } from "react"
import { useCookies } from "react-cookie"
import { Route, Switch } from "react-router-dom"
import { hasPermission } from "../Constants/permissions"
import { routes } from "../Constants/routes"
import { SessionContext } from "../Contexts/SessionContext"
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary"
import ErrorPageText from "./ErrorPageText/ErrorPageText"
import PageLayout from "./PageLayout/PageLayout"
import Spinner from "./Spinner/Spinner"

export default function RouterComp()
{
    const [cookies, setCookie, removeCookie] = useCookies();
    const { state } = useContext(SessionContext)

    useEffect(() =>
    {
        var date = new Date();
        var minutes = 40;
        date.setTime(date.getTime() + (minutes * 60 * 1000));
        setCookie("cpBackUrl",
            window.location.href,
            {
                domain: ".voicehost.co.uk",
                path: "/",
                expires: date
            })

        window.onbeforeunload = () => { }
        // }
    }, [])

    return (
        <ErrorBoundary>
            <Switch>
                {
                    routes.map((route: IRoute, index) =>
                        state.session.user_id
                            ? (
                                state.session &&
                                    state.session.permissions &&
                                    hasPermission(
                                        route.permissions,
                                        state.session.permissions,
                                        "read") ?
                                    (
                                        <Route
                                            key={index}
                                            path={route.path}
                                            exact
                                            render={(props) => (
                                                <route.Component prop={props} />
                                            )}
                                        />
                                    ) : (
                                        <Route
                                            key={index}
                                            path={route.path}
                                            exact
                                            render={() => (
                                                <PageLayout>
                                                    <ErrorPageText
                                                        error={"403"}
                                                        text={
                                                            "You do not have access to view this page"
                                                        }
                                                    />
                                                </PageLayout>
                                            )}
                                        />
                                    )

                            ) : (
                                window.location.href.includes("registration") || window.location.href.includes("AOT") ? (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact
                                        render={(props) => (
                                            <route.Component prop={props} />
                                        )}
                                    />
                                ) : (
                                    <Route
                                        render={
                                            () => (
                                                <PageLayout>
                                                    <Spinner />
                                                </PageLayout>
                                            )}
                                    />
                                )
                            )
                    )}
            </Switch>
        </ErrorBoundary >
    )
}
