export function capitaliseFirstLetter(unformatted: String)
{
    if (unformatted)
    {
        return unformatted.charAt(0).toUpperCase() + unformatted.slice(1);
    }
    else
    {
        return null;
    }
}