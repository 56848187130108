import React, { useState } from "react";
import { FormControl } from "react-bootstrap";
import Icon from "../Icons/Icon";

export default function PasswordInput({
    value,
    disabled,
    onChange,
    name,
    small,
    setError,
    regex,
    regexError,
    required,
    maxLength
}: ITextInput)
{

    const [showPassword, setShowPassword] = useState(false)

    function handleOnChange(e)
    {
        const { value } = e.target

        if (!value && !required)
        {
            setError && setError({ target: { ...e.target, name, value: "" } })
        } else if (!value && required)
        {
            setError && setError({ target: { ...e.target, name, value: "This is a required field" } })
        }
        else
        {
            if (maxLength && value.length > maxLength)
            {
                setError && setError({ target: { ...e.target, name, value: `Length must not exceed ${maxLength} characters` } })
            }
            else if (regex)
            {
                if (regex.test(value))
                {
                    setError && setError({ target: { ...e.target, name, value: "" } })
                }
                else
                {
                    setError && setError({ target: { ...e.target, name, value: regexError } })
                }
            }
            else
            {
                setError && setError({ target: { ...e.target, name, value: "" } })
            }
        }
        onChange(e)
    }

    return (
        <div
            className="flex-row flex-1"
            style={{
                position: "relative"
            }}
        >
            <FormControl
                size={small ? "sm" : null}
                style={{ width: small && "80%" }}
                disabled={disabled}
                type={!showPassword ? "password" : "text"}
                name={name}
                onChange={handleOnChange}
                value={String(value)}
            />
            <div
                style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer",
                    height: 38,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingRight: 8,
                }}
            >
                <Icon
                    icon={!showPassword ? "eye" : "eye-slash"}
                    variation={"regular"}
                    onClick={() => setShowPassword(!showPassword)}
                    color={"grey"}
                />
            </div>
        </div>
    )
}
