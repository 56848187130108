import React, { useEffect, useState } from "react";
import Spinner from "../Spinner/Spinner";

export default function LinkField({
    text,
    value,
    newTab,
}: ILinkField)
{
    return (
        value ? (
            newTab ? (
                <div className="flex-row flex-1">
                    <a target="_blank" href={value}>{text}</a>
                </div>) : (
                <div className="flex-row flex-1">
                    <a href={value}>{text}</a>
                </div>
            )
        ) : (
            <Spinner />
        )
    )
}