import React, { useContext } from "react";
import "./spinner.scss"
import { makeStyles, createStyles, CircularProgress } from "@material-ui/core";
import { ThemeContext } from "../../Contexts/ThemeContext";

export default function Spinner()
{
    const { primaryColour } = useContext(ThemeContext)
    const useStyles = makeStyles((theme) =>
        createStyles({
            bottom: {
                color: primaryColour,
                animationDuration: '1000ms',
            },
        })
    );

    const classes = useStyles();

    return (
        <CircularProgress
            disableShrink
            className={classes.bottom}
            size={18}
            thickness={8}
        />
    )
}