import React, { useState, useReducer, useContext, useRef, useEffect } from "react";
import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage";
import { AddTelephoneNumberModal } from "./AddTelephoneNumberModal";
import { regex } from "../../Constants/regex";
import { regexError } from "../../Constants/regexError";
import useFun from "../../Hooks/useAPIRequests"
import { SessionContext } from "../../Contexts/SessionContext";
import { hasPermission, permissions } from "../../Constants/permissions";

export default function TelephoneNumbersIndexPage()
{

    const { getPromise } = useFun()
    const { state: sessionState } = useContext(SessionContext)
    const indexRef = useRef()

    const editModalSettings: ISettingsStage[] = [
        {
            title: "Settings",
            fields: [
                {
                    type: "select",
                    label: "Call Recording",
                    name: "callRecord",
                    options: [
                        { value: 0, label: "None" },
                        { value: 1, label: "Enabled - Pausable" },
                        { value: 2, label: "Enabled - Non-Pausable" },
                    ],
                },
                {
                    type: "switch",
                    label: "Warn Caller",
                    name: "callRecordWarn",
                    helptext:
                        "Warn the caller that the call is being recorded by playing 'This call may be monitored or recorded' before continuing.",
                },
                {
                    type: "select",
                    label: "Destinations",
                    name: "destination",
                    placeholder: "Destination",
                    loadOptions: () =>
                        getPromise({
                            body: {},
                            value: "destination",
                            label: "name",
                            url: "destinations",
                        }),
                    options: [{ label: "None - Reject Calls", value: "NONE" }],
                    helptext:
                        "Select the destination where calls to this number should be sent.",
                },
                {
                    type: "switch",
                    label: "Anonymous Call Reject",
                    name: "anonCallReject",
                    helptext:
                        "Reject incoming calls if the caller ID is 'Anonymous' or 'Withheld'." +
                        "Any calls with the caller ID set to 'Unavailable' will not be rejected."
                }
            ]
        },
        {
            title: "Emergency Service Settings",
            fields: [
                {
                    type: "text",
                    label: "Title",
                    name: "title",
                    maxLength: 20
                },
                {
                    type: "text",
                    label: "First Name",
                    name: "forename",
                    maxLength: 20
                },
                {
                    type: "text",
                    label: "Surname",
                    name: "name",
                    maxLength: 50
                },
                {
                    type: "text",
                    label: "Business Suffix",
                    name: "businessSuffix",
                    maxLength: 50
                },
                {
                    type: "text",
                    label: "Premises",
                    name: "premises",
                    maxLength: 60
                },
                {
                    type: "text",
                    label: "Thoroughfare",
                    name: "thoroughfare",
                    maxLength: 55
                },
                {
                    type: "text",
                    label: "Locality",
                    name: "locality",
                    maxLength: 30
                },
                {
                    type: "text",
                    label: "Postcode",
                    name: "postcode",
                    regex: regex.postcode,
                    regexError: regexError.postcode
                }
            ],
        }
    ]

    const filters: IFilterDropdown = {
        filters: [
            {
                name: "callRecording",
                type: "select",
                label: "Call Recording",
                multi: true,
                options: [
                    { label: "Not Enabled", value: 0 },
                    { label: "Enabled - Pausable", value: 1 },
                    { label: "Enabled - Non Pausable", value: 2 },
                ],
            }
        ],
    }

    const columns: IColumn[] = [
        {
            name: "telephoneNumber",
            bold: true,
            title: "Number",
            link: { root: "telephone-numbers" },
            sortBy: true
        },
        {
            name: "nickname",
            hide: true,
            title: "Nickname",
            sortBy: true
        },
        {
            name: "displayDestination",
            title: "Destination",
        },
        {
            name: "callRecord",
            title: "Call Recording",
            conditional: [
                { value: 0, text: "Not Enabled", icon: "microphone-slash" },
                { value: 1, text: "Enabled - Pausable", icon: "pause" },
                { value: 2, text: "Enabled - Not Pausable", icon: "microphone" },
            ],
        }
    ]

    const [addOpen, setAddOpen] = useState(false)

    const customModalButton: IButton =
    {
        size: "sm",
        variant: "success",
        style: {
            width: 32,
            height: 38,
            marginRight: 4,
        },
        icon: "plus",
        onClick: () => setAddOpen(true),
        requireSelected: false,
        leftSide: true
    }

    async function getFailureMessage(response)
    {
        if (response.usages)
        {
            let inUseObject = []
            for (let inUse of response.usages)
            {
                inUseObject.push(` In use in ${inUse.type} ${inUse.name}`)
            }
            return inUseObject
        }
        else if (response.message)
        {
            return response.message
        }
        else
        {
            return "Unexpected error occured. Please try again or contact support."
        }
    }


    return (
        <React.Fragment>
            <GenericIndexPage
                title={"Telephone Numbers"}
                urlString={"/numbering/allocated"}
                ref={indexRef}
                restType={"POST"}
                columns={columns}
                searchable={true}
                filters={filters}
                customModalButton={hasPermission([permissions.numbers, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "create") && customModalButton}
                permissions={[permissions.numbers, permissions.admin, permissions.VHAdmin]}
                editModal={{
                    displayName: "Telephone Number",
                    uniqueIdentifier: "telephoneNumber",
                    settingsStages: editModalSettings,
                    loadRecords: [
                        {
                            url: "/numbering/allocated"
                        },
                        {
                            url: "/numbering/allocated",
                            urlSuffix: "/ems"
                        },
                    ],
                    submitData: (data) =>
                    {
                        return [
                            {
                                postData: {
                                    "title": data.title !== "" ? data.title : null,
                                    "forename": data.forename !== "" ? data.forename : null,
                                    "name": data.name !== "" ? data.name : null,
                                    "businesssuffix": data.businessSuffix !== "" ? data.businessSuffix : null,
                                    "premises": data.premises !== "" ? data.premises : null,
                                    "thoroughfare": data.thoroughfare !== "" ? data.thoroughfare : null,
                                    "locality": data.locality !== "" ? data.locality : null,
                                    "postcode": data.postcode !== "" ? data.postcode : null
                                },
                                path: "/numbering/allocated",
                                identifiers: ["id"],
                                type: "edit",
                                displayName: "Telephone Number",
                                pathSuffix: "/ems"
                            },
                            {
                                postData: {
                                    "destination": data.destination !== "" ? data.destination : null,
                                    "callRecordWarn": data.callRecordWarn !== "" ? data.callRecordWarn : null,
                                    "callRecord": data.callRecord !== "" ? data.callRecord : null,
                                    "anonCallReject": data.anonCallReject !== "" ? data.anonCallReject : null,
                                },
                                path: "/numbering/allocated",
                                identifiers: ["id"],
                                type: "edit",
                                displayName: "Telephone Number",
                            }
                        ]
                    }
                }}
                deleteModal={{
                    displayName: "Telephone Number",
                    uniqueIdentifier: "telephoneNumber",
                    submitData: () =>
                    {
                        return [{
                            path: "/numbering/allocated"
                        }]
                    },
                    deleteFailureMessage: getFailureMessage
                }}
                helpSections={[
                    {
                        title: "Telephone Numbers",
                        text: "Configure inbound numbers assigned."
                    }
                ]}
            />
            <AddTelephoneNumberModal
                open={addOpen}
                setOpen={setAddOpen}
                indexRef={indexRef}
            />
        </React.Fragment >
    )
}