import React, { useContext } from "react";
import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage";
import { useHistory } from "react-router-dom"
import useAPIRequests from "../../Hooks/useAPIRequests";
import { permissions } from "../../Constants/permissions";

export default function SoftphoneCLIsIndexPage()
{

    const history = useHistory()
    const { getCallerIdPromise } = useAPIRequests()

    const columns: IColumn[] = [
        {
            name: "name",
            onClick: id =>
            {
                history.push(`/outbound-clis/${id}`)
            },
            bold: true,
            title: "Name",
            sortBy: true,
            conditional: [{
                value: "",
                text: "Name not set",
            }],
        },
        {
            name: "description",
            hide: true,
            title: "Description",
            sortBy: true,
        },
    ]

    const modalSettings: ISettingsStage[] = [
        {
            title: "Settings",
            fields: [
                {
                    type: "text",
                    name: "description",
                    label: "Description",
                    maxLength: 50,
                    required: true
                },
                {
                    type: "select",
                    label: "CLIs",
                    name: "outboundCLIs",
                    loadOptions: () => getCallerIdPromise(),
                    multi: true,
                    required: true
                }
            ]
        }]


    return (
        <GenericIndexPage
            title={"Softphone Outbound CLIs"}
            urlString={"/outboundclilist"}
            permissions={[permissions.outboundclis, permissions.admin, permissions.VHAdmin]}
            columns={columns}
            searchable={true}
            restType={"GET"}
            addModal={{
                displayName: "CLI List",
                allowBulkAdd: false,
                fieldFrom: {
                    field: "name",
                    label: "List Name",
                    type: "text",
                    maxLength: 50,
                    required: true
                },
                settingsStages: modalSettings,
                submitData: (data) =>
                {
                    return [{
                        postData: {
                            description: data.description,
                            numbers: data.outboundCLIs
                        },
                        path: "/outboundclilist",
                        identifier: "name",
                        type: "create",
                        displayName: data.fromField
                    }]
                }
            }}
            deleteModal={{
                displayName: "CLI List",
                uniqueIdentifier: "name",
                submitData: () =>
                {
                    return [{
                        path: "/outboundclilist",
                        displayName: "name",
                        type: "delete"
                    }]
                }
            }}
            helpSections={[
                {
                    title: "Softphone CLIs",
                    text: "This page allows you to set CLI groups that will then be available to Softphone users to display on outbound calls."
                }
            ]}
        />

    )
}