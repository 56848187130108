import React from "react";
import { TabbedCardFormPage } from "../../components/Form/TabbedCardFormPage";
import { useRegistrationFormPage } from "./useRegistrationFormPage";
import PageLayout from "../../components/PageLayout/PageLayout";
import ErrorPageText from "../../components/ErrorPageText/ErrorPageText";

export default function RegistrationFormPage()
{

    const {
        state,
        loading,
        tabs,
        submitData,
        updateForm,
        updateErrors,
        isUK,
        resetCaptcha,
    } = useRegistrationFormPage()

    if (isUK && !loading)
    {
        return (
            <TabbedCardFormPage
                state={state}
                tabs={tabs}
                loading={loading}
                defineSubmitData={submitData}
                submitText={"Register"}
                updateForm={updateForm}
                updateErrors={updateErrors}
                displayName={"Account"}
                removeHeaders={true}
                noPermissions={true}
                resetCaptcha={resetCaptcha}
            />
        )
    }
    else if (!loading)
    {
        return (
            <PageLayout
                removeHeaders={true}>
                <ErrorPageText
                    error={"Unauthorised Access Detected"}
                    text={"It appears you are attempting to register from outside of The United Kingdom. Please contact support to register."}
                />
            </PageLayout>
        )
    }
    else
    {
        return (
            <PageLayout
                removeHeaders={true}>
            </PageLayout>
        )
    }
}