import React, { useContext } from "react";
import { Col, Row } from "react-grid-system";
import { hasPermission, permissions } from "../../Constants/permissions";
import { SessionContext } from "../../Contexts/SessionContext";
import Card from "../Card/Card";
import GradientBackground from "../GradientBackground/GradientBackground";
import Icon from "../Icons/Icon";
import DashboardSummaryItem from "./DashboardSummaryItem";
import "./dashboardWidgets.scss";


export default function DashboardErrors()
{
    const { state: sessionState } = useContext(SessionContext)

    return (
        <Card noPadding style={{ height: "100%" }}>
            <div
                className={"notificationsWidgetTitle"}
            >

                <GradientBackground
                    colors={['#ed5419', '#d10303']}
                    angle={0.6}
                    className="dashboardSummaryUpperSection"
                >
                    <div className="dashboardSummaryIcon">
                        <Icon
                            icon={"exclamation-triangle"}
                            color={"#a30a08"}
                            size={"2x"}
                        />
                    </div>
                    <div className="dashboardSummaryTitle">Account Errors</div>
                    <div className="dashboardSummarySpacer" />
                </GradientBackground>
            </div>

            <div
                style={{
                    fontSize: "0.9em"
                }}
            >
                <Row>
                    <DashboardSummaryItem
                        title={"Emergency Services"}
                        link={"/ems-errors"}
                        icon={"hospital-alt"}
                        color={"red"}
                        requestUrl={"/numbering/allocated/ems/errors"}
                        requestType={"POST"}
                        hidden={!hasPermission([permissions.seats, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read")
                        }
                        doubleWidth
                    />
                </Row>
            </div>
        </Card >
    )
}