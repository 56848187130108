import React, { useState } from "react"
import Icon from "../Icons/Icon"
import Button from "../Button"
import { DropdownMenu } from "../DropdownMenu/DropdownMenu"
import { FormField } from "../Form/FormField"
import { FiltersDropdown } from "../DropdownMenu/FiltersDropdown"

interface IProps
{
    filters: IFilterDropdown
    filterState?: any
    setFilterState?: ISetFilter
    open?: boolean
    setOpen: (val?: boolean) => void
    loading?: boolean
    applyFilters?: () => void
    resetFilters?: () => void
}

export function TableFilters({
    filters,
    filterState,
    setFilterState,
    open,
    setOpen,
    loading,
    applyFilters,
    resetFilters,
}: IProps)
{
    return (
        <div
            style={{
                position: "relative",
                marginRight: 4,
            }}>
            <FiltersDropdown
                borderRadius={4}
                width={350}
                open={open}
                setOpen={setOpen}
                IconComponent={() => (
                    <Button
                        size="sm"
                        variant="primary"
                        style={{ height: 38, whiteSpace: "nowrap" }}>
                        {!filters.label ? "Filters" : filters.label}
                        <Icon
                            icon="sort-down"
                            style={{ marginLeft: 8, marginBottom: 2 }}
                        />
                    </Button>
                )}
                filters={filters}
                applyFilters={applyFilters}
                resetFilters={resetFilters}
                filterState={filterState}
                setFilterState={setFilterState}
            />
        </div>
    )
}
