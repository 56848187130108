import React, { useState } from "react"
import { TabbedCardFormPage } from "../../components/Form/TabbedCardFormPage"
import { useCallQueuesFormPage } from "./useCallQueuesFormPage"
import { RecordModal } from "../../components/RecordModal/RecordModal"
import { permissions } from "../../Constants/permissions"

export default function CallQueuesFormPage({ prop }: any)
{
    const { id } = prop.match.params
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    const {
        loading,
        tabs,
        state,
        updateForm,
        updateErrors,
        submitData,
        deleteData,
    } = useCallQueuesFormPage(id)

    return (
        <React.Fragment>
            <TabbedCardFormPage
                configIdentifier={state.displayName}
                state={state}
                updateForm={updateForm}
                updateErrors={updateErrors}
                tabs={tabs}
                loading={loading}
                onDelete={(() => setDeleteModalOpen(true))}
                defineSubmitData={submitData}
                displayName={"Call Queue"}
                permissions={[permissions.queues, permissions.admin, permissions.VHAdmin]}
            />
            <RecordModal
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
                action={"delete"}
                selectedRecords={[state]}
                uniqueIdentifier={"displayName"}
                displayName={"Call Queue"}
                defineSubmitData={deleteData}
            />
        </React.Fragment>
    )
}
