import React, { useEffect, useState, useContext } from "react"
import Icon from "./Icons/Icon"
import { withStyles, } from '@material-ui/core/styles';
import ButtonComp from '@material-ui/core/Button';
import { ThemeContext } from "../Contexts/ThemeContext";

export default function Button({
    children,
    onClick,
    iconLeft,
    iconRight,
    iconVariation,
    color,
    loading,
    variant,
    style,
    disabled,
    size,
    to,
    type,
    icon
}: IButton)
{
    const { primaryColour, primaryColourLighter } = useContext(ThemeContext)
    const variantThemeArr = [
        {
            variant: "primary",
            backgroundColour: primaryColour,
            borderColour: primaryColour,
            hoverBackgroundColor: primaryColourLighter,
            hoverBorderColor: primaryColourLighter,
            textColour: "white"
        },
        {
            variant: "outline-primary",
            backgroundColour: "none",
            borderColour: primaryColour,
            hoverBackgroundColor: primaryColour,
            textColour: primaryColour,
            hoverTextColour: "white"
        },
        {
            variant: "success",
            backgroundColour: "#28a745",
            borderColour: "#28a745",
            hoverBackgroundColor: "#218838",
            hoverBorderColor: "#218838",
            activeBackgroundColor: "#1e7e34",
            activeBorderColor: "#1c7430",
            textColour: "white"
        },
        {
            variant: "outline-success",
            borderColour: "#28a745",
            hoverBackgroundColor: "#28a745",
            hoverBorderColor: "#28a745",
            activeBackgroundColor: "#1e7e34",
            activeBorderColor: "#1c7430",
            textColour: "#28a745",
            hoverTextColour: "white"
        },
        {
            variant: "danger",
            backgroundColour: "#dc3545",
            borderColour: "#dc3545",
            hoverBackgroundColor: "#c82333",
            hoverBorderColor: "#c82333",
            textColour: "white"
        },
        {
            variant: "outline-danger",
            backgroundColour: "none",
            borderColour: "#dc3545",
            hoverBackgroundColor: "#dc3545",
            hoverBorderColor: "#dc3545",
            textColour: "#dc3545",
            hoverTextColour: "white"
        },
        {
            variant: "warning",
            backgroundColour: "#ffc107",
            borderColour: "#ffc107",
            hoverBackgroundColor: "#e0a800",
            hoverBorderColor: "#d39e00",
            textColour: "#212529",
            hoverTextColour: "#212529"
        },
        {
            variant: "outline-warning",
            borderColour: "#ffc107",
            hoverBackgroundColor: "#e0a800",
            hoverBorderColor: "#d39e00",
            textColour: "#ffc107",
            hoverTextColour: "#212529"
        },
    ]
    const [variantTheme, setVariantTheme] = useState<any>({
        variant: "primary",
        backgroundColour: primaryColour,
        borderColour: primaryColour,
        hoverBackgroundColor: primaryColourLighter,
        hoverBorderColor: primaryColourLighter,
    })

    useEffect(() =>
    {
        const matchingTheme = variantThemeArr.filter(theme => theme.variant === variant)
        if (matchingTheme && matchingTheme.length > 0)
        {
            setVariantTheme(matchingTheme[0])
        } else
        {
            setVariantTheme(variantThemeArr[0])
        }
    }, [variant, primaryColour])

    const BootstrapButton = withStyles({
        root: {
            opacity: disabled ? 0.6 : 1,
            minWidth: 10,
            boxShadow: 'none',
            textTransform: 'none',
            fontSize: 16,
            padding: '6px 12px',
            border: '1px solid',
            lineHeight: 1.5,
            backgroundColor: variantTheme.backgroundColour,
            borderColor: variantTheme.borderColour,
            color: `${color ? color : variantTheme.textColour}${disabled ? " !important" : ""}`,
            fontWeight: 400,
            fontFamily: "inherit",
            letterSpacing: "normal",
            '&:hover': {
                backgroundColor: variantTheme.hoverBackgroundColor,
                borderColor: variantTheme.hoverBorderColor,
                color: `${variantTheme.hoverTextColour}`,
                boxShadow: 'none',
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: variantTheme.activeBackgroundColor,
                borderColor: variantTheme.activeBorderColor,
            },
            '&:focus': {
                outline: "none",
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
            },
        },
    })(ButtonComp);

    return (
        <BootstrapButton
            href={to}
            onClick={onClick}
            disabled={disabled}
            disableRipple
            style={{
                padding: size === "sm" && ".25rem .5rem",
                fontSize: size === "sm" && ".875rem",
                lineHeight: size === "sm" && 1.5,
                borderRadius: size === "sm" && ".2rem",
                display: "flex",
                alignItems: "center",
                ...style,
            }}
            type={type}
        >
            {iconLeft && (
                !loading ? (
                    <Icon
                        icon={iconLeft}
                        transform="shrink-2 down-1"
                        style={{ marginRight: 4 }}
                        variation={iconVariation}
                    />
                ) : (
                    <Icon
                        icon="circle-notch"
                        spin
                        transform={`shrink-2 ${size !== "sm" ? "down-2" : ""}`}
                        style={{ marginRight: 4 }}
                    />
                )
            )}
            {icon && (
                !loading ? (
                    <Icon
                        icon={icon}
                    />
                ) : (
                    <Icon
                        icon="circle-notch"
                        spin
                    />
                )
            )}
            {children}
            {iconRight && (
                !loading ? (
                    <Icon
                        icon={iconRight}
                        transform="shrink-2 down-1"
                        style={{ marginLeft: 4 }}
                        variation={iconVariation}
                    />
                ) : (
                    <Icon
                        icon="circle-notch"
                        spin
                        transform={`shrink-2 ${size !== "sm" ? "down-2" : ""}`}
                        style={{ marginLeft: 4 }}
                    />
                ))}
            {!iconRight && !iconLeft && loading &&
                <Icon
                    icon="circle-notch"
                    spin
                    transform={`shrink-2 ${size !== "sm" ? "down-2" : ""}`}
                    style={{ marginLeft: 4 }}
                />
            }
        </BootstrapButton>
    )
}