import { usePDFDisplayPage } from "../InvoicePage/usePDFDisplayPage";
import Spinner from "../../components/Spinner/Spinner";
import React from "react";

export default function TariffInvoicePage({ prop }: any)
{

    const { name, id } = prop.match.params
    const { response, loading } = usePDFDisplayPage(id, name, `/documents/tariff/${name}/${id}`, "POST", {})
    const pdfString = "data:application/pdf;base64;" + response

    return (
        <div
            style={{
                height: "100vh",
                backgroundColor: "#525659",
                display: "flex",
                flex: 1,
            }}
        >
            {!loading ? (
                <iframe
                    src={pdfString}
                    style={{
                        display: "flex",
                        flex: "1",
                        border: "none",
                        width: "100%"
                    }}
                />
            ) : (
                <div
                    style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        marginTop: "-5px",
                        marginLeft: "-5px",
                    }}
                >
                    <Spinner />
                </div>
            )}
        </div>
    )
}