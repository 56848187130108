import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage";
import React from "react";
import { permissions } from "../../Constants/permissions";

export default function EmergencyServiceAddressErrorsIndexPage()
{
    const columns: IColumn[] = [
        {
            title: "Telephone Number",
            link: { root: "/telephone-numbers" },
            bold: true,
            name: "telephoneNo",
        },
        {
            title: "Error",
            name: "errorMessage"
        }
    ]

    return (
        <GenericIndexPage
            title={"Emergency Service Address Errors"}
            urlString={"/numbering/allocated/ems/errors"}
            columns={columns}
            restType={"POST"}
            uniqueIdentifier={"allocatedId"}
        />
    )
}