import React from "react"
import { FormControl } from "react-bootstrap";
import MaskedFormControl from 'react-bootstrap-maskedinput'

export default function TextInput({
    placeholder,
    value,
    disabled,
    onChange,
    name,
    small,
    inputType,
    maxLength,
    minLength,
    setError,
    regex,
    regexError,
    required,
    onBlur,
    onFocus,
    mask,
    useValue
}: ITextInput)
{
    function handleOnChange(e)
    {
        const { value } = e.target

        if (!value && !required)
        {
            setError && setError({ target: { ...e.target, name, value: "" } })
        } else if (!value && required)
        {
            setError && setError({ target: { ...e.target, name, value: "This is a required field" } })
        }
        else
        {
            if (maxLength && value.length > maxLength)
            {
                setError && setError({ target: { ...e.target, name, value: `Length must not exceed ${maxLength} characters` } })
            }
            else if (minLength && value.length < minLength)
            {
                setError && setError({ target: { ...e.target, name, value: `Length must exceed ${minLength} characters` } })
            }
            else if (regex)
            {
                if (regex.test(value))
                {
                    setError && setError({ target: { ...e.target, name, value: "" } })
                }
                else
                {
                    setError && setError({ target: { ...e.target, name, value: regexError } })
                }
            }
            else
            {
                setError && setError({ target: { ...e.target, name, value: "" } })
            }
        }

        onChange(e)
    }

    return (
        <div className="flex-row flex-1">
            {!mask && useValue ?
                (
                    <FormControl
                        size={small ? "sm" : null}
                        style={{ width: small && "80%" }}
                        disabled={disabled}
                        type={inputType ? inputType : "text"}
                        placeholder={placeholder}
                        name={name}
                        onChange={handleOnChange}
                        defaultValue={value !== undefined ? String(value) : ""}
                        value={value !== undefined ? String(value) : ""}
                        onBlur={onBlur}
                        onFocus={onFocus}
                    />
                ) : !mask ? (
                    <FormControl
                        size={small ? "sm" : null}
                        style={{ width: small && "80%" }}
                        disabled={disabled}
                        type={inputType ? inputType : "text"}
                        placeholder={placeholder}
                        name={name}
                        onChange={handleOnChange}
                        defaultValue={value !== undefined ? String(value) : ""}
                        onBlur={onBlur}
                        onFocus={onFocus}
                    />
                ) : (
                    <MaskedFormControl
                        size={small ? "sm" : null}
                        style={{ width: small && "80%" }}
                        disabled={disabled}
                        type={inputType ? inputType : "text"}
                        placeholder={placeholder}
                        name={name}
                        onChange={handleOnChange}
                        value={value !== undefined ? String(value) : ""}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        mask={mask}
                    />
                )}

        </div>
    )
}
