import React from "react";
import moment from "moment";
import TextInput from "./TextInput";

export default function DateTimeInput({
    name,
    value,
    onChange,
}: IDateTimeInput)
{

    function handleInputChange(val)
    {
        // I could not get this just working by using val, don't know why
        const formattedDate = moment(val, "YYYY-MM-DDTHH:mm").format("YYYY-MM-DDTHH:mm")
        if (onChange)
        {
            onChange({
                target: {
                    name,
                    type: "time",
                    value: formattedDate
                }
            })
        }
    }

    return (
        <TextInput
            name={name}
            value={value ? moment(value, "YYYY-MM-DDTHH:mm").format("YYYY-MM-DDTHH:mm") : moment().format("YYYY-MM-DDTHH:mm")}
            onChange={e => handleInputChange(e.target.value)}
            inputType={"datetime-local"}
        />
    )
}

