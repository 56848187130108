import AccountDetailsFormPage from "../Pages/AccountDetailsPage/AccountDetailsFormPage";
import AccountTransactionsIndexPage from "../Pages/AccountTransactionsPage/AccountTransactionsIndexPage";
import BlackWhiteListFormPage from "../Pages/BlackWhiteListPage/BlackWhiteListFormPage";
import BlackWhiteListIndexPage from "../Pages/BlackWhiteListPage/BlackWhiteListIndexPage";
import BundlesFormPage from "../Pages/BundlesPage/BundlesFormPage";
import CallGroupsFormPage from "../Pages/CallGroupsPage/CallGroupsFormPage";
import CallGroupsIndexPage from "../Pages/CallGroupsPage/CallGroupsIndexPage";
import CallMonitoringFormPage from "../Pages/CallMonitoringPage/CallMonitoringFormPage";
import CallMonitoringIndexPage from "../Pages/CallMonitoringPage/CallMonitoringIndexPage";
import CallQueuesFormPage from "../Pages/CallQueuesPage/CallQueuesFormPage";
import CallQueuesIndexPage from "../Pages/CallQueuesPage/CallQueuesIndexPage";
import CallRecordingFormPage from "../Pages/CallRecordingPage/CallRecordingFormPage";
import CallRecordingIndexPage from "../Pages/CallRecordingPage/CallRecordingIndexPage";
import CallRecordsIndexPage from "../Pages/CallRecordsPage/CallRecordsIndexPage";
import ConferencingFormPage from "../Pages/ConferencingPage/ConferencingFormPage";
import ConferencingIndexPage from "../Pages/ConferencingPage/ConferencingIndexPage";
import CustomPromptsFormPage from "../Pages/CustomPromptsPage/CustomPromptsFormPage";
import CustomPromptsIndexPage from "../Pages/CustomPromptsPage/CustomPromptsIndexPage";
import DashboardPage from "../Pages/DashboardPage/DashboardPage";
// import DemoPage from "../Pages/DemoPage/DemoPage";
import DialThroughFormPage from "../Pages/DialThroughPage/DialThroughFormPage";
import DialThroughIndexPage from "../Pages/DialThroughPage/DialThroughIndexPage";
import InvoiceDisplayPage from "../Pages/InvoicePage/InvoiceDisplayPage";
import InvoicePage from "../Pages/InvoicePage/InvoiceIndexPage";
import IVRsFormPage from "../Pages/IVRPage/IVRsFormPage";
import IVRsIndexPage from "../Pages/IVRPage/IVRsIndexPage";
import MobileFormPage from "../Pages/MobilePage/MobileFormPage";
import MobileIndexPage from "../Pages/MobilePage/MobileIndexPage";
import MusicOnHoldFormPage from "../Pages/MusicOnHoldPage/MusicOnHoldFormPage";
import MusicOnHoldIndexPage from "../Pages/MusicOnHoldPage/MusicOnHoldIndexPage";
import NotificationsIndexPage from "../Pages/NotificationsPage/NotificationsIndexPage";
import NTSDestinationsFormPage from "../Pages/NTSDestinatinsPage/NTSDestinationsFormPage";
import NTSDestinationsIndexPage from "../Pages/NTSDestinatinsPage/NTSDestinationsIndexPage";
import PageNotFoundPage from "../Pages/PageNotFoundPage/PageNotFoundPage";
import PhoneDirectoryFormPage from "../Pages/PhoneDirectoryPage/PhoneDirectoryFormPage";
import PhoneDirectoryGroupIndexPage from "../Pages/PhoneDirectoryPage/PhoneDirectoryGroupsIndexPage";
import PhoneDirectoryIndexPage from "../Pages/PhoneDirectoryPage/PhoneDirectoryIndexPage";
import PhoneDirectoryEntryIndexPage from "../Pages/PhoneDirectoryPage/PhoneDirectoryEntryIndexPage";
import PickUpGroupsFormPage from "../Pages/PickUpGroupsPage/PickUpGroupsFormPage";
import PickUpGroupsIndexPage from "../Pages/PickUpGroupsPage/PickUpGroupsIndexPage";
import PortingAOTFormPage from "../Pages/PortingAOTPage/PortingAOTFormPage";
import ProvisioningFormPage from "../Pages/ProvisioningPage/ProvisioningFormPage";
import ProvisioningIndexPage from "../Pages/ProvisioningPage/ProvisioningIndexPage";
import SeatsFormPage from "../Pages/SeatsPage/SeatsFormPage";
import SeatsIndexPage from "../Pages/SeatsPage/SeatsIndexPage";
import SIPTrunksFormPage from "../Pages/SIPTrunksPage/SIPTrunksFormPage";
import SIPTrunksIndexPage from "../Pages/SIPTrunksPage/SIPTrunksIndexPage";
import SMSFormPage from "../Pages/SMSPage/SMSFormPage";
import SMSIndexPage from "../Pages/SMSPage/SMSIndexPage";
import StoreRedirectPage from "../Pages/StorePage/StoreRedirectPage";
import TariffFormPage from "../Pages/TariffPage/TariffFormPage";
import TariffInvoicePage from "../Pages/TariffPage/TariffInvoicePage";
import TelephoneNumbersFormPage from "../Pages/TelephoneNumbersPage/TelephoneNumbersFormPage";
import TelephoneNumbersIndexPage from "../Pages/TelephoneNumbersPage/TelephoneNumbersIndexPage";
import TimeProfilesFormPage from "../Pages/TimeProfilesPage/TimeProfilesFormPage";
import TimeProfilesIndexPage from "../Pages/TimeProfilesPage/TimeProfilesIndexPage";
import VoicemailFormPage from "../Pages/VoicemailPage/VoicemailFormPage";
import VoicemailIndexPage from "../Pages/VoicemailPage/VoicemailIndexPage";
import VoicemailSettingsPage from "../Pages/VoicemailPage/VoicemailSettingsPage";
import { permissions } from "./permissions";
import RegistrationFormPage from "../Pages/RegistrationPage/RegistrationFormPage";
import RegistrationSuccessPage from "../Pages/RegistrationPage/RegistrationSuccessPage";
import ZohoRedirectPage from "../Pages/ZohoRedirectPage/ZohoRedirectPage";
import IntegrationsFormPage from "../Pages/IntegrationsPage/IntegrationsFormPage";
import IntegrationsPreferencesPage from "../Pages/IntegrationsPreferencesPage/IntegrationsPreferencesPage";
import ZohoCallbackPage from "../Pages/ZohoRedirectPage/ZohoCallbackPage";
import { sipconvergenceReformatter } from "../Common/urlHelpers";
import EmergencyServiceAddressErrorsIndexPage from "../Pages/EmergencyServiceAddressPage/EmergencyServiceAddressErrorsIndexPage";
import InternationalRestrictionsPage from "../Pages/InternationalRestrictionsPage/InternationalRestrictionsFormPage";
import TimeProfileCalendarFormPage from "../Pages/TimeProfilesPage/TimeProfileCalendarFormPage";
import SoftphoneCLIsIndexPage from "../Pages/SoftphoneCLIsPage/SoftphoneCLIsIndexPage";
import SoftphoneCLIsFormPage from "../Pages/SoftphoneCLIsPage/SoftphoneCLIsFormPage";
import HubspotCallbackPage from "../Pages/HubspotCallbackPage/HubspotCallBackPage";
import SupportFormPage from "../Pages/SupportPage/SupportFormPage";

export const routes: IRoute[] = [
    { path: "/access-lists", Component: BlackWhiteListIndexPage, permissions: [permissions.acl, permissions.admin, permissions.VHAdmin] },
    { path: "/access-lists/:id", Component: BlackWhiteListFormPage, permissions: [permissions.acl, permissions.admin, permissions.VHAdmin] },
    { path: "/account-details", Component: AccountDetailsFormPage, permissions: [permissions.account, permissions.admin, permissions.VHAdmin] },
    { path: "/account-transaction", Component: AccountTransactionsIndexPage, permissions: [permissions.account, permissions.admin, permissions.VHAdmin], isPrepay: true },
    { path: "/AOT", Component: PortingAOTFormPage },
    { path: "/bundles", Component: BundlesFormPage, permissions: [permissions.bundles, permissions.admin, permissions.VHAdmin] },
    { path: "/call-groups", Component: CallGroupsIndexPage, permissions: [permissions.callGroups, permissions.admin, permissions.VHAdmin] },
    { path: "/call-groups/:id", Component: CallGroupsFormPage, permissions: [permissions.callGroups, permissions.admin, permissions.VHAdmin] },
    { path: "/call-monitoring", Component: CallMonitoringIndexPage, permissions: [permissions.spy, permissions.admin, permissions.VHAdmin] },
    { path: "/call-monitoring/:id", Component: CallMonitoringFormPage, permissions: [permissions.spy, permissions.admin, permissions.VHAdmin] },
    { path: "/call-queues", Component: CallQueuesIndexPage, permissions: [permissions.queues, permissions.admin, permissions.VHAdmin] },
    { path: "/call-queues/:id", Component: CallQueuesFormPage, permissions: [permissions.queues, permissions.admin, permissions.VHAdmin] },
    { path: "/call-recording", Component: CallRecordingIndexPage, permissions: [permissions.callRecordings, permissions.admin, permissions.VHAdmin] },
    { path: "/call-recording/:id", Component: CallRecordingFormPage, permissions: [permissions.callRecordings, permissions.admin, permissions.VHAdmin] },
    { path: "/call-records", Component: CallRecordsIndexPage, permissions: [permissions.cdr, permissions.admin, permissions.VHAdmin] },
    // { path: "/callback", Component: WebsiteCallbackIndexPage, permissions: [permissions.retail] },
    // { path: "/callback/:id", Component: WebsiteCallbackFormPage, permissions: [permissions.retail] },
    { path: "/conferencing", Component: ConferencingIndexPage, permissions: [permissions.conference, permissions.admin, permissions.VHAdmin] },
    { path: "/conferencing/:id", Component: ConferencingFormPage, permissions: [permissions.conference, permissions.admin, permissions.VHAdmin] },
    { path: "/custom-prompts", Component: CustomPromptsIndexPage, permissions: [permissions.prompts, permissions.admin, permissions.VHAdmin] },
    { path: "/custom-prompts/:id", Component: CustomPromptsFormPage, permissions: [permissions.prompts, permissions.admin, permissions.VHAdmin] },
    // { path: "/demo", Component: DemoPage },
    { path: "/dial-through", Component: DialThroughIndexPage, permissions: [permissions.dialThrough, permissions.admin, permissions.wholesaleAdmin, permissions.wholesaleCustomers, permissions.VHAdmin, permissions.VHCustomers] },
    { path: "/dial-through/:id", Component: DialThroughFormPage, permissions: [permissions.dialThrough, permissions.admin, permissions.wholesaleAdmin, permissions.wholesaleCustomers, permissions.VHAdmin, permissions.VHCustomers] },
    { path: "/ems-errors", Component: EmergencyServiceAddressErrorsIndexPage, permissions: [permissions.numbers, permissions.admin, permissions.VHAdmin] },
    { path: "/hubspotcallback", Component: HubspotCallbackPage, permissions: [permissions.VHAdmin] },
    { path: "/integrations", Component: IntegrationsFormPage, permissions: [permissions.VHAdmin] },
    { path: "/integrations/redcactus/preferences", Component: IntegrationsPreferencesPage, permissions: [permissions.VHAdmin] },
    { path: "/international-addresses", Component: InternationalRestrictionsPage, permissions: [permissions.numbers, permissions.admin, permissions.VHAdmin] },
    { path: "/invoice", Component: InvoicePage, permissions: [permissions.account, permissions.admin, permissions.VHAdmin], isRetail: true },
    { path: "/invoice/:id", Component: InvoiceDisplayPage, permissions: [permissions.account, permissions.admin, permissions.VHAdmin], isRetail: true },
    { path: "/ivrs", Component: IVRsIndexPage, permissions: [permissions.IVR, permissions.admin, permissions.VHAdmin] },
    { path: "/ivrs/:id", Component: IVRsFormPage, permissions: [permissions.IVR, permissions.admin, permissions.VHAdmin] },
    { path: "/mobile", Component: MobileIndexPage, permissions: [permissions.mobile, permissions.admin, permissions.VHAdmin], isRetail: true },
    { path: "/mobile/:id", Component: MobileFormPage, permissions: [permissions.mobile, permissions.admin, permissions.VHAdmin], isRetail: true },
    { path: "/music-on-hold", Component: MusicOnHoldIndexPage, permissions: [permissions.musicOnHold, permissions.admin, permissions.VHAdmin] },
    { path: "/music-on-hold/:id", Component: MusicOnHoldFormPage, permissions: [permissions.musicOnHold, permissions.admin, permissions.VHAdmin] },
    { path: "/notifications", Component: NotificationsIndexPage, permissions: [permissions.notification, permissions.admin, permissions.VHAdmin] },
    { path: "/nts", Component: NTSDestinationsIndexPage, permissions: [permissions.externalExt, permissions.admin, permissions.VHAdmin] },
    { path: "/nts/:id", Component: NTSDestinationsFormPage, permissions: [permissions.externalExt, permissions.admin, permissions.VHAdmin] },
    { path: "/outbound-clis", Component: SoftphoneCLIsIndexPage, permissions: [permissions.outboundCLILists, permissions.admin, permissions.VHAdmin] },
    { path: "/outbound-clis/:id", Component: SoftphoneCLIsFormPage, permissions: [permissions.outboundCLILists, permissions.admin, permissions.VHAdmin] },
    { path: "/phone-directory", Component: PhoneDirectoryIndexPage, permissions: [permissions.ldap, permissions.admin, permissions.VHAdmin] },
    { path: "/phone-directory/groups", Component: PhoneDirectoryGroupIndexPage, permissions: [permissions.ldap, permissions.admin, permissions.VHAdmin] },
    { path: "/phone-directory/groups/:directory", Component: PhoneDirectoryEntryIndexPage, permissions: [permissions.ldap, permissions.admin, permissions.VHAdmin] },
    { path: "/phone-directory/groups/:directory/:entry", Component: PhoneDirectoryFormPage, permissions: [permissions.ldap, permissions.admin, permissions.VHAdmin] },
    { path: "/phone-directory/:directory", Component: PhoneDirectoryEntryIndexPage, permissions: [permissions.ldap, permissions.admin, permissions.VHAdmin] },
    { path: "/phone-directory/:directory/:entry", Component: PhoneDirectoryFormPage, permissions: [permissions.ldap, permissions.admin, permissions.VHAdmin] },
    { path: "/pickup-groups", Component: PickUpGroupsIndexPage, permissions: [permissions.pickupGroups, permissions.admin, permissions.VHAdmin] },
    { path: "/pickup-groups/:id", Component: PickUpGroupsFormPage, permissions: [permissions.pickupGroups, permissions.admin, permissions.VHAdmin] },
    { path: "/provisioning", Component: ProvisioningIndexPage, permissions: [permissions.provisioning, permissions.admin, permissions.VHAdmin] },
    { path: "/provisioning/:id", Component: ProvisioningFormPage, permissions: [permissions.provisioning, permissions.admin, permissions.VHAdmin] },
    { path: "/registration", Component: RegistrationFormPage },
    { path: "/registration/success", Component: RegistrationSuccessPage },
    { path: "/seats", Component: SeatsIndexPage, permissions: [permissions.seats, permissions.admin, permissions.VHAdmin] },
    { path: "/seats/:id", Component: SeatsFormPage, permissions: [permissions.seats, permissions.admin, permissions.VHAdmin] },
    { path: "/sip", Component: SIPTrunksIndexPage, permissions: [permissions.SIPTrunks, permissions.admin, permissions.VHAdmin] },
    { path: "/sip/:id", Component: SIPTrunksFormPage, permissions: [permissions.SIPTrunks, permissions.admin, permissions.VHAdmin] },
    { path: "/sms", Component: SMSIndexPage, permissions: [permissions.mobile, permissions.admin, permissions.VHAdmin], isRetail: true },
    { path: "/sms/:id", Component: SMSFormPage, permissions: [permissions.mobile, permissions.admin, permissions.VHAdmin], isRetail: true },
    { path: "/store", Component: StoreRedirectPage, permissions: [permissions.store, permissions.admin, permissions.VHAdmin], isRetail: true },
    { path: "/support", Component: SupportFormPage, permissions: [permissions.VHAdmin, permissions.retail], isRetail: true },
    { path: "/tariff", Component: TariffFormPage, permissions: [permissions.account, permissions.admin, permissions.VHAdmin], isRetail: true },
    { path: "/tariff/:name/:id", Component: TariffInvoicePage, permissions: [permissions.account, permissions.admin, permissions.VHAdmin], isRetail: true },
    { path: "/telephone-numbers", Component: TelephoneNumbersIndexPage, permissions: [permissions.numbers, permissions.admin, permissions.VHAdmin] },
    { path: "/telephone-numbers/:id", Component: TelephoneNumbersFormPage, permissions: [permissions.numbers, permissions.admin, permissions.VHAdmin] },
    { path: "/time-profiles", Component: TimeProfilesIndexPage, permissions: [permissions.timeprofile, permissions.admin, permissions.VHAdmin] },
    { path: "/time-profiles/:id", Component: TimeProfilesFormPage, permissions: [permissions.timeprofile, permissions.admin, permissions.VHAdmin] },
    { path: "/time-profiles/:timeProfileId/calendars/:id", Component: TimeProfileCalendarFormPage, permissions: [permissions.timeprofile, permissions.admin, permissions.VHAdmin] },
    { path: sipconvergenceReformatter("https://login.voicehost.co.uk/ManageUser?filter=active"), Component: null, permissions: [permissions.account, permissions.admin, permissions.VHAdmin] },
    { path: "/voicemail", Component: VoicemailIndexPage, permissions: [permissions.voicemail, permissions.admin, permissions.VHAdmin] },
    { path: "/voicemail/settings/:mailbox", Component: VoicemailSettingsPage, permissions: [permissions.voicemail, permissions.admin, permissions.VHAdmin] },
    { path: "/voicemail/:mailbox/:id", Component: VoicemailFormPage, permissions: [permissions.voicemail, permissions.admin, permissions.VHAdmin] },
    { path: "/zohocallback", Component: ZohoCallbackPage, permissions: [permissions.VHAdmin] },
    { path: "/zoho", Component: ZohoRedirectPage, permissions: [permissions.VHAdmin] },
    { path: "/", Component: DashboardPage },

    // Default
    { Component: PageNotFoundPage },
]
