

import React, { useState } from "react";
import Spinner from "../Spinner/Spinner";
import { PayPalButton } from "react-paypal-button-v2";

const CLIENT = {
    sandbox:
        process.env.REACT_APP_PAYPAL_CLIENTID_SANDBOX,
    production:
        process.env.REACT_APP_PAYPAL_CLIENTID_PRODUCTION
}

const CLIENT_ID =
    process.env.NODE_ENV === "production" ? CLIENT.production : CLIENT.sandbox

// let PayPalButton = null

interface IProps
{
    // Don't set these, it needs them to run for some reason but just ignore them
    isScriptLoaded?: boolean
    isScriptLoadSucceed?: boolean
}

export default function PaypalButtonBoi({
    // Don't set these, it needs them to run for some reason but just ignore them
    isScriptLoaded,
    isScriptLoadSucceed,

    // Set these though, these are needed :)
    productDescription,
    productPriceGBP,
    onApproveTransaction,
    successText
}: IProps
    & IPaypalButton)
{
    const [paid, setPaid] = useState(false)
    const [errored, setErrored] = useState(false)
    const [loading, setLoading] = useState(false)

    function createOrder(data, actions) 
    {
        return actions.order.create({
            purchase_units: [
                {
                    description: productDescription,
                    amount: {
                        value: productPriceGBP,
                        currency: "GBP"
                    }
                }
            ]
        })
    }

    function onApprove(data, actions) 
    {
        actions.order.capture().then(details =>
        {
            setLoading(true)
            onApproveTransaction(details).then(response =>
            {
                if (response && response.success)
                {
                    setPaid(true)
                }
                else
                {
                    setErrored(true)
                }
                setLoading(false)
            })

        });
    }

    return (
        <div style={{
            width: "100%",
            overflow: "auto",
            maxHeight: 400
        }}
        >
            {loading ? <Spinner /> :
                !paid && !errored && (
                    <PayPalButton
                        createOrder={(data, actions) => createOrder(data, actions)}
                        onApprove={(data, actions) => onApprove(data, actions)}
                        options={{
                            clientId: CLIENT_ID,
                            currency: "GBP",
                            // debug: true
                        }}
                        style={{
                            shape: "pill",
                            size: "responsive"
                        }}
                        onError={() => setErrored(true)}
                    />
                )}
            {paid && (
                <p>
                    {successText}
                </p>
            )}
            {errored && (
                <p>
                    Something went wrong, please contact support
                </p>
            )}
        </div>
    )
}