import React from "react"
import { useHistory } from "react-router-dom"
import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage"
import useAPIRequests from "../../Hooks/useAPIRequests"
import { useRequest } from "../../Hooks/useRequest"
import { permissions } from "../../Constants/permissions"

export default function CallQueuesIndexPage()
{
    const history = useHistory()
    const { postRequest, getRequest } = useRequest()

    const {
        getDestinationPromise,
        getMusicOnHoldPromise,
        getPromptPromise,
    } = useAPIRequests()

    async function getLatestCallQueue()
    {
        const response = await getRequest("/queue/next")

        if (response)
        {
            return Number(response)
        }
    }

    const columns: IColumn[] = [
        {
            name: "displayName",
            title: "Queue",
            sortByName: "name",
            bold: true,
            onClick: (id) =>
            {
                history.push(`/call-queues/${id}`)
            },
            sortBy: true,
        },
        { name: "customName", title: "Name", sortBy: true },
    ]

    function getModalSettings(edit): ISettingsStage[] 
    {
        return [
            {
                title: "General Settings",
                fields: [
                    !edit ? {
                        type: "text",
                        name: "customName",
                        maxLength: 45,
                        label: "Name"
                    } : {},
                    {
                        type: "select",
                        name: "queueTime",
                        label: "Maximum Queue Time",
                        required: true,
                        options: [
                            { value: 0, label: "Unlimited" },
                            { value: 10, label: "10 seconds" },
                            { value: 15, label: "15 seconds" },
                            { value: 20, label: "20 seconds" },
                            { value: 25, label: "25 seconds" },
                            { value: 30, label: "30 seconds" },
                            { value: 60, label: "1 min" },
                            { value: 120, label: "2 mins" },
                            { value: 180, label: "3 mins" },
                            { value: 240, label: "4 mins" },
                            { value: 300, label: "5 mins" },
                            { value: 600, label: "10 mins" },
                            { value: 1200, label: "20 mins" },
                            { value: 1800, label: "30 mins" },
                            { value: 3600, label: "1 hour" },
                            { value: 7200, label: "2 hours" },
                        ],
                        defaultValue: 0
                    },
                    {
                        type: "select",
                        label: "Maximum Queue Length",
                        name: "maxLength",
                        required: true,
                        options: [
                            { value: 1, label: "1" },
                            { value: 2, label: "2" },
                            { value: 3, label: "3" },
                            { value: 4, label: "4" },
                            { value: 5, label: "5" },
                            { value: 6, label: "6" },
                            { value: 7, label: "7" },
                            { value: 8, label: "8" },
                            { value: 9, label: "9" },
                            { value: 10, label: "10" },
                            { value: 15, label: "15" },
                            { value: 20, label: "20" },
                            { value: 25, label: "25" },
                            { value: 30, label: "30" },
                            { value: 35, label: "35" },
                            { value: 40, label: "40" },
                            { value: 45, label: "45" },
                            { value: 50, label: "50" },
                        ],
                        defaultValue: 1
                    },
                    {
                        type: "switch",
                        label: "Queue Exit Time",
                        name: "exitSwitch",
                    },
                    {
                        type: "time",
                        label: "Set Queue Exit Time",
                        name: "exitTime",
                        conditionallyShow: { checks: [{ field: "exitSwitch", value: true }] },
                    },
                    {
                        type: "select",
                        label: "Time-out Destination",
                        name: "falloverLocation",
                        options: [{ label: "None", value: "none" }],
                        loadOptions: () =>
                            getDestinationPromise({
                                highPriorityQueue: true,
                                dialThrough: false,
                                sipTrunkMap: false,
                            }),
                        defaultValue: "none"
                    },
                    {
                        type: "switch",
                        label: "Enter Agent-less Queue",
                        name: "joinEmpty",
                    },
                    {
                        type: "select",
                        label: "Agent-less Queue Divert",
                        name: "falloverLocationEmpty",
                        options: [{ label: "None", value: "none" }],
                        loadOptions: () =>
                            getDestinationPromise({
                                highPriorityQueue: true,
                                dialThrough: false,
                                sipTrunkMap: false,
                            }),
                        conditionallyHide: { checks: [{ field: "joinEmpty", value: true }] },
                        defaultValue: "none"
                    },
                    {
                        type: "switch",
                        label: "Answered Elsewhere",
                        name: "answerElsewhere",
                    },
                    {
                        type: "select",
                        label: "Welcome Prompt",
                        name: "welcomeId",
                        loadOptions: () => getPromptPromise(),
                        options: [
                            { value: -1, label: "None" },
                            { value: 0, label: "Default" },
                        ],
                        defaultValue: -1
                    },
                    {
                        type: "select",
                        label: "Music On Hold",
                        name: "musicOnHold",
                        loadOptions: () => getMusicOnHoldPromise(),
                        options: [
                            { value: "none", label: "None" },
                            { value: "default", label: "Default" }
                        ],
                        defaultValue: "none"
                    },
                    {
                        type: "select",
                        label: "Queue Jump Destination",
                        name: "queueJump",
                        options: [{ label: "None", value: "none" }],
                        loadOptions: () =>
                            getDestinationPromise({
                                dialThrough: false,
                                highPriorityQueue: true,
                                sipTrunkMap: false,
                            }),
                        defaultValue: "none"
                    },
                ],
            },
            {
                title: "Member Settings",
                fields: [
                    {
                        type: "select",
                        label: "Ring Strategy",
                        name: "strategy",
                        required: true,
                        options: [
                            { value: "ringall", label: "Ring All" },
                            { value: "leastrecent", label: "Least Recent" },
                            { value: "fewestcalls", label: "Fewest Calls" },
                            { value: "random", label: "Random" },
                            { value: "roundrobin", label: "Round Robin" },
                            { value: "rrmemory", label: "Round Robin (Memory)" },
                            { value: "linear", label: "Round Robin (Linear)" },
                        ],
                        defaultValue: "ringall"
                    },
                    {
                        type: "select",
                        label: "Ring Time",
                        name: "timeout",
                        required: true,
                        options: [
                            { value: 10, label: "10 sec" },
                            { value: 15, label: "15 sec" },
                            { value: 20, label: "20 sec" },
                            { value: 25, label: "25 sec" },
                            { value: 30, label: "30 sec" },
                            { value: 35, label: "35 sec" },
                            { value: 40, label: "40 sec" },
                        ],
                        defaultValue: 10
                    },
                    {
                        type: "select",
                        label: "Retry Agents Every",
                        name: "retry",
                        options: [
                            { value: 5, label: "5 sec" },
                            { value: 10, label: "10 sec" },
                            { value: 20, label: "20 sec" },
                            { value: 30, label: "30 sec" },
                            { value: 60, label: "1 min" },
                            { value: 120, label: "2 mins" },
                            { value: 180, label: "3 mins" },
                            { value: 240, label: "4 mins" },
                            { value: 300, label: "5 mins" },
                        ],
                        defaultValue: 5
                    },
                    {
                        type: "select",
                        label: "Wrap-up Time",
                        name: "wrapUpTime",
                        options: [
                            { value: 0, label: "None" },
                            { value: 5, label: "5 sec" },
                            { value: 10, label: "10 sec" },
                            { value: 15, label: "15 sec" },
                            { value: 20, label: "20 sec" },
                            { value: 25, label: "25 sec" },
                            { value: 30, label: "30 sec" },
                            { value: 35, label: "35 sec" },
                            { value: 40, label: "40 sec" },
                            { value: 45, label: "45 sec" },
                            { value: 50, label: "50 sec" },
                            { value: 55, label: "55 sec" },
                            { value: 60, label: "1 min" },
                            { value: 120, label: "2 mins" },
                            { value: 180, label: "3 mins" },
                            { value: 240, label: "4 mins" },
                            { value: 300, label: "5 mins" },
                        ],
                        defaultValue: 0
                    },
                    {
                        label: "Report Hold Time",
                        type: "switch",
                        name: "reportHoldTime",
                    },
                    {
                        type: "select",
                        label: "Queue Identification",
                        name: "announceId",
                        loadOptions: () => getPromptPromise(),
                        options: [
                            { value: -1, label: "None" },
                            { value: -2, label: "Support" },
                            { value: -3, label: "Sales" },
                            { value: -4, label: "Accounting" },
                            { value: -5, label: "Purchasing" }
                        ],
                        defaultValue: -1
                    },
                ],
            },
            {
                title: "Sound Settings",
                fields: [
                    {
                        label: "Average Hold Time",
                        type: "switch",
                        name: "announceHoldTime",
                    },
                    {
                        label: "Position/Time Frequency",
                        type: "select",
                        name: "announceFrequency",
                        options: [
                            { value: 0, label: "Disabled" },
                            { value: 30, label: "30 sec" },
                            { value: 60, label: "1 min" },
                            { value: 120, label: "2 mins" },
                            { value: 180, label: "3 mins" },
                            { value: 240, label: "4 mins" },
                            { value: 300, label: "5 mins" },
                        ],
                        defaultValue: 0
                    },
                    {
                        label: "Periodic Announce",
                        type: "select",
                        name: "periodicAnnounceId",
                        loadOptions: () => getPromptPromise(),
                        options: [{ value: -1, label: "None" }],
                        defaultValue: -1
                    },
                    {
                        label: "Periodic Frequency",
                        type: "select",
                        name: "periodicAnnounceFrequency",
                        options: [
                            { value: 0, label: "Disabled" },
                            { value: 30, label: "30 sec" },
                            { value: 60, label: "1 min" },
                            { value: 90, label: "1:30 mins" },
                            { value: 120, label: "2 mins" },
                        ],
                        defaultValue: 0
                    },
                    {
                        label: "Overflow Message",
                        type: "select",
                        name: "queueFullMessageId",
                        loadOptions: () => getPromptPromise(),
                        options: [{ value: -1, label: "None" }],
                        defaultValue: -1
                    },
                ],
            },
        ]
    }

    function checkQueueTaken(
        fieldFrom,
        fieldTo
    ): Promise<{ success: boolean; message: string }>
    {
        return new Promise(async (resolve) =>
        {
            let response = await postRequest({}, `/queue/-1/-1`)

            if (response)
            {
                if (response.records)
                {
                    const filteredRecords = response.records.filter(
                        (record) =>
                            Number(record.displayName) === Number(fieldFrom) ||
                            (Number(fieldFrom) <= Number(record.displayName) &&
                                Number(record.displayName) <= Number(fieldTo))
                    )

                    if (filteredRecords && filteredRecords.length > 0)
                    {
                        if (!fieldTo)
                        {
                            resolve({
                                success: false,
                                message:
                                    "Queue number is already in use. Please select a different number",
                            })
                        } else
                        {
                            resolve({
                                success: false,
                                message:
                                    "1 or more of the queues already has the number in this selected range. Please check the range and try again",
                            })
                        }
                    } else
                    {
                        resolve({ success: true, message: "" })
                    }
                } else
                {
                    resolve({
                        success: false,
                        message: "Failed to retrieve data, please try again",
                    })
                }
            } else
            {
                resolve({
                    success: false,
                    message: "Failed to retrieve data, please try again",
                })
            }
        })
    }

    function addQueue(data)
    {
        const postData = {
            customName: data.customName,
            queueTime: data.queueTime,
            maxLength: data.maxLength,
            falloverLocation:
                data.falloverLocation === "none" ? null : data.falloverLocation,
            falloverLocationEmpty: 
                data.falloverLocationEmpty == "none" ? null : data.falloverLocationEmpty,
            joinEmpty: data.joinEmpty,
            welcomeId: data.welcomeId,
            musicOnHold: data.musicOnHold === "none" ? null : data.musicOnHold,
            queueJump: data.queueJump === "none" ? null : data.queueJump,
            strategy: data.strategy,
            timeout: data.timeout,
            retry: data.retry,
            wrapUpTime: data.wrapUpTime,
            reportHoldTime: data.reportHoldTime,
            announceId: data.announceId,
            announceHoldTime: data.announceHoldTime,
            announceFrequency: data.announceFrequency,
            periodicAnnounceId: data.periodicAnnounceId,
            periodicAnnounceFrequency: data.periodicAnnounceFrequency,
            queueFullMessageId: data.queueFullMessageId,
            answerElsewhere: data.answerElsewhere,
            exitSwitch: data.exitSwitch,
            exitTime: data.exitTime
        }
        return [{
            postData: postData,
            path: "/queue",
            identifier: "name",
            type: "create",
            displayName: data.fromField,
            bulkIncrement: "customName"
        }]
    }

    function editQueue(data)
    {
        const postData = {
            queueTime: data.queueTime,
            maxLength: data.maxLength,
            joinEmpty: data.joinEmpty,
            falloverLocation:
                data.falloverLocation == "none" ? null : data.falloverLocation,
            falloverLocationEmpty: 
                data.falloverLocationEmpty == "none" ? null : data.falloverLocationEmpty,
            welcomeId: data.welcomeId,
            musicOnHold: data.musicOnHold === "none" ? null : data.musicOnHold,
            queueJump: data.queueJump == "none" ? null : data.queueJump,
            strategy: data.strategy,
            timeout: data.timeout,
            retry: data.retry,
            wrapUpTime: data.wrapUpTime,
            reportHoldTime: data.reportHoldTime,
            announceId: data.announceId,
            announceHoldTime: data.announceHoldTime,
            announceFrequency: data.announceFrequency,
            periodicAnnounceId: data.periodicAnnounceId,
            periodicAnnounceFrequency: data.periodicAnnounceFrequency,
            queueFullMessageId: data.queueFullMessageId,
            answerElsewhere: data.answerElsewhere,
            exitSwitch: data.exitSwitch,
            exitTime: data.exitTime,
        }

        data.selectedRecords.forEach(record =>
        {
            record.name = record.displayName
            record.queueJump = record.queueJump ? record.queueJump : null
        });

        return [{
            postData: postData,
            path: "/queue",
            type: "edit",
            displayName: "name",
        }]
    }

    return (
        <GenericIndexPage
            title={"Call Queues"}
            urlString={"/queue"}
            restType={"POST"}
            body={{ sortBy: "queue", direction: "ASC" }}
            columns={columns}
            searchable={true}
            permissions={[permissions.queues, permissions.admin, permissions.VHAdmin]}
            addModal={{
                displayName: "Call Queue",
                fieldFrom: {
                    field: "Queue",
                    label: "Queue",
                    type: "number",
                    min: 200,
                    max: 998,
                    loadDefaultValue: getLatestCallQueue,
                },
                settingsStages: getModalSettings(false),
                availabilityCheck: (fieldFrom, fieldTo) =>
                {
                    return new Promise(async (resolve) =>
                    {
                        if (
                            Number(fieldFrom) < 200 ||
                            Number(fieldFrom) > 998 ||
                            (fieldTo &&
                                (Number(fieldTo) < 200 || fieldTo > 998))
                        )
                        {
                            resolve({
                                success: false,
                                error: "Please input a valid number",
                            })
                        }

                        if (fieldTo && fieldTo - fieldFrom > 10)
                        {
                            resolve({
                                success: false,
                                error:
                                    "You cannot add more than 10 call queues at one time, please contact your account manager to add more",
                            })
                        }

                        if (fieldTo && fieldTo <= fieldFrom)
                        {
                            resolve({
                                success: false,
                                error:
                                    "Please ensure this number is less than the To field",
                            })
                        }

                        const responseMessage = await checkQueueTaken(
                            fieldFrom,
                            fieldTo
                        )

                        if (responseMessage && responseMessage.success)
                        {
                            resolve({ success: true })
                        } else
                        {
                            resolve({
                                success: false,
                                error: responseMessage.message,
                            })
                        }
                    })
                },
                submitData: (data) =>
                {
                    return addQueue(data)
                },
            }}
            editModal={{
                displayName: "Call Queue",
                uniqueIdentifier: "name",
                settingsStages: getModalSettings(true),
                loadRecords: [{
                    url: "/queue",
                }],
                submitData: (data) =>
                {
                    return editQueue(data)
                },
            }}
            deleteModal={{
                displayName: "Call Queue",
                uniqueIdentifier: "name",
                submitData: () =>
                {
                    return [{
                        path: "/queue",
                        displayName: "name",
                        type: "delete",
                    }]
                },
            }}
            helpSections={[
                {
                    title: "Call Queues",
                    text: "Allows you to stack incoming calls."
                }
            ]}
        />
    )
}
