import React, { useEffect, useRef, useState } from "react"
import QRCodeComp from "qrcode.react"

export default function QRCode({
    nullValue,
    value,
}: IQRCode)
{

    const ref = useRef()
    const [qrCodeWidth, setQrCodeWidth] = useState()

    useEffect(() =>
    {

        function handleResize()
        {
            if (ref && ref.current)
            {
                //@ts-ignore
                setQrCodeWidth(200)
            }
        }

        handleResize()
        window.addEventListener("resize", handleResize)

        return () =>
        {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    return (
        <div
            className="flex-row flex-1"
            ref={ref}
        >
            {value ? (
                <div
                    style={{
                        padding: 16,
                        paddingBottom: 10,
                        boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.2)",
                        borderRadius: 8
                    }}
                >
                    <QRCodeComp value={value} size={qrCodeWidth} style={{ maxWidth: "100%" }} />
                </div>
            ) : (nullValue && nullValue)}
        </div>
    )
}
