import React, { useContext, useEffect, useState } from "react";
import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage";
import CallRecordingSettingsModal from "./CallRecordingSettingsModal";
import { SessionContext } from "../../Contexts/SessionContext"
import { hasPermission, permissions } from "../../Constants/permissions"

export default function CallRecordingIndexPage()
{

    const [settingModalOpen, setSettingModalOpen] = useState(false)
    const { state: session } = useContext(SessionContext)
    function formatDate(date: Date)
    {
        let year = date.getFullYear()
        let month = (date.getMonth() + 1).toString()
        let day = date.getDate().toString()

        if (month.length < 2)
        {
            month = "0" + month
        }

        if (day.length < 2)
        {
            day = "0" + day
        }

        return `${day}/${month}/${year}`
    }

    const filters: IFilterDropdown = {
        filters: [
            {
                name: "dateFrom",
                type: "date",
                label: "Date From",
                getValue: () =>
                {
                    let dateFrom = new Date();
                    dateFrom.setMonth(dateFrom.getMonth() - 1);
                    return formatDate(dateFrom)
                }
            },
            {
                name: "dateTo",
                type: "date",
                label: "Date To",
                getValue: () =>
                {
                    return formatDate(new Date())
                }
            },
            {
                name: "originSearch",
                label: "Origin",
                type: "text",
            },
            {
                name: "destinationSearch",
                label: "Destination",
                type: "text",
            }
        ]
    }

    const columns: IColumn[] = [
        {
            title: "Date",
            name: "date",
            link: { root: "/call-recording" },
            bold: true,
            sortBy: true,
        },
        {
            title: "Time",
            name: "time",
        },
        {
            title: "Origin",
            name: "origin",
            sortBy: true,
        },
        {
            title: "Destination",
            name: "destination",
            sortBy: true,
        },
        {
            title: "Inbound #",
            name: "inbound",
            sortBy: true,
        },
        {
            title: "Duration",
            name: "mins",
            hide: true,
            sortBy: true,
        },
        {
            title: "Mb",
            name: "filesize",
            hide: true,
            sortBy: true,
        },
    ]

    const customActions: IButton[] = [
        {
            variant: "primary",
            iconLeft: "cog",
            color: "white",
            iconVariation: "regular",
            children: "Storage Settings",
            disabled: !hasPermission([permissions.callRecordings, permissions.admin, permissions.VHAdmin], session.session.permissions, "read"),
            onClick: () => setSettingModalOpen(true)
        }
    ]

    return (
        <React.Fragment>
            <GenericIndexPage
                title={"Call Recordings"}
                urlString={"/callrecording"}
                body={{}}
                searchable={false}
                restType={"POST"}
                columns={columns}
                customActions={customActions}
                filters={filters}
                hideTotalRecords={true}
            />
            <CallRecordingSettingsModal open={settingModalOpen} setOpen={setSettingModalOpen} />
        </React.Fragment>
    )
}

