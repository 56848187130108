import React, { useEffect, useState, useContext } from "react";
import Modal from "../Modal/Modal";
import { useRequest } from "../../Hooks/useRequest";
import { ToastContext } from "../../Contexts/ToastContext";
import { SessionContext } from "../../Contexts/SessionContext";
import { hasPermission, permissions } from "../../Constants/permissions";
import { FormButtons } from "../Form/FormButtons";
import { loginDotUrl, sipconvergenceReformatter } from "../../Common/urlHelpers";


interface IProps
{
    open?: boolean
    setOpen: (val: boolean) => void
}

export default function LogoutModal({
    open,
    setOpen,
}: IProps)
{
    const defaultTimeout = 30

    const { state } = useContext(SessionContext);
    const { getRequest, deleteRequest, getRequestWithToken } = useRequest()
    const { add } = useContext(ToastContext)
    const [counter, setCounter] = useState(defaultTimeout)
    const [timer, setTimer] = useState(null)

    function keepSessionAlive()
    {
        const timer = setTimeout(() =>
        {
            getRequestWithToken("/users/auth/session", state.token)
            keepSessionAlive()
        }, 1000 * 60 * 5)

        return () => clearTimeout(timer);
    }

    useEffect(() =>
    {
        if (state.token)
        {
            keepSessionAlive()
        }
    }, [state.token])

    useEffect(() =>
    {
        if (open)
        {
            const timer1 = setTimeout(() =>
            {
                setCounter(counter - 1)
                clearTimeout(timer)
            }, 1000)
            setTimer(timer1)
            return () => clearTimeout(timer1)
        }
        else
        {
            clearTimeout(timer)
            setCounter(defaultTimeout)
        }
    }, [open])

    useEffect(() =>
    {
        if (counter < defaultTimeout)
        {
            if (counter > 0)
            {
                const timer1 = setTimeout(() =>
                {
                    setCounter(counter - 1)
                    clearTimeout(timer)
                }, 1000)
                setTimer(timer1)
                return () => clearTimeout(timer1)
            }
            if (counter === 0)
            {
                logout()
            }
        }
    }, [counter])

    const customButtons: IButton[] = [
        {
            onClick: () => logout(),
            buttonText: "Log Out",
            variant: "outline-danger"
        },
        {
            onClick: () =>
            {
                getRequest("/users/auth/session")
                setOpen(false)
            },
            buttonText: "Stay Logged In",
        }
    ]

    async function logout()
    {
        if (
            hasPermission(
                [permissions.retail],
                state.session.permissions,
                "read")
        )
        {
            const logoutResponse = await getRequest(
                `/users/auth/logout`,
            )
            if ((logoutResponse
                && logoutResponse.status
                && logoutResponse.status === "success")
                || (logoutResponse
                    && logoutResponse.status === "error"
                    && logoutResponse.code
                    && logoutResponse.code === "KRUGER"))
            {
                window.location.href = loginDotUrl(true)
            }
            else
            {
                add({
                    text: "Unable to logout please try again",
                    type: "error",
                })
            }
        }
        else
        {
            const logoutResponse = await deleteRequest(
                null,
                `/users/auth/disguise`
            )
            if ((logoutResponse &&
                logoutResponse.success)
                || (logoutResponse
                    && logoutResponse.status === "error"
                    && logoutResponse.code
                    && logoutResponse.code === "KRUGER"))
            {
                window.location.href = sipconvergenceReformatter("https://wholesale.voicehost.co.uk/Customers?view_mode=2")
            }
            else
            {
                add({
                    text: "Unable to logout please try again",
                    type: "error",
                })
            }
        }
    }

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            maxWidth={700}
        >
            <h5
                style={{
                    paddingBottom: 12
                }}
            >
                Your session is about to expire
            </h5>
            <div>
                <p>
                    You will be logged out in {counter} seconds, please confirm if you want to stay logged in.
                </p>
            </div>
            <div>
                <FormButtons
                    customButtons={customButtons}
                />
            </div>
        </Modal>
    )
}
