import React, { useContext, useEffect } from "react"
import "./formInputs.scss";
import { ThemeContext } from "../../Contexts/ThemeContext";

export function DaySelector({
    name,
    value,
    onChange,
    defaultDayValues
}: IDaySelector)
{

    /**
     * initialises defaultDayValues
     */
    useEffect(() =>
    {
        let tempVal = value
        if (!tempVal)
        {
            if (defaultDayValues)
            {
                tempVal = defaultDayValues
            }
            else
            {
                tempVal = { mon: false, tue: false, wed: false, thu: false, fri: false, sat: false, sun: false }
            }
        }
        onChange({
            target: {
                value: tempVal,
                name: name,
                type: "daySelector"
            }
        })
    }, [])

    function handleChange(val, day)
    {
        let tempVal = value
        if (!tempVal)
        {
            tempVal = { mon: false, tue: false, wed: false, thu: false, fri: false, sat: false, sun: false }
        }
        tempVal[day] = val
        onChange({
            target: {
                value: tempVal,
                name: name,
                type: "daySelector"
            }
        })
    }

    function isSelected(day)
    {
        if (value)
        {
            return value[day]
        } else
        {
            return false
        }
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
            }}
        >
            {["mon", "tue", "wed", "thu", "fri", "sat", "sun"].map(day => (
                <DayDot
                    day={day}
                    selected={isSelected(day)}
                    setSelected={(val) =>
                    {
                        handleChange(val, day)
                    }}
                />
            ))}
        </div>)
}

interface IDayDot
{
    day: string
    selected?: boolean
    setSelected: (val: boolean) => void
}

function DayDot({ day, selected, setSelected }: IDayDot)
{
    const { accentColour } = useContext(ThemeContext)
    return (
        <div
            onClick={() => setSelected(!selected)}
            style={{
                userSelect: "none",
                fontSize: "0.9em",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 32,
                height: 32,
                borderRadius: 16,
                marginRight: 16,
                backgroundColor: selected ? accentColour : "inherit",
                border: !selected ? `1px solid ${accentColour}` : "none",
                color: selected ? "white" : accentColour,
                transition: "background-color .1s ease-in-out, color .1s ease-in-out, font-weight .1s ease-in-out, border .1s ease-in-out",
            }}
        >
            {day.charAt(0).toUpperCase()}
            {!selected && (<div className={"line"} style={{ borderBottom: `1px solid ${accentColour}` }} />)}
        </div>
    )
}