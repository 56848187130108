import { useContext, useState, useEffect } from "react";
import { SessionContext } from "../Contexts/SessionContext";
import { hasPermission, permissions } from "../Constants/permissions";
import { useRequest } from "./useRequest";
import { ToastContext } from "../Contexts/ToastContext";
import { loginDotUrl, sipconvergenceReformatter } from "../Common/urlHelpers";
import TopUpFormModal from "../Pages/AccountTransactionsPage/TopUpFormModal";

export default function useSidebarItems(): any[]
{

    const [isRetail, setIsRetail] = useState(false)
    const [isPrepay, setIsPrepay] = useState(false)
    const [isWholesale, setIsWholesale] = useState(false)
    const [isPostpay, setIsPostpay] = useState(false)
    const [hasPortal, setHasPortal] = useState(false)
    const [emsErrors, setEmsErrors] = useState([])
    const { state: state } = useContext(SessionContext);
    const { getRequest, deleteRequest } = useRequest()
    const { add } = useContext(ToastContext);

    function showChatButton(): boolean
    {
        if (state && state.session && isWholesale)
        {
            if (state.session.parent && state.session.parent == "0")
            {
                return true
            }
        }
        return false
    }

    useEffect(() =>
    {
        if (state && state.session && state.session.permissions)
        {
            setIsPrepay(hasPermission(
                [permissions.prepay],
                state.session.permissions,
                "read"
            ))
            setIsPostpay(hasPermission(
                [permissions.postpay],
                state.session.permissions,
                "read"
            ))
            setIsWholesale(hasPermission(
                [permissions.wholesale],
                state.session.permissions,
                "read"
            ))

            setIsWholesale(hasPermission(
                [permissions.wholesale],
                state.session.permissions,
                "read"
            ))

            setHasPortal(state.session.puppeteer === Number(state.session.user_id))
        }
        if (state && state.session && state.errors)
        {
            setEmsErrors(state.errors.filter(error => error.type === "ems"))
        }
    }, [state])

    async function logout()
    {
        if (hasPortal)
        {
            const logoutResponse = await deleteRequest(
                null,
                `/users/auth/disguise`
            )
            if (logoutResponse && logoutResponse.success)
            {
                window.location.href = sipconvergenceReformatter("https://wholesale.voicehost.co.uk/Customers?view_mode=2")
            }
            else
            {
                add({
                    text: "Unable to logout please try again",
                    type: "error",
                })
            }
        }
        else
        {
            const logoutResponse = await getRequest(
                `/users/auth/logout`,
            )
            if (logoutResponse && logoutResponse.status && logoutResponse.status === "success")
            {
                window.location.href = loginDotUrl(false)
            }
            else
            {
                add({
                    text: "Unable to logout please try again",
                    type: "error",
                })
            }
        }
    }

    const balance = state.account.balance
    const balanceString = (balance || balance === 0) && `Balance: ${balance < 0 ? "-" : ""}£${Math.abs(balance).toFixed(2)}`

    return [
        { text: "Dashboard", icon: "home", url: "/", error: emsErrors && emsErrors.length > 0 },
        {
            text: "Account " + (state.session.account_no ? state.session.account_no : ""),
            icon: "user",
            subItems: [
                { text: "Account Details", icon: "file-alt", url: "/account-details" },
                { text: "Invoices", icon: "file", url: "/invoice" },
                { text: "User Manager", icon: "users", url: sipconvergenceReformatter("https://login.voicehost.co.uk/ManageUser?filter=active"), isExternal: true },
                { text: "Account Transactions", icon: "calculator", url: "/account-transaction" },
                { text: "Tariffs", icon: "file-excel", url: "/tariff" },
                { text: "Bundles", icon: "boxes", url: "/bundles" },
                { text: "Integrations", icon: "handshake", url: "/integrations" },
                { text: "International Addresses", icon: "address-book", url: "/international-addresses" },
            ],
        },
        {
            text: "Call Routing",
            icon: "map-signs",
            subItems: [
                { text: "Call Groups", icon: "table", url: "/call-groups" },
                { text: "Call Queues", icon: "sort-amount-down", url: "/call-queues" },
                { text: "IVR Menus", icon: "share-alt", url: "/ivrs" },
                { text: "NTS Destinations", icon: "external-link-alt", url: "/nts" },
                { text: "Seat Setup", icon: "cogs", url: "/seats" },
                { text: "SIP Trunk", icon: "link", url: "/sip" },
                { text: "Telephone Numbers", icon: "phone", url: "/telephone-numbers" },
                { text: `Emergency Service Errors`, icon: "hospital-alt", url: "/ems-errors", error: true, hide: !emsErrors || emsErrors.length === 0 },
                { text: "Time Profile", icon: "clock", url: "/time-profiles" }
            ]
        },
        {
            text: "Call Information",
            icon: "headset",
            subItems: [
                { text: "Call Records", icon: "hdd", url: "/call-records" },
                { text: "Call Recordings", icon: "microphone-alt", url: "/call-recording" }
            ]
        },
        {
            text: "Additional Features",
            icon: "tools",
            subItems: [
                { text: "Black/White Lists", icon: "list-alt", url: "/access-lists" },
                { text: "Call Monitoring", icon: "eye", url: "/call-monitoring" },
                { text: "Conferencing", icon: "comments", url: "/conferencing" },
                { text: "Custom Prompts", icon: "quote-left", url: "/custom-prompts" },
                { text: "Dial Through", icon: "random", url: "/dial-through" },
                { text: "Music On Hold", icon: "music", url: "/music-on-hold" },
                { text: "Phone Directory", icon: "book", url: "/phone-directory" },
                { text: "Pickup Groups", icon: "th", url: "/pickup-groups" },
                { text: "SMS", icon: "envelope", url: "/sms" },
                { text: "Mobile", icon: "mobile-alt", url: "/mobile" },
                { text: "Softphone CLIs", icon: "list", url: "/outbound-clis" },
                //     subItems = subItems.concat({ text: "Website Callback", icon: "star", url: "/callback" })
            ]
        },
        { text: "Provisioning", icon: "wrench", url: "/provisioning" },
        { text: "Voicemail", icon: "voicemail", url: "/voicemail" },
        { text: "Store", icon: "shopping-cart", url: "/store" },
        isPrepay && {
            text: balanceString,
            icon: "money-bill-alt",
            iconVariant: "regular",
            url: "/account-transaction",
        },
        isPrepay && {
            text: 'Top Up',
            icon: "money-bill-alt",
            iconVariant: "regular",
        },
        showChatButton() && {
            text: "Live Help Chat",
            icon: "comments",
            onClick: () =>
            {   //@ts-ignore
                window.$_LHC.eventListener.emitEvent('showWidget');
            }
        },
        { text: "Support", icon: "comments", url: "/support" },
        {
            text: hasPortal ? "Back to Portal" : "Log Out",
            icon: "power-off",
            onClick: logout,
            borderTop: true,
            bottom: true
        },
    ].filter(Boolean)
}
