import React, { useEffect, useState } from "react"
import Modal from "../Modal/Modal";
import StagedForm from "../Form/StagedForm";
import { useFormReducer } from "../../Hooks/useFormReducer";
import { useRequest } from "../../Hooks/useRequest";
import useAPIRequests from "../../Hooks/useAPIRequests";

interface IProps
{
    open?: boolean
    setOpen?: (val?: boolean) => void
    pullData?: () => void
}

export function ProvisioningModal({
    open,
    setOpen,
    pullData
}: IProps)
{

    const { state, updateForm, updateErrors } = useFormReducer({
        record: {
            macAddress: "",
            make: "",
            model: "",
            modelList: [],
            sipAccounts: ""
        }
    })

    const { getRequest, postRequest } = useRequest()
    const { getDestinationPromise } = useAPIRequests()

    function checkMACAddress(address): Promise<{ success: boolean, message: string }>
    {
        return new Promise(async resolve =>
        {
            const errorMessage = "Invalid MAC Address, please try again"
            const macRegex = /^[0-9a-fA-F]{2}([-:]?)[0-9a-fA-F]{2}(\1[0-9a-fA-F]{2}){4}$/;
            if (macRegex.test(address))
            {
                let response = await getRequest("/provisioning/mac/" + address.substring(0, 6))
                if (response && response.macMake && response.modelList)
                {
                    updateForm({
                        target: {
                            name: "modelList",
                            value: response.modelList
                        }
                    })
                    updateForm({
                        target: {
                            name: "make",
                            value: response.macMake
                        }
                    })
                    resolve({ success: true, message: "" })
                }
                else
                {
                    updateErrors({ target: { name: "macAddress", value: errorMessage } })
                    resolve({ success: false, message: errorMessage })
                }
            }
            else
            {
                updateErrors({ target: { name: "macAddress", value: errorMessage } })
                resolve({ success: false, message: errorMessage })
            }
        })
    }

    async function getModelsAsOptions(models): Promise<ISelectOption[]>
    {

        return new Promise(resolve =>
        {
            if (models)
            {
                const modelsAsSelect = models.map(model => ({
                    label: model,
                    value: model
                }))
                resolve(modelsAsSelect)
            }
            else
            {
                resolve(null)
            }
        })
    }

    function checkIfModelIsSet(model): Promise<{ success: boolean }>
    {
        return new Promise(async resolve =>
        {
            if (model)
            {
                updateErrors({ target: { value: "", name: "model" } })
                resolve({ success: true })
            }
            else
            {
                updateErrors({ target: { value: "Please select a model", name: "model" } })
                resolve({ success: false })
            }
        })
    }

    function submitDevice(data): Promise<{ success: boolean, message: string }>
    {
        return new Promise(async resolve =>
        {
            const postData = {
                macAddress: data.macAddress,
                make: data.make,
                model: data.model,
                sipAccounts: data.sipAccounts
            }

            let response = await postRequest(postData, "/provisioning")
            if (response)
            {
                if (response.success)
                {
                    if (response.redirectError)
                    {
                        resolve({ success: false, message: response.redirectError })
                    }
                    else
                    {
                        resolve({ success: true, message: "" })
                    }
                }
                else
                {
                    if (response.validation)
                    {
                        resolve({ success: false, message: response.validation[0] })
                    }
                    else
                    {
                        resolve({ success: false, message: response.message })
                    }
                }
            }
            else
            {
                resolve({ success: false, message: "Something went wrong, please try again" })
            }
        })
    }

    function getFeedback(data)
    {
        if (data.success)
        {
            return "Device added successfully"
        }
        else
        {
            return "Failed to add device for reason: " + data.message
        }
    }

    function findFieldError(name)
    {
        if (state.errors && state.errors.length > 0)
        {
            const errors = state.errors.filter((error) => error.name === name)
            if (errors && errors.length > 0)
            {
                return errors[0].value
            }
        }
        return null
    }

    function getSeats()
    {
        return new Promise<ISelectOption[]>(async resolve =>
        {
            const options = await getDestinationPromise({
                seats: true,
                queues: false,
                voicemail: false,
                groups: false,
                ivrs: false,
                timeProfiles: false,
                sipTrunks: false,
                dialThrough: false,
                externalDest: false,
                sipTrunkMap: false,
                queueTrunkMaps: false,
                extensionMap: false,
                highPriorityQueue: false,
            })
            resolve(options.map(option => ({
                label: option.label,
                value: option.value.replace(`SIP/`, "")
            })
            ))
        })
    }

    return (
        <Modal open={open} setOpen={setOpen} maxWidth={700}>
            <h4>Add a new device</h4>
            <StagedForm
                onComplete={() =>
                {
                    pullData && pullData()
                    setOpen(false)
                }}
                stages={[
                    {
                        title: `MAC Address`,
                        onComplete: {
                            buttonText: "Add",
                            function: () =>
                            {
                                return new Promise(async resolve =>
                                {
                                    resolve(checkMACAddress(state.macAddress))
                                })
                            }
                        },
                        fields: [
                            {
                                type: "text",
                                name: "macAddress",
                                label: "MAC Address",
                                onChange: updateForm,
                                value: state.macAddress,
                                error: findFieldError("macAddress"),
                                setError: updateErrors
                            }
                        ]
                    },
                    {
                        title: "Make and Model",
                        onComplete: {
                            buttonText: "Next",
                            function: () =>
                            {
                                return new Promise(async resolve =>
                                {
                                    resolve(checkIfModelIsSet(state.model))
                                })
                            }
                        },
                        fields: [
                            {
                                type: "info",
                                name: "make",
                                label: "Make",
                                value: state.make,
                                onChange: updateForm
                            },
                            {
                                type: "select",
                                name: "model",
                                label: "Model",
                                required: true,
                                value: state.model,
                                loadOptions: () => getModelsAsOptions(state.modelList),
                                onChange: updateForm,
                                error: findFieldError("model"),
                                setError: updateErrors
                            }
                        ]
                    },
                    {
                        title: "User",
                        fields: [
                            {
                                type: "select",
                                name: "sipAccounts",
                                label: "Main User",
                                value: state.sipAccounts,
                                onChange: updateForm,
                                loadOptions: getSeats
                            }
                        ]
                    },
                    {
                        title: "Confirm",
                        onComplete: {
                            buttonText: "Add",
                            function: () =>
                            {
                                return new Promise(async resolve =>
                                {
                                    const retData = await submitDevice(state)
                                    updateForm({
                                        target: {
                                            name: "success",
                                            value: retData.success
                                        }
                                    })
                                    updateForm({
                                        target: {
                                            name: "message",
                                            value: retData.message
                                        }
                                    })
                                    resolve({ success: true })
                                })
                            }
                        },
                        fields: [
                            {
                                type: "paragraph",
                                value: "Are you sure you want to add this device?"
                            }
                        ]
                    },
                    {
                        title: "Feedback",
                        fields: [
                            {
                                type: "paragraph",
                                value: getFeedback(state)
                            }
                        ]
                    }
                ]}
            />
        </Modal>
    )
}