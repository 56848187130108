import GradientBackground from "../GradientBackground/GradientBackground";
import Icon from "../Icons/Icon";
import { Col } from "react-grid-system";
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useRequest } from "../../Hooks/useRequest";
import "./dashboardWidgets.scss"


export default function DashboardSummaryItem({
    title,
    icon,
    link,
    color,
    requestUrl,
    requestType = "GET",
    hidden,
    doubleWidth = false
})
{
    const history = useHistory()
    const { getRequest, postRequest } = useRequest()

    const [count, setCount] = useState()

    useEffect(() =>
    {
        async function pullData()
        {
            let response = null
            if (requestType === "GET")
            {
                response = await getRequest(requestUrl)
            }
            if (requestType === "POST")
            {
                response = await postRequest({}, `${requestUrl}/0/1`)
            }
            if (response && (response.totalRecords || response.totalRecords === 0))
            {
                setCount(response.totalRecords)
            }
        }

        pullData()
    }, [])

    const themes = [
        {
            color: 'blue',
            gradientColors: ['#60c3ea', '#228dcf'],
        },
        {
            color: 'yellow',
            gradientColors: ['#ffce3b', '#fea018'],
        },
        {
            color: 'red',
            gradientColors: ['#ed5419', '#d10303'],
        },
        {
            color: 'green',
            gradientColors: ['#4dad2c', '#248800'],
        },
    ]

    function activeTheme()
    {
        const matchingThemes = themes.filter(theme => theme.color === color)
        if (matchingThemes.length > 0)
        {
            return matchingThemes[0]
        } else
        {
            return null
        }
    }
    if (hidden)
    {
        return (null)
    }
    else
    {
        return (
            <Col md={12} lg={doubleWidth ? 12 : 6} xl={doubleWidth ? 12 : 6}>
                <div
                    className={"dashboardSummaryGridItemContainer"}
                    style={{
                        cursor: link && "pointer",
                    }}
                    onClick={() =>
                    {
                        if (link)
                        {
                            history.push(link)
                        }
                    }}
                >
                    <div
                        className={"dashboardSummaryGridItemInner"}
                    >
                        <GradientBackground
                            colors={activeTheme().gradientColors}
                            angle={0.6}
                            className={"dashboardSummaryGridItemIconContainer"}
                        >
                            <div
                                className={"dashboardSummaryGridItemIcon"}
                            >
                                <Icon icon={icon} color={"white"} />
                            </div>
                        </GradientBackground>
                        <div
                            className={"dashboardSummaryGridText"}
                        >
                            <span
                                style={{
                                    fontWeight: 500,
                                }}
                            >
                                {title}:
                            </span>
                            <span>{count}</span>
                        </div>
                    </div>

                </div>
            </Col>
        )
    }
}