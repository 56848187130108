import React, { useContext, useRef, useState } from "react";
import { hasPermission, permissions } from "../../Constants/permissions";
import { SessionContext } from "../../Contexts/SessionContext";
import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage";
import { BulkAddCallMonitoringModal } from "./BulkAddCallMonitoringModal";

export default function CallMonitoringIndexPage()
{
    const { state: sessionState } = useContext(SessionContext)
    const [addOpen, setAddOpen] = useState(false)
    const indexRef = useRef({ selectedRecords: [] })

    const columns: IColumn[] = [
        {
            title: "Extension",
            link: { root: "/call-monitoring" },
            bold: true,
            name: "extension",
            sortBy: true,
        },
        {
            title: "Nickname",
            name: "nickname",
            sortBy: true
        },
        {
            title: "Authorised Seats",
            name: "numSeats",
            sortBy: true,
        }
    ]

    const customModalButton: IButton =
    {
        variant: "warning",
        iconLeft: "pencil-alt",
        color: "white",
        iconVariation: "regular",
        children: "Edit Monitoring",
        requireSelected: true,
        onClick: () => setAddOpen(true)
    }

    return (
        <React.Fragment>
            <GenericIndexPage
                uniqueIdentifier={"extension"}
                title={"Call Monitoring"}
                urlString={"/callmonitoring/overview"}
                ref={indexRef}
                columns={columns}
                customModalButton={hasPermission([permissions.callmonitoring, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "delete")
                    && hasPermission([permissions.callmonitoring, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "create") && customModalButton}
                searchable={true}
                restType={"POST"}
                helpSections={[
                    {
                        title: "Call Monitoring",
                        text: "The call monitoring service can only be accessed using on-net phones registered to this account. To use the call monitoring service please dial '154' and follow the spoken prompts. " +
                            "You will be asked for the 'Extension' that you wish to monitor and the 'PIN' specified for each seat."
                    }
                ]}
            />
            {addOpen && <BulkAddCallMonitoringModal
                open={addOpen}
                setOpen={setAddOpen}
                indexRef={indexRef}
            />}
        </React.Fragment>
    )
}
