import { shadeColour } from "../Common/shadeColour";
import React, { createContext, useReducer, useContext, useEffect } from "react";
import { SessionContext } from "./SessionContext";

type ITheme = {

    primaryColour: string
    primaryColourDarker: string
    primaryColourLighter: string
    accentColour: string
    setPrimaryColour: (colour: string) => void
    setAccentColour: (colour: string) => void
}

const themeReducer = (state, action) =>
{
    const { payload, type } = action;
    switch (type)
    {
        case "SET_PRIMARY_COLOUR":
            return { ...state, primaryColour: payload };
        case "SET_ACCENT_COLOUR":
            return { ...state, accentColour: payload };
        case "SET_PRIMARY_COLOUR_DARKER":
            return { ...state, primaryColourDarker: payload };
        case "SET_PRIMARY_COLOUR_LIGHER":
            return { ...state, primaryColourLighter: payload };
        default:
            return state;
    }
};

const ThemeContext = createContext<ITheme>(
    {
        primaryColour: localStorage.getItem('primaryColour') ? localStorage.getItem('primaryColour') : "#1671aa",
        primaryColourDarker: localStorage.getItem('primaryColour') ? localStorage.getItem('primaryColour') : "#f36f21",
        primaryColourLighter: "#000000",
        accentColour: "#f36f21",
        setPrimaryColour: null,
        setAccentColour: null
    }
)

const ThemeProvider = (props) =>
{
    const { state: session } = useContext(SessionContext)
    const [state, dispatch] = useReducer(themeReducer, {
        primaryColour: localStorage.getItem('primaryColour') ? localStorage.getItem('primaryColour') : "#1671aa",
        accentColour: localStorage.getItem('accentColour') ? localStorage.getItem('accentColour') : "#f36f21",
        setPrimaryColour,
        setAccentColour
    });

    useEffect(() =>
    {
        if (session.theme.br_primary_colour)
        {
            dispatch({
                type: "SET_PRIMARY_COLOUR",
                payload: session.theme.br_primary_colour
            })
            localStorage.setItem('primaryColour', session.theme.br_primary_colour)
        }
        if (session.theme.br_accent_colour)
        {
            dispatch({
                type: "SET_ACCENT_COLOUR",
                payload: session.theme.br_accent_colour
            })
            localStorage.setItem('accentColour', session.theme.br_accent_colour)
        }
    }, [session.theme])

    useEffect(() =>
    {
        dispatch({
            type: "SET_PRIMARY_COLOUR_DARKER",
            payload: shadeColour(state.primaryColour, -6)
        })
        dispatch({
            type: "SET_PRIMARY_COLOUR_LIGHER",
            payload: shadeColour(state.primaryColour, 35)
        })
    }, [state.primaryColour])

    function setPrimaryColour(primaryColour)
    {
        dispatch({
            type: "SET_PRIMARY_COLOUR",
            payload: primaryColour
        })
        localStorage.setItem('primaryColour', primaryColour)
    }
    function setAccentColour(accentColour)
    {
        dispatch({
            type: "SET_ACCENT_COLOUR",
            payload: accentColour
        })
        localStorage.setItem('accentColour', accentColour)
    }

    return (
        <ThemeContext.Provider value={{ ...state, setPrimaryColour, setAccentColour }}>
            {props.children}
        </ThemeContext.Provider>
    )
}

export { ThemeContext, ThemeProvider }