import React, { useEffect, useContext, useState } from "react";
import variablesScss from "../../Constants/variables.scss";
import { SessionContext } from "../../Contexts/SessionContext";
import { useRequest } from "../../Hooks/useRequest";
import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage";
import InvoicePaymentModal from "./InvoicePaymentModal";
import StripeFeedbackModal from "./StripeFeedbackModal";
import DirectDebitFormModal from "../AccountTransactionsPage/DirectDebitFormModal";
import TopUpFormModal from "../AccountTransactionsPage/TopUpFormModal";
import { hasPermission, permissions } from "../../Constants/permissions";

export default function InvoiceIndexPage()
{
    const { state: session } = useContext(SessionContext)
    const { postRequest } = useRequest()
    const [payInvoiceModalOpen, setPayInvoiceModalOpen] = useState(false)
    const [selectedInvoice, setSelectedInvoice] = useState()
    const [stripeFeedbackModalOpen, setStripeFeedbackModalOpen] = useState(false)
    const [directDebitModalOpen, setDirectDebitModalOpen] = useState(false)
    const [topUpModalOpen, setTopUpModalOpen] = useState(false)

    async function updateSelectedInvoice(id)
    {
        setSelectedInvoice(undefined)
        if (id)
        {
            var postData = {
                invoiceNo: id,
                accountNo: session.session.account_no
            }
            const invoicesResponse = await postRequest(postData, `/invoice/search/0/1`)
            if (invoicesResponse && invoicesResponse.records && invoicesResponse.records.length)
            {
                setSelectedInvoice(invoicesResponse.records[0])
            }
            setPayInvoiceModalOpen(true)
        }
    }

    useEffect(() =>
    {
        async function getStripeStatus()
        {
            const stripe_status = new URLSearchParams(window.location.search).get("redirect_status");

            if (stripe_status != null)
            {
                setStripeFeedbackModalOpen(true);
            }
        }

        getStripeStatus()
    }, []);

    const customActions: IButton[] = []

    if (hasPermission(
        [permissions.prepay],
        session.session.permissions,
        "read"
    ))
    {
        customActions.push({
            variant: "warning",
            iconLeft: "money-bill-alt",
            color: "white",
            iconVariation: "regular",
            children: "Top Up",
            onClick: () => setTopUpModalOpen(true)
        })
    }

    if (hasPermission(
        [permissions.directDebit],
        session.session.permissions,
        "read"
    ))
    {
        customActions.push({
            variant: "success",
            iconLeft: "credit-card",
            // color: "white",
            iconVariation: "regular",
            children: "Direct Debit",
            onClick: () => setDirectDebitModalOpen(true)
        })
    }

    const columns: IColumn[] = [
        {
            title: "Invoice #",
            name: "id",
            sortBy: true,
            bold: true,
            link: { root: "/invoice", newTab: true }
        },
        {
            title: "Date",
            name: "date",
            type: "date",
            sortBy: true,
            conditional: [{ value: null, text: " " }]
        },
        {
            title: "Due",
            name: "due",
            type: "date",
            sortBy: true,
            conditional: [{ value: null, text: " " }]
        },
        {
            title: "Paid Date",
            name: "paidDate",
            type: "date",
            sortBy: true,
            conditional: [{ value: null, text: " " }]
        },
        {
            title: "Sub-Total",
            type: "money",
            name: "subTotal",
        },
        {
            title: "VAT",
            type: "money",
            name: "vat",
        },
        {
            title: "Total",
            name: "total",
            type: "money",
            sortBy: true,
        },
        {
            title: "Status",
            name: "status",
            conditional: [
                {
                    value: "Overdue",
                    color: variablesScss.colorRed,
                    icon: "exclamation-triangle",
                    onClick: (id) =>
                    {
                        updateSelectedInvoice(id)
                    }
                },
                { value: "Void", icon: "ban" },
                { value: "Paid", color: variablesScss.colorGreen, icon: "check" },
                {
                    value: "Unpaid",
                    color: variablesScss.primaryColorLighter,
                    onClick: (id) =>
                    {
                        updateSelectedInvoice(id)
                    }
                }
            ],
        },
    ]

    const filters: IFilterDropdown = {
        filters: [
            {
                label: "Status",
                name: "status",
                type: "select",
                multi: true,
                options: [
                    { label: "Paid", value: "paid" },
                    { label: "Unpaid", value: "unpaid" },
                    { label: "Overdue", value: "overdue" }, ,
                    { label: "Void", value: "void" }, ,
                ]
            }
        ]
    }

    return (
        <React.Fragment>
            <GenericIndexPage
                title={"Invoices"}
                urlString={"/invoice"}
                body={{}}
                restType={"POST"}
                columns={columns}
                filters={filters}
                searchable={true}
                customActions={customActions}
            />
            {stripeFeedbackModalOpen && <StripeFeedbackModal open={stripeFeedbackModalOpen} setOpen={setStripeFeedbackModalOpen} />}
            {selectedInvoice && <InvoicePaymentModal open={payInvoiceModalOpen} setOpen={setPayInvoiceModalOpen} invoice={selectedInvoice} />}
            {topUpModalOpen && <TopUpFormModal open={topUpModalOpen} setOpen={setTopUpModalOpen} />}
            <DirectDebitFormModal open={directDebitModalOpen} setOpen={setDirectDebitModalOpen} />
        </React.Fragment>
    )
}