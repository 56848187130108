import React, { useEffect, useState } from "react"
import ReactSelect from "react-select"
import "./formInputs.scss"
import Icon from "../Icons/Icon"
import { VariableSizeList as List } from "react-window"

export default function Select({
    placeholder,
    loadOptions,
    value,
    disabled,
    options,
    onChange,
    name,
    style,
    multi,
    updateOn,
    defaultToFirst,
    setError,
    required,
    noResultText,
    heightChange
}: ISelect)
{
    const [displayOptions, setDisplayOptions] = useState<ISelectOption[]>([])
    const [loading, setLoading] = useState(false)
    const [currentValue, setCurrentValue] = useState<ISelectOption[]>()

    useEffect(() =>
    {
        if (displayOptions)
        {
            if (value || value === 0)
            {
                if (multi)
                {
                    setCurrentValue(
                        displayOptions.filter((option) =>
                            value.includes(option.value)
                        )
                    )
                } else
                {
                    setCurrentValue(
                        displayOptions.filter(
                            (option) => option.value === value
                        )
                    )
                }
            }
            else
            {
                setCurrentValue([])
            }
        }
    }, [value, displayOptions])

    useEffect(() =>
    {
        async function getLoadOptions()
        {
            let optionsArray
            const fetchedOptions = await loadOptions()
            if (options)
            {
                optionsArray = [...options, ...fetchedOptions]
            } else
            {
                optionsArray = fetchedOptions
            }
            if (!optionsArray || optionsArray.length === 0)
            {
                optionsArray = [{ label: "No Results", value: null, isDisabled: true }]
            }
            setDisplayOptions(optionsArray)
            if (
                !value &&
                defaultToFirst &&
                fetchedOptions &&
                fetchedOptions.length > 0
            )
            {
                onChange({
                    //@ts-ignore
                    target: {
                        value: fetchedOptions[0].value,
                        type: "select",
                        name,
                    },
                })
            }
            setLoading(false)
        }

        if (!loadOptions)
        {
            setDisplayOptions(options)
        } else if (loadOptions)
        {
            setLoading(true)
            getLoadOptions()
        }
    }, [updateOn])

    function handleOnChange(val)
    {
        if (val?.onChange)
        {
            val.onChange();
        }
        else
        {
            if (multi)
            {
                if ((!val || (val && !val.length)) && required)
                {
                    setError && setError({ target: { name, value: "This is a required field" } })
                } else
                {
                    setError && setError({ target: { name, value: "" } })
                }
                //@ts-ignore
                if (val && val.length)
                {
                    // @ts-ignore
                    const newValue = val.map((value) => value.value)
                    onChange({
                        target: {
                            value: newValue,
                            type: "select",
                            name,
                        },
                    })
                } else
                {
                    onChange({
                        target: { value: [], type: "select", name },
                    })
                }
            } else
            {
                if (!val && required)
                {
                    setError && setError({ target: { name, value: "This is a required field" } })
                } else
                {
                    setError && setError({ target: { name, value: "" } })
                }
                onChange({
                    //@ts-ignore
                    target: { value: val.value, type: "select", name },
                })
            }
        }
    }

    return (
        <div
            className={"reactselect"}
            style={{
                ...style,
                flex: 1,
                position: "relative",
                whiteSpace: "nowrap"
            }}
        >
            <ReactSelect
                menuPosition={'fixed'}
                components={{
                    MenuList:
                        ({ options, children, maxHeight, getValue }) =>
                            <MenuList options={options} children={children} maxHeight={maxHeight} getValue={getValue} noResultText={noResultText} heightChange={heightChange} />
                }}
                isDisabled={disabled || loading}
                placeholder={placeholder}
                options={
                    loading
                        ? [
                            {
                                label: "Loading...",
                                value: "loadingValue",
                            },
                        ]
                        : displayOptions && displayOptions.filter(Boolean)
                }
                value={currentValue}
                onChange={handleOnChange}
                name={name}
                isMulti={multi}
            />
            {loading && (
                <div
                    style={{
                        position: "absolute",
                        top: 5,
                        right: 46,
                    }}
                >
                    <Icon
                        icon="circle-notch"
                        spin
                        transform="shrink-2 down-2"
                        color="#cccccc"
                    />
                </div>
            )}
        </div>
    )
}

const height = 35

function MenuList({ options, children, maxHeight, getValue, noResultText, heightChange })
{
    if (options.length > 0)
    {
        const [value] = getValue()
        let initialOffset = options.indexOf(value) * height
        let rowHeights = []
        if (children && typeof children[Symbol.iterator] === 'function')
        {
            heightChange = heightChange > 0 ? heightChange : 55
            for (let child of children)
            {
                rowHeights.push(35)
            }
        }
        else
        {
            maxHeight = height
        }

        if (maxHeight > children.length * height)
        {
            initialOffset = 0
            maxHeight = children.length * height + 5
        }
        return (
            <List
                height={maxHeight}
                itemCount={children.length}
                itemSize={index => rowHeights[index]}
                initialScrollOffset={initialOffset}
            >
                {({ index, style }) => <div style={style}>{children[index]}</div>}
            </List>
        )
    }
    else
    {
        return (
            <List
                height={height}
                itemCount={1}
                itemSize={() => height}
                initialScrollOffset={0}
            >
                {() => <div style={{ marginLeft: 4, padding: 5 }}>{noResultText ? noResultText : "No Results"}</div>}
            </List>
        )
    }
}
