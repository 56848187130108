import React, { useState } from "react";
import { FormControl } from "react-bootstrap";
import { regex } from "../../Constants/regex";

export default function EmailInput({
    placeholder,
    value,
    disabled,
    onChange,
    name,
    error,
    setError,
}: IEmailInput)
{
    function handleOnChange(e)
    {
        const { value } = e.target

        if (!value || regex.email.test(value))
        {
            setError && setError({ target: { ...e.target, name, value: "" } })
        } else
        {
            setError && setError({ target: { ...e.target, name, value: "Must be a valid email address" } })
        }
        onChange(e)
    }

    return (
        <div className="flex-row flex-1">
            <FormControl
                isInvalid={!!error}
                disabled={disabled}
                type={"email"}
                placeholder={placeholder}
                name={name}
                onChange={handleOnChange}
                value={value}
            />
        </div>
    );
}
