import { useFormReducer } from "../../Hooks/useFormReducer";

export function useSupportFormPage()
{

    function showChatButton(): boolean
    {
        if (state && state.session)
        {
            if (state.session.account_no == "10000")
            {
                return true
            }
            else if (state.session.parent && state.session.parent == "0")
            {
                return true
            }
        }
        return false
    }

    const {
        state,
        updateState,
        updateForm,
        updateErrors,
    } = useFormReducer({ record: { staticpage: true } })

    const tabs: ITab[] = [
        {
            title: "Contact Us",
            icon: "envelope",
            sections: [
                {
                    title: "Support Information",
                    fields: [
                        {
                            type: "link",
                            label: "Email Us",
                            text: "support@voicehost.co.uk",
                            value: "mailto:support@voicehost.co.uk"
                        },
                        showChatButton && {
                            type: "button",
                            label: "Live Chat",
                            placeholder: "Contact Us",
                            onClick: () =>
                            {
                                //@ts-ignore
                                window.$_LHC.eventListener.emitEvent('showWidget');
                            }
                        },
                        {
                            type: "info",
                            label: "Live Chat Availability",
                            value: "09:00-17:30 GMT"
                        },
                        {
                            type: "link",
                            label: "UK Phone Number",
                            text: "01603 90 40 90",
                            value: "tel:01603904090",
                        },
                        {
                            type: "info",
                            label: "Phone Availability",
                            value: "24/7 365 days a year"
                        },
                    ],
                },
                {
                    title: "Postal Address",
                    fields: [
                        {
                            type: "info",
                            label: "Address",
                            centred: true,
                            value: `63 Thorpe Road`
                        },
                        {
                            type: "info",
                            label: "City",
                            centred: true,
                            value: `Norwich`
                        },
                        {
                            type: "info",
                            label: "County",
                            centred: true,
                            value: `Norfolk`
                        },
                        {
                            type: "info",
                            label: "Postcode",
                            centred: true,
                            value: `NR1 1UD`
                        },
                    ]
                }
            ],
        },
        {
            title: "Help",
            icon: "question",
            sections: [
                {
                    title: "Video Tutorials",
                    fullWidth: true,
                    fields: [
                        {
                            type: "youtubeVideo",
                            noLabel: true,
                            height: 390,
                            width: 640,
                        },
                    ],
                },
            ],
        }
    ]

    return {
        tabs,
        state,
        updateState,
        updateForm,
        updateErrors,
    }
}
