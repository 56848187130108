import React from "react"
import StagedForm from "../../components/Form/StagedForm"
import { useContext, useEffect, useState } from "react"
import Modal from "../../components/Modal/Modal"
import { useAddTelephoneNumberModal } from "./useAddTelephoneNumberModal"
import { SessionContext } from "../../Contexts/SessionContext"
import { hasPermission, permissions } from "../../Constants/permissions"

interface IProps
{
    open?: boolean
    setOpen?: (val?: boolean) => void,
    indexRef?: any
}

export function AddTelephoneNumberModal({ open, setOpen, indexRef }: IProps)
{
    const {
        getConfirmationStage,
        getNumberStage,
        renderChargeAdvice,
        findFieldError,
        loading,
        state,
        updateErrors,
        updateForm,
        countryStage,
        usaStateStage,
        regionStage,
        getRegulationStage,
        feedbackStageFields,
        vanityFilterStage
    } = useAddTelephoneNumberModal()

    const { state: session } = useContext(SessionContext)

    function getTypes()
    {
        let numberArray = [
            { value: "GEONUM", label: "UK Geographic Numbers (01,02)" },
            { value: "0333NGN", label: "UK Wide Numbers (0333)" },
            { value: "03NGN", label: "UK Not-for-profit Numbers (0300)" },
            { value: "0843NGN", label: "UK Non-Geographic Numbers (0843 557)[SC006]" },
            { value: "0845NGN", label: "UK Non-Geographic Numbers (0845 561)[SC003]" },
            { value: "0800FREE", label: "UK Freephone Numbers (0800)" },
            { value: "0808FREE", label: "UK Freephone Numbers (0808)" },
            { value: "VAN", label: "Vanity Numbers" }
        ]

        if (session.account.wholesale || hasPermission([permissions.VHAdmin], session.session.permissions, "create"))
        {
            numberArray.push({ value: "INT", label: "International" })
        }

        return numberArray
    }

    return (
        <Modal open={open} setOpen={setOpen} maxWidth={700}>
            <h4>{"Add New Telephone Number"}</h4>
            <StagedForm
                modal
                state={state}
                loading={loading}
                onComplete={() =>
                {
                    indexRef && indexRef.current && indexRef.current.refPullData()
                    setOpen(false)
                }}
                stages={[
                    {
                        title: "Type",
                        onComplete: {
                            function: () =>
                            {
                                return new Promise(async (resolve) =>
                                {
                                    if (!state.type)
                                    {
                                        updateErrors({
                                            target: {
                                                name: "type",
                                                value: "Please select a type"
                                            },
                                        })
                                        resolve({ success: false })
                                    }
                                    
                                    if (state.type == "03NGN" && session.account.notForProfit)
                                    {
                                        resolve({ success: true })
                                    }
                                    else if (state.type == "03NGN" && !session.account.notForProfit)
                                    {
                                        updateErrors({
                                            target: {
                                                name: "type",
                                                value: "Unable to proceed as this account has not been marked as Not For Profit. Please contact Support on support@voicehost.co.uk or 01603 90 40 90 for more information"
                                            },
                                        })
                                        resolve({ success: false })
                                    }
                                    else
                                    {
                                        resolve({ success: true })
                                    }
                                })
                            }
                        },
                        fields: [{
                            type: "select",
                            label: "Type",
                            onChange: updateForm,
                            setError: updateErrors,
                            error: findFieldError("type"),
                            name: "type",
                            value: state.type,
                            options: getTypes()
                        },
                        {
                            type: "select",
                            label: "Vanity Number Type",
                            onChange: updateForm,
                            conditionallyShow: {
                                checks: [{
                                    field: "type",
                                    value: "VAN",
                                }]
                            },
                            value: state.vanityType,
                            name: "vanityType",
                            options: [
                                { value: "GEONUM", label: "UK Geographic Numbers (01,02)" },
                                { value: "0333NGN", label: "UK Wide Numbers (0333)" },
                                { value: "03NGN", label: "UK Not-for-profit Numbers (0300)" },
                                { value: "0800FREE", label: "UK Freephone Numbers (0800)" },
                                { value: "0808FREE", label: "UK Freephone Numbers (0808)" },
                            ]
                        },
                        {
                            type: "paragraph",
                            value: renderChargeAdvice()
                        }]
                    },
                    ...countryStage,
                    ...usaStateStage,
                    ...regionStage,
                    ...vanityFilterStage,
                    ...getRegulationStage(),
                    ...getNumberStage(),
                    ...getConfirmationStage(),
                    {
                        title: "Feedback",
                        fields: feedbackStageFields
                    }
                ]}
            />

        </Modal>
    )
}