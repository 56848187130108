import React, { useContext, useState, useEffect } from "react"
import { SessionContext } from "../../Contexts/SessionContext"
import useAPIRequests from "../../Hooks/useAPIRequests"
import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage"
import Button from "../../components/Button"
import { permissions } from "../../Constants/permissions"

export default function VoicemailIndexPage()
{
    const { state: sessionState } = useContext(SessionContext)
    const { getMailboxes } = useAPIRequests()
    const [mailbox, setMailbox] = useState<String>()

    useEffect(() =>
    {
        setMailbox(sessionState.session.account_no)
    }, [sessionState.session.account_no])

    const columns: IColumn[] = [
        {
            title: "Date",
            name: "date",
            type: "datetime",
            bold: true,
            link: { root: `/voicemail/${mailbox}` },
            conditional: [
                { value: null, text: "No Date" }
            ]
        },
        {
            title: "Caller ID Name",
            name: "callerIdName",
        },
        {
            title: "Caller ID Number",
            name: "callerIdNumber",
        },
    ]

    const filters: IFilterDropdown = {
        label: "Mailboxes",
        filters: [
            {
                label: "Mailbox",
                name: "mailbox",
                type: "select",
                customLabel: "Mailboxes",
                loadOptions: getMailboxes,
                value: mailbox,
                onChange: setMailbox,
            },
        ],
    }

    const customActions: IButton[] = [
        {
            variant: "primary",
            iconLeft: "cog",
            color: "white",
            iconVariation: "regular",
            children: "Mailbox Settings",
            to: `voicemail/settings/${mailbox}`,
        },
    ]

    return (
        <GenericIndexPage
            title={`Voicemails${mailbox ? ` (${mailbox})` : ""}`}
            urlString={`/voicemail/messages`}
            body={{ mailbox: mailbox }}
            restType={"POST"}
            columns={columns}
            filters={filters}
            customActions={customActions}
            permissions={[permissions.voicemail, permissions.admin, permissions.VHAdmin]}
            deleteModal={{
                displayName: "Voicemail",
                submitData: () =>
                {
                    return [{
                        path: `/voicemail/messages`,
                        displayName: "voicemail",
                        type: "delete",
                    }]
                },
            }}
        />
    )
}
