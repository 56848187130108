import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage";
import React from "react";
import { regex } from "../../Constants/regex";
import { regexError } from "../../Constants/regexError";
import { permissions } from "../../Constants/permissions";

export default function MobileIndexPage()
{

    const columns: IColumn[] =
        [
            {
                name: "description",
                title: "Description",
                link: { root: "/mobile" },
                bold: true,
                sortBy: true,
            },
            {
                name: "number",
                title: "Mobile Number",
                sortBy: true,
            },
            {
                name: "active",
                title: "Status",
                conditional: [
                    { value: true, text: "Active", color: "green", icon: "phone", iconColor: "green" },
                    { value: false, text: "Inactive", color: "red", icon: "phone-slash", iconColor: "red" }
                ],
                sortBy: true,
            },
        ]

    const modalSettings: ISettingsStage[] = [
        {
            title: "Settings",
            fields: [
                {
                    type: "text",
                    name: "description",
                    label: "Description",
                    placeholder: "Name",
                    maxLength: 45,
                },
                {
                    type: "text",
                    name: "number",
                    label: "Number",
                    regex: regex.telephoneNumber,
                    regexError: regexError.telephoneNumber
                }
            ]
        }
    ]

    return (
        <GenericIndexPage
            title={"Mobiles"}
            urlString={"/mobile"}
            restType={"POST"}
            body={{}}
            columns={columns}
            searchable={true}
            permissions={[permissions.callGroups, permissions.admin, permissions.VHAdmin]}
            addModal={{
                displayName: "Mobile",
                settingsStages: modalSettings,
                submitData: (data) =>
                {
                    return [{
                        postData: {
                            "mobileDescription": data.description,
                            "mobileNo": data.number,
                        },
                        path: "/mobile",
                        type: "create"
                    }]
                }
            }}
            deleteModal={{
                displayName: "Mobile",
                uniqueIdentifier: "description",
                submitData: () =>
                {
                    return [{
                        path: `/mobile`,
                        displayName: "mobile",
                        type: "delete"
                    }]
                }
            }}
        />
    )
}