import React, { useEffect } from "react"
import { ChromePicker } from 'react-color';

export default function ColourPicker({
    name,
    value,
    onChange,
    placeholder
}: IColourPicker)
{

    function handleChange(val)
    {
        onChange({ target: { value: val, type: "colourPicker", name } })
    }

    return (
        <div className="flex-row flex-1">
            <ChromePicker
                header={placeholder}
                color={value}
                onChangeComplete={(col) => handleChange(col.hex)}
            />
        </div>
    )
}