import React, { forwardRef } from "react";
import Reaptcha from "reaptcha";

export const GoogleReCAPTCHA = forwardRef((
    {
        onChange,
    }: IGoogleReCAPTCHA, ref) =>
{
    return (
        <Reaptcha
            //@ts-ignore
            ref={ref}
            size={"normal"}
            sitekey={process.env.REACT_APP_CAPTCHA_KEY}
            onVerify={(e) => onChange(e)}
        />
    )
})