import { useContext, useEffect, useState } from "react"
import { useRequest } from "../../Hooks/useRequest"
import { useFormReducer } from "../../Hooks/useFormReducer"
import { SessionContext } from "../../Contexts/SessionContext"
import { ToastContext } from "../../Contexts/ToastContext"
import useFun from "../../Hooks/useAPIRequests"
import { regex } from "../../Constants/regex"
import { regexError } from "../../Constants/regexError"

export function useTelephoneNumbersFormPage(id: number)
{
    const { add } = useContext(ToastContext)
    const { getPromise } = useFun()
    const session = useContext(SessionContext)

    const [record, setRecord] = useState({})
    const [loading, setLoading] = useState(true)
    const [inUse, setInUse] = useState([])
    const { getRequest, putRequest } = useRequest()


    const {
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
    } = useFormReducer({ record })

    useEffect(() =>
    {
        async function pullData()
        {
            setLoading(true)
            let response = await getRequest(`/numbering/allocated/${id}`)
            let responseEMS = await getRequest(`/numbering/allocated/${id}/ems`)
            if (response && responseEMS)
            {
                setRecord({
                    allocatedNumbers: response,
                    allocatedNumbersEMS: responseEMS
                })
            } else
            {
                if (response)
                {
                    setRecord({
                        allocatedNumbers: response
                    })
                } else
                {
                    add({
                        text:
                            "Failed to retrieve Telephone Number Information.",
                        type: "error",
                    })
                }
                if (responseEMS)
                {
                    setRecord({ allocatedNumbersEMS: responseEMS })
                } else
                {
                    add({
                        text:
                            "Failed to retrieve Telephone Number EMS Information.",
                        type: "error",
                    })
                }
            }
            setLoading(false)
        }
        pullData()
    }, [id])

    const tabs: ITab[] = [
        {
            title: "Telephone Number Configuration",
            icon: "wrench",
            disabled: false,
            sections: [
                {
                    title: "General Settings",
                    fields: [
                        {
                            type: "info",
                            label: "Telephone Number",
                            name: "allocatedNumbers.telephoneNumber",
                        },
                        {
                            type: "info",
                            label: "Cancellation Available",
                            name: "allocatedNumbers.cancellationAvailable",
                            conditionallyHide: { checks: [{ field: "allocatedNumbers.cancellationAvailable", value: null }] },

                        },
                        {
                            type: "text",
                            label: "Nickname",
                            name: "allocatedNumbers.nickname",
                            maxLength: 15,
                            helptext:
                                "The nickname allows you to tag the number so you can identify what it is being used for.",
                        },
                        {
                            type: "select",
                            label: "Distinctive Ring",
                            name: "allocatedNumbers.distinctiveRing",
                            placeholder: "Distinctive Ring",
                            options: Array.from(Array(10).keys()).map(
                                (number) => ({
                                    value: `vh-ring-${number + 1}`,
                                    label: `Ringer ${number + 1}`,
                                })
                            ),
                            helptext:
                                "Distinctive Ringing allows handsets to play distinctive ringtones depending on the inbound telephone number called. This is helpful to identify call types such as internal or external calls.",
                        },
                        {
                            type: "select",
                            label: "Destinations",
                            name: "allocatedNumbers.destination",
                            placeholder: "Destination",
                            options: [
                                { label: "None - Reject Calls", value: "NONE" },
                                { label: "Conferencing", value: "CONFERENCING" },
                                { label: "Fax", value: "Fax" },
                            ],
                            loadOptions: () =>
                                getPromise({
                                    body: { highPriorityQueue: true },
                                    value: "destination",
                                    label: "name",
                                    url: "destinations",
                                }),
                            helptext:
                                "Select the destination where calls to this number should be sent.",
                        },
                    ],
                },
                {
                    title: "Caller ID",
                    conditionallyHide: { checks: [{ field: "allocatedNumbers.destination", value: "Fax" }] },
                    fields: [
                        {
                            type: "text",
                            label: "Prefix",
                            name: "allocatedNumbers.cidPrefix",
                            maxLength: 15,
                            helptext:
                                "Prefix the Caller ID with a text string to help identify the number or department called." +
                                "For example, if you have a sales line and an accounts line you can prefix each number with their department such as 'Sales' or 'Accounts'. The text is prepended to the telephone number and shown on your telephone's screen. " +
                                "Please note it is not possible to show this string when diverting calls to the PSTN as Caller ID names are not supported by the networks.",
                        },
                        {
                            type: "switch",
                            label: "Show Dialled Number",
                            name: "allocatedNumbers.cidCalledNumber",
                            helptext:
                                "Show the dialed telephone number as Caller ID on handsets. If disabled the callers telephone number will be shown.",
                        },
                        {
                            type: "switch",
                            label: "Dialled Number On Divert",
                            name: "allocatedNumbers.cidOnDivert",
                            helptext:
                                "Show the dialed telephone number as Caller ID on diverted calls. If disabled the callers telephone number will be shown.",
                        },
                    ],
                },
                {
                    title: "Call Screening",
                    conditionallyHide: { checks: [{ field: "allocatedNumbers.destination", value: "Fax" }] },
                    fields: [
                        {
                            type: "select",
                            label: "List",
                            placeholder: "None",
                            name: "allocatedNumbers.telephoneNumberAclId",
                            loadOptions: () =>
                                getPromise({
                                    body: { highPriorityQueue: false, sipTrunkMap: false },
                                    url: `accesscontrol`,
                                    label: "name",
                                    value: "id",
                                }),
                            options: [{ value: 0, label: "None" }]
                        },
                        {
                            type: "select",
                            label: "Action",
                            name: "allocatedNumbers.telephoneNumberAclReject",
                            options: [
                                { value: 1, label: "Reject" },
                                { value: 0, label: "Accept" },
                            ],
                            helptext:
                                "Reject/Accept incoming calls if the caller ID number appears within the selected list.",
                        },
                        {
                            type: "switch",
                            label: "Anonymous Call Reject",
                            name: "allocatedNumbers.anonCallReject",
                            helptext:
                                "Reject incoming calls if the caller ID is 'Anonymous' or 'Withheld'." +
                                "Any calls with the caller ID set to 'Unavailable' will not be rejected.",
                        },
                        {
                            type: "switch",
                            label: "Invalid Caller ID Reject",
                            name: "allocatedNumbers.anonCallRejectAdvanced",
                            helptext:
                                "Any calls with the caller ID set to 'Unavailable' will not be rejected." +
                                "This option does not reject 'Anonymous' or 'Withheld' calls unless the Network ID is invalid." +
                                "WARNING: This option is very aggressive and may block legitimate calls if the originating network is not setting the Network ID correctly.",
                        },
                    ],
                },
                {
                    title: "Call Recording",
                    conditionallyHide: { checks: [{ field: "allocatedNumbers.destination", value: "Fax" }] },
                    fields: [
                        {
                            type: "select",
                            label: "Enabled",
                            name: "allocatedNumbers.callRecord",
                            options: [
                                { value: 0, label: "None" },
                                { value: 1, label: "Enabled - Pausable" },
                                { value: 2, label: "Enabled - Non-Pausable" },
                            ],
                            helptext:
                                "Enable inbound call recording for this telephone number. Please note this is a chargeable service.",
                        },
                        {
                            type: "switch",
                            label: "Warn Caller",
                            name: "allocatedNumbers.callRecordWarn",
                            helptext:
                                "Warn the caller that the call is being recorded by playing 'This call may be monitored or recorded' before continuing.",
                        },
                    ],
                },
                {
                    title: "Fax",
                    conditionallyShow: { checks: [{ field: "allocatedNumbers.destination", value: "Fax" }] },
                    fields: [
                        {
                            type: "text",
                            label: "Contact Name",
                            name: "allocatedNumbers.faxContact"
                        },
                        {
                            type: "email",
                            label: "E-mail Address",
                            name: "allocatedNumbers.faxAddress",
                            regex: regex.email,
                            regexError: regexError.email
                        },
                        {
                            type: "text",
                            label: "PDF Password",
                            name: "allocatedNumbers.faxPassword",
                            maxLength: 15
                        }
                    ]
                }
            ],
        },
        {
            title: "Emergency Services Data",
            icon: "clinic-medical",
            disabled: !state.allocatedNumbers || (state.allocatedNumbers && !state.allocatedNumbers.emsNeeded),
            sections: [
                ...getErrors(),
                {
                    title: "Customer or Business Name",
                    fields: [
                        {
                            type: "text",
                            label: "Title",
                            name: "allocatedNumbersEMS.title",
                            disabled: state.allocatedNumbersEMS && state.allocatedNumbersEMS.updatePending,
                            maxLength: 20
                        },
                        {
                            type: "text",
                            label: "First Name",
                            name: "allocatedNumbersEMS.forename",
                            disabled: state.allocatedNumbersEMS && state.allocatedNumbersEMS.updatePending,
                            maxLength: 20
                        },
                        {
                            type: "text",
                            label: "Business/Last Name",
                            name: "allocatedNumbersEMS.name",
                            disabled: state.allocatedNumbersEMS && state.allocatedNumbersEMS.updatePending,
                            maxLength: 50
                        },
                        {
                            type: "text",
                            label: "Business Suffix",
                            name: "allocatedNumbersEMS.businessSuffix",
                            disabled: state.allocatedNumbersEMS && state.allocatedNumbersEMS.updatePending,
                            maxLength: 50
                        },
                    ],
                },
                {
                    title: "Physical Address",
                    fields: [
                        {
                            type: "text",
                            label: "Premises",
                            name: "allocatedNumbersEMS.premises",
                            disabled: state.allocatedNumbersEMS && state.allocatedNumbersEMS.updatePending,
                            maxLength: 60
                        },
                        {
                            type: "text",
                            label: "Thoroughfare",
                            name: "allocatedNumbersEMS.thoroughfare",
                            disabled: state.allocatedNumbersEMS && state.allocatedNumbersEMS.updatePending,
                            maxLength: 55
                        },
                        {
                            type: "text",
                            label: "Locality",
                            name: "allocatedNumbersEMS.locality",
                            disabled: state.allocatedNumbersEMS && state.allocatedNumbersEMS.updatePending,
                            maxLength: 30
                        },
                        {
                            type: "text",
                            label: "Postcode",
                            name: "allocatedNumbersEMS.postcode",
                            disabled: state.allocatedNumbersEMS && state.allocatedNumbersEMS.updatePending,
                            regex: regex.postcode,
                            regexError: regexError.postcode
                        },
                    ],
                },
                {
                    title: "Notes",
                    fullWidth: true,
                    fields: getNotes()
                },
            ],
        },
    ]

    function getErrors(): ISection[]
    {
        let errors: ISection[] = []
        let successfulErrorcodes = [33, 37, 38, 39, 40, 56, 61, 73, 0]

        if (state.allocatedNumbersEMS && state.allocatedNumbersEMS.errorMessage && !successfulErrorcodes.includes(state.allocatedNumbersEMS.errorCode))
        {
            errors.push({
                title: "Errors",
                fullWidth: true,
                fields: [{
                    type: "paragraph",
                    value: `ERROR: ${state.allocatedNumbersEMS.errorMessage}`,
                    colour: "red",
                    centred: true,
                    bold: true
                }]
            })
        }
        return errors
    }

    function getNotes(): IFormField[]
    {
        let notes: IFormField[] = [
            {
                type: "paragraph",
                value: "If you have seats in different physical locations but use the same outgoing caller ID on more than one seat, you must add additional numbers to the account and set the 'Emergency Services Addressing' option in each seat.",
            },
        ]

        if (state.allocatedNumbersEMS && state.allocatedNumbersEMS.updatePending)
        {
            notes.push(
                {
                    type: "paragraph",
                    value: "Update Pending: Please wait for address confirmation before submitting further updates.",
                    colour: "red"
                },
            )
        }
        return notes
    }

    function onDelete()
    {
        return [{
            path: "/numbering/allocated",
            type: "delete",
            indexPagePath: "/telephone-numbers",
            displayName: "Telephone Number",
        }]
    }

    async function getFailureMessage(response)
    {
        if (response.usages)
        {
            let inUseObject = []
            for (let inUse of response.usages)
            {
                inUseObject.push(` In use in ${inUse.type} ${inUse.name}`)
            }
            return inUseObject
        }
        else if (response.message)
        {
            return response.message
        }
        else
        {
            return "Unexpected error occured. Please try again or contact support."
        }
    }

    function onSubmit()
    {
        if (state.allocatedNumbers.emsNeeded && !state.allocatedNumbersEMS.updatePending)
        {
            const storedErrors = JSON.parse(localStorage.getItem("errors"))
            if (storedErrors && storedErrors["ems"])
            {
                delete storedErrors["ems"]
            }
            return [
                {
                    body: {
                        title: state.allocatedNumbersEMS.title,
                        forename: state.allocatedNumbersEMS.forename,
                        name: state.allocatedNumbersEMS.name,
                        businesssuffix:
                            state.allocatedNumbersEMS.businessSuffix,
                        premises: state.allocatedNumbersEMS.premises,
                        thoroughfare: state.allocatedNumbersEMS.thoroughfare,
                        locality: state.allocatedNumbersEMS.locality,
                        postcode: state.allocatedNumbersEMS.postcode,
                    },

                    path: `/numbering/allocated/${id}/ems`,
                },
                {
                    body: {
                        cidPrefix: state.allocatedNumbers.cidPrefix,
                        destination: state.allocatedNumbers.destination,
                        faxAddress: state.allocatedNumbers.faxAddress ? state.allocatedNumbers.faxAddress : null,
                        faxContact: state.allocatedNumbers.faxContact ? state.allocatedNumbers.faxContact : null,
                        faxPassword: state.allocatedNumbers.faxPassword ? state.allocatedNumbers.faxPassword : null,
                        cidOnDivert: !state.allocatedNumbers.cidOnDivert,
                        nickname: state.allocatedNumbers.nickname,
                        callRecord: state.allocatedNumbers.callRecord,
                        callRecordWarn: state.allocatedNumbers.callRecordWarn,
                        cidCalledNumber: state.allocatedNumbers.cidCalledNumber,
                        distinctiveRing: state.allocatedNumbers.distinctiveRing,
                        anonCallReject: state.allocatedNumbers.anonCallReject,
                        anonCallRejectAdvanced:
                            state.allocatedNumbers.anonCallRejectAdvanced,
                        telephoneNumberAclId:
                            state.allocatedNumbers.telephoneNumberAclId,
                        telephoneNumberAclReject:
                            state.allocatedNumbers.telephoneNumberAclReject
                    },
                    formPrefix: "allocatedNumbers",
                    path: `/numbering/allocated/${id}`,
                }
            ]
        }
        else
        {
            return [
                {
                    body: {
                        cidPrefix: state.allocatedNumbers.cidPrefix,
                        destination: state.allocatedNumbers.destination,
                        faxAddress: state.allocatedNumbers.faxAddress ? state.allocatedNumbers.faxAddress : null,
                        faxContact: state.allocatedNumbers.faxContact ? state.allocatedNumbers.faxContact : null,
                        faxPassword: state.allocatedNumbers.faxPassword ? state.allocatedNumbers.faxPassword : null,
                        cidOnDivert: !state.allocatedNumbers.cidOnDivert,
                        nickname: state.allocatedNumbers.nickname,
                        callRecord: state.allocatedNumbers.callRecord,
                        callRecordWarn: state.allocatedNumbers.callRecordWarn,
                        cidCalledNumber: state.allocatedNumbers.cidCalledNumber,
                        distinctiveRing: state.allocatedNumbers.distinctiveRing,
                        anonCallReject: state.allocatedNumbers.anonCallReject,
                        anonCallRejectAdvanced:
                            state.allocatedNumbers.anonCallRejectAdvanced,
                        telephoneNumberAclId:
                            state.allocatedNumbers.telephoneNumberAclId,
                        telephoneNumberAclReject:
                            state.allocatedNumbers.telephoneNumberAclReject
                    },
                    formPrefix: "allocatedNumbers",
                    path: `/numbering/allocated/${id}`,
                }
            ]
        }
    }

    return {
        loading,
        tabs,
        state,
        updateState,
        updateForm,
        updateErrors,
        onSubmit,
        onDelete,
        getFailureMessage,
        // changes,
        // resetChanges,
    }
}
