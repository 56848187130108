import React, { useEffect, useState } from "react";
import moment from "moment";
import TextInput from "./TextInput";

export default function DateInput({
    name,
    value,
    onChange,
}: IDateInput)
{
    const [selectedDate, setSelectedDate] = useState<String>(moment().format("YYYY-MM-DD"))

    useEffect(() =>
    {
        if (value)
        {
            const formattedDate = moment(value, "DD/MM/YYYY").format("YYYY-MM-DD")
            setSelectedDate(formattedDate)
        }
    }, [value])

    function handleInputChange(val)
    {

        setSelectedDate(val)

        const formattedDate = moment(val, "YYYY-MM-DD").format("DD/MM/YYYY")
        if (onChange)
        {
            onChange({
                target: {
                    name,
                    type: "time",
                    value: formattedDate
                }
            })
        }
    }

    return (
        <TextInput
            name={name}
            value={value ? moment(value, "DD/MM/YYYY").format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
            onChange={e => handleInputChange(e.target.value)}
            inputType={"date"}
        />
    )
}