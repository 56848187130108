import React from "react"
import PageLayout from "../PageLayout/PageLayout"
import ErrorPageText from "../ErrorPageText/ErrorPageText"
export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo)
    }

    render() {
        //@ts-ignore
        if (this.state.hasError) {
            return (
                <PageLayout>
                    <ErrorPageText
                        error={"Oh dear"}
                        text={"Something went wrong"}
                    />
                </PageLayout>
            )
        }

        return this.props.children
    }
}
