import React from "react"

export function Image({
    link,
    src,
    style,
    alt,
    openLinkInNewWindow
}: IImage)
{
    return (
        <a
            href={link}
            style={{ maxWidth: "100%", ...style }}
            target={openLinkInNewWindow && "_blank"}
        >
            <img
                style={{ maxWidth: "100%", ...style }}
                src={src}
                alt={alt}
            />
        </a>
    )
}