import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage";
import React, { useContext, useEffect, useState } from "react";
import { regex } from "../../Constants/regex";
import { regexError } from "../../Constants/regexError";
import { SessionContext } from "../../Contexts/SessionContext";
import { hasPermission, permissions } from "../../Constants/permissions";
import { EditGroupUsersModal } from "./EditGroupUsersModal";

export default function PhoneDirectoryEntryIndexPage({ prop }: any)
{

    const { directory } = prop.match.params
    const { state: sessionState } = useContext(SessionContext)
    const [open, setOpen] = useState(false);

    const columns: IColumn[] = [
        {
            name: "text",
            title: "Entry",
            link: { root: regex.extension.test(directory) || directory === 'central' ? `/phone-directory/${directory}` : `/phone-directory/groups/${directory}` },
            bold: true,
        },
    ]

    const modalSettings: ISettingsStage[] = [
        {
            title: "Entry",
            fields: [
                {
                    type: "text",
                    name: "name",
                    label: "Name",
                },
                {
                    type: "text",
                    name: "surname",
                    label: "Surname",
                    required: true
                },
                {
                    label: "Organisation",
                    type: "text",
                    name: "organisation",
                },
                {
                    type: "text",
                    name: "telephone",
                    label: "Telephone Number",
                    regex: regex.telephoneNumber,
                    regexError: regexError.telephoneNumber
                },
                {
                    type: "text",
                    name: "mobile",
                    label: "Mobile Number",
                    regex: regex.telephoneNumber,
                    regexError: regexError.telephoneNumber
                },
                {
                    type: "text",
                    name: "voipPhone",
                    label: "VoIP Phone",
                    regex: regex.telephoneNumber,
                    regexError: regexError.telephoneNumber
                },
                {
                    type: "text",
                    name: "voipExt",
                    label: "VoIP Ext",
                    regex: regex.extension,
                    regexError: "Please enter a valid VoIP Extension"
                }
            ]
        }
    ]

    function addByCSV(data)
    {
        const postData = new FormData()
        postData.append("file", data.file)
        postData.append("ignoreFirstLine", data.ignoreFirstLine)
        return {
            body: postData,
            path: `/ldap/${sessionState.session.account_no}/${directory}/csv`
        }
    }

    function buildDisplay(response)
    {
        let failures = []
        let successes = []
        let multiResponse = false
        let message = ""
        let failureTitle = "These entries have failed"
        let successTitle = ""
        if (response)
        {
            if (response.success)
            {
                if (response.failedEntries > 0)
                {
                    multiResponse = true
                    response.failures.forEach((entry) =>
                    {
                        failures = [
                            ...failures,
                            { id: entry.line, message: entry.reason },
                        ]
                    })
                } else
                {
                    message = "All Entries added successfully"
                }
            } else
            {
                message = "Something went wrong please try again"
            }
        }

        const feedback = {
            multiResponse: multiResponse,
            message: message,
            failures: failures,
            failureTitle: failureTitle,
            successTitle: successTitle,
            successes: successes,
        }

        return feedback
    }

    const fileUploadModal: IUploadFileModalSettings = {
        buttonProps: {
            variant: "success",
            iconLeft: "file-excel",
            color: "white",
            iconVariation: "regular",
            children: "CSV Upload",
            leftSide: true
        },
        maxWidth: 1200,
        multiResponse: true,
        customTitle: "Phone Directory CSV Upload",
        sectionTitle: "CSV Upload",
        previewColumns: [
            {
                name: "name",
                title: "Name",
            },
            {
                name: "surname",
                title: "Surname",
            },
            {
                name: "organisation",
                title: "Organisation",
            },
            {
                name: "telephoneNumber",
                title: "Telephone Number",
            },
            {
                name: "mobile",
                title: "Mobile",
            },
            {
                name: "voipNumber",
                title: "VoIP Number",
            },
            {
                name: "voipExt",
                title: "VoIP Ext",
            }
        ],
        fields: [
            {
                type: "fileUpload",
                name: "file",
                label: "Select File",
                fileType: "csv",
                required: true,
                accept: ["text/csv"],
            },
            {
                type: "switch",
                name: "ignoreFirstLine",
                label: "Ignore First Line?"
            },
            {
                type: "paragraph",
                value: "The CSV file used for importing directory entries should contain the following columns in this order: name, surname*, organisation, telephoneNumber, mobile, voipNumber, voipExt."
            },
            {
                type: "paragraph",
                value: "Please note: All valid entries in the CSV will create new entries in the directory and will NOT override already existing entries."
            },
            {
                type: "csvDownload",
                label: "Download",
                text: "Example Directory File",
                value: [
                    ["name", "surname", "organisation", "telephoneNumber", "mobile", "voipNumber", "voipExt"],
                    ["Directory", "Example 1", "OrgName", "01234567891", "07123456891", "01234567892", "200"],
                    ["Directory", "Example 2", "", "01234567895", "", "", "201"]
                ]
            }

        ],
        defineSubmitData: (data) =>
        {
            return new Promise(async (resolve) =>
            {
                const createData = await addByCSV(data)
                resolve(createData)
            })
        },
        displayResponse: (response) =>
        {
            return new Promise(async (resolve) =>
            {
                const createData = await buildDisplay(
                    response
                )
                resolve(createData)
            })
        }
    }

    const customModalButton: IButton =
    {
        variant: "warning",
        iconLeft: "pencil-alt",
        color: "white",
        iconVariation: "regular",
        children: "Edit Group Users",
        leftSide: true,
        onClick: () => setOpen(true)
    }

    return (
        <React.Fragment>
            <GenericIndexPage
                configIdentifier={regex.extension.test(directory) ? "Seat " + directory : directory}
                subIndex={true}
                specialBreadcrumbIndices={regex.extension.test(directory) || directory === 'central' ? null : [{ href: `phone-directory/groups`, text: `Groups` }]}
                title={"Hosted Phone Directory"}
                urlString={"/ldap/overview"}
                restType={"POST"}
                searchable={true}
                body={{ directory: directory }}
                columns={columns}
                customModalButton={!(regex.extension.test(directory) || directory === 'central') && hasPermission([permissions.ldap, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "update") && customModalButton}
                uploadFileModal={hasPermission([permissions.ldap, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "create") && fileUploadModal}
                permissions={[permissions.ldap, permissions.admin, permissions.VHAdmin]}
                addModal={{
                    displayName: "Directory Entry",
                    allowBulkAdd: false,
                    settingsStages: modalSettings,
                    submitData: (data) =>
                    {
                        return [{
                            postData: {
                                givenName: data.name,
                                surname: data.surname,
                                organisation: data.organisation,
                                telephone: data.telephone ? data.telephone : null,
                                mobile: data.mobile ? data.mobile : null,
                                voipPhone: data.voipPhone ? data.voipPhone : null,
                                voipExt: data.voipExt ? data.voipExt : null
                            },
                            path: `/ldap/${sessionState.session.account_no}/${directory}`,
                            type: "create",
                        }]
                    }
                }}
                deleteModal={{
                    displayName: "Directory Entry",
                    uniqueIdentifier: "text",
                    submitData: (data) =>
                    {
                        return [{
                            path: `/ldap/${sessionState.session.account_no}/${directory}`,
                            indentifier: `${data.entry}`,
                            displayName: "Directory Entry",
                            type: "delete"
                        }]
                    }
                }}
            />
            {open && <EditGroupUsersModal
                open={open}
                setOpen={setOpen}
                groupName={directory}
            />}
        </React.Fragment>
    )
}