import { useEffect, useRef, useState, useContext } from "react"
import { useRequest } from "../../Hooks/useRequest"
import useAPIRequests from "../../Hooks/useAPIRequests"
import { useFormReducer } from "../../Hooks/useFormReducer"
import { permissions } from "../../Constants/permissions"

export function useCallQueuesFormPage(id: number)
{
    const [record, setRecord] = useState({})
    const [loading, setLoading] = useState(true)
    const { getRequest } = useRequest()
    const tableRef = useRef()
    const {
        getDestinationPromise,
        getMusicOnHoldPromise,
        getPromptPromise,
    } = useAPIRequests()

    const {
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
    } = useFormReducer({ record })

    const columns: IColumn[] = [
        {
            name: "displayName",
            title: "Member",
        },
        {
            name: "penalty",
            title: "Penalty",
            formField: {
                type: "select",
                name: "penalty",
                maxWidth: 60,
                options: [
                    { value: 0, label: "0" },
                    { value: 1, label: "1" },
                    { value: 2, label: "2" },
                    { value: 3, label: "3" },
                    { value: 4, label: "4" },
                    { value: 5, label: "5" },
                    { value: 6, label: "6" },
                    { value: 7, label: "7" },
                    { value: 8, label: "8" },
                    { value: 9, label: "9" },
                ],
            },
        },
    ]

    async function pullData()
    {
        setLoading(true)
        let response = await getRequest(`/queue/${id}`)
        if (response)
        {
            response.queueJump =
                response.queueJump === null || response.queueJump == ""
                    ? "none"
                    : response.queueJump
            response.falloverLocation =
                response.falloverLocation === null ||
                    response.falloverLocation == ""
                    ? "none"
                    : response.falloverLocation
            response.falloverLocationEmpty =
                response.falloverLocationEmpty === null ||
                    response.falloverLocationEmpty == ""
                    ? "none"
                    : response.falloverLocationEmpty
            response.musicOnHold =
                response.musicOnHold === null || response.musicOnHold == ""
                    ? "none"
                    : response.musicOnHold
            setRecord(response)
        }
        setLoading(false)
    }

    useEffect(() =>
    {
        pullData()
        // eslint-disable-next-line
    }, [id])

    function checkIfMemberIsInQueue(
        fieldFrom
    ): Promise<{ success: boolean; message: string }>
    {
        return new Promise(async (resolve) =>
        {
            if (!fieldFrom)
            {
                resolve({ success: false, message: "Please select an option" })
            }

            let response = await getRequest(`/queue/members/` + id + `/-1/-1`)

            if (response)
            {
                if (response.records)
                {
                    const filteredRecords = response.records.filter((record) =>
                        fieldFrom.endsWith(record.memberName)
                    )

                    if (filteredRecords && filteredRecords.length > 0)
                    {
                        resolve({
                            success: false,
                            message:
                                "This member is already a part of this call queue",
                        })
                    } else
                    {
                        resolve({ success: true, message: "" })
                    }
                } else
                {
                    resolve({
                        success: false,
                        message: "Failed to retrieve data, please try again",
                    })
                }
            } else
            {
                resolve({
                    success: false,
                    message: "Failed to retrieve data, please try again",
                })
            }
        })
    }

    function submitData()
    {
        state.queueCidPrefix = state.queueCidPrefix ? state.queueCidPrefix : null
        state.falloverLocation =
            state.falloverLocation == "none" ? null : state.falloverLocation
        state.falloverLocationEmpty = 
            state.falloverLocationEmpty == "none" ? null : state.falloverLocationEmpty
        state.queueJump = state.queueJump == "none" ? null : state.queueJump
        state.musicOnHold = state.musicOnHold == "none" ? null : state.musicOnHold
        state.name = state.displayName

        return [
            {
                body: state,
                path: "/queue/" + id,
            },
        ]
    }



    function deleteData()
    {
        return [{
            path: "/queue",
            type: "delete",
            indexPagePath: "/call-queues",
            displayName: "Call Queue"
        }]
    }

    function editQueueMembers(data)
    {
        data.selectedRecords.forEach((record) =>
        {
            if (record.memberName.includes("*"))
            {
                record.memberName = "SIP/" + record.memberName
            } else if (record.memberName.includes("ST"))
            {
                record.memberName = "SIPTRUNK/" + record.memberName
            } else
            {
                record.memberName = "EXTERNALDEST/" + record.memberName.slice(3)
            }
        })

        return [{
            postData: {
                penalty: data.penalty,
            },
            path: "/queue/member",
            identifiers: ["memberName"],
            type: "edit",
            displayName: "displayName",
        }]
    }

    function saveTable(data)
    {
        return {
            id: data.id,
            penalty: data.penalty,
        }
    }

    const tabs: ITab[] = [
        {
            title: "General",
            icon: "toolbox",
            disabled: false,
            sections: [
                {
                    title: "Queue Settings",
                    spanTwoRows: true,
                    fields: [
                        {
                            type: "text",
                            label: "Name",
                            name: "customName",
                            placeholder: "Name",
                            maxLength: 45,
                            helptext: "Allows identification through the control panel."
                        },
                        {
                            type: "select",
                            label: "Maximum Queue Time",
                            name: "queueTime",
                            options: [
                                { value: 0, label: "Unlimited" },
                                { value: 10, label: "10 seconds" },
                                { value: 15, label: "15 seconds" },
                                { value: 20, label: "20 seconds" },
                                { value: 25, label: "25 seconds" },
                                { value: 30, label: "30 seconds" },
                                { value: 60, label: "1 min" },
                                { value: 120, label: "2 mins" },
                                { value: 180, label: "3 mins" },
                                { value: 240, label: "4 mins" },
                                { value: 300, label: "5 mins" },
                                { value: 600, label: "10 mins" },
                                { value: 1200, label: "20 mins" },
                                { value: 1800, label: "30 mins" },
                                { value: 3600, label: "1 hour" },
                                { value: 7200, label: "2 hours" },
                            ],
                            helptext: "The maximum time a caller will be held in this queue, after this time the caller will be routed to the Timeout Destination below."
                        },
                        {
                            type: "select",
                            label: "Maximum Queue Length",
                            name: "maxLength",
                            options: [
                                { value: 1, label: "1" },
                                { value: 2, label: "2" },
                                { value: 3, label: "3" },
                                { value: 4, label: "4" },
                                { value: 5, label: "5" },
                                { value: 6, label: "6" },
                                { value: 7, label: "7" },
                                { value: 8, label: "8" },
                                { value: 9, label: "9" },
                                { value: 10, label: "10" },
                                { value: 15, label: "15" },
                                { value: 20, label: "20" },
                                { value: 25, label: "25" },
                                { value: 30, label: "30" },
                                { value: 35, label: "35" },
                                { value: 40, label: "40" },
                                { value: 45, label: "45" },
                                { value: 50, label: "50" },
                                { value: 55, label: "55" },
                                { value: 60, label: "60" },
                                { value: 65, label: "65" },
                                { value: 70, label: "70" },
                            ],
                            helptext: "The maximum number of callers in this queue."
                        },
                        {
                            type: "switch",
                            label: "Queue Exit Time",
                            name: "exitSwitch",
                            helptext: "The queue exit time is the time in any given day that the queue will terminate any queuing callers irrespective of their queue time. Times are GMT."
                        },
                        {
                            type: "time",
                            label: "Set Queue Exit Time",
                            name: "exitTime",
                            conditionallyShow: { checks: [{ field: "exitSwitch", value: true }] },
                        },
                        {
                            type: "select",
                            label: "Time-out Destination",
                            name: "falloverLocation",
                            loadOptions: () =>
                                getDestinationPromise({
                                    highPriorityQueue: true,
                                    dialThrough: false,
                                    sipTrunkMap: false,
                                    excludeObjectRegex: "^QUEUE\\/" + state.accountNo + "\\*" + state.displayName + "|QUEUETRUNKMAP\\/" + + state.accountNo + "\\*" + state.displayName + "\\/[0-9]*$"
                                }),
                            options: [{ value: "none", label: "None" }],
                            helptext: "Where the caller will be routed when the maximum queue time is reached."
                        },
                        {
                            type: "switch",
                            label: "Enter Agent-less Queue",
                            name: "joinEmpty",
                            helptext: "If unticked the caller will be routed to the timeout destination if there are no dynamic agents logged into the queue.",
                        },
                        {
                            type: "select",
                            label: "Agent-less Queue Divert",
                            name: "falloverLocationEmpty",
                            loadOptions: () =>
                            getDestinationPromise({
                                highPriorityQueue: true,
                                dialThrough: false,
                                sipTrunkMap: false,
                                excludeObjectRegex: "^QUEUE\\/" + state.accountNo + "\\*" + state.displayName + "|QUEUETRUNKMAP\\/" + + state.accountNo + "\\*" + state.displayName + "\\/[0-9]*$"
                            }),
                            conditionallyHide: { checks: [{ field: "joinEmpty", value: true }] },
                            options: [{ value: "none", label: "None" }],
                            helptext: "If No Agents are within the queue, and Enter Agentless queue is disabled, the call will divert to this destination.",
                        },
                        {
                            type: "switch",
                            label: "Answered Elsewhere",
                            name: "answerElsewhere",
                        },
                    ],
                },
                {
                    title: "Welcome Message",
                    fields: [
                        {
                            type: "select",
                            label: "Prompt",
                            name: "welcomeId",
                            loadOptions: () => getPromptPromise(),
                            options: [
                                { value: -1, label: "None" },
                                { value: 0, label: "Default" },
                            ],
                            helptext: "A Custom Prompt that is played when callers enter the queue."
                        },
                    ],
                },
                {
                    title: "Music On Hold",
                    fields: [
                        {
                            type: "select",
                            label: "Type",
                            name: "musicOnHold",
                            loadOptions: () => getMusicOnHoldPromise(),
                            options: [
                                { value: "none", label: "None" },
                                { value: "default", label: "Default" }
                            ],
                            helptext: "Music played to callers waiting in the queue."
                        },
                    ],
                },
                {
                    title: "Queue Jump",
                    fields: [
                        {
                            type: "select",
                            label: "Destination",
                            name: "queueJump",
                            loadOptions: () =>
                                getDestinationPromise({
                                    dialThrough: false,
                                    highPriorityQueue: true,
                                    sipTrunkMap: false,
                                    excludeObjectRegex: "^QUEUE\\/" + state.accountNo + "\\*" + state.displayName + "|QUEUETRUNKMAP\\/" + + state.accountNo + "\\*" + state.displayName + "\\/[0-9]*$"
                                }),
                            options: [{ value: "none", label: "None" }],
                            helptext: "Allows a caller to press any digit on their phone and be routed to the destination set here, " +
                                "this could be used as an option to leave a voicemail or go to an answering service. " +
                                "If the destination is set to 'None', the feature is disabled"
                        },
                    ],
                },
            ],
        },
        {
            title: "Members",
            icon: "users",
            disabled: false,
            sections: [
                {
                    fullWidth: true,
                    fields: [
                        {
                            type: "table",
                            customPaginationName: "callQueueMembers",
                            columns: columns,
                            fieldRef: tableRef,
                            urlString: `/queue/members/` + id.toString(),
                            body: { sortBy: "displayName", direction: "ASC" },
                            pullData: pullData,
                            restType: "GET",
                            onSubmit: {
                                getData: saveTable,
                                path: "/queue/member",
                            },
                            permissions: [permissions.queues, permissions.admin, permissions.VHAdmin],
                            addModal: {
                                displayName: "Member",
                                allowBulkAdd: false,
                                fieldFrom: {
                                    field: "Member",
                                    label: "Member",
                                    type: "select",
                                    loadOptions: () =>
                                        getDestinationPromise({
                                            queues: false,
                                            voicemail: false,
                                            groups: false,
                                            ivrs: false,
                                            timeProfiles: false,
                                            dialThrough: false,
                                            queueTrunkMaps: false,
                                            extensionMap: false,
                                            highPriorityQueue: false,
                                            sipTrunkMap: false
                                        }),
                                },
                                settingsStages: [
                                    {
                                        title: "Settings",
                                        fields: [
                                            {
                                                type: "select",
                                                name: "penalty",
                                                label: "Penalty",
                                                options: [
                                                    { value: 0, label: "0" },
                                                    { value: 1, label: "1" },
                                                    { value: 2, label: "2" },
                                                    { value: 3, label: "3" },
                                                    { value: 4, label: "4" },
                                                    { value: 5, label: "5" },
                                                    { value: 6, label: "6" },
                                                    { value: 7, label: "7" },
                                                    { value: 8, label: "8" },
                                                    { value: 9, label: "9" },
                                                ],
                                                defaultValue: 0,
                                            },
                                        ],
                                    },
                                ],
                                availabilityCheck: (fieldFrom, fieldTo) =>
                                {
                                    return new Promise(async (resolve) =>
                                    {
                                        const responseMessage = await checkIfMemberIsInQueue(
                                            fieldFrom
                                        )

                                        if (
                                            responseMessage &&
                                            responseMessage.success
                                        )
                                        {
                                            resolve({ success: true })
                                        } else
                                        {
                                            resolve({
                                                success: false,
                                                error: responseMessage.message,
                                            })
                                        }
                                    })
                                },
                                submitData: (data) =>
                                {
                                    return [{
                                        postData: {
                                            penalty: data.penalty,
                                        },
                                        path: "/queue/member/" + id,
                                        identifier: "memberName",
                                        type: "create",
                                        displayName: data.fromField,
                                    }]
                                },
                            },
                            editModal: {
                                displayName: "Member",
                                uniqueIdentifier: "displayName",
                                settingsStages: [
                                    {
                                        title: "Settings",
                                        fields: [
                                            {
                                                type: "select",
                                                name: "penalty",
                                                label: "Penalty",
                                                options: [
                                                    { value: 0, label: "0" },
                                                    { value: 1, label: "1" },
                                                    { value: 2, label: "2" },
                                                    { value: 3, label: "3" },
                                                    { value: 4, label: "4" },
                                                    { value: 5, label: "5" },
                                                    { value: 6, label: "6" },
                                                    { value: 7, label: "7" },
                                                    { value: 8, label: "8" },
                                                    { value: 9, label: "9" },
                                                ],
                                                defaultValue: 0,
                                            },
                                        ],
                                    },
                                ],
                                submitData: (data) =>
                                {
                                    return editQueueMembers(data)
                                },
                            },
                            deleteModal: {
                                displayName: "Member",
                                uniqueIdentifier: "displayName",
                                submitData: () =>
                                {
                                    return [{
                                        path: "/queue/member",
                                        displayName: "displayName",
                                        type: "delete",
                                    }]
                                },
                            },
                            helptext: "Add/edit/delete endpoints to receive calls from this queue. " +
                                "To log in and out of this queue using your SIP phone to answer calls, please dial 120*<queueID> to log in, and  121*<queueID> to log out."
                        },
                    ],
                },
            ],
        },
        {
            title: "Member Settings",
            icon: "user",
            disabled: false,
            sections: [
                {
                    title: "Ring Settings",
                    fields: [
                        {
                            type: "select",
                            label: "Ring Strategy",
                            name: "strategy",
                            options: [
                                { value: "ringall", label: "Ring All" },
                                { value: "leastrecent", label: "Least Recent" },
                                { value: "fewestcalls", label: "Fewest Calls" },
                                { value: "random", label: "Random" },
                                { value: "roundrobin", label: "Round Robin" },
                                { value: "rrmemory", label: "Round Robin (Memory)" },
                                { value: "linear", label: "Round Robin (Linear)" },
                            ],
                            helptext: "This sets how the agents are to be rung from this queue; the default is Ring All. "
                        },
                        {
                            helptext: "Ring All: Ring all agents."
                        },
                        {
                            helptext: "Least Recent: Ring the agent which was least recently dialled from this queue. "
                        },
                        {
                            helptext: "Fewest Calls: Ring the agent with the fewest completed calls from this queue."
                        },
                        {
                            helptext: "Random: Ring a random agent."
                        },
                        {
                            helptext: "Round Robin: Ring agents in order listed in the queue configuration."
                        },
                        {
                            type: "select",
                            label: "Ring Time",
                            name: "timeout",
                            options: [
                                { value: 10, label: "10 sec" },
                                { value: 15, label: "15 sec" },
                                { value: 20, label: "20 sec" },
                                { value: 25, label: "25 sec" },
                                { value: 30, label: "30 sec" },
                                { value: 35, label: "35 sec" },
                                { value: 40, label: "40 sec" },
                            ],
                            helptext: "How long agents ring for on each pass."
                        },
                        {
                            type: "select",
                            label: "Retry Agents Every",
                            name: "retry",
                            options: [
                                { value: 5, label: "5 sec" },
                                { value: 10, label: "10 sec" },
                                { value: 20, label: "20 sec" },
                                { value: 30, label: "30 sec" },
                                { value: 60, label: "1 min" },
                                { value: 120, label: "2 mins" },
                                { value: 180, label: "3 mins" },
                                { value: 240, label: "4 mins" },
                                { value: 300, label: "5 mins" },
                            ],
                            helptext: "Breathe period if the call wasn't answered in that pass."
                        },
                        {
                            type: "select",
                            label: "Wrap-up Time",
                            name: "wrapUpTime",
                            options: [
                                { value: 0, label: "None" },
                                { value: 5, label: "5 sec" },
                                { value: 10, label: "10 sec" },
                                { value: 15, label: "15 sec" },
                                { value: 20, label: "20 sec" },
                                { value: 25, label: "25 sec" },
                                { value: 30, label: "30 sec" },
                                { value: 35, label: "35 sec" },
                                { value: 40, label: "40 sec" },
                                { value: 45, label: "45 sec" },
                                { value: 50, label: "50 sec" },
                                { value: 55, label: "55 sec" },
                                { value: 60, label: "1 min" },
                                { value: 120, label: "2 mins" },
                                { value: 180, label: "3 mins" },
                                { value: 240, label: "4 mins" },
                                { value: 300, label: "5 mins" },
                            ],
                            helptext: "Period of time after the agent ends their call from the queue before that agent rings again."
                        },
                    ],
                },
                {
                    title: "Report To Agent",
                    fields: [
                        {
                            label: "Report Hold Time",
                            type: "switch",
                            name: "reportHoldTime",
                            helptext: "Reports only to agent how long caller waited in the queue."
                        },
                        {
                            type: "select",
                            label: "Queue Identification",
                            name: "announceId",
                            loadOptions: () => getPromptPromise(),
                            options: [
                                { value: -1, label: "None" },
                                { value: -2, label: "Support" },
                                { value: -3, label: "Sales" },
                                { value: -4, label: "Accounting" },
                                { value: -5, label: "Purchasing" }
                            ],
                            helptext: "Reports only to agent the Custom Prompt selected here. Examples include: Support, Sales, Accounts, Purchasing."
                        },
                    ],
                },
            ],
        },
        {
            title: "Sounds",
            icon: "volume-up",
            disabled: false,
            sections: [
                {
                    title: "Caller Announcements",
                    fields: [
                        {
                            label: "Average Hold Time",
                            type: "switch",
                            name: "announceHoldTime",
                            helptext: "Announce the estimated hold time of the queue to the incoming caller. The hold time is based on the average time taken to answer previous calls."
                        },
                        {
                            label: "Position/Time Frequency",
                            type: "select",
                            name: "announceFrequency",
                            options: [
                                { value: 0, label: "Disabled" },
                                { value: 30, label: "30 sec" },
                                { value: 60, label: "1 min" },
                                { value: 120, label: "2 mins" },
                                { value: 180, label: "3 mins" },
                                { value: 240, label: "4 mins" },
                                { value: 300, label: "5 mins" },
                            ],
                            helptext: "How often to announce the queue position and call time to the caller."
                        },
                        {
                            label: "Periodic Announce",
                            type: "select",
                            name: "periodicAnnounceId",
                            loadOptions: () => getPromptPromise(),
                            options: [{ value: -1, label: "None" }],
                            helptext: "Custom Prompt to be periodically played to the caller."
                        },
                        {
                            label: "Periodic Frequency",
                            type: "select",
                            name: "periodicAnnounceFrequency",
                            options: [
                                { value: 0, label: "Disabled" },
                                { value: 30, label: "30 sec" },
                                { value: 60, label: "1 min" },
                                { value: 90, label: "1:30 mins" },
                                { value: 120, label: "2 mins" },
                            ],
                            helptext: "How often this prompt is played."
                        },
                        {
                            label: "Overflow Message",
                            type: "select",
                            name: "queueFullMessageId",
                            loadOptions: () => getPromptPromise(),
                            options: [{ value: -1, label: "None" }],
                            helptext: "Custom Prompt that plays when the maximum number of callers is reached"
                        },
                    ],
                },
            ],
        },
    ]

    return {
        loading,
        tabs,
        state,
        submitData,
        deleteData,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        tableRef,
        // resetChanges,
    }
}
