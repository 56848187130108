import React from "react"
import Button from "../Button"
import "./form.scss"

interface IProps
{
    onCancel?: () => void
    onDelete?: () => void
    onSubmit?: (any) => void
    customButtons?: IButton[]
    submitDisabled?: boolean
    submitText?: string
    submitVariant?: 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light'
    | 'link'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-success'
    | 'outline-danger'
    | 'outline-warning'
    | 'outline-info'
    | 'outline-dark'
    | 'outline-light'
    loading?: boolean
    deleteText?: String
}

export function FormButtons({
    onDelete,
    onCancel,
    onSubmit,
    customButtons,
    submitDisabled,
    submitText,
    submitVariant,
    deleteText,
    loading
}: IProps)
{
    return (
        <div className="flex-row button-container justify-end">
            {onDelete && (
                <Button
                    variant="outline-danger"
                    onClick={onDelete}
                    style={{ marginLeft: 4 }}
                >
                    {deleteText ? deleteText : "Delete"}
                </Button>
            )}
            {onCancel && (
                <Button
                    variant="outline-primary"
                    onClick={onCancel}
                    style={{ marginLeft: 4 }}
                >
                    Cancel
                </Button>
            )}
            {customButtons && (
                customButtons.map(customAction =>
                    <Button
                        {...customAction}
                        style={{
                            marginLeft: 4,
                        }}
                    >
                        {customAction.buttonText}
                    </Button>
                )
            )}
            {onSubmit && (
                <Button
                    disabled={submitDisabled}
                    onClick={onSubmit}
                    style={{ marginLeft: 4 }}
                    loading={loading}
                    variant={submitVariant ? submitVariant : "primary"}
                >
                    {submitText ? submitText : "Save"}
                </Button>
            )}
        </div>
    )
}
