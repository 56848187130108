import React, { useState } from 'react'
import IdleTimerComp from 'react-idle-timer'
import LogoutModal from "../LogoutModal/LogoutModal"

export default function IdleTimer()
{
    const [open, setOpen] = useState(false)
    const [idle, setIdle] = useState(null)

    return (
        <div>
            <IdleTimerComp
                ref={ref => { setIdle(ref) }}
                element={document}
                onIdle={() => setOpen(true)}
                debounce={250}
                timeout={1000 * 60 * 60}
            />
            <LogoutModal
                open={open}
                setOpen={setOpen}
            />
        </div>
    )

}