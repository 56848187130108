import { useContext, useEffect, useState } from "react"
import { SessionContext } from "../../Contexts/SessionContext"
import { useFormReducer } from "../../Hooks/useFormReducer"
import { useRequest } from "../../Hooks/useRequest"
import React from "react"
import renderHTML from "react-render-html"
import Button from "../../components/Button"
import ProgressBar from "@ramonak/react-progress-bar";
import { ThemeContext } from "../../Contexts/ThemeContext"

export function useBundlesFormPage()
{
    const [loading, setLoading] = useState(true)
    const { getRequest, postRequest } = useRequest()
    const { accentColour } = useContext(ThemeContext)

    const {
        state,
        updateState,
        updateForm,
        updateErrors
    } = useFormReducer({
        record: {
            bundles: [],
            hasDirectDebit: false,
            usage: []
        }
    })

    const [enableDisableModalOpen, setEnableDisableModalOpen] = useState(false)
    const [action, setAction] = useState<"enable" | "disable">()
    const [selectedBundle, setSelectedBundle] = useState<IBundle>()
    const { state: sessionState } = useContext(SessionContext)
    const [trunk, setTrunk] = useState()
    const [seat, setSeat] = useState()

    async function pullData()
    {
        setLoading(true)

        let bundles = null
        let hasDD = false
        let usage = null

        const postData = {
        }

        let trunkResponse = await postRequest(postData, `/trunk/-1/-1/`)
        if (trunkResponse && trunkResponse.records)
        {
            let channels = 0;
            let i = 0;
            while (i < trunkResponse.records.length)
            {

                if (trunkResponse.records[i].options.channelLimit > 0)
                {
                    channels += trunkResponse.records[i].options.channelLimit
                }
                i++
            }
            trunkResponse.totalRecords = channels;
            setTrunk(trunkResponse.totalRecords)
        }

        let seatResponse = await postRequest(postData, `/seat/-1/-1/`)
        if (seatResponse && seatResponse.records)
        {
            setSeat(seatResponse.totalRecords)
        }

        let ddResponse = await getRequest(`/billing/directdebit`)
        if (ddResponse && ddResponse?.status === "Approved")
        {
            hasDD = true
        }

        let response = await getRequest(`/bundle/list`)
        if (response && response.records)
        {
            bundles = response.records
        }

        let usageResponse = await getRequest(`/bundle/usage`)
        if (usageResponse && usageResponse.records)
        {
            usage = usageResponse.records
        }

        updateState({
            hasDirectDebit: hasDD,
            bundles: bundles,
            usage: usage
        })
        setLoading(false)
    }

    useEffect(() =>
    {
        pullData()
        // eslint-disable-next-line
    }, [])

    type IBundle = {
        id: string
        type: "trunk" | "seat"
        name: string
        description: string
        contract: string
        seconds: string
        active: boolean
    }

    function getAccordionBody(active: boolean, bundle: IBundle)
    {
        if (!active)
        {
            return <div>
                {renderHTML(bundle.description)}
                <p style={{ fontWeight: "bolder" }}>{bundle.contract} Months</p>
                <Button
                    onClick={() =>
                    {
                        setAction("enable")
                        setSelectedBundle(bundle)
                        setEnableDisableModalOpen(true)
                    }}
                >
                    Apply
                </Button>

            </div>
        }
        else
        {
            let usage = state.usage.find((usage) => usage.bundle_id === bundle.id)
            let usageText = ""
            let numericalUsage = usage ? Number((100 - (usage.remaining / usage.total) * 100).toFixed(0)) : 0;

            if (usage)
            {
                usageText = `${((usage.total - usage.remaining) / 60).toFixed(2)} mins / ${(usage.total / 60).toFixed(2)} mins (${(100 - (usage.remaining / usage.total) * 100).toFixed(2)}%)`
            }
            return <div style={{ width: "100%", display: "flex", gap: "10px", flexDirection: "column" }}>
                {renderHTML(bundle.description)}
                <div title={usageText}>
                    {<p style={{ fontWeight: "bolder" }}>Usage:</p> &&
                        <ProgressBar
                            bgColor={"#6a1b9a"}
                            completed={numericalUsage}
                            animateOnRender={true}
                            labelAlignment={numericalUsage > 5 ? "right" : numericalUsage >= 1 && numericalUsage <= 5 ? "outside" : "left"}
                            labelColor={numericalUsage > 5 ? "#FFFFFF" : "#000000"}
                        />}
                </div>
                <Button
                    variant="danger"
                    style={{ maxWidth: "64px" }}
                    onClick={() =>
                    {
                        setAction("disable")
                        setSelectedBundle(bundle)
                        setEnableDisableModalOpen(true)
                    }}
                >
                    Disable
                </Button>
            </div>
        }
    }

    function getSections(): ISection[]
    {
        let seatBundles = state.bundles?.filter((bundle) => bundle.type === "seat" && bundle.active)
        let seatActive = true
        if (!(seatBundles?.length > 0))
        {
            seatBundles = state.bundles?.filter((bundle) => bundle.type === "seat")
            seatActive = false
        }

        let sipBundles = state.bundles?.filter((bundle) => bundle.type === "trunk" && bundle.active)
        let sipActive = true
        if (!(sipBundles?.length > 0))
        {
            sipBundles = state.bundles?.filter((bundle) => bundle.type === "trunk")
            sipActive = false
        }

        return [
            {
                title: `Seat Bundle${seatActive ? " (Active)" : "s"}`,
                fields: seatBundles ? seatBundles.map((bundle) =>
                {
                    return {
                        type: "accordion",
                        title: bundle.name,
                        content: getAccordionBody(seatActive, bundle),
                        expanded: seatActive
                    }
                }) : []
            },
            {
                title: `Trunk Bundle${sipActive ? " (Active)" : "s"}`,
                fields: sipBundles ? sipBundles.map((bundle) =>
                {
                    return {
                        type: "accordion",
                        title: bundle.name,
                        content: getAccordionBody(sipActive, bundle),
                        expanded: sipActive
                    }
                }) : []
            },
            {
                title: "Existing Contracts",
                fullWidth: true,
                fields: [
                    {
                        type: "table",
                        customPaginationName: "bundleExtensions",
                        columns: [
                            {
                                title: "Endpoint",
                                name: "endpoint",
                            },
                            {
                                title: "Bundle Name",
                                name: "bundleName",
                            },
                            {
                                title: "Channels",
                                name: "channels",
                            },
                            {
                                title: "Activated",
                                name: "activation"
                            },
                            {
                                title: "Ceased",
                                name: "ceased"
                            },
                            {
                                title: "Months Left",
                                name: "monthsRemaining",
                            }
                        ],
                        restType: "GET",
                        urlString: `/bundle/${sessionState.session.account_no}/extensions`,
                    },
                ],
            },
        ]
    }


    let tabs: ITab[] = [
        {
            title: "General",
            icon: "toolbox",
            sections: getSections()
        },
    ]

    return {
        loading,
        tabs,
        state,
        updateState,
        updateForm,
        updateErrors,
        selectedBundle,
        action,
        enableDisableModalOpen,
        setEnableDisableModalOpen,
        pullData,
        trunk,
        seat
    }
}
