import {useMediaQuery} from "@material-ui/core";
import React, {useEffect, useState} from "react";


export default function GridLayout({children, breakWidth}: any) {
    const isBreak = useMediaQuery(`(max-width:${breakWidth ? breakWidth : "940"}px)`)
    const [gridRowsString, setGridRowsString] = useState("")

    useEffect(() => {
        let rowString = ""
        let currentRow = 1
        let otherCount = 0
        children && children.length > 0 && children.forEach((child, index) => {

            rowString += `[row${currentRow}-start] auto ${index === children.length - 1 ? `[row${currentRow}-end]` : ""}`

            otherCount++

            if (otherCount === 1) {
                currentRow++
                otherCount = 0
            }
        })
        setGridRowsString(rowString)
    }, [children])

    let currentColumn = 1
    let currentRow = 1
    let pushRow = null
    let pushColumn = null

    function handleColumnAndRow(spanTwoRows, spanThreeRows, spanTwoColumns) {

        if (spanTwoColumns && currentColumn === 2) {
            currentRow++
            currentColumn = 1
        }

        if (pushColumn === 2) {
            pushColumn = 1
        } else if (pushColumn === 1) {
            pushColumn = null
        }

        if (pushRow === 4) {
            pushRow = 3
        } else if (pushRow === 3) {
            pushRow = 1
        } else if (pushRow === 2) {
            pushRow = 1
        } else if (pushRow === 1) {
            pushRow = null
        }

        let column = `col${currentColumn}-start`

        if (spanTwoColumns) {
            column += " / span 2"
            if (currentColumn === 1) {
                pushColumn = 1
            }

        }

        let row = `row${currentRow}-start`

        if (spanTwoRows) {
            row += " / span 2"
            pushRow = 2
        }

        if (spanThreeRows) {
            row += " / span 3"
            pushRow = 4
        }


        if (currentColumn === 2) {
            currentRow++
            currentColumn = 1
            if (pushRow === 3 || pushRow === 1) {
                currentColumn++
            }
        } else {
            if (pushRow !== 1 && pushRow !== 3) {
                currentColumn++
            }
            if (pushColumn === 1) {
                currentColumn = 1
                currentRow++
            }
        }

        return {column, row}
    }

    return (
        <div
            style={{
                maxWidth: "100%",
                display: !isBreak ? "grid" : "flex",
                flexDirection: "column",
                gridGap: 16,
                gridTemplateColumns: "[col1-start] calc(50% - 8px) [col2-start] calc(50% - 8px) [col2-end]",
                gridTemplateRows: gridRowsString,
            }}
        >
            {children && children.length > 0 && children.filter(Boolean).map((child) => {
                const {spanTwoRows, spanThreeRows, spanTwoColumns, children} = child.props

                const {column, row} = handleColumnAndRow(spanTwoRows, spanThreeRows, spanTwoColumns)

                return (
                    <div
                        style={{
                            width: isBreak && "100%",
                            marginBottom: isBreak && 16,
                            gridColumn: column,
                            gridRow: row,
                        }}>
                        {children}
                    </div>
                )
            })}
        </div>
    )
}