import React, { useContext, useEffect } from "react";
import { useRequest } from "../../Hooks/useRequest";
import queryString from 'query-string';
import { ToastContext } from "../../Contexts/ToastContext";
import { useHistory } from "react-router-dom";
import { TabbedCardFormPage } from "../../components/Form/TabbedCardFormPage";
import { useFormReducer } from "../../Hooks/useFormReducer";

export default function HubspotCallbackPage({ prop }: any)
{
    const { postRequest } = useRequest()
    const { add } = useContext(ToastContext)
    const record = { info: "Intergrating with hubspot please wait..." }
    const history = useHistory()
    const {
        state,
        updateForm,
        updateErrors
    } = useFormReducer({ record })

    useEffect(() =>
    {

        (async () =>
        {
            const values = queryString.parse(prop.location.search)
            if (values?.code)
            {
                const response = await postRequest(
                    {
                        "code": values.code,
                        "redirectURI": `https://cp.voicehost.co.uk/hubspotcallback`
                        // "redirectURI": `https://cp3.dev.voicehost.co.uk/hubspotcallback`
                        // "redirectURI": `http://localhost:3000/hubspotcallback`
                    },
                    "/hubspot/token")

                if (response?.success)
                {
                    add({
                        type: "success",
                        text: "Connected to Hubspot"
                    })
                }
                else
                {
                    add({
                        type: "error",
                        text: "Unable to connect to Hubspot, please contact support"
                    })
                }
            }
            else
            {
                add({
                    type: "error",
                    text: "Unable to connect to Hubspot, please contact support"
                })
            }
            history.push("/integrations")
        })()
    }, [])

    const tabs: ITab[] = [
        {
            title: "Hubspot",
            icon: "hubspot",
            disabled: false,
            sections: [
                {
                    fields: [
                        {
                            type: "info",
                            name: "make",
                            value: state.info,
                        }
                    ]
                }
            ],
        },
    ]

    return (
        <React.Fragment>
            <TabbedCardFormPage
                tabs={tabs}
                loading={false}
                state={state}
                updateForm={updateForm}
                updateErrors={updateErrors}
            />
        </React.Fragment>

    )
}