import { useContext, useEffect, useState } from "react"
import { SessionContext } from "../Contexts/SessionContext"

function arrayBufferToBase64(buffer)
{
    let binary = ""
    const bytes = [].slice.call(new Uint8Array(buffer))

    bytes.forEach((b) => (binary += String.fromCharCode(b)))

    return window.btoa(binary)
}

export function useRequest()
{
    const baseURL = process.env.NODE_ENV === "production"
        ? "https://beta.api.voicehost.io/v3"
        : process.env.NODE_ENV === "development" && "http://localhost:8080/voicehostapiv3/v3"

    const { state } = useContext(SessionContext)

    const [headers, setHeaders] = useState(
        new Headers({
            Authorization: state && state.token && `Bearer ${state.token}`,
            "Content-Type": "application/json",
        })
    )

    useEffect(() =>
    {
        setHeaders(
            new Headers({
                Authorization: state && state.token && `Bearer ${state.token}`,
                "Content-Type": "application/json",
            })
        )
    }, [state && state.token])

    async function getRequestWithToken(path, token): Promise<any>
    {
        const obj: any = {
            method: "GET",
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            }),
        }
        return fetch(baseURL + path, obj)
            .then(async (resp) =>
            {
                return resp.json()
            })
            .catch((error) => console.error(error))
    }

    async function getRequestBase64Conversion(path, type): Promise<any>
    {
        const obj: any = {
            method: "GET",
            headers,
        }

        return fetch(baseURL + path, obj)
            .then(async (resp) =>
            {
                return resp.arrayBuffer().then((buffer) =>
                {
                    const base64Flag = `data:${type};base64,`
                    const audioStr = arrayBufferToBase64(buffer)

                    return base64Flag + audioStr
                })
            })
            .catch((error) => console.error(error))
    }

    async function postRequestBase64Conversion(postData, path, type): Promise<any>
    {
        const obj: any = {
            method: "POST",
            headers,
            body: JSON.stringify(postData)
        }

        return fetch(baseURL + path, obj)
            .then(async (resp) =>
            {
                return resp.arrayBuffer().then((buffer) =>
                {
                    const base64Flag = `data:${type};base64,`
                    const audioStr = arrayBufferToBase64(buffer)

                    return base64Flag + audioStr
                })
            })
            .catch((error) => console.error(error))
    }

    async function getRequestAsImageWithToken(path, token): Promise<any>
    {
        const obj: any = {
            method: "GET",
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            }),
        }

        return fetch(baseURL + path, obj)
            .then(async (resp) =>
            {
                return resp.arrayBuffer().then((buffer) =>
                {
                    const base64Flag = "data:image/jpeg;base64,"
                    const imageStr = arrayBufferToBase64(buffer)

                    if (imageStr)
                    {
                        return base64Flag + imageStr
                    } else
                    {
                        return "error"
                    }
                })
            })
            .catch((error) => console.error(error))
    }

    async function getRequest(path): Promise<any>
    {
        const obj: any = {
            method: "GET",
            headers,
        }
        return fetch(baseURL + path, obj)
            .then(async (resp) =>
            {
                return resp.json()
            })
            .catch((error) => console.error(error))
    }

    async function postRequestAsString(postData, path): Promise<any>
    {
        const obj: any = {
            method: "POST",
            headers,
            body: JSON.stringify(postData),
        }

        return fetch(baseURL + path, obj)
            .then(async (resp) =>
            {
                return resp.arrayBuffer().then((buffer) =>
                {
                    let str = new TextDecoder("utf-8").decode(buffer)

                    return str
                })
            })
            .catch((error) => console.error(error))
    }

    async function getRequestAsString(path): Promise<any>
    {
        const obj: any = {
            method: "GET",
            headers,
        }

        return fetch(baseURL + path, obj)
            .then(async (resp) =>
            {
                return resp.arrayBuffer().then((buffer) =>
                {
                    let str = new TextDecoder("utf-8").decode(buffer)

                    return str
                })
            })
            .catch((error) => console.error(error))
    }

    function postRequest(postData, path)
    {
        const obj: any = {
            method: "POST",
            headers,
            body: JSON.stringify(postData),
        }

        return fetch(baseURL + path, obj)
            .then(async (resp) =>
            {
                return resp.json()
            })
            .catch((error) => console.error(error))
    }

    function postRequestWithToken(postData, path, token)
    {
        const obj: any = {
            method: "POST",
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(postData),
        }

        return fetch(baseURL + path, obj)
            .then(async (resp) =>
            {
                return resp.json()
            })
            .catch((error) => console.error(error))
    }

    function postMultiPartRequest(postData, path)
    {
        const obj: any = {
            method: "POST",
            headers: new Headers({
                Authorization: state && state.token && `Bearer ${state.token}`,
            }),
            body: postData,
        }

        return fetch(baseURL + path, obj)
            .then(async (resp) =>
            {
                return resp.json()
            })
            .catch((error) => console.error(error))
    }

    function putRequest(putData, path): Promise<any>
    {
        const obj: any = {
            method: "PUT",
            headers,
            body: JSON.stringify(putData, (key, value) =>
            {
                if (value !== null) return value
            }),
        }

        return fetch(baseURL + path, obj)
            .then(async (resp) =>
            {
                return resp.json()
            })
            .catch((error) => console.error(error))
    }

    function patchRequest(patchData, path)
    {
        const obj: any = {
            method: "PATCH",
            headers,
            body: JSON.stringify(patchData),
        }

        return fetch(baseURL + path, obj)
            .then(async (resp) =>
            {
                return resp.json()
            })
            .catch((error) => console.error(error))
    }

    function deleteRequest(deleteData, path)
    {
        const obj: any = {
            method: "DELETE",
            headers,
            body: deleteData ? JSON.stringify(deleteData) : null
        }

        return fetch(baseURL + path, obj)
            .then(async (resp) =>
            {
                return resp.json()
            })
            .catch((error) => console.error(error))
    }

    async function deleteRequestWithToken(path, token): Promise<any>
    {
        const obj: any = {
            method: "DELETE",
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            }),
        }
        return fetch(baseURL + path, obj)
            .then(async (resp) =>
            {
                return resp.json()
            })
            .catch((error) => console.error(error))
    }

    return {
        getRequestWithToken,
        getRequestAsImageWithToken,
        getRequest,
        getRequestAsString,
        getRequestBase64Conversion,
        postRequest,
        postRequestWithToken,
        postRequestAsString,
        postMultiPartRequest,
        postRequestBase64Conversion,
        putRequest,
        patchRequest,
        deleteRequest,
        deleteRequestWithToken,
    }
}