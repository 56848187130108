import React from "react";

export function Info({ value, capitalise, nullValue, bold, textColour }: IInfoField)
{

    function getValue()
    {
        if (value)
        {
            if (capitalise)
            {
                return value.toUpperCase()
            } else
                return value
        } else
        {
            return nullValue
        }
    }

    return (
        <div className="flex-column">
            <span style={{ fontWeight: bold && 500, color: textColour && textColour }}>
                {getValue()}
            </span>
        </div>
    )
}