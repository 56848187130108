import { useHistory } from "react-router-dom";
import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage";
import React, { useContext, useState } from "react";
import { SessionContext } from "../../Contexts/SessionContext";
import { hasPermission, permissions } from "../../Constants/permissions";

export default function MusicOnHoldIndexPage()
{
    const history = useHistory()
    const { state: sessionState } = useContext(SessionContext)

    const columns: IColumn[] = [
        {
            title: "Name",
            onClick: id =>
            {
                history.push(`/music-on-hold/${id}`)
            },
            bold: true,
            name: "name",
            sortBy: true,
        },
        {
            title: "Used In",
            name: "count",
            sortBy: true,
        }
    ]

    const conditionalReadOnly: IConditionalReadOnly = {
        field: "accountNo",
        value: 0
    }

    const fileUploadModal: IUploadFileModalSettings = {
        buttonProps: {
            variant: "success",
            iconLeft: "file-audio",
            color: "white",
            iconVariation: "regular",
            children: "Upload",
            leftSide: true
        },
        multiResponse: false,
        customTitle:
            "Please upload an MP3 file",
        sectionTitle: "File Upload",
        fields: [
            {
                type: "paragraph",
                value: "To upload a Music on Hold file, select the file below and then click upload. Upload time depends on the size of the file being uploaded. Only MP3 files can be uploaded."
            },
            {
                type: "fileUpload",
                name: "musicFile",
                label: "Music On Hold",
                required: true,
                fileType: "mp3/wav",
                accept: ["audio/mpeg"],
                maxSizeBytes: 10 * 1000 * 1000, //10MB
            },
            {
                type: "paragraph",
                value: `I understand and agree that; by using this service any sound files uploaded to the network are neither examined nor moderated. I will only use what the UK law considers to be legal and decent, and fall within the Terms and Conditions of the service. I also understand the use of copyrighted music as defined by the Copyright, Designs and Patents Act requires a licence from the <a target="_blank" href=https://www.prsformusic.com />Performing Rights Society (PRS)</a> and <a target="_blank" href=https://www.ppluk.com />Phonographic Performance Limited (PPL)</a>`,
            },
        ],
        defineSubmitData: (data) =>
        {
            return new Promise(async (resolve) =>
            {
                const createData = await defineData(data)
                resolve(createData)
            })
        },
        displayResponse: (response) =>
        {
            return new Promise(async (resolve) =>
            {
                const createData = await buildDisplay(
                    response
                )
                resolve(createData)
            })
        },
    }

    function checkIfInUse(selectedRecords)
    {
        let error = false
        let message = "One or more of the music on hold is in use, unable to delete"
        selectedRecords.forEach(record =>
        {
            if (record.count > 0)
            {
                error = true
            }
        });

        let retData = {
            error: error,
            message: message
        }

        return (retData)
    }

    function defineData(data)
    {
        const postData = new FormData()
        postData.append("musicFile", data.musicFile)

        return {
            body: postData,
            path: "/musiconhold"
        }
    }

    function buildDisplay(response)
    {
        let failures = []
        let successes = []
        let multiResponse = false
        let message = ""
        let failureTitle = ""
        let successTitle = ""

        if (response && response.success)
        {
            message = "Music on hold added successfully"
        }
        else if (response && response.message)
        {
            message = response.message
        }
        else
        {
            message = "Something went wrong, please try again"
        }

        const feedback = {
            multiResponse: multiResponse,
            message: message,
            failures: failures,
            failureTitle: failureTitle,
            successTitle: successTitle,
            successes: successes,
        }

        return feedback
    }

    return (
        <GenericIndexPage
            title={"Music On Hold"}
            urlString={"/musiconhold/overview"}
            columns={columns}
            searchable={true}
            restType={"POST"}
            conditionalReadOnly={conditionalReadOnly}
            permissions={[permissions.musicOnHold, permissions.admin, permissions.VHAdmin]}
            deleteModal={{
                displayName: "Music On Hold",
                uniqueIdentifier: "name",
                submitData: data =>
                {
                    return [{
                        path: "/musiconhold",
                        type: "delete"
                    }]
                },
                deleteCheck: data =>
                {
                    return new Promise(async resolve =>
                    {
                        const deleteData = await checkIfInUse(data)
                        resolve(deleteData)
                    })
                }
            }}
            uploadFileModal={hasPermission([permissions.musicOnHold, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "create") && fileUploadModal}
            helpSections={[
                {
                    title: "Music On Hold",
                    text: "Configure Music On Hold for your account."
                }
            ]}

        />

    )
}