import React, { createContext, useEffect, useReducer } from "react"
import { useRequest } from "../Hooks/useRequest"
import IdleTimer from "../components/IdleTimer/IdleTimer"
import { loginDotUrl } from "../Common/urlHelpers"
type ISession = {
    state: {
        token?: string
        avatar?: string
        themeLogo?: string
        session?: {
            user_id?: string
            permissions?: {
                [key: string]: number
            }
            username?: string
            account_no?: string
            parent?: string
            puppeteer?: number
        }
        theme?: any,
        account?: any
        notifications?: any[]
        errors?: any
    }
}

const sessionReducer = (state, action) =>
{
    const { payload, type } = action

    switch (type)
    {
        case "SET_SESSION":
            return { ...state, session: payload }
        case "SET_AVATAR":
            return { ...state, avatar: payload }
        case "SET_ACCOUNT":
            return { ...state, account: payload }
        case "SET_THEME_LOGO":
            return { ...state, themeLogo: payload }
        case "SET_THEME":
            return { ...state, theme: payload }
        case "SET_NOTIFICATIONS":
            return { ...state, notifications: payload }
        case "SET_TOKEN":
            return { ...state, token: payload }
        case "SET_ERRORS":
            return { ...state, errors: payload }

        default:
            return { ...state }
    }
}

const SessionContext = createContext<ISession>({
    state: {
        token: null,
        avatar: "",
        themeLogo: "",
        session: {},
        account: {},
        theme: {},
        notifications: [],
        errors: [],
    },
})

const SessionProvider = (props) =>
{
    const { getRequestWithToken, postRequest, getRequest, getRequestAsImageWithToken, postRequestWithToken } = useRequest()

    const [state, dispatch] = useReducer(sessionReducer, {
        token: null,
        avatar: "",
        themeLogo: "",
        session: {},
        account: {},
        theme: {},
        notifications: [],
        errors: [],
    })

    async function getInitialSessionData()
    {
        let tempToken
        let sessionResponse

        if (process.env.NODE_ENV === "development")
        {
            const mySessionToken = process.env.REACT_APP_TOKEN
            const mySessionResponse = await getRequestWithToken(
                "/users/auth/session",
                mySessionToken
            )
            if (mySessionResponse && mySessionResponse.status !== "error")
            {
                tempToken = mySessionToken
                sessionResponse = mySessionResponse
                dispatch({
                    type: "SET_TOKEN",
                    payload: mySessionToken,
                })
            }
        } else if (process.env.NODE_ENV === "production")
        {
            const cookies = document.cookie
            const regex = /VHAUTHTOKEN=([^\s;]*)/g

            let matches = cookies.match(regex)

            if (matches)
            {
                const tokens = matches.map((match) =>
                    match.replace("VHAUTHTOKEN=", "")
                )

                for (let token of tokens)
                {
                    if (
                        !sessionResponse ||
                        (sessionResponse &&
                            sessionResponse.status === "error")
                    )
                    {
                        const multiSessionResponse = await getRequestWithToken(
                            "/users/auth/session",
                            token
                        )
                        if (
                            multiSessionResponse &&
                            multiSessionResponse.status !== "error"
                        )
                        {
                            tempToken = token
                            sessionResponse = multiSessionResponse
                            dispatch({
                                type: "SET_TOKEN",
                                payload: token,
                            })
                        }
                    }
                }
            }
        }

        if (sessionResponse && sessionResponse.status !== "error")
        {
            dispatch({
                type: "SET_SESSION",
                payload: sessionResponse,
            })
            const accountResponse = await getRequestWithToken(
                `/account/${sessionResponse.account_no}`,
                tempToken
            )
            if (accountResponse)
            {
                dispatch({
                    type: "SET_ACCOUNT",
                    payload: accountResponse,
                })
            }
        } else if (process.env.NODE_ENV === "production")
        {
            window.location.href = loginDotUrl(true)
        }
        // const notificationsResponse = await getRequestWithToken(
        //     "/notifications",
        //     tempToken
        // )
        // if (notificationsResponse)
        // {
        //     if (
        //         notificationsResponse &&
        //         notificationsResponse.filter(
        //             (notification) => !notification.notification_read
        //         ).length
        //     )
        //     {
        //         dispatch({
        //             type: "SET_NOTIFICATIONS",
        //             payload: notificationsResponse.filter(
        //                 (notification) => !notification.notification_read
        //             ),
        //         })
        //     }
        // }

        if (sessionResponse)
        {
            const themeLogoResponse = await getRequestAsImageWithToken(
                `/theme/${sessionResponse.account_no}/logo`,
                tempToken
            )
            if (themeLogoResponse && themeLogoResponse !== "error")
            {
                dispatch({
                    type: "SET_THEME_LOGO",
                    payload: themeLogoResponse,
                })
            }

            const themeResponse = await getRequestWithToken(
                `/theme/${sessionResponse.account_no}`,
                tempToken
            )
            if (themeResponse && themeResponse !== "error")
            {
                dispatch({
                    type: "SET_THEME",
                    payload: themeResponse,
                })
            }

            const avatarResponse = await getRequestAsImageWithToken(
                "/users/avatar",
                tempToken
            )
            if (avatarResponse && avatarResponse !== "error")
            {
                dispatch({
                    type: "SET_AVATAR",
                    payload: avatarResponse,
                })
            }
        }

        let errorsToBeChecked = [
            {
                name: "ems",
                restType: "post",
                body: {},
                url: "/numbering/allocated/ems/errors/0/0"
            }
        ]

        await Promise.all(errorsToBeChecked.map(async (check) =>
        {
            let response
            if (check.restType === "post")
            {
                response = await postRequestWithToken(check.body, check.url, tempToken)
            }
            else if (check.restType === "get")
            {
                response = await getRequestWithToken(check.url, tempToken)
            }

            if (response && response.totalRecords > 0)
            {
                dispatch({
                    type: "SET_ERRORS",
                    payload: [
                        ...response.records.map(record => (
                            {
                                type: "ems",
                                ...record
                            }
                        ))
                    ],
                })
            }
        }))
    }

    useEffect(() =>
    {
        if (!state.session.account_no && !window.location.href.includes("registration")
            && !window.location.href.includes("AOT"))
        {
            getInitialSessionData()
        }
    }, [state.session])

    return (
        <SessionContext.Provider value={{ state }}>
            {process.env.NODE_ENV === "production" && !window.location.href.includes("registration")
                && !window.location.href.includes("AOT") && <IdleTimer />}
            {props.children}
        </SessionContext.Provider>
    )
}

export { SessionContext, SessionProvider }
