import React, { useState, useEffect } from "react";
import variablesScss from "../../Constants/variables.scss";
import Icon from "../Icons/Icon";

export default function FileUpload({
    name,
    value,
    fileName,
    onChange,
    fileType,
    required,
    setError,
    accept,
    maxSizeBytes,
}: IFileUpload)
{
    const [tempError, setTempError] = useState<{ target: { name?: string; value?: any } }>()
    const [files, setFiles] = useState([])

    useEffect(() =>
    {
        if (value)
        {
            setFiles([value])
        }
    }, [value])

    function formatFileSize(sizeInBytes)
    {
        if (sizeInBytes >= 0 && sizeInBytes < 1000)
        {
            return `${sizeInBytes} bytes`
        }
        if (sizeInBytes >= 1e4 && sizeInBytes < 1e6)
        {
            return `${Math.trunc(sizeInBytes / 1e3)}KB`
        }
        else if (sizeInBytes >= 1e6 && sizeInBytes < 1e9)
        {
            return `${Math.trunc(sizeInBytes / 1e6)}MB`
        }
        else
        {
            return `${Math.trunc(sizeInBytes / 1e9)}GB`
        }
    }

    function onChangeHandler(e)
    {
        onChange({
            target: {
                type: "file",
                name,
                value: null
            }
        })
        if (!e.target.files && !required)
        {
            setTempError({ target: { ...e.target, name, value: "" } })
            setError && setError({ target: { ...e.target, name, value: "" } })
        } else if (!e.target.files && required)
        {
            setTempError({ target: { ...e.target, name, value: "This is a required field" } })
        }
        else if (e.target.files)
        {
            let allowUpload = true

            if (accept)
            {
                Array.from(e.target.files).forEach((file: any) =>
                {
                    if (!accept.find(allowed => allowed === file.type || allowed === "text/csv" && file.name.includes(".csv")))
                    {
                        allowUpload = false
                        setTempError({ target: { ...e.target, name, value: "Invalid file format" } })
                        setError && setError({ target: { ...e.target, name, value: "Invalid file format" } })
                    }
                })
            }
            if (maxSizeBytes)
            {
                Array.from(e.target.files).forEach((file: any) =>
                {

                    if (file.size > maxSizeBytes)
                    {
                        allowUpload = false
                        const errorString = `File size must not exceed ${formatFileSize(maxSizeBytes)}, this file is ${formatFileSize(file.size)}`
                        setTempError({ target: { ...e.target, name, value: errorString } })
                        setError && setError({ target: { ...e.target, name, value: errorString } })
                    }
                })
            }
            if (allowUpload)
            {
                setFiles(e.target.files)
                if (e.target.files[0])
                {
                    setTempError({ target: { ...e.target, name, value: "" } })
                    setError && setError({ target: { ...e.target, name, value: "" } })
                    onChange({
                        target: {
                            type: "file",
                            name,
                            value: e.target.files[0]
                        }
                    })
                }
            }
        }
    }

    return (
        <div className="flex-row flex-1">
            <input
                id="files"
                accept={accept && accept.join(", ")}
                style={{ display: "none" }}
                type="file"
                name="file"
                onChange={onChangeHandler}
            />
            <label
                htmlFor="files"
                style={{
                    backgroundColor: variablesScss.primaryColor,
                    padding: 24,
                    height: 48,
                    borderRadius: 24,
                    color: "white",
                    margin: 0,
                    cursor: "pointer",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex"
                }}
            >
                Choose file

                <Icon
                    icon={"arrow-alt-circle-up"}
                    size={"lg"}
                    style={{ marginLeft: 8 }}
                />
            </label>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginLeft: 8
                }}
            >
                {(files && files.length > 0) ? Array.from(files).map(file => (
                    <div style={{ flex: 1, alignItems: "center", display: "flex" }}>{file.name}</div>
                )) : (
                    <div style={{ flex: 1, alignItems: "center", display: "flex" }}>No file chosen</div>
                )}
            </div>
        </div>
    )
}