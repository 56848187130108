import React from "react"
import { useRequest } from "../../Hooks/useRequest"
import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage"
import { useHistory } from "react-router-dom"
import useAPIRequests from "../../Hooks/useAPIRequests"
import { permissions } from "../../Constants/permissions";

export default function SeatsIndexPage()
{
    const history = useHistory()
    const { postRequest, getRequest } = useRequest()
    const { getDestinationPromise, getMusicOnHoldPromise } = useAPIRequests()

    async function getLatestCallGroup()
    {
        const response = await postRequest({}, "/callgroup/next")

        if (response)
        {
            return Number(response)
        }
    }

    const columns: IColumn[] = [
        {
            name: "groupNo",
            title: "Group",

            bold: true,
            onClick: (id) =>
            {
                history.push(`/call-groups/${id}`)
            },
            sortBy: true,
        },
        { name: "customName", title: "Name", sortBy: true },
    ]

    const modalSettings: ISettingsStage[] = [
        {
            title: "Settings",
            fields: [
                {
                    type: "text",
                    name: "customName",
                    label: "Name",
                    required: true,
                    maxLength: 45,
                },
                {
                    type: "select",
                    name: "ringType",
                    label: "Ring Type",
                    options: [
                        { value: 1, label: "Ring All" },
                        { value: 2, label: "Round Robin" },
                    ],
                    defaultValue: 1
                },
                {
                    type: "select",
                    label: "Ring Time",
                    name: "ringTime",
                    options: [
                        { value: 10, label: "10 seconds" },
                        { value: 20, label: "20 seconds" },
                        { value: 30, label: "30 seconds" },
                        { value: 40, label: "40 seconds" },
                        { value: 50, label: "50 seconds" },
                    ],
                    defaultValue: 10
                },
                {
                    type: "select",
                    label: "Music On Hold",
                    name: "musicOnHold",
                    loadOptions: () => getMusicOnHoldPromise(),
                    options: [{ value: "default", label: "Default" }],
                    defaultValue: "default"
                },
                {
                    type: "select",
                    label: "Fallover Location",
                    name: "falloverLocation",
                    required: true,
                    loadOptions: () =>
                        getDestinationPromise({
                            highPriorityQueue: true,
                            sipTrunkMap: false,
                        }),
                    options: [{ value: "none", label: "None" }],
                    defaultValue: "none"
                },
                {
                    type: "switch",
                    label: "Answered Elsewhere",
                    name: "answerElsewhere",
                },
                {
                    type: "switch",
                    label: "Ignore Call Forward",
                    name: "ignoreCfwd",
                },
            ],
        },
    ]

    const editModalSettings: ISettingsStage[] = [
        {
            title: "Settings",
            fields: [
                {
                    type: "select",
                    name: "ringType",
                    label: "Ring Type",
                    options: [
                        { value: 1, label: "Ring All" },
                        { value: 2, label: "Round Robin" },
                    ],
                },
                {
                    type: "select",
                    label: "Ring Time",
                    name: "ringTime",
                    options: [
                        { value: 10, label: "10 seconds" },
                        { value: 20, label: "20 seconds" },
                        { value: 30, label: "30 seconds" },
                        { value: 40, label: "40 seconds" },
                        { value: 50, label: "50 seconds" },
                    ],
                },
                {
                    type: "select",
                    label: "Music On Hold",
                    name: "musicOnHold",
                    loadOptions: () => getMusicOnHoldPromise(),
                    options: [{ value: "default", label: "Default" }],
                },
                {
                    type: "select",
                    label: "Fallover Location",
                    name: "falloverLocation",
                    loadOptions: () =>
                        getDestinationPromise({
                            highPriorityQueue: true,
                            sipTrunkMap: false,
                        }),
                    options: [{ value: "none", label: "None" }],
                },
                {
                    type: "switch",
                    label: "Answered Elsewhere",
                    name: "answerElsewhere",
                },
                {
                    type: "switch",
                    label: "Ignore Call Forward",
                    name: "ignoreCfwd",
                },
            ],
        },
    ]

    function checkGroupTaken(
        fieldFrom,
        fieldTo
    ): Promise<{ success: boolean; message: string }>
    {
        return new Promise(async (resolve) =>
        {
            let response = await postRequest({}, `/callgroup/-1/-1`)

            if (response)
            {
                if (response.records)
                {
                    const filteredRecords = response.records.filter(
                        (record) =>
                            record.groupNo === Number(fieldFrom) ||
                            (Number(fieldFrom) <= record.groupNo &&
                                record.groupNo <= Number(fieldTo))
                    )

                    if (filteredRecords && filteredRecords.length > 0)
                    {
                        if (!fieldTo)
                        {
                            resolve({
                                success: false,
                                message:
                                    "Group number is already in use. Please select a different number",
                            })
                        } else
                        {
                            resolve({
                                success: false,
                                message:
                                    "1 or more of the groups already has the number in this selected range. Please check the range and try again",
                            })
                        }
                    } else
                    {
                        resolve({ success: true, message: "" })
                    }
                } else
                {
                    resolve({
                        success: false,
                        message: "Failed to retrieve data, please try again",
                    })
                }
            } else
            {
                resolve({
                    success: false,
                    message: "Failed to retrieve data, please try again",
                })
            }
        })
    }

    return (
        <GenericIndexPage
            title={"Call Groups"}
            urlString={"/callgroup"}
            permissions={[permissions.callGroups, permissions.admin, permissions.VHAdmin]}
            restType={"POST"}
            body={{ sortBy: "group", direction: "ASC" }}
            columns={columns}
            searchable={true}
            addModal={{
                displayName: "Call Group",
                uniqueIdentifier: "customName",
                fieldFrom: {
                    field: "Group",
                    label: "Group",
                    type: "number",
                    min: 200,
                    max: 998,
                    loadDefaultValue: getLatestCallGroup,
                },
                settingsStages: modalSettings,
                availabilityCheck: (fieldFrom, fieldTo) =>
                {
                    return new Promise(async (resolve) =>
                    {
                        if (
                            Number(fieldFrom) < 200 ||
                            Number(fieldFrom) > 998 ||
                            (fieldTo &&
                                (Number(fieldTo) < 200 || fieldTo > 998))
                        )
                        {
                            resolve({
                                success: false,
                                error: "Please input a valid number",
                            })
                        }

                        if (fieldTo && fieldTo - fieldFrom > 10)
                        {
                            resolve({
                                success: false,
                                error:
                                    "You cannot add more than 10 call groups at one time, please contact your account manager to add more",
                            })
                        }

                        if (fieldTo && fieldTo <= fieldFrom)
                        {
                            resolve({
                                success: false,
                                error:
                                    "Please ensure this number is less than the To field",
                            })
                        }

                        const responseMessage = await checkGroupTaken(
                            fieldFrom,
                            fieldTo
                        )

                        if (responseMessage && responseMessage.success)
                        {
                            resolve({ success: true })
                        } else
                        {
                            resolve({
                                success: false,
                                error: responseMessage.message,
                            })
                        }
                    })
                },
                submitData: (data) =>
                {
                    return [{
                        postData: {
                            customName: data.customName,
                            ringType: data.ringType,
                            ringTime: data.ringTime,
                            musicOnHold: data.musicOnHold,
                            falloverLocation:
                                data.falloverLocation === "none" ? null : data.falloverLocation,
                            answerElsewhere: data.answerElsewhere,
                            ignoreCfwd: data.ignoreCfwd
                        },
                        path: "/callgroup",
                        identifier: "groupNo",
                        type: "create",
                        displayName: data.fromField,
                        bulkIncrement: "customName"
                    }]
                },
            }}
            editModal={{
                displayName: "Call Group",
                settingsStages: editModalSettings,
                uniqueIdentifier: "customName",
                loadRecords: [{
                    url: "/callgroup",
                }],
                submitData: (data) =>
                {
                    return [{
                        postData: {
                            ringType: data.ringType,
                            ringTime: data.ringTime,
                            musicOnHold: data.musicOnHold,
                            falloverLocation:
                                data.falloverLocation === "none" ? null : data.falloverLocation,
                            answerElsewhere: data.answerElsewhere,
                            ignoreCfwd: data.ignoreCfwd
                        },
                        path: "/callgroup",
                        type: "edit",
                        displayName: "groupNo",
                    }]
                },
            }}
            deleteModal={{
                displayName: "Call Group",
                uniqueIdentifier: "groupNo",
                submitData: () =>
                {
                    return [{
                        path: "/callgroup",
                        displayName: "groupNo",
                        type: "delete",
                    }]
                },
            }}
            helpSections={[
                {
                    title: "Call Groups",
                    text: "Allows you to group functions together so calls trigger multiple elements of your setup."
                }
            ]}
        />
    )
}
