import { createStyles, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import IOSSwitch from "react-ios-switch";
import { ThemeContext } from "../../Contexts/ThemeContext";
import Button from "../Button";
import "./formInputs.scss";

export function Switch({
    value,
    disabled,
    onChange,
    name,
    hint,
    nullable,
}: ISwitch)
{
    const { accentColour } = useContext(ThemeContext)

    const useStyles = makeStyles((theme) =>
        createStyles({
            switch: {
                "& span": {
                    backgroundColor: `${accentColour} !important`,
                    border: `1px solid ${accentColour} !important`,
                    boxShadow: `${accentColour} 0px 0px 0px 20px inset !important`,
                    "& span": {
                        backgroundColor: "#fff !important",
                        boxShadow: "none !important",
                        border: "none !important"
                    }
                },
            }
        })
    );

    const classes = useStyles();
    const options: {
        value: any, label: string, selectedVariant: IButtonVariant, unSelectedVariant: IButtonVariant
    }[] = [
            {
                label: "No",
                value: false,
                selectedVariant: "danger",
                unSelectedVariant: "outline-danger",
            },
            {
                label: "Yes",
                value: true,
                selectedVariant: "success",
                unSelectedVariant: "outline-success",
            },
        ]

    return (
        <div
            className={`switch`}
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}>
            {!nullable ? (
                <div className={value && classes.switch}>
                    <IOSSwitch
                        name={name}
                        disabled={disabled}
                        onChange={(val) =>
                            onChange({
                                target: {
                                    value: val,
                                    type: "checkbox",
                                    name: name,
                                },
                            })
                        }
                        checked={Boolean(value)}
                    />
                </div>
            ) : (
                    <>
                        {options.map((option) => (
                            <Button
                                style={{ marginRight: 8 }}
                                iconLeft={
                                    option.value === value
                                        ? "check-square"
                                        : "square"
                                }
                                size={"sm"}
                                variant={
                                    option.value === value
                                        ? option.selectedVariant
                                        : option.unSelectedVariant
                                }
                                iconVariation={
                                    option.value === value ? "solid" : "regular"
                                }
                                onClick={() =>
                                {
                                    if (option.value === value)
                                    {
                                        onChange({
                                            target: {
                                                value: null,
                                                name,
                                                type: "radio",
                                            },
                                        })
                                    } else
                                    {
                                        onChange({
                                            target: {
                                                value: option.value,
                                                name,
                                                type: "radio",
                                            },
                                        })
                                    }
                                }}>
                                {option.label}
                            </Button>
                        ))}
                    </>
                )}
            {hint && (
                <div
                    style={{
                        marginLeft: 8,
                        fontSize: "0.9em",
                    }}>
                    {hint}
                </div>
            )}
        </div>
    )
}
