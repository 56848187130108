import React from "react"
import "./dropdownMenu.scss"
import { useHandleOutsideClick } from "../../Hooks/useHandleOutsideClick"

interface IProps
{
    open?: boolean
    setOpen: (open: boolean) => void
    IconComponent: React.ComponentType
    MenuComponent?: any
    iconClassName?: string
    dropdownClassName?: string
    borderRadius?: number
    right?: number
    width?: number
}

export function DropdownMenu({
    open,
    setOpen,
    IconComponent,
    MenuComponent,
    iconClassName,
    dropdownClassName,
    borderRadius,
    right,
    width
}: IProps)
{
    const node = useHandleOutsideClick(open, setOpen)

    return (
        <div className={iconClassName && iconClassName} ref={node}>
            <div
                onClick={() =>
                {
                    if (!open)
                    {
                        setOpen(true)
                    } else
                    {
                        setOpen(false)
                    }
                }}
            >
                <IconComponent />
            </div>
            {MenuComponent && open && (
                <div
                    className={`dropdownComponent ${dropdownClassName ? dropdownClassName : ""}`}
                    style={{ borderRadius, right, width }}
                >
                    <MenuComponent />
                </div>
            )}
        </div>
    )
}
