import React, { useEffect, useState } from "react"
import PageLayout from "../../components/PageLayout/PageLayout"
import DashboardSummaryGrid from "../../components/DashboardWidgets/DashboardSummaryGrid";
import DashboardErrors from "../../components/DashboardWidgets/DashboardErrors";
import { useRequest } from "../../Hooks/useRequest";
import { useContext } from "react";
import { SessionContext } from "../../Contexts/SessionContext";

export default function DashboardPage()
{
    const { postRequest } = useRequest()
    const [hasErrors, setHasErrors] = useState(false)
    const { state: session } = useContext(SessionContext)


    useEffect(() =>
    {
        async function getErrors()
        {
            let response = await postRequest({ "accountNo": session.session.account_no }, "/numbering/allocated/ems/errors/-1/-1")
            setHasErrors(response && response.totalRecords > 0)
        }
        getErrors()
    }, [])

    return (
        <PageLayout
            helpSections={[
                {
                    title: "Welcome to helpDot:",
                    text: "While you're traversing the portal click on this tab if any help is required, information and tips on how to get the best out of this portal, for you."
                },
                {
                    title: "",
                    text: "With unique content for every page, helpDOT is here to help!"
                },
                {
                    title: "",
                    text: "This page contains a summary of all the call routing items that you have in your account"
                }
            ]}
        >
            <DashboardSummaryGrid fullWidth={!hasErrors} />
            {hasErrors && <DashboardErrors />}
        </PageLayout>
    )
}