import React, { useEffect, useState } from "react"
import moment from "moment"
import { FormField } from "../Form/FormField"
import Slider from "../Slider/Slider"
import { SizeMe } from "react-sizeme"
import Button from "../Button"

export default function TimeSlider({ name, value, onChange, nullable }: ITimeSlider)
{
    const [numberValue, setNumberValue] = useState([540, 1050])
    const [error, setError] = useState([])
    const [isNull, setIsNull] = useState(nullable)

    useEffect(() =>
    {
        if (!value || !value[1])
        {
            setNumberValue([540, 1050])
            changeFunction(["09:00", "17:30"])
        } else
        {
            setNumberValue([
                stringTimeToNumber(value[0]),
                stringTimeToNumber(value[1]),
            ])
        }
    }, [value])

    function changeFunction(val)
    {
        let valueToSet = val

        const momentValue0 = moment(val[0], "HH:mm")
        const momentValue1 = moment(val[1], "HH:mm")

        if (momentValue0.isAfter(momentValue1))
        {
            valueToSet = [val[1], val[0]]
        }

        onChange({
            target: {
                type: "timeSlider",
                value: valueToSet,
                name,
            },
        })
    }

    function numberToStringTime(val)
    {
        if (isNaN(val))
        {
            return "00:00"
        }

        const dividedNumber = val / 60

        const hours = Math.floor(dividedNumber)

        let hoursString = String(hours)
        if (hoursString.length === 1)
        {
            hoursString = 0 + hoursString
        }

        const minutes = Math.round((dividedNumber % 1) * 60)

        let minutesString = String(minutes)
        if (minutesString.length === 1)
        {
            minutesString = "0" + minutesString
        }

        return `${hoursString}:${minutesString}`
    }

    function stringTimeToNumber(val)
    {
        let timeValue = moment(val, "hh:mm")
        return timeValue.hour() * 60 + timeValue.minutes()
    }

    function handleChange(event: any)
    {
        const { value } = event.target
        setNumberValue(value as number[])
        changeFunction([
            numberToStringTime(value[0]),
            numberToStringTime(value[1]),
        ])
    }

    function handleLowerChange(event: React.ChangeEvent<HTMLInputElement>)
    {
        changeFunction([event.target.value, value[1]])
    }

    function handleUpperChange(event: React.ChangeEvent<HTMLInputElement>)
    {
        changeFunction([value[0], event.target.value])
    }

    useEffect(() =>
    {
        if (nullable && isNull)
        {
            setNumberValue(null)
        }
    }, [isNull])

    function renderInlineLayout()
    {
        return (

            !isNull ? (
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                    }}
                >
                    {nullable && <Button
                        size="sm"
                        style={{ padding: 4, marginRight: 4 }}
                        variant={"outline-primary"}
                        onClick={() => setIsNull(!isNull)}>
                        Ignore times
                    </Button>}
                    <FormField
                        noLabel
                        type={"time"}
                        value={value ? value[0] : ""}
                        onChange={handleLowerChange}
                        setError={(err) => setError([err, error[1]])}
                        error={error[0] && error[0].value}
                        small
                    />
                    <Slider
                        style={{
                            marginLeft: 16,
                            marginRight: 16,
                        }}
                        value={numberValue}
                        onChange={handleChange}
                        min={0}
                        max={1439}
                        step={5}
                        aria-labelledby="discrete-slider"
                    />
                    <FormField
                        noLabel
                        type={"time"}
                        value={value ? value[1] : ""}
                        onChange={handleUpperChange}
                        setError={(err) => setError([error[0], err])}
                        error={error[1] && error[1].value}
                        small
                        reversed
                    />
                </div >) :
                (
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                        }}
                    >
                        <Button
                            size="sm"
                            style={{ padding: 4, marginRight: 4 }}
                            variant={"outline-primary"}
                            onClick={() => setIsNull(!isNull)}>
                            Edit times
                        </Button>
                    </div>
                )

        )
    }

    function renderTwoLineLayout()
    {
        return (
            <div style={{ width: "100%" }}>
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        paddingLeft: 12,
                        paddingRight: 12,
                    }}
                >
                    <Slider
                        value={numberValue}
                        onChange={handleChange}
                        min={0}
                        max={1439}
                        step={5}
                        aria-labelledby="discrete-slider"
                    />
                </div>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingLeft: 12,
                        paddingRight: 12,
                    }}
                >
                    <FormField
                        noLabel
                        type={"time"}
                        value={value ? value[0] : ""}
                        onChange={handleLowerChange}
                        setError={(err) => setError([err, error[1]])}
                        error={error[0] && error[0].value}
                        small
                    />
                    <FormField
                        noLabel
                        type={"time"}
                        value={value ? value[1] : ""}
                        onChange={handleUpperChange}
                        setError={(err) => setError([error[0], err])}
                        error={error[1] && error[1].value}
                        small
                        reversed
                    />
                </div>
            </div>
        )
    }

    return (
        <SizeMe>
            {({ size }) =>
            {
                if (size.width > 300)
                {
                    return renderInlineLayout()
                } else
                {
                    return renderTwoLineLayout()
                }
            }}
        </SizeMe>
    )
}
