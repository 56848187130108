import React, { useContext, useEffect, useState } from "react"
import { TabbedCardFormPage } from "../../components/Form/TabbedCardFormPage"
import { useSupportFormPage } from "./useSupportFormPage"

export default function SupportFormPage({ prop }: any)
{

    const {
        tabs,
        state,
        updateForm,
        updateErrors,
    } = useSupportFormPage()

    return (
        <React.Fragment>
            <TabbedCardFormPage
                tabs={tabs}
                state={state}
                updateForm={updateForm}
                updateErrors={updateErrors}
            />

        </React.Fragment>
    )
}
