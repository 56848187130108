import React, { useState } from "react"
import { TabbedCardFormPage } from "../../components/Form/TabbedCardFormPage"
import { RecordModal } from "../../components/RecordModal/RecordModal"
import { permissions } from "../../Constants/permissions"
import { useTimeProfileCalendarFormPages } from "./useTimeProfileCalendarFormPage"

export default function TimeProfileCalendarFormPage({ prop }: any)
{
    const { id, timeProfileId } = prop.match.params
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    const {
        loading,
        tabs,
        state,
        updateForm,
        updateErrors,
        saveTimeProfileCalendar,
        deleteTimeProfileCalendar
    } = useTimeProfileCalendarFormPages(id, timeProfileId)

    return (
        <React.Fragment>
            <TabbedCardFormPage
                configIdentifier={"Calendar " + (state?.description ? state.description : "")}
                state={state}
                updateForm={updateForm}
                updateErrors={updateErrors}
                tabs={tabs}
                loading={loading}
                defineSubmitData={saveTimeProfileCalendar}
                onDelete={() => setDeleteModalOpen(true)}
                displayName={"Time Profile"}
                permissions={[permissions.timeprofile, permissions.admin, permissions.VHAdmin]}
                specialBreadcrumbIndices={[{ href: `time-profiles/${timeProfileId}`, text: state?.timeProfile?.extension ? `Time Profile ${state?.timeProfile?.extension}` : `Time Profile` }]}
            />
            <RecordModal
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
                selectedRecords={[state]}
                action={"delete"}
                displayName={"Time Profile Calendar"}
                defineSubmitData={deleteTimeProfileCalendar}
            />
        </React.Fragment>
    )
}