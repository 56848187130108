import React, { useContext } from "react"
import { regex } from "../../Constants/regex"
import { regexError } from "../../Constants/regexError"
import variables from "../../Constants/variables.scss"
import useAPIRequests from "../../Hooks/useAPIRequests"
import { useRequest } from "../../Hooks/useRequest"
import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage"
import { permissions, hasPermission } from "../../Constants/permissions"
import { SessionContext } from "../../Contexts/SessionContext"

export default function SIPTrunksIndexPage()
{
    const { state: sessionState } = useContext(SessionContext)
    const { postRequest } = useRequest()
    const { getDestinationPromise } = useAPIRequests()

    function getCallerIDs(): Promise<ISelectOption[]>
    {
        return new Promise(async (resolve) =>
        {
            let response = await postRequest({}, `/numbering/allocated/callerids`)
            if (response && response.records)
            {
                const callerIds = response.records.map((callerId) => ({
                    label: callerId.name,
                    value: callerId.number,
                }))

                resolve(callerIds)
            }
        })
    }

    async function getEMSAddresses(): Promise<ISelectOption[]>
    {
        const emsResponse = await postRequest({}, `/emsaddress`)
        if (emsResponse && emsResponse.records)
        {
            return new Promise((resolve) =>
            {
                const formattedEMSAddresses = emsResponse.records.map(
                    (ems) => ({
                        label: `${ems.premises}, ${ems.thoroughfare ? `${ems.thoroughfare}, ` : ""
                            }${ems.locality}, ${ems.postcode}, (${formatNum(ems.telephoneNo)})`,
                        value: ems.telephoneNo,
                    })
                )
                if (formattedEMSAddresses.length > 0)
                {
                    resolve(formattedEMSAddresses)
                }
                else
                {
                    resolve([{ label: "Please add a telephone number to continue", value: "" }])
                }
            })
        }
    }

    function formatNum(num: String)
    {
        if (num.startsWith("44"))
        {
            num = num.replace("44", "0")
        }
        return num
    }

    async function getAccessLists(): Promise<ISelectOption[]>
    {
        return new Promise(async (resolve) =>
        {
            let response = await postRequest({}, `/accesscontrol/-1/-1`)
            if (response && response.records)
            {
                const failoverDestinations = response.records.map(
                    (destination) => ({
                        label: destination.name,
                        value: String(destination.id),
                    })
                )

                resolve(failoverDestinations)
            }
        })
    }

    function getUniqueIPs(): Promise<ISelectOption[]>
    {
        return new Promise(async (resolve) =>
        {
            let response = await postRequest({}, "/trunk/uniqueips")
            if (response && response.records)
            {
                const promiseDetails = response.records.map((details) => ({
                    label: details,
                    value: details,
                }))
                resolve(promiseDetails)
            }
        })
    }

    const columns: IColumn[] = [
        {
            name: "name",
            title: "Name",
            bold: true,
            sortBy: true,
            link: { root: "/sip" },
        },
        { name: "nickname", title: "Nickname", sortBy: true },
        { name: "callerIdNumber", title: "Caller ID" },
        {
            name: "emergencyServicesCid",
            title: "Location",
            hide: true,
            conditional: [
                {
                    value: "ANY-VALUE",
                    icon: "globe-europe",
                    iconColor: variables.colorBlue,
                    color: variables.colorBlue,
                    text: "Set",
                },
                {
                    value: "",
                    icon: "times",
                    iconColor: variables.colorRed,
                    color: variables.colorRed,
                    text: "Not Set",
                },
            ],
        },
        {
            name: "ip",
            title: "Public IP",
            sortByName: "ipaddr",
        },
        {
            name: "onNet",
            title: "On-Net/Off-Net",
            sortBy: true,
            conditional: [
                {
                    value: true,
                    text: "On-Net",
                },
                {
                    value: false,
                    text: "Off-Net",
                },
            ],
        },
        {
            name: "recordCalls",
            title: "Call Recording",
            hide: true,
            conditional: [
                { value: 0, text: "Not Enabled", icon: "microphone-slash" },
                { value: 1, text: "Enabled - Pausable", icon: "pause" },
                {
                    value: 2,
                    text: "Enabled - Not Pausable",
                    icon: "microphone",
                },
            ],
        },
        {
            name: "registered",
            title: "Status",
            hide: true,
            conditional: [
                {
                    value: true,
                    text: "Online",
                    icon: "phone",
                    color: variables.colorGreen,
                },
                {
                    value: false,
                    text: "Offline",
                    icon: "phone-slash",
                    color: variables.colorRed,
                },
                {
                    value: "Direct IP",
                    text: "Direct IP",
                    icon: "network-wired",
                    color: variables.colorGreen,
                }
            ],
        },
    ]

    const filters: IFilterDropdown = {
        filters: [
            {
                name: "ipAddrs",
                type: "select",
                multi: true,
                label: "Public IP",
                loadOptions: getUniqueIPs,
            },
            {
                name: "callRecording",
                type: "select",
                label: "Call Recording",
                multi: true,
                options: [
                    { label: "Not Enabled", value: "off" },
                    { label: "Enabled - Pausable", value: "pausable" },
                    { label: "Enabled - Non Pausable", value: "permanent" },
                ],
            },
            {
                name: "status",
                type: "select",
                label: "Status",
                multi: true,
                options: [
                    { label: "Offline", value: "offline" },
                    { label: "Online", value: "online" },
                ],
            },
        ],
    }

    const modalSettings: ISettingsStage[] = [
        {
            title: "General Settings",
            fields: [
                {
                    type: "text",
                    label: "Nickname",
                    name: "nickname",
                    helptext:
                        "The nickname allows you to tag the seat so you can identify who is using it or where it is located",
                    maxLength: 15,
                    required: true
                },
                {
                    type: "select",
                    name: "cliNumber",
                    label: "Caller ID",
                    loadOptions: getCallerIDs,
                    options: [{ label: "Set By Agent", value: "set by agent" }],
                    helptext:
                        "The 'Caller ID Number' is shown as your identity when making outgoing calls. \r\n" +
                        "This will allow the person receiving your call to see your telephone number on their phone's display \r\n" +
                        "This is dependant on network type and services on the end users line",
                    defaultValue: "set by agent"
                },
                {
                    type: "switch",
                    label: "Withhold Caller ID",
                    name: "withholdNumber",
                },
                {
                    type: "select",
                    label: "Failover Destination",
                    name: "trunkFailOver",
                    options: [{ value: "none", label: "None" }],
                    loadOptions: () => getDestinationPromise({ highPriorityQueue: true, extensionMap: false }),
                    helptext:
                        "If the SIP Trunk is offline or cannot be contacted, send  the call to the set destination. \r\n" +
                        "If no destination is set a temporary unavailable signal is sent back to the caller. \r\n" +
                        "We recommend setting a registration period of 60 seconds to make sure the fail over happens as soon as the PBX goes offline",
                    defaultValue: "none"
                },
                {
                    type: "select",
                    label: "EMS Location",
                    name: "emergencyServicesCid",
                    heightChange: 200,
                    loadOptions: getEMSAddresses,
                    required: true,
                    helptext:
                        "The emergency services location option allows you to set the physical location of a phone for use when calling 999 or 112. This helps the emergency services locate you if you are unable to speak" +
                        " or otherwise unable to confirm your location. It is recommended that you update every phone with it's physical location as this could make the difference between life and death. \r\n" +
                        "If your physical address is not shown in the list, you must update your location by going to the 'Telephones Numbers' section and select the telephone number associated with the phone",
                },
                {
                    type: "number",
                    name: "channelLimit",
                    required: true,
                    min: 1,
                    max: 200,
                    label: "Channels",
                    defaultValue: 1
                },
            ],
        },
        {
            title: "Outbound Time Restrictions",
            fields: [
                {
                    type: "switch",
                    label: "Allow Weekday Calls",
                    name: "callRestrictionsWeekdayAllow",
                },
                {
                    type: "timeSlider",
                    label: "Between",
                    name: "callRestrictionsWeekdayTimes",
                },
                {
                    type: "switch",
                    label: "Allow Weekend Calls",
                    name: "callRestrictionsWeekendAllow",
                },
                {
                    type: "timeSlider",
                    label: "Between",
                    name: "callRestrictionsWeekendTimes",
                },
            ],
        },
        {
            title: "Outbound Call Restrictions",
            fields: [
                {
                    type: "switch",
                    label: "Allow UK Geographic (01, 02 & 03)",
                    name: "allowUKGeo",
                    halfWidth: true,
                },
                {
                    type: "switch",
                    label: "Allow UK Mobile (071 to 079)",
                    name: "allowUKMobile",
                    halfWidth: true,
                },
                {
                    type: "switch",
                    label: "Allow UK Directory (118)",
                    name: "allowUK118",
                    halfWidth: true,
                },
                {
                    type: "switch",
                    label: "Allow Other UK Calls (08XX)",
                    name: "allowUKOther",
                    halfWidth: true,
                },
                {
                    type: "switch",
                    label: "Allow International Calls",
                    disabled: !hasPermission([permissions.highRisk, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                    name: "allowInternational",
                    halfWidth: true,
                },
                {
                    type: "switch",
                    disabled: !hasPermission([permissions.highRisk, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                    label: "Allow High Risk Zone",
                    name: "allowInternationalHighRisk",
                    helptext:
                        "High Risk Zones are destinations that are considered to have high or extreme risks of telecommunications fraud based on current data",
                    halfWidth: true,
                },
                {
                    type: "switch",
                    label: "Allow UK Premium Calls (09)",
                    name: "allowUKPremium09",
                    halfWidth: true,
                },
                {
                    type: "switch",
                    label: "Allow UK Premium Calls (070)",
                    name: "allowUKPremium070",
                    halfWidth: true,
                },
                {
                    type: "select",
                    name: "acl",
                    label: "Outbound Black/White List",
                    options: [{ value: "0", label: "No Blacklist" }],
                    loadOptions: getAccessLists,
                    defaultValue: "0"
                },
            ],
        },
        {
            title: "IP Address",
            fields: [
                {
                    type: "text",
                    label: "IP Address",
                    name: "host",
                    conditional: [{ value: "dynamic", text: "" }],
                    helptext:
                        "Direct IP addressing allows a PBX to receive calls directly without the use of SIP registrations. \r\n" +
                        "Please note if your PBX tries to register and an IP address has been set, registrations will fail. \r\n" +
                        "The recommended setting would be to allow the PBX to register and therefore the Direct IP address to be left blank",
                    regex: regex.publicIpAddress,
                    regexError: regexError.publicIpAddress
                },
                hasPermission([permissions.VHAdmin], sessionState.session.permissions, "read") && {
                    type: "switch",
                    label: "IP Authentication",
                    name: "trunkIPAuth",
                    conditionallyDisabled: {
                        checks: [{
                            field: "host",
                            value: ""
                        }]
                    },
                },
                {
                    type: "multiText",
                    label: "Restrict IP Address",
                    name: "permit",
                    helptext:
                        "The 'restrict to IP address' feature allows calls to only originate from a single IP address. \r\n" +
                        "This increases security as any calls originating from any other IP address will be rejected. \r\n" +
                        "It is highly recommended that the IP address of the PBX is entered here.",
                    regex: regex.publicIpAddress,
                    regexError: regexError.publicIpAddress,
                    maxLength: 45,
                },
            ],
        },
        {
            title: "Advanced",
            fields: [
                {
                    type: "select",
                    name: "trunkNoPlus",
                    label: "Number Mapping Format",
                    options: [
                        { label: "E.164 - To Header - Plus  (e.g. +441XXXXXXXXX)", value: 0 },
                        { label: "E.164 - To Header - No Plus (e.g. 441XXXXXXXXX)", value: 1 },
                        { label: "E.164 - From/To Headers (e.g. +441XXXXXXXXX)", value: 2 },
                    ],
                    defaultValue: -1
                },
                {
                    type: "switch",
                    label: "Trunk Signaling Only signal",
                    name: "sipSignalOnly",
                },
                {
                    type: "select",
                    label: "Trunk Network Transport",
                    name: "transport",
                    options: [
                        { value: "udp", label: "UDP" },
                        { value: "tcp", label: "TCP" },
                        { value: "tls", label: "TLS" },
                    ],
                    defaultValue: "udp"
                },
            ],
        },
    ]

    function getWeekendAndWeekday(data)
    {
        let weekday
        let weekend

        if (data.callRestrictionsWeekdayAllow)
        {
            let startTime = data.callRestrictionsWeekdayTimes[0]

            let endTime = data.callRestrictionsWeekdayTimes[1]

            if (startTime !== "00:00" || endTime !== "23:59")
            {
                if (startTime[0] === "0")
                {
                    startTime = startTime.substring(1)
                }
                if (endTime[0] === "0")
                {
                    endTime = endTime.substring(1)
                }
                weekday = `${startTime}-${endTime}`
            } else
            {
                weekday = `on`
            }
        } else
        {
            weekday = "off"
        }

        if (data.callRestrictionsWeekendAllow)
        {
            let startTime = data.callRestrictionsWeekendTimes[0]

            let endTime = data.callRestrictionsWeekendTimes[1]

            if (startTime !== "00:00" || endTime !== "23:59")
            {
                if (endTime[0] === "0")
                {
                    startTime = startTime.substring(1)
                }
                if (endTime[0] === "0")
                {
                    endTime = endTime.substring(1)
                }

                weekend = `${startTime}-${endTime}`
            } else
            {
                weekend = `on`
            }
        } else
        {
            weekend = "off"
        }

        return { weekday, weekend }
    }

    function extractSetVarTimes(value)
    {
        const timeRegex = /([0-9]{1,2}:[0-9]{1,2})-([0-9]{1,2}:[0-9]{1,2})/g

        const matches = timeRegex.exec(value)

        if (matches)
        {
            let startTime = matches[1]
            if (startTime.length === 4)
            {
                startTime = "0" + startTime
            }
            let endTime = matches[2]
            if (endTime.length === 4)
            {
                endTime = "0" + endTime
            }
            return [startTime, endTime]
        } else
        {
            return ["00:00", "23:59"]
        }
    }

    const { state } = useContext(SessionContext)

    return (
        <GenericIndexPage
            title={"SIP Trunks"}
            urlString={"/trunk"}
            searchable={true}
            filters={filters}
            columns={columns}
            restType={"POST"}
            conditionallySetValue={{ checkedName: "host", setName: "registered", checkedValue: "dynamic", setValue: "Direct IP", match: false }}
            body={{ sortBy: "name", direction: "ASC" }}
            permissions={[permissions.SIPTrunks, permissions.admin, permissions.VHAdmin]}
            maxNoRecords={hasPermission([permissions.retail], state.session.permissions, "read") ? 1 : undefined}
            addModal={{
                displayName: "SIP Trunk",
                allowBulkAdd: false,
                settingsStages: modalSettings,
                submitData: (data) =>
                {
                    const { weekend, weekday } = getWeekendAndWeekday(data)

                    return [{
                        postData: {
                            nickname: data.nickname,
                            cliNumber: data.cliNumber,
                            withholdNumber: data.withholdNumber,
                            trunkFailOver: data.trunkFailOver === "none" ? null : data.trunkFailOver,
                            emergencyServicesCid: data.emergencyServicesCid,
                            host: data.host,
                            trunkIPAuth: data.trunkIPAuth,
                            permit: data.permit,
                            trunkNoPlus: data.trunkNoPlus,
                            transport: data.transport,
                            options: {
                                weekdayTimes: weekday,
                                weekendTimes: weekend,
                                allowUKGeo: data.allowUKGeo,
                                allowUKMobile: data.allowUKMobile,
                                allowUK118: data.allowUK118,
                                allowUKOther: data.allowUKOther,
                                allowInternational: data.allowInternational,
                                allowInternationalHighRisk: data.allowInternationalHighRisk,
                                allowUKPremium09: data.allowUKPremium09,
                                allowUKPremium070: data.allowUKPremium070,
                                acl: data.acl,
                                signalsonly: data.signalsonly,
                                channelLimit: data.channelLimit,
                                teamsCLI: data.enableTeams ? data.teamsCLI : null,
                                ipAcl: ['GB']
                            },
                        },
                        path: "/trunk",
                        identifier: "",
                        type: "create",
                        displayName: "SIP TRUNK",
                    }]
                },
            }}
            editModal={{
                displayName: "SIP Trunk",
                uniqueIdentifier: "name",
                settingsStages: modalSettings,
                loadRecords: [{
                    url: "/trunk",
                    dataDefinition: (data) =>
                    {
                        return {
                            ...data,
                            ...data.options,
                            cliNumber: data.cliNumber ? data.cliNumber : "set by agent",
                            host: data.host === "dynamic" ? "" : data.host,
                            trunkFailOver: data.trunkFailOver ? data.trunkFailOver : "none",
                            vhOnlySignal: data.options.signalsonly,

                            callRestrictionsWeekdayAllow:
                                data.options.weekdayTimes !== "off",
                            callRestrictionsWeekdayTimes: extractSetVarTimes(
                                data.options.weekdayTimes
                            ),

                            callRestrictionsWeekendAllow:
                                data.options.weekendTimes !== "off",
                            callRestrictionsWeekendTimes: extractSetVarTimes(
                                data.options.weekendTimes
                            ),
                        }
                    },
                }],
                submitData: (data) =>
                {
                    const { weekend, weekday } = getWeekendAndWeekday(data)
                    return [{
                        postData: {
                            nickname: data.nickname,
                            cliNumber: data.cliNumber,
                            withholdNumber: data.withholdNumber,
                            trunkFailOver: data.trunkFailOver === "none" ? null : data.trunkFailOver,
                            emergencyServicesCid: data.emergencyServicesCid,
                            host: data.host,
                            trunkIPAuth: data.trunkIPAuth,
                            permit: data.permit,
                            trunkNoPlus: data.trunkNoPlus,
                            transport: data.transport,
                            options: {
                                weekday: weekday,
                                weekend: weekend,
                                allowUKGeo: data.allowUKGeo,
                                allowUKMobile: data.allowUKMobile,
                                allowUK118: data.allowUK118,
                                allowUKOther: data.allowUKOther,
                                allowInternational: data.allowInternational,
                                allowInternationalHighRisk: data.allowInternationalHighRisk,
                                allowUKPremium09: data.allowUKPremium09,
                                allowUKPremium070: data.allowUKPremium070,
                                acl: data.acl,
                                signalsonly: data.signalsonly,
                                channelLimit: data.channelLimit
                            },
                        },
                        path: "/trunk",
                        identifier: "",
                        type: "edit",
                        displayName: "name",
                        allowNull: ["trunkFailOver"]
                    }]
                },
            }}
            deleteModal={{
                displayName: "SIP Trunk",
                uniqueIdentifier: "name",
                submitData: () =>
                {
                    return [{
                        path: "/trunk",
                        displayName: "name",
                        type: "delete",
                    }]
                },
            }}
        />
    )
}
