import { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom";
import { ToastContext } from "../../Contexts/ToastContext";
import { SessionContext } from "../../Contexts/SessionContext"
import { useFormReducer } from "../../Hooks/useFormReducer";
import { useRequest } from "../../Hooks/useRequest";

export function useIntegrationsPreferencesPage()
{
    const { add } = useContext(ToastContext)
    const [record, setRecord] = useState({})
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState<any>(null)
    const [customer, setCustomer] = useState<any>(null)
    const [activeAccountLicense, setActiveAccountLicense] = useState(false);
    const [activeUserLicense, setActiveUserLicense] = useState(false);
    const [users, setUsers] = useState([]);
    const [modalContent, setModalContent] = useState('');
    const [showModal, setShowModal] = useState(false);
    const { state: session } = useContext(SessionContext)
    const { getRequest, postRequest, deleteRequest, putRequest } = useRequest()
    const accountNo = session.session.account_no;
    const [userReference, setUserReference] = useState('')
    const [customerReference, setCustomerReference] = useState('')

    async function pullData()
    {
        setLoading(true);

        let data = {}

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const rcLicenseCheck = await getRequest('/redcactus/customer/own')
        const rcUserLicenseCheck = await getRequest('/redcactus/user/own');

        if (rcLicenseCheck)
        {
            setCustomerReference(rcLicenseCheck?.customer?.id)

            if (rcLicenseCheck?.customer?.active)
            {
                setActiveAccountLicense(true);
            }
        }

        if (rcUserLicenseCheck)
        {
            setUserReference(rcUserLicenseCheck?.userReference)

            if (rcUserLicenseCheck?.active)
            {
                setActiveUserLicense(true);
            }
        }

        if (session.session.account_no)
        {
            // @ts-ignore
            let response = await getRequest(
                `/account/` + session.session.account_no
            )

            if (response)
            {
                let customerData =
                {
                    company: response.company,
                    address: `${response.address_line1} ${response.address_line2}`,
                    postal: response.address_postcode,
                    city: response.address_city,
                    country_code: response.countryCode,
                    timezone: timezone,
                    active: true,
                };

                let userData =
                {
                    email: response.email,
                    firstname: response.firstName,
                    lastname: response.surname,
                    active: true,
                    has_password: false
                }

                setUser(userData)
                setCustomer(customerData)
            }
            else
            {
                add({
                    text: "The request failed.",
                    type: "error",
                })
            }
            data = { ...data, active: false }
        }

        setRecord(data)
        setLoading(false)
    }

    const {
        state,
        updateState,
        updateForm,
        updateErrors,
    } = useFormReducer({ record })

    useEffect(() =>
    {
        pullData();
    }, []);

    function getRedCactusPreferencesFields(data): IFormField[]
    {
        let fields: IFormField[] = [
            {
                type: "info",
                value: "To access your Red Cactus integration, you'll need to setup a method of logging into the Bubble client.",
                noLabel: true
            },
            {
                type: "info",
                value: "Currently, OAuth is available for Microsoft, Google & Apple emails, if you do not intend on using any of the accepted providers you will need to opt for setting a password.",
                noLabel: true
            },
        ];

        if (activeUserLicense)
        {

            fields.push({
                type: "button",
                placeholder: "Setup OAuth for my Bubble account",
                noLabel: true,
                onClick: () =>
                {
                    setModalContent("oauthSetup");
                    setShowModal(true);
                }
            });

            fields.push({
                type: "button",
                placeholder: "Set a password for my Bubble account",
                noLabel: true,
                onClick: () =>
                {
                    setModalContent("passSetup");
                    setShowModal(true);
                }
            });
        }

        return fields;
    }

    const tabs: ITab[] = [
        {
            title: "Red Cactus",
            icon: "plug",
            disabled: false,
            sections: [
                {
                    title: "Preferences",
                    fields: getRedCactusPreferencesFields(record),
                    fullWidth: true
                }
            ],
        }
    ]

    return {
        loading,
        tabs,
        user,
        customer,
        activeAccountLicense,
        activeUserLicense,
        users,
        state,
        showModal,
        modalContent,
        userReference,
        customerReference,
        setShowModal,
        setLoading,
        setModalContent,
        updateState,
        updateForm,
        updateErrors,
    }
}
