import React from "react"

export function Checkbox({
    value,
    disabled,
    onChange,
    setError,
    name,
    required
}: ICheckbox)
{
    function handleOnChange(e)
    {
        if (e.target.checked === true)
        {
            setError && setError({ target: { ...e.target, name, value: "" } })
        } else if (e.target.checked === false && required)
        {
            setError && setError({ target: { ...e.target, name, value: "This is a required field" } })
        }

        onChange({
            target: {
                name: name,
                type: "checkbox",
                // @ts-ignore
                value: e.target.checked
            }
        })
    }

    return (
        <input
            type="checkbox"
            name={name}
            onClick={handleOnChange}
            disabled={disabled}
            // onClick={onChange}
            checked={value}
            style={{ marginRight: 8, marginTop: 2 }}
        />
    )
}