import { useEffect, useState } from "react"
import { useRequest } from "../../Hooks/useRequest"
import { useFormReducer } from "../../Hooks/useFormReducer"
import useAPIRequests from "../../Hooks/useAPIRequests"

export function usePickUpGroupsFormPage(id: number)
{
    const [record, setRecord] = useState({})
    const [loading, setLoading] = useState(true)
    const { getRequest } = useRequest()
    const { getDestinationPromise } = useAPIRequests()

    async function pullData()
    {
        setLoading(true)
        let data = {}

        const pickupGroupResponse = await getRequest(`/pickupgroups/${id}`)

        if (pickupGroupResponse)
        {
            data = {
                ...data,
                pickupGroup: {
                    ...pickupGroupResponse,
                    //TODO: make this better
                    shortCode:
                        pickupGroupResponse.name &&
                        "*0#" +
                        pickupGroupResponse.name.substring(
                            6,
                            9
                        ),
                },
            }
        }

        setRecord(data)
        setLoading(false)
    }

    useEffect(() =>
    {
        pullData()
        // eslint-disable-next-line
    }, [id])

    const {
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
    } = useFormReducer({ record })

    function deleteData()
    {
        return [{
            path: '/pickupgroups',
            type: "delete",
            indexPagePath: "/pickup-groups",
            displayName: "Pickup Group"
        }]
    }

    function submitData()
    {
        return [
            {
                restType: "PATCH",
                body: {
                    name: state.pickupGroup.desc,
                },
                path: "/pickupgroups/" + id + "/name",
            },
        ]
    }

    function pickUpGroupMemberCheck(
        fieldFrom
    ): Promise<{ success: boolean; message: string }>
    {
        return new Promise(async (resolve) =>
        {
            if (!fieldFrom)
            {
                resolve({
                    success: false,
                    message: "Please select a member",
                })
            }
            else
            {
                let response = await getRequest(
                    `/pickupgroups/${id}/members/-1/-1`
                )

                fieldFrom.forEach(async function(item) {
                    const name = item.match(/^(\w+)(\/)([1-9][0-9]{4}\*)([2-9][0-9]{2,4})/)[4]
                    if (response)
                    {
                        if (response.records)
                        {
                            const filteredRecords = response.records.filter(
                                (record) => record.name === name
                            )
    
                            if (filteredRecords && filteredRecords.length > 0)
                            {
                                resolve({
                                    success: false,
                                    message:
                                        "This destination is already part of this pickup group",
                                })
                            } else
                            {
                                resolve({ success: true, message: "" })
                            }
                        } else
                        {
                            resolve({
                                success: false,
                                message: "Failed to retrieve data, please try again",
                            })
                        }
                    } else
                    {
                        resolve({
                            success: false,
                            message: "Failed to retrieve data, please try again",
                        })
                    }
                });
            }
        })
    }

    const tabs: ITab[] = [
        {
            title: "General",
            icon: "toolbox",
            disabled: false,
            sections: [
                {
                    title: "Overview",
                    fullWidth: true,
                    fields: [
                        {
                            label: "Name",
                            type: "text",
                            name: "pickupGroup.desc",
                            maxLength: 100,
                        },
                    ],
                },
                {
                    title: "Members",
                    fields: [
                        {
                            type: "table",
                            customPaginationName: "pickupMembers",
                            name: "options",
                            dataType: "Members",
                            columns: [
                                {
                                    title: "Seat",
                                    name: "name",
                                },
                                {
                                    title: "Member",
                                    name: "nickname",
                                },
                            ],
                            urlString: `/pickupgroups/${id}/members`,
                            restType: "GET",
                            pullData: pullData,
                            addModal: {
                                displayName: "Member",
                                fieldFrom: {
                                    name: "destination",
                                    label: "Member",
                                    type: "select",
                                    multi: true,
                                    loadOptions: () =>
                                        getDestinationPromise({
                                            seats: true,
                                            queues: false,
                                            voicemail: false,
                                            groups: false,
                                            ivrs: false,
                                            timeProfiles: false,
                                            externalDest: false,
                                            sipTrunks: false,
                                            dialThrough: false,
                                            queueTrunkMaps: false,
                                            extensionMap: false,
                                            highPriorityQueue: false,
                                            sipTrunkMap: false,
                                        }),
                                },
                                allowBulkAdd: false,
                                availabilityCheck: (fieldFrom) =>
                                {
                                    return new Promise(async (resolve) =>
                                    {
                                        const responseMessage = await pickUpGroupMemberCheck(
                                            fieldFrom
                                        )

                                        if (
                                            responseMessage &&
                                            responseMessage.success
                                        )
                                        {
                                            resolve({ success: true })
                                        } else
                                        {
                                            resolve({
                                                success: false,
                                                error: responseMessage.message,
                                            })
                                        }
                                    })
                                },
                                submitData: (data) => 
                                {
                                    for (let i = 0; i < data.fieldFrom.length; i++) 
                                    {
                                        let match = data.fieldFrom[i].match(/^(\w+)(\/)(.+)/);
                                        if (match)
                                        {
                                            data.fieldFrom[i] = match[3]; 
                                        }
                                    }
                                    return [{
                                        postData: {name : data.fieldFrom },
                                        identifier: "name",
                                        path: "/pickupgroups/" + id + "/members",
                                        type: "create",
                                        displayName: data.fieldFrom,
                                    }]
                                }
                            },
                            deleteModal: {
                                uniqueIdentifier: "name",
                                displayName: "member",
                                submitData: () =>
                                {
                                    return [{
                                        path:
                                            "/pickupgroups/" +
                                            id +
                                            "/members",
                                        type: "delete",
                                    }]
                                },
                            },
                        },
                    ],
                },
                {
                    title: "Pickup Procedure",
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "To pickup a ringing phone that belongs to this pickup group, dial the following " +
                                "short-code.",
                        },
                        {
                            type: "info",
                            label: "Short-code",
                            name: "pickupGroup.shortCode",
                        },
                    ],
                },
            ],
        },
    ]

    return {
        loading,
        tabs,
        state,
        updateState,
        updateForm,
        updateErrors,
        submitData,
        deleteData,
        // changes,
        // resetChanges,
    }
}
