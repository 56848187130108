import React from "react"
import { OverlayTrigger, Tooltip as TooltipComp } from "react-bootstrap"
import { Placement } from "react-bootstrap/Overlay";

interface IProps
{
    children: React.ReactElement
    dontShow?: boolean
    text: string
    placement?: Placement
}

export default function Tooltip({ children, dontShow, text, placement }: IProps)
{

    return (
        <OverlayTrigger
            placement={placement ? placement : "bottom"}
            delay={{ show: 250, hide: 400 }}
            overlay={props =>
                !dontShow ? <TooltipComp id={text} {...props}>{text}</TooltipComp> : <div />
            }
        >
            {children}
        </OverlayTrigger>

    );
}
