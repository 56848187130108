import React from "react";
import { sipconvergenceReformatter } from "../../Common/urlHelpers";

export default function ZohoRedirectPage()
{
    const urlString = `0; url=https://accounts.zoho.eu/oauth/v2/auth?`
        + `response_type=code&`
        + `client_id=1000.5V9X7KUS2Q4OXAE4DYYJLDVOZFBEDH&`
        + `scope=PhoneBridge.call.log,PhoneBridge.zohoone.search&`
        + `redirect_uri=${sipconvergenceReformatter("https://cp3.voicehost.co.uk/zohocallback&")}`
        + `access_type=offline`

    return (
        <div>
            <meta http-equiv="Refresh" content={urlString} />
        </div>
    )
}
