import { TabbedCardFormPage } from "../../components/Form/TabbedCardFormPage"
import React from "react";
import { useVoicemailSettingsPage } from "./useVoicemailSettingsPage";
import { permissions } from "../../Constants/permissions";

export default function VoicemailSettingsPage({ prop }: any)
{
    const { mailbox } = prop.match.params

    const { loading, tabs, state, updateForm, updateErrors, submitData } = useVoicemailSettingsPage(mailbox)

    return (
        <React.Fragment>
            <TabbedCardFormPage
                state={state}
                updateForm={updateForm}
                updateErrors={updateErrors}
                tabs={tabs}
                loading={loading}
                defineSubmitData={submitData}
                permissions={[permissions.voicemail, permissions.admin, permissions.VHAdmin]}
                displayName={"Voicemail"}
            />
        </React.Fragment>
    )
}