import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage"
import React, { useContext } from "react"
import { useRequest } from "../../Hooks/useRequest"
import useAPIRequests from "../../Hooks/useAPIRequests"
import { regex } from "../../Constants/regex"
import { regexError } from "../../Constants/regexError"
import { SessionContext } from "../../Contexts/SessionContext"
import { hasPermission, permissions } from "../../Constants/permissions"

export default function DialThroughIndexPage()
{
    const { state: sessionState } = useContext(SessionContext)
    const { getRequest, postRequest } = useRequest()
    const { getCallerIdPromise } = useAPIRequests()

    const columns: IColumn[] = [
        {
            title: "Name",
            link: { root: "/dial-through" },
            bold: true,
            name: "name",
            sortBy: true,
            conditional: [{
                value: "",
                text: "Name not set",
            }],
        },
    ]

    const addModalSettings: ISettingsStage[] = [
        {
            title: "Settings",
            fields: [
                {
                    type: "text",
                    name: "name",
                    label: "Name",
                    required: true,
                    maxLength: 50,
                },
                {
                    type: "select",
                    label: "Caller ID",
                    name: "cli",
                    loadOptions: getCallerIdPromise,
                    defaultToFirst: true
                },
                {
                    label: "PIN",
                    type: "password",
                    name: "pin",
                    regex: regex.pin,
                    regexError: regexError.pin
                },
                {
                    label: "PIN Enabled",
                    type: "switch",
                    name: "pinEnabled",
                },
            ],
        },
        {
            title: "Call Restrictions",
            fields: [
                {
                    type: "switch",
                    label: "Allow UK Geographic",
                    name: "ukGeo",
                    helptext: "(01, 02 & 03)",
                },
                {
                    type: "switch",
                    disabled: !hasPermission([permissions.highRisk, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                    label: "Allow International Calls",
                    name: "inter",
                },
                {
                    type: "switch",
                    label: "Allow UK Mobile",
                    name: "ukMob",
                    helptext: "(071 to 079)",
                },
                {
                    type: "switch",
                    label: "Allow Other UK Calls",
                    name: "ukOther",
                },

                {
                    label: "Concurrent Calls",
                    type: "select",
                    required: true,
                    options: [
                        { label: "1", value: 1 },
                        { label: "2", value: 2 },
                        { label: "3", value: 3 },
                        { label: "4", value: 4 },
                        { label: "5", value: 5 },
                        { label: "10", value: 10 },
                        { label: "15", value: 15 },
                        { label: "20", value: 20 },
                        { label: "30", value: 30 },
                        { label: "40", value: 40 },
                        { label: "50", value: 50 },
                    ],
                    name: "channels",
                },
            ],
        },
    ]

    const editModalSettings: ISettingsStage[] = [
        {
            title: "Call Restrictions",
            fields: [
                {
                    type: "switch",
                    label: "Allow UK Geographic",
                    name: "ukGeo",
                    helptext: "(01, 02 & 03)",
                },
                {
                    type: "switch",
                    disabled: !hasPermission([permissions.highRisk, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                    label: "Allow International Calls",
                    name: "inter",
                },
                {
                    type: "switch",
                    label: "Allow UK Mobile",
                    name: "ukMob",
                    helptext: "(071 to 079)",
                },
                {
                    type: "switch",
                    label: "Allow Other UK Calls",
                    name: "ukOther",
                },

                {
                    label: "Concurrent Calls",
                    type: "select",
                    required: true,
                    options: [
                        { label: "1", value: 1 },
                        { label: "2", value: 2 },
                        { label: "3", value: 3 },
                        { label: "4", value: 4 },
                        { label: "5", value: 5 },
                        { label: "10", value: 10 },
                        { label: "15", value: 15 },
                        { label: "20", value: 20 },
                        { label: "30", value: 30 },
                        { label: "40", value: 40 },
                        { label: "50", value: 50 },
                    ],
                    name: "channels",
                },
            ],
        },
    ]

    return (
        <GenericIndexPage
            title={"Dial Through"}
            urlString={"/dialthrough"}
            columns={columns}
            searchable={true}
            restType={"POST"}
            permissions={[permissions.dialThrough, permissions.admin, permissions.wholesaleAdmin, permissions.wholesaleCustomers, permissions.VHAdmin, permissions.VHCustomers]}
            addModal={{
                displayName: "Dial Through",
                settingsStages: addModalSettings,
                submitData: (data) =>
                {
                    return [{
                        postData: {
                            name: data.name,
                            cli: data.cli,
                            pin: data.pin,
                            pinEnabled: data.pinEnabled,
                            ukGeo: data.ukGeo,
                            ukMob: data.ukMob,
                            inter: data.inter,
                            ukOther: data.ukOther,
                            channels: data.channels,
                        },
                        path: "/dialthrough",
                        identifier: "",
                        type: "create",
                        displayName: data.name,
                    }]
                },
            }}
            editModal={{
                displayName: "Dial Through",
                settingsStages: editModalSettings,
                uniqueIdentifier: "name",
                loadRecords: [{
                    url: "/dialthrough",
                }],
                submitData: (data) =>
                {
                    return [{
                        postData: {
                            ukGeo: data.ukGeo,
                            ukMob: data.ukMob,
                            inter: data.inter,
                            ukOther: data.ukOther,
                            channels: data.channels,
                        },
                        path: "/dialthrough",
                        type: "edit",
                        displayName: "name",
                    }]
                },
            }}
            deleteModal={{
                displayName: "Dial Through",
                uniqueIdentifier: "name",
                submitData: () =>
                {
                    return [{
                        path: "/dialthrough",
                        displayName: "name",
                        type: "delete",
                    }]
                },
            }}
            helpSections={[
                {
                    title: "Dial Through",
                    text: "Allows remote access to your account features."
                }
            ]}
        />
    )
}
