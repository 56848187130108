import { TabbedCardFormPage } from "../../components/Form/TabbedCardFormPage";
import React, { useState, useContext } from "react";
import { useBlackWhiteListFormPage } from "./useBlackWhiteListFormPage";
import { RecordModal } from "../../components/RecordModal/RecordModal";
import { permissions } from "../../Constants/permissions";

export default function BlackWhiteListFormPage({ prop }: any)
{
    const { id } = prop.match.params
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    const {
        loading,
        tabs,
        state,
        updateForm,
        updateErrors,
        submitData,
        deleteData,
        // changes,
        // resetChanges,
    } = useBlackWhiteListFormPage(id)

    return (
        <React.Fragment>
            <TabbedCardFormPage
                state={state}
                updateForm={updateForm}
                updateErrors={updateErrors}
                // changes={changes}
                // resetChanges={resetChanges}
                tabs={tabs}
                loading={loading}
                onDelete={(() => setDeleteModalOpen(true))}
                defineSubmitData={submitData}
                displayName={"Access List"}
                permissions={[permissions.acl, permissions.admin, permissions.VHAdmin]}
            />
            <RecordModal
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
                action={"delete"}
                selectedRecords={[state]}
                displayName={"Black/White List"}
                uniqueIdentifier={"name"}
                defineSubmitData={deleteData}
            />
        </React.Fragment>
    )
}
