import { useContext, useEffect, useState } from "react";
import { ToastContext } from "../../Contexts/ToastContext";
import { useMediaQuery } from "@material-ui/core";


export function useStagedForm({ stages, onComplete, state })
{
    const { add } = useContext(ToastContext);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});
    const [loading, setLoading] = useState<boolean>(false);
    const isMobile = useMediaQuery("(max-width:460px)")
    const steps = getSteps()

    function checkErrors()
    {
        return !!stages.find((stage) => stage.error)
    }

    function getSteps()
    {
        return stages.map((stage) => stage.title)
    }

    function completedSteps()
    {
        return Object.keys(completed).length
    }

    function isLastStep()
    {
        return activeStep === stages.length - 1
    }

    function allStepsCompleted()
    {
        return completedSteps() === stages.length
    }

    function handleNext()
    {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1
        setActiveStep(newActiveStep)
    }

    function handleBack()
    {
        const newCompleted: { [k: number]: boolean } = completed
        delete newCompleted[activeStep - 1]
        setCompleted(newCompleted)
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    function handleComplete()
    {
        const newCompleted = completed
        newCompleted[activeStep] = true
        setCompleted(newCompleted)

        if (allStepsCompleted())
        {
            onComplete()
        } else
        {
            handleNext()
        }
    }

    function calculateConditionalValue(conditions: ITabbedFormConditional)
    {
        if (!conditions)
        {
            return null
        } 
        else
        {
            let operator = conditions.operator && conditions.operator !== null ? conditions.operator : "AND"

            if (operator === "AND")
            {
                for (let condition of conditions.checks)
                {
                    let fieldValue = findFieldValue(condition.field)
                    if (condition.value === "ANY-VALUE")
                    {
                        if (fieldValue === null || fieldValue === undefined)
                        {
                            return undefined
                        }
                    }
                    else if (condition.value !== fieldValue)
                    {
                        return undefined;
                    }
                }
                return conditions.successValue
            }
            else if (operator === "OR")
            {
                for (let condition of conditions.checks)
                {
                    var fieldValue = findFieldValue(condition.field)
                    if (condition.value === "ANY-VALUE")
                    {
                        if (fieldValue !== null && fieldValue !== undefined)
                        {
                            return conditions.successValue
                        }
                    }
                    else if (condition.value === fieldValue)
                    {
                        return conditions.successValue
                    }
                }
                return undefined
            }
        }
    }

    function calculateCondition(conditions: ITabbedFormConditional)
    {
        if (!conditions || conditions == null)
        {
            return null
        } else
        {
            let operator = conditions.operator && conditions.operator !== null ? conditions.operator : "AND"

            if (operator === "AND")
            {
                for (let condition of conditions.checks)
                {
                    if (findFieldValue(condition.field) !== condition.value)
                    {
                        return false
                    }
                }
                return true
            }
            else if (operator === "OR")
            {
                for (let condition of conditions.checks)
                {
                    if (findFieldValue(condition.field) === condition.value)
                    {
                        return true
                    }
                }
                return false
            }
        }
    }

    function findFieldValue(name)
    {
        if (!name)
        {
            return null
        }
        const splitName = name.split(".")

        let value = state
        splitName.forEach(split =>
        {
            if (value && value[split] || value[split] === 0 || value[split] === false || value[split] === "")
            {
                value = value[split]
            }
        })
        if (typeof value !== "object" || typeof value === "object" && value.constructor === Array)
        {
            return value
        }
    }

    return {
        isMobile,
        activeStep,
        steps,
        completed,
        allStepsCompleted,
        handleBack,
        loading,
        setLoading,
        add,
        handleComplete,
        completedSteps,
        checkErrors,
        calculateCondition,
        calculateConditionalValue,
        findFieldValue
    }
}
