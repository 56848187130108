import { useEffect, useState, useRef, useContext } from "react"
import { useRequest } from "../../Hooks/useRequest"
import { useFormReducer } from "../../Hooks/useFormReducer"
import useAPIRequests from "../../Hooks/useAPIRequests"
import { regex } from "../../Constants/regex"
import { regexError } from "../../Constants/regexError"
import { SessionContext } from "../../Contexts/SessionContext"
import { permissions } from "../../Constants/permissions"

export function useCallMonitoringFormPage(id: number)
{
    const [record, setRecord] = useState({})
    const [loading, setLoading] = useState(true)
    const { postRequest } = useRequest()
    const { state: sessionState } = useContext(SessionContext)
    const { getDestinationPromise } = useAPIRequests()
    const tableRef = useRef()

    async function pullData(firstLoad)
    {
        setLoading(true)
        let data = {}

        const callMonitoringResponse = await postRequest({ destination: id }, `/callmonitoring/-1/-1`)
        if (callMonitoringResponse)
        {
            data = {
                ...data,
                callMonitoring: callMonitoringResponse
            }
        }

        if (firstLoad)
        {
            setRecord(data)
        } else
        {
            updateState(data)
        }
        setLoading(false)
    }


    useEffect(() =>
    {
        pullData(true)
    }, [id])

    const {
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
    } = useFormReducer({ record })

    function checkIfAlreadyMonitoring(
        fieldFrom
    ): Promise<{ success: boolean; message: string }>
    {
        return new Promise(async (resolve) =>
        {
            if (!fieldFrom)
            {
                resolve({ success: false, message: "Please select an option" })
            }

            let response = await postRequest(
                { destination: id },
                `/callmonitoring/-1/-1`
            )

            if (response?.records)
            {
                fieldFrom.forEach(seatId =>
                {
                    if (Number(seatId) === Number(id))
                    {
                        resolve({ success: false, message: "A seat cannot monitor itself" })
                    }

                    const filteredRecords = response.records.filter(
                        (record) => record.source === seatId
                    )

                    if (filteredRecords && filteredRecords.length > 0)
                    {
                        resolve({
                            success: false,
                            message: filteredRecords[0].source + " is already monitoring calls",
                        })
                    }
                });
            }
            else
            {
                resolve({ success: false, message: "Failed to retrieve data, please try again", })
            }

            resolve({ success: true, message: "" })
        })
    }

    function saveTable(data)
    {
        return {
            id: data.id,
            password: data.password
        }
    }

    function submitData()
    {
        return []
    }

    function getDestinations()
    {
        return new Promise<ISelectOption[]>(async resolve =>
        {
            const options = await getDestinationPromise({
                seats: true,
                queues: false,
                voicemail: false,
                groups: false,
                ivrs: false,
                timeProfiles: false,
                sipTrunks: false,
                dialThrough: false,
                externalDest: false,
                sipTrunkMap: false,
                queueTrunkMaps: false,
                extensionMap: false,
                highPriorityQueue: false,
            })
            resolve(options.map(option => ({
                label: option.label,
                value: option.value.replace(`SIP/${sessionState.session.account_no}*`, "")
            })
            ))
        })
    }
    const tabs: ITab[] = [
        {
            title: "General",
            icon: "toolbox",
            sections: [
                {
                    fullWidth: true,
                    fields: [
                        {
                            type: "table",
                            customPaginationName: "callMonitorings",
                            columns: [
                                {
                                    name: "source",
                                    title: "Seat",
                                },
                                {
                                    name: "monitorName",
                                    title: "Name",
                                },
                                {
                                    name: "password",
                                    title: "PIN",
                                    formField: {
                                        type: "text",
                                        name: "password",
                                        regex: regex.pin,
                                        regexError: regexError.pin
                                    },
                                },
                            ],
                            restType: "POST",
                            fieldRef: tableRef,
                            body: { destination: id },
                            permissions: [permissions.spy, permissions.admin, permissions.VHAdmin],
                            urlString: `/callmonitoring`,
                            onSubmit: {
                                getData: saveTable,
                                path: `/callmonitoring`,
                            },
                            pullData: () => pullData(false),
                            addModal: {
                                displayName: "Member",
                                allowBulkAdd: false,
                                fieldFrom: {
                                    field: "Member",
                                    label: "Members",
                                    type: "select",
                                    multi: true,
                                    loadOptions: () =>
                                        getDestinations()
                                },
                                settingsStages: [
                                    {
                                        title: "Settings",
                                        fields: [
                                            {
                                                type: "text",
                                                name: "password",
                                                label: "PIN",
                                                regex: regex.pin,
                                                regexError: regexError.pin
                                            },
                                        ],
                                    },
                                ],
                                availabilityCheck: (fieldFrom) =>
                                {
                                    return new Promise(async (resolve) =>
                                    {
                                        const responseMessage = await checkIfAlreadyMonitoring(
                                            fieldFrom
                                        )

                                        if (
                                            responseMessage &&
                                            responseMessage.success
                                        )
                                        {
                                            resolve({ success: true })
                                        } else
                                        {
                                            resolve({
                                                success: false,
                                                error: responseMessage.message,
                                            })
                                        }
                                    })
                                },
                                submitData: (data) =>
                                {
                                    return [{
                                        postData: {
                                            destination: id,
                                            password: data.password,
                                        },
                                        path: "/callmonitoring",
                                        identifier: "source",
                                        type: "create",
                                        displayName: "source",
                                    }]
                                },
                            },
                            deleteModal: {
                                displayName: "Member",
                                uniqueIdentifier: "monitorName",
                                submitData: () =>
                                {
                                    return [{
                                        path: "/callmonitoring",
                                        displayName: "monitorName",
                                        type: "delete",
                                    }]
                                },
                            },
                        },
                    ],
                },
            ],
        },
    ]

    return {
        loading,
        tabs,
        state,
        updateState,
        updateForm,
        updateErrors,
        submitData,
        // changes,
        // resetChanges,
    }
}
