import { useEffect, useState } from "react";
import { useRequest } from "../../Hooks/useRequest";

export function usePDFDisplayPage(id: number, name: string, apiString: string, restType, body)
{
    const [response, setResponse] = useState({})
    const [loading, setLoading] = useState({})
    const { getRequestBase64Conversion, postRequestBase64Conversion } = useRequest()

    useEffect(() =>
    {
        async function pullData()
        {
            setLoading(true)
            let docData
            if (restType === "POST")
            {
                docData = await postRequestBase64Conversion(body, `${apiString}`, "application/pdf")
            }
            else
            {
                docData = await getRequestBase64Conversion(`${apiString}`, "application/pdf")
            }
            if (docData)
            {
                if (docData && Object.keys(docData).length != 0)
                {
                    const downloadLink = document.createElement("a");
                    let fileName = name.includes("invoice") ? `Invoice_${id}` : "file";

                    //it's gross, but ask yourself, why did I have to do this
                    if (name.includes("tariff"))
                    {
                        let names = name.split("_")
                        fileName = names[0].replace(names[0][0], names[0][0].toUpperCase())
                            + " "
                            + names[1].replace(names[1][0], names[1][0].toUpperCase())
                    }

                    downloadLink.href = docData;
                    downloadLink.download = fileName;
                    downloadLink.click();
                }

                setResponse(docData)
                setLoading(false)
            }
        }

        pullData()
        // eslint-disable-next-line
    }, [apiString])

    return { response, loading };
}