import React from "react"
import { TabbedCardFormPage } from "../../components/Form/TabbedCardFormPage";
import { useProvisioningFormPage } from "./useProvisioningFormPage";
import { permissions } from "../../Constants/permissions";

export default function ProvisioningFormPage({ prop }: any)
{
    const { id } = prop.match.params

    const {
        loading,
        getTabs,
        state,
        updateForm,
        updateErrors,
        submitData,
        // changes,
        // resetChanges,
    } = useProvisioningFormPage(id)

    return (
        <React.Fragment>
            <TabbedCardFormPage
                configIdentifier={
                    state.provisioning && state.provisioning.macAddress
                }
                state={state}
                updateForm={updateForm}
                updateErrors={updateErrors}
                // changes={changes}
                // resetChanges={resetChanges}
                tabs={getTabs()}
                loading={loading}
                defineSubmitData={submitData}
                displayName={"Provisioning Device"}
                permissions={[permissions.provisioning, permissions.admin, permissions.VHAdmin]}
            />
        </React.Fragment>
    )
}
