import React from "react"
import Modal from "../Modal/Modal"
import { Button } from "react-bootstrap"
import { FormButtons } from "../Form/FormButtons"



export default function GenericWarningModal({
    text,
    open,
    setOpen
}: IWarningModal)
{
    return (
        <Modal open={open} setOpen={setOpen} maxWidth={700}>
            <h4> Warning </h4>
            {text}
            <FormButtons
                customButtons={
                    [
                        {
                            onClick: () => setOpen(false),
                            buttonText: "Continue",
                        }
                    ]
                }
            />
        </Modal>
    )
}