import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import React from "react"

export default function SimpleAccordion({ title, content, backgroundColor, expanded }: ISimpleAccordion)
{
    return (
        <Accordion style={{ width: "100%", backgroundColor: backgroundColor }} expanded={expanded === true ? true : undefined}>
            <AccordionSummary>
                {title}
            </AccordionSummary>
            <AccordionDetails>
                {content}
            </AccordionDetails>
        </Accordion >
    );
}