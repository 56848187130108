import React, { useState, useContext } from "react"
import { TabbedCardFormPage } from "../../components/Form/TabbedCardFormPage"
import { useBundlesFormPage } from "./useBundlesFormPage"
import Modal from "../../components/Modal/Modal"
import { useRequest } from "../../Hooks/useRequest"
import StagedForm from "../../components/Form/StagedForm"
import { SessionContext } from "../../Contexts/SessionContext"

export default function BundlesFormPage()
{

    const {
        loading,
        tabs,
        state,
        updateForm,
        updateErrors,
        selectedBundle,
        action,
        enableDisableModalOpen,
        setEnableDisableModalOpen,
        pullData,
        trunk,
        seat
    } = useBundlesFormPage()

    return (
        <>
            <TabbedCardFormPage
                state={state}
                updateForm={updateForm}
                updateErrors={updateErrors}
                tabs={tabs}
                loading={loading}
                displayName={"Bundles"}
            />
            <EnableDisableModal
                open={enableDisableModalOpen}
                setOpen={setEnableDisableModalOpen}
                action={action}
                selectedBundle={selectedBundle}
                pullData={pullData}
                trunk={trunk}
                seat={seat}
            />
        </>
    )
}

function EnableDisableModal({
    open,
    setOpen,
    action,
    selectedBundle,
    pullData,
    trunk,
    seat
})
{
    const { state: sessionState } = useContext(SessionContext)
    const [success, setSuccess] = useState<boolean>()
    const [feedbackMessage, setFeedbackMessage] = useState()

    const { deleteRequest, postRequest } = useRequest()

    function enableBundle()
    {
        return new Promise(async resolve =>
        {
            let response = await postRequest(
                {
                    bundleId: selectedBundle.id,
                    accountNo: sessionState.session.account_no
                },
                `/bundle`)
            resolve(response)
        })
    }

    function disableBundle()
    {
        return new Promise(async resolve =>
        {
            let response = await deleteRequest(null, `/bundle/cease/${selectedBundle.id}`)
            resolve(response)
        })
    }

    return (
        <Modal open={open} setOpen={setOpen} maxWidth={700}>
            <h4>{action && action.charAt(0).toUpperCase() + action.slice(1)} Bundle</h4>
            <StagedForm
                onComplete={() =>
                {
                    pullData && pullData()
                    setOpen(false)

                }}
                stages={[

                    {
                        title: "Confirm",
                        buttonText: `${action && action.charAt(0).toUpperCase() + action.slice(1)} Bundle`,
                        fields: [
                            {
                                type: "paragraph",
                                value: `Are you sure you wish to ${action} this bundle?`
                            },
                            {
                                bold: true,
                                type: "paragraph",
                                value: `${selectedBundle?.name} - ${selectedBundle?.contract} Months`
                            },
                            action === "disable" ? {
                                type: "paragraph",
                                value: "Please be aware that there may be multiple contracts associated with this bundle, that must still be paid."
                            }
                                :
                                selectedBundle?.type == "trunk" ?
                                {
                                    bold: true,
                                    type: "paragraph",
                                    value: `Amount of SIP Trunk channel bundle(s) being added:  ${trunk}`,
                                    fontSize: "1.1em"
                                } : 
                                    selectedBundle?.type == "seat" ?
                                    {
                                        bold: true,
                                        type: "paragraph",
                                        value: `Amount of Seat bundle(s) being added:  ${seat}`,
                                        fontSize: "1.1em"
                                    } : null,
                                {
                                    type: "paragraph",
                                    value: "Please be aware that by enabling this bundle, you are entering yourself into a contract."
                                },
                            {
                                type: "paragraph",
                                value: "For more information on this, please contact Sales."
                            },
                        ],
                        onComplete: {
                            function: () =>
                            {
                                return new Promise(async resolve =>
                                {
                                    let response
                                    if (action && action === "enable")
                                    {
                                        response = await enableBundle()
                                    }
                                    else if (action && action === "disable")
                                    {
                                        response = await disableBundle()
                                    }

                                    if (response)
                                    {
                                        setSuccess(!!response.success)
                                        if (response.message && !response.success)
                                        {
                                            setFeedbackMessage(response.message)
                                        }

                                        resolve({ success: true })
                                    }
                                    else
                                    {
                                        resolve({ success: false, error: "Something went wrong." });
                                    }
                                })
                            }
                        },
                    },
                    {
                        title: "Feedback",
                        fields: [
                            {
                                type: "paragraph",
                                value: `Bundle ${success ? "successfully" : "unsuccessfully"} ${action}d.`
                            },
                            !success && feedbackMessage && {
                                type: "paragraph",
                                value: feedbackMessage
                            }
                        ],
                    }
                ]}
            />
        </Modal>
    )
}