import { useContext, useEffect, useState } from "react"
import { SessionContext } from "../../Contexts/SessionContext"
import { ToastContext } from "../../Contexts/ToastContext"
import { useFormReducer } from "../../Hooks/useFormReducer"
import { useRequest } from "../../Hooks/useRequest"
import { regex } from "../../Constants/regex"
import { regexError } from "../../Constants/regexError"

export function useAccountDetailsFormPage()
{
    const [record, setRecord] = useState({})
    const [loading, setLoading] = useState(true)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const { getRequest } = useRequest()
    const { add } = useContext(ToastContext)
    const { state: session } = useContext(SessionContext)

    const {
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
    } = useFormReducer({ record })

    function showDelete()
    {
        if (session.account.parent == 10000 || session.account.parent == 0)
        {
            return true
        }
        else
        {
            return false
        }
    }

    function getBalanceAlert(): IFormField[]
    {
        if (!session.account.wholesale)
        {
            return [{
                type: "select",
                label: "Balance Alert",
                name: "alert.value",
                options: [
                    { value: 0, label: "Disabled" },
                    { value: 1, label: "< £1" },
                    { value: 1.5, label: "< £1.50" },
                    { value: 2, label: "< £2" },
                    { value: 2.5, label: "< £2.50" },
                    { value: 5, label: "< £5" },
                    { value: 10, label: "< £10" },
                    { value: 20, label: "< £20" },
                    { value: 50, label: "< £50" },
                ],
            }]
        }
        else
        {
            return [{}]
        }
    }

    function determineEndTypeValue(response)
    {
        if (response.endUserType == 0)
        {
            return response.endUserType = "Business User"
        }
        else if (response.endUserType == 1)
        {
            return response.endUserType = "Small / Microenterprise"
        }
        else if (response.endUserType == 2)
        {
            return response.endUserType = "Consumer / End User"
        }
    }

    useEffect(() =>
    {
        async function pullData()
        {
            if (session.session.account_no)
            {
                setLoading(true)
                // @ts-ignore
                let response = await getRequest(
                    `/account/` + session.session.account_no
                )
                let responseAlert = await getRequest(`/account/alert/balance`)

                if (response && responseAlert)
                {   
                    setRecord({
                        account: {
                            ...response,
                            firstName: response.firstName,
                            surname: response.surname,
                            address_line1: response.address_line1,
                            address_line2: response.address_line2,
                            city: response.address_city,
                            company: response.company,
                            county: response.address_county,
                            postcode: response.address_postcode,
                            telephone: response.telephone,
                            fax: response.fax,
                            mobile: response.mobile,
                            email: response.email,
                            endUserType: determineEndTypeValue(response),
                            vulnerablePerson: response.vulnerablePerson
                        },
                        alert: responseAlert
                    })
                } else if (responseAlert)
                {
                    setRecord({ alert: responseAlert })
                } else if (response)
                {
                    setRecord({
                        account: {
                            ...response,
                            firstName: response.firstName,
                            surname: response.surname,
                            address_line1: response.address_line1,
                            address_line2: response.address_line2,
                            city: response.city,
                            company: response.company,
                            county: response.address_county,
                            postcode: response.address_postcode,
                            telephone: response.telephone,
                            fax: response.fax,
                            mobile: response.mobile,
                            email: response.email,
                            endUserType: response.endUserType,
                            vulnerablePerson: response.vulnerablePerson
                        }
                    })
                } else
                {
                    add({
                        text: "The request failed.",
                        type: "error",
                    })
                }
                setLoading(false)
            }
        }

        pullData()
        // eslint-disable-next-line
    }, [session.session.account_no])

    const tabs: ITab[] = [
        {
            title: "Account Details",
            icon: "file-alt",
            disabled: false,
            sections: [
                {
                    title: "Contact Details",
                    fields: [
                        {
                            type: "text",
                            label: "First Name",
                            name: `account.firstName`,
                            maxLength: 45,
                        },
                        {
                            type: "text",
                            label: "Surname",
                            name: `account.surname`,
                            maxLength: 45,
                        },
                        {
                            type: "text",
                            label: "Telephone",
                            name: `account.telephone`,
                            regex: regex.telephoneNumber,
                            regexError: regexError.telephoneNumber
                        },
                        {
                            type: "text",
                            label: "Fax",
                            name: `account.fax`,
                            regex: regex.telephoneNumber,
                            regexError: regexError.telephoneNumber
                        },
                        {
                            type: "text",
                            label: "Mobile",
                            name: `account.mobile`,
                            regex: regex.telephoneNumber,
                            regexError: regexError.telephoneNumber
                        },
                        {
                            type: "text",
                            label: "Email",
                            name: `account.email`,
                            regex: regex.email,
                            regexError: regexError.email,
                            maxLength: 100
                        },
                        {
                            type: "info",
                            label: "End User Type",
                            name: `account.endUserType`,
                        },
                        {
                            type: "checkbox",
                            label: "Vulnerable Person",
                            name: `account.vulnerablePerson`,
                            conditionallyShow: { checks: [{ field: "account.endUserType", value: "Consumer / End User"  }] },
                            disabled: true
                        },
                    ],
                },
                {
                    title: "Company Details",
                    fields: [
                        {
                            type: "info",
                            label: "Company",
                            name: `account.company`,
                        },
                        {
                            type: "text",
                            label: "Premises",
                            name: `account.address_line1`,
                            maxLength: 100,
                        },
                        {
                            type: "text",
                            name: `account.address_line2`,
                            maxLength: 100,
                        },
                        {
                            type: "text",
                            label: "City",
                            name: `account.address_city`,
                            maxLength: 100,
                        },
                        {
                            type: "text",
                            label: "County",
                            name: `account.address_county`,
                            maxLength: 100,
                        },
                        {
                            type: "text",
                            label: "Postcode",
                            name: `account.address_postcode`,
                            regex: regex.postcode,
                            regexError: regexError.postcode
                        },
                        ...getBalanceAlert()
                    ]
                },
            ],
        },
        showDelete() && {
            title: "Delete Account",
            icon: "trash-alt",
            sections: [
                {
                    fields: [
                        {
                            type: "info",
                            bold: true,
                            value: "Warning!",
                        },
                        {
                            type: "info",
                            value:
                                "This action cannot be undone, please proceed with caution.",
                        },
                        {
                            type: "button",
                            buttonVariant: "outline-danger",
                            placeholder: "Delete Account",
                            onClick: () => setDeleteModalOpen(true),
                        },
                    ],
                },
            ],
        },
    ]

    function handleSubmit()
    {
        return [
            {
                body: {
                    firstName: state.account.firstName ? state.account.firstName : null,
                    surname: state.account.surname ? state.account.surname : null,
                    address_line1: state.account.address_line1 ? state.account.address_line1 : null,
                    address_line2: state.account.address_line2 ? state.account.address_line2 : null,
                    address_city: state.account.address_city ? state.account.address_city : null,
                    company: state.account.company ? state.account.company : null,
                    address_county: state.account.address_county ? state.account.address_county : null,
                    address_postcode: state.account.address_postcode ? state.account.address_postcode : null,
                    telephone: state.account.telephone ? state.account.telephone : null,
                    fax: state.account.fax ? state.account.fax : null,
                    mobile: state.account.mobile ? state.account.mobile : null,
                    email: state.account.email ? state.account.email : null,
                    alert: state.alert.value,
                    alertActive: state.alert.value > 0
                },
                formPrefix: "account",
                path: `/account/` + session.session.account_no,
            }
        ]
    }

    return {
        loading,
        tabs,
        state,
        updateState,
        updateForm,
        updateErrors,
        deleteModalOpen,
        setDeleteModalOpen,
        handleSubmit,
        // changes,
        // resetChanges,
    }
}
