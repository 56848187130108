import { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom";
import { ToastContext } from "../../Contexts/ToastContext";
import { SessionContext } from "../../Contexts/SessionContext"
import { useFormReducer } from "../../Hooks/useFormReducer";
import { useRequest } from "../../Hooks/useRequest";
import { sipconvergenceReformatter } from "../../Common/urlHelpers";
import { hasPermission, permissions } from "../../Constants/permissions";

export function useIntegrationsFormPage(
    setShowModal: (show: boolean) => void,
    setModalContent: (content: "addUser" | "viewUsers" | "confirmActivation" | "activateCustomerLicense" | "deactivateCustomerLicense" | "activateUserLicense" | "deactivateUserLicense" | "bulkActivateUsers" | "bulkDeactivateUsers" | null) => void,
    modalContent: "addUser" | "viewUsers" | "confirmActivation" | "activateCustomerLicense" | "deactivateCustomerLicense" | "activateUserLicense" | "deactivateUserLicense" | "bulkActivateUsers" | "bulkDeactivateUsers" | null
)
{
    type DownloadLinks = {
        windows?: string;
        mac?: string;
    };

    const { add } = useContext(ToastContext)
    const [record, setRecord] = useState({})
    const [loading, setLoading] = useState(true)
    const [downloads, setDownloads] = useState<DownloadLinks>({})
    const [user, setUser] = useState<any>(null)
    const [customer, setCustomer] = useState<any>(null)
    const [confirmationData, setConfirmationData] = useState<any>(null)
    const [activationType, setActivationType] = useState<any>(null)
    const [deactivationType, setDeactivationType] = useState<any>(null)
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [activeAccountLicense, setActiveAccountLicense] = useState(false);
    const [activeUserLicense, setActiveUserLicense] = useState(false);
    const [customerExists, setCustomerExists] = useState(false);
    const [offset, setOffset] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [users, setUsers] = useState([]);
    const [activeUsers, setActiveUsers] = useState([])
    const [preferences, setPreferences] = useState(false)
    const { state: session } = useContext(SessionContext)
    const { getRequest, postRequest, deleteRequest, putRequest } = useRequest()
    const accountNo = session.session.account_no;
    const someLimit = 300;

    const getAccUsers = async () => 
    {
        setLoading(true);
        try 
        {
            const accountNo = session.session.account_no;
            const response = await getRequest(`/redcactus/users/${accountNo}/${offset}/${someLimit}`);

            if (response?.users)
            {
                setUsers(response.users);
                setTotalUsers(response.total);
            }
        }
        catch (error) 
        {
            console.error("Error fetching users:", error);
        }
        finally
        {
            setLoading(false)
        }
    };

    useEffect(() =>
    {
        pullData();
        getBubbleDownloads();
    }, []);

    useEffect(() =>
    {
        if (modalContent === "bulkActivateUsers") 
        {
            getAccUsers();
        }
        else if (modalContent === "bulkDeactivateUsers") 
        {
            getActiveUsers();
        }
    }, [offset, modalContent]);


    const getActiveUsers = async () => 
    {
        setLoading(true);
        try 
        {
            const accountNo = session.session.account_no;
            const response = await postRequest({}, `/redcactus/users/active/${accountNo}/${offset}/${someLimit}`,);

            if (response?.records) 
            {
                setActiveUsers(response.records);
            }
        }
        catch (error) 
        {
            console.error("Error fetching users for activation:", error);
        }
        finally
        {
            setLoading(false);
        }
    };

    const nextPage = () =>
    {
        if (offset + someLimit < totalUsers)
        {
            setOffset(prevOffset => prevOffset + someLimit);
        }
    };

    const prevPage = () =>
    {
        if (offset > 0)
        {
            setOffset(prevOffset => Math.max(prevOffset - someLimit, 0));
        }
    };

    const toggleUserSelection = (user) => 
    {
        if (selectedUsers.some((selectedUser) => selectedUser.id === user.id)) 
        {
            setSelectedUsers(selectedUsers.filter((selectedUser) => selectedUser.id !== user.id));
        }
        else 
        {
            setSelectedUsers([...selectedUsers, user]);
        }
    };

    const bulkActivateUsers = async () =>
    {
        try
        {
            const usersPayload = selectedUsers.map((user) => ({
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                id: user.id
            }));

            const response = await postRequest({ users: usersPayload }, "/redcactus/users/bulkActivate");

            if (response)
            {
                add({ text: "Users successfully activated", type: "success" });
                setShowModal(false);
                setSelectedUsers([]);
            }
            else
            {
                add({ text: "Bulk activation failed", type: "error" });
            }
        }
        catch (error)
        {
            console.error("Error activating users:", error);
            add({ text: "An error occurred during activation", type: "error" });
        }
    };

    const bulkDeactivateUsers = async () =>
    {
        try
        {
            const usersPayload = selectedUsers.map((user) => ({
                email: user.email,
                firstName: user.name,
                lastName: user.surname,
                id: user.userId,
            }));

            const response = await postRequest({ users: usersPayload }, "/redcactus/users/bulkDeactivate");

            if (response)
            {
                add({ text: "Users successfully deactivated", type: "success" });
                setShowModal(false);
                setSelectedUsers([]);
            }
            else
            {
                add({ text: "Bulk deactivation failed", type: "error" });
            }
        }
        catch (error)
        {
            console.error("Error deactivating users:", error);
            add({ text: "An error occurred during deactivation", type: "error" });
        }
    };

    async function pullData()
    {
        setLoading(true)
        let data = {}

        const tokenResponse = await getRequest('/integration/token/-1/-1')
        const zoho = await getRequest('/zoho/active')
        const bubbleDownloads = await getRequest('/redcactus/downloads');
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const rcLicenseCheck = await getRequest('/redcactus/customer/own')
        const rcUserLicenseCheck = await getRequest('/redcactus/user/own');

        if (rcLicenseCheck)
        {
            if (rcLicenseCheck.active)
            {
                setCustomerExists(true)
                setActiveAccountLicense(true);
            }
            else if (rcLicenseCheck.active === false)
            {
                setCustomerExists(true)
            }
        }

        if (rcUserLicenseCheck)
        {
            if (rcUserLicenseCheck?.active)
            {
                setActiveUserLicense(true);
            }
            if (rcUserLicenseCheck?.preferences)
            {
                setPreferences(true);
            }
        }

        if (session.session.account_no)
        {
            // @ts-ignore
            let response = await getRequest(
                `/account/` + session.session.account_no
            )

            if (response)
            {
                let customerData =
                {
                    company: response.company,
                    address: `${response.address_line1} ${response.address_line2}`,
                    postal: response.address_postcode,
                    city: response.address_city,
                    country_code: response.countryCode,
                    timezone: timezone,
                    active: true,
                };

                let userData =
                {
                    email: response.email,
                    firstname: response.firstName,
                    lastname: response.surname,
                    active: true,
                    has_password: false
                }

                setUser(userData)
                setCustomer(customerData)
            }
            else
            {
                add({
                    text: "The request failed.",
                    type: "error",
                })
            }
        }

        if (tokenResponse?.records)
        {
            tokenResponse.records.forEach((token) =>
            {
                switch (token.integration)
                {
                    case "zoho":
                        data = { ...data, zohoAccessToken: token.accessToken }
                        break;
                    case "hubspot":
                        data = { ...data, hubspotAccessToken: token.accessToken }
                        break;
                    case "redcactus":
                        data = { ...data, redcactusAccessToken: token.accessToken }
                        break;
                }
            })
        }
        if (zoho?.success)
        {
            data = { ...data, active: zoho.active }
        }

        setRecord(data)
        setLoading(false)
    }

    async function redCactusActivation(userData, customerData, activationType) 
    {
        try 
        {
            let response;

            if (activationType === 'customer' && customerExists && !activeAccountLicense)
            {
                response = await postRequest(customerData, '/redcactus/customers/license');
            }
            else if (activationType === 'customer' && !customerExists)
            {
                response = await postRequest(customerData, '/redcactus/customers');
            }
            else if (activationType === 'user')
            {
                response = await postRequest(userData, '/redcactus/user')
            }

            if (response && response?.success) 
            {
                add({
                    text: "Activation was successful.",
                    type: "success",
                });

                window.location.reload()
            }
            else 
            {
                add({
                    text: "Activation failed.",
                    type: "error",
                });
            }
        }
        catch (error) 
        {
            add({
                text: "An error occurred during activation.",
                type: "error",
            });
        }
    }

    async function redCactusDeactivation(userData, customerData, deactivationType) 
    {
        try 
        {
            let response;

            if (deactivationType === 'customer')
            {
                response = await putRequest(customerData, '/redcactus/customers/deactivate');
            }
            else if (deactivationType === 'user')
            {
                response = await putRequest(userData, '/redcactus/user')
            }

            if (response && response?.success) 
            {
                add({
                    text: "Deactivation was successful.",
                    type: "success",
                });

                window.location.reload()
            }
            else 
            {
                add({
                    text: "Deactivation failed.",
                    type: "error",
                });
            }
        }
        catch (error) 
        {
            add({
                text: "An error occurred during deactivation.",
                type: "error",
            });
        }
    }

    const {
        state,
        updateState,
        updateForm,
        updateErrors,
    } = useFormReducer({ record })

    const history = useHistory()

    async function zohoActivate(activate)
    {
        let zohoResponse
        if (activate)
        {
            zohoResponse = await postRequest(null, `/zoho/activation`)
        }
        else
        {
            zohoResponse = await deleteRequest(null, `/zoho/activation`)
        }
        if (zohoResponse.success && zohoResponse.success)
        {
            setRecord({ ...record, active: activate })
            add({
                type: "success",
                text: zohoResponse.message
            })
        }
        else
        {
            add({
                type: "error",
                text: zohoResponse.message
            })
        }
    }

    function getZohoActivationFields(data): IFormField[]
    {
        let fields = []
        if (data && data.zohoAccessToken)
        {
            fields = [
                {
                    type: "info",
                    label: "Login with Zoho",
                    value: "Logged in",
                },
                {
                    type: "info",
                    label: "Status",
                    value: data.active == true ? "Activated" : "Inactive"
                }
            ]
            if (data.active)
            {
                fields = [...fields,
                {
                    type: "button",
                    placeholder: "Deactivate",
                    onClick: () =>
                    {
                        zohoActivate(false)
                    }
                }]
            }
            else
            {
                fields = [...fields,
                {
                    type: "button",
                    placeholder: "Activate",
                    onClick: () =>
                    {
                        zohoActivate(true)
                    }
                }]
            }
        }
        else
        {
            fields = [
                {
                    type: "button",
                    label: "Login with Zoho",
                    placeholder: "Login",
                    onClick: () => (history.push("/zoho")),
                }
            ]
        }

        return fields

    }

    function getHubspotActivationFields(data): IFormField[]
    {
        let fields = []
        if (data && data.hubspotAccessToken)
        {
            fields = [
                {
                    type: "info",
                    value: "VoiceHost installed into Hubspot"
                },
            ]
        }
        else
        {
            fields = [
                {
                    type: "link",
                    value: "https://app-eu1.hubspot.com/oauth/authorize?client_id=91fd027b-1281-4628-953d-a8b14d65f4d6&redirect_uri=https://cp.voicehost.co.uk/hubspotcallback&scope=crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.custom.read",
                    // value: "https://app-eu1.hubspot.com/oauth/authorize?client_id=91fd027b-1281-4628-953d-a8b14d65f4d6&redirect_uri=https://cp3.dev.voicehost.co.uk/hubspotcallback&scope=crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.custom.read",
                    // value: "https://app-eu1.hubspot.com/oauth/authorize?client_id=91fd027b-1281-4628-953d-a8b14d65f4d6&redirect_uri=http://localhost:3000/hubspotcallback&scope=crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.custom.read",
                    text: "Install VoiceHost into my Hubspot",
                    newTab: true
                }
            ]
        }

        return fields

    }

    function getZohoFeaturesFields(data): IFormField[]
    {
        let isActive = "Inactive"
        if (data && data.active && data.active)
        {
            isActive = "Active"
        }

        return [
            {
                type: "info",
                label: "Call Notify",
                value: isActive,
            },
            {
                type: "info",
                label: "Click To Dial",
                value: isActive,
            }
        ]
    }

    function getRedCactusFeaturesFields(data): IFormField[]
    {
        const fields: IFormField[] = [];

        if (activeAccountLicense)
        {
            fields.push(!hasPermission([permissions.VHAdmin], session.session.permissions, "read") && {
                type: "info",
                value: "You do not have the required permissions to access this section.",
                noLabel: true
            });

            fields.push(hasPermission([permissions.VHAdmin], session.session.permissions, "read") && {
                type: "button",
                placeholder: "Deactivate Company Bubble License",
                noLabel: true,
                onClick: () =>
                {
                    handleDeactivateClick()
                },
            });
        }

        else 
        {
            fields.push(!hasPermission([permissions.VHAdmin], session.session.permissions, "read") && {
                type: "info",
                value: "You do not have the required permissions to access this section.",
                noLabel: true
            });

            fields.push(hasPermission([permissions.VHAdmin], session.session.permissions, "read") && {
                type: "button",
                placeholder: "Activate Company Bubble License",
                noLabel: true,
                onClick: () =>
                {
                    handleActivateClick()
                },
            });
        }

        if (activeAccountLicense)
        {
            fields.push(hasPermission([permissions.VHAdmin], session.session.permissions, "read") && {
                type: "button",
                placeholder: "Activate Users",
                noLabel: true,
                onClick: () =>
                {
                    handleBulkActivateClick();
                },
            });

            fields.push(hasPermission([permissions.VHAdmin], session.session.permissions, "read") && {
                type: "button",
                placeholder: "Deactivate Users",
                noLabel: true,
                onClick: () =>
                {
                    handleBulkDeactivateClick();
                },
            });
        }

        return fields;
    }


    function getRedcactusActivationFields(data): IFormField[]
    {
        const fields: IFormField[] = [
            {
                type: "info",
                value: "You'll need to install Bubble using the relevant link below.",
                noLabel: true
            },
        ];

        if (downloads?.windows)
        {
            fields.push({
                type: "button",
                placeholder: "Download for Windows",
                noLabel: true,
                onClick: () => window.location.href = downloads.windows
            });
        }

        if (downloads?.mac)
        {
            fields.push({
                type: "button",
                placeholder: "Download for Mac",
                noLabel: true,
                onClick: () => window.location.href = downloads.mac
            });
        }
        return fields;
    }

    function getRedCactusPreferencesStatus(data): IFormField[]
    {
        const fields: IFormField[] = [];

        if (activeAccountLicense && !preferences)
        {
            fields.push({
                type: "info",
                value: "Upon being activated successfully, you will still need to set your login preferences before you begin using Bubble. Please set them using the link below.",
                noLabel: true
            });

            fields.push({
                type: "button",
                placeholder: "Set Login Preferences",
                noLabel: true,
                onClick: () => window.location.href = '/integrations/redcactus/preferences'
            });
        }
        else if (activeAccountLicense && preferences)
        {
            fields.push({
                type: "info",
                value: "Your preferences have been set, your user has been fully activated for Bubble useage!",
                noLabel: true
            });
        }
        else 
        {
            fields.push({
                type: "info",
                value: "Awaiting license and user activation",
                noLabel: true
            });
        }

        if (activeAccountLicense)
        {
            fields.push(hasPermission([permissions.VHAdmin], session.session.permissions, "read") && {
                type: "table",
                searchable: true,
                body: { column: "preferences", direction: "ASC" },
                columns: [
                    {
                        title: "Email",
                        name: "email",
                        sortBy: true,
                    },
                    {
                        title: "First Name",
                        name: "name",
                        sortBy: true,
                    },
                    {
                        title: "Last Name",
                        name: "surname",
                        sortBy: true,
                    },
                    {
                        title: "Preferences",
                        name: "preferences",
                        sortBy: true,
                        conditional: [{ value: false, text: "Pending" }, { value: true, text: "Set" }]
                    }
                ],
                restType: "POST",
                urlString: `/redcactus/users/active/${accountNo}`,
            });
        }

        return fields;
    }

    async function getBubbleDownloads()
    {
        type DownloadLinks = {
            windows?: string;
            mac?: string;
        };

        let downloads: DownloadLinks = {};

        try
        {
            const redCactusResponse = await getRequest('/redcactus/downloads');

            if (redCactusResponse && Array.isArray(redCactusResponse.downloads))
            {
                const downloadArray = redCactusResponse.downloads;

                downloadArray.forEach((downloadObj) =>
                {
                    if (downloadObj.Windows) 
                    {
                        downloads.windows = downloadObj.Windows;
                    }

                    if (downloadObj.Mac)
                    {
                        downloads.mac = downloadObj.Mac;
                    }
                });
                setDownloads(downloads);
            }
            else
            {
                console.error("Unexpected response format or missing 'downloads' key:", redCactusResponse);
            }
        }

        catch (error)
        {
            console.error("Error fetching downloads:", error);
        }

        return downloads;
    }

    const handleBulkActivateClick = () => 
    {
        setModalContent("bulkActivateUsers");
        getAccUsers();
        setShowModal(true);
    };

    const handleBulkDeactivateClick = () =>
    {
        setModalContent("bulkDeactivateUsers");
        getActiveUsers();
        setShowModal(true);
    };


    const tabs: ITab[] = [
        {
            title: "Red Cactus",
            icon: "plug",
            disabled: false,
            sections: [
                {
                    title: "Integrations",
                    fields: getRedcactusActivationFields(record)
                },
                {
                    title: "Activation/Users",
                    fields: getRedCactusFeaturesFields(record),
                },
                {
                    title: "Activated Users",
                    fields: getRedCactusPreferencesStatus(record),
                    fullWidth: true
                }
            ],
        },
        {
            title: "Zoho",
            icon: "handshake",
            disabled: false,
            sections: [
                {
                    title: "Activation",
                    fields: getZohoActivationFields(record),
                },
                {
                    title: "Features",
                    fields: getZohoFeaturesFields(record)
                }
            ],
        },
        {
            title: "Hubspot",
            icon: "hubspot",
            disabled: false,
            sections: [
                {
                    fields: getHubspotActivationFields(record)
                }
            ],
        },
    ]

    const handleActivateClick = () =>
    {
        if (user)
        {
            setConfirmationData(user);
            setModalContent("confirmActivation");
            setActivationType("customer");
            setShowModal(true);
        }
        else
        {
            add({ text: "User data is not loaded.", type: "error" });
        }
    };

    const handleDeactivateClick = () =>
    {
        if (user)
        {
            setModalContent("deactivateCustomerLicense");
            setDeactivationType("customer");
            setShowModal(true);
        }
        else
        {
            add({ text: "User data is not loaded.", type: "error" });
        }
    };

    return {
        loading,
        tabs,
        state,
        user,
        customer,
        confirmationData,
        activationType,
        deactivationType,
        activeAccountLicense,
        activeUserLicense,
        users,
        activeUsers,
        selectedUsers,
        offset,
        totalUsers,
        someLimit,
        nextPage,
        prevPage,
        setConfirmationData,
        setActivationType,
        setDeactivationType,
        updateState,
        updateForm,
        updateErrors,
        redCactusActivation,
        redCactusDeactivation,
        handleActivateClick,
        getAccUsers,
        toggleUserSelection,
        bulkActivateUsers,
        bulkDeactivateUsers,
        setSelectedUsers
    }
}