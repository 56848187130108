import GradientBackground from "../GradientBackground/GradientBackground";
import Icon from "../Icons/Icon";
import { Col, Row } from "react-grid-system";
import Card from "../Card/Card";
import React, { useContext } from "react";
import "./dashboardWidgets.scss"
import { SessionContext } from "../../Contexts/SessionContext";
import { hasPermission, permissions } from "../../Constants/permissions";
import DashboardSummaryItem from "./DashboardSummaryItem";


export default function DashboardSummaryGrid({ fullWidth = false })
{
    const { state: sessionState } = useContext(SessionContext)

    return (
        <Card noPadding style={{ height: "100%", marginBottom: 16 }}>
            <div
                className={"notificationsWidgetTitle"}
            >
                <GradientBackground
                    colors={["#489816", '#1e5b17']}
                    angle={0.6}
                    className="dashboardSummaryUpperSection"
                >
                    <div className="dashboardSummaryIcon">
                        <Icon
                            icon={"user-circle"}
                            color={"#174412"}
                            size={"2x"}
                        />
                    </div>
                    <div className="dashboardSummaryTitle">Account Activity</div>
                    <div className="dashboardSummarySpacer" />
                </GradientBackground>
            </div>

            <div
                style={{
                    fontSize: "0.9em"
                }}
            >
                <Row nogutter>
                    <DashboardSummaryItem
                        title={"Seats"}
                        link={"/seats"}
                        icon={"cogs"}
                        color={"blue"}
                        requestUrl={"/seat"}
                        requestType={"POST"}
                        hidden={!hasPermission([permissions.seats, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read")}
                    />
                    <DashboardSummaryItem
                        title={"Telephone Numbers"}
                        link={"/telephone-numbers"}
                        icon={"phone"}
                        color={"yellow"}
                        requestUrl={"/numbering/allocated"}
                        requestType={"POST"}
                        hidden={!hasPermission([permissions.numbers, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read")}
                    />
                    <DashboardSummaryItem
                        title={"Call Groups"}
                        link={"/call-groups"}
                        icon={"table"}
                        color={"red"}
                        requestUrl={"/callgroup"}
                        requestType={"POST"}
                        hidden={!hasPermission([permissions.callGroups, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read")}
                    />
                    <DashboardSummaryItem
                        title={"Call Queues"}
                        link={"/call-queues"}
                        icon={"sort-amount-down"}
                        color={"green"}
                        requestUrl={"/queue"}
                        requestType={"POST"}
                        hidden={!hasPermission([permissions.queues, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read")}
                    />
                    <DashboardSummaryItem
                        title={"IVR Menus"}
                        link={"/ivrs"}
                        icon={"share-alt"}
                        color={"blue"}
                        requestUrl={"/ivr"}
                        requestType={"POST"}
                        hidden={!hasPermission([permissions.IVR, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read")}
                    />
                    <DashboardSummaryItem
                        title={"NTS Destinations"}
                        link={"/nts"}
                        icon={"external-link-alt"}
                        color={"red"}
                        requestUrl={"/nts"}
                        requestType={"POST"}
                        hidden={!hasPermission([permissions.externalExt, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read")}
                    />
                    <DashboardSummaryItem
                        title={"SIP Trunks"}
                        link={"/sip"}
                        icon={"link"}
                        color={"yellow"}
                        requestUrl={"/trunk"}
                        requestType={"POST"}
                        hidden={!hasPermission([permissions.SIPTrunks, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read")}
                    />
                    <DashboardSummaryItem
                        title={"Time Profiles"}
                        link={"/time-profiles"}
                        icon={"clock"}
                        color={"green"}
                        requestUrl={"/timeprofile"}
                        requestType={"POST"}
                        hidden={!hasPermission([permissions.timeprofile, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read")}
                    />
                </Row>
            </div>
        </Card>
    )
}

