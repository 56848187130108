import React, { useContext } from "react";
import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage";
import { useHistory } from "react-router-dom"
import useAPIRequests from "../../Hooks/useAPIRequests";
import { permissions } from "../../Constants/permissions";

export default function BlackWhiteListIndexPage()
{

    const history = useHistory()
    const { getPromptPromise } = useAPIRequests()

    const columns: IColumn[] = [
        {
            name: "name",
            onClick: id =>
            {
                history.push(`/access-lists/${id}`)
            },
            bold: true,
            title: "Name",
            sortBy: true,
            conditional: [{
                value: "",
                text: "Name not set",
            }],
        },
        {
            name: "description",
            hide: true,
            title: "Description",
            sortBy: true,
        },
        {
            name: "prompt",
            hide: true,
            title: "Prompt",
            sortBy: true,
            conditional: [{
                value: null,
                text: "None",
            }],
        },
        {
            name: "numEntries",
            title: "Entries",
            sortBy: true,
        }
    ]

    const modalSettings: ISettingsStage[] = [
        {
            title: "Settings",
            fields: [
                {
                    type: "text",
                    name: "description",
                    label: "Description",
                    maxLength: 50
                },
                {
                    type: "select",
                    label: "Prompt",
                    name: "promptId",
                    loadOptions: () => getPromptPromise(),
                    options: [{ label: "None", value: -1 }],
                    defaultValue: -1
                }
            ]
        }]


    return (
        <GenericIndexPage
            title={"Blacklist/Whitelist"}
            urlString={"/accesscontrol"}
            permissions={[permissions.acl, permissions.admin, permissions.VHAdmin]}
            columns={columns}
            searchable={true}
            restType={"POST"}
            addModal={{
                displayName: "Black/White list",
                allowBulkAdd: false,
                fieldFrom: {
                    field: "name",
                    label: "Black/White List Name",
                    type: "text",
                    maxLength: 50,
                    required: true
                },
                settingsStages: modalSettings,
                submitData: (data) =>
                {
                    return [{
                        postData: {
                            description: data.description,
                            promptId: data.promptId
                        },
                        path: "/accesscontrol",
                        identifier: "name",
                        type: "create",
                        displayName: data.fromField

                    }]
                }
            }}
            deleteModal={{
                displayName: "Black/White list",
                uniqueIdentifier: "name",
                submitData: () =>
                {
                    return [{
                        path: "/accesscontrol",
                        displayName: "name",
                        type: "delete"
                    }]
                }
            }}
            helpSections={[
                {
                    title: "Black/White Lists",
                    text: "Allows you to control the numbers that can be called, or can call you."
                }
            ]}
        />

    )
}