import React, { useState } from "react"
import { usePhoneDirectoryFormPage } from "./usePhoneDirectoryFormPage"
import { TabbedCardFormPage } from "../../components/Form/TabbedCardFormPage"
import { RecordModal } from "../../components/RecordModal/RecordModal"
import { permissions } from "../../Constants/permissions"

export default function PhoneDirectoryFormPage({ prop }: any)
{
    const { entry, directory } = prop.match.params
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    const {
        loading,
        tabs,
        state,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
        submitData,
        deleteData,
        specialBreadcrumbIndices
    } = usePhoneDirectoryFormPage(entry, directory)

    return (
        <React.Fragment>
            <TabbedCardFormPage
                state={state}
                updateForm={updateForm}
                updateErrors={updateErrors}
                configIdentifier={(state.givenName ? state.givenName + " " : "") + state.surname}
                // changes={changes}
                // resetChanges={resetChanges}
                tabs={tabs}
                loading={loading}
                permissions={[permissions.ldap, permissions.admin, permissions.VHAdmin]}
                defineSubmitData={submitData}
                onDelete={() => setDeleteModalOpen(true)}
                displayName={"Entry"}
                specialBreadcrumbIndices={specialBreadcrumbIndices}
            />
            <RecordModal
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
                action={"delete"}
                selectedRecords={[{ entry }]}
                displayName={"LDAP Entry"}
                uniqueIdentifier={"entry"}
                apiUniqueIdentifier={"entry"}
                defineSubmitData={deleteData}
            />
        </React.Fragment>
    )
}
