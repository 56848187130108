import { useContext, useEffect, useState } from "react"
import { hasPermission, permissions } from "../../Constants/permissions"
import { SessionContext } from "../../Contexts/SessionContext"
import { useFormReducer } from "../../Hooks/useFormReducer"
import { useRequest } from "../../Hooks/useRequest"
import { regex } from "../../Constants/regex"
import { regexError } from "../../Constants/regexError"
import useAPIRequests from "../../Hooks/useAPIRequests"

export function useNTSDestinationsFormPage(id: number)
{
    const [record, setRecord] = useState({})
    const [loading, setLoading] = useState(true)
    const { getRequest } = useRequest()
    const { getPromptPromise } = useAPIRequests()
    const {
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
    } = useFormReducer({ record })

    const { state: sessionState } = useContext(SessionContext)

    useEffect(() =>
    {
        async function pullData()
        {
            setLoading(true)
            let response = await getRequest(`/nts/${id}`)
            if (response)
            {
                setRecord(response)
            }
            setLoading(false)
        }

        pullData()
        // eslint-disable-next-line
    }, [id])

    function submitData()
    {
        return [
            {
                body: state,
                path: "/nts/" + id,
            },
        ]
    }

    function deleteData()
    {
        return [{
            path: "/nts",
            type: "delete",
            indexPagePath: "/nts",
            displayName: "NTS Destination"
        }]
    }
    const tabs: ITab[] = [
        {
            title: "General",
            icon: "toolbox",
            disabled: false,
            sections: [
                {
                    title: "NTS Destination Settings",
                    fields: [
                        {
                            type: "text",
                            label: "Name",
                            name: "name",
                            placeholder: "name",
                            maxLength: 45,
                            helptext:
                                "The name allows you to tag the NTS destination with a name so you can identify it easily throughout the control panel",
                        },
                        {
                            type: "text",
                            label: "Destination",
                            name: "destination",
                            placeholder: "Destination",
                            helptext: "Telephone number of the NTS destination",
                            regex: regex.telephoneNumber,
                            regexError: regexError.telephoneNumber
                        },
                        {
                            type: "info",
                            label: "Channel Limit",
                            name: "options.channelLimit",
                        },
                        {
                            type: "switch",
                            label: "Enable Refuse Call",
                            name: "options.extRefuse"
                        },
                        {
                            type: "select",
                            label: "Prompt Before Answer",
                            name: "answerPromptId",
                            loadOptions: getPromptPromise,
                            options: [
                                { value: -1, label: "None" },
                            ]
                        }
                    ],
                },
                {
                    title: "Call Restrictions",
                    fields: [
                        {
                            type: "switch",
                            label: "Allow UK Geographic",
                            name: "options.allowUKGeo",
                            halfWidth: true,
                            helptext: "(01, 02 & 03)",
                            disabled: !hasPermission([permissions.ntsCallRestrictions, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                        },
                        {
                            type: "switch",
                            label: "Allow UK Mobile",
                            name: "options.allowUKMobile",
                            halfWidth: true,
                            helptext: "(071 to 079)",
                            disabled: !hasPermission([permissions.ntsCallRestrictions, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                        },
                        {
                            type: "switch",
                            label: "Allow UK Directory",
                            name: "options.allowUK118",
                            halfWidth: true,
                            helptext: "(118)",
                            disabled: !hasPermission([permissions.ntsCallRestrictions, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                        },
                        {
                            type: "switch",
                            label: "Allow Other UK Calls",
                            name: "options.allowUKOther",
                            helptext: "(08xx)",
                            halfWidth: true,
                            disabled: !hasPermission([permissions.ntsCallRestrictions, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                        },
                        {
                            type: "switch",
                            label: "Allow International Calls",
                            name: "options.allowInternational",
                            halfWidth: true,
                            disabled: !hasPermission([permissions.highRisk, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                        },
                        {
                            conditionallyShow: {
                                checks: [{
                                    field: "options.allowInternational",
                                    value: true,
                                }]
                            },
                            type: "switch",
                            label: "Allow High Risk Zone",
                            name: "options.allowInternationalHighRisk",
                            halfWidth: true,
                            helptext:
                                "High Risk Zones are destinations that are considered to have high or extreme risks of telecommunications fraud based on current data",
                            disabled: !hasPermission([permissions.highRisk, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                        },
                        {
                            type: "switch",
                            label: "Allow UK Premium Calls (09)",
                            name: "options.allowUKPremium09",
                            halfWidth: true,
                            disabled: !hasPermission([permissions.ntsCallRestrictions, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                        },
                        {
                            type: "switch",
                            label: "Allow UK Premium Calls (070)",
                            name: "options.allowUKPremium070",
                            halfWidth: true,
                            disabled: !hasPermission([permissions.ntsCallRestrictions, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                        },
                    ],
                }
            ],
        },
    ]

    return {
        loading,
        tabs,
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        submitData,
        deleteData,
        // resetChanges,
    }
}
