import React, { useState, useContext, useEffect } from "react";
import { TabbedCardFormPage } from "../../components/Form/TabbedCardFormPage";
import Modal from "../../components/Modal/Modal";
import { useIntegrationsPreferencesPage } from "../IntegrationsPreferencesPage/useIntegrationsPreferencesPage";
import { ToastContext } from "../../Contexts/ToastContext";
import { useRequest } from "../../Hooks/useRequest";
import { SessionContext } from "../../Contexts/SessionContext";
import { regex } from "../../Constants/regex"
import { regexError } from "../../Constants/regexError"
import Button from "../../components/Button";

export default function IntegrationsPreferencesPage()
{
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const { add } = useContext(ToastContext);
    const { state: session } = useContext(SessionContext);
    const { getRequest, putRequest } = useRequest();
    const [userData, setUserData] = useState(null);

    useEffect(() =>
    {
        fetchUserData();
    }, []);

    const {
        tabs,
        user,
        customer,
        activeAccountLicense,
        activeUserLicense,
        users,
        state,
        showModal,
        modalContent,
        loading,
        userReference,
        customerReference,
        setLoading,
        setModalContent,
        updateState,
        updateForm,
        setShowModal,
        updateErrors,
    } = useIntegrationsPreferencesPage();

    const fetchUserData = async () =>
    {
        setLoading(true);
        try
        {
            const response = await getRequest('/redcactus/user/own');
            if (response)
            {
                setUserData(response);
                setEmail(response.email);
            } else
            {
                add({ text: "Failed to fetch user data", type: "error" });
            }
        } catch (error)
        {
            console.error("Error fetching user data:", error);
            add({ text: "An error occurred while fetching user data.", type: "error" });
        }
    };

    const updateUserData = async () =>
    {
        const updatedUserData = { email, password, userData };
        try
        {
            let result = await putRequest(updatedUserData, `/redcactus/users/${userReference}`);
            if (result?.success)
            {
                add({ text: "User updated successfully, you can now download and begin using Bubble", type: "success" });
            }
            else
            {
                add({ text: "Failed to update user", type: "error" });
            }
        }
        catch (error)
        {
            console.error("Error updating user data:", error);
            add({ text: "An error occurred while updating user data.", type: "error" });
        }
    };

    const allowedDomains = [
        "gmail.com", "googlemail.com",
        "outlook.com", "hotmail.com", "live.com", "msn.com", "outlook.co.uk", "live.co.uk", "hotmail.co.uk", "msn.co.uk",
        "icloud.com", "me.com", "mac.com"
    ];

    const validateEmail = (email) =>
    {
        if (!regex.email.test(email))
        {
            return false;
        }

        const emailDomain = email.split('@')[1];
        return allowedDomains.includes(emailDomain);
    };

    const handleOAuthSetup = () =>
    {
        if (!validateEmail(email))
        {
            setEmailError('Please enter a valid email from Microsoft, Google, or Apple.');
            return;
        }
        setShowModal(false);
        updateUserData();
    };

    const handlePasswordSetup = () =>
    {
        if (password !== confirmPassword)
        {
            setPasswordError("Passwords do not match.");
            return;
        }
        if (!regex.password.test(password))
        {
            setPasswordError(regexError.password);
            return;
        }
        setShowModal(false);
        updateUserData();
    };

    return (
        <React.Fragment>
            <TabbedCardFormPage
                tabs={tabs}
                loading={loading}
                state={state}
                updateForm={updateForm}
                updateErrors={updateErrors}
            />
            {modalContent === "oauthSetup" && showModal && (
                <Modal
                    open={showModal}
                    setOpen={setShowModal}
                    maxWidth={800}
                    onClose={() => setShowModal(false)}
                >
                    <h2>OAuth for Bubble</h2>
                    <p>To set OAuth up for your Bubble account, please provide an email address from one of the accepted providers below:</p>
                    <ul style={{ paddingLeft: "20px", marginBottom: "1rem" }}>
                        <li>Microsoft</li>
                        <li>Google</li>
                        <li>Apple</li>
                    </ul>
                    <div style={{ display: "flex", flexDirection: "column", width: "40%", marginBottom: "1rem" }}>
                        <label style={{ paddingRight: "8px", fontWeight: "bold" }}>Email </label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        {emailError && <span style={{ color: "red" }}>{emailError}</span>}
                    </div>
                    <div style={{ marginTop: "1rem", display: "flex", gap: "10px" }}>
                        <Button onClick={handleOAuthSetup}>
                            Activate OAuth
                        </Button>
                        <Button style={{ background: "#dc3545" }} onClick={() => setShowModal(false)}>
                            Cancel
                        </Button>
                    </div>
                </Modal>
            )}
            {modalContent === "passSetup" && showModal && (
                <Modal
                    open={showModal}
                    setOpen={setShowModal}
                    maxWidth={800}
                    onClose={() => setShowModal(false)}
                >
                    <h2>Login setup for Bubble</h2>
                    <p>Below you will need to provide a password for your Bubble account, the email used to login will be the same one associated with your current account</p>
                    <div style={{ display: "flex", flexDirection: "column", width: "40%", marginBottom: "1rem" }}>
                        <label style={{ paddingRight: "8px", fontWeight: "bold" }}>Password </label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", width: "40%", marginBottom: "1rem" }}>
                        <label style={{ paddingRight: "8px", fontWeight: "bold" }}>Confirm Password </label>
                        <input
                            type="password"
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                        {passwordError && <span style={{ color: "red" }}>{passwordError}</span>}
                    </div>
                    <div style={{ marginTop: "1rem", display: "flex", gap: "10px" }}>
                        <Button disabled={password.length === 0 || confirmPassword.length === 0} onClick={handlePasswordSetup}>
                            Set Password
                        </Button>
                        <Button style={{ background: "#dc3545" }} onClick={() => setShowModal(false)}>
                            Cancel
                        </Button>
                    </div>
                </Modal>
            )}
        </React.Fragment>
    );
}
