import React, {createContext, useReducer} from "react";
import {ToastContainer} from "../components/Toasts/ToastContainer";

const toastReducer = (state, action) => {
    const {payload, type} = action;
    switch (type) {
        case "ADD_TOAST":
            return [...state, payload];
        case "REMOVE_TOAST":
            return state.filter(toast => payload.id !== toast.id);
        default:
            return [...state];
    }
};

type IToastAddProps = {
    text: string
    type: "error" | "success" | "info"
    timeout?: number
}

interface IToastContext {
    toasts: any[]
    add: (IToastAddProps) => void
    remove: (id: number) => void
}

const ToastContext = createContext<IToastContext>(null);

const ToastProvider = props => {
    const [state, dispatch] = useReducer(toastReducer, []);

    function add({text, type, timeout}: IToastAddProps) {
        dispatch({
            type: "ADD_TOAST",
            payload: {
                id: Math.random(),
                text,
                type,
                timeout
            }
        })
    }

    function remove(id) {
        dispatch({
            type: "REMOVE_TOAST",
            payload: {
                id
            }
        })
    }

    return (
        <ToastContext.Provider
            value={{toasts: state, add, remove}}
        >
            <ToastContainer/>
            {props.children}
        </ToastContext.Provider>
    );
};

export {ToastContext, ToastProvider};