export const permissions: IPermission = {
    accessWholesale: "1",
    account: "2001",
    acl: "2034",
    admin: "2012",
    bundles: "2047",
    callbacks: "2037",
    callGroups: "2020",
    callRecordings: "2028",
    cdr: "2018",
    conference: "2026",
    cpAdmin: "2012",
    cpUsers: "2010",
    cpv3Beta: "7",
    dashboard: "2017",
    dialThrough: "2042",
    directDebit: "2050",
    externalExt: "2022",
    highRisk: "2048",
    IVR: "2025",
    ldap: "2023",
    mobile: "2038",
    musicOnHold: "2033",
    notification: "2032",
    ntsCallRestrictions: "2041",
    outboundCLILists: "2049",
    paypal: "2043",
    numbers: "2004",
    pickupGroups: "2029",
    postpay: "9005",
    prepay: "9006",
    prompts: "2027",
    provisioning: "2007",
    queues: "2008",
    retail: "9999",
    seats: "2009",
    seatsCallRestrictions: "2019",
    SIPTrunks: "2013",
    SIPTrunksCallRestrictions: "2040",
    softphone: "2024",
    spy: "2031",
    store: "6",
    teams: "2044",
    timeprofile: "2021",
    VHAdmin: "3006",
    VHCustomers: "3002",
    voicemail: "2011",
    wholesale: "9004",
    wholesaleAdmin: "1006",
    wholesaleCustomers: "1002",
    parentTop: "9002"
}

const crudNumbers = {
    create: [1, 3, 5, 7, 9, 11, 13, 15],
    read: [2, 3, 6, 7, 10, 11, 14, 15],
    update: [4, 5, 6, 7, 12, 13, 14, 15],
    delete: [8, 9, 10, 11, 12, 13, 14, 15]
}

export function hasPermission(pagePermissions, userPermissions, crudValue)
{
    if (userPermissions && userPermissions[permissions.cpv3Beta])
    {
        if (pagePermissions)
        {
            return (
                pagePermissions.filter(
                    (permission) =>
                    {
                        if (permission === permissions.retail)
                        {
                            return !userPermissions[permissions.wholesale]
                        }
                        else
                        { return crudNumbers[crudValue].includes(userPermissions[permission]) }
                    }
                ).length > 0
            )
        } else
        {
            return true
        }
    }
    return false
}
