export const breadcrumbSections = [
    { baseUrl: "account-details", index: "Account Details" },
    { baseUrl: "bundles", index: "Bundles" },
    { baseUrl: "invoice", index: "Invoices" },
    { baseUrl: "user-manager", index: "User :identifier Manager" },
    { baseUrl: "call-groups", index: "Call Groups", config: "Call Group :identifier Configuration" },
    { baseUrl: "call-queues", index: "Call Queues", config: "Call Queue :identifier Configuration" },
    { baseUrl: "ivrs", index: "IVR (Interactive Voice Response)", config: "IVR :identifier Configuration" },
    { baseUrl: "notifications", index: "Notifications" },
    { baseUrl: "nts", index: "NTS Destinations", config: "NTS Destination :identifier Configuration" },
    { baseUrl: "seats", index: "Seats", config: "Seat :identifier Configuration" },
    { baseUrl: "sip", index: "SIP Trunks", config: "SIP Trunk :identifier Configuration" },
    { baseUrl: "telephone-numbers", index: "Telephone Numbers", config: "Telephone Number :identifier Configuration" },
    { baseUrl: "time-profiles", index: "Time Profiles", config: "Time Profile :identifier Configuration" },
    { baseUrl: "access-lists", index: "Access Lists", config: "Access List :identifier Configuration" },
    { baseUrl: "call-records", index: "Call Records", config: "Call Record :identifier Configuration" },
    { baseUrl: "call-recording", index: "Call Recordings", config: "Call Recording :identifier Configuration" },
    { baseUrl: "call-monitoring", index: "Call Monitoring", config: "Call Monitoring :identifier Configuration" },
    { baseUrl: "conferencing", index: "Conferencing", config: "Conferencing :identifier Configuration" },
    { baseUrl: "custom-prompts", index: "Custom Prompts", config: "Custom Prompt :identifier Configuration" },
    { baseUrl: "dial-through", index: "Dial Through", config: "Dial Through :identifier Configuration" },
    { baseUrl: "music-on-hold", index: "Music On Hold", config: "Music On Hold :identifier Configuration" },
    { baseUrl: "phone-directory", index: "Phone Directory", subIndex: ":identifier", config: "Entry :identifier Configuration" },
    { baseUrl: "pickup-groups", index: "Pickup Groups", config: "Pickup Group :identifier Configuration" },
    { baseUrl: "provisioning", index: "Provisioned Devices", config: "Provisioned Device :identifier Configuration" },
    { baseUrl: "voicemail", index: "Voicemail", config: "Voicemail :identifier Configuration" },
    { baseUrl: "sms", index: "SMS", config: "Message Details" },
    { baseUrl: "mobile", index: "Mobile", config: "Mobile :identifier Configuration" },
    { baseUrl: "callback", index: "Website Callback", config: ":identifier Configuration" },
    { baseUrl: "ems-errors", index: "Emergency Service Address Errors" },
    { baseUrl: "demo", index: "Demo" },
    { baseUrl: "AOT", index: "Advice of Transfer" },
    { baseUrl: "account-transaction", index: "Account Transactions" },
    { baseUrl: "tariff", index: "Tariffs" },
    { baseUrl: "international-addresses", index: "International Addresses" },
    { baseUrl: "integrations", index: "Integrations" },
    { baseUrl: "support", index: "Support" },
]