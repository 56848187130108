import React, { useEffect, useState } from "react";
import { regex } from "../../Constants/regex";
import { useFormReducer } from "../../Hooks/useFormReducer";
import { useRequest } from "../../Hooks/useRequest";
import StagedForm from "../Form/StagedForm";
import Modal from "../Modal/Modal";

export function FileUploadModal({
    open,
    setOpen,
    customTitle,
    sectionTitle,
    fields,
    displayResponse,
    defineSubmitData,
    pullData,
    maxWidth,
    previewColumns
}: IUploadFileModalSettings)
{
    const { state, updateForm, updateErrors } = useFormReducer({
        record: {
            feedback: {}
        }
    })

    const [previewStage, setPreviewStage] = useState([])
    const [loading, setLoading] = useState(false)

    const { postMultiPartRequest } = useRequest()

    function uploadFile(data)
    {
        return new Promise(async resolve =>
        {
            let response = await postMultiPartRequest(data.body, data.path)
            resolve(response)
        })
    }

    function csvPreview(data)
    {
        return new Promise(async resolve =>
        {
            let response = await postMultiPartRequest(data.body, "/ldap/extract/csv")
            resolve(response)
        })
    }

    useEffect(() =>
    {
        async function setPreviewValues()
        {
            setLoading(true)
            let response = await new Promise(async resolve =>
            {
                const retData = await defineSubmitData(state)
                const preview = await csvPreview(retData)
                resolve(preview)
            })

            if (response)
            {
                //@ts-ignore
                let results = response.result
                if (results)
                {
                    let array = [];

                    JSON.parse(results).forEach(line =>
                    {
                        let linestring: { [key: string]: string | number } = {}
                        for (let index = 0; index < previewColumns.length; index++)
                        {
                            const column = previewColumns[index];
                            linestring = { ...linestring, [column.name]: line[index] }
                        }
                        array.push(linestring)
                    });
                    setPreviewStage([
                        {
                            title: "Preview",
                            scrollable: true,
                            onComplete: {
                                buttonText: "Next",
                                function: () =>
                                {
                                    return new Promise(async resolve =>
                                    {
                                        resolve({ success: true })
                                    })
                                }
                            },
                            fields: [
                                {
                                    type: "formTable",
                                    columns: previewColumns,
                                    defaultValue: array,
                                }
                            ]
                        }
                    ])
                }
            }
            setLoading(false)
        }
        if (state.file && previewColumns)
        {
            setPreviewValues()
        }
    }, [state.file, state.ignoreFirstLine])

    useEffect(() =>
    {
        if (fields)
        {
            fields.forEach(field =>
            {
                let value: any = ""
                if (field.type === "text")
                {
                    value = ""
                }
                if (field.type === "number")
                {
                    value = 0
                }
                if (field.type === "switch")
                {
                    value = false
                }
                if (field.type === "time")
                {
                    value = "00:00"
                }
                if (field.type === "select")
                {
                    value = ""
                }
                if (field.defaultValue || field.defaultValue === 0)
                {
                    value = field.defaultValue
                }
                updateForm({
                    target: {
                        type: field.type,
                        name: field.name,
                        value
                    }
                })
            })
        }
    }, [])

    function findFieldError(name)
    {
        if (state.errors && state.errors.length > 0)
        {
            const errors = state.errors.filter((error) => error.name === name)
            if (errors && errors.length > 0)
            {
                return errors[0].value
            }
        }
        return null
    }

    return (
        <Modal open={open} setOpen={setOpen} maxWidth={maxWidth ? maxWidth : 700}>
            <h4>{customTitle}</h4>
            <StagedForm
                loading={loading}
                onComplete={() =>
                {
                    pullData && pullData()
                    setOpen(false)

                }}
                stages={[
                    {
                        title: sectionTitle,
                        fields: fields.map(field => ({
                            ...field,
                            onChange: updateForm,
                            value: field.value
                                ? field.value
                                : state[field.name],
                            setError: updateErrors,
                            error: findFieldError(field.name)
                        })),
                        error: !!fields.filter(field => findFieldError(field.name)).filter(Boolean).length,
                        onComplete: {
                            buttonText: "Next",
                            function: () =>
                                new Promise(async (resolve) =>
                                {
                                    let success = true

                                    fields.map(field =>
                                    {
                                        if (field.required && !state[field.name] && state[field.name] !== 0)
                                        {
                                            updateErrors({ target: { name: field.name, value: "This is a required field" } })
                                            success = false
                                        }
                                    })
                                    resolve({ success })
                                })
                        },
                    },
                    ...previewStage,
                    {
                        title: "Confirm",
                        onComplete: {
                            buttonText: "Create",
                            function: () =>
                            {
                                return new Promise(async resolve =>
                                {
                                    const retData = await defineSubmitData(state)

                                    let response = await uploadFile(retData)

                                    let feedback = await displayResponse(response)

                                    updateForm({
                                        target: {
                                            name: "feedback",
                                            value: feedback
                                        }
                                    })
                                    resolve({ success: true })
                                })
                            }
                        },
                        fields: [
                            {
                                type: "paragraph",
                                value: "Are you sure you want to upload this file?"
                            }
                        ]
                    },
                    {
                        title: "Feedback",
                        fields: [
                            state.feedback.multiResponse && state.feedback.successes && state.feedback.successes.length > 0 && {
                                type: "feedbackAccordion",
                                title: state.feedback.successTitle,
                                items: state.feedback.successes && state.feedback.successes.map(success => `${success.id} - ${success.message}`)
                            },
                            state.feedback.multiResponse && state.feedback.failures && state.feedback.failures.length > 0 && {
                                type: "feedbackAccordion",
                                title: state.feedback.failureTitle,
                                items: state.feedback.failures &&
                                    state.feedback.failures.map(
                                        failure => `${failure.id} - ${failure.message}`
                                    ),
                                variant: "red"
                            },
                            !state.feedback.multiResponse && state.feedback.message && {
                                type: "paragraph",
                                value: state.feedback.message
                            }
                        ].filter(Boolean),
                    }
                ]}
            />
        </Modal>
    )
}