import { useContext, useEffect, useRef, useState } from "react"
import { capitaliseFirstLetter } from "../../Common/functions"
import { sipconvergenceReformatter } from "../../Common/urlHelpers"
import { ToastContext } from "../../Contexts/ToastContext"
import useAPIRequests from "../../Hooks/useAPIRequests"
import { useFormReducer } from "../../Hooks/useFormReducer"
import { useRequest } from "../../Hooks/useRequest"

export function useProvisioningFormPage(id: number)
{
    const [loading, setLoading] = useState(true)
    const { getRequest, postRequest } = useRequest()
    const [expansions, setExpansions] = useState<ISection[]>([])
    const tableRef = useRef([])
    const deviceDestRef = useRef<ISelectOption[]>([])
    const { add } = useContext(ToastContext)
    const { getDestinationPromise } = useAPIRequests()
    const [deviceLineKeys, setDeviceLineKeys] = useState<ISelectOption[]>();

    const {
        state,
        updateState,
        updateForm,
        updateErrors,
    } = useFormReducer({ record: {} })

    function saveExpTable(data)
    {
        return {
            id: data.provisioningkey_id,
            provisioningkey_idx: data.provisioningkey_idx,
            provisioningkey_line: data.provisioningkey_line,
            provisioningkey_type: data.provisioningkey_type,
            provisioningkey_value: data.provisioningkey_value,
            provisioningkey_label: data.provisioningkey_label,
            provisioning_id: id
        }
    }

    function getLineKeys(provisioning)
    {
        return new Promise<ISelectOption[]>(async resolve =>
        {
            let options = []
            if (provisioning)
            {
                let response = await getRequest(`/provisioning/device/${provisioning.model}`)
                if (response)
                {
                    let numLines = response.itemLines <= 8 ? response.itemLines : 8 // Hard limit due to DB restrictions
                    options = Array.from(Array(numLines).keys()).map(
                        (number) => ({
                            value: (number + 1),
                            label: `${(number + 1)}`,
                        })
                    )
                    options = [{ label: "Active", value: -1 }, ...options]
                }
            }
            resolve(options)
        })
    }

    function getSeatName(seat: string)
    {
        let match = seat.match(/\(.+\)/)
        if (match)
        {
            return match[0].replace("(", "").replace(")", "");
        }
        else
        {
            return seat
        }
    }

    async function getExpansions(provisioning, expDeviceLineKeys)
    {
        let response = await getRequest(`/provisioning/${id}/expansions`)

        if (response && response.expansions)
        {
            let count = response.expansions
            if (count > 0 && provisioning && provisioning.expId && provisioning.expId > 0)
            {
                response = await getRequest(
                    `/provisioning/expmodel/${provisioning.expId}`
                )
                if (response)
                {
                    let expans: ISection[] = []
                    for (let i = 1; i <= count; i++)
                    {
                        expans = [
                            ...expans,
                            {
                                title: `${response.desc} - ${i}`,
                                fullWidth: true,
                                fields: [
                                    {
                                        type: "table",
                                        customPaginationName: "expKeys",
                                        pullData: pullData,
                                        fieldRef: el => tableRef.current[i] = el,
                                        onSubmit: {
                                            getData: saveExpTable,
                                            //@ts-ignore
                                            path: `/provisioning/${id}/expkey`,
                                        },
                                        columns: [
                                            {
                                                name: "provisioningkey_display_idx",
                                                title: "ID",
                                            },
                                            {
                                                title: "Line",
                                                name: "provisioningkey_line",
                                                formField: {
                                                    type: "select",
                                                    name: "provisioningkey_line",
                                                    options: expDeviceLineKeys,
                                                }
                                            },
                                            {
                                                title: "Type",
                                                name: "provisioningkey_type",
                                                emptyFields: ["provisioningkey_value", "provisioningkey_label"],
                                                formField: {
                                                    type: "select",
                                                    options: [
                                                        { value: "line", label: "Line" },
                                                        { value: "blf", label: "BLF" },
                                                        { value: "speed", label: "Speed Dial" },
                                                        { value: "directory", label: "Directory" },
                                                        { value: "transfer", label: "Transfer" },
                                                        { value: "mute", label: "Mute" },
                                                        { value: "voicemail", label: "Voicemail" },
                                                        { value: "none", label: "None" },
                                                    ],
                                                    name: "provisioningkey_type",
                                                },
                                            },
                                            {
                                                title: "Value",
                                                name: "provisioningkey_value",
                                                conditional: [{ value: null, text: "" }],
                                                conditionalFill: {
                                                    conditionalForFill: [{ field: "provisioningkey_type", value: "blf" }],
                                                    dataSet: deviceDestRef?.current?.map((dd) =>
                                                    {
                                                        return { label: getSeatName(dd.label), value: dd.value }
                                                    }),
                                                    columnToUpdate: "provisioningkey_label"
                                                },
                                                formField: {
                                                    conditionallyDisabled: {
                                                        checks: [
                                                            {
                                                                field: "provisioningkey_type",
                                                                value: "none",
                                                            },
                                                            {
                                                                field: "provisioningkey_type",
                                                                value: "directory",
                                                            },
                                                            {
                                                                field: "provisioningkey_type",
                                                                value: "transfer",
                                                            },
                                                            {
                                                                field: "provisioningkey_type",
                                                                value: "mute",
                                                            },
                                                            {
                                                                field: "provisioningkey_type",
                                                                value: "voicemail",
                                                            }
                                                        ]
                                                    },
                                                    conditionalProps: {
                                                        field: "provisioningkey_type",
                                                        value: "blf",
                                                        props:
                                                        {
                                                            type: "select",
                                                            options: deviceDestRef.current
                                                        }
                                                    },
                                                    type: "text",
                                                    name: "provisioningkey_value",
                                                    maxLength: 50,
                                                    regex: /^[0-9\*]{0,50}$/,
                                                    regexError: "Please enter a valid value",
                                                    useValue: true
                                                },
                                            },
                                            {
                                                title: "Label",
                                                name: "provisioningkey_label",
                                                conditional: [{ value: null, text: "" }],
                                                formField: {
                                                    type: "text",
                                                    name: "provisioningkey_label",
                                                    maxLength: 50,
                                                    useValue: true
                                                },
                                            },
                                        ],
                                        restType: "GET",
                                        urlString: `/provisioning/${id}/expkeys/${i}`,
                                    },
                                ],
                            },
                        ]
                    }
                    setExpansions(expans)
                }
            }
        }
    }

    async function pullData()
    {
        setLoading(true)

        let seats = await getDestinationPromise({
            seats: true,
            queues: false,
            voicemail: false,
            groups: false,
            ivrs: false,
            timeProfiles: false,
            sipTrunks: false,
            dialThrough: false,
            externalDest: false,
            sipTrunkMap: false,
            queueTrunkMaps: false,
            extensionMap: false,
            highPriorityQueue: false,
        })

        deviceDestRef.current = seats.map((seat) =>
        {
            return { value: seat.value.replace("SIP/", ""), label: seat.label }
        })

        let provisioning = {}
        let provisioningResponse = await getRequest(`/provisioning/${id}`)
        if (provisioningResponse)
        {
            let sipAccounts = provisioningResponse.sipAccounts
            let sipTableData = []
            let sipCount = 1
            if (sipAccounts)
            {
                let splits = sipAccounts.split(',')
                for (let sipAccountName of splits)
                {
                    sipTableData.push({ id: `Account #${sipCount++}`, seat: "SIP/" + sipAccountName })
                }
            }
            let numAccounts = provisioningResponse.numberOfAccounts <= 12 ? provisioningResponse.numberOfAccounts : 12 // Hard limit due to DB restrictions

            for (sipCount = sipCount; sipCount <= numAccounts; sipCount++)
            {
                sipTableData.push({ id: `Account #${sipCount}`, seat: "none" })
            }

            provisioning = {
                ...provisioningResponse,
                currentFirmware: capitaliseFirstLetter(provisioningResponse.currentFirmware),
                sipTableData: sipTableData
            }
            updateState({ provisioning })
        }

        let lineKeys = await getLineKeys(provisioning)
        setDeviceLineKeys(lineKeys)
        getExpansions(provisioning, lineKeys)

        setLoading(false)
    }

    useEffect(() =>
    {
        pullData()
    }, [id])

    function submitData()
    {
        if (tableRef && tableRef.current)
        {
            tableRef.current.forEach(tableReference =>
            {
                tableReference?.handleSubmit()
            })
        }

        let tableData = state.provisioning.sipTableData
        let accounts = ""
        for (let data of tableData)
        {
            if (data.seat && data.seat !== "none")
            {
                if (accounts === "")
                {
                    accounts = data.seat.replace(/SIP\//, '') // removes SIP/ from the name field
                }
                else
                {
                    accounts = accounts + "," + data.seat.replace(/SIP\//, '')
                }
            }
        }

        return [
            {
                body: {
                    accountNo: state.provisioning.accountNo,
                    adminPassword: state.provisioning.adminPassword,
                    brandingName: state.provisioning.brandingName,
                    childAccounts: state.provisioning.childAccounts,
                    enableHiddenTags: state.provisioning.enableHiddenTags,
                    expId: state.provisioning.expId,
                    expName: state.provisioning.expName,
                    firmware: state.provisioning.firmware,
                    initialised: state.provisioning.initialised,
                    lastCheckin: state.provisioning.lastCheckin,
                    lastUpdated: state.provisioning.lastUpdated,
                    ldapPassword: state.provisioning.ldapPassword,
                    lineKeys: state.provisioning.lineKeys,
                    localPort: state.provisioning.localPort,
                    locked: state.provisioning.locked,
                    macAddress: state.provisioning.macAddress,
                    make: state.provisioning.make,
                    model: state.provisioning.model,
                    numberOfAccounts: state.provisioning.numberOfAccounts,
                    phoneKeys: state.provisioning.phoneKeys,
                    pictureUrl: state.provisioning.pictureUrl,
                    provisionKeys: state.provisioning.provisionKeys,
                    provisioningId: state.provisioning.provisioningId,
                    repeaters: state.provisioning.repeaters,
                    serialNumber: state.provisioning.serialNumber,
                    selectedFirmware: state.provisioning.selectedFirmware,
                    sipAccounts: accounts,
                    sipTableData: state.provisioning.sipTableData,
                    stationName: state.provisioning.stationName,
                    type: state.provisioning.type,
                    userPassword: state.provisioning.userPassword,
                },
                path: `/provisioning/${id}`,
            },
        ]
    }

    function getFirmwares(): Promise<ISelectOption[]>
    {
        return new Promise(async (resolve) =>
        {
            let response = await getRequest(`/provisioning/${id}`)
            if (response)
            {
                if (response.availableFirmwares)
                {
                    const firmwares = response.availableFirmwares.map((firm) => ({
                        label: `${firm.version}${firm.skip ? " (Not Auto Enabled)" : ""
                            }`,
                        value: firm.url,
                    }))
                    resolve([{ label: "Auto", value: "auto" }, ...firmwares])
                }
            }
        })
    }

    async function reinit()
    {
        let response = await postRequest("", `/provisioning/${id}/reinit`)
        if (response)
        {
            if (!response.success)
            {
                if (response.message)
                {
                    add({
                        text: response.message,
                        type: "error",
                    })
                } else
                {
                    add({
                        text: "An error occured",
                        type: "error",
                    })
                }
            } else if (response.success === true)
            {
                add({
                    text: "Device successfully reinitialised",
                    type: "success",
                })
            } else
            {
                add({
                    text: "An error occured",
                    type: "error",
                })
            }
        } else
        {
            add({
                text: "An error occured",
                type: "error",
            })
        }
    }

    async function reboot()
    {
        const postData = {
            command: "REBOOT_SNOM",
            c_device_id: id
        }

        let response = await postRequest(postData, `/provisioning/${id}/command`)
        if (response)
        {
            if (!response.success)
            {
                if (response.message)
                {
                    add({
                        text: response.message,
                        type: "error",
                    })
                } else
                {
                    add({
                        text: "An error occured",
                        type: "error",
                    })
                }
            } else if (response.success === true)
            {
                add({
                    text: "Device successfully rebooted",
                    type: "success",
                })
            } else
            {
                add({
                    text: "An error occured",
                    type: "error",
                })
            }
        } else
        {
            add({
                text: "An error occured",
                type: "error",
            })
        }
    }

    function getTabs(): ITab[]
    {
        return [
            {
                title: "General",
                icon: "toolbox",
                disabled: false,
                sections: [
                    {
                        title: "Overview",
                        fields: [
                            {
                                type: "info",
                                label: "MAC Address",
                                name: "provisioning.macAddress",
                            },
                            {
                                type: "info",
                                label: "Serial #",
                                name: "provisioning.serialNumber",
                            },
                            {
                                type: "info",
                                label: "Make",
                                name: "provisioning.make",
                            },
                            {
                                type: "info",
                                label: "Model",
                                name: "provisioning.model",
                            },
                            {
                                type: "info",
                                label: "Local Port",
                                name: "provisioning.localPort",
                            },
                            {
                                type: "info",
                                label: "Last Checkin",
                                name: "provisioning.lastCheckin",
                                nullValue: "Never",
                            },
                            {
                                type: "info",
                                label: "Current Firmware",
                                name: "provisioning.currentFirmware",
                                nullValue: "N/A",
                            },
                            {
                                type: "select",
                                label: "Firmware",
                                name: "provisioning.selectedFirmware",
                                loadOptions: getFirmwares,
                            },
                            {
                                type: "text",
                                label: "Background Image URL",
                                name: "provisioning.pictureUrl",
                            }
                        ],
                    },
                    {
                        title: "Sip Accounts",
                        fields: [
                            {
                                type: "formTable",
                                name: "state.provisioning.sipTableData",
                                noPagination: false,
                                columns: [
                                    {
                                        name: "id",
                                        title: "",
                                    },
                                    {
                                        name: "seat",
                                        title: "",
                                        formField: {
                                            type: "select",
                                            name: "seat",
                                            loadOptions: () =>
                                                getDestinationPromise({
                                                    seats: true,
                                                    queues: false,
                                                    voicemail: false,
                                                    groups: false,
                                                    ivrs: false,
                                                    timeProfiles: false,
                                                    sipTrunks: false,
                                                    dialThrough: false,
                                                    externalDest: false,
                                                    sipTrunkMap: false,
                                                    queueTrunkMaps: false,
                                                    extensionMap: false,
                                                    highPriorityQueue: false,
                                                }),

                                            options: [
                                                { value: "none", label: "None" },
                                            ],
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        title: "Security",
                        fields: [
                            {
                                type: "text",
                                label: "Admin Password",
                                name: "provisioning.adminPassword",
                                maxLength: 10,
                                regex: /.{0,10}$/,
                                regexError: "Password must be 0 - 10 character"
                            },
                            {
                                type: "text",
                                label: "User Password",
                                name: "provisioning.userPassword",
                                maxLength: 10,
                                regex: /.{0,10}$/,
                                regexError: "Password must be 0 - 10 character"
                            },
                            {
                                type: "switch",
                                label: "Enhanced Security",
                                name: "provisioning.enableHiddenTags",
                            },
                        ],
                    },
                    {
                        title: "Lock",
                        fields: [
                            {
                                type: "paragraph",
                                value:
                                    "For security reasons the phone is locked after the first provisioning request. " +
                                    "If the phone requires the username and password again, please unlock the device.",
                            },
                            {
                                type: "switch",
                                label: "Locked",
                                name: "provisioning.locked",
                            },
                        ],
                    },
                    {
                        title: "Initialisation",
                        fields: [
                            {
                                type: "paragraph",
                                value:
                                    "If a device is factory reset, it is necessary to re-initialise the security " +
                                    "details for the device to provision. To re-initialise the provisioning details, " +
                                    "please click the re-initialise button below.",
                            },
                            {
                                type: "button",
                                label: "Re-init Device",
                                placeholder: "Re-initialise",
                                onClick: () => reinit(),
                            },
                        ],
                    },
                    {
                        title: "Reboot Device",
                        fields: [
                            {
                                type: "paragraph",
                                value:
                                    "Click to remotely reboot your phone.",
                            },
                            {
                                type: "button",
                                label: "Reboot Device",
                                placeholder: "Reboot",
                                onClick: () => reboot(),
                            },
                        ],
                    },
                ],
            },
            {
                title: "Function Keys",
                icon: "keyboard",
                disabled: false,
                sections: [
                    {
                        title: "Provisioning Settings",
                        fullWidth: true,
                        fields: [
                            {
                                type: "switch",
                                label: "Provision Keys",
                                name: "provisioning.provisionKeys",
                            },
                        ],
                    },
                    {
                        title: "Line Keys",
                        fullWidth: true,
                        fields: [
                            {
                                type: "formTable",
                                name: "provisioning.lineKeys",
                                columns: [
                                    {
                                        name: "provisioningkey_display_idx",
                                        title: "ID",
                                    },
                                    {
                                        title: "Line",
                                        name: "provisioningkey_line",
                                        formField: {
                                            type: "select",
                                            name: "provisioningkey_line",
                                            options: deviceLineKeys
                                        }
                                    },
                                    {
                                        title: "Type",
                                        name: "provisioningkey_type",
                                        emptyFields: ["provisioningkey_value", "provisioningkey_label"],
                                        formField: {
                                            type: "select",
                                            name: "provisioningkey_type",
                                            options: [
                                                { value: "line", label: "Line" },
                                                { value: "blf", label: "BLF" },
                                                { value: "speed", label: "Speed Dial" },
                                                { value: "directory", label: "Directory" },
                                                { value: "transfer", label: "Transfer" },
                                                { value: "mute", label: "Mute" },
                                                { value: "voicemail", label: "Voicemail" },
                                                { value: "none", label: "None" },
                                            ],
                                        },
                                    },
                                    {
                                        title: "Value",
                                        name: "provisioningkey_value",
                                        conditional: [{ value: null, text: "" }],
                                        conditionalFill: {
                                            conditionalForFill: [{ field: "provisioningkey_type", value: "blf" }],
                                            dataSet: deviceDestRef?.current?.map((dd) =>
                                            {

                                                return { label: getSeatName(dd.label), value: dd.value }
                                            }),
                                            columnToUpdate: "provisioningkey_label"
                                        },
                                        formField: {
                                            conditionallyDisabled: {
                                                checks: [
                                                    {
                                                        field: "provisioningkey_type",
                                                        value: "none",
                                                    },
                                                    {
                                                        field: "provisioningkey_type",
                                                        value: "directory",
                                                    },
                                                    {
                                                        field: "provisioningkey_type",
                                                        value: "transfer",
                                                    },
                                                    {
                                                        field: "provisioningkey_type",
                                                        value: "mute",
                                                    },
                                                    {
                                                        field: "provisioningkey_type",
                                                        value: "voicemail",
                                                    }
                                                ]
                                            },
                                            conditionalProps: {
                                                field: "provisioningkey_type",
                                                value: "blf",
                                                props:
                                                {
                                                    type: "select",
                                                    options: deviceDestRef?.current
                                                }
                                            },
                                            type: "text",
                                            name: "provisioningkey_value",
                                            maxLength: 50,
                                            regex: /^[0-9\*\#]{0,50}$/,
                                            regexError: "Please enter a valid value",
                                            useValue: true
                                        },
                                    },
                                    {
                                        title: "Label",
                                        name: "provisioningkey_label",
                                        conditional: [{ value: null, text: "" }],
                                        formField: {
                                            type: "text",
                                            name: "provisioningkey_label",
                                            maxLength: 50,
                                            useValue: true
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        title: "Phone Keys",
                        fullWidth: true,
                        fields: [
                            {
                                type: "formTable",
                                name: "provisioning.phoneKeys",
                                columns: [
                                    {
                                        name: "provisioningkey_display_idx",
                                        title: "ID"
                                    },
                                    {

                                        title: "Line",
                                        name: "provisioningkey_line",
                                        formField: {
                                            type: "select",
                                            name: "provisioningkey_line",
                                            options: deviceLineKeys,
                                            defaultToFirst: true
                                        }
                                    },
                                    {
                                        title: "Type",
                                        name: "provisioningkey_type",
                                        emptyFields: ["provisioningkey_value", "provisioningkey_label"],
                                        formField: {
                                            type: "select",
                                            name: "provisioningkey_type",
                                            options: [
                                                { value: "line", label: "Line" },
                                                { value: "blf", label: "BLF" },
                                                { value: "speed", label: "Speed Dial" },
                                                { value: "directory", label: "Directory" },
                                                { value: "transfer", label: "Transfer" },
                                                { value: "mute", label: "Mute" },
                                                { value: "voicemail", label: "Voicemail" },
                                                { value: "none", label: "None" },
                                            ],
                                        },
                                    },
                                    {
                                        title: "Value",
                                        name: "provisioningkey_value",
                                        conditional: [{ value: null, text: "" }],
                                        conditionalFill: {
                                            conditionalForFill: [{ field: "provisioningkey_type", value: "blf" }],
                                            dataSet: deviceDestRef?.current?.map((dd) =>
                                            {
                                                return { label: getSeatName(dd.label), value: dd.value }
                                            }),
                                            columnToUpdate: "provisioningkey_label"
                                        },
                                        formField: {
                                            conditionallyDisabled: {
                                                checks: [
                                                    {
                                                        field: "provisioningkey_type",
                                                        value: "none",
                                                    },
                                                    {
                                                        field: "provisioningkey_type",
                                                        value: "directory",
                                                    },
                                                    {
                                                        field: "provisioningkey_type",
                                                        value: "transfer",
                                                    },
                                                    {
                                                        field: "provisioningkey_type",
                                                        value: "mute",
                                                    },
                                                    {
                                                        field: "provisioningkey_type",
                                                        value: "voicemail",
                                                    }
                                                ]
                                            },
                                            conditionalProps: {
                                                field: "provisioningkey_type",
                                                value: "blf",
                                                props:
                                                {
                                                    type: "select",
                                                    options: deviceDestRef?.current
                                                },
                                            },
                                            type: "text",
                                            name: "provisioningkey_value",
                                            maxLength: 50,
                                            regex: /^[0-9\*\#]{0,50}$/,
                                            regexError: "Please enter a valid value",
                                            useValue: true
                                        },
                                    },
                                    {
                                        title: "Label",
                                        name: "provisioningkey_label",
                                        conditional: [{ value: null, text: "" }],
                                        formField: {
                                            type: "text",
                                            name: "provisioningkey_label",
                                            maxLength: 50,
                                            useValue: true
                                        },
                                    },
                                ]
                            },
                        ],
                    },
                    ...expansions
                ],
            },
            {
                title: "How To Use",
                icon: "book",
                disabled: false,
                sections: [
                    {
                        title: "Provisioning Instructions",
                        fullWidth: true,
                        fields: [
                            {
                                type: "paragraph",
                                name: "provisioning.provisioningInstructions",
                            },
                        ],
                    },
                ],
            },
        ]
    }

    return {
        loading,
        state,
        updateState,
        updateForm,
        updateErrors,
        submitData,
        // changes,
        // resetChanges,
        getTabs,
    }
}
