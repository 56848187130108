import Icon from "../../components/Icons/Icon"
import variablesScss from "../../Constants/variables.scss"
import Button from "../../components/Button"
import React, { useState, useContext } from "react"
import renderHTML from "react-render-html"
import { useRequest } from "../../Hooks/useRequest"
import { ToastContext } from "../../Contexts/ToastContext"

export default function NotificationBox({ notification, open, setOpen }) {
    const { patchRequest } = useRequest()
    const [loading, setLoading] = useState(false)
    const [read, setRead] = useState(notification.notification_read)
    const { add } = useContext(ToastContext)

    async function markAsRead() {
        setLoading(true)
        const response: any = await patchRequest(
            {},
            `/notifications/${notification.notification_id}/read`
        )
        if (response && response.status && response.status) {
            add({
                type: "success",
                text: "Successfully marked notification as read.",
            })
            setRead(true)
        } else {
            add({
                type: "error",
                text: "Unsuccessfully marked notification as read.",
            })
        }
        setLoading(false)
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                borderBottom: "1px solid #f4f4f4",
            }}
        >
            <div
                onClick={() => setOpen(!open)}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    padding: 16,
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        alignItems: "center",
                    }}
                >
                    {notification.notification_icon && (
                        <Icon
                            icon={notification.notification_icon.replace(
                                "fa-",
                                ""
                            )}
                            color={
                                notification.notification_color === "text-blue"
                                    ? variablesScss.colorBlue
                                    : notification.notification_color ===
                                          "text-aqua" && variablesScss.colorAqua
                            }
                            style={{
                                marginRight: 8,
                            }}
                        />
                    )}
                    <span
                        style={{
                            fontWeight: !read && 500,
                        }}
                    >
                        {notification.notification_title}
                    </span>
                    <div
                        style={{
                            marginLeft: 8,
                            opacity: 0.8,
                        }}
                    >
                        {notification.notification_created}
                    </div>
                </div>
                <div>
                    <Icon
                        icon={"chevron-left"}
                        style={{
                            transform: open ? "rotate(-90deg)" : "none",
                            transition: "transform 0.3s ease-in-out",
                            marginRight: 8,
                        }}
                    />
                </div>
            </div>
            <div
                style={{
                    maxHeight: !open ? 0 : "100vh",
                    overflow: "hidden",
                    transition: "max-height 0.3s ease-in-out",
                    borderTop: "1px solid #f4f4f4",
                }}
            >
                <div
                    style={{
                        padding: 16,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div style={{ minHeight: 50 }}>
                        {renderHTML(notification.notification_message)}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        {!read && (
                            <Button
                                loading={loading}
                                onClick={markAsRead}
                                size={"sm"}
                                style={{
                                    marginTop: 16,
                                }}
                            >
                                Mark as Read
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
