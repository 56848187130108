import React from "react"
import variablesScss from "../../Constants/variables.scss";
import Icon from "../Icons/Icon";

export default function Download({
    value,
    fileName,
    disabled,
}: IDownload)
{

    return (
        <div className="flex-row flex-1">
            {value && (
                <a href={!disabled ? value : null} download={fileName ? fileName : "file"} style={{ textDecoration: "none" }}>

                    <div
                        style={{
                            opacity: disabled ? 0.6 : 1,
                            height: 20,
                            padding: 24,
                            backgroundColor: variablesScss.primaryColor,
                            display: "flex",
                            borderRadius: 24,
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "white",
                        }}
                    >
                        Download

                        <Icon icon={"arrow-alt-circle-down"} size={"lg"} style={{ marginLeft: 8 }} />
                    </div>
                </a>
            )}
        </div>
    )
}
