import React, { useContext, useEffect } from "react"
import { hasPermission, permissions } from "../../Constants/permissions";
import { SessionContext } from "../../Contexts/SessionContext";

export default function ChatWidget()
{
    const { state } = useContext(SessionContext);
    useEffect(() =>
    {
        if (hasPermission([permissions.retail], state.session.permissions, "read"))
        {
            let script = document.createElement('script');
            script.setAttribute('id', 'LHC');
            let newScript = document.createElement('script');
            let inlineScript = document.createTextNode("var LHC_API = LHC_API || {};"
                + "LHC_API.args = { mode: 'widget', lhc_base_url: 'https://chat.voicehost.co.uk/index.php/', wheight: 450, wwidth: 350, pheight: 520, pwidth: 500, leaveamessage: true, department: [1, 2], theme: 1, check_messages: false, position: 'api', Account: '<%= state.session.account%>', username: '<%= state.session.username%>' };"
                + "(function () { var po = document.createElement('script'); po.type = 'text/javascript'; po.setAttribute('crossorigin', 'anonymous'); po.async = true; var date = new Date(); po.src = 'https://chat.voicehost.co.uk/design/defaulttheme/js/widgetv2/index.js?' + ('' + date.getFullYear() + date.getMonth() + date.getDate()); var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s); })();"
                + "");
            newScript.setAttribute('id', 'LHCEmbedded');
            newScript.appendChild(inlineScript);
            document.getElementsByTagName('head')[0].appendChild(newScript);
            script.src = ""
            document.getElementsByTagName('head')[0].appendChild(script);
        }
    }, [state.session])

    return (
        <div id="chat-widgit-div">

        </div>
    )
}
