import React, { useState } from "react"
import TopBar from "../TopBar/TopBar"
import "./pageLayout.scss"
import Container from "../Container/Container"
import Sidebar from "../Sidebar/Sidebar"
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import HelperBar from "../HelperBar/HelperBar";

interface IProps
{
    title?: string
    children: React.ReactNode,
    white?: boolean
    noBreadcrumbs?: boolean
    configIdentifier?: string
    helpSections?: IHelpSection[]
    removeHeaders?: boolean
    specialBreadcrumbIndices?: { href: string, text: string }[]
    subIndex?: boolean
}


export default function PageLayout({
    title,
    children,
    white,
    noBreadcrumbs,
    configIdentifier,
    helpSections,
    removeHeaders = false,
    specialBreadcrumbIndices,
    subIndex
}: IProps)
{
    // TODO:Maybe this could be done with contexts?
    const [sidebarExpanded, setSidebarExpanded] = useState(false)
    const [helperSidebarExpanded, setHelperSidebarExpanded] = useState(false)

    if (!removeHeaders)
    {
        return (
            <div className={`background ${white ? "white" : ""}`}>
                <TopBar
                    showHelpButton={helpSections && helpSections.length > 0}
                    sidebarExpanded={sidebarExpanded}
                    setSidebarExpanded={setSidebarExpanded}
                    helperSidebarExpanded={helperSidebarExpanded}
                    setHelperSidebarExpanded={setHelperSidebarExpanded}
                />
                <div className="flex-row flex-1">
                    <Sidebar
                        sidebarExpanded={sidebarExpanded}
                        setSidebarExpanded={setSidebarExpanded}
                    />
                    <div className="content">
                        <Container>
                            {!noBreadcrumbs && <Breadcrumbs configIdentifier={configIdentifier} specialIndices={specialBreadcrumbIndices} subIndex={subIndex} />}
                            {title && (<div className="pageLayoutTitle">

                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    {title}
                                </div>

                            </div>)}
                            {children}
                        </Container>
                    </div>
                    {helpSections && helpSections.length > 0 && (
                        <HelperBar
                            open={helperSidebarExpanded}
                            sections={helpSections}
                        />
                    )}
                </div>
            </div>
        )
    }
    else
    {
        return (
            <div
                className={`background ${white ? "white" : ""} content`}
                style={{ paddingTop: 16 }}
            >
                <Container>
                    {title && (<div className="pageLayoutTitle">

                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            {title}
                        </div>

                    </div>)}
                    {children}
                </Container>
            </div>
        )
    }

}
