import { useContext, useState, useEffect } from "react";
import { SessionContext } from "../../Contexts/SessionContext";
import { useFormReducer } from "../../Hooks/useFormReducer";
import { useRequest } from "../../Hooks/useRequest";

export function useEditGroupUsersModal(groupName)
{
    const { state, updateForm } = useFormReducer({
        record: {
            users: [],
            successes: [],
            failures: []
        }
    })

    const [loading, setLoading] = useState(false)
    const { state: sessionState } = useContext(SessionContext)
    const { putRequest, getRequest } = useRequest()

    async function getLDAPUsers(): Promise<ISelectOption[]>
    {
        return new Promise(async resolve =>
        {
            let users = []

            let response = await getRequest(`/ldap/${sessionState.session.account_no}/users`)
            if (response?.records)
            {
                users = response.records.map(record => ({
                    label: "Seat " + record.user.replace(`${sessionState.session.account_no}*`, ``),
                    value: record.user
                }))
            }
            resolve(users);
        })
    }

    useEffect(() =>
    {
        async function getGroupUsers()
        {
            setLoading(true)
            const groupUsers = []

            let response = await getRequest(`/ldap/groups/${sessionState.session.account_no}/${groupName}/users`)

            if (response?.records)
            {
                response.records.forEach(record =>
                {
                    groupUsers.push(record.user)
                })
            }

            updateForm({
                target: {
                    name: "users",
                    value: groupUsers,
                }
            })

            setLoading(false)
        }

        getGroupUsers()
    }, [groupName])

    function onSubmit(): Promise<{ successes?: postFeedback[]; failures?: postFeedback[] }>
    {
        return new Promise(async (resolve) =>
        {
            setLoading(true)
            const successes = []
            const failures = []

            const response = await putRequest({ users: state.users }, `/ldap/groups/${sessionState.session.account_no}/${groupName}/users`)
            if (response.success)
            {
                successes.push({
                    id: groupName,
                    message: "All selected users have access",
                })
            }
            else if (response.failedUsers)
            {
                response.failedUsers.forEach(failedUser =>
                {
                    failures.push({
                        id: groupName,
                        message: `${failedUser.user} - ${failedUser.reason}`,
                    })
                })
            }
            else
            {
                failures.push({
                    id: groupName,
                    message: "Something went wrong please try again",
                })
            }

            const feedback = {
                successes: successes,
                failures: failures,
            }

            setLoading(false)
            resolve(feedback)
        })
    }

    function getConfirmationStage(): IStage[]
    {
        return [
            {
                title: "Confirm",
                buttonText: "Confirm",
                onComplete: {
                    function: () =>
                    {
                        return new Promise(async (resolve) =>
                        {
                            let feedback
                            feedback = await onSubmit()

                            updateForm({
                                target: {
                                    name: "successes",
                                    value: feedback.successes,
                                },
                            })
                            updateForm({
                                target: {
                                    name: "failures",
                                    value: feedback.failures,
                                },
                            })
                            resolve({ success: true })
                        })
                    },
                },
                fields: [
                    {
                        type: "paragraph",
                        value: "Are you sure you want these users to have access to this group?",
                    }
                ],
            }
        ]
    }

    function getFeedbackStage(): IStage[]
    {
        return [
            {
                title: "Feedback",
                fields: [
                    state?.successes?.length > 0 && {
                        type: "feedbackAccordion",
                        title: `All selected users have access`,
                        items:
                            state.successes &&
                            state.successes.map(
                                (success) =>
                                    `${success.id} - ${success.message}`
                            ),
                        variant: "green"
                    },
                    state?.failures?.length > 0 && {
                        type: "feedbackAccordion",
                        title: `${state.failures.length} user${state.failures.length > 1 ? "s" : ""} do not have access to the group`,
                        items:
                            state.failures &&
                            state.failures.map(
                                (failure) =>
                                    `${failure.id} - ${failure.message}`
                            ),
                        variant: "red"
                    }
                ],
            }
        ]
    }

    return {
        loading,
        state,
        updateForm,
        getLDAPUsers,
        getConfirmationStage,
        getFeedbackStage
    }
}