import React, { useEffect, useState, useContext } from "react"
import { FormField } from "../../components/Form/FormField"
import Modal from "../../components/Modal/Modal"
import { useFormReducer } from "../../Hooks/useFormReducer"
import { useRequest } from "../../Hooks/useRequest"
import { ToastContext } from "../../Contexts/ToastContext"
import Button from "../../components/Button"
import { SessionContext } from "../../Contexts/SessionContext"
import { hasPermission, permissions } from "../../Constants/permissions"

export default function CallRecordingSettingsModal({ open, setOpen })
{
    const { add } = useContext(ToastContext)
    const [record, setRecord] = useState({})
    const [loading, setLoading] = useState(true)
    const { postRequest, putRequest } = useRequest()

    const { state: session } = useContext(SessionContext)

    const { state, updateForm, updateState, updateErrors } = useFormReducer({ record })

    useEffect(() =>
    {
        async function pullData()
        {
            setLoading(true)
            let response = await postRequest({}, `/callrecording/settings`)

            if (response)
            {
                response.passive
                    ? (response.passive = 1)
                    : (response.passive = 0)
                setRecord
                    (
                        {
                            ...response,
                            freePeriodUsage: getStorageValue(response.freePeriodUsage),
                            chargeablePeriodUsage: getStorageValue(response.chargeablePeriodUsage),
                            totalUsage: getStorageValue(response.totalUsage)
                        }
                    )
            }
            setLoading(false)
        }

        pullData()
    }, [])

    async function onSubmit()
    {
        const putData = {
            storageDays: state.storageDays,
            enabled: state.enabled,
            username: state.username,
            password: state.password,
            host: state.host,
            passive: state.passive === 1,
        }

        const response = await putRequest(putData, "/callrecording/settings")
        if (response && response.success)
        {
            add({
                type: "success",
                text: "Updated Successfully",
            })
            setOpen(false)
        } else if (response && response.validation)
        {
            for (var key in response.validation)
            {

                if (response.validation.hasOwnProperty(key))
                {
                    updateErrors({
                        target: {
                            name: `${"state."}${key}`,
                            value: response.validation[key],
                        },
                    })
                    add({
                        type: "error",
                        text: response.validation[key],
                    })
                }
            }
        }
        else if (response && response.message)
        {
            add({
                type: "error",
                text: response.message,
            })
        } else
        {
            add({
                type: "error",
                text: "An error occured.",
            })
        }
    }

    function getStorageValue(value)
    {
        if (value === 0 || !value)
        {
            return "0.0 MB"
        }
        if (value < 1000)
        {
            return value.toFixed(2) + " MB"
        }
        else
        {
            return (value / 1000).toFixed(2) + " GB"
        }
    }

    return (
        <Modal open={open} setOpen={setOpen} maxWidth={800} scrollable={true}>
            <h4>Call Recording Storage</h4>
            <form className="tab-form">
                <div
                    style={{
                        padding: 24,
                    }}
                    className="tab-container"
                >
                    <div style={{ paddingBottom: 24 }}>
                        <FormField
                            type="paragraph"
                            value="By default call recordings are stored for 30 days, for an additional charge call
                                recordings can be stored for longer periods. To increase the standard storage period,
                                select the required value below and click 'Save'."
                        />
                    </div>
                    <br></br>
                    <h6>Hosted Storage</h6>
                    <div style={{ paddingBottom: 24 }}>
                        <FormField
                            type="select"
                            disabled={!hasPermission([permissions.callRecordings, permissions.admin, permissions.VHAdmin], session.session.permissions, "update")}
                            label="Storage Period"
                            options={[
                                { value: 31, label: "1 Month (Default)" },
                                { value: 62, label: "2 Months" },
                                { value: 93, label: "3 Months" },
                                { value: 124, label: "4 Months" },
                                { value: 155, label: "5 Months" },
                                { value: 186, label: "6 Months" },
                                { value: 365, label: "1 Year" },
                                { value: 396, label: "1 Year, 1 Month" },
                                { value: 427, label: "1 Year, 2 Months" },
                                { value: 458, label: "1 Year, 3 Months" },
                                { value: 489, label: "1 Year, 4 Months" },
                                { value: 520, label: "1 Year, 5 Months" },
                                { value: 730, label: "2 Years" },
                                { value: 1095, label: "3 Years" },
                                { value: 1460, label: "4 Years" },
                                { value: 1825, label: "5 Years" },
                                { value: 2190, label: "6 Years" },
                                { value: 2555, label: "7 Years" },
                            ]}
                            value={state.storageDays ? state.storageDays : 31}
                            name={"storageDays"}
                            onChange={updateForm}
                            defaultValue={31}
                        />
                    </div>

                    <div style={{ paddingBottom: 24 }}>
                        <FormField
                            type="info"
                            label="Free Period Usage"
                            value={state.freePeriodUsage}
                            onChange={updateForm}
                            name={"freePeriodUsage"}
                        />
                    </div>

                    <div style={{ paddingBottom: 24 }}>
                        <FormField
                            type="info"
                            label="Chargeable Period Usage"
                            value={state.chargeablePeriodUsage}
                            onChange={updateForm}
                            name={"chargeablePeriodUsage"}
                        />
                    </div>

                    <div style={{ paddingBottom: 24 }}>
                        <FormField
                            type="info"
                            label="Total Usage"
                            value={state.totalUsage}
                            onChange={updateForm}
                            name={"totalUsage"}
                        />
                    </div>
                    <h6>FTP Configuration</h6>

                    <div style={{ paddingBottom: 24 }}>
                        <FormField
                            type={"switch"}
                            label={"FTP Enabled"}
                            value={state.enabled}
                            onChange={updateForm}
                            name={"enabled"}
                            disabled={
                                !hasPermission([permissions.callRecordings, permissions.admin, permissions.VHAdmin], session.session.permissions, "update")
                            }
                        />
                    </div>

                    <div style={{ paddingBottom: 24 }}>
                        <FormField
                            type={"text"}
                            label={"Username"}
                            value={state?.username && state.username != null ? state.username : ""}
                            onChange={updateForm}
                            disabled={!state.enabled || !hasPermission([permissions.callRecordings, permissions.admin, permissions.VHAdmin], session.session.permissions, "update")}
                            name={"username"}
                            maxLength={50}
                        />
                    </div>

                    <div style={{ paddingBottom: 24 }}>
                        <FormField
                            type={"text"}
                            label={"Password"}
                            value={state?.password && state.password != null ? state.password : ""}
                            onChange={updateForm}
                            disabled={!state.enabled || !hasPermission([permissions.callRecordings, permissions.admin, permissions.VHAdmin], session.session.permissions, "update")}
                            name={"password"}
                            maxLength={50}
                        />
                    </div>
                    <div style={{ paddingBottom: 24 }}>
                        <FormField
                            type={"text"}
                            label={"Host Name"}
                            value={state?.host && state.host != null ? state.host : ""}
                            onChange={updateForm}
                            disabled={!state.enabled || !hasPermission([permissions.callRecordings, permissions.admin, permissions.VHAdmin], session.session.permissions, "update")}
                            name={"host"}
                            maxLength={50}
                        />
                    </div>

                    <div style={{ paddingBottom: 24 }}>
                        <FormField
                            type={"select"}
                            label={"Transfer Mode"}
                            options={[
                                { value: 0, label: "Active" },
                                { value: 1, label: "Passive" },
                            ]}
                            value={state.passive >= 0 ? state.passive : 0}
                            onChange={updateForm}
                            disabled={!state.enabled || !hasPermission([permissions.callRecordings, permissions.admin, permissions.VHAdmin], session.session.permissions, "update")}
                            name={"passive"}
                        />
                    </div>
                </div>

                <div className="flex-row button-container justify-end">
                    <Button
                        onClick={() => onSubmit()}
                        color={"white"}
                        variant={"primary"}
                        disabled={!hasPermission([permissions.callRecordings, permissions.admin, permissions.VHAdmin], session.session.permissions, "update")}
                    >
                        Save
                    </Button>
                </div>
            </form>
        </Modal>
    )
}
