import React, { useEffect } from "react"
import Modal from "../Modal/Modal"
import StagedForm from "../Form/StagedForm"
import { useFormReducer } from "../../Hooks/useFormReducer"
import { regex } from "../../Constants/regex"

export function SimpleRecordModal({
    open,
    setOpen,
    dataType,
    fieldFrom,
    settingsStages = [],
    availabilityCheck,
    defineSubmitData,
    onSubmit,
    allowBulkAdd = true,
    selectedRecords,
    action,
    customTitle,
    customConfirm,
}: ISimpleModalSettings)
{
    const { state, updateForm } = useFormReducer({
        record: {
            bulkAdd: false,
            totalNumber: 1,
            totalCost: 0,
            fieldFrom: null,
            fieldTo: null,
        },
    })

    useEffect(() =>
    {
        updateForm({
            target: {
                name: "selectedRecords",
                value: selectedRecords,
            },
        })
    }, [selectedRecords])

    useEffect(() =>
    {
        if (settingsStages)
        {
            settingsStages.forEach(settingsStage =>
            {
                settingsStage.fields.forEach(field =>
                {
                    let value: any = ""
                    if (field.type === "text")
                    {
                        value = ""
                    }
                    if (field.type === "number")
                    {
                        value = 0
                    }
                    if (field.type === "switch")
                    {
                        value = false
                    }
                    if (field.type === "time")
                    {
                        value = "00:00"
                    }
                    if (field.type === "select")
                    {
                        value = ""
                    }
                    if (field.defaultValue || field.defaultValue === 0)
                    {
                        value = field.defaultValue
                    }
                    updateForm({
                        target: {
                            type: field.type,
                            name: field.name,
                            value,
                        },
                    })
                })
            })
        }
    }, [])

    useEffect(() =>
    {
        if (state.bulkAdd)
        {
            updateForm({
                target: {
                    type: "number",
                    name: "fieldTo",
                    value: Number(+state.fieldFrom + 1),
                },
            })
        } else
        {
            updateForm({
                target: {
                    type: "number",
                    name: "fieldFrom",
                },
            })
        }
    }, [state.bulkAdd])

    useEffect(() =>
    {
        if (state.fieldTo !== null)
        {
            updateForm({
                target: {
                    type: "number",
                    name: "totalNumber",
                    value: state.fieldTo - state.fieldFrom + 1,
                },
            })
        } else
        {
            updateForm({
                target: {
                    type: "number",
                    name: "totalNumber",
                    value: 1,
                },
            })
        }
        updateForm({
            target: {
                type: "number",
                name: "totalCost",
                value: state.totalNumber * 6,
            },
        })
    }, [state.fieldFrom, state.fieldTo, state.totalNumber])

    useEffect(() =>
    {
        updateForm({
            target: {
                type: "number",
                name: "totalCost",
                value: state.totalNumber * 6,
            },
        })
    }, [state.totalNumber])

    function getConfirmationText()
    {
        let confirmationText

        if (!customConfirm)
        {
            confirmationText = `Are you sure you want to ${action}`

            if (state.selectedRecords)
            {
                if (regex.pluralCheck.test(dataType))
                {
                    confirmationText += ` ${state.selectedRecords.length} ${state.selectedRecords.length > 1 ? dataType + "ies" : dataType}`
                }
                else
                {
                    confirmationText += ` ${state.selectedRecords.length} ${dataType}${state.selectedRecords.length > 1 ? "s" : ""}`
                }
            }
            else
            {
                if (regex.pluralCheck.test(dataType))
                {
                    confirmationText += ` ${state.totalNumber} ${state.totalNumber > 1 ? dataType + "ies" : dataType}`
                }
                else
                {
                    confirmationText += ` ${state.totalNumber} ${dataType}${state.totalNumber > 1 ? "s" : ""}`
                }
            }
        }
        else
        {
            confirmationText = customConfirm
        }

        return confirmationText
    }

    function completeFunction()
    {
        return new Promise(async resolve =>
        {
            if (action === "create")
            {
                const retData = await defineSubmitData(state)
                const body = {
                    [retData.identifier]: state.fieldFrom,
                    ...retData.data,
                }

                await onSubmit(body)
            } else if (action === "delete")
            {
                await onSubmit(selectedRecords)
            }
            resolve({ success: true })
        })
    }

    return (
        <Modal open={open} setOpen={setOpen} maxWidth={700}>
            {!customTitle && action && dataType ? (
                <h4>
                    {action.charAt(0).toUpperCase() + action.slice(1)}{" "}
                    {dataType.charAt(0).toUpperCase() + dataType.slice(1)}
                </h4>
            ) : (
                <h4>{customTitle}</h4>
            )}
            <StagedForm
                onComplete={() => setOpen(false)}
                stages={[
                    fieldFrom && {
                        title: `${fieldFrom.title ? fieldFrom.title : fieldFrom.label}s`,
                        onComplete: {
                            buttonText: "Create",
                            function: () =>
                            {
                                return new Promise(async resolve =>
                                {
                                    let ret = null
                                    if (availabilityCheck)
                                    {
                                        ret = await availabilityCheck(
                                            state.fieldFrom,
                                            state.fieldTo
                                        )
                                    } else
                                    {
                                        ret = { success: true }
                                    }
                                    resolve(ret)
                                })
                            },
                        },
                        fields: [
                            {
                                ...fieldFrom,
                                value: state.fieldFrom,
                                onChange: updateForm,
                                name: "fieldFrom",
                            },
                            state.bulkAdd && {
                                ...fieldFrom,
                                name: "fieldTo",
                                value: state.fieldTo,
                                min: Number(+state.fieldFrom + 1),
                                label: "To",
                                onChange: updateForm,
                            },
                            allowBulkAdd && {
                                type: "switch",
                                name: "bulkAdd",
                                value: state.bulkAdd,
                                label: "Bulk Add",
                                onChange: updateForm,
                            },
                            {
                                type: "paragraph",
                                value: "Click 'Next' to continue",
                            },
                        ].filter(Boolean),
                    },
                    ...settingsStages.map((stage, index) => ({
                        ...stage,
                        fields: stage.fields.map(field => ({
                            ...field,
                            onChange: updateForm,
                            value: state[field.name],
                        })),
                        buttonText:
                            index === settingsStages.length - 1 && "Create",
                        onComplete: index === settingsStages.length - 1 && {
                            buttonText: "Create",
                            function: () =>
                            {
                                return completeFunction()
                            },
                        },
                    })),
                    action === "delete" && {
                        title: "Confirm",
                        buttonText: "Confirm",
                        onComplete: {
                            function: () =>
                            {
                                return completeFunction()
                            },
                        },
                        fields: [
                            {
                                type: "paragraph",
                                value: getConfirmationText(),
                            },
                        ],
                    },
                ].filter(Boolean)}
            />
        </Modal>
    )
}
