import { useContext, useEffect, useState } from "react"
import { ToastContext } from "../../Contexts/ToastContext"
import useAPIRequests from "../../Hooks/useAPIRequests"
import { useFormReducer } from "../../Hooks/useFormReducer"
import { useRequest } from "../../Hooks/useRequest"
import { regex } from "../../Constants/regex"
import { regexError } from "../../Constants/regexError"
import { permissions } from "../../Constants/permissions"

export function useBlackWhiteListFormPage(id: number)
{
    const { add } = useContext(ToastContext)
    const [record, setRecord] = useState({})
    const [loading, setLoading] = useState(true)
    const { getRequest } = useRequest()
    const { getPromptPromise } = useAPIRequests()
    const {
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
    } = useFormReducer({
        record,
    })

    const columns: IColumn[] = [
        {
            name: "number",
            title: "Number",
        },
    ]

    const fileUploadModal: IUploadFileModalSettings = {
        buttonProps: {
            variant: "success",
            iconLeft: "file-excel",
            color: "white",
            iconVariation: "regular",
            children: "CSV Upload",
            leftSide: true
        },
        multiResponse: true,
        customTitle:
            "Please upload a CSV",
        sectionTitle: "Numbers csv",
        fields: [
            {
                type: "fileUpload",
                name: "numbers",
                label: "Telephone Numbers",
                fileType: "csv",
                accept: ["text/csv"]
            },
        ],
        defineSubmitData: (data) =>
        {
            return new Promise(async (resolve) =>
            {
                const createData = await addACLCsv(data)
                resolve(createData)
            })
        },
        displayResponse: (response) =>
        {
            return new Promise(async (resolve) =>
            {
                const createData = await buildDisplay(
                    response
                )
                resolve(createData)
            })
        }
    }

    async function pullData()
    {
        setLoading(true)
        let response = await getRequest(`/accesscontrol/${id}`)
        if (response)
        {
            setRecord(response)
        } else
        {
            add({
                text: "Failed to retrieve data.",
                type: "error",
            })
        }
        setLoading(false)
    }

    useEffect(() =>
    {
        pullData()
    }, [id])

    function accessListNumberAvailabilityCheck(
        fieldFrom
    ): Promise<{ success: boolean; message: string }>
    {
        return new Promise(async (resolve) =>
        {
            if (!fieldFrom)
            {
                resolve({
                    success: false,
                    message: "Please enter a telephone number",
                })
            }

            let response = await getRequest(
                `/accesscontrol/${id}/entries/-1/-1`
            )

            if (response)
            {
                if (response.records)
                {
                    const filteredRecords = response.records.filter(
                        (record) => record.number === fieldFrom
                    )

                    if (filteredRecords && filteredRecords.length > 0)
                    {
                        resolve({
                            success: false,
                            message:
                                "This telephone number is already a part of this access list",
                        })
                    } else
                    {
                        resolve({ success: true, message: "" })
                    }
                } else
                {
                    resolve({
                        success: false,
                        message: "Failed to retrieve data, please try again",
                    })
                }
            } else
            {
                resolve({
                    success: false,
                    message: "Failed to retrieve data, please try again",
                })
            }
        })
    }

    function addACLCsv(data)
    {
        const postData = new FormData()
        postData.append("numbers", data.numbers)
        return {
            body: postData,
            path: "/accesscontrol/entry/" + id + "/csv"
        }
    }

    function buildDisplay(response)
    {
        let failures = []
        let successes = []
        let multiResponse = false
        let message = ""
        let failureTitle = "These numbers have failed"
        let successTitle = ""
        if (response)
        {
            if (response.success)
            {
                if (response.failedNumbers > 0)
                {
                    multiResponse = true
                    response.failures.forEach((number) =>
                    {
                        failures = [
                            ...failures,
                            { id: number.number, message: number.reason },
                        ]
                    })
                } else
                {
                    message = "All numbers added successfully"
                }
            } else
            {
                message = "Something went wrong please try again"
            }
        }

        const feedback = {
            multiResponse: multiResponse,
            message: message,
            failures: failures,
            failureTitle: failureTitle,
            successTitle: successTitle,
            successes: successes,
        }

        return feedback
    }

    const tabs: ITab[] = [
        {
            title: "List Details",
            icon: "info",
            disabled: false,
            sections: [
                {
                    title: "General Settings",
                    fields: [
                        {
                            type: "text",
                            label: "Name",
                            name: "name",
                            required: true,
                            maxLength: 50
                        },
                        {
                            type: "text",
                            label: "Description",
                            name: "description",
                            maxLength: 50
                        },
                        {
                            type: "select",
                            label: "Prompt",
                            name: "promptId",
                            loadOptions: () => getPromptPromise(),
                            options: [{ label: "None", value: -1 }],
                        },
                    ],
                },
            ],
        },
        {
            title: "Numbers",
            icon: "list-ol",
            disabled: false,
            sections: [
                {
                    fullWidth: true,
                    fields: [
                        {
                            type: "table",
                            customPaginationName: "aclEntries",
                            columns: columns,
                            permissions: [permissions.acl, permissions.admin, permissions.VHAdmin],
                            restType: "GET",
                            urlString: `/accesscontrol/${id}/entries`,
                            pullData: pullData,
                            addModal: {
                                displayName: "number",
                                allowBulkAdd: false,
                                fieldFrom: {
                                    field: "Number",
                                    label: "Number",
                                    type: "text",
                                    regex: regex.telephoneNumber,
                                    regexError: regexError.telephoneNumber
                                },
                                availabilityCheck: (fieldFrom, fieldTo) =>
                                {
                                    return new Promise(async (resolve) =>
                                    {
                                        const responseMessage = await accessListNumberAvailabilityCheck(
                                            fieldFrom
                                        )

                                        if (
                                            responseMessage &&
                                            responseMessage.success
                                        )
                                        {
                                            resolve({ success: true })
                                        } else
                                        {
                                            resolve({
                                                success: false,
                                                error: responseMessage.message,
                                            })
                                        }
                                    })
                                },
                                submitData: (data) =>
                                {
                                    return [{
                                        postData: {
                                            aclId: id,
                                        },
                                        path: "/accesscontrol/entry/" + id,
                                        identifier: "number",
                                        type: "create",
                                        displayName: data.fromField,
                                    }]
                                },
                            },
                            uploadFileModal: fileUploadModal,
                            deleteModal: {
                                displayName: "Numbers",
                                submitData: (data) =>
                                {
                                    return [{
                                        path: "/accesscontrol/entry",
                                        displayName: "number",
                                        type: "delete",
                                    }]
                                },
                            },
                        },
                    ],
                },
            ],
        },
    ]

    function submitData()
    {
        return [
            {
                body: state,
                path: "/accesscontrol/" + id,
            },
        ]
    }

    function deleteData()
    {
        return [{
            path: "/accesscontrol",
            type: "delete",
            indexPagePath: "/access-lists",
            displayName: "Black/White List"
        }]
    }

    return {
        loading,
        tabs,
        state,
        updateState,
        updateForm,
        updateErrors,
        submitData,
        deleteData,
        // changes,
        // resetChanges,
    }
}
