import React, { useEffect, useState } from "react"
import { regex } from "../../Constants/regex"
import { regexError } from "../../Constants/regexError"
import useAPIRequests from "../../Hooks/useAPIRequests"
import { useFormReducer } from "../../Hooks/useFormReducer"
import { useRequest } from "../../Hooks/useRequest"
import { getOptionValue } from "react-select/src/builtins"

export function useRegistrationFormPage()
{
    const [loading, setLoading] = useState(true)
    const [isUK, setIsUK] = useState(true)
    const { getCountriesPromise } = useAPIRequests()
    const { getRequest } = useRequest()
    const recaptchaRef = React.createRef()

    const { state, updateForm, updateState, updateErrors } = useFormReducer({
        record: {
            type: 0,
            country: 1,
            endUserType: "0",
        },
    })

    useEffect(() =>
    {
        setLoading(true)
        new Promise(async () =>
        {
            let details = await getRequest(`/ip/check/details`)

            if (details && details.isoCode && details.isoCode === "GB")
            {
                setIsUK(true)
            }
            else
            {
                setIsUK(false)
            }
        })
        setLoading(false)
    }, [])

    function companiesHouseLookup(e)
    {
        setLoading(true)

        new Promise(async () =>
        {
            let record = await getRequest(`/account/companieshouse/${e}`)

            if (record && record.company_name)
            {
                updateForm({
                    target: { value: record.company_name, name: "companyName" }
                })
                updateErrors({
                    target: { value: "", name: "companyName" }
                })

                if (record.registered_office_address)
                {
                    updateForm({
                        target: { value: record.registered_office_address.address_line_1, name: "addressLine1" }
                    })
                    updateForm({
                        target: { value: record.registered_office_address.address_line_2, name: "addressLine2" }
                    })
                    updateForm({
                        target: { value: record.registered_office_address.locality, name: "city" }
                    })
                    updateForm({
                        target: { value: record.registered_office_address.region, name: "county" }
                    })
                    updateForm({
                        target: { value: record.registered_office_address.postal_code, name: "postCode" }
                    })
                }
            }
            else
            {
                updateForm({
                    target: { value: "Company not found", name: "companyName" }
                })
                updateErrors({
                    target: { value: "Please enter a valid UK Company Number", name: "companyNo" }
                })
            }
        })
        setLoading(false)
    }

    useEffect(() =>
    {
        if (state.type === "1")
        {
            updateErrors({
                target: { value: "", name: "companyName" }
            })
            updateErrors({
                target: { value: "", name: "companyNo" }
            })
            updateErrors({
                target: { value: "", name: "vatNo" }
            })
        }
        else 
        {
            if (state.companyNo)
            {
                companiesHouseLookup(state.companyNo)
            }
            if (state.vatNo && !/^[0-9]{0,9}$/.test(state.vatNo))
            {
                updateErrors({
                    target: { value: "Please enter a valid company vat number", name: "vatNo" }
                })
            }

        }
    }, [state.type])

    function getSections(): ISection[]
    {
        if (state.endUserType == 0 || state.endUserType == 1)
        {
            return [
                {
                    title: "Company Details",
                    fields: [
                        {
                            type: state.endUserType == 0 && state.registeredCompany == !null ? "info" : "text",
                            label: "Company Name",
                            name: "companyName",
                            required: state.endUserType == 0 || state.endUserType == 1 ? true : false,
                        },
                        {
                            type: "text",
                            label: "Company Reg No",
                            name: "companyNo",
                            regex: /^[\w 0-9]{0,15}$/,
                            regexError: "Please enter a valid company registration number",
                            required: true,
                            onBlur: (e) => companiesHouseLookup(e.target.value),
                            conditionallyShow: { checks: [{ field: "registeredCompany", value: !null }] },
                        },
                        {
                            type: "text",
                            label: "Company VAT No",
                            name: "vatNo",
                            regex: /^[0-9]{0,9}$/,
                            regexError: "Please enter a valid company vat number",
                        }
                    ]
                },

            ]
        }
        else
        {
            return []
        }
    }

    useEffect(() =>
    {
        if (state.country === 1 && state.companyNo)
        {
            companiesHouseLookup(state.companyNo)
        }
    }, [state.country])

    const tabs: ITab[] = [
        {
            title: "VoiceHost Registration",
            icon: "toolbox",
            disabled: false,
            sections: [
                {
                    title: "Account",
                    fields: [
                        {
                            type: "select",
                            label: "Country",
                            loadOptions: getCountriesPromise,
                            name: "country",
                            onChange: updateForm,
                        },
                        {
                            type: "radio",
                            label: "End User Type",
                            name: "endUserType",
                            radioOptions: [
                                { value: "0", label: "Business User", tooltip: "A business with more than 10 employees" },
                                { value: "1", label: "Small / Microenterprise", tooltip: "A business with less than 10 employees" },
                                { value: "2", label: "Consumer / End User", tooltip: "A single person not using the service for business purposes" },
                            ],
                            onChange: (e) =>
                            {
                                updateForm(e)
                                updateForm({
                                    target: {
                                        value: null, type: "checkbox", name: "vulnerablePerson"
                                    },
                                })
                            },
                        },
                        {
                            type: "checkbox",
                            label: "Registered Company",
                            name: "registeredCompany",
                            conditionallyShow: {
                                checks: [
                                    { field: "endUserType", value: "0" },
                                    { field: "endUserType", value: "1" }
                                ],
                                operator: 'OR'
                            },
                            onChange: (e) =>
                            {
                                updateForm(e)
                                updateForm({
                                    target: {
                                        value: null, type: "info", name: "companyName"
                                    }
                                })
                            },
                        },
                        {
                            type: "checkbox",
                            label: "Vulnerable Person",
                            name: "vulnerablePerson",
                            conditionallyShow: { checks: [{ field: "endUserType", value: "2" }] },
                        },
                    ]
                },
                {
                    title: "Account Details",
                    fields: [
                        {
                            type: "text",
                            label: "First Name",
                            name: "firstName",
                            regex: /[\w ]{1,45}/,
                            regexError: "Please enter a valid first name",
                            maxLength: 45,
                            required: true
                        },
                        {
                            type: "text",
                            label: "Surname",
                            name: "surname",
                            regex: /[\w ]{1,45}/,
                            regexError: "Please enter a valid surname",
                            maxLength: 45,
                            required: true
                        }
                    ]
                },
                ...getSections(),
                {
                    title: state.endUserType == 0 || state.endUserType == 1 ? "Company Premise" : "Address",
                    fields: [
                        {
                            type: "text",
                            label: "Address Line 1",
                            name: "addressLine1",
                            maxLength: 100,
                            required: true
                        },
                        {
                            type: "text",
                            label: "Address Line 2",
                            name: "addressLine2",
                            regex: /[\w ]{0,100}/,
                            regexError: "Please enter a valid address",
                            maxLength: 100
                        },
                        {
                            type: "text",
                            label: "City",
                            name: "city",
                            regex: /[\w ]{1,100}/,
                            regexError: "Please enter a valid city",
                            maxLength: 100,
                            required: true
                        },
                        {
                            type: "text",
                            label: "County",
                            name: "county",
                            regex: /[\w ]{0,50}/,
                            regexError: "Please enter a valid county",
                            maxLength: 50
                        },
                        {
                            type: "text",
                            label: "Post Code",
                            name: "postCode",
                            regex: regex.postcode,
                            regexError: regexError.postcode,
                            required: true
                        },
                    ]
                },
                {
                    title: "Contact Details",
                    fields: [
                        {
                            type: "text",
                            label: "Telephone",
                            name: "telephone",
                            regex: regex.telephoneNumber,
                            regexError: regexError.telephoneNumber,
                            required: true
                        },
                        {
                            type: "text",
                            label: "Fax",
                            name: "fax",
                            regex: regex.telephoneNumber,
                            regexError: "Please enter a valid fax number",
                        },
                        {
                            type: "text",
                            label: "Mobile",
                            name: "mobile",
                            regex: regex.telephoneNumber,
                            regexError: "Please enter a valid mobile number",
                        },
                        {
                            type: "text",
                            label: "Email",
                            name: "email",
                            regex: regex.email,
                            regexError: regexError.email,
                            required: true
                        },
                    ]
                },
                {
                    title: "Confirmation",
                    fields: [
                        {
                            type: "paragraph",
                            value: `I have read and agree to the <a target="_blank" href=https://www.voicehost.co.uk/terms-and-conditions >VoiceHost Ltd Terms & Conditions.</a>`,
                        },
                        {
                            type: "checkbox",
                            onChange: updateForm,
                            label: "Terms & Conditions",
                            name: "tAndC",
                            halfWidth: state.type === 1,
                            required: true
                        },
                        {
                            type: "captcha",
                            recaptchaRef: recaptchaRef,
                            name: "reCaptcha",
                            onChange: (value) => updateForm({
                                target: {
                                    value: value, name: "reCaptcha"
                                }
                            }),
                            halfWidth: true
                        },
                    ]
                }

            ]
        }
    ]

    function resetCaptcha() 
    {
        //@ts-ignore
        recaptchaRef && recaptchaRef.current && recaptchaRef.current.reset()
    }

    function submitData()
    {
        if (state.endUserType == 0 || state.endUserType == 1)
        {
            return [
                {
                    body: {
                        companyName: state.companyName,
                        firstName: state.firstName,
                        surname: state.surname,
                        addressLine1: state.addressLine1,
                        addressLine2: state.addressLine2,
                        city: state.city,
                        county: state.county,
                        country: state.country,
                        postCode: state.postCode,
                        telephone: state.telephone,
                        mobile: state.mobile,
                        fax: state.fax,
                        email: state.email,
                        tAndC: state.tAndC,
                        vatNo: state.vatNo,
                        companyNo: state.companyNo,
                        reCaptcha: state.reCaptcha,
                        endUserType: state.endUserType,
                        vulnerablePerson: state.vulnerablePerson ? true : false,
                    },
                    path: "/account/register",
                    displayName: "Account",
                    restType: `POST`,
                    redirect: "/registration/success"
                },
            ]
        }
        else
        {
            return [
                {
                    body: {
                        firstName: state.firstName,
                        surname: state.surname,
                        addressLine1: state.addressLine1,
                        addressLine2: state.addressLine2,
                        city: state.city,
                        county: state.county,
                        country: state.country,
                        postCode: state.postCode,
                        telephone: state.telephone,
                        mobile: state.mobile,
                        fax: state.fax,
                        email: state.email,
                        tAndC: state.tAndC,
                        reCaptcha: state.reCaptcha,
                        endUserType: state.endUserType,
                        vulnerablePerson: state.vulnerablePerson ? true : false,
                    },
                    path: "/account/register",
                    displayName: "Account",
                    restType: `POST`,
                    redirect: "/registration/success"
                },
            ]
        }
    }

    return {
        state,
        updateState,
        updateForm,
        updateErrors,
        loading,
        tabs,
        submitData,
        isUK,
        resetCaptcha,
        // changes,
        // resetChanges,
    }
}