import { useFormReducer } from "../../Hooks/useFormReducer"
import { useRequest } from "../../Hooks/useRequest"
import { useState, useEffect } from "react"
import { regex } from "../../Constants/regex"
import { regexError } from "../../Constants/regexError"
import { permissions } from "../../Constants/permissions"

export function useVoicemailSettingsPage(mailbox: string)
{
    const [record, setRecord] = useState({})
    const [loading, setLoading] = useState(true)
    const { getRequest } = useRequest()

    async function pullData()
    {
        setLoading(true)

        const voicemailResponse = await getRequest(`/voicemail/${mailbox}/settings`)
        if (voicemailResponse)
        {
            // @ts-ignore
            if (voicemailResponse.vmDelete === true)
            {
                // @ts-ignore
                voicemailResponse.vmDelete = false
            }
            else
            {
                // @ts-ignore
                voicemailResponse.vmDelete = true
            }

            setRecord(voicemailResponse)
            setLoading(false)
        }
    }

    useEffect(() =>
    {

        pullData()
        // eslint-disable-next-line
    }, [mailbox])

    const { state, updateState, updateForm, updateErrors } = useFormReducer({ record })

    // const { getMobiles } = useAPIRequests()

    // const mobileColumns: IColumn[] = [
    //     {
    //         name: "number",
    //         title: "Number"
    //     },
    //     {
    //         name: "description",
    //         title: "Name"
    //     }
    // ]

    const voicemailColumns: IColumn[] = [
        {
            name: "type",
            title: "Type",
            bold: true,
            link: { root: `/voicemail/${mailbox}` }
        }
    ]

    function submitData()
    {

        return [
            {
                body: {
                    fullname: state.fullname,
                    email: state.email,
                    vmDelete: state.vmDelete === true ? false : true,
                    password: state.password,
                },
                path: `/voicemail/${mailbox}/settings`,
            }
        ]
    }

    // function checkIfMobileInMailbox(id): Promise<{ success: boolean, message: string }>
    // {
    //     return new Promise(async resolve =>
    //     {

    //         let response = await getRequest(`/voicemail/${mailbox}/mobiles/-1/-1`)

    //         if (response)
    //         {
    //             if (response.records)
    //             {
    //                 const filteredRecords = response.records.filter(record => record.id === id)

    //                 if (filteredRecords && filteredRecords.length > 0)
    //                 {
    //                     resolve({ success: false, message: "This mobile is already a part of this mailbox" })
    //                 } else
    //                 {
    //                     resolve({ success: true, message: "" })
    //                 }
    //             } else
    //             {
    //                 resolve({ success: false, message: "Failed to retrieve data, please try again" })
    //             }
    //         } else
    //         {
    //             resolve({ success: false, message: "Failed to retrieve data, please try again" })
    //         }
    //     })
    // }

    // function checkIfVoicemailInMailbox(value): Promise<{ success: boolean, message: string }>
    // {
    //     return new Promise(async resolve =>
    //     {

    //         let response = await getRequest(`/voicemail/${mailbox}/prerecorded/-1/-1`)
    //         if (response)
    //         {
    //             if (response.records)
    //             {
    //                 const filteredRecords = response.records.filter(record => record.type === value)

    //                 if (filteredRecords && filteredRecords.length > 0)
    //                 {
    //                     resolve({ success: false, message: `Mailbox already contains a ${value} recording` })
    //                 } else
    //                 {
    //                     resolve({ success: true, message: "" })
    //                 }
    //             } else
    //             {
    //                 resolve({ success: false, message: "Failed to retrieve data, please try again" })
    //             }
    //         } else
    //         {
    //             resolve({ success: false, message: "Failed to retrieve data, please try again" })
    //         }
    //     })
    // }

    function defineData(data)
    {
        const postData = new FormData()
        postData.append("file", data.file)
        postData.append("type", data.type)

        return {
            body: postData,
            path: `/voicemail/${mailbox}/messages`
        }
    }

    function buildDisplay(response)
    {
        let failures = []
        let successes = []
        let multiResponse = false
        let message = ""
        let failureTitle = ""
        let successTitle = ""

        if (response && response.success)
        {
            message = "Prerecorded Voicemail added successfully"
        }
        else if (response && response.message)
        {
            message = response.message
        }
        else
        {
            message = "Something went wrong, please try again"
        }

        const feedback = {
            multiResponse: multiResponse,
            message: message,
            failures: failures,
            failureTitle: failureTitle,
            successTitle: successTitle,
            successes: successes,
        }

        return feedback
    }

    const fileUploadModal: IUploadFileModalSettings = {
        buttonProps: {
            variant: "success",
            iconLeft: "file-audio",
            color: "white",
            iconVariation: "regular",
            children: "Prerecorded Voicemail Upload",
            leftSide: true
        },
        multiResponse: false,
        customTitle:
            "Please upload an audio file",
        sectionTitle: "Audio File",
        fields: [
            {
                type: "paragraph",
                value: "Selecting a type that is already in use will result in that prerecording to be overwritten."
            },
            {
                type: "select",
                name: "type",
                label: "Type",
                options: [
                    { label: "Busy", value: "Busy" },
                    { label: "Unavailable", value: "Unavailable" },
                    { label: "Greeting", value: "Greeting" },
                    { label: "Temporary", value: "Temporary" }
                ]
            },
            {
                type: "fileUpload",
                name: "file",
                label: "Prerecorded Voicemail",
                fileType: "mp3/wav",
                accept: ["audio/mpeg", "audio/wav"],
                maxSizeBytes: 20 * 1000 * 1000,
                required: true
            }
        ],
        defineSubmitData: (data) =>
        {
            return new Promise(async (resolve) =>
            {
                const createData = await defineData(data)
                resolve(createData)
            })
        },
        displayResponse: (response) =>
        {
            return new Promise(async (resolve) =>
            {
                const createData = await buildDisplay(
                    response
                )
                resolve(createData)
            })
        },
    }

    const tabs: ITab[] = [
        {
            title: "Mailbox Configuration",
            icon: "toolbox",
            disabled: false,
            sections: [
                {
                    title: "Details",
                    fields: [
                        {
                            label: "Name",
                            type: "text",
                            name: "fullname",
                            maxLength: 150
                        },
                        {
                            label: "Email",
                            type: "text",
                            name: "email",
                            regex: regex.email,
                            regexError: regexError.email,
                            maxLength: 50
                        },
                        {
                            label: "Keep On Network",
                            type: "switch",
                            name: "vmDelete",
                        }
                    ]
                },
                {
                    title: "Remote Access",
                    fields: [
                        {
                            label: "",
                            type: "paragraph",
                            value: "Voicemail can be remotely accessed via any telephone by calling the access number below. When prompted enter the mailbox number along with the PIN below."
                        },
                        {
                            label: "Access Number",
                            type: "info",
                            value: "0843 557 4 557",
                        },
                        {
                            label: "Mailbox",
                            type: "info",
                            name: "mailbox"
                        },
                        {
                            label: "PIN",
                            type: "text",
                            name: "password",
                            regex: /[0-9]{0,10}/,
                            regexError: "PIN must be numerical with a maximum of 10 digits"
                        }
                    ]
                },
                // {
                //     title: "Mobile SMS Alerts",
                //     fields: [
                //         {
                //             label: "Mobiles",
                //             type: "table",
                //             columns: mobileColumns,
                //             restType: "GET",
                //             urlString: `/voicemail/${mailbox}/mobiles`,
                //             pullData: pullData,
                //             addModal: {
                //                 customTitle: "Add Mobile to Mailbox",
                //                 displayName: "Mobile",
                //                 allowBulkAdd: false,
                //                 fieldFrom: {
                //                     field: "number",
                //                     label: "Telephone Number",
                //                     type: "select",
                //                     loadOptions: getMobiles
                //                 },
                //                 availabilityCheck: (id) =>
                //                 {
                //                     return new Promise(async resolve =>
                //                     {

                //                         const responseMessage = await checkIfMobileInMailbox(id)

                //                         if (responseMessage && responseMessage.success)
                //                         {
                //                             resolve({ success: true })
                //                         } else
                //                         {
                //                             resolve({ success: false, error: responseMessage.message })
                //                         }
                //                     })
                //                 },
                //                 submitData: data =>
                //                 {
                //                     return new Promise(async resolve =>
                //                     {
                //                         const createData = {
                //                             path: `/voicemail/${mailbox}/mobiles/${data.fieldFrom}`,
                //                             postData: {},
                //                             type: "Mobile"
                //                         }
                //                         resolve(createData)
                //                     })
                //                 }
                //             },
                //             deleteModal: {
                //                 displayName: "Mobile",
                //                 uniqueIdentifier: "description",
                //                 submitData: data =>
                //                 {
                //                     return new Promise(async resolve =>
                //                     {

                //                         const deleteData = {
                //                             path: `/voicemail/${mailbox}/mobiles`,
                //                             type: "delete",
                //                             displayName: "description",
                //                         }
                //                         resolve(deleteData)
                //                     })
                //                 }
                //             }
                //         }
                //     ]
                // },
                {
                    title: "Recorded Voicemail",
                    fields: [
                        {
                            label: "Voicemails",
                            type: "table",
                            customPaginationName: "voicemailSettings",
                            columns: voicemailColumns,
                            pullData: pullData,
                            restType: "GET",
                            urlString: `/voicemail/${mailbox}/prerecorded`,
                            permissions: [permissions.voicemail, permissions.admin, permissions.VHAdmin],
                            uploadFileModal: fileUploadModal,
                            deleteModal: {
                                displayName: "Voicemail Prompt",
                                submitData: () =>
                                {
                                    return [{
                                        path: `/voicemail/messages`,
                                        type: "delete",
                                    }]
                                },
                            }
                        }
                    ]
                }
            ]
        },
    ]

    return { loading, tabs, state, updateState, updateForm, updateErrors, submitData };
}