import React from "react";
import Icon from "../Icons/Icon";
import { NavLink } from "react-router-dom";
import "./menuItem.scss";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import Button from "../Button";

interface IProps
{
    children: React.ReactNode
    to?: string
    isExternalLink?: boolean
    onClick?: () => void
    button?: boolean
    disabled?: boolean
    hoverHighlight?: boolean
    borderBottom?: boolean
    padding?: number | string
    fontColor?: string
    disabledFontColor?: string
    fontSize?: number | string
    textClassName?: string
    icon?: string
    iconSize?: SizeProp
    iconColor?: string
    iconBoxSize?: number | string
    iconVariation?: "solid" | "regular"
    iconRight?: string
    iconRightColor?: string
    iconRightSize?: SizeProp
    iconRightStyle?: { [key: string]: string | number }
}

export function MenuItem({
    children,
    to,
    isExternalLink,
    onClick,
    button,
    disabled,
    hoverHighlight,
    padding,
    fontColor,
    disabledFontColor,
    borderBottom,
    fontSize,
    textClassName,
    icon,
    iconSize,
    iconColor,
    iconBoxSize,
    iconVariation,
    iconRight,
    iconRightColor,
    iconRightSize,
    iconRightStyle
}: IProps)
{

    function renderContent()
    {
        return <div
            className={`flex-1 flex-row ${hoverHighlight ? "menuItemHoverHighlight" : ""} ${borderBottom ? "menuItemBorderBottom" : ""}`}
            style={{
                padding: padding,
            }}
        >
            {!button && (
                <div
                    className="menuItemIconContainer"
                    style={{
                        height: iconBoxSize && iconBoxSize,
                        width: iconBoxSize && iconBoxSize
                    }}
                >
                    {icon && (
                        <Icon
                            icon={icon}
                            size={iconSize}
                            color={iconColor}
                            variation={iconVariation}
                        />
                    )}
                </div>
            )}
            <div className={`flex-1 menuItemText ${textClassName ? textClassName : ""}`}>
                {button ? (
                    <Button size={"sm"}
                        onClick={onClick}
                    >
                        {children}
                    </Button>
                ) : (
                        children
                    )}
            </div>
            {iconRight && (
                <div className="menuItemRightIcon">
                    <Icon
                        icon={iconRight}
                        size={iconRightSize}
                        color={iconRightColor}
                        style={iconRightStyle}
                    />
                </div>
            )}
        </div>
    }

    return (
        !isExternalLink ? (
            <NavLink

                onClick={(e) =>
                {
                    if (!to)
                    {
                        e.preventDefault()
                    }
                    if (onClick)
                    {
                        e.preventDefault()
                        onClick()
                    }
                }}
                to={to ? to : ""}
                className="flex-1"
                style={{
                    color: disabled ? disabledFontColor : fontColor,
                    cursor: disabled && "not-allowed",
                    textDecoration: "none",
                    fontSize: fontSize && fontSize,

                }}
            >
                {renderContent()}
            </NavLink>
        ) : (
                <a
                    onClick={(e) =>
                    {
                        if (!to)
                        {
                            e.preventDefault()
                        }
                        if (onClick)
                        {
                            e.preventDefault()
                            onClick()
                        }
                    }}
                    href={to ? to : ""}
                    className="flex-1"
                    style={{
                        color: disabled ? disabledFontColor : fontColor,
                        cursor: disabled && "not-allowed",
                        textDecoration: "none",
                        fontSize: fontSize && fontSize,

                    }}
                >
                    {renderContent()}
                </a>
            )
    );
}
