import { useContext, useEffect, useState } from "react"
import { useRequest } from "../../Hooks/useRequest"
import { useFormReducer } from "../../Hooks/useFormReducer"
import { regex } from "../../Constants/regex"
import { regexError } from "../../Constants/regexError"
import { SessionContext } from "../../Contexts/SessionContext"

export function usePhoneDirectoryFormPage(entry: string, directory: string)
{
    const [record, setRecord] = useState({})
    const [loading, setLoading] = useState(true)
    const { getRequest } = useRequest()
    const { state: sessionState } = useContext(SessionContext)
    const [specialBreadcrumbIndices, setSpecialBreadcrumbIndices] = useState<{ href: string; text: string; }[]>()

    useEffect(() =>
    {
        async function pullData()
        {
            setSpecialBreadcrumbIndices(regex.extension.test(directory) || directory === 'central' ?
                [{ href: `phone-directory/${directory}`, text: directory }] :
                [{ href: `phone-directory/groups`, text: `Groups` }, { href: `phone-directory/groups/${directory}`, text: directory }])

            setLoading(true)
            const apiResponse = await getRequest(`/ldap/${sessionState.session.account_no}/${directory}/${entry}`)

            if (apiResponse)
            {
                setRecord(apiResponse)
            }

            setLoading(false)
        }

        pullData()
        // eslint-disable-next-line
    }, [entry, directory])

    function submitData()
    {
        return [
            {
                body: {
                    givenName: state.givenName,
                    surname: state.surname,
                    organisation: state.organisation,
                    telephone: state.telephone,
                    mobile: state.mobile,
                    voipPhone: state.voipPhone,
                    voipExt: state.voipExt,
                },
                path: `/ldap/${sessionState.session.account_no}/${directory}/${entry}`,
            },
        ]
    }

    function deleteData()
    {
        return [{
            path: `/ldap/${sessionState.session.account_no}/${directory}`,
            displayName: "Directory Entry",
            indexPagePath: `/phone-directory/${directory}`,
            type: "delete"
        }]
    }

    const tabs: ITab[] = [
        {
            title: "LDAP Entry",
            icon: "toolbox",
            disabled: false,
            sections: [
                {
                    title: "Entry Details",
                    fullWidth: true,
                    fields: [
                        {
                            label: "Name",
                            type: "text",
                            name: "givenName",
                        },
                        {
                            label: "Surname",
                            type: "text",
                            name: "surname",
                            required: true
                        },
                        {
                            label: "Organisation",
                            type: "text",
                            name: "organisation",
                        },
                        {
                            label: "Telephone Number",
                            type: "text",
                            name: "telephone",
                            regex: regex.telephoneNumber,
                            regexError: regexError.telephoneNumber
                        },
                        {
                            label: "Mobile Number",
                            type: "text",
                            name: "mobile",
                            regex: regex.telephoneNumber,
                            regexError: regexError.telephoneNumber
                        },
                        {
                            label: "VoIP Phone",
                            type: "text",
                            name: "voipPhone",
                            regex: regex.telephoneNumber,
                            regexError: regexError.telephoneNumber
                        },
                        {
                            label: "VoIP Ext",
                            type: "text",
                            name: "voipExt",
                        }
                    ],
                },
            ],
        },
    ]

    const {
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
    } = useFormReducer({ record })

    return {
        loading,
        tabs,
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
        submitData,
        deleteData,
        specialBreadcrumbIndices
    }
}
