import React, { useEffect } from "react"
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import { handleInputChange } from "react-select/src/utils";

export default function MultiText({
    onChange,
    name,
    required,
    setError,
    maxLength,
    value,
    validateText,
    regex,
    regexError,
}: IMultiText)
{
    function validateInput(text: string):boolean
    {
        if (validateText) 
        {
            return isEmail(text);
        }
        else 
        {
            return true;
        }                       

    }

    function handleOnChange(valArray)
    {
        if ((!valArray || (valArray && !valArray.length)) && required)
        {
            setError && setError({ target: { name, value: "This is a required field" } })
        }
        else if (!valArray && !required)
        {
            setError && setError({ target: { name, value: "" } })
        }
        else if (valArray && valArray.length)
        {
            if (maxLength)
            {
                let characters = 0

                valArray.forEach(value =>
                {
                    characters += value.length
                });
                if (characters + valArray.length - 1 > maxLength)
                {
                    setError && setError({ target: { name, value: `Length must not exceed 3 IP addresses` } })
                }
                else if (regex)
                {
                    valArray.forEach(value =>
                        {
                            if (regex.test(value))
                            {
                                setError && setError({ target: { ...valArray, name, value: "" } })
                            }
                            else
                            {
                                setError && setError({ target: { ...valArray, name, value: regexError } })
                            }
                        });
                }
                else 
                {
                    setError && setError({ target: { ...valArray.target, name, value: "" } })
                }
            }
        }
        onChange && onChange({ target: {
            value: valArray,
            name
        }})
    }

    return (
        <ReactMultiEmail
            emails={value}
            onChange={handleOnChange}
            validateEmail={validateInput}
            getLabel={(
               email: string,
               index: number,
                removeEmail: (index: number) => void
            )=>
            {
               return (
                  <div data-tag key={index}>
                       {email}
                       <span data-tag-handle onClick={() => removeEmail(index)}>
                           ×
                       </span>
                   </div>
               )
            }}
        />
    )
}