import React from "react";
import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage";
import useAPIRequests from "../../Hooks/useAPIRequests";
import { regex } from "../../Constants/regex";
import { regexError } from "../../Constants/regexError";
import { permissions } from "../../Constants/permissions";

export default function ConferencingIndexPage()
{
    const { getMusicOnHoldPromise } = useAPIRequests()

    const columns: IColumn[] = [
        {
            title: "Conference Number",
            link: { root: "/conferencing" },
            bold: true,
            name: "confNumber",
            sortByName: "confno",
            sortBy: true,
        },
        {
            title: "Nickname",
            name: "nickname",
            sortByName: "vh_nickname",
            sortBy: true,
        }
    ]

    const modalSettings: ISettingsStage[] = [
        {
            title: "Settings",
            fields: [
                {
                    type: "text",
                    name: "nickname",
                    label: "Nickname",
                    maxLength: 50,
                },
                {
                    type: "text",
                    name: "pin",
                    label: "PIN",
                    regex: regex.pin,
                    regexError: regexError.pin,
                    required: true
                },
                {
                    type: "text",
                    name: "adminPin",
                    label: "Admin PIN",
                    regex: regex.pin,
                    regexError: regexError.pin,
                    required: true
                },
                {
                    type: "switch",
                    name: "listenOnly",
                    label: "Listen Only"
                },
                {
                    type: "switch",
                    name: "waitForMarked",
                    label: "Wait For Admin"
                },
                {
                    type: "switch",
                    name: "announceUsers",
                    label: "Announce Attendees"
                },
                {
                    type: "switch",
                    name: "moh",
                    label: "Music On Hold"
                },
                {
                    type: "select",
                    name: "mohClass",
                    loadOptions: getMusicOnHoldPromise,
                    label: "Music On Hold File"
                }
            ],
        },
    ]

    return (
        <React.Fragment>
            <GenericIndexPage
                title={"Conferencing"}
                urlString={"/conferencing"}
                columns={columns}
                restType={"POST"}
                searchable={true}
                body={{ sortBy: "confno", direction: "ASC" }}
                permissions={[permissions.conference, permissions.admin, permissions.VHAdmin]}
                addModal={{
                    displayName: "Conference Room",
                    allowBulkAdd: false,
                    settingsStages: modalSettings,
                    submitData: data =>
                    {
                        return [{
                            postData: {
                                pin: data.pin,
                                adminPin: data.adminPin,
                                moh: data.moh,
                                mohClass: data.mohClass,
                                announceUsers: data.announceUsers,
                                waitForMarked: data.waitForMarked,
                                listenOnly: data.listenOnly,
                                nickname: data.nickname
                            },
                            path: "/conferencing",
                            type: "create",
                        }]
                    }
                }}
                deleteModal={{
                    displayName: "Conference Room",
                    uniqueIdentifier: "confNumber",
                    submitData: () =>
                    {
                        return [{
                            path: "/conferencing",
                            type: "delete"
                        }]
                    },
                }}
                helpSections={[
                    {
                        title: "Conferencing",
                        text: "Allows you to setup and manage your conference room."
                    }
                ]}
            />
        </React.Fragment>
    )
}
