import PageLayout from "../../components/PageLayout/PageLayout";
import React from "react";
import SuccessPageText from "../../components/SuccessPageText/SuccessPageText";

export default function RegistrationSuccessPage()
{
    return (
        <PageLayout
            removeHeaders={true}>
            <SuccessPageText
                success={"Account Created Successfully"}
                text={"Please check your emails to activate the account"}
            />

        </PageLayout>
    )
}