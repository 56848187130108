import "bootstrap/dist/css/bootstrap.min.css"
import React from "react"
import "./App.scss"
import CacheBuster from 'react-cache-buster';
import { ToastProvider } from "../../Contexts/ToastContext"
import PageSelector from "../PageSelector"
import { SessionProvider } from "../../Contexts/SessionContext"
import { ThemeProvider } from "../../Contexts/ThemeContext"
import { BrowserRouter } from "react-router-dom"
import ChatWidget from "../ChatWidget/ChatWidget"

export default function App()
{
    const ISPRODUCTION = process.env.NODE_ENV === 'production';
    const VERSION = require("../../../package.json").version;

    return (
        <ToastProvider>
            <SessionProvider>
                <ThemeProvider>
                    <BrowserRouter>
                        <PageSelector />
                        <ChatWidget />
                        <CacheBuster
                            currentVersion={VERSION}
                            isEnabled={ISPRODUCTION}
                            isVerboseMode={false}
                            metaFileDirectory={'.'}
                        ></CacheBuster>
                    </BrowserRouter>
                </ThemeProvider>
            </SessionProvider>
        </ToastProvider>
    )
}