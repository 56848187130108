import Button from "../Button"
import React, { useState } from "react"

export function FormButton({
    onClick,
    placeholder,
    buttonVariant,
    buttonSize,
}: IFormButton) {
    const [loading, setLoading] = useState(false)

    async function handleOnClick(e) {
        setLoading(true)
        await onClick(e)
        setLoading(false)
    }

    return (
        <div>
            <Button
                onClick={handleOnClick}
                size={buttonSize ? buttonSize : "sm"}
                variant={buttonVariant ? buttonVariant : "outline-primary"}
                loading={loading}
            >
                {placeholder}
            </Button>
        </div>
    )
}
