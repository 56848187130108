import React from "react";
import Modal from "../../components/Modal/Modal";
import StagedForm from "../../components/Form/StagedForm"
import { useEditGroupUsersModal } from "./useEditGroupUsersModal";

interface IProps
{
    open: boolean
    setOpen: (val?: boolean) => void
    groupName: String
}

export function EditGroupUsersModal({ open, setOpen, groupName }: IProps)
{
    const {
        loading,
        state,
        updateForm,
        getLDAPUsers,
        getConfirmationStage,
        getFeedbackStage
    } = useEditGroupUsersModal(groupName)

    return (
        <Modal open={open} setOpen={setOpen} maxWidth={700}>
            <h4>{"Edit Group Users"}</h4>
            <StagedForm
                modal
                state={state}
                loading={loading}
                onComplete={() =>
                {
                    setOpen(false)
                }}
                stages={[
                    {
                        title: "Users",
                        fields: [
                            {
                                type: "select",
                                label: "Users",
                                multi: true,
                                onChange: updateForm,
                                name: "users",
                                loadOptions: getLDAPUsers,
                                value: state.users
                            }
                        ]
                    },
                    ...getConfirmationStage(),
                    ...getFeedbackStage()
                ]}
            />
        </Modal>
    )
}