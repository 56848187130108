
import { createMuiTheme, makeStyles, MobileStepper as MobileStepperComponent, ThemeProvider } from "@material-ui/core";
import React, { useContext } from "react";
import { ThemeContext } from "../../Contexts/ThemeContext";

interface IMobileStepper
{
    NextButton: React.FunctionComponent<any>
    BackButton: React.FunctionComponent<any>
    variant: "dots" | "progress"
    numSteps: number
    activeStep: number
}



export default function MobileStepper({ NextButton, BackButton, variant, numSteps, activeStep }: IMobileStepper)
{
    const { primaryColour } = useContext(ThemeContext)

    const useStyles = makeStyles({
        root: {
            flex: 1,
            background: "none",
            padding: 8

        },
    });

    const classes = useStyles();

    const materialTheme = createMuiTheme({
        overrides: {
            MuiLinearProgress: {
                root: {

                },
                barColorPrimary: {
                    backgroundColor: `${primaryColour} !important`
                },

            },
            MuiMobileStepper: {
                dotActive: {
                    backgroundColor: `${primaryColour} !important`
                }
            }
        }
    });

    return (
        <ThemeProvider theme={materialTheme}>
            <MobileStepperComponent
                variant={variant}
                steps={numSteps}
                position="static"
                activeStep={activeStep}
                className={classes.root}
                nextButton={
                    <NextButton />
                }
                backButton={
                    <BackButton />
                }
            />
        </ThemeProvider>
    )
}