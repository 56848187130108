import React, { useEffect, useState, useContext } from "react"
import { Col, Row } from "react-grid-system"
import "./formInputs.scss"
import Spinner from "../Spinner/Spinner"
import { ThemeContext } from "../../Contexts/ThemeContext"

export function DialPad({
    onChange,
    value,
    name,
    loadUnavailableNumbers,
}: IDialPad)
{
    const [loading, setLoading] = useState(true)

    const [unavailableNumbers, setUnavailableNumbers] = useState([])

    useEffect(() =>
    {
        function runLoadUnavailableNumbers()
        {
            const numbers = loadUnavailableNumbers()
            setUnavailableNumbers(numbers)
            setLoading(false)
            onChange({ target: { value: null, name, type: "dialPad" } })
        }

        setLoading(true)
        runLoadUnavailableNumbers()
    }, [loadUnavailableNumbers])

    function handleSelect(value)
    {
        onChange({ target: { value, name, type: "dialPad" } })
    }

    return (
        <React.Fragment>
            {!loading ? (
                <div className={"dialPad"}>
                    <Row nogutter>
                        {[1, 2, 3].map((number) => (
                            <ButtonCol
                                key={number}
                                selected={value === number}
                                onSelect={handleSelect}
                                number={number}
                                unavailableNumbers={unavailableNumbers}
                            />
                        ))}
                        {[4, 5, 6].map((number) => (
                            <ButtonCol
                                key={number}
                                selected={value === number}
                                onSelect={handleSelect}
                                number={number}
                                unavailableNumbers={unavailableNumbers}
                            />
                        ))}
                        {[7, 8, 9].map((number) => (
                            <ButtonCol
                                key={number}
                                selected={value === number}
                                onSelect={handleSelect}
                                number={number}
                                unavailableNumbers={unavailableNumbers}
                            />
                        ))}
                        {["*", 0, "#"].map((number) => (
                            <ButtonCol
                                key={number}
                                selected={value === number}
                                onSelect={handleSelect}
                                number={number}
                                unavailableNumbers={unavailableNumbers}
                            />
                        ))}
                        {["", "Timeout", ""].map((number) => (
                            <ButtonCol
                                small
                                key={number}
                                selected={value === number}
                                onSelect={handleSelect}
                                number={number}
                                unavailableNumbers={unavailableNumbers}
                            />
                        ))}
                    </Row>
                </div>
            ) : (
                    <Spinner />
                )}
        </React.Fragment>
    )
}

const buttonTexts = [
    {
        key: 2,
        value: "ABC",
    },
    {
        key: 3,
        value: "DEF",
    },
    {
        key: 4,
        value: "GHI",
    },
    {
        key: 5,
        value: "JKL",
    },
    {
        key: 6,
        value: "MNO",
    },
    {
        key: 7,
        value: "PQRS",
    },
    {
        key: 8,
        value: "TUV",
    },
    {
        key: 9,
        value: "WXYZ",
    },
]

interface IProps
{
    selected?: boolean
    number: number | string
    disabled?: boolean
    onSelect?: (val: any) => void
    unavailableNumbers?: number[] | string[]
    small?: boolean
}

function ButtonCol({
    selected,
    number,
    onSelect,
    unavailableNumbers,
    small,
}: IProps)
{
    const [buttonText, setButtonText] = useState<any>()
    const [unavailable, setUnavailable] = useState(false)
    const { primaryColour, primaryColourDarker, accentColour } = useContext(ThemeContext)

    useEffect(() =>
    {
        const arr: any[] = buttonTexts.filter(
            (buttonText) => buttonText.key === number
        )
        if (arr)
        {
            setButtonText(arr[0])
        }

        // @ts-ignore
        if (unavailableNumbers && unavailableNumbers.includes(String(number)))
        {
            setUnavailable(true)
        } else
        {
            setUnavailable(false)
        }
    }, [number, unavailableNumbers])

    return (
        <Col lg={4}>
            <div
                className={`dialPadKey ${selected ? "selected" : ""} ${
                    unavailable ? "disabled" : ""
                    }`}
                style={{
                    paddingBottom: !buttonText ? (small ? 12 : 20) : 6,
                    borderRight: [3, 6, 9, "#", ""].includes(number) && "none",
                    borderLeft: number === "Timeout" && "1px solid #f0f0f0",
                    borderBottom:
                        ["Timeout", "", "Invalid"].includes(String(number)) &&
                        "none",
                    cursor: number === "" && "default",
                    color: selected && accentColour
                }}
                onClick={() =>
                {
                    if (!unavailable && number !== "")
                    {
                        onSelect(number)
                    }
                }}
            >
                <span
                    style={{
                        paddingTop: small && 8,
                        fontSize: !small ? "1.4em" : "0.9em",
                    }}
                >
                    {number}
                </span>
                <span
                    style={{
                        letterSpacing: 1.5,
                        fontSize: "0.6em",
                    }}
                >
                    {buttonText ? buttonText.value : ""}
                </span>
            </div>
        </Col>
    )
}
