import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import Spinner from "../Spinner/Spinner";

export default function CSVDownloadField({
    text,
    value,
    loadValue,
}: ICSVDownloadField)
{

    const [loadedValue, setLoadedValue] = useState()

    useEffect(() =>
    {
        async function getValue()
        {
            const val = await loadValue()
            setLoadedValue(val)
        }

        if (loadValue)
        {
            getValue()
        }
    }, [loadValue])

    return (
        value || loadedValue ? (
            <div className="flex-row flex-1">
                <CSVLink filename={text + ".csv"} data={loadedValue ? loadedValue : value}>{text}</CSVLink>
            </div>
        ) : (
                <Spinner />
            )
    )
}