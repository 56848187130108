import React, {useEffect, useState} from "react"
import PageLayout from "../../components/PageLayout/PageLayout";
import {useRequest} from "../../Hooks/useRequest";
import Card from "../../components/Card/Card";
import {useHistory, useLocation} from "react-router-dom";
import NotificationBox from "./NotificationBox";

export default function NotificationShowPage() {
    const {getRequest} = useRequest()
    const [notifications, setNotifications] = useState([])
    const [open, setOpen] = useState<string | null>(null)
    const history = useHistory()
    const query = useQuery();
    const location = useLocation();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    useEffect(() => {
        setOpen(query.get("open"))
    }, [location])

    useEffect(() => {
        async function fetchNotifications() {
            const response = await getRequest(`/notifications`)
            if (response) {
                setNotifications(response)
            }
        }

        fetchNotifications()
    }, [location])

    return (
        <PageLayout title={"Notifications"}>

            <Card
                noPadding
            >
                {notifications && notifications.length > 0 ? notifications.map(notification => (
                    <NotificationBox
                        notification={notification}
                        open={Number(open) === notification.notification_id}
                        setOpen={val => {
                            if (val) {
                                history.push(`/notifications?open=${notification.notification_id}&modal=true`)
                            } else {
                                history.push(`/notifications?modal=true`)
                            }
                        }}
                    />
                )) : (
                    <div
                        style={{
                            padding: 16
                        }}>
                        You have no notifications
                    </div>
                )}
            </Card>
        </PageLayout>
    )
}

