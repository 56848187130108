import React, { useState } from "react"
import { TabbedCardFormPage } from "../../components/Form/TabbedCardFormPage"
import Modal from "../../components/Modal/Modal"
import { RecordModal } from "../../components/RecordModal/RecordModal"
import Table from "../../components/Table/Table"
import { permissions } from "../../Constants/permissions"
import { useInternationalRestrictionsFormPage } from "./useInternationalRestrictionsFormPage"

export default function InternationalRestrictionsPage({ prop }: any)
{
    const [linkedNumberSelectedRecords, setLinkedNumberSelectedRecords] = useState([])
    const [linkedNumberModalOpen, setlinkedNumberModalOpen] = useState(false)

    const {
        tabs,
        state,
        updateForm,
        loading,
        addressModalOpen,
        setAddressModalOpen,
        linkedNumberModal,
        addLinkedNumberModal,
    } = useInternationalRestrictionsFormPage()

    return (
        <React.Fragment>
            <TabbedCardFormPage
                state={state}
                tabs={tabs}
                loading={loading}
                updateForm={updateForm}
                displayName={"Account"}
                permissions={[permissions.account, permissions.admin, permissions.VHAdmin]}
            />
            <Modal open={addressModalOpen} setOpen={setAddressModalOpen} maxWidth={700}>
                <h4>Linked Telephone Numbers</h4>
                <Table
                    noPagination={true}
                    columns={linkedNumberModal.columns}
                    records={linkedNumberModal.records}
                    loading={linkedNumberModal.loading}
                    selectedRecords={linkedNumberSelectedRecords}
                    setSelectedRecords={setLinkedNumberSelectedRecords}
                    onAdd={
                        {
                            action: () =>
                                setlinkedNumberModalOpen(true)
                        }
                    }
                />
                <RecordModal
                    open={linkedNumberModalOpen}
                    setOpen={() =>
                        setlinkedNumberModalOpen(false)
                    }
                    displayName={"Address Link"}
                    settingsStages={addLinkedNumberModal.settingsStages}
                    defineSubmitData={addLinkedNumberModal.submitData}
                    action={"create"}
                />
            </Modal>
        </React.Fragment>
    )
}