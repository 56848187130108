import React, { useState, useContext, useEffect } from "react";
import { TabbedCardFormPage } from "../../components/Form/TabbedCardFormPage";
import Modal from "../../components/Modal/Modal";
import { useIntegrationsFormPage } from "./useIntegrationsFormPage";
import { ToastContext } from "../../Contexts/ToastContext";
import Button from "../../components/Button";
import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage";
import { permissions } from "../../Constants/permissions";

export default function IntegrationsFormPage()
{
    const [showModal, setShowModal] = useState(false);
    const [searchInactive, setSearchInactive] = useState('');
    const [searchActive, setSearchActive] = useState('');
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [modalContent, setModalContent] = useState<
        | "addUser"
        | "viewUsers"
        | "confirmActivation"
        | "activateCustomerLicense"
        | "deactivateCustomerLicense"
        | "activateUserLicense"
        | "deactivateUserLicense"
        | "bulkActivateUsers"
        | "bulkDeactivateUsers"
        | null
    >(null);
    const { add } = useContext(ToastContext);

    const {
        tabs,
        loading,
        state,
        user,
        users,
        activeUsers,
        selectedUsers,
        customer,
        activationType,
        confirmationData,
        deactivationType,
        updateForm,
        updateErrors,
        redCactusActivation,
        redCactusDeactivation,
        toggleUserSelection,
        bulkActivateUsers,
        bulkDeactivateUsers,
        setSelectedUsers,
    } = useIntegrationsFormPage(setShowModal, setModalContent, modalContent);

    const [filteredUsers, setFilteredUsers] = useState([]);

    useEffect(() =>
    {
        const filtered = users.filter((user) =>
            user.email.toLowerCase().includes(searchInactive.toLowerCase())
        );
        setFilteredUsers(filtered);
    }, [searchInactive, users]);

    useEffect(() =>
    {
        const filtered = activeUsers.filter((user) =>
            user.email.toLowerCase().includes(searchInactive.toLowerCase())
        );
        setFilteredUsers(filtered);
    }, [searchActive, activeUsers]);

    const handleSelectAll = () =>
    {
        if (selectAllChecked)
        {
            setSelectedUsers([]);
        } else
        {
            setSelectedUsers(filteredUsers);
        }
        setSelectAllChecked(!selectAllChecked);
    };

    const handleConfirmActivation = () =>
    {
        if (activationType === "user")
        {
            redCactusActivation(user, customer, "user");
            setShowModal(false);
        }
        if (activationType === "customer")
        {
            redCactusActivation(user, customer, "customer");
            setShowModal(false);
        }
    };

    const handleConfirmDeactivation = () =>
    {
        if (deactivationType === "user")
        {
            redCactusDeactivation(user, customer, "user");
            setShowModal(false);
        }
        if (deactivationType === "customer")
        {
            redCactusDeactivation(user, customer, "customer");
            setShowModal(false);
        }
    };

    const handleCloseModal = () => 
    {
        setSelectedUsers([]);
        setShowModal(false);
        setSelectAllChecked(false);
        setSearchActive('');
        setSearchInactive('');
    }

    return (
        <React.Fragment>
            <TabbedCardFormPage
                tabs={tabs}
                loading={loading}
                state={state}
                updateForm={updateForm}
                updateErrors={updateErrors}
            />

            {modalContent === "bulkActivateUsers" && showModal && (
                <Modal
                    open={showModal}
                    setOpen={setShowModal}
                    maxWidth={800}
                    onClose={handleCloseModal}
                >
                    <h2>Select Users for Activation</h2>
                    <p style={{ fontWeight: "600", color: "red" }}>
                        Caution: This is a chargeable service, please only proceed if you're aware of this.
                    </p>
                    {loading ? (
                        <p>Loading users...</p>
                    ) : (
                        <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", marginRight: "4px" }}>
                                <input
                                    style={{ marginBottom: "10px", marginTop: "10px" }}
                                    type="text"
                                    className="search-input"
                                    placeholder="Search users..."
                                    value={searchInactive}
                                    onChange={(e) => setSearchInactive(e.target.value)}
                                />
                                <Button onClick={handleSelectAll} style={{ marginBottom: "10px" }}>
                                    {selectAllChecked ? "Deselect All" : "Select All"}
                                </Button>
                            </div>
                            {filteredUsers.map((user) => (
                                <div key={user.id} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                    <input
                                        type="checkbox"
                                        checked={selectedUsers.some((selectedUser) => selectedUser.id === user.id)}
                                        onChange={() => toggleUserSelection(user)}
                                    />
                                    <span style={{ marginLeft: "10px" }}>{user.email}</span>
                                </div>
                            ))}
                        </div>
                    )}
                    <Button onClick={bulkActivateUsers} disabled={selectedUsers.length === 0}>
                        Activate Selected Users
                    </Button>
                </Modal>
            )}

            {modalContent === "bulkDeactivateUsers" && showModal && (
                <Modal
                    open={showModal}
                    setOpen={setShowModal}
                    maxWidth={800}
                    onClose={handleCloseModal}
                >
                    <h2>Select Users for Deactivation</h2>
                    {loading ? (
                        <p>Loading users...</p>
                    ) : (
                        <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", marginRight: "4px" }}>
                                <input
                                    style={{ marginBottom: "10px", marginTop: "10px" }}
                                    type="text"
                                    className="search-input"
                                    placeholder="Search users..."
                                    value={searchActive}
                                    onChange={(e) => setSearchActive(e.target.value)}
                                />
                                <Button onClick={handleSelectAll} style={{ marginBottom: "10px" }}>
                                    {selectAllChecked ? "Deselect All" : "Select All"}
                                </Button>
                            </div>
                            {activeUsers.map((user) => (
                                <div key={user.userId} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                    <input
                                        type="checkbox"
                                        checked={selectedUsers.some((selectedUser) => selectedUser.userId === user.userId)}
                                        onChange={() => toggleUserSelection(user)}
                                    />
                                    <span style={{ marginLeft: "10px" }}>{user.email}</span>
                                </div>
                            ))}
                        </div>
                    )}
                    <Button onClick={bulkDeactivateUsers} disabled={selectedUsers.length === 0}>
                        Deactivate Selected Users
                    </Button>
                </Modal>
            )}

            {modalContent === "confirmActivation" && showModal && (
                <Modal
                    open={showModal}
                    setOpen={setShowModal}
                    maxWidth={600}
                    onClose={() => setShowModal(false)}
                >
                    <div>
                        {customer && activationType === "customer" && (
                            <>
                                <h2>Activate Company License</h2>
                                <p style={{ color: "red", fontWeight: "600" }}>This is a chargeable service, please only proceed if you're aware of this. </p>
                                <p>Upon activation you will be granted the ability to provide Bubble access to your users. A company license alone will not allow you to login, you'll still need to activate a user before accessing the application.</p>
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <Button onClick={handleConfirmActivation}>Confirm Activation</Button>
                                    <Button onClick={() => setShowModal(false)}>Cancel</Button>
                                </div>
                            </>
                        )}
                    </div>
                </Modal>
            )}

            {modalContent === "deactivateCustomerLicense" && showModal && (
                <Modal
                    open={showModal}
                    setOpen={setShowModal}
                    maxWidth={600}
                    onClose={() => setShowModal(false)}
                >
                    <div>
                        {deactivationType === "customer" && (
                            <>
                                <h2>Deactivate Company License</h2>
                                <p>You are about to cancel your company license, upon deactivation you and all users under this account will lose access to the Bubble application.</p>
                                <p>Please proceed if you are aware of this.</p>
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <Button onClick={handleConfirmDeactivation}>Confirm Deactivation</Button>
                                    <Button onClick={() => setShowModal(false)}>Cancel</Button>
                                </div>
                            </>
                        )}
                    </div>
                </Modal>
            )}

        </React.Fragment>
    );
}
