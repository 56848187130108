export const regex = {
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    publicIpAddress: /^(?!(10\.|192\.168\.|172\.(1[6-9]|2[0-9]|3[0-1])\.))((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
    privateIpAddress: /(^127\.)|(^10\.)|(^172\.1[6-9]\.)|(^172\.2[0-9]3[0-9]2[0-9]|\.)|(^172\.3[0-1]\.)|(^192\.168\.)/,
    telephoneNumber: /^[+0-9]{5,14}$/,
    postcode: /^(GIR 0AA)|(((A[BL]|B[ABDHLNRSTX]?|C[ABFHMORTVW]|D[ADEGHLNTY]|E[HNX]?|F[KY]|G[LUY]?|H[ADGPRSUX]|I[GMPV]|JE|K[ATWY]|L[ADELNSU]?|M[EKL]?|N[EGNPRW]?|O[LX]|P[AEHLOR]|R[GHM]|S[AEGKLMNOPRSTY]?|T[ADFNQRSW]|UB|W[ADFNRSV]|YO|ZE)[1-9]?[0-9]|((E|N|NW|SE|SW|W)1|EC[1-4]|WC[12])[A-HJKMNPR-Y]|(SW|W)([2-9]|[1-9][0-9])|EC[1-9][0-9]) [0-9][ABD-HJLNP-UW-Z]{2})$/,
    accountSeat: /^[1-9][0-9]{4}\*[2-9][0-9]{2,4}$/,
    pin: /^[0-9]{4,6}$/,
    internationalPin: /^[0-9]{4,8}$/,
    cli: /^0{0,1}(0|44)[1-9][0-9]{0,13}$/,
    vmDateFormat: /^(0[1-9]|[1-2][0-9]|3[0-1])\s(0[1-9]|1[0-2])\s[0-9]{4}\s(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/,
    cardAddressLine1: /^.{5,45}$/,
    pluralCheck: /^(.*[^aeiou]y)$/,
    extension: /^(?!999\\d*)[2-9][0-9]{2,4}$/,
    username: /^[0-9A-Za-z-_.@! ]{4,100}$/,
    usersObjectName: /^[0-9A-Za-z-_. ]{2,100}$/,
    bankAccountNumber: /^(?!(?:0{8}|01234567|12345678))(\d){8}$/,
    sortCode: /^[+0-9]{6}$/,
    password: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?/~`'"\\|]).{8,50}$/
}
