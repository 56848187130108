import React, { useState } from "react"
import { FormControl } from "react-bootstrap"
import Icon from "../Icons/Icon"

export default function NumberInput({
    placeholder,
    value,
    min,
    max,
    disabled,
    loading,
    onChange,
    name,
    maxWidth,
    step,
    error,
    setError,
}: INumberInput)
{

    function handleOnChange(e)
    {
        const { value } = e.target

        let error = ""

        if (setError)
        {
            if (value > max)
            {
                error = `Value must not exceed ${max}`
            } else if ((min || min === 0 || value === "") && value < min)
            {
                error = `Value must be at least ${min}`
            }
            if (!error)
            {
                setError && setError({
                    target: { ...e.target, name, value: error },
                })
                setError &&
                    setError({
                        target: { ...e.target, name, value: error },
                    })
            } else
            {
                setError && setError({
                    target: { ...e.target, name, value: error },
                })
            }

            onChange(e)
        } else
        {
            if ((value >= min && value <= max) || value === "")
            {
                onChange(e)
            }
        }
    }

    return (
        <div
            className="flex-row"
            style={{
                minWidth: 67,
                width: "65%",
                position: "relative",
            }}>
            <FormControl
                style={{ maxWidth: maxWidth }}
                isInvalid={!!error}
                min={min}
                max={max}
                disabled={disabled || loading}
                type={"number"}
                step={step}
                placeholder={placeholder}
                name={name}
                onChange={handleOnChange}
                value={value}
            />
            {loading && (
                <div
                    style={{
                        position: "absolute",
                        top: 5,
                        right: 8,
                    }}>
                    <Icon
                        icon="circle-notch"
                        spin
                        transform="shrink-2 down-2"
                        color="#cccccc"
                    />
                </div>
            )}
        </div>
    )
}
