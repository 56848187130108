import React from "react";

interface IProps
{
    style?: { [key: string]: string | number }
    className?: string
    colors: string[]
    angle?: number
    children: React.ReactNode
}

export default function GradientBackground({
    style,
    className,
    colors,
    angle,
    children
}: IProps)
{
    if (!angle)
    {
        angle = 0.25;
    }
    return (
        <div
            className={className ? className : ""}
            style={{
                ...style,
                background: `linear-gradient(${angle}turn, ${colors.map(
                    (color, i) => `${color} ${i > colors.length ? "," : ""}`
                )})`,
                display: "flex",
                flex: 1
            }}
        >
            {children}
        </div>
    );
}
