import {NavLink} from "react-router-dom";
import React from "react";
import "./errorPageText.scss"


interface IProps {
    error: string,
    text: string,
    link?: {
        url: string,
        text: string
    }
}

export default function ErrorPageText({error, text, link}: IProps) {
    return (
        <div
            className={"errorTextContainer"}
            style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                paddingTop: 80
            }}
        >
            <span className="errorText">{error}</span>
            <span className="secondaryText">{text}</span>
            {link && (
                <span className="errorLink">
                    <NavLink to={link.url}>{link.text}</NavLink>
                </span>
            )}
        </div>
    )
}