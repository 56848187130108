import Modal from "../../components/Modal/Modal";
import React, { useState, useContext } from "react";
import StagedForm from "../../components/Form/StagedForm";
import { useRequest } from "../../Hooks/useRequest";
import { useFormReducer } from "../../Hooks/useFormReducer";
import { SessionContext } from "../../Contexts/SessionContext";
import { loginDotUrl } from "../../Common/urlHelpers";

interface IProps
{
    open?: boolean
    setOpen?: (val?: boolean) => void
}

export function AccountDeleteModal({ open, setOpen }: IProps)
{
    const { getRequest } = useRequest()
    const { deleteRequest } = useRequest()
    const { postRequest } = useRequest()
    const [loading, setLoading] = useState(false)
    const { state: session } = useContext(SessionContext)
    const { state, updateForm } = useFormReducer({
        record: {
            password: "",
            errors: [],
        },
    })

    function processAccountDeletion(password): Promise<{ successes?: postFeedback[]; failures?: postFeedback[] }>
    {
        return new Promise(async (resolve) =>
        {
            setLoading(true)
            const successes = []
            const failures = []

            let passwordResponse = await postRequest(
                { password: state.password },
                "/users/auth/passwordcheck"
            )

            if (passwordResponse)
            {
                if (passwordResponse.success)
                {
                    let deleteResponse = await deleteRequest(
                        null,
                        "/account/" + session.session.account_no
                    )

                    if (deleteResponse)
                    {
                        if (deleteResponse.success)
                        {
                            const logoutResponse = await getRequest(
                                `/users/auth/logout`,
                            )

                            window.location.href = loginDotUrl(true)
                        }
                        else
                        {
                            if (deleteResponse.message)
                            {
                                if (Array.isArray(deleteResponse.message))
                                {
                                    let i = 0
                                    for (let mess of deleteResponse.message)
                                    {
                                        failures.push({
                                            id: i++,
                                            message: mess
                                        })
                                    }
                                }
                                else
                                {
                                    failures.push({
                                        id: 0,
                                        message: deleteResponse.message
                                    })
                                }
                            }
                            else
                            {
                                failures.push({
                                    id: 0,
                                    message: "Something went wrong please contact support.",
                                })
                            }
                        }
                    }
                    else
                    {
                        failures.push({
                            id: 0,
                            message: "Something went wrong please try again",
                        })
                    }
                }
                else
                {
                    failures.push({
                        id: 0,
                        message: "Password seems to be incorrect, please try again.",
                    })
                }
            }
            else
            {
                failures.push({
                    id: 0,
                    message: "Something went wrong please try again",
                })
            }



            setLoading(false)
            resolve({
                successes: successes,
                failures: failures,
            })
        })
    }

    return (
        <Modal open={open} setOpen={setOpen} maxWidth={700}>
            <h4>{"Delete Account?"}</h4>
            <StagedForm
                loading={loading}
                onComplete={() => setOpen(false)}
                stages={[
                    {
                        title: "Confirmation",
                        fields: [
                            {
                                type: "paragraph",
                                label: "WARNING",
                                value: "Deleting your account is UNRECOVERABLE, once this process has begun we are not able to revert the changes made. This includes, but is not limited to all telephone numbers" +
                                    " being removed and all your devices will cease to work on our network. Please contact our support team or your accounts manager if you are unsure."
                            }
                        ]
                    },
                    {
                        title: "Password Confirmation",
                        fields: [
                            {
                                type: "paragraph",
                                label: "WARNING",
                                value: "Please enter your password below to confirm you want to delete your account. Once this process has begun we are not able to revert the changes made."
                            },
                            {
                                type: "password",
                                label: "Password",
                                name: `password`,
                                onChange: updateForm,
                                value: state.password
                            }
                        ]
                    },
                    {
                        title: "Final Confirmation",
                        buttonText: "DELETE",
                        onComplete: {
                            function: () =>
                            {
                                return new Promise(async (resolve) =>
                                {
                                    const feedback = await processAccountDeletion(state.password)

                                    updateForm({
                                        target: {
                                            name: "successes",
                                            value: feedback.successes,
                                        },
                                    })

                                    updateForm({
                                        target: {
                                            name: "failures",
                                            value: feedback.failures,
                                        },
                                    })

                                    resolve({ success: true })
                                })
                            },

                        },
                        fields: [
                            {
                                type: "paragraph",
                                value: "Clicking 'DELETE' will start the process of removing you account and all associated assets from our system."
                            }
                        ]
                    },
                    {
                        title: "Feedback",
                        fields: [
                            state.failures &&
                            state.failures.length > 0 && {
                                type: "feedbackAccordion",
                                title: "Failures",
                                items:
                                    state.failures &&
                                    state.failures.map(
                                        (failure) =>
                                            `${failure.id} - ${failure.message}`
                                    ),
                                variant: "red",
                            },
                            (!state.failures ||
                                state.failures.length === 0) &&
                            !state.deleteFailure && {
                                type: "paragraph",
                                value: `Your account has been deleted successfully`,
                            },
                        ],
                    }

                ]}
            />
        </Modal>
    )
}