import React from "react";
import "./card.scss";

interface IProps {
    children: React.ReactNode
    style?: { [key: string]: string | number }
    padding?: number | string
    noPadding?: boolean
    flexDirection?: any
    className?: string
}

export default function Card({
                                 children,
                                 style,
                                 padding,
                                 noPadding,
                                 flexDirection,
                                 className
                             }: IProps) {
    return (
        <div
            className={`card-component ${noPadding ? "cardNoPadding" : ""} ${className}`}
            style={{...style, padding, flexDirection}}
        >
            {children}
        </div>
    );
}
