import React, { useContext } from "react"
import { permissions } from "../../Constants/permissions"
import { SessionContext } from "../../Contexts/SessionContext"
import { useRequest } from "../../Hooks/useRequest"
import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage"

export default function PhoneDirectoryGroupsIndexPage()
{
    const { state: sessionState } = useContext(SessionContext)
    const { postRequest, getRequest } = useRequest()

    const columns: IColumn[] = [
        {
            name: "text",
            title: "Directory",
            link: { root: "/phone-directory/groups" },
            bold: true,
        },
    ]

    function checkExists(fieldFrom): Promise<{ success: boolean, message: string }>
    {
        return new Promise(async resolve =>
        {

            if (!fieldFrom)
            {
                resolve({ success: false, message: "Please enter a group name" })
            }

            if (fieldFrom === "groups")
            {
                resolve({ success: false, message: "group name connot be groups" })
            }

            let response = await postRequest({ directory: "groups" }, `/ldap/overview/-1/-1`)

            if (response)
            {
                if (response.records)
                {
                    const filteredRecords = response.records.filter(record => record.id.toLowerCase() === fieldFrom.toLowerCase())

                    if (filteredRecords && filteredRecords.length > 0)
                    {
                        resolve({ success: false, message: "This directory already exists" })
                    } else
                    {
                        resolve({ success: true, message: "" })
                    }
                } else
                {
                    resolve({ success: false, message: "Failed to retrieve data, please try again" })
                }
            } else
            {
                resolve({ success: false, message: "Failed to retrieve data, please try again" })
            }
        })
    }

    async function getLDAPUsers(): Promise<ISelectOption[]>
    {
        return new Promise(async resolve =>
        {
            let users = []

            let response = await getRequest(`/ldap/${sessionState.session.account_no}/users`)
            if (response?.records)
            {
                users = response.records.map(record => ({
                    label: "Seat " + record.user,
                    value: record.user
                }))
            }
            resolve(users);
        })
    }

    const modalSettings: ISettingsStage[] = [
        {
            title: "Entry",
            fields: [
                {
                    type: "select",
                    name: "users",
                    label: "Users",
                    multi: true,
                    loadOptions: () => getLDAPUsers()
                }
            ]
        }
    ]

    return (
        <GenericIndexPage
            title={"Hosted Phone Directory"}
            urlString={"/ldap/overview"}
            restType={"POST"}
            searchable={true}
            configIdentifier={"Groups"}
            subIndex={true}
            body={{ directory: "groups" }
            }
            columns={columns}
            permissions={[permissions.ldap, permissions.admin, permissions.VHAdmin]}
            addModal={{
                displayName: "Group Directory",
                allowBulkAdd: false,
                settingsStages: modalSettings,
                fieldFrom: {
                    type: "text",
                    name: "name",
                    label: "Group Name",
                    required: true
                },
                availabilityCheck: (fieldFrom) =>
                {
                    return new Promise(async resolve =>
                    {
                        const responseMessage = await checkExists(fieldFrom)

                        if (responseMessage && responseMessage.success)
                        {
                            resolve({ success: true })
                        } else
                        {
                            resolve({ success: false, error: responseMessage.message })
                        }
                    })
                },
                submitData: (data) =>
                {
                    return [{
                        postData: {
                            users: data.users
                        },
                        path: `/ldap/groups/directory/${sessionState.session.account_no}/${data.fieldFrom.toLowerCase()}`,
                        type: "create",
                    }]
                }
            }}
            deleteModal={{
                displayName: "LDAP Directory",
                uniqueIdentifier: "text",
                submitData: (data) =>
                {
                    return [{
                        path: `/ldap/groups/${sessionState.session.account_no}`,
                        identifier: `${data.directory}`,
                        displayName: "LDAP Directory",
                        type: "delete",
                    }]
                }
            }}
        />
    )
}