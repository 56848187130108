import useAPIRequests from "../../Hooks/useAPIRequests"
import { useRequest } from "../../Hooks/useRequest"
import { useEffect, useState } from "react"
import { useFormReducer } from "../../Hooks/useFormReducer"
import { regex } from "../../Constants/regex"
import { regexError } from "../../Constants/regexError"

export function useMobileFormPage(id: number)
{
    const { getRequest, postRequest } = useRequest()
    const [loading, setLoading] = useState(true)
    const [record, setRecord] = useState({})

    const {
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
    } = useFormReducer({ record })

    useEffect(() =>
    {
        async function pullData()
        {
            setLoading(true)
            let response = await getRequest(`/mobile/${id}`)
            if (response)
            {
                setRecord(response)
            }
            setLoading(false)
        }

        pullData()
        // eslint-disable-next-line
    }, [id])

    const tabs: ITab[] = [
        {
            title: "Message",
            icon: "toolbox",
            disabled: false,
            sections: [
                {
                    title: "Message Details",
                    fullWidth: true,
                    fields: [
                        {
                            type: "text",
                            label: "Description",
                            name: `description`,
                            maxLength: 45
                        },
                        {
                            type: "text",
                            label: "Mobile Number",
                            name: "number",
                            regex: regex.telephoneNumber,
                            regexError: regexError.telephoneNumber,
                        },
                        {
                            type: "info",
                            label: "Active",
                            name: "active",
                            conditional: [
                                {
                                    value: true,
                                    text: "Active",
                                    color: "green",
                                    icon: "phone",
                                    iconColor: "green",
                                },
                                {
                                    value: false,
                                    text: "Inactive",
                                    color: "red",
                                    icon: "phone-slash",
                                    iconColor: "red",
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ]

    function submitData()
    {
        return [
            {
                body: {
                    mobileDescription: state.description,
                    mobileNo: state.number,
                },
                path: `/mobile/${id}`,
            },
        ]
    }

    return {
        loading,
        tabs,
        state,
        updateForm,
        updateErrors,
        submitData,
        // changes,
        // resetChanges,
    }
}
