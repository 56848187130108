import { useEffect, useState } from "react"
import { useRequest } from "../../Hooks/useRequest"
import { useFormReducer } from "../../Hooks/useFormReducer"
import { regex } from "../../Constants/regex"

export function useTariffFormPage()
{
    const [loading, setLoading] = useState(true)
    const { getRequest, postRequestAsString } = useRequest()

    const [outboundTariff, setOutboundTariff] = useState("")
    const [outboundDialplanTariff, setOutboundDialplanTariff] = useState("")
    const [inboundTariff, setInboundTariff] = useState("")
    const [standardTariff, setStandardTariff] = useState("")
    const [broadbandTariff, setBroadbandTariff] = useState("")
    const [wlrTariff, setWLRTariff] = useState("")

    let record = {
        destination: "Please enter a number",
        access: 0,
        onPeak: 0,
        onPeakConnection: 0,
        offPeak: 0,
        weekendOnPeak: 0,
        weekendOffPeak: 0,
        minimum: 0,
    }

    const {
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
    } = useFormReducer({ record })

    useEffect(() =>
    {
        async function setTariffs()
        {
            setOutboundTariff(await getTariff("outbound"))
            setOutboundDialplanTariff(await getTariff("outboundDialplan"))
            setInboundTariff(await getTariff("inbound"))
            setWLRTariff(await getServiceTariff("wlr"))
            setBroadbandTariff(await getServiceTariff("broadband"))
            setStandardTariff(await getServiceTariff("standard"))
        }
        setTariffs()
    }, [])

    useEffect(() =>
    {
        async function pullData()
        {
            if (state.number && regex.cli.test(state.number))
            {
                let response = await getRequest(`/billing/callcost/${state.number}`)

                if (response && response.destination)
                {
                    Object.keys(record).forEach((key) =>
                    {
                        updateForm({
                            target: { name: key, value: response[key] },
                        })
                    })
                } else
                {
                    Object.keys(record).forEach((key) =>
                    {
                        updateForm({ target: { name: key, value: 0 } })
                    })
                    updateForm({
                        target: {
                            name: "destination",
                            value: "Please Enter a Number",
                        },
                    })
                }
            } else
            {
                Object.keys(record).forEach((key) =>
                {
                    updateForm({ target: { name: key, value: 0 } })
                })
                updateForm({
                    target: {
                        name: "destination",
                        value: "Please Enter a Number",
                    },
                })
            }
            setLoading(false)
        }

        pullData()
    }, [state.number])

    type ITariff = {
        id: number
        account_no: number
        title: string
        filename: string
        ext: string
    }

    type IAllTariff = {
        records: ITariff[]
    }

    async function getTariff(
        inboundOrOutbound: "inbound" | "outbound" | "outboundDialplan"
    ): Promise<string>
    {
        return new Promise(async (resolve) =>
        {
            let allTariffs: IAllTariff = await getRequest("/documents/tariff")
            if (allTariffs && allTariffs.records)
            {
                let filterBy = ""
                if (inboundOrOutbound === "outbound")
                {
                    filterBy = "SIP Call Tariff"
                } else if (inboundOrOutbound === "inbound")
                {
                    filterBy = "Inbound Call Tariff"
                } else if (inboundOrOutbound === "outboundDialplan")
                {
                    filterBy = "SIP Call Tariff - Dialplan"
                }

                const filteredTariffs = allTariffs.records.filter(
                    (tariff) => tariff.title === filterBy
                )

                if (filteredTariffs && filteredTariffs.length > 0)
                {
                    let response = await postRequestAsString({},
                        `/documents/tariff/${filteredTariffs[0].filename}/${filteredTariffs[0].id}`
                    )
                    if (response)
                    {
                        resolve(response)
                    }
                }
            }
        })
    }

    async function getServiceTariff(
        service: "standard" | "broadband" | "wlr" | "legacy"
    ): Promise<string>
    {
        return new Promise(async (resolve) =>
        {
            let allTariffs: IAllTariff = await getRequest("/documents/tariff")
            if (allTariffs && allTariffs.records)
            {
                let filterBy = ""
                if (service === "standard")
                {
                    filterBy = "SIP Services Tariff"
                } else if (service === "broadband")
                {
                    filterBy = "Broadband Tariff"
                }
                else if (service === "wlr")
                {
                    filterBy = "WLR Tariff"
                }

                const filteredTariffs = allTariffs.records.filter(
                    (tariff) => tariff.title === filterBy
                )
                if (filteredTariffs && filteredTariffs.length > 0)
                {
                    resolve(
                        "tariff/" +
                        filteredTariffs[0].filename +
                        "/" +
                        filteredTariffs[0].id
                    )
                }
            }
        })
    }

    const tabs: ITab[] = [
        {
            title: "General",
            icon: "toolbox",
            disabled: false,
            sections: [
                {
                    title: "Overview",
                    spanTwoRows: true,
                    fields: [
                        {
                            type: "text",
                            label: "Number",
                            name: "number",
                        },
                        {
                            type: "info",
                            label: "Destination",
                            name: "destination",
                            halfWidth: true,
                        },
                        {
                            type: "info",
                            label: "Access Charge",
                            name: "access",
                            halfWidth: true,
                            tariff: true,
                        },
                        {
                            type: "info",
                            label: "On Peak",
                            name: "onPeak",
                            halfWidth: true,
                            tariff: true,
                            perMin: true,
                        },
                        {
                            type: "info",
                            label: "Connection Fee",
                            name: "onPeakConnection",
                            halfWidth: true,
                            tariff: true,
                        },
                        {
                            type: "info",
                            label: "Off Peak",
                            name: "offPeak",
                            halfWidth: true,
                            tariff: true,
                            perMin: true,
                        },
                        {
                            type: "info",
                            label: "Weekend On Peak",
                            name: "weekendOnPeak",
                            halfWidth: true,
                            tariff: true,
                            perMin: true,
                        },
                        {
                            type: "info",
                            label: "Weekend Off Peak",
                            name: "weekendOffPeak",
                            halfWidth: true,
                            tariff: true,
                            perMin: true,
                        },
                        {
                            type: "info",
                            label: "Minimum Charge",
                            name: "minimum",
                            halfWidth: true,
                            tariff: true,
                        },
                    ],
                },
                {
                    title: "Call Tariff",
                    fields: [
                        {
                            label: "Outbound",
                            type: "csvDownload",
                            text: "Retail Standard",
                            value: outboundTariff
                        },
                        {
                            label: "Outbound",
                            type: "csvDownload",
                            text: "Retail Standard - Dialplan",
                            value: outboundDialplanTariff
                        },
                        {
                            label: "Inbound",
                            type: "csvDownload",
                            text: "Retail Inbound Standard",
                            value: inboundTariff
                        },
                        {
                            type: "info",
                            value: "Peak rate is 8am to 6pm.",
                        }
                    ],
                },
                {
                    title: "Service Tariff",
                    fields: [
                        {
                            label: "Services",
                            type: "link",
                            text: "Retail - Standard Services",
                            value: standardTariff,
                            newTab: true,
                        },
                        {
                            type: "link",
                            text: "Retail - Broadband Tariff",
                            value: broadbandTariff,
                            newTab: true,
                        },
                        {
                            type: "link",
                            text: "Retail - WLR Tariff",
                            value: wlrTariff,
                            newTab: true,
                        }
                    ],
                },
            ],
        },
    ]

    return {
        loading,
        tabs,
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
    }
}
