import React, { useState, useContext } from "react"
import variables from "../../Constants/variables.scss"
import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage"
import { useRequest } from "../../Hooks/useRequest"
import EmergencyServicesAddressModal from "../../components/EmergencyServicesAddressModal"
import useAPIRequests from "../../Hooks/useAPIRequests"
import { SessionContext } from "../../Contexts/SessionContext"
import { hasPermission, permissions } from "../../Constants/permissions"

export default function SeatsIndexPage()
{
    const [addressModalOpen, setAddressModalOpen] = useState(false)
    const [id, setId] = useState(2)
    const { getRequest, postRequest } = useRequest()
    const { getMusicOnHoldPromise, getPromptPromise, getDestinationPromise } = useAPIRequests()
    // const [seatDifference, setSeatDifference] = useState(0)
    const { state: sessionState } = useContext(SessionContext)

    async function getLatestSeat()
    {
        const response = await getRequest(`/seat/${sessionState.session.account_no}/next`)

        if (response)
        {
            return Number(response)
        }
    }

    async function getEMSAddresses(): Promise<ISelectOption[]>
    {
        const emsResponse = await postRequest({}, `/emsaddress`)
        if (emsResponse && emsResponse.records)
        {
            return new Promise((resolve) =>
            {
                const formattedEMSAddresses = emsResponse.records.map(
                    (ems) => ({
                        label: `${ems.premises}, ${ems.thoroughfare ? `${ems.thoroughfare}, ` : ""
                            }${ems.locality}, ${ems.postcode}, (${formatNum(ems.telephoneNo)})`,
                        value: ems.telephoneNo,
                    })
                )
                if (formattedEMSAddresses.length > 0)
                {
                    resolve(formattedEMSAddresses)
                }
                else
                {
                    resolve([{ label: "Please add a telephone number to continue", value: "" }])
                }
            })
        }
    }

    function formatNum(num: String)
    {
        if (num.startsWith("44"))
        {
            num = num.replace("44", "0")
        }
        return num
    }

    async function getAccessLists(): Promise<ISelectOption[]>
    {
        return new Promise(async (resolve) =>
        {
            let response = await postRequest({}, `/accesscontrol/-1/-1`)
            if (response && response.records)
            {
                const failoverDestinations = response.records.map(
                    (destination) => ({
                        label: destination.name,
                        value: String(destination.id),
                    })
                )

                resolve(failoverDestinations)
            }
        })
    }

    function getCallerIds(): Promise<ISelectOption[]>
    {
        return new Promise(async (resolve) =>
        {
            let callerIds = []
            const allocatedNumbersResponse = await postRequest(
                {},
                `/numbering/allocated/-1/-1`
            )
            if (allocatedNumbersResponse && allocatedNumbersResponse.records)
            {
                const formattedAllocatedNumbers = allocatedNumbersResponse.records.map(
                    (allocatedNumber) => ({
                        label: `${allocatedNumber.telephoneNumber}${allocatedNumber.nickname
                            ? ` (${allocatedNumber.nickname})`
                            : ""
                            }`,
                        value: allocatedNumber.callerIDFormat,
                    })
                )

                callerIds = [...callerIds, ...formattedAllocatedNumbers]
            }

            const cidResponse = await postRequest({}, `/numbering/allocated/callerids`)
            if (cidResponse && cidResponse.records)
            {
                const formattedCid = cidResponse.records.map((cid) => ({
                    label: cid.name,
                    value: cid.number,
                }))

                callerIds = [...callerIds, ...formattedCid]
            }
            // Filter out duplicates based on value
            if (callerIds.length > 0)
            {
                resolve(
                    callerIds.filter((ele, ind) =>
                        ind === callerIds.findIndex(elem =>
                            elem.value === ele.value
                        )
                    )
                )
            }
            else
            {
                resolve([{ label: "Please add a telephone number to continue", value: "" }])
            }
        })
    }

    const columns: IColumn[] = [
        {
            name: "extension",
            title: "Extension",
            bold: true,
            sortBy: true,
            sortByName: "name",
            link: { root: "/seats" },
        },
        {
            name: "nickname",
            title: "Nickname",
            sortBy: true,
            sortByName: "vhNickname",
        },
        {
            name: "callerIdNumber",
            title: "Caller ID",
        },
        {
            name: "emergencyServiceCid",
            title: "Location",
            hide: true,
            onClick: (id) =>
            {
                setId(id)
                setAddressModalOpen(true)
            },
            conditional: [
                {
                    value: "ANY-VALUE",
                    icon: "globe-europe",
                    iconColor: variables.colorBlue,
                    color: variables.colorBlue,
                    text: "Set",
                },
                {
                    value: "",
                    icon: "times",
                    iconColor: variables.colorRed,
                    color: variables.colorRed,
                    text: "Not Set",
                },
            ],
        },
        {
            name: "ipaddr",
            title: "Public IP",
        },
        {
            name: "onNet",
            title: "On-Net/Off-Net",
            conditional: [
                {
                    value: true,
                    text: "On-Net",
                },
                {
                    value: false,
                    text: "Off-Net",
                },
            ],
        },
        {
            name: "callRecord",
            title: "Call Recording",
            hide: true,
            conditional: [
                { value: 0, text: "Not Enabled", icon: "microphone-slash" },
                { value: 1, text: "Enabled - Pausable", icon: "pause" },
                { value: 2, text: "Enabled - Not Pausable", icon: "microphone" },
            ],
        },
        {
            name: "online",
            title: "Status",
            hide: true,
            conditional: [
                {
                    value: true,
                    icon: "phone",
                    iconColor: variables.colorGreen,
                },
                {
                    value: false,
                    icon: "phone-slash",
                    iconColor: variables.colorRed,
                },
                {
                    value: true,
                    icon: "mobile",
                    iconColor: variables.colorGreen,
                    name: "mobileOnline"
                },
                {
                    value: false,
                    icon: "mobile",
                    iconColor: variables.colorRed,
                    name: "mobileOnline"
                },
                {
                    value: true,
                    icon: "desktop",
                    iconColor: variables.colorGreen,
                    name: "desktopOnline"
                },
                {
                    value: false,
                    icon: "desktop",
                    iconColor: variables.colorRed,
                    name: "desktopOnline"
                },
            ],
        },
    ]

    const addModalSettings: ISettingsStage[] = [
        {
            title: "General",
            fields: [
                {
                    type: "text",
                    name: "vhNickname",
                    label: "Nickname",
                    maxLength: 11//seatDifference >= 100 ? 11 : seatDifference >= 10 ? 12 : seatDifference > 0 ? 13 : 15
                },
                {
                    type: "select",
                    name: "musicOnHold",
                    loadOptions: getMusicOnHoldPromise,
                    label: "Music On Hold",
                    options: [{ value: "default", label: "Default" }],
                    defaultValue: "default"
                },
                {
                    type: "select",
                    name: "emergencyServiceCid",
                    label: "EMS Location",
                    required: true,
                    heightChange: 70,
                    loadOptions: getEMSAddresses,
                },
                {
                    type: "select",
                    name: "enableCallRecording",
                    label: "Enable Call Recording",
                    options: [
                        { value: "off", label: "None" },
                        { value: "pausable", label: "Enabled - Pausable" },
                        { value: "permanent", label: "Enabled - Non Pausable" },
                    ],
                    defaultValue: "off"
                },
                {
                    type: "text",
                    name: "callerIdName",
                    label: "Caller ID Name",
                    maxLength: 80,
                },
                {
                    type: "select",
                    name: "callerIdNumber",
                    label: "Caller ID Number",
                    loadOptions: getCallerIds,
                    required: true,
                },
            ],
        },
        {
            title: "Voicemail",
            fields: [
                {
                    type: "select",
                    name: "vmMode",
                    label: "Mode",
                    options: [
                        {
                            value: 0,
                            label: "Disabled",
                        },
                        {
                            value: 1,
                            label: "Basic",
                        },
                        {
                            value: 2,
                            label: "Advanced",
                        },
                    ],
                    defaultValue: 0
                },
                {
                    conditionallyHide: { checks: [{ field: "vmMode", value: 0 }] },
                    type: "email",
                    name: "email",
                    label: "Email",
                },
                {
                    conditionallyHide: { checks: [{ field: "vmMode", value: 0 }] },
                    type: "switch",
                    name: "keepOnNetwork",
                    label: "Keep On Network",
                },
                {
                    type: "select",
                    name: "vmAdvancedPromptId",
                    label: "Advanced Prompt",
                    loadOptions: () => getPromptPromise(),
                    conditionallyShow: { checks: [{ field: "vmMode", value: 2 }] },
                    options: [
                        { value: 0, label: "Default" },
                    ],
                    defaultValue: 0
                },
                {
                    type: "select",
                    name: "vmAdvancedDest",
                    label: "Advanced Destination",
                    conditionallyShow: { checks: [{ field: "vmMode", value: 2 }] },
                    loadOptions: () => getDestinationPromise({
                        highPriorityQueue: false,
                        sipTrunkMap: false
                    }),
                    defaultToFirst: true
                },
            ],
        },
        {
            title: "Outbound Time Restrictions",
            fields: [
                {
                    type: "switch",
                    label: "Allow Weekday Calls",
                    name: "callRestrictionsWeekdayAllow",
                    defaultValue: false
                },
                {
                    type: "timeSlider",
                    label: "Between",
                    name: "callRestrictionsWeekdayTimes",
                },
                {
                    type: "switch",
                    label: "Allow Weekend Calls",
                    name: "callRestrictionsWeekendAllow",
                    defaultValue: false
                },
                {
                    type: "timeSlider",
                    label: "Between",
                    name: "callRestrictionsWeekendTimes",
                },
            ],
        },
        {
            title: "Outbound Call Restrictions",
            fields: [
                {
                    type: "switch",
                    label: "Allow UK Geographic (01, 02 & 03)",
                    name: "allowUKGeo",
                },
                {
                    type: "switch",
                    label: "Allow UK Mobile (071 to 079)",
                    name: "allowUKMobile",
                },
                {
                    type: "switch",
                    label: "Allow UK Directory (118)",
                    name: "allowUK118",
                },
                {
                    type: "switch",
                    label: "Allow Other UK Calls (08XX)",
                    name: "allowUKOther",
                },
                {
                    type: "switch",
                    disabled: !hasPermission([permissions.highRisk, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                    label: "Allow International Calls",
                    name: "allowInternational",
                },
                {
                    type: "switch",
                    disabled: !hasPermission([permissions.highRisk, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                    label: "Allow High Risk Zone",
                    name: "allowInternationalHighRisk",
                    helptext:
                        "High Risk Zones are destinations that are considered to have high or extreme risks of telecommunications fraud based on current data",
                },
                {
                    type: "switch",
                    label: "Allow UK Premium Calls (09)",
                    name: "allowUKPremium09",
                },
                {
                    type: "switch",
                    label: "Allow UK Premium Calls",
                    name: "allowUKPremium070",
                },
                {
                    type: "select",
                    name: "acl",
                    label: "Outbound Blacklist",
                    options: [{ value: "0", label: "No Blacklist" }],
                    loadOptions: getAccessLists,
                    defaultValue: "0"
                }
            ],
        }
    ]

    const editModalSettings: ISettingsStage[] = [
        {
            title: "General",
            fields: [
                {
                    type: "text",
                    name: "vhNickname",
                    label: "Nickname",
                    maxLength: 11
                },
                {
                    type: "select",
                    name: "musicOnHold",
                    loadOptions: getMusicOnHoldPromise,
                    label: "Music On Hold",
                },
                {
                    type: "select",
                    name: "emergencyServiceCid",
                    label: "EMS Location",
                    heightChange: 70,
                    loadOptions: getEMSAddresses,
                },
                {
                    type: "select",
                    name: "enableCallRecording",
                    label: "Enable Call Recording",
                    options: [
                        { value: "off", label: "None" },
                        { value: "pausable", label: "Enabled - Pausable" },
                        { value: "permanent", label: "Enabled - Non Pausable" },
                    ],
                },
                {
                    type: "text",
                    name: "callerIdName",
                    label: "Caller ID Name",
                    maxLength: 80,
                },
                {
                    type: "select",
                    name: "callerIdNumber",
                    label: "Caller ID Number",
                    loadOptions: getCallerIds,
                },
            ],
        },
        {
            title: "Outbound Time Restrictions",
            fields: [
                {
                    type: "switch",
                    label: "Allow Weekday Calls",
                    name: "callRestrictionsWeekdayAllow",
                },
                {
                    conditionallyHide: { checks: [{ field: "callRestrictionsWeekdayAllow", value: undefined }] },
                    type: "timeSlider",
                    label: "Between",
                    name: "callRestrictionsWeekdayTimes",
                },
                {
                    type: "switch",
                    label: "Allow Weekend Calls",
                    name: "callRestrictionsWeekendAllow",
                },
                {
                    conditionallyHide: { checks: [{ field: "callRestrictionsWeekendAllow", value: undefined }] },
                    type: "timeSlider",
                    label: "Between",
                    name: "callRestrictionsWeekendTimes",
                },
            ],
        },
        {
            title: "Outbound Call Restrictions",
            fields: [
                {
                    type: "switch",
                    label: "Allow UK Geographic (01, 02 & 03)",
                    name: "allowUKGeo",
                },
                {
                    type: "switch",
                    label: "Allow UK Mobile (071 to 079)",
                    name: "allowUKMobile",
                },
                {
                    type: "switch",
                    label: "Allow UK Directory (118)",
                    name: "allowUK118",
                },
                {
                    type: "switch",
                    label: "Allow Other UK Calls (08XX)",
                    name: "allowUKOther",
                },
                {
                    type: "switch",
                    disabled: !hasPermission([permissions.highRisk, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                    label: "Allow International Calls",
                    name: "allowInternational",
                },
                {
                    type: "switch",
                    disabled: !hasPermission([permissions.highRisk, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                    label: "Allow High Risk Zone",
                    name: "allowInternationalHighRisk",
                    helptext:
                        "High Risk Zones are destinations that are considered to have high or extreme risks of telecommunications fraud based on current data",
                },
                {
                    type: "switch",
                    label: "Allow UK Premium Calls (09)",
                    name: "allowUKPremium09",
                },
                {
                    type: "switch",
                    label: "Allow UK Premium Calls (070)",
                    name: "allowUKPremium070",
                },
                {
                    type: "select",
                    name: "acl",
                    label: "Outbound Blacklist",
                    options: [{ value: "0", label: "No Blacklist" }],
                    loadOptions:
                        getAccessLists
                },
                hasPermission([permissions.VHAdmin], sessionState.session.permissions, "read") && {
                    type: "number",
                    min: 1,
                    max: 10,
                    name: "channelLimit",
                    label: "Seat Channel limit"
                }
            ],
        },
    ]

    function getUniqueIPs(): Promise<ISelectOption[]>
    {
        return new Promise(async (resolve) =>
        {
            let response = await getRequest("/seat/uniqueips")
            if (response && response.records)
            {
                const promiseDetails = response.records.map((details) => ({
                    label: details,
                    value: details,
                }))
                resolve(promiseDetails)
            }
        })
    }

    function checkSeatTaken(
        fieldFrom,
        fieldTo
    ): Promise<{ success: boolean; message: string }>
    {
        return new Promise(async (resolve) =>
        {
            let response = await postRequest({}, `/seat/-1/-1`)

            if (response)
            {
                if (response.records)
                {
                    const filteredRecords = response.records.filter(
                        (record) =>
                            Number(record.extension) === Number(fieldFrom) ||
                            (Number(fieldFrom) < Number(record.extension) &&
                                Number(record.extension) < Number(fieldTo))
                    )

                    if (filteredRecords && filteredRecords.length > 0)
                    {
                        if (!fieldTo)
                        {
                            resolve({
                                success: false,
                                message:
                                    "Seat number is already in use. Please select a different number",
                            })
                        } else
                        {
                            resolve({
                                success: false,
                                message:
                                    "1 or more of the seats already has the number in this selected range. Please check the range and try again",
                            })
                        }
                    } else
                    {
                        resolve({ success: true, message: "" })
                    }
                } else
                {
                    resolve({
                        success: false,
                        message: "Failed to retrieve data, please try again",
                    })
                }
            } else
            {
                resolve({
                    success: false,
                    message: "Failed to retrieve data, please try again",
                })
            }
        })
    }

    function getWeekendAndWeekday(seat)
    {
        let weekday = null
        let weekend = null

        if (seat.callRestrictionsWeekdayAllow)
        {
            let startTime = seat.callRestrictionsWeekdayTimes[0]

            let endTime = seat.callRestrictionsWeekdayTimes[1]

            if (startTime !== "00:00" || endTime !== "23:59")
            {
                if (startTime[0] === "0")
                {
                    startTime = startTime.substring(1)
                }
                if (endTime[0] === "0")
                {
                    endTime = endTime.substring(1)
                }
                weekday = `${startTime}-${endTime}`
            } else
            {
                weekday = `on`
            }
        } else if (seat.callRestrictionsWeekdayAllow !== null)
        {
            weekday = "off"
        }

        if (seat.callRestrictionsWeekendAllow)
        {
            let startTime = seat.callRestrictionsWeekendTimes[0]

            let endTime = seat.callRestrictionsWeekendTimes[1]

            if (startTime !== "00:00" || endTime !== "23:59")
            {
                if (startTime[0] === "0")
                {
                    startTime = startTime.substring(1)
                }
                if (endTime[0] === "0")
                {
                    endTime = endTime.substring(1)
                }

                weekend = `${startTime}-${endTime}`
            } else
            {
                weekend = `on`
            }
        } else if (seat.callRestrictionsWeekendAllow !== null)
        {
            weekend = "off"
        }

        return { weekday, weekend }
    }

    function addSeat(data)
    {
        if (data.bulkAdd)
        {
            data.enableTeams = false
        }

        if (data.callRestrictionsWeekdayAllow == null)
        {
            data.callRestrictionsWeekdayAllow = false
        }

        if (data.callRestrictionsWeekendAllow == null)
        {
            data.callRestrictionsWeekendAllow = false
        }

        const { weekend, weekday } = getWeekendAndWeekday(data)
        const options = {
            weekdayTimes: weekday,
            weekendTimes: weekend,
            allowUKGeo: data.allowUKGeo,
            allowUKMobile: data.allowUKMobile,
            allowUK118: data.allowUK118,
            allowUKOther: data.allowUKOther,
            allowInternational: data.allowInternational,
            allowInternationalHighRisk: data.allowInternationalHighRisk,
            allowUKPremium09: data.allowUKPremium09,
            allowUKPremium070: data.allowUKPremium070,
            acl: data.acl,
            callRecording: data.enableCallRecording,
            teamsCLI: data.enableTeams ? data.teamsCLI : null,
            ipAcl: ['GB']
        }

        return [{
            postData: {
                callerId: (data.callerIdName || data.callerIdNumber) ? data.callerIdName + " <" + data.callerIdNumber + ">" : null,
                vhNickname: data.vhNickname,
                musicOnHold: data.musicOnHold,
                emergencyServiceCid: data.emergencyServiceCid,
                options: options,
                email: data.email,
                vmEnable: data.vmMode === 1,
                vmAdvanced: data.vmMode === 2,
                keepOnNetwork: data.keepOnNetwork,
                vmAdvancedPromptId: data.vmMode === 2 ? data.vmAdvancedPromptId : -1,
                vmAdvancedDest: data.vmMode === 2 ? data.vmAdvancedDest : null,
            },
            path: "/seat",
            identifier: "name",
            type: "create",
            displayName: data.fromField,
            bulkIncrement: "vhNickname"
        }]
    }

    function editSeat(data)
    {
        const { weekend, weekday } = getWeekendAndWeekday(data)
        data.selectedRecords.map((record) =>
        {
            record.videoCodec =
                record.videoCodec === "" || record.videoCodec === null
                    ? "h264"
                    : record.videoCodec
            record.vhNickname = record.nickname
        })

        const options = {
            weekdayTimes: data.callRestrictionsWeekdayAllow !== undefined && data.callRestrictionsWeekdayAllow !== null ? weekday : null,
            weekendTimes: data.callRestrictionsWeekendAllow !== undefined && data.callRestrictionsWeekendAllow !== null ? weekend : null,
            allowUKGeo: data.allowUKGeo,
            allowUKMobile: data.allowUKMobile,
            allowUK118: data.allowUK118,
            allowUKOther: data.allowUKOther,
            allowInternational: data.allowInternational,
            allowInternationalHighRisk: data.allowInternationalHighRisk,
            allowUKPremium09: data.allowUKPremium09,
            allowUKPremium070: data.allowUKPremium070,
            acl: data.acl,
            callRecording: data.enableCallRecording,
            channelLimit: data.channelLimit
        }

        data.selectedRecords.forEach(record =>
        {
            record.name = record.extension
        });

        return [{
            postData: {
                vhNickname: data.vhNickname,
                callerId: (data.callerIdName || data.callerIdNumber) ? data.callerIdName + " <" + data.callerIdNumber + ">" : null,
                musicOnHold: data.musicOnHold,
                emergencyServiceCid: data.emergencyServiceCid,
                options: options,
            },
            path: "/seat",
            type: "edit",
            displayName: "extension",
            bulkIncrement: "vhNickname"
        }]
    }

    function isSeatInvalid(fieldFrom, fieldTo)
    {
        if (sessionState.account.digitExtension && sessionState.account.digitExtension === "five")
        {
            if (
                Number(fieldFrom) < 20000 ||
                Number(fieldFrom) > 99899 ||
                (fieldTo &&
                    (Number(fieldTo) < 20000 ||
                        fieldTo > 99899))
            )
            {
                return true
            }
            else
            {
                return false
            }
        }
        else if (sessionState.account.digitExtension && sessionState.account.digitExtension === "four")
        {
            if (
                Number(fieldFrom) < 2000 ||
                Number(fieldFrom) > 9989 ||
                (fieldTo &&
                    (Number(fieldTo) < 2000 ||
                        fieldTo > 9989))
            )
            {
                return true
            }
            else
            {
                return false
            }
        }
        else
        {
            if (
                Number(fieldFrom) < 200 ||
                Number(fieldFrom) > 998 ||
                (fieldTo &&
                    (Number(fieldTo) < 200 ||
                        fieldTo > 998))
            )
            {
                return true
            }
            else
            {
                return false
            }
        }
    }

    const filters: IFilterDropdown = {
        filters: [
            {
                name: "ipAddrs",
                type: "select",
                multi: true,
                label: "Public IP",
                loadOptions: getUniqueIPs,
            },
            {
                name: "callRecording",
                type: "select",
                label: "Call Recording",
                multi: true,
                options: [
                    { label: "Not Enabled", value: 0 },
                    { label: "Enabled - Pausable", value: 1 },
                    { label: "Enabled - Non Pausable", value: 2 },
                ],
            },
            {
                name: "status",
                type: "select",
                label: "Status",
                multi: true,
                options: [
                    { label: "Offline", value: "offline" },
                    { label: "Online", value: "online" },
                ],
            },
        ],
    }

    function extractSetVarTimes(value)
    {
        const timeRegex = /([0-9]{1,2}:[0-9]{1,2})-([0-9]{1,2}:[0-9]{1,2})/g

        const matches = timeRegex.exec(value)

        if (matches)
        {
            let startTime = matches[1]
            if (startTime.length === 4)
            {
                startTime = "0" + startTime
            }
            let endTime = matches[2]
            if (endTime.length === 4)
            {
                endTime = "0" + endTime
            }
            return [startTime, endTime]
        } else
        {
            return ["00:00", "23:59"]
        }
    }


    function getMinNumber()
    {
        if (sessionState.account.digitExtension && sessionState.account.digitExtension === "five")
        {
            return 20000
        }
        else if (sessionState.account.digitExtension && sessionState.account.digitExtension === "four")
        {
            return 2000
        }
        else
        {
            return 200
        }
    }

    function getMaxNumber()
    {
        if (sessionState.account.digitExtension && sessionState.account.digitExtension === "five")
        {
            return 99899
        }
        else if (sessionState.account.digitExtension && sessionState.account.digitExtension === "four")
        {
            return 9989
        }
        else
        {
            return 998
        }
    }

    return (
        <>
            <GenericIndexPage
                title={"Seats"}
                urlString={"/seat"}
                columns={columns}
                filters={filters}
                restType={"POST"}
                body={{ sortBy: "extension", direction: "DESC" }}
                conditionallySetValue={{ checkedName: "withholdNumber", setName: "callerIdNumber", checkedValue: true, setValue: "Withheld", match: true }}
                searchable={true}
                permissions={[permissions.seats, permissions.admin, permissions.VHAdmin]}
                addModal={{
                    confirm: true,
                    displayName: "Seat",
                    fieldFrom: {
                        field: "extension",
                        label: "Extension",
                        type: "number",
                        min: getMinNumber(),
                        max: getMaxNumber(),
                        loadDefaultValue: getLatestSeat,
                    },
                    allowBulkAdd: true,
                    settingsStages: addModalSettings,
                    availabilityCheck: (fieldFrom, fieldTo) =>
                    {
                        return new Promise(async (resolve) =>
                        {
                            if (isSeatInvalid(fieldFrom, fieldTo))
                            {
                                resolve({
                                    success: false,
                                    error: "Please input a valid number",
                                })
                            } else
                            {
                                if (fieldTo && fieldTo - fieldFrom > 100)
                                {
                                    resolve({
                                        success: false,
                                        error:
                                            "You cannot add more than 100 seats at one time, please contact your account manager to add more",
                                    })
                                }
                                else if (fieldTo && fieldTo <= fieldFrom)
                                {
                                    resolve({
                                        success: false,
                                        error:
                                            "Please ensure this number is less than the To field",
                                    })
                                }
                                else
                                {
                                    const responseMessage = await checkSeatTaken(
                                        fieldFrom,
                                        fieldTo
                                    )

                                    if (
                                        responseMessage &&
                                        responseMessage.success
                                    )
                                    {
                                        // setSeatDifference(fieldTo - fieldFrom)
                                        resolve({ success: true })
                                    } else
                                    {
                                        resolve({
                                            success: false,
                                            error: responseMessage.message,
                                        })
                                    }
                                }
                            }
                        })
                    },
                    submitData: (data) =>
                    {
                        return addSeat(data)
                    },
                }}
                editModal={{
                    displayName: "Seat",
                    settingsStages: editModalSettings,
                    uniqueIdentifier: "extension",
                    loadRecords: [{
                        url: "/seat",
                        dataDefinition: (data) =>
                        {
                            return ({
                                musicOnHold: data.musicOnHold,
                                emergencyServiceCid: data.emergencyServiceCid,

                                enableCallRecording:
                                    data.options.callRecording,
                                allowUKGeo: data.options.allowUKGeo,
                                allowUKMobile: data.options.allowUKMobile,
                                allowUK118: data.options.allowUK118,
                                allowUKOther: data.options.allowUKOther,
                                allowInternational:
                                    data.options.allowInternational,
                                allowInternationalHighRisk: data.options.allowInternationalHighRisk,
                                allowUKPremium09: data.options.allowUKPremium09,
                                allowUKPremium070:
                                    data.options.allowUKPremium070,
                                acl: data.options.acl,

                                callRestrictionsWeekdayAllow:
                                    data.options.weekdayTimes !== "off",
                                callRestrictionsWeekdayTimes: extractSetVarTimes(
                                    data.options.weekdayTimes
                                ),

                                callRestrictionsWeekendAllow:
                                    data.options.weekendTimes !== "off",
                                callRestrictionsWeekendTimes: extractSetVarTimes(
                                    data.options.weekendTimes,
                                ),
                                channelLimit: data.options.channelLimit,
                                callerIdName: data.callerIdName,
                                callerIdNumber: data.callerIdNumber
                            })
                        },
                    }],
                    submitData: (data) =>
                    {
                        return editSeat(data)
                    },
                }}
                deleteModal={{
                    displayName: "Seat",
                    uniqueIdentifier: "extension",
                    submitData: () =>
                    {
                        return [{
                            path: "/seat",
                            displayName: "extension",
                            type: "delete",
                        }]
                    },
                }}
                helpSections={[
                    {
                        title: "Seat Setup",
                        text: "Allows configuration of hosted seats on your account."
                    }
                ]}
            />
            <EmergencyServicesAddressModal
                open={addressModalOpen}
                setOpen={setAddressModalOpen}
                dataType={"Seat"}
                id={id}
                onSubmit={(data) =>
                {
                    return new Promise<{ success?: boolean; message?: string }>(
                        (resolve) =>
                        {
                            const timer = setTimeout(() =>
                            {
                                resolve({ success: true })
                            }, 1000)
                            return () => clearTimeout(timer);
                        }
                    )
                }}
            />
        </>
    )
}
