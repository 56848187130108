import React, { useContext, forwardRef } from "react"
import { useGenericIndexPage } from "./useGenericIndexPage"
import PageLayout from "../../components/PageLayout/PageLayout"
import { RecordModal } from "../../components/RecordModal/RecordModal"
import Table from "../../components/Table/Table"
import { FileUploadModal } from "../../components/FileUploadModal/FileUploadModal"
import { SessionContext } from "../../Contexts/SessionContext"
import { hasPermission, permissions as StaticPermissions } from "../../Constants/permissions"

interface IProps
{
    title: string
    urlString: string
    columns: IColumn[]
    customActions?: IButton[]
    csvProperties?: ICSVProperties
    customModalButton?: IButton
    uniqueIdentifier?: string
    filters?: IFilterDropdown
    getFilters?: () => IFilterDropdown
    addModal?: IModalSettings
    editModal?: IModalSettings
    deleteModal?: IModalSettings
    customModal?: IModalSettings
    restType?: string
    body?: {}
    searchable?: boolean
    conditionalReadOnly?: IConditionalReadOnly
    uploadFileModal?: IUploadFileModalSettings
    conditionallySetValue?: IConditionallySetValue
    permissions?: string[]
    hideTotalRecords?: boolean
    helpSections?: IHelpSection[]
    configIdentifier?: string
    subIndex?: boolean
    specialBreadcrumbIndices?: { href: string, text: string }[]
    maxNoRecords?: number
}

export const GenericIndexPage = forwardRef(({
    title,
    urlString,
    restType,
    body,
    columns,
    customActions,
    customModalButton,
    uniqueIdentifier = "id",
    filters,
    addModal,
    editModal,
    deleteModal,
    customModal,
    searchable,
    conditionalReadOnly,
    uploadFileModal,
    conditionallySetValue,
    permissions,
    hideTotalRecords,
    helpSections,
    csvProperties,
    configIdentifier,
    subIndex,
    specialBreadcrumbIndices,
    maxNoRecords
}: IProps, ref) =>
{
    const { state: sessionState } = useContext(SessionContext)

    const {
        state,
        dispatch,
        actions,
        setFilterState,
        applyFilters,
        resetFilters,
        pullData,
        onSearch
    } = useGenericIndexPage(restType, urlString, body, filters, ref, conditionallySetValue, hideTotalRecords)



    return (
        <PageLayout
            helpSections={helpSections}
            configIdentifier={configIdentifier}
            subIndex={subIndex}
            specialBreadcrumbIndices={specialBreadcrumbIndices}
        >
            <Table
                title={title}
                columns={columns}
                customActions={customActions}
                customModalButton={customModalButton}
                uploadFileModalButton={uploadFileModal && uploadFileModal.buttonProps}
                uniqueIdentifier={uniqueIdentifier}
                offset={state.offset}
                filters={filters}
                filterState={state.filters}
                setFilterState={setFilterState}
                applyFilters={applyFilters}
                resetFilters={resetFilters}
                csvProperties={csvProperties}
                setOffset={(offset) =>
                {
                    dispatch({ type: actions.offsetChanged, payload: offset })
                    pullData()
                }}
                limit={state.limit}
                setLimit={(limit) =>
                {
                    dispatch({ type: actions.limitChanged, payload: limit })
                    pullData()
                }}
                totalRecords={state.totalRecords}
                records={state.records}
                loading={state.loading}
                sortBy={state.sortBy}
                setSortBy={(sortBy) =>
                {
                    dispatch({ type: actions.sortByChanged, payload: sortBy })
                    pullData()
                }}
                searchable={searchable}
                onSearch={(search) => onSearch(search)}
                searchDefault={state.onSearch}
                selectedRecords={state.selectedRecords}
                setSelectedRecords={(selectedRecords) =>
                    dispatch({
                        type: actions.selectedRecordChanged,
                        payload: selectedRecords,
                    })
                }
                conditionalReadOnly={conditionalReadOnly}
                onAdd={
                    addModal && hasPermission(permissions, sessionState.session.permissions, "create") && {
                        action: () =>
                            dispatch({
                                type: actions.newModalOpenStatusChanged,
                                payload: true,
                            }),
                    }
                }
                onBulkEdit={
                    editModal && hasPermission(permissions, sessionState.session.permissions, "update") && {
                        action: (records) =>
                        {
                            dispatch({
                                type: actions.selectedRecordChanged,
                                payload: records,
                            })
                            dispatch({
                                type: actions.editModalOpenStatusChanged,
                                payload: true,
                            })
                        },
                    }
                }
                onDelete={
                    deleteModal && hasPermission(permissions, sessionState.session.permissions, "delete") && {
                        action: (records) =>
                        {
                            dispatch({
                                type: actions.selectedRecordChanged,
                                payload: records,
                            })
                            dispatch({
                                type: actions.deleteModalOpenStatusChanged,
                                payload: true,
                            })
                        },
                    }
                }
                onCustomModal={
                    customModal && {
                        action: (records) =>
                        {
                            dispatch({
                                type: actions.selectedRecordChanged,
                                payload: records,
                            })
                            dispatch({
                                type: actions.customModalOpenStatusChanged,
                                payload: true,
                            })
                        },
                    }
                }
                onFileUpload={
                    uploadFileModal && {
                        action: () =>
                            dispatch({
                                type: actions.fileUploadModalOpenStatusChanged,
                                payload: true,
                            }),
                    }
                }
                hideTotalRecords={hideTotalRecords}
                disableAdd={maxNoRecords && maxNoRecords <= state.totalRecords
                    && !hasPermission([StaticPermissions.VHAdmin], sessionState.session.permissions, "read")}
            />
            {state.newModalOpenStatus &&
                (addModal.CustomComponent ? (
                    <addModal.CustomComponent
                        open={state.newModalOpenStatus}
                        setOpen={() =>
                            dispatch({
                                type: actions.newModalOpenStatusChanged,
                                payload: false,
                            })
                        }
                        pullData={pullData}
                    />
                ) : (
                    <RecordModal
                        open={state.newModalOpenStatus}
                        setOpen={() =>
                            dispatch({
                                type: actions.newModalOpenStatusChanged,
                                payload: false,
                            })
                        }
                        displayName={addModal.displayName}
                        fieldFrom={addModal.fieldFrom}
                        settingsStages={addModal.settingsStages}
                        availabilityCheck={addModal.availabilityCheck}
                        defineSubmitData={addModal.submitData}
                        allowBulkAdd={addModal.allowBulkAdd}
                        action={"create"}
                        customTitle={addModal.customTitle}
                        uniqueIdentifier={addModal.uniqueIdentifier}
                        pullData={pullData}
                    />
                ))}

            {state.editModalOpenStatus && (
                <RecordModal
                    open={state.editModalOpenStatus}
                    setOpen={() =>
                        dispatch({
                            type: actions.editModalOpenStatusChanged,
                            payload: false,
                        })
                    }
                    displayName={editModal.displayName}
                    settingsStages={editModal.settingsStages}
                    availabilityCheck={editModal.availabilityCheck}
                    defineSubmitData={editModal.submitData}
                    selectedRecords={state.selectedRecords}
                    action={"edit"}
                    customTitle={editModal.customTitle}
                    uniqueIdentifier={editModal.uniqueIdentifier}
                    pullData={pullData}
                    loadRecords={editModal.loadRecords}
                />
            )}
            {state.deleteModalOpenStatus && (
                <RecordModal
                    selectedRecords={state.selectedRecords}
                    open={state.deleteModalOpenStatus}
                    setOpen={() =>
                        dispatch({
                            type: actions.deleteModalOpenStatusChanged,
                            payload: false,
                        })
                    }
                    displayName={deleteModal.displayName}
                    defineSubmitData={deleteModal.submitData}
                    action={"delete"}
                    customTitle={deleteModal.customTitle}
                    uniqueIdentifier={deleteModal.uniqueIdentifier}
                    pullData={pullData}
                    deleteCheck={deleteModal.deleteCheck}
                    deleteFailureMessage={deleteModal.deleteFailureMessage}
                />
            )}
            {state.customModalOpenStatus && (
                <RecordModal
                    selectedRecords={state.selectedRecords}
                    open={state.customModalOpenStatus}
                    setOpen={() =>
                        dispatch({
                            type: actions.customModalOpenStatusChanged,
                            payload: false,
                        })
                    }
                    displayName={customModal.displayName}
                    defineSubmitData={customModal.submitData}
                    action={"delete"}
                    customTitle={customModal.customTitle}
                    uniqueIdentifier={customModal.uniqueIdentifier}
                    pullData={pullData}
                />
            )}
            {state.fileUploadModalOpenStatus && (
                <FileUploadModal
                    open={state.fileUploadModalOpenStatus}
                    setOpen={() =>
                        dispatch({
                            type: actions.fileUploadModalOpenStatusChanged,
                            payload: false,
                        })
                    }
                    customTitle={uploadFileModal.customTitle}
                    sectionTitle={uploadFileModal.sectionTitle}
                    fields={uploadFileModal.fields}
                    defineSubmitData={uploadFileModal.defineSubmitData}
                    displayResponse={uploadFileModal.displayResponse}
                    pullData={pullData}
                    maxWidth={uploadFileModal.maxWidth}
                    previewColumns={uploadFileModal.previewColumns}
                />
            )}
        </PageLayout>
    )
})
