import React from "react"

export default function Audio({
                                  value,
                              }: IQRCode) {

    return (
        <div className="flex-row flex-1">
            {value && (
                <audio
                    controls
                    src={value}
                >
                    The “audio” tag is not supported by your browser.
                </audio>
            )}
        </div>
    )
}
