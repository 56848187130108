import React from "react"
import Button from "../Button"
import MobileStepper from "../MobileStepper/MobileStepper"
import Spinner from "../Spinner/Spinner"
import Stepper from "../Stepper/Stepper"
import { FormField } from "./FormField"
import { useStagedForm } from "./useStagedForm"

interface IProps
{
    stages: IStage[]
    onComplete?: () => void
    loading?: boolean
    state?: any
    modal?: boolean
}

export default function StagedForm({
    stages,
    onComplete,
    loading: loadingProp,
    state,
    modal
}: IProps)
{
    const {
        isMobile,
        activeStep,
        steps,
        completed,
        allStepsCompleted,
        handleBack,
        loading,
        setLoading,
        add,
        handleComplete,
        completedSteps,
        checkErrors,
        calculateCondition,
        calculateConditionalValue,
    } = useStagedForm({ stages, onComplete, state })

    return (
        <div>
            {!isMobile && (
                <Stepper
                    orientation={isMobile ? "vertical" : "horizontal"}
                    activeStep={activeStep}
                    steps={steps.map((label, index) =>
                        ({ label, error: stages[index].error })
                    )}
                />
            )}

            <div>
                {loading || loadingProp || allStepsCompleted() ? (
                    <div
                        style={{
                            padding: 24,
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                        }}>
                        <Spinner />
                    </div>
                ) : (
                    <div className={modal && "stagedFormContent"}>
                        <div
                            style={{
                                padding: 24,
                                paddingTop: 0,
                                overflowY: stages[activeStep].scrollable ? "scroll" : "hidden",
                                height: stages[activeStep].scrollable ? 500 : "auto",
                            }}>
                            {!loading ? (
                                stages[activeStep].fields.map((field) =>
                                {
                                    let showField = true

                                    if ((field?.value === null || field?.value === undefined) && field?.conditionalValue)
                                    {
                                        field.value = calculateConditionalValue(field.conditionalValue)
                                    }
                                    if (field?.conditionallyShow)
                                    {
                                        showField = calculateCondition(
                                            field.conditionallyShow
                                        )
                                    }
                                    else if (field?.conditionallyHide)
                                    {
                                        showField = !calculateCondition(
                                            field.conditionallyHide
                                        )
                                    }

                                    if (showField)
                                    {
                                        return (
                                            <div style={{ paddingBottom: 24 }}>
                                                <FormField
                                                    {...field}
                                                    disabled={field && field.conditionallyDisabled && calculateCondition(field.conditionallyDisabled)}
                                                    halfWidth={field ? field.halfWidth : false}
                                                    key={field ? field.name : ""}
                                                />
                                            </div>
                                        )
                                    }
                                })
                            ) : (
                                <Spinner />
                            )}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flex: 1
                            }}>

                            {isMobile ? (
                                <MobileStepper
                                    NextButton={() =>
                                        <NextButton
                                            small
                                            loading={loading}
                                            stages={stages}
                                            activeStep={activeStep}
                                            setLoading={setLoading}
                                            handleComplete={handleComplete}
                                            add={add}
                                            completedSteps={completedSteps}
                                            steps={steps}
                                        />
                                    }
                                    BackButton={() =>
                                        <BackButton
                                            small
                                            activeStep={activeStep}
                                            handleBack={handleBack}
                                            completedSteps={completedSteps}
                                            steps={steps}
                                            checkErrors={checkErrors}
                                        />
                                    }
                                    variant={"dots"}
                                    numSteps={steps.length}
                                    activeStep={activeStep}
                                />
                            ) : (
                                <React.Fragment>
                                    <BackButton
                                        activeStep={activeStep}
                                        handleBack={handleBack}
                                        completedSteps={completedSteps}
                                        steps={steps}
                                        checkErrors={checkErrors}
                                    />
                                    <NextButton
                                        loading={loading}
                                        stages={stages}
                                        activeStep={activeStep}
                                        setLoading={setLoading}
                                        handleComplete={handleComplete}
                                        add={add}
                                        completedSteps={completedSteps}
                                        steps={steps}
                                    />
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div >
    )
}

function BackButton({ activeStep, handleBack, completedSteps, steps, checkErrors, small = false })
{

    if (activeStep !== 0)
    {
        return (
            <Button
                size={small ? "sm" : null}
                disabled={completedSteps() === (steps.length - 1) && !checkErrors()}
                onClick={handleBack}
                iconLeft={"chevron-left"}>
                Back
            </Button>
        )
    }
    else
    {
        return (
            <div />
        )
    }
}

function NextButton({ loading, setLoading, stages, activeStep, handleComplete, add, completedSteps, steps, small = false })
{
    return (
        !stages[activeStep].hideNextButton && <Button
            size={small ? "sm" : null}
            loading={loading}
            disabled={stages[activeStep].error || stages[activeStep].progressBlocked}
            variant="primary"
            onClick={async () =>
            {
                if (stages[activeStep].onComplete)
                {
                    setLoading(true)
                    const ret = await stages[
                        activeStep
                    ].onComplete.function()

                    if (ret && ret.success)
                    {
                        handleComplete()
                    } else
                    {
                        if (ret.error)
                        {
                            add({
                                type: "error",
                                text: ret.error,
                            })
                        }

                        stages[activeStep].error = true
                    }
                    setLoading(false)
                } else
                {
                    handleComplete()
                }
            }}
            iconRight={
                completedSteps() !== steps.length - 1 &&
                "chevron-right"
            }>
            {stages[activeStep].buttonText
                ? stages[activeStep].buttonText
                : completedSteps() === steps.length - 1
                    ? "Close"
                    : "Next"}
        </Button>)
}