import { useContext, useState, useEffect } from "react";
import { SessionContext } from "../../Contexts/SessionContext";
import useAPIRequests from "../../Hooks/useAPIRequests";
import { useFormReducer } from "../../Hooks/useFormReducer";
import { useRequest } from "../../Hooks/useRequest";

export function useBulkAddCallMonitoringModal(indexRef)
{
    const { getDestinationPromise } = useAPIRequests()
    const { putRequest } = useRequest()
    const { state: sessionState } = useContext(SessionContext)
    const [loading, setLoading] = useState(false)
    const { state, updateForm, updateErrors } = useFormReducer({
        record: {
            selectedRecords: [],
            monitors: [],
            password: "",
            delete: false
        },
    })

    function findFieldError(name)
    {
        if (state.errors && state.errors.length > 0)
        {
            const errors = state.errors.filter((error) => error.name === name)
            if (errors && errors.length > 0)
            {
                return errors[0].value
            }
        }
        return null
    }

    useEffect(() =>
    {
        updateForm({
            target: {
                name: "selectedRecords",
                value: indexRef.current.refGetSelectedRecords(),
            }
        })

    }, [])

    async function getDestinations()
    {
        return new Promise<ISelectOption[]>(async resolve =>
        {
            const options = await getDestinationPromise({
                seats: true,
                queues: false,
                voicemail: false,
                groups: false,
                ivrs: false,
                timeProfiles: false,
                sipTrunks: false,
                dialThrough: false,
                externalDest: false,
                sipTrunkMap: false,
                queueTrunkMaps: false,
                extensionMap: false,
                highPriorityQueue: false,
            })
            resolve(options.map(option => ({
                label: option.label,
                value: option.value.replace(`SIP/`, "")
            })
            ))
        })
    }

    function onSubmit(): Promise<{ successes?: postFeedback[]; failures?: postFeedback[] }>
    {
        return new Promise(async resolve =>
        {
            setLoading(true)
            const successes = []
            const failures = []
            for (var i = 0; i < state.selectedRecords.length; i++)
            {
                let record = state.selectedRecords[i];

                let postData = {
                    monitors: state.monitors,
                    password: state.password,
                    delete: state.delete
                }

                let response = await putRequest(postData, `/callmonitoring/bulk/${sessionState.session.account_no}*${record.extension}`)

                if (response)
                {
                    if (response.success)
                    {
                        successes.push({
                            id: record.extension,
                            message: "All monitors added successfully",
                        })
                    }
                    else if (response.errors)
                    {
                        response.errors.forEach(error =>
                        {
                            failures.push({
                                id: record.extension,
                                message: `${error.monitor} - ${error.message}`,
                            })
                        })
                    }
                    else if (response.message)
                    {
                        failures.push({
                            id: record.extension,
                            message: response.message,
                        })
                    }
                }
                else
                {
                    failures.push({
                        id: record.extension,
                        message: "Something went wrong please try again",
                    })
                }
            }

            const feedback = {
                successes: successes,
                failures: failures,
            }
            setLoading(false)
            resolve(feedback)
        })
    }

    return {
        state,
        updateErrors,
        updateForm,
        loading,
        getDestinations,
        onSubmit,
        findFieldError
    }
}