import React, { useContext, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { hasPermission } from "../../Constants/permissions"
import { SessionContext } from "../../Contexts/SessionContext"
import { ThemeContext } from "../../Contexts/ThemeContext"
import { ToastContext } from "../../Contexts/ToastContext"
import { useRequest } from "../../Hooks/useRequest"
import Container from "../Container/Container"
import { DropdownMenu } from "../DropdownMenu/DropdownMenu"
import ErrorPageText from "../ErrorPageText/ErrorPageText"
import GridItem from "../GridLayout/GridItem"
import GridLayout from "../GridLayout/GridLayout"
import Icon from "../Icons/Icon"
import { MenuItem } from "../MenuItem/MenuItem"
import PageLayout from "../PageLayout/PageLayout"
import variables from "./../../Constants/variables.scss"
import { FormButtons } from "./FormButtons"
import { FormField } from "./FormField"
import "./tabbedCardForm.scss"
import useTabbedCardFormPage from "./useTabbedCardFormPage"
import { useHistory } from "react-router-dom"

interface IProps
{
    tabs: ITab[]
    state?: any
    updateForm?: any
    updateErrors?: any
    loading?: boolean
    customButtons?: IButton[]
    defineSubmitData?: () => ISubmitData[]
    submitText?: string
    onDelete?: () => void
    configIdentifier?: string
    changes?: boolean
    resetChanges?: () => void
    displayName?: string
    pullData?: () => void
    permissions?: string[]
    removeHeaders?: boolean
    noPermissions?: boolean
    resetCaptcha?: any
    specialBreadcrumbIndices?: { href: string, text: string }[]
}

export function TabbedCardFormPage({
    tabs,
    state,
    updateForm,
    updateErrors,
    loading,
    defineSubmitData,
    submitText,
    onDelete,
    configIdentifier,
    changes,
    customButtons,
    resetChanges,
    displayName,
    pullData,
    permissions,
    removeHeaders = false,
    noPermissions = false,
    resetCaptcha,
    specialBreadcrumbIndices
}: IProps)
{
    const {
        el,
        hiddenTabs,
        visibleTabs,
        moreTabsVisible,
        setMoreTabsVisible,
        handleChangeTab,
        findFieldValue,
        calculateCondition,
        calculateConditionalValue,
        findFieldError,
        currentTab,
        setCurrentTab,
        recordNotFound,
        isTabErrored,
        helpSections,
        isSmall,
    } = useTabbedCardFormPage({ tabs, state, loading, changes })

    const history = useHistory()
    const { putRequest, patchRequest, postRequest } = useRequest()
    const { add } = useContext(ToastContext)
    const { primaryColour, primaryColourDarker } = useContext(ThemeContext)
    const [submitLoading, setSubmitLoading] = useState(false)
    const { state: sessionState } = useContext(SessionContext)

    async function update(submits: ISubmitData[], type)
    {
        setSubmitLoading(true)
        let success = false

        let tableSuccess = true
        let tableFields = []
        tabs.filter(Boolean).forEach(tab =>
        {
            tab.sections.forEach(section =>
            {
                section.fields.forEach(async field =>
                {
                    if (field.type === "table")
                    {
                        tableFields = [...tableFields, field]
                    }
                })
            })
        })
        for (const tableField of tableFields)
        {
            if (tableField.fieldRef
                && tableField.fieldRef.current
                && tableField.fieldRef.current.handleSubmit
                && tableSuccess)
            {
                const ret = await tableField.fieldRef.current.handleSubmit()
                tableSuccess = ret.success
            }
        }

        if (tableSuccess)
        {
            let message = ""
            let response
            if (submits != null)
            {
                if (submits.length === 0)
                {
                    success = true
                }

                let redirect = ""
                let refresh = false;
                for (const data of submits)
                {
                    if (data.redirect && data.redirect != "")
                    {
                        redirect = data.redirect
                    }
                    if (data.refreshOnSuccess && data.refreshOnSuccess != null)
                    {
                        refresh = data.refreshOnSuccess
                    }
                    if (data.restType && data.restType === "PATCH")
                    {
                        response = await patchRequest(data.body, data.path)
                    }
                    else if (data.restType && data.restType === "POST")
                    {
                        response = await postRequest(data.body, data.path)
                    }
                    else
                    {
                        response = await putRequest(data.body, data.path)
                    }
                    resetCaptcha && resetCaptcha()

                    if (response && response.success)
                    {
                        success = true
                    } else
                    {
                        success = false
                        if (response && response.validation)
                        {
                            if (Object.keys(response.validation).length > 1)
                            {
                                message = "There are multiple errors, please correct these errors and save again"
                            }
                            else
                            {
                                message = response.validation[Object.keys(response.validation)[0]]
                            }
                            for (var key in response.validation)
                            {
                                if (response.validation.hasOwnProperty(key))
                                {
                                    updateErrors({
                                        target: {
                                            name: `${data.formPrefix ? data.formPrefix + "." : ""}${key}`,
                                            value: response.validation[key],
                                        },
                                    })
                                    message = response.validation[key]
                                }
                            }
                        } else
                        {
                            message = response.message
                        }

                        break
                    }
                }
                if (success)
                {
                    if (redirect)
                    {
                        history.push(redirect)
                    }
                    else if (refresh)
                    {
                        window.location.reload()
                    }
                    else
                    {
                        add({
                            text: type + " has been updated",
                            type: "success",
                        })
                    }
                } else
                {
                    add({
                        text: message
                            ? message
                            : "Something went wrong please try again",
                        type: "error",
                    })
                }
            }
            else
            {
                add({
                    text: type + " has been updated",
                    type: "success",
                })
                success = true
            }
        }
        setSubmitLoading(false)

        if (success)
        {
            pullData && pullData()
        }

        return success
    }

    return (
        <PageLayout
            configIdentifier={configIdentifier}
            helpSections={helpSections}
            removeHeaders={removeHeaders}
            specialBreadcrumbIndices={specialBreadcrumbIndices}>
            {!recordNotFound ? (
                <div style={{ marginBottom: 32 }}>
                    <div className={"tabbedCard"}>
                        <div
                            ref={el}
                            className={"tabPanel"}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                overflow: "hidden",
                                width: "100%",
                                justifyContent: "space-between",
                                marginRight: hiddenTabs.length < 1 && 8,
                            }}>
                            {visibleTabs.filter(Boolean).map((tab, index) =>
                            {
                                const tabErrored = isTabErrored(tab)

                                let className = ""
                                if (tab.disabled)
                                {
                                    className += " cardTabDisabled"
                                }
                                if (tabErrored)
                                {
                                    className += " cardTabErrored"
                                }
                                if (currentTab === tabs.findIndex(searchTab => searchTab.title === tab.title))
                                {
                                    className += " cardTabActive"
                                } else
                                {
                                    className += " cardTabInactive"
                                }
                                return (
                                    <div
                                        key={tab.title}
                                        className={className}
                                        style={{
                                            fontWeight: 460,
                                            color: currentTab === tabs.findIndex(searchTab => searchTab.title === tab.title) && primaryColour
                                        }}
                                        onClick={() =>
                                        {
                                            if (!tab.disabled)
                                            {
                                                setCurrentTab(tabs.findIndex(searchTab => searchTab.title === tab.title))
                                            }
                                        }}>
                                        <Icon
                                            icon={tab.icon}
                                            size={"lg"}
                                            style={{ marginBottom: 6, fontSize: "1.3em", }}
                                        />
                                        <span style={{ fontSize: "0.9em", whiteSpace: "normal" }}>
                                            {tab.title}
                                        </span>
                                        <div
                                            style={{
                                                marginTop: 16,
                                                display: "flex",
                                                height: 3,
                                                width: "100%",
                                                backgroundColor: currentTab === tabs.findIndex(searchTab => searchTab.title === tab.title) && (tabErrored ? variables.colorRed : primaryColourDarker),
                                                transition: "background-color 0.1s ease-in",
                                            }}
                                        />
                                    </div>
                                )
                            })}
                            {hiddenTabs && hiddenTabs.length > 0 && (
                                <DropdownMenu
                                    open={moreTabsVisible}
                                    setOpen={setMoreTabsVisible}
                                    right={24}
                                    IconComponent={() => (
                                        <div
                                            className={`cardTabMore ${moreTabsVisible ? "cardTabActive" : "cardTabInactive"}`}>
                                            <Icon
                                                icon={"sort-down"}
                                                size={"lg"}
                                                style={{ marginBottom: 6, fontSize: "1.3em", }}
                                            />
                                            <span style={{ fontSize: "0.9em" }}>
                                                More
                                            </span>
                                            <div
                                                style={{
                                                    marginTop: 16,
                                                    display: "flex",
                                                    height: 3,
                                                    width: "100%",
                                                    backgroundColor: moreTabsVisible && variables.primaryColorDarker,
                                                    transition: "background-color 0.1s ease-in",
                                                }}
                                            />
                                        </div>
                                    )}
                                    MenuComponent={() =>
                                        hiddenTabs.map((tab) =>
                                        {
                                            const tabErrored = isTabErrored(tab)
                                            return (
                                                <MenuItem
                                                    key={tab.title}
                                                    icon={tab.icon}
                                                    iconColor={tabErrored ? variables.colorRed : primaryColourDarker}
                                                    fontSize="0.9em"
                                                    fontColor={tabErrored ? variables.colorRed : variables.textColor}
                                                    padding={"8px 4px"}
                                                    hoverHighlight
                                                    borderBottom
                                                    onClick={() =>
                                                    {
                                                        if (!tab.disabled)
                                                        {
                                                            handleChangeTab(tab.title)
                                                            setMoreTabsVisible(false)
                                                        }
                                                    }}>
                                                    {tab.title}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                />
                            )}
                        </div>
                        <div className="tabbedCardContent">
                            <Container>
                                <GridLayout>
                                    {!loading &&
                                        tabs[currentTab].sections.map(
                                            (section) =>
                                            {
                                                let showSection = true

                                                if (section.conditionallyShow)
                                                {
                                                    showSection = calculateCondition(section.conditionallyShow)
                                                }
                                                if (showSection && section.conditionallyHide) // Only check for hide if not already being hidden
                                                {
                                                    showSection = !calculateCondition(section.conditionallyHide)
                                                }

                                                if (showSection)
                                                {
                                                    return (
                                                        <GridItem
                                                            spanTwoRows={section.spanTwoRows}
                                                            spanThreeRows={section.spanThreeRows}
                                                            spanTwoColumns={section.fullWidth}
                                                            key={section.title}
                                                        >
                                                            <div
                                                                style={{
                                                                    paddingLeft: 16,
                                                                    paddingRight: 16,
                                                                    paddingBottom: 16,
                                                                }}>
                                                                {section.title && (
                                                                    <div
                                                                        style={{
                                                                            fontWeight: 500,
                                                                            fontSize: "1.1em",
                                                                            borderBottom: `1px solid ${primaryColour}`,
                                                                            width: "100%",
                                                                            display: "flex",
                                                                            paddingBottom: 4,
                                                                        }}>
                                                                        {section.title}
                                                                    </div>
                                                                )}
                                                                <div style={{ paddingTop: 16 }}>
                                                                    <Row
                                                                        noGutters>
                                                                        {section.fields.map((field, index) =>
                                                                        {
                                                                            let fieldValue = findFieldValue(field.name)

                                                                            if (field.name && field.conditional)
                                                                            {
                                                                                field.conditional.forEach((cond) =>
                                                                                {
                                                                                    if (cond.value === fieldValue)
                                                                                    {
                                                                                        fieldValue = cond.text
                                                                                    }
                                                                                })
                                                                            }

                                                                            if (!fieldValue && field.emptyValue)
                                                                            {
                                                                                fieldValue = field.emptyValue
                                                                            }

                                                                            let showValue = ""
                                                                            if (fieldValue !== null && (fieldValue !== undefined || fieldValue !== null))
                                                                            {
                                                                                showValue = fieldValue
                                                                            }
                                                                            else if (field.value)
                                                                            {
                                                                                showValue = field.value
                                                                            }

                                                                            const error = findFieldError(field.name)
                                                                            if (field.tariff)
                                                                            {
                                                                                showValue = (Number(showValue) * 120).toFixed(1) + "p (Ex-VAT " + (Number(showValue) * 100).toFixed(1) + "p) "
                                                                                if (field.perMin)
                                                                                {
                                                                                    showValue += "ppm"
                                                                                }
                                                                            }

                                                                            let disabled = null

                                                                            if (field.conditionallyDisabled)
                                                                            {
                                                                                disabled = calculateCondition(field.conditionallyDisabled)
                                                                            }

                                                                            let showField = true

                                                                            if (showValue === "" && field?.conditionalValue)
                                                                            {
                                                                                let returnedValue = calculateConditionalValue(field.conditionalValue)

                                                                                if (returnedValue !== undefined)
                                                                                {
                                                                                    showValue = returnedValue
                                                                                }
                                                                            }
                                                                            if (field?.conditionallyShow)
                                                                            {
                                                                                showField = calculateCondition(
                                                                                    field.conditionallyShow
                                                                                )
                                                                            }
                                                                            else if (field?.conditionallyHide)
                                                                            {
                                                                                showField = !calculateCondition(
                                                                                    field.conditionallyHide
                                                                                )
                                                                            }

                                                                            if (showField)
                                                                            {
                                                                                return (
                                                                                    <Col key={field.name} sm={12} md={field.halfWidth && !isSmall ? 6 : 12}
                                                                                        style={{
                                                                                            paddingBottom: index !== section.fields.length - 1 && 24
                                                                                        }}>
                                                                                        <FormField
                                                                                            value={showValue}
                                                                                            onChange={field.onChange ? field.onChange : updateForm}
                                                                                            disabled={disabled}
                                                                                            setError={updateErrors}
                                                                                            error={error && error}
                                                                                            {...field}
                                                                                            halfWidth={isSmall ? false : field.halfWidth}
                                                                                            shortLabel={isSmall ? false : field.shortLabel}
                                                                                        />
                                                                                    </Col>
                                                                                )
                                                                            }
                                                                            else
                                                                            {
                                                                                return null
                                                                            }
                                                                        }
                                                                        )}
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </GridItem>
                                                    )
                                                }
                                            }
                                        )}
                                </GridLayout>
                                {!loading ? (
                                    <FormButtons
                                        submitDisabled={state?.errors && state?.errors?.length}
                                        loading={submitLoading}
                                        submitText={submitText && submitText}
                                        onDelete={(noPermissions || hasPermission(permissions, sessionState.session.permissions, "delete")) && onDelete && onDelete}
                                        deleteText={`Delete ${displayName}`}
                                        onSubmit={
                                            (noPermissions || hasPermission(permissions, sessionState.session.permissions, "update")) && defineSubmitData && defineSubmitData() !== null &&
                                            (() =>
                                            {
                                                return new Promise(
                                                    async (resolve) =>
                                                    {
                                                        const ret = await update(
                                                            defineSubmitData(),
                                                            displayName
                                                        )
                                                        if (ret && resetChanges)
                                                        {
                                                            resetChanges()
                                                        }
                                                    }
                                                )
                                            })
                                        }
                                        customButtons={
                                            (noPermissions || hasPermission(permissions, sessionState.session.permissions, "update")) && customButtons && customButtons
                                        }
                                    />
                                ) : (
                                    <div />
                                )}
                            </Container>
                        </div>
                    </div>
                </div>
            ) : (
                <ErrorPageText
                    error={"Error"}
                    text={"The requested record could not be found"}
                />
            )}
        </PageLayout>
    )
}
