import React from "react";
import { TabbedCardFormPage } from "../../components/Form/TabbedCardFormPage";
import { usePortingEmailFormPage as usePortingAOTFormPage } from './usePortingAOTFormPage';

export default function PortingAOTFormPage({ prop }: any)
{
    const {
        loading,
        tabs,
        state,
        updateForm,
        updateErrors,
        submitData
    } = usePortingAOTFormPage(prop)

    return (
        <React.Fragment>
            <TabbedCardFormPage
                tabs={tabs}
                loading={loading}
                state={state}
                updateErrors={updateErrors}
                updateForm={updateForm}
                defineSubmitData={submitData}
                submitText={"Submit"}
                noPermissions={true}
            />
        </React.Fragment>
    )
}