import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { Slide } from "@material-ui/core";

type IVariant =
    null
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light'

interface IProps
{
    text: string
    type: "error" | "info" | "success"
    timeout?: number,
    onDismiss: () => void
}

export function ToastBody({ text, type, timeout, onDismiss }: IProps)
{
    const [variant, setVariant] = useState<IVariant>(null)
    const [visible, setVisible] = useState(true)

    // default 3 second timeout
    if (!timeout)
    {
        timeout = 3000
    }

    useEffect(() =>
    {
        if (type === "error")
        {
            setVariant("danger")
        } else if (type === "info")
        {
            setVariant("info")
        } else if (type === "success")
        {
            setVariant("success")
        }
    }, [])

    function close()
    {
        setVisible(false)
        //Give time for the toast to slide away before ceasing to exist
        const timer = setTimeout(() =>
        {
            onDismiss()
        }, 100);

        return () => clearTimeout(timer);
    }

    useEffect(() =>
    {
        const timer1 = setTimeout(() =>
        {
            setVisible(false)

            //Give time for the toast to slide away before ceasing to exist
            const timer2 = setTimeout(() =>
            {
                onDismiss()
            }, 100);

            return () => clearTimeout(timer2);

        }, timeout);

        return () => clearTimeout(timer1);
    });


    return (
        <Slide direction="left" in={visible} mountOnEnter unmountOnExit>
            <Alert
                onClose={close}
                variant={variant}
                dismissible
            >
                {text}
            </Alert>
        </Slide>

    );
}