import React, { useContext, useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckoutForm from "../StripeCheckout/StripeCheckoutForm";
import { useRequest } from "../../Hooks/useRequest";
import { SessionContext } from "../../Contexts/SessionContext"

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PROMISE);

export default function CreditCardInput({
    paymentType,
    invoiceNumber,
    cardAutoBill,
    cardAutoTopup,
    cardAmount,
    cardThreshold
}: ICreditCardInput)
{
    const [clientSecret, setClientSecret] = useState("");
    const { postRequest } = useRequest()

    const { state: sessionState } = useContext(SessionContext)

    useEffect(() =>
    {
        async function getIntent()
        {
            if (paymentType == "topup")
            {
                const postData = {
                    autoTopup: cardAutoTopup,
                    amount: cardAmount,
                    threshold: cardThreshold
                }

                let response = await postRequest(postData, `/account/${sessionState.session.account_no}/topup`)
                if (response)
                {
                    setClientSecret(response.clientSecret);
                }
            }
            else if (paymentType == "invoice")
            {
                const postData = {
                    autoBill: cardAutoBill,
                    amount: cardAmount
                }

                let response = await postRequest(postData, `/invoice/${invoiceNumber}/pay`)
                if (response)
                {
                    setClientSecret(response.clientSecret);
                }
            }
        }

        getIntent()
    }, []);

    const options = {
        clientSecret
    };

    return (
        <div className="Stripe">
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <StripeCheckoutForm />
                </Elements>
            )}
        </div>
    );
}
