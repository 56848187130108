import React from "react";
import "./container.scss";


export default function Container({children, style}: any) {
    return (
        <div className="containerComponent" style={style}>
            {children}
        </div>
    );
}
