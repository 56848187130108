import React, { useState, useContext } from "react"
import { TabbedCardFormPage } from "../../components/Form/TabbedCardFormPage";
import { useMusicOnHoldFormPage } from "./useMusicOnHoldFormPage";
import { RecordModal } from "../../components/RecordModal/RecordModal";
import { permissions } from "../../Constants/permissions";

export default function MusicOnHoldFormPage({ prop }: any)
{
    const { id } = prop.match.params
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    const {
        loading,
        tabs,
        state,
        updateForm,
        updateErrors,
        deleteData,
        hideDelete
        // changes,
        // resetChanges,
    } = useMusicOnHoldFormPage(id)

    return (
        <React.Fragment>
            <TabbedCardFormPage
                state={state}
                updateForm={updateForm}
                updateErrors={updateErrors}
                // changes={changes}
                // resetChanges={resetChanges}
                tabs={tabs}
                loading={loading}
                permissions={[permissions.musicOnHold, permissions.admin, permissions.VHAdmin]}
                onDelete={
                    !hideDelete &&
                    (() => setDeleteModalOpen(true))
                }
                displayName={"Music On Hold"}
            />
            <RecordModal
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
                action={"delete"}
                selectedRecords={[state.musicOnHold]}
                displayName={"Music On Hold"}
                uniqueIdentifier={"musicOnHoldName"}
                defineSubmitData={deleteData}
                apiUniqueIdentifier={"musicOnHoldId"}
            />
        </React.Fragment>
    )
}
