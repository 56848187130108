import useAPIRequests from "../../Hooks/useAPIRequests"
import { useRequest } from "../../Hooks/useRequest"
import { useEffect, useState } from "react"
import { useFormReducer } from "../../Hooks/useFormReducer"
import { regex } from "../../Constants/regex"
import { regexError } from "../../Constants/regexError"

export function useSMSFormPage(id: number)
{
    const { getRequest } = useRequest()
    const [loading, setLoading] = useState(true)
    const [record, setRecord] = useState({})
    const { getPromise } = useAPIRequests()

    const [addModalOpen, setAddModalOpen] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const {
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
    } = useFormReducer({ record })

    useEffect(() =>
    {
        async function pullData()
        {
            setLoading(true)
            let response = await getRequest(`/sms/${id}`)
            if (response)
            {
                setRecord(response)
            }
            setLoading(false)
        }

        pullData()
        // eslint-disable-next-line
    }, [id])

    const [forward, setForward] = useState(false)
    const [reply, setReply] = useState(false)

    function replyModal()
    {
        setReply(true)
        setForward(false)
        setAddModalOpen(true)
    }

    function forwardModal()
    {
        setForward(true)
        setReply(false)
        setAddModalOpen(true)
    }

    const customButtons: IButton[] = [
        {
            onClick: () => replyModal(),
            buttonText: "Reply",
        },
        {
            onClick: () => forwardModal(),
            buttonText: "Forward",
        },
    ]

    function getToValue()
    {
        return reply ? state.smsOriginator : ""
    }

    function getMessageValue()
    {
        return forward ? state.smsMessage : ""
    }

    const modalSettings: ISettingsStage[] = [
        {
            title: "Message",
            fields: [
                {
                    type: "select",
                    name: "from",
                    label: "From",
                    loadOptions: () =>
                        getPromise({
                            restType: "GET",
                            url: "sms/originators",
                            label: "number",
                            value: "id",
                        }),
                },
                {
                    type: "text",
                    name: "to",
                    label: "To",
                    regex: regex.telephoneNumber,
                    regexError: regexError.telephoneNumber,
                    value: getToValue(),
                },
                {
                    type: "text",
                    name: "message",
                    label: "Message",
                    maxLength: 250,
                    value: getMessageValue(),
                },
            ],
        },
    ]

    const addModal = {
        dataType: "Message",
        settingsStages: modalSettings,
        customTitle: "Send Message",
        onSubmit: (data) =>
        {
            return [{
                postData: {
                    originator: data.from.replace("MOBILE-", ""),
                    destinations: [data.to],
                    message: data.message,
                    fromMobile: data.from.includes("MOBILE-"),
                },
                path: "/sms",
            }]
        },
        open: addModalOpen,
        setOpen: setAddModalOpen,
    }

    const deleteModal = {
        dataType: "Message",
        customTitle: "Delete Message",
        onSubmit: (data) =>
        {
            return [{
                path: "/sms",
                type: "delete",
            }]
        },
        open: deleteModalOpen,
        setOpen: setDeleteModalOpen,
    }

    const tabs: ITab[] = [
        {
            title: "Message",
            icon: "toolbox",
            disabled: false,
            sections: [
                {
                    title: "Message Details",
                    fullWidth: true,
                    fields: [
                        {
                            type: "info",
                            label: "From",
                            name: `from`,
                        },
                        {
                            type: "info",
                            label: "To",
                            name: "to",
                        },
                        {
                            type: "info",
                            label: "Date",
                            name: "date",
                        },
                        {
                            type: "info",
                            label: "Message",
                            name: "message",
                        },
                    ],
                },
            ],
        },
    ]

    return {
        loading,
        tabs,
        state,
        updateForm,
        updateErrors,
        addModal,
        customButtons,
        deleteModal,
        // changes,
        // resetChanges,
    }
}
