import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage";
import React from "react";
import variablesScss from "../../Constants/variables.scss";
import { ProvisioningModal } from "../../components/ProvisioningModal/ProvisioningModal";
import { permissions } from "../../Constants/permissions";

export default function ProvisioningIndexPage()
{

    const columns: IColumn[] = [
        {
            title: "MAC",
            name: "mac",
            link: { root: "provisioning" },
            bold: true,
            sortBy: true,
        },
        {
            title: "Seat",
            name: "sipAccounts",
            sortBy: true
        },
        {
            title: "Serial",
            name: "serial",
            sortBy: true
        },
        {
            title: "Make",
            name: "make",
            sortBy: true
        },
        {
            title: "Model",
            name: "model",
            sortBy: true
        },
        {
            title: "Locked?",
            conditional: [
                { value: true, icon: "lock", color: variablesScss.primaryColorDarker, text: "Locked" },
                { value: false, icon: "lock-open", color: variablesScss.primaryColorDarker, text: "Unlocked" },
            ],
            name: "locked",
            sortBy: true
        },

    ]

    function getModalSettings(edit): ISettingsStage[] 
    {
        return [
            {
                title: "General Settings",
                fields: [
                !edit ? {
                    type: "text",
                    name: "customName",
                    maxLength: 45,
                    label: "Name"
                } : {},    
                    {    
                    type: "text",
                    label: "Background Image URL",
                    name: "pictureUrl",
                    },
                    {
                        type: "paragraph",
                        value:
                            "For security reasons the phone is locked after the first provisioning request. " +
                            "If the phone requires the username and password again, please unlock the device.",
                    },
                    {
                        type: "switch",
                        label: "Locked",
                        name: "locked",
                    },
                ],
            },
        ]
    }

    
    function editProvisioningDevice(data)
    {

        const postData = {
            pictureUrl: data.pictureUrl,
            locked: data.locked,
        }

        return [{
            postData: postData,
            path: "/provisioning",
            type: "edit",
            displayName: "name",
        }]
    }

    return (
        <GenericIndexPage
            title={"Provisioned Devices"}
            urlString={"/provisioning/overview"}
            body={{}}
            restType={"POST"}
            addModal={{
                CustomComponent: ProvisioningModal
            }}
            editModal={{
                displayName: "Provisioned Device",
                settingsStages: getModalSettings(true),
                loadRecords: [{
                    url: "/provisioning",
                }],
                submitData: (data) =>
                {
                    return editProvisioningDevice(data)
                },
            }}
            permissions={[permissions.provisioning, permissions.admin, permissions.VHAdmin]}
            columns={columns}
            searchable={true}
            helpSections={[
                {
                    title: "Provisioning",
                    text: "Administer provisioned devices in your account."
                }
            ]}
        />
    )
}