import React, { useEffect, useState, useContext } from "react";
import TextInput from "../FormInputs/TextInput"
import EmailInput from "../FormInputs/EmailInput"
import Select from "../FormInputs/Select"
import { Switch } from "../FormInputs/Switch"
import { Checkbox } from "../FormInputs/Checkbox"
import { Paragraph } from "../FormInputs/Paragraph"
import { Info } from "../FormInputs/Info"
import { RadioGroup } from "../FormInputs/RadioGroup"
import { FormButton } from "../FormInputs/FormButton"
import NumberInput from "../FormInputs/NumberInput"
import TimeInput from "../FormInputs/TimeInput"
import Button from "../Button"
import Icon from "../Icons/Icon"
import { DialPad } from "../FormInputs/DialPad"
import { FeedbackAccordion } from "../Accordion/FeedbackAccordion"
import TimeSlider from "../TimeSlider/TimeSlider"
import Slider from "../Slider/Slider"
import QRCode from "../FormInputs/QRCode"
import { DaySelector } from "../FormInputs/DaySelector"
import { Image } from "../FormInputs/Image"
import Audio from "../FormInputs/Audio"
import PasswordInput from "../FormInputs/PasswordInput"
import Download from "../FormInputs/Download"
import { Images } from "../FormInputs/Images"
import DateInput from "../FormInputs/DateInput"
import CSVDownloadField from "../FormInputs/CSVDownloadField"
import LinkField from "../FormInputs/LinkField"
import FileUpload from "../FormInputs/FileUpload"
import { EditableTable } from "../FormInputs/EditableTable"
import FormTable from "../FormTable/FormTable"
import ColourPicker from "../FormInputs/ColourPicker"
import GenericWarningModal from "../FormInputs/GenericWarningModal"
import CardSelect from "../FormInputs/CardSelect"
import PaypalButton from "../PaypalButton/PaypalButton"
import CreditCardInput from "../FormInputs/CreditCardInput"
import { GoogleReCAPTCHA } from "../FormInputs/GoogleReCAPTCHA"
import DateTimeInput from "../FormInputs/DateTimeInput"
import MultiText from "../FormInputs/MultiText"
import { Accordion } from "@material-ui/core"
import SimpleAccordion from "../Accordion/SimpleAccordion"
import YouTubeVideo from "../YouTubeVideo/YouTubeVideo"
import { ThemeContext } from "../../Contexts/ThemeContext";
import Tooltip from '../Tooltip'

export function FormField(field: IFormField)
{
    const { primaryColour } = useContext(ThemeContext) 
    
    return (
        <div className="flex-column formField" style={{ flex: 1 }}>
            <div className="formFieldContainer">
                {field.type !== "paragraph" &&
                    field.type !== "table" &&
                    field.type !== "formTable" &&
                    field.type !== "feedbackAccordion" &&
                    field.type !== "cardSelect" &&
                    field.type !== "paypalButton" &&
                    field.type !== "creditCard" &&
                    field.type !== "accordion" &&
                    !field.noLabel && (
                        <div
                            className="flex-row label"
                            style={{ flex: field.shortLabel && 1 }}
                        >
                            {field.label && `${field.label}${field.required ? "*" : ""}:`}
                        
                            {field.icon && (
                                <Tooltip
                                    text={field.helptext}
                                >
                                    <div style={{
                                            marginLeft: 5,
                                            backgroundColor: primaryColour,
                                            height: 18,
                                            width: 18,
                                            borderRadius: 35 / 2,
                                         }} >
                                    
                                    <Icon style={{ marginRight: '6', color: "#FFFFFF", }} size='xs' icon={field.icon}/>
                                </div>
                                </Tooltip>
                            )}                       
                        </div>
                    )}
                <div
                    className={`flex-row field ${field.halfWidth ? "halfWidth" : ""
                        }`}
                    style={{
                        alignItems: "center",
                        flex: field.shortLabel && 2,
                    }}
                >
                    {field.type === "info" && <Info {...field} />}
                    {field.type === "paragraph" && <Paragraph {...field} />}
                    {field.type === "checkbox" && <Checkbox {...field} />}
                    {field.type === "radio" && <RadioGroup {...field} />}
                    {field.type === "switch" && <Switch {...field} />}
                    {(field.type === "text" || field.type === "tel") && <TextInput {...field} />}
                    {field.type === "number" && <NumberInput {...field} />}
                    {field.type === "select" && <Select {...field} />}
                    {field.type === "email" && <EmailInput {...field} />}
                    {field.type === "button" && <FormButton {...field} />}
                    {field.type === "time" && <TimeInput {...field} />}
                    {field.type === "date" && <DateInput {...field} />}
                    {field.type === "timeSlider" && <TimeSlider {...field} />}
                    {field.type === "table" && (
                        <EditableTable {...field} ref={field.fieldRef} />
                    )}
                    {field.type === "warningModal" && <GenericWarningModal {...field} />}
                    {field.type === "formTable" && <FormTable {...field} />}
                    {field.type === "slider" && <Slider {...field} />}
                    {field.type === "dialPad" && <DialPad {...field} />}
                    {field.type === "feedbackAccordion" && (
                        <FeedbackAccordion {...field} />
                    )}
                    {field.type === "qrCode" && <QRCode {...field} />}
                    {field.type === "daySelector" && <DaySelector {...field} />}
                    {field.type === "image" && <Image {...field} />}
                    {field.type === "images" && <Images {...field} />}
                    {field.type === "audio" && <Audio {...field} />}
                    {field.type === "download" && <Download {...field} />}
                    {field.type === "password" && <PasswordInput {...field} />}
                    {field.type === "csvDownload" && (
                        <CSVDownloadField {...field} />
                    )}
                    {field.type === "cardSelect" && <CardSelect {...field} />}
                    {field.type === "link" && <LinkField {...field} />}
                    {field.type === "colourPicker" && <ColourPicker {...field} />}
                    {field.type === "fileUpload" && <FileUpload {...field} />}
                    {field.type === "paypalButton" && <PaypalButton {...field} />}
                    {field.type === "creditCard" && <CreditCardInput {...field} />}
                    {field.type === "component" && (
                        // @ts-ignore
                        <field.Component {...field} />
                    )}
                    {field.type === "captcha" && (<GoogleReCAPTCHA {...field} ref={field.recaptchaRef} />)}
                    {field.type === "multiText" && (<MultiText {...field} />)}
                    {field.type === "dateTime" && (<DateTimeInput {...field} />)}
                    {field.type === "accordion" && (<SimpleAccordion {...field} />)}
                    {field.type === "youtubeVideo" && (<YouTubeVideo {...field} />)}
                    {field.type === "child" && field.value}
                    {field.onRemove && (
                        <Button
                            variant={"danger"}
                            onClick={field.onRemove}
                            size={"sm"}
                            style={{ marginLeft: 8 }}
                        >
                            <Icon icon={"trash-alt"} />
                        </Button>
                    )}
                </div>
            </div>
            {!field.noError && field.error && field.type !== "formTable" && field.type !== "creditCard" && (
                <div className="formFieldContainer">
                    {!field.noLabel && <div className="flex-row label" />}
                    <div className={`flex-column error field ${field.halfWidth ? "halfWidth" : ""}`}>
                        <p>{field.error}</p>
                    </div>
                </div>
            )}
        </div>
    )
}
