import React from "react"
import { TabbedCardFormPage } from "../../components/Form/TabbedCardFormPage"
import { useAccountDetailsFormPage } from "./useAccountDetailsFormPage"
import { AccountDeleteModal } from "./AccountDeleteModal"
import { permissions } from "../../Constants/permissions"

export default function AccountDetailsFormPage({ prop }: any)
{
    const {
        loading,
        tabs,
        state,
        updateForm,
        updateErrors,
        deleteModalOpen,
        setDeleteModalOpen,
        handleSubmit,
        // changes,
        // resetChanges,
    } = useAccountDetailsFormPage()

    return (
        <React.Fragment>
            <TabbedCardFormPage
                state={state}
                updateForm={updateForm}
                updateErrors={updateErrors}
                // changes={changes}
                // resetChanges={resetChanges}
                tabs={tabs}
                loading={loading}
                defineSubmitData={handleSubmit}
                displayName={"Account"}
                permissions={[permissions.account, permissions.admin, permissions.VHAdmin]}
            />

            <AccountDeleteModal
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
            />
        </React.Fragment>
    )
}
