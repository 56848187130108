import React, { useContext, useState, useEffect } from "react"
import "./topBar.scss"
import Icon from "../Icons/Icon"
import variables from "./../../Constants/variables.scss"
import { NavLink } from "react-router-dom"
import { MenuItem } from "../MenuItem/MenuItem"
import { DropdownMenu } from "../DropdownMenu/DropdownMenu"
import { useMediaQuery } from "@material-ui/core"
import GradientBackground from "../GradientBackground/GradientBackground"
import { SessionContext } from "../../Contexts/SessionContext"
import { shadeColour } from "../../Common/shadeColour"
import { ThemeContext } from "../../Contexts/ThemeContext"

interface IProps
{
    sidebarExpanded?: boolean
    setSidebarExpanded: (val?: boolean) => void
    helperSidebarExpanded?: boolean
    setHelperSidebarExpanded: (val?: boolean) => void
    showHelpButton?: boolean
}

export default function TopBar({
    sidebarExpanded,
    setSidebarExpanded,
    helperSidebarExpanded,
    setHelperSidebarExpanded,
    showHelpButton,
}: IProps)
{
    const [dropdownVisible, setDropdownVisible] = useState(0)
    const isTablet = useMediaQuery("(max-width:600px)")
    const isMobile = useMediaQuery("(max-width:460px)")
    const { state } = useContext(SessionContext)
    const { primaryColour, primaryColourDarker, accentColour } = useContext(ThemeContext)

    return (
        <GradientBackground
            colors={[primaryColour, primaryColourDarker]}
            angle={0.6}
            className="topbar"
            style={{ borderBottom: `1px solid ${primaryColourDarker}` }}
        >
            <div className="topbarContent">
                {!isMobile && (
                    <div className="leftPane">
                        {state.themeLogo && state.themeLogo != null &&
                            <img
                                style={{ maxHeight: 46, marginRight: 8 }}
                                src={
                                    state.themeLogo
                                }
                                alt="logo"
                            />
                        }
                        {isTablet && (
                            <NavLink to="/" className="cpTitle">
                                Control Panel
                            </NavLink>
                        )}
                    </div>
                )}
                {!isTablet && (
                    <div className="centerPane">
                        <NavLink to="/" className="cpTitle">
                            Control Panel
                        </NavLink>
                    </div>
                )}

                <div className="rightPane">
                    {isTablet && (
                        <DropdownMenu
                            iconClassName="topBarMenuItemContainer"
                            dropdownClassName="dropdownTopbar"
                            open={sidebarExpanded}
                            setOpen={() =>
                            {
                                setSidebarExpanded(!sidebarExpanded)
                            }}
                            IconComponent={() => (
                                <div className="menuItemIcon">
                                    <Icon icon="bars" />
                                </div>
                            )}
                        />
                    )}
                    {/* <DropdownMenu
                        iconClassName="topBarMenuItemContainer"
                        dropdownClassName="dropdownTopbar"
                        open={dropdownVisible === 1}
                        setOpen={(val) => {
                            if (val) {
                                setDropdownVisible(1)
                            } else {
                                setDropdownVisible(0)
                            }
                        }}
                        IconComponent={() => (
                            <div
                                className={
                                    dropdownVisible === 1 ? "menuHighlight" : ""
                                }
                            >
                                <div className={"topBarCircle"}>
                                    <div className="menuItemIcon">
                                        <Icon icon="bell" />
                                    </div>
                                    <div className="notificationBadge">
                                        {state.notifications.length > 0 && (
                                            <Badge variant="danger">
                                                {state.notifications.length}
                                            </Badge>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        MenuComponent={() => (
                            <React.Fragment>
                                <div className="dropdownHeader">
                                    You have {state.notifications.length}{" "}
                                    notification
                                    {state.notifications.length !== 1 && "s"}
                                    {state.notifications.length > 0 ? ":" : "."}
                                </div>
                                {state.notifications.map((notification) => (
                                    <MenuItem
                                        hoverHighlight
                                        borderBottom
                                        key={notification.notification_id}
                                        children={
                                            notification.notification_title
                                        }
                                        to={`/notifications?open=${notification.notification_id}`}
                                        iconSize="sm"
                                        icon={notification.notification_icon.substring(
                                            3
                                        )}
                                        iconColor={
                                            notification.notification_color ===
                                            "text-blue"
                                                ? variables.colorBlue
                                                : notification.notification_color ===
                                                      "text-aqua" &&
                                                  variables.colorAqua
                                        }
                                        fontSize="0.9em"
                                        padding={8}
                                        fontColor={variables.textColor}
                                        iconBoxSize={24}
                                    />
                                ))}
                                <div className={"dropdownMoreBox"}>
                                    <Link
                                        style={{
                                            color: variablesScss.textColor,
                                            textDecoration: "none",
                                            fontWeight: 500,
                                        }}
                                        to={"/notifications"}
                                    >
                                        More
                                    </Link>
                                </div>
                            </React.Fragment>
                        )}
                    /> */}
                    {showHelpButton && (
                        <DropdownMenu
                            iconClassName="topBarMenuItemContainer"
                            dropdownClassName="dropdownTopbar"
                            open={helperSidebarExpanded}
                            setOpen={setHelperSidebarExpanded}
                            IconComponent={() => (
                                <div
                                    style={
                                        {
                                            backgroundColor:
                                                helperSidebarExpanded
                                                && shadeColour(primaryColour, 5)
                                        }
                                    }
                                    className={
                                        helperSidebarExpanded
                                            ? "menuHighlight"
                                            : ""
                                    }
                                >
                                    <div className={"topBarCircle"}>
                                        <div className="menuItemIcon">
                                            <Icon icon="question" />
                                        </div>
                                    </div>
                                </div>
                            )}
                        />
                    )}
                    <DropdownMenu
                        iconClassName="topBarMenuItemContainer"
                        dropdownClassName="dropdownTopbar"
                        open={dropdownVisible === 3}
                        setOpen={(val) =>
                        {
                            if (val)
                            {
                                setDropdownVisible(3)
                            } else
                            {
                                setDropdownVisible(0)
                            }
                        }}
                        IconComponent={() => (
                            <div
                                style={
                                    {
                                        backgroundColor:
                                            dropdownVisible === 3
                                            && shadeColour(primaryColour, 5)
                                    }
                                }
                                className={
                                    dropdownVisible === 3 ? "menuHighlight" : ""
                                }
                            >
                                {state.avatar && state.avatar != null ? (
                                    <img
                                        alt={"avatar"}
                                        src={state.avatar}
                                        className={"topBarCircle avatar"}
                                        style={{ backgroundColor: accentColour }}
                                    />
                                ) : (
                                    <div
                                        className="topBarCircle avatar"
                                        style={{
                                            backgroundColor: accentColour
                                        }}
                                    >
                                        {state &&
                                            //@ts-ignore
                                            state.session.name &&
                                            //@ts-ignore
                                            state.session.surname &&
                                            state.session &&
                                            //@ts-ignore
                                            `${state.session.name.charAt(
                                                0
                                                //@ts-ignore
                                            )}${state.session.surname.charAt(
                                                0
                                            )}`}
                                    </div>
                                )}
                            </div>
                        )}
                    />
                </div>
            </div>
        </GradientBackground>
    )
}
