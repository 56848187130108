import Icon from "../Icons/Icon";
import React, { useState } from "react";

export function FeedbackAccordion({ title, items, variant = "green" }: IFeedbackAccordion)
{

    const [open, setOpen] = useState(false)

    const themes = [
        {
            variant: "red",
            borderBottomColour: "#f5c6cb",
            backgroundColour: "#f8d7da",
            textColour: "#721c24",
        },
        {
            variant: 'green',
            borderBottomColour: "#c3e6cb",
            backgroundColour: "#d4edda",
            textColour: "#155724",
        },
    ]

    const activeTheme = themes.filter(theme => theme.variant === variant)[0]

    return (
        <div
            style={{
                color: activeTheme.textColour,
                backgroundColor: activeTheme.backgroundColour,
                border: `1px solid ${activeTheme.borderBottomColour}`,
                width: "100%"
            }}
        >
            <div
                style={{
                    padding: 6,
                    fontWeight: 450,
                    cursor: "pointer"
                }}
                onClick={() => setOpen(!open)}
            >
                <Icon
                    icon={"chevron-right"}
                    size={"xs"}
                    style={{
                        marginRight: 8,
                        marginBottom: 2,
                        marginLeft: 2,
                        transform: open ? "rotate(90deg)" : "none",
                        transition: "transform 0.3s ease-out",
                    }}
                />
                {title}

            </div>
            <div
                style={{
                    height: open ? 30 * items.length : 0,
                    transition: "height 0.3s ease-out",
                    overflow: "hidden"
                }}
            >
                {items.map((item, index) =>
                    <div
                        style={{
                            borderTop: index === 0 && `1px solid ${activeTheme.borderBottomColour}`,
                            borderBottom: index !== items.length - 1 && `1px solid ${activeTheme.borderBottomColour}`,
                            padding: "4px 8px",
                            fontSize: "0.9em",
                            opacity: open ? 1 : 0,
                            transition: "opacity 0.1s ease-out"
                        }}
                    >
                        {item}
                    </div>
                )}
            </div>
        </div>
    )
}