
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-grid-system";
import Button from "../Button";
import Card from "../Card/Card";
import renderHTML from "react-render-html"

export default function CardSelect({
    value,
    cardOptions,
    onEnable,
    onDisable,
    disabled
}: ICardSelect)
{
    const [options, setOptions] = useState([])
    async function loadCardOptions()
    {
        setOptions(await cardOptions())
    }

    useEffect(() =>
    {
        loadCardOptions()
    }, [])
    return (
        <Row
            style={{
                display: "flex",
                flex: 1,
                rowGap: 15

            }}
        >
            {options.map(option =>
                <Col lg={12} xl={6} >
                    <Card style={{ padding: 32, width: "100%", display: "flex", flex: 1, backgroundColor: "aliceblue" }}>
                        <span
                            style={{ fontWeight: 500, paddingBottom: 8 }}
                        >
                            {option.title}
                        </span>
                        <div
                            style={{ paddingBottom: 8 }}
                        >
                            {renderHTML(option.description)}
                        </div>
                        <div
                            style={{ paddingBottom: 8 }}
                        >
                            <strong>{option.contract}</strong>
                        </div>
                        <div
                        >
                            <Button
                                disabled={disabled}
                                variant={value.includes(option.id) ? "danger" : "success"}
                                onClick={async () =>
                                {
                                    if (value.includes(option.id))
                                    {
                                        onDisable(option)
                                    }
                                    else
                                    {
                                        onEnable(option)
                                    }
                                }}
                            >
                                {value.includes(option.id) ? "Disable" : "Enable"}
                            </Button>
                        </div>
                    </Card>
                </Col>

            )
            }
        </Row >
    )
}