import { useCallback, useEffect, useReducer, useState } from "react"
import { has, set } from "lodash"
import { produce } from "immer"

export function useFormReducer({ record })
{
    const [state, updateState] = useReducer(enhancedReducer, record)

    // const [referenceRecord, setReferenceRecord] = useState<string>()
    // const [changes, setChanges] = useState<boolean>(false)

    useEffect(() =>
    {
        // setReferenceRecord(JSON.stringify(record))
        updateState({ type: "REPLACE_STATE", payload: record })

    }, [JSON.stringify(record)])

    // useEffect(() =>
    // {

    //     if (JSON.stringify(state) !== referenceRecord)
    //     {
    //         setChanges(true)
    //     } else
    //     {
    //         setChanges(false)
    //     }

    // }, [state])

    // const resetChanges = useCallback(() =>
    // {
    //     setReferenceRecord(JSON.stringify(state))
    //     setChanges(false)

    // }, [referenceRecord, state])

    function enhancedReducer(state, updateArg)
    {
        if (updateArg.type === "REPLACE_STATE")
        {
            return updateArg.payload
        }

        if (updateArg.type === "UPDATE_TABLE")
        {
            const { type, name, value, uniqueIdentifier } = updateArg.payload
            if (type === "create")
            {
                let tableData = state[name] ? state[name] : []
                tableData = [...tableData, value]

                return {
                    ...state,
                    [name]: tableData,
                }
            }
            if (type === "delete")
            {
                const records = state[name]
                const identifiers = value.map((val) => val[uniqueIdentifier])

                return {
                    ...state,
                    [name]: records.filter(
                        (record) =>
                            !identifiers.includes(record[uniqueIdentifier])
                    ),
                }
            } else
            {

                return state
            }
        }
        if (updateArg.type === "UPDATE_ERROR")
        {
            const { name, value, row, type } = updateArg.payload
            let tempErrors = []

            if (type === "formTable" || type === "creditCard")
            {
                if (value)
                {
                    let matchingErrors

                    if (state.errors)
                    {
                        // dirty fix cause this thing is driving me mad
                        if (value.value === "" && (!state.errors || !state.errors.length))
                        {
                            return state
                        }

                        matchingErrors = state.errors.filter(error => error.name === name)
                        if (matchingErrors && matchingErrors.length)
                        {
                            let formTableError = matchingErrors[0]
                            if (value.value !== "")
                            {
                                formTableError.value = [
                                    ...formTableError.value.filter(error => (error.name !== value.name || error.row !== value.row) && error.value !== ""),
                                    value.value !== "" && value
                                ].filter(Boolean)
                            }
                            else
                            {
                                formTableError.value = formTableError.value.filter(error => (error.name !== value.name || error.row !== value.row) && error.value !== "")
                            }

                            if (formTableError.value && formTableError.value.length)
                            {
                                tempErrors = [...state.errors.filter(error => error.name !== name && error.value !== ""), formTableError]
                            }
                            else
                            {
                                tempErrors = state.errors.filter(error => error.name !== name && error.value !== "")
                            }
                        } else
                        {
                            tempErrors = [...tempErrors, { name, value: [value], row }]
                        }
                    }
                    else
                    {
                        if (value.value)
                        {
                            tempErrors = [...tempErrors, { name, value: [value], row }]
                        }
                        else
                        {
                            return state
                        }
                    }
                }
            }
            else
            {

                if (state && state.errors)
                {
                    tempErrors = state.errors.filter((error) => error.name !== name)
                }
                if (value)
                {
                    tempErrors = [...tempErrors, { name, value, row }]
                }

            }

            return {
                ...state,
                errors: tempErrors,
            }
        }

        // check if the type of update argument is a callback function
        if (updateArg.constructor === Function)
        {

            return { ...state, ...updateArg(state) }
        }

        // if the type of update argument is an object
        if (updateArg.constructor === Object)
        {
            // does the update object have _path and _value as it's keys
            // if yes then use them to update deep object values
            if (has(updateArg, "_path") && has(updateArg, "_value"))
            {
                const { _path, _value } = updateArg

                return produce(state, (draft) =>
                {
                    set(draft, _path, _value)
                })
            } else
            {

                return { ...state, ...updateArg }
            }
        }
    }

    const updateForm = useCallback(
        ({ target: { value, name } }) =>
        {
            if (name)
            {
                const updatePath = name.split(".")

                // if we have to update the root level nodes in the form
                if (updatePath.length === 1)
                {
                    const [key] = updatePath

                    updateState({
                        [key]: value,
                    })

                }

                // if we have to update nested nodes in the form object
                // use _path and _value to update them.
                if (updatePath.length > 1)
                {
                    updateState({
                        _path: updatePath,
                        _value: value,
                    })

                }
            }
        },
        [
            // referenceRecord, 
            state
        ]
    )

    const updateErrors = useCallback(({ target: { value, type, name, row } }) =>
    {
        updateState({ type: "UPDATE_ERROR", payload: { value, type, name, row } })

    }, [])

    return {
        state,
        updateState,
        updateForm,
        updateErrors,
        // resetChanges,
        // changes,
    }
}
