import { useRequest } from "../Hooks/useRequest";
import React, { useContext, useEffect, useState } from "react";
import { ToastContext } from "../Contexts/ToastContext";
import { useFormReducer } from "../Hooks/useFormReducer";
import Modal from "./Modal/Modal";
import { FormField } from "./Form/FormField";
import Button from "./Button";
import { FormButtons } from "./Form/FormButtons";
import Spinner from "./Spinner/Spinner";

interface IProps
{
    open?: boolean
    setOpen: (val: boolean) => void
    dataType?: string
    id?: number
    onSubmit?: (data: any) => Promise<{ success?: boolean, message?: string }>
}


export default function EmergencyServicesAddressModal({ open, setOpen, dataType, id, onSubmit }: IProps)
{
    const { getRequest } = useRequest()
    const { add } = useContext(ToastContext)

    const addressObjectLayout = {
        title: "Title",
        forename: "Forename",
        name: "Name",
        businesssuffix: "Business Suffix",
        premises: "Premises",
        thoroughfare: "Thoroughfare",
        locality: "Locality",
        postcode: "Postcode",
    }

    const [address, setAddress] = useState<any>()
    const [loading, setLoading] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [editing, setEditing] = useState(false)

    const { state, updateForm } = useFormReducer({ record: address })

    useEffect(() =>
    {
        async function fetchAddress()
        {
            setLoading(true)
            const addressResponse = await getRequest(`/seat/${id}/ems`)
            if (addressResponse)
            {
                setAddress(addressResponse)
            }
            setLoading(false)
        }

        fetchAddress()
    }, [id])

    return (
        <Modal open={open} setOpen={setOpen} maxWidth={700}>
            <h4 style={{ paddingBottom: 12 }}>
                {editing ? "Editing " : ""}{dataType && `${dataType} `}Emergency Services Address
            </h4>
            <div style={{ display: "flex", flexDirection: "column" }}>
                {!loading ? (
                    <React.Fragment>
                        {Object.keys(addressObjectLayout).map(key => (
                            (address && address[key] || editing) && (
                                <div style={{ paddingBottom: 12 }}>
                                    <FormField
                                        disabled={submitting}
                                        type={editing ? "text" : "info"}
                                        label={addressObjectLayout[key]}
                                        name={key}
                                        value={state[key]}
                                        onChange={updateForm}
                                    />
                                </div>
                            )
                        ))}
                        {!editing ? (
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button
                                    onClick={() => setEditing(true)}
                                    color={"white"}
                                    variant={"warning"}
                                    iconLeft={"pencil-alt"}
                                >
                                    Edit
                                </Button>
                            </div>
                        ) : (
                            <FormButtons
                                onCancel={() => setEditing(false)}
                                loading={submitting}
                                onSubmit={async () =>
                                {
                                    setSubmitting(true)
                                    const response = await onSubmit(state)
                                    if (response && response.success)
                                    {
                                        add({
                                            type: "success",
                                            text: "Emergency services address was successfully updated"
                                        })
                                        setEditing(false)
                                    } else
                                    {
                                        add({
                                            type: "error",
                                            text: "There was an error when updating the emergency services" +
                                                " address. If the problem persists, please contact support."
                                        })
                                    }
                                    setSubmitting(false)
                                }}
                            />
                        )}
                    </React.Fragment>
                ) : (
                    <Spinner />
                )}
            </div>
        </Modal>
    )
}