import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage";
import React, { useContext } from "react";
import useAPIRequests from "../../Hooks/useAPIRequests";
import { regex } from "../../Constants/regex";
import { kMaxLength } from "buffer";
import { regexError } from "../../Constants/regexError";
import { permissions } from "../../Constants/permissions";
import { SessionContext } from "../../Contexts/SessionContext";

export default function SMSIndexPage()
{

    const { getPromise } = useAPIRequests()
    const { state: sessionState } = useContext(SessionContext)

    const modalSettings: ISettingsStage[] = [
        {
            title: "Message",
            fields: [
                {
                    type: "select",
                    name: "from",
                    label: "From",
                    loadOptions: () => getPromise({
                        restType: "POST",
                        url: "sms/originators",
                        label: "number",
                        value: "id",
                        body: { accountNo: sessionState.session.account_no }
                    })
                },
                {
                    type: "text",
                    name: "to",
                    label: "To",
                    regex: regex.telephoneNumber,
                    regexError: regexError.telephoneNumber
                },
                {
                    type: "text",
                    name: "message",
                    label: "Message",
                    maxLength: 250
                }
            ]
        }
    ]

    const columns: IColumn[] =
        [
            {
                name: "status",
                title: "Status",
                conditional: [
                    { value: 0, icon: "envelope", text: "Unread" },
                    { value: 1, icon: "envelope-open-text", text: "Read" },
                    { value: 2, icon: "envelope-open-text", text: "Read" }
                ]
            },
            {
                name: "from",
                title: "From",
                link: { root: "/sms" },
                bold: true,
                sortBy: true,
            },
            {
                name: "message",
                title: "Message",
            },
            {
                name: "date",
                title: "Date",
                sortBy: true,
            }
        ]


    return (
        <GenericIndexPage
            title={"SMS"}
            urlString={"/sms"}
            restType={"POST"}
            columns={columns}
            searchable={true}
            uniqueIdentifier={"smsId"}
            permissions={[permissions.mobile, permissions.admin, permissions.VHAdmin]}
            addModal={{
                displayName: "Message",
                settingsStages: modalSettings,
                customTitle: "Send Message",
                submitData: (data) =>
                {
                    return [{
                        postData: {
                            "originator": data.from.replace("MOBILE-", ""),
                            "destinations": [data.to],
                            "message": data.message,
                            "fromMobile": data.from.includes("MOBILE-")
                        },
                        path: "/sms",
                        type: "create",
                    }]
                }
            }}
            deleteModal={{
                displayName: "Message",
                customTitle: "Delete Message",
                uniqueIdentifier: "smsId",
                submitData: () =>
                {
                    return [{
                        path: "/sms",
                        type: "delete",
                        displayName: "message"
                    }]
                }
            }}
        />
    )
}