import React, { useContext } from "react"
import { useHistory } from "react-router-dom"
import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage"
import useAPIRequests from "../../Hooks/useAPIRequests"
import { useRequest } from "../../Hooks/useRequest"
import { SessionContext } from "../../Contexts/SessionContext"
import { permissions } from "../../Constants/permissions"

export default function IVRsIndexPage()
{
    const history = useHistory()
    const { getDestinationPromise, getPromptPromise } = useAPIRequests()
    const { postRequest, getRequest } = useRequest()
    const { state: sessionState } = useContext(SessionContext)

    async function getLatestIVR()
    {
        const response = await postRequest({}, "/ivr/next")

        if (response)
        {
            return Number(response)
        }
    }

    const columns: IColumn[] = [
        {
            name: "name",
            title: "IVR",

            bold: true,
            onClick: (id) =>
            {
                history.push(`/ivrs/${id}`)
            },
            sortBy: true,
        },
        { name: "description", title: "Name", sortBy: true },
    ]

    const addModalSettings: ISettingsStage[] = [
        {
            title: "General Settings",
            fields: [
                {
                    type: "text",
                    name: "description",
                    label: "Name",
                    required: true,
                    maxLength: 45,
                },
                {
                    type: "select",
                    label: "Introduction Prompt",
                    name: "greetingId",
                    loadOptions: () => getPromptPromise(),
                    options: [{ value: -1, label: "None" }],
                    defaultValue: -1,
                },
                {
                    type: "select",
                    label: "Invalid Option Prompt",
                    name: "invalidPrompt",
                    loadOptions: getPromptPromise,
                    options: [{ value: -1, label: "Default" }],
                    defaultValue: -1,
                },
            ],
        },
        {
            title: "Options 1",
            fields: [
                {
                    type: "formTable",
                    name: "options1",
                    defaultValue: [
                        { number: "1", destination: "none" },
                        { number: "2", destination: "none" },
                        { number: "3", destination: "none" },
                        { number: "4", destination: "none" },
                        { number: "5", destination: "none" },
                        { number: "6", destination: "none" },
                    ],
                    columns: [
                        {
                            title: "Number",
                            name: "number",
                        },
                        {
                            title: "Destination",
                            name: "destination",
                            formField: {
                                type: "select",
                                loadOptions: () => getDestinationPromise({}),
                                name: "destination",
                                options: [
                                    { value: "none", label: "None" },
                                    { label: "Conferencing", value: "CONFERENCING" },
                                ],
                            },
                        },
                    ],
                },
            ],
        },
        {
            title: "Options 2",
            fields: [
                {
                    type: "formTable",
                    name: "options2",
                    defaultValue: [
                        { number: "7", destination: "none" },
                        { number: "8", destination: "none" },
                        { number: "9", destination: "none" },
                        { number: "0", destination: "none" },
                        { number: "*", destination: "none" },
                        { number: "#", destination: "none" },
                        { number: "Timeout", destination: "none" },
                    ],
                    columns: [
                        {
                            title: "Number",
                            name: "number",
                        },
                        {
                            title: "Destination",
                            name: "destination",
                            formField: {
                                type: "select",
                                loadOptions: () => getDestinationPromise({}),
                                name: "destination",
                                options: [
                                    { value: "none", label: "None" },
                                    { label: "Conferencing", value: "CONFERENCING" },
                                ],
                            },
                        },
                    ],
                },
            ],
        },
    ]

    const editModalSettings: ISettingsStage[] = [
        {
            title: "Greetings",
            fields: [
                {
                    type: "select",
                    label: "Introduction Prompt",
                    name: "greetingId",
                    loadOptions: () => getPromptPromise(),
                    options: [{ value: -1, label: "None" }],
                },
                {
                    type: "select",
                    label: "Invalid Option Prompt",
                    name: "invalidPrompt",
                    loadOptions: getPromptPromise,
                    options: [{ value: 0, label: "Default" }],
                },
            ],
        },
        {
            title: "Options 1",
            fields: [
                {
                    type: "formTable",
                    name: "options1",
                    defaultValue: [
                        { number: "1", destination: null },
                        { number: "2", destination: null },
                        { number: "3", destination: null },
                        { number: "4", destination: null },
                        { number: "5", destination: null },
                        { number: "6", destination: null },
                    ],
                    columns: [
                        {
                            title: "Number",
                            name: "number",
                        },
                        {
                            title: "Destination",
                            name: "destination",
                            formField: {
                                type: "select",
                                options: [{ label: "Conferencing", value: "CONFERENCING" }],
                                loadOptions: () => getDestinationPromise({}),
                                name: "destination",
                            },
                        },
                    ],
                },
            ],
        },
        {
            title: "Options 2",
            fields: [
                {
                    type: "formTable",
                    name: "options2",
                    defaultValue: [
                        { number: "7", destination: null },
                        { number: "8", destination: null },
                        { number: "9", destination: null },
                        { number: "0", destination: null },
                        { number: "*", destination: null },
                        { number: "#", destination: null },
                        { number: "Timeout", destination: null },
                    ],
                    columns: [
                        {
                            title: "Number",
                            name: "number",
                        },
                        {
                            title: "Destination",
                            name: "destination",
                            formField: {
                                type: "select",
                                options: [{ label: "Conferencing", value: "CONFERENCING" }],
                                loadOptions: () => getDestinationPromise({}),
                                name: "destination",
                            },
                        },
                    ],
                },
            ],
        },
    ]

    function checkIVRTaken(
        fieldFrom,
        fieldTo
    ): Promise<{ success: boolean; message: string }>
    {
        return new Promise(async (resolve) =>
        {
            let response = await postRequest({}, `/ivr/-1/-1`)

            if (response)
            {
                if (response.records)
                {
                    const filteredRecords = response.records.filter(
                        (record) =>
                            Number(record.name) === Number(fieldFrom) ||
                            (Number(fieldFrom) <= Number(record.name) &&
                                Number(record.name) <= Number(fieldTo))
                    )

                    if (filteredRecords && filteredRecords.length > 0)
                    {
                        resolve({
                            success: false,
                            message:
                                "IVR number is already in use. Please select a different number",
                        })
                    } else
                    {
                        resolve({ success: true, message: "" })
                    }
                } else
                {
                    resolve({
                        success: false,
                        message: "Failed to retrieve data, please try again",
                    })
                }
            } else
            {
                resolve({
                    success: false,
                    message: "Failed to retrieve data, please try again",
                })
            }
        })
    }

    function addIVR(data)
    {
        let options = {}
        data.options1.forEach((option) =>
        {
            let optionKey = option.number
            if (option.number === "*")
            {
                optionKey = "Star"
            } else if (option.number === "#")
            {
                optionKey = "Hash"
            }
            if (option.destination && option.destination != "none")
            {
                options[`option${optionKey}`] = option.destination
            }
        })
        data.options2.forEach((option) =>
        {
            let optionKey = option.number
            if (option.number === "*")
            {
                optionKey = "Star"
            } else if (option.number === "#")
            {
                optionKey = "Hash"
            }
            if (option.destination && option.destination != "none")
            {
                options[`option${optionKey}`] = option.destination
            }
        })
        const postData = {
            description: data.description,
            greetingId: data.greetingId,
            invalidPrompt: data.invalidPrompt,
            ...options,
        }

        const path = "/ivr"
        const identifier = "name"
        const type = "create"
        const displayName = data.fieldFrom

        return [{
            postData,
            path,
            identifier,
            type,
            displayName,
        }]
    }

    function editIVR(data)
    {
        let options = {}
        data.options1.forEach((option) =>
        {
            let optionKey = option.number
            if (option.number === "*")
            {
                optionKey = "Star"
            } else if (option.number === "#")
            {
                optionKey = "Hash"
            }
            if (option.destination)
            {
                options[`option${optionKey}`] = option.destination
            }
        })
        data.options2.forEach((option) =>
        {
            let optionKey = option.number
            if (option.number === "*")
            {
                optionKey = "Star"
            } else if (option.number === "#")
            {
                optionKey = "Hash"
            }
            if (option.destination)
            {
                options[`option${optionKey}`] = option.destination
            }
        })
        const postData = {
            name: data.name,
            greetingId: data.greetingId,
            invalidPrompt: data.invalidPrompt,
            ...options,
        }

        const displayName = "name"
        const path = "/ivr"

        const type = "edit"

        return [{
            postData,
            path,
            type,
            displayName,
        }]
    }

    return (
        <GenericIndexPage
            title={"IVR (Interactive Voice Response)"}
            urlString={"/ivr"}
            restType={"POST"}
            body={{ sortBy: "name", direction: "ASC" }}
            columns={columns}
            searchable={true}
            permissions={[permissions.IVR, permissions.admin, permissions.VHAdmin]}
            addModal={{
                displayName: "IVR",
                fieldFrom: {
                    field: "IVR",
                    label: "IVR",
                    type: "number",
                    min: 200,
                    max: 998,
                    loadDefaultValue: getLatestIVR,
                },
                allowBulkAdd: true,
                settingsStages: addModalSettings,
                availabilityCheck: (fieldFrom, fieldTo) =>
                {
                    return new Promise(async (resolve) =>
                    {
                        if (
                            Number(fieldFrom) < 200 ||
                            Number(fieldFrom) > 998 ||
                            (fieldTo &&
                                (Number(fieldTo) < 200 || fieldTo > 998))
                        )
                        {
                            resolve({
                                success: false,
                                error: "Please input a valid number",
                            })
                        }

                        if (fieldTo && fieldTo <= fieldFrom)
                        {
                            resolve({
                                success: false,
                                error:
                                    "Please ensure this number is less than the To field",
                            })
                        }

                        const responseMessage = await checkIVRTaken(
                            fieldFrom,
                            fieldTo
                        )

                        if (responseMessage && responseMessage.success)
                        {
                            resolve({ success: true })
                        } else
                        {
                            resolve({
                                success: false,
                                error: responseMessage.message,
                            })
                        }
                    })
                },
                submitData: (data) =>
                {
                    return addIVR(data)
                },
            }}
            editModal={{
                displayName: "IVR",
                settingsStages: editModalSettings,
                uniqueIdentifier: "name",
                loadRecords: [{
                    url: "/ivr",
                    dataDefinition: (data) =>
                    {
                        const options = Object.keys(data)
                            .map((key) =>
                            {
                                if (key.startsWith("option"))
                                {
                                    if (data[key])
                                    {
                                        let optionNumber = key.replace(
                                            "option",
                                            ""
                                        )
                                        if (optionNumber === "Star")
                                        {
                                            optionNumber = "*"
                                        } else if (optionNumber === "Hash")
                                        {
                                            optionNumber = "#"
                                        }
                                        return {
                                            number: optionNumber,
                                            destination: data[key],
                                        }
                                    }
                                }
                            })
                            .filter(Boolean)

                        const options1 = ["1", "2", "3", "4", "5", "6"].map(
                            (number) =>
                            {
                                const matchingOptions = options.filter(
                                    (option) => option.number === number
                                )
                                if (matchingOptions && matchingOptions.length)
                                {
                                    return matchingOptions[0]
                                } else
                                {
                                    return { number, destination: null }
                                }
                            }
                        )
                        const options2 = [
                            "7",
                            "8",
                            "9",
                            "*",
                            "0",
                            "#",
                            "Timeout",
                        ].map((number) =>
                        {
                            const matchingOptions = options.filter(
                                (option) => option.number === number
                            )
                            if (matchingOptions && matchingOptions.length)
                            {
                                return matchingOptions[0]
                            } else
                            {
                                return { number, destination: null }
                            }
                        })

                        return {
                            ...data,
                            options1,
                            options2,
                        }
                    },
                }],
                submitData: (data) =>
                {
                    return editIVR(data)
                },
            }}
            deleteModal={{
                displayName: "IVR",
                uniqueIdentifier: "name",
                submitData: () =>
                {
                    return [{
                        path: "/ivr",
                        displayName: "name",
                        type: "delete",
                    }
                    ]
                },
            }}
            helpSections={[
                {
                    title: "IVR Menus",
                    text: "Direct callers based on option pressed."
                }
            ]}
        />
    )
}
