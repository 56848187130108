export const regexError = {
    email: "Please enter a valid email address.",
    publicIpAddress: "Please enter a valid IP address for a public network.",
    privateIpAddress: "Please enter a valid IP address for a private network.",
    telephoneNumber: "Please enter a valid telephone number.",
    postcode: "Please enter a valid UK postcode.",
    accountSeat: "Please enter a valid seat identifier. e.g. 12345*200",
    pin: "PIN must be numerical with length between 4 and 6 digits",
    internationalPin: "PIN must be numerical with length between 4 and 8 digits",
    cardAddressLine1: "Please enter a valid address line 1; shouldn't be more than 45 characters long.",
    username: "Please enter a valid username. Usernames are limited to alphanumerics, underscores(_), dashes(-), asperand(@), full stops(.), exclamations point(!) and spaces. Limited to 4 - 100 characters",
    usersObjectName: "Please enter a valid name. Names are limited to alphanumerics & spaces. Limited to 2 - 100 characters",
    bankAccountNumber: "Please enter a valid UK bank account number",
    sortCode: "Please enter a valid sort code",
    password: "Password must be 8-50 characters long, contain at least one uppercase, one lowercase letter, one number, and one special character"
}