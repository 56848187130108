import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "@material-ui/core";

export default function useTabbedCardFormPage({ tabs, state, loading, changes })
{
    const el = useRef()
    const isSmall = useMediaQuery("(max-width:940px)")
    const [recordNotFound, setRecordNotFound] = useState(false)
    const [currentTab, setCurrentTab] = useState(0)
    const [tempTabArray, setTempTabArray] = useState(tabs)

    const [moreTabsVisible, setMoreTabsVisible] = useState(false)

    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    })

    const [tabAmount, setTabAmount] = useState(0)

    const [hiddenTabs, setHiddenTabs] = useState([])
    const [visibleTabs, setVisibleTabs] = useState([tempTabArray[0]])
    const [helpSections, setHelpSections] = useState<any>([])

    useEffect(() =>
    {
        if (!loading)
        {
            if ((Object.entries(state).length === 0 && state.constructor === Object) || state.success === false)
            {
                setRecordNotFound(true)
            } else
            {
                setRecordNotFound(false)
            }
        }
    }, [loading, state])

    useEffect(() =>
    {
        const helptexts = []
        tabs[currentTab].sections.forEach(section =>
        {
            section.fields.forEach(field =>
            {
                if (field.helptext)
                {
                    helptexts.push({ title: field.label, text: field.helptext })
                }
            })
        }
        )
        setHelpSections(helptexts)
    }, [currentTab])

    function handleTabOrder()
    {
        const element = el

        if (element && element.current)
        {
            setTabAmount(
                // @ts-ignore
                Math.trunc((element.current.clientWidth - 135) / (135))
            )
        }
        const visible = tempTabArray.filter(
            (tab, index) => index + 1 <= tabAmount
        )
        setVisibleTabs(visible)

        const hidden = tempTabArray.filter(
            (tab, index) => index + 1 > tabAmount
        )

        setHiddenTabs(hidden)
    }

    function handleChangeTab(title)
    {
        setTempTabArray(tabs)
        handleTabOrder()

        const selectedTabIndex = tempTabArray.findIndex(
            tab => tab.title === title
        )

        const lastVisibleTab = visibleTabs[visibleTabs.length - 1]
        const lastVisibleTabIndex = tempTabArray.findIndex(
            tab => tab.title === lastVisibleTab.title
        )

        const b = tempTabArray[selectedTabIndex]
        tempTabArray[selectedTabIndex] = tempTabArray[lastVisibleTabIndex]
        tempTabArray[lastVisibleTabIndex] = b

        setTempTabArray(tempTabArray)

        handleTabOrder()
        setCurrentTab(tabs.findIndex(tab => tab.title === title))
    }

    function findFieldValue(name)
    {
        if (!name)
        {
            return null
        }
        const splitName = name.split(".")

        let value = state
        splitName.forEach(split =>
        {
            if (value && value[split] || value[split] === 0 || value[split] === false)
            {
                value = value[split]
            }
        })

        if (typeof value !== "object" || typeof value === "object" && value.constructor === Array)
        {
            return value
        }
        else
        {
            return null
        }
    }

    function findFieldError(name)
    {
        if (state.errors && state.errors.length > 0)
        {
            const errors = state.errors.filter(error => error.name === name)
            if (errors && errors.length > 0)
            {
                return errors[0].value
            }
        }
        return null
    }

    function calculateCondition(conditions: ITabbedFormConditional)
    {
        if (!conditions || conditions == null)
        {
            return null
        } else
        {
            let operator = conditions.operator && conditions.operator !== null ? conditions.operator : "AND"

            if (operator === "AND")
            {
                for (let condition of conditions.checks)
                {
                    if (findFieldValue(condition.field) !== condition.value)
                    {
                        return false
                    }
                }
                return true
            }
            else if (operator === "OR")
            {
                for (let condition of conditions.checks)
                {
                    if (findFieldValue(condition.field) === condition.value)
                    {
                        return true
                    }
                }
                return false
            }
        }
    }

    function calculateConditionalValue(conditions: ITabbedFormConditional)
    {
        if (!conditions)
        {
            return null
        } 
        else
        {
            let operator = conditions.operator && conditions.operator !== null ? conditions.operator : "AND"

            if (operator === "AND")
            {
                for (let condition of conditions.checks)
                {
                    let fieldValue = findFieldValue(condition.field)
                    if (condition.value === "ANY-VALUE")
                    {
                        if (fieldValue === null || fieldValue === undefined)
                        {
                            return undefined
                        }
                    }
                    else if (condition.value !== fieldValue)
                    {
                        return undefined
                    }
                }
                return conditions.successValue
            }
            else if (operator === "OR")
            {
                for (let condition of conditions.checks)
                {
                    var fieldValue = findFieldValue(condition.field)
                    if (condition.value === "ANY-VALUE")
                    {
                        if (fieldValue !== null && fieldValue !== undefined)
                        {
                            return conditions.successValue
                        }
                    }
                    else if (condition.value === fieldValue)
                    {
                        return conditions.successValue
                    }
                }
                return undefined
            }
        }
    }

    function isTabErrored(tab)
    {
        if (state.errors && state.errors.length > 0)
        {
            return tab.sections.filter(section =>
            {
                return section.fields.filter(field =>
                {
                    const errors = state.errors.filter(error => error.name === field.name)
                    return errors.length > 0
                }).length > 0
            }
            ).length > 0
        }
        return false
    }

    useEffect(() =>
    {
        function handleResize()
        {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            })
        }

        window.addEventListener("resize", handleResize)
        handleResize()

        return () =>
        {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    useEffect(() =>
    {
        handleTabOrder()
        // eslint-disable-next-line
    }, [dimensions, tempTabArray])

    useEffect(() =>
    {
        setTempTabArray(tabs)
    }, [...tabs.map(tab => tab.disabled)])

    return {
        el,
        hiddenTabs,
        visibleTabs,
        moreTabsVisible,
        setMoreTabsVisible,
        handleChangeTab,
        findFieldValue,
        calculateCondition,
        calculateConditionalValue,
        findFieldError,
        currentTab,
        setCurrentTab,
        tempTabArray,
        recordNotFound,
        isTabErrored,
        helpSections,
        isSmall,
    }
}