import React, { useContext } from "react"
import { useHistory } from "react-router-dom"
import { GenericIndexPage } from "../GenericIndexPage/GenericIndexPage"
import { useRequest } from "../../Hooks/useRequest"
import { regex } from "../../Constants/regex"
import { regexError } from "../../Constants/regexError"
import { hasPermission, permissions } from "../../Constants/permissions"
import { SessionContext } from "../../Contexts/SessionContext"
import useAPIRequests from "../../Hooks/useAPIRequests"

export default function NTSDestinationsIndexPage()
{
    const { state: sessionState } = useContext(SessionContext)
    const history = useHistory()
    const { getRequest } = useRequest()
    const { getPromptPromise } = useAPIRequests()

    function checkNtsAvailability(
        fieldFrom
    ): Promise<{ success: boolean; message: string }>
    {
        return new Promise(async (resolve) =>
        {
            if (!fieldFrom)
            {
                resolve({
                    success: false,
                    message: "Please enter a telephone number",
                })
            }

            fieldFrom = fieldFrom.replace("\\s+", "")
            if (!regex.telephoneNumber.test(fieldFrom))
            {
                resolve({
                    success: false,
                    message: "Please enter a valid telephone number",
                })
            }

            let response = await getRequest(
                `/nts/check/` + fieldFrom
            )

            if (response)
            {
                if (response.success)
                {
                    if (!response.onnet)
                    {
                        resolve({ success: true, message: "" })
                    }
                    else if (response.message)
                    {
                        resolve({
                            success: false,
                            message: response.message,
                        })
                    }
                    else
                    {
                        resolve({
                            success: false,
                            message:
                                "It is not possible to add on network telephone number as NTS destination.",
                        })
                    }
                }
                else if (response.message)
                {
                    resolve({
                        success: false,
                        message: response.message,
                    })
                }
                else
                {
                    {
                        resolve({
                            success: false,
                            message: "An unknown error occured when checking the number. Please try again or contact support.",
                        })
                    }
                }
            } else
            {
                resolve({
                    success: false,
                    message: "Failed to retrieve data, please try again",
                })
            }
        })
    }

    const columns: IColumn[] = [
        {
            name: "name",
            title: "Name",

            bold: true,
            onClick: (id) =>
            {
                history.push(`/nts/${id}`)
            },
            sortBy: true,
            conditional: [{
                value: "",
                text: "Name not set",
            }],
        },
        { name: "destination", title: "Destination", sortBy: true },
    ]

    return (
        <GenericIndexPage
            title={"NTS Destinations"}
            urlString={"/nts"}
            restType={"POST"}
            body={{ sortBy: "name", direction: "ASC" }}
            columns={columns}
            searchable={true}
            permissions={[permissions.externalExt, permissions.admin, permissions.VHAdmin]}
            addModal={{
                displayName: "NTS Destination",
                allowBulkAdd: false,
                fieldFrom: {
                    field: "Telephone Number",
                    label: "Telephone Number",
                    type: "text",
                    regex: regex.telephoneNumber,
                    regexError: regexError.telephoneNumber
                },
                settingsStages: [
                    {
                        title: "Settings",
                        fields: [
                            {
                                type: "text",
                                label: "Name",
                                name: "name",
                                maxLength: 50,
                            },
                            {
                                type: "switch",
                                label: "Enable Refuse Call",
                                name: "extRefuse"
                            },
                            {
                                type: "select",
                                label: "Prompt Before Answer",
                                name: "answerPromptId",
                                loadOptions: getPromptPromise,
                                options: [
                                    { value: -1, label: "None" },
                                ],
                                defaultValue: -1
                            }
                        ]
                    },
                    {
                        title: "Call Restrictions",
                        fields: [
                            {
                                type: "switch",
                                label: "Allow UK Geographic (01, 02 & 03)",
                                name: "allowUKGeo",
                            },
                            {
                                type: "switch",
                                label: "Allow UK Mobile (071 to 079)",
                                name: "allowUKMobile",
                            },
                            {
                                type: "switch",
                                label: "Allow UK Directory (118)",
                                name: "allowUK118",
                            },
                            {
                                type: "switch",
                                label: "Allow Other UK Calls (08XX)",
                                name: "allowUKOther",
                            },
                            {
                                type: "switch",
                                disabled: !hasPermission([permissions.highRisk, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                                label: "Allow International Calls",
                                name: "allowInternational",
                            },
                            {
                                conditionallyShow: {
                                    checks: [{
                                        field: "allowInternational",
                                        value: true,
                                    }]
                                },
                                type: "switch",
                                disabled: !hasPermission([permissions.highRisk, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                                label: "Allow High Risk Zone",
                                name: "allowInternationalHighRisk",
                                helptext:
                                    "High Risk Zones are destinations that are considered to have high or extreme risks of telecommunications fraud based on current data",
                            },
                            {
                                type: "switch",
                                label: "Allow UK Premium Calls (09)",
                                name: "allowUKPremium09",
                            },
                            {
                                type: "switch",
                                label: "Allow UK Premium Calls (070)",
                                name: "allowUKPremium070",
                            },
                        ],
                    },
                ],
                availabilityCheck: (fieldFrom, fieldTo) =>
                {
                    return new Promise(async (resolve) =>
                    {
                        const responseMessage = await checkNtsAvailability(
                            fieldFrom
                        )

                        if (responseMessage && responseMessage.success)
                        {
                            resolve({ success: true })
                        } else
                        {
                            resolve({
                                success: false,
                                error: responseMessage.message,
                            })
                        }
                    })
                },
                submitData: (data) =>
                {
                    const options = {
                        allowUKGeo: data.allowUKGeo,
                        allowUKMobile: data.allowUKMobile,
                        allowUK118: data.allowUK118,
                        allowUKOther: data.allowUKOther,
                        allowInternational: data.allowInternational,
                        allowInternationalHighRisk: data.allowInternationalHighRisk,
                        allowUKPremium09: data.allowUKPremium09,
                        allowUKPremium070: data.allowUKPremium070,
                        extRefuse: data.extRefuse
                    }
                    return [{
                        postData: {
                            name: data.name,
                            answerPromptId: data.answerPromptId,
                            options: options,
                        },
                        path: "/nts",
                        identifier: "destination",
                        type: "create",
                        displayName: data.fromField,
                    }]
                },
            }}
            editModal={{
                displayName: "NTS Destination",
                uniqueIdentifier: "name",
                settingsStages: [
                    {
                        title: "Settings",
                        fields: [
                            {
                                type: "switch",
                                label: "Allow UK Geographic (01, 02 & 03)",
                                name: "allowUKGeo",
                            },
                            {
                                type: "switch",
                                label: "Allow UK Mobile (071 to 079)",
                                name: "allowUKMobile",
                            },
                            {
                                type: "switch",
                                label: "Allow UK Directory (118)",
                                name: "allowUK118",
                            },
                            {
                                type: "switch",
                                label: "Allow Other UK Calls (08XX)",
                                name: "allowUKOther",
                            },
                            {
                                type: "switch",
                                disabled: !hasPermission([permissions.highRisk, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                                label: "Allow International Calls",
                                name: "allowInternational",
                            },
                            {
                                conditionallyShow: {
                                    checks: [{
                                        field: "allowInternational",
                                        value: true,
                                    }]
                                },
                                type: "switch",
                                disabled: !hasPermission([permissions.highRisk, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                                label: "Allow High Risk Zone",
                                name: "allowInternationalHighRisk",
                                helptext:
                                    "High Risk Zones are destinations that are considered to have high or extreme risks of telecommunications fraud based on current data",
                            },
                            {
                                type: "switch",
                                label: "Allow UK Premium Calls (09)",
                                name: "allowUKPremium09",
                            },
                            {
                                type: "switch",
                                label: "Allow UK Premium Calls (070)",
                                name: "allowUKPremium070",
                            },
                        ],
                    },
                ],
                loadRecords: [{
                    url: "/nts",
                    dataDefinition: (data) =>
                    {
                        return {
                            ...data,
                            ...data.options,
                        }
                    },
                }],
                submitData: (data) =>
                {
                    const options = {
                        allowUKGeo: data.allowUKGeo,
                        allowUKMobile: data.allowUKMobile,
                        allowUK118: data.allowUK118,
                        allowUKOther: data.allowUKOther,
                        allowInternational: data.allowInternational,
                        allowInternationalHighRisk: data.allowInternationalHighRisk,
                        allowUKPremium09: data.allowUKPremium09,
                        allowUKPremium070: data.allowUKPremium070,
                    }
                    return [{
                        postData: {
                            options: options,
                        },
                        path: "/nts",
                        type: "edit",
                        displayName: "destination",
                    }]
                },
            }}
            deleteModal={{
                displayName: "NTS Destination",
                uniqueIdentifier: "name",
                submitData: () =>
                {
                    return [{
                        path: "/nts",
                        displayName: "name",
                        type: "delete",
                    }]
                },
            }}
            helpSections={[
                {
                    title: "NTS Destinations",
                    text: "Add external telephone numbers to your account for call routing."
                }
            ]}
        />
    )
}
