import React from "react"
import "./dropdownMenu.scss"
import { useHandleOutsideClick } from "../../Hooks/useHandleOutsideClick"
import { FormField } from "../Form/FormField"
import Button from "../Button"

interface IProps
{
    open?: boolean
    setOpen: (open: boolean) => void
    IconComponent: React.ComponentType
    iconClassName?: string
    dropdownClassName?: string
    borderRadius?: number
    right?: number
    width?: number
    filters: IFilter
    filterState: any
    setFilterState: ISetFilter
    resetFilters: () => void
    applyFilters: () => void
    loading?: boolean
}

export function FiltersDropdown({
    open,
    setOpen,
    IconComponent,
    iconClassName,
    dropdownClassName,
    borderRadius,
    right,
    width,
    filters,
    filterState,
    setFilterState,
    resetFilters,
    applyFilters,
    loading
}: IProps)
{
    const node = useHandleOutsideClick(open, setOpen)

    return (
        <div className={iconClassName && iconClassName} ref={node}>
            <div
                onClick={() =>
                {
                    if (!open)
                    {
                        setOpen(true)
                    } else
                    {
                        setOpen(false)
                    }
                }}
            >
                <IconComponent />
            </div>
            {open && (
                <div
                    className={`dropdownComponent ${dropdownClassName ? dropdownClassName : ""}`}
                    style={{ borderRadius, right, width }}
                >
                    {filters.filters.map((filter) => (
                        <div
                            key={filter.label}
                            style={{
                                flexDirection: "row",
                                top: 0,
                                display: "flex",
                                alignItems: "center",
                                borderBottom: "1px solid #f4f4f4",
                                padding: "8px 12px",
                                fontSize: "0.9em",
                            }}>
                            <FormField
                                {...filter}
                                small
                                value={filterState[filter.name]}
                                onChange={(e) =>
                                    setFilterState({
                                        name: filter.name,
                                        // @ts-ignore
                                        type: filter.type,
                                        // @ts-ignore
                                        value: e.target.value,
                                    })
                                }
                            />
                        </div>
                    ))}
                    <div
                        style={{
                            flexDirection: "row",
                            top: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            borderBottom: "1px solid #f4f4f4",
                            padding: 8,
                            fontSize: "0.9em",
                        }}>
                        <Button
                            size="sm"
                            style={{ padding: 4, marginRight: 4 }}
                            variant={"outline-primary"}
                            onClick={resetFilters}
                        >
                            Reset
                        </Button>
                        <Button
                            size="sm"
                            style={{ padding: 4 }}
                            loading={loading}
                            onClick={async () =>
                            {
                                applyFilters()
                                setOpen(false)
                            }}
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}
